import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const VirtualTraining = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={true}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('VirtualTraining-Title') }</h1>
                                <p className="mw-900 mt-3 parsed-text">{ tp('VirtualTraining-Intro') }</p>

                                <Tile title={ t('VirtualTraining-WhatTrain-Title') } hideTileHeader={false}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <span className="mw-900">{ tp('VirtualTraining-WhatTrain-Intro') }</span>
                                    <GrayTile percentWidth={100}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <h3 className="sub-headings-h3">{ t('VirtualTraining-ThingsToConsider') }</h3>
                                            
                                        </>
                                    </GrayTile>
                                    
                                    <TwoColumnLayout
                                    left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <p className="parsed-text">{ tp('VirtualTraining-WhatTrain-DeliveryMethod') }</p>
                                    </>
                                    }

                                    right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <p className="parsed-text">{ tp('VirtualTraining-WhatTrain-Participants') }</p>
                                    </>
                                    }
                                />

                                </>      
                                </Tile>
                                <Tile title={ t('VirtualTraining-SelectPlatform-Title') } hideTileHeader={false}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <span className="mw-900">{ tp('VirtualTraining-SelectPlatform-Intro') }</span>
                                    <TwoColumnLayout
                                    left={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('VirtualTraining-SelectPlatform-Activities') }</p>
                                            <p className="parsed-text">{ tp('VirtualTraining-SelectPlatform-Settings') }</p>

                                        </>
                                    }

                                    right={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('VirtualTraining-SelectPlatform-EventInfo') }</p>
                                            <p className="parsed-text">{ tp('VirtualTraining-SelectPlatform-Contingency') }</p>
                                            <p className="parsed-text">{ tp('VirtualTraining-SelectPlatform-Practice') }</p>
                                        </>
                                    }
                                />
                                </>      
                            </Tile>
                            <Tile title={ t('VirtualTraining-Preparing-Title') } hideTileHeader={false}>
                            
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <span className="mw-900"> { tp('VirtualTraining-Preparing-Intro') }</span>
                                <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-Materials') }</p>
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-SafetyIntevention') }</p>
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-Workbooks') }</p>
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-Tests') }</p>
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-ChoosingCohost') }</p>
                                        <p className="parsed-text">{ tp('VirtualTraining-Preparing-PracticeCohost') }</p>
                                    </>
                                }
                            />
                            </>      
                            </Tile>
                            <Tile title={ t('VirtualTraining-Facilitate-Title') } hideTileHeader={false}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <span className="mw-900"> { tp('VirtualTraining-Facilitate-Intro') }</span>
                                    <TwoColumnLayout
                                    left={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('VirtualTraining-Facilitate-Considerations1') }</p>
                                            
                                        </>
                                    }

                                    right={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('VirtualTraining-Facilitate-Considerations2') }</p>
                                            

                                        </>
                                    }
                                />
                                
                                </>      
                            </Tile>
                            <Tile title={ t('AfterVirtualTraining-Title') } hideTileHeader={false}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <span className="mw-900">{ tp('AfterVirtualTraining-Intro') }</span>
                                    <TwoColumnLayout
                                    left={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('AfterVirtualTraining-SelfAssessment') }</p>
                                            <p className="parsed-text">{ tp('AfterVirtualTraining-Feedback') }</p>
                                        </>
                                    }

                                    right={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <p className="parsed-text">{ tp('AfterVirtualTraining-Reinforce') }</p>
                                            <p className="parsed-text">{ tp('AfterVirtualTraining-Resources') }</p>
                                        </>
                                    }
                                />


                                    
                                
                                </>      
                            </Tile>


                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default VirtualTraining;
