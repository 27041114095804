import "react-datepicker/dist/react-datepicker.css";

import * as React from "react";
import { RbacContext } from "../rbac-context";
import { ApiResponse, JsonResponseModel } from "../ApiServices";
import { Breadcrumb, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { InvoiceReturnModel, OrganizationInvoiceModel, OrganizationInvoicedCourse, OrganizationSearchModel, SeatExpirationUpdateModel, StaffSelectModel } from "../Interfaces";
import { WithToast, withToast } from "../hooks/toast";
import { WithTranslation, useTranslation, withTranslation } from "react-i18next";
import { fetchOrganization, fetchSeatsByOrganization, fetchCourseSeatDataByOrganization } from "./Utils/organization";

import { AdminBaseRoutes } from "../Routing/routes";
import EditSeatModal from "./Components/seat-edit-form";
import InvoiceAccordions from "./Components/invoice-accordions";
import { RoleEnum, StaffSeatEditorRoles } from "../Enums";
import { InvoiceStatus } from "../Enums/InvoiceStatus";
import PageMessages from "../Components/page-messages";
import RemoveInvoiceModal from "./Components/remove-invoice-modal";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withNavigator } from "../Components/with-navigator";
import { ConfigContext } from "../configuration-context";
import { fetchStaffMembers } from "./Utils/profile";

interface LocationProps {
    courseName?: string;
    organization?: OrganizationSearchModel;
    organizationCourseSeats?: OrganizationInvoicedCourse;
}

export interface SeatUsageHistoryPageProps
    extends RouteComponentProps<{ orgId: string; courseId: string }, any, LocationProps>,
    WithTranslation,
    WithToast {
    courseId: number;
    courseName: string;
    organization: OrganizationSearchModel;
    organizationCourseSeats: OrganizationInvoicedCourse;
}

interface SeatUsageHistoryPageState {
    courseName: string;
    organization: OrganizationSearchModel;
    organizationCourseSeats: OrganizationInvoicedCourse;
    seatData: OrganizationInvoiceModel[];
    loading: boolean;
    errored: boolean;
    orgNotFound: boolean;
    courseNotFound: boolean;
    accordionStates: { [id: string]: boolean };
    currentOrgSeat: OrganizationInvoiceModel;
    editSeatModalCurrentExpirationDate: string;
    filter: InvoiceStatus;
    staffMembers: StaffSelectModel[];
}

const SeatUsageHistoryPage = (props: any) => {
    const configContext = React.useContext(ConfigContext);
    const rContext = React.useContext(RbacContext);
    let uRoles: RoleEnum[] = rContext.userContext.RolesByName ?? [];
    const showAdminFeatures = uRoles.some(r => StaffSeatEditorRoles.includes(r) );
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { t } = useTranslation();
    const location = useLocation();
    const { orgId, courseId  } = useParams();

    const initialState: any = location?.state ?? {
        courseName: "",
        organization: {},
        organizationCourseSeats: {},
    };
    const [state, setState] = React.useState<SeatUsageHistoryPageState>(
    {
        ...initialState,
        seatData: [],
        loading: true,
        errored: false,
        orgNotFound: false,
        courseNotFound: false,
        accordionStates: {},
        currentOrgSeat: null,
        filter: 0,        
        staffMembers: [],
    });
    const [showEditSeatModal, setShowEditSeatModal] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);

    React.useEffect(() => {
        const componentDidMount = async () => {
            try {
                if (state.courseName !== "") {
                    const { organization } = location.state;

                    const seatData = await fetchCourseSeatDataByOrganization(configContext, organization.organizationId, courseId);

                    const staffMembers = await fetchStaffMembers(configContext);

                    setState({ 
                        ...state, 
                        loading: false,
                        organization, 
                        seatData: seatData,
                        staffMembers: staffMembers});
                } else {
                    const organization = await fetchOrganization(apimBaseUrl, configContext, orgId);
                    if (!organization) {
                        setState({ ...state, orgNotFound: true });
                        return;
                    }

                    const organizationCourseSeatsArray = await fetchSeatsByOrganization(apimBaseUrl, configContext, organization.organizationId);
                    const organizationCourseSeats = organizationCourseSeatsArray?.find(seat => seat.courseId === courseId);

                    if (!organizationCourseSeats) {
                        setState({ ...state, courseNotFound: true });
                        return;
                    }

                    const seatData = await fetchCourseSeatDataByOrganization(configContext, organization.organizationId, courseId);

                    const staffMembers = await fetchStaffMembers(configContext);                        

                    setState({
                        ...state,
                        loading: false,
                        courseName: organizationCourseSeats.courseName,
                        organization: organization,
                        organizationCourseSeats: organizationCourseSeats,
                        seatData: seatData,
                        staffMembers: staffMembers
                    });
                }
            } catch (err) {
                console.error(err);
                setState({ ...state, errored: true });
            }
        }
        componentDidMount();
    }, []);

    const refresh = (seatUpdatedResponse: OrganizationInvoiceModel) => {

        if(seatUpdatedResponse){
            let { seatData, organizationCourseSeats } = state;

            const invoiceToUpdate = seatData.find(seat => seat.seatPoolId === seatUpdatedResponse.seatPoolId);
            if (invoiceToUpdate) {
                // Update the org seat summary with the new updated values
                const seatsRemainingDiff = invoiceToUpdate.seatsRemaining - seatUpdatedResponse.seatsRemaining;
                const seatsUsedDiff = invoiceToUpdate.seatsUsed - seatUpdatedResponse.seatsUsed;
                organizationCourseSeats.seatsRemaining -= seatsRemainingDiff;
                organizationCourseSeats.seatsUsed -= seatsUsedDiff;

                invoiceToUpdate.expirationDate = seatUpdatedResponse.expirationDate; 
                invoiceToUpdate.seatsRemaining = seatUpdatedResponse.seatsRemaining;            
                invoiceToUpdate.seatsUsed = seatUpdatedResponse.seatsUsed;
                invoiceToUpdate.isExpired = seatUpdatedResponse.isExpired;                   
                invoiceToUpdate.seatAdjustments = invoiceToUpdate.seatAdjustments && invoiceToUpdate.seatAdjustments.length > 0 ? 
                seatUpdatedResponse.seatAdjustments?.concat(invoiceToUpdate.seatAdjustments) : seatUpdatedResponse.seatAdjustments;

                setState({ ...state,  
                    seatData: seatData,
                    organizationCourseSeats: organizationCourseSeats });
            }
        }
    };

    const handleRemoveModalClose = () => setShowRemoveModal(false);

    const handleRemoveModalShow = (currentOrgSeat: OrganizationInvoiceModel) => {
        setState({ ...state, currentOrgSeat });
        setShowRemoveModal(true);
    };

    const handleRemove = (seatRemovedResponse: OrganizationInvoiceModel) => {
        refresh(seatRemovedResponse);
        handleRemoveModalClose();
    };

    const handleClose = () => {
        setShowEditSeatModal(false);
    }

    const handleShow = (currentOrgSeatId: string, currentExpirationDate: string) =>
    {
        setShowEditSeatModal(true);
        setState({
            ...state,
            currentOrgSeat: {... state.currentOrgSeat, seatPoolId: currentOrgSeatId},
            editSeatModalCurrentExpirationDate: currentExpirationDate
        });
    }

    const handleExpirationUpdated = (seatUpdatedResponse: OrganizationInvoiceModel) => {
        refresh(seatUpdatedResponse);
        handleClose();
    };

    if (state.loading || state.errored || state.orgNotFound || state.courseNotFound) {
        const notFound = state.orgNotFound || state.courseNotFound;
        const notFoundMessage = state.orgNotFound
            ? t("Org with OrgId {{orgId}} not found.", { orgId })
            : t("Course with courseId {{courseId}} not found.", { courseId });
        return (
            <PageMessages
                loading={state.loading}
                errored={state.errored}
                notFound={notFound}
                notFoundMessage={notFoundMessage}
            />
        );
    }

    return (
        <>
            <h1>{t("Manage Seats")}</h1>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: AdminBaseRoutes.ManageSeats.fullPath }}>
                    {t("Manage Seats")}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: AdminBaseRoutes.ManageSeatsOrg.fullPath.replace(":orgId", orgId) }}
                >
                    {state.organization?.organizationName}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{state.courseName}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="jumbotron">
                <Row>
                    <Col md={6}>
                        <h4>
                            {state.organization?.organizationName} (#{state.organization?.orgId})
                        </h4>
                        <h6>{state.courseName}</h6>
                    </Col>
                    <Col md={2}>
                        <h6 style={{ fontWeight: "bold" }}>{t("Total Seats Purchased")}</h6>
                        <div>{state.organizationCourseSeats?.seatsPurchased}</div>
                    </Col>
                    <Col md={2}>
                        <h6 style={{ fontWeight: "bold" }}>{t("Total Seats Available")}</h6>
                        <div>{state.organizationCourseSeats?.seatsRemaining}</div>
                    </Col>
                    <Col md={2}>
                        <h6 style={{ fontWeight: "bold" }}>{t("Total Seats Used")}</h6>
                        <div>{state.organizationCourseSeats?.seatsUsed}</div>
                    </Col>
                </Row>
            </div>
            <h5>Filter Invoices</h5>
            <Container fluid className="mb-3">
                <Row>
                    <Col sm={4} lg={3} xl={2}>
                        <h6>{t("Invoice Status")}</h6>
                        <Form.Control
                            as="select"
                            value={state.filter}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                setState({ ...state, filter: parseInt(e.target.value) as InvoiceStatus })
                            }
                        >
                            <option value="0">{t("All Invoices")}</option>
                            <option value="1">{InvoiceStatus.toString(InvoiceStatus.Active)}</option>
                            <option value="2">{InvoiceStatus.toString(InvoiceStatus.Expired)}</option>
                            <option value="3">{InvoiceStatus.toString(InvoiceStatus.Removed)}</option>
                        </Form.Control>
                    </Col>
                </Row>
            </Container>
            <div>
                <InvoiceAccordions
                    invoices={state.seatData}
                    showAdminFeatures={showAdminFeatures}
                    handleExpirationModalShow={handleShow}
                    handleRemoveModalShow={handleRemoveModalShow}
                    filter={state.filter}
                />
            </div>
            <EditSeatModal
                orgSeatId={state.currentOrgSeat?.seatPoolId}
                staffMembers={state.staffMembers}
                editSeatModalCurrentExpirationDate={state.editSeatModalCurrentExpirationDate}
                isVisible={showEditSeatModal}
                onHide={handleClose}
                handleSaveComplete={handleExpirationUpdated}
            />
            {showRemoveModal && (
                <RemoveInvoiceModal
                    orgSeat={state.currentOrgSeat}
                    isVisible={showRemoveModal}
                    onHide={handleRemoveModalClose}
                    handleRemove={handleRemove}
                />
            )}
        </>
    );
}

export default withNavigator(withAITracking(reactPlugin, withTranslation()(SeatUsageHistoryPage)));
