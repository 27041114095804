import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../Components/UI/button";
export interface Props {
    selected: string[],
    canBulkUpdateParticipants: boolean
}
const ParticipantsSelected = (props: Props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            {props.selected.length > 0 && props.canBulkUpdateParticipants && (
                <div className="participants-selection">                    
                    <span className="participants-selection-selected">{t("Total-Selected", { totalSelected: props.selected.length })}</span>
                    <Button isSolid={false} label={t("Add To Class")} />
                    <Button isSolid={false} label={t("Remove From Class")} />
                    <Button isSolid={false} label={t("Delete Participant")} />
                </div>
            )}
        </>
    );
};

export default ParticipantsSelected;
