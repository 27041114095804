import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ClassesActive from "./Components/Classes/classes-active";
import Button from "../Components/UI/button";
import TileTabbed from "../Components/UI/tile-tabbed";
import ClassesArchived from "./Components/Classes/classes-archived";
import BOESelector from "./Components/UI/boe-selector";

import { useNavigate } from "react-router-dom";
import { RbacContext } from "../rbac-context";
import { isExternalURL, isCertInstructor, isBusinessAdministrator } from "../helper-functions";

import { PlatformBaseRoutes } from "../Routing/routes";
import useFeatureFlags from "../hooks/useFeatureFlags";
import useLocalStorage from "../hooks/useLocalstorage";

const ClassesPage = () => {
    const { t } = useTranslation();
    const rbac = React.useContext(RbacContext);
    const navigate = useNavigate();
    const [canCreateAClass] = useFeatureFlags("ITLCreateAClass", true);
    const [canViewLearningHistory] = useFeatureFlags("ITLViewLearningHistory", true);
    const [canViewClassDetails] = useFeatureFlags("ITLViewClassDetails", true);
    const [shouldBOESelectorShow] = useFeatureFlags("ITLAltBOESelector", true);
    const [activeTab, setActiveTab] = React.useState(0);
    const [storedValue, setValue] = useLocalStorage('selectedOrganization', null);
    const isCI = isCertInstructor(rbac.userContext);
    const isBA = isBusinessAdministrator(rbac.userContext);
    const shouldShowBOE = rbac.userContext.AltOrganizationsList?.length > 1 && (isCI || isBA) && shouldBOESelectorShow;

    const handleNavigation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isExternalURL(PlatformBaseRoutes.DashboardClassCreate.fullPath)) {
            event.preventDefault();
            navigate(PlatformBaseRoutes.DashboardClassCreate.fullPath);
        }
    };

    return (
        <>
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <div className="classes-row">
                        <h1 className="page-title-h1 title-margin">{t("Classes")}</h1>

                        {canCreateAClass && (
                            <div onClick={handleNavigation}>
                                <Button
                                    elementId="create-class-button"
                                    label={
                                        <>
                                            <i className="fa-light fa-sharp fa-plus mr-2"/>
                                            {t("Create Class")}
                                        </>
                                    }
                                    isSolid
                                />
                            </div>
                        )}
                    </div>
                    {shouldShowBOE && <BOESelector />}
                    <TileTabbed
                        onSelectTab={setActiveTab}
                        activeTab={activeTab}
                        tabs={[
                            {
                                title: t("Active"),
                                content: (
                                    <ClassesActive
                                        canViewLearningHistory={canViewLearningHistory}
                                        canCreateAClass={canCreateAClass}
                                        canViewClassDetails={canViewClassDetails}
                                    />
                                )
                            },
                            {
                                title: t("Archived"),
                                content: (
                                    <ClassesArchived
                                        canViewLearningHistory={canViewLearningHistory}
                                        canViewClassDetails={canViewClassDetails}
                                    />
                                )
                            }
                        ]}
                    />
                </Container>
            </div>
        </>
    );
};

export default ClassesPage;
