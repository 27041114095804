import * as React from "react";
import { ConfigContext } from "../../../configuration-context";
import TextArea from "../../../Components/Form/text-area";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";
import { fetchClassNotes, createClassNote } from "../../Utils/class";
import { validate, validateNote } from "../../../FormValidationRules/createClassNote";
import { ClassNote } from "../../../Interfaces/Platform/Classes";
import useForm from "../../../hooks/useForm";
import { RbacContext } from "../../../rbac-context";

export interface Props {
    classId: string;
    onClose: () => void;
}

const ClassNotes = (props: Props): JSX.Element => {

    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const rbacContext = React.useContext(RbacContext);
    const [classNotes, setClassNotes] = React.useState<ClassNote[]>([]);
    const [validateExistingNote, setValidateExistingNote] = React.useState<boolean>(false);

    const handleCreateNote = async () => {
        const classNoteId = classNotes && classNotes.length > 0 ? classNotes[0].classNoteId : null;
        try {
            const status = await createClassNote(apimBaseUrl, configContext, {
                classId: props.classId,
                userId: rbacContext.userContext.PingId,
                firstName: rbacContext.userContext.FirstName,
                lastName: rbacContext.userContext.LastName,
                notes: [
                    {
                        note: formFieldData.note,
                        deleted: false,
                        classNoteId
                    }
                ]
            });
            if (status === 202) props.onClose();
        } catch (e) {
            console.error(e);
        }
    };

    const {
        formFieldData,
        errors,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit,
    } = useForm(handleCreateNote, validateExistingNote ? validateNote : validate);

    React.useEffect(() => {
        if (classNotes && classNotes.length > 0) {
            handleChangeByGetEvent({ note: classNotes[0].note });
        }
    }, [classNotes]);

    const getClassNotes = async () => {
        try {
            const response = await fetchClassNotes(apimBaseUrl, configContext, props.classId);
            setValidateExistingNote(response.status === 200);
            setClassNotes(response.data);
        } catch (e) {

        } finally {
        }
    };

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) getClassNotes();
    }, [configContext?.SystemConfiguration?.ApimKey]);

    return (
        <>
            <div className="class-notes-content">
                <TextArea
                    additionalClass="class-notes-content-text-area"
                    defaultRows={8}
                    errorLabel={errors.note}
                    isError={errors.hasOwnProperty("note")}
                    showRequired={false}
                    isRequired
                    label="Notes"
                    id="note"
                    name="note"
                    value={formFieldData.note}
                    changeAction={handleChangeByUserEvent}
                />
                {/*classNotes && classNotes.length > 0 && classNotes.map((note) => (
                    <div className="class-note mt-3">
                        <div className="class-note-field">{new Date(note.dateCreated).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}</div>
                        <div className="class-note-text">{note.note}</div>
                        <div className="class-note-author">{note.firstName} {note.lastName}</div>
                        <div className="class-note-field class-note-actions">
                            <i className="fa-sharp fa-light fa-pen-to-square cursor-pointer" />
                            <i className="fa-sharp fa-light fa-trash cursor-pointer" />
                        </div>
                    </div>
                ))*/}
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton clickAction={() => props.onClose()} label={<><i className="me-1 far fa-times"/> Cancel</>} />
                    <Button elementId="save-notes-button" isSolid label="Save Notes" clickAction={handleSubmit} />
                </div>
            </div>
        </>
    );

};

export default ClassNotes;
