import * as React from "react";

const EmptyContent = (props: {
    title: string,
    subtitle?: string,
    primary?: boolean,
    button?: React.ReactElement,
    icon?: React.ReactElement,
}): JSX.Element => {

    return (
        <div className="empty-content-container">
            {props.icon}
            <div className="d-flex flex-column align-items-center">
                {props.primary ? (
                    <p className="fw600 fs24">{props.title}</p>
                ) : (
                    <h2 className="fw600">{props.title}</h2>
                )}
                {props.subtitle && (
                    props.primary ? (
                        <p className="fs16">{props.subtitle}</p>
                    ) : (
                        <h5 className="mb-4">{props.subtitle}</h5>
                    )
                )}
                {props.button}
            </div>
        </div>
    );
};

export default EmptyContent;
