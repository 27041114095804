
import * as React from "react";

import { formatDate, formatNumber } from "../locale-utils";

export interface Renderer {
    parentClassName?: string;
    renderer?: (row: object) => JSX.Element;
    expanded?: string[] | number[];
    onExpand?: Function;
}

export interface DefaultSortedField {
    dataField: string;
    order: "desc" | "asc";
}

export interface Column {
    dataField: string;
    text: string;
    footer?: string;
    footerStyle?: object;
    sort?: boolean;
    searchable?: boolean;
    sortValue?: (cell: string | number | boolean | Date, row: any) => string | number | boolean | Date;
    filterValue?: (cell: string | number | boolean | Date, row: any) => string | number | boolean | Date;
    formatter?: (cell: string | number | boolean | Date, row: any) => JSX.Element | string | number | boolean | Date;
    headerStyle?: (column: Column, index: number) => React.CSSProperties;
    style?: React.CSSProperties;
    classes?: string;
    headerClasses?: string;
}

/**
 * Create a default sortable date column
 * @param dataField
 * @param text
 */
export function dateColumn(dataField: string, text: string, headerStyle?: React.CSSProperties): Column {
    return {
        dataField: dataField,
        text: text,
        headerStyle: () => headerStyle,
        sort: true,
        searchable: false,
        classes: "text-truncate align-middle",
        sortValue: (cell: Date) => cell,
        formatter: (cell: Date) => formatDate(cell)
    };
}

/**
 * Create a default searchable/sortable formatted text column
 * @param dataField
 * @param text
 */
export function textColumn(dataField: string, text: string, headerStyle?: React.CSSProperties): Column {
    return {
        dataField: dataField,
        text: text,
        headerStyle: () => headerStyle,
        sort: true,
        searchable: true,
        classes: "text-truncate align-middle"
    };
}

/**
 * Create a default sortable formatted number column
 * @param dataField
 * @param text
 */
export function numberColumn(dataField: string, text: string, headerStyle?: React.CSSProperties): Column {
    return {
        dataField: dataField,
        text: text,
        headerStyle: () => headerStyle,
        sort: true,
        searchable: false,
        classes: "text-truncate align-middle",
        sortValue: (cell: number) => cell,
        formatter: (cell: number) => formatNumber(cell)
    };
}

