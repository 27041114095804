import * as React from "react";

import { IParticipant } from "../../../Interfaces/Platform/Participants";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";

const ParticipantList = (
    props: { listItems: React.ReactElement<IParticipant>[] }
): JSX.Element => {
    const { isMobile } = useDetectWindowSize();
    const length = props.listItems.length;

    const getClassNames = (index: number) => {
        if (length > 1) {
            if (index === 0) return "first-participant-listing";
            if (index === length - 1) return "last-participant-listing";
            return "inner-participant-listing";
        }
        return "unique-participant-listing";
    };

    const returnListing = (): any => props.listItems.map((participantListing, index: number) => {
        return !isMobile ? (
            <tr className={`participant-list-container ${getClassNames(index)} ${participantListing.props.isChecked ? 'participant-list-checked' : 'participant-list-unchecked'}`}>
                {participantListing}
            </tr>
        ) : (
            <div className={`mobile-custom-table participant-list-container ${getClassNames(index)} ${participantListing.props.isChecked ? 'participant-list-checked' : 'participant-list-unchecked'}`}>
                {participantListing}
            </div>
        );
    });

    return returnListing();

};

export default ParticipantList;
