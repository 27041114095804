import * as React from "react";
import { BadgeDisplayStyle } from "../../Interfaces/Platform/BadgeDisplayStyle";

const CIIDBadge = (props: {
    id: string,
    style: BadgeDisplayStyle
}): JSX.Element => {
    const style = props.style.style;

    return (
        <>
            <div className={style + " id-label"}>ID: {props.id}</div>
        </>
    );
};

export default CIIDBadge;
