import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const DownloadResources = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={true}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('WriteTrainingActivities-DownloadResources-Title') }</h1>
                                <p className="mw-900 mt-3">{ t('WriteTrainingActivities-DownloadResources-Intro') }</p>
                                <Tile title="Resources" hideTileHeader={true}>
                                
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <ThreeColumnLayout
                        left={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="mb-5 mb-lg-0">
                                <a href="https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf" target="_new"><img width="200px" src="https://cdn.crisisprevention.com/media/training-center/images/activity-writing-guide.png" alt="Activity Writing Guide"></img></a>
                                <ArrowLink data={{ label:  t('BTN-Download-Activity-Writing-Guide') , href: "https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf", openInNewTab: true  }} />
                                </div>
                            </>
                        }

                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="mb-5 mb-lg-0">
                                <a href="https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity_Template.pdf" target="_new"><img width="185px" src="https://cdn.crisisprevention.com/media/training-center/images/activity-template.png" alt="Activity Template"></img></a>
                                <ArrowLink data={{ label:  t('BTN-Download-Activity-Template') , href: "https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity_Template.pdf", openInNewTab: true  }} />
                                </div>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="mb-5 mb-lg-0">
                                <h3 className="sub-headings-h3">{ t('WriteTrainingActivities-ShareTitle') }</h3>
                                <p>{ t('WriteTrainingActivities-ShareContent') }</p>
                                <Button label={ t('BTN-Share-Your-Activity') } isSolid={false} elementId="btn-shareactivity" href="https://forms.office.com/pages/responsepage.aspx?id=bY2VPBeI4kOjoBeoPNhBx4Ka-ygXCjJNmxt_DEABGg9UNjZGR1JCQVpGSUtSUEY1VFRQR1pFU1dYSSQlQCN0PWcu" />
                                </div>
                            </>
                        }
                    />
                        
                        </>      
                    </Tile>


                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default DownloadResources;
