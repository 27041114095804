import i18n from "i18next";
import useEmailRegex from "../hooks/useEmailRegex";
import { DEFAULT_MAX_CHARS } from "./commonValues";

export default function validate(values:any) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors: any = {};

    // Ensure first name exists, is not an email, and is not too long
    if (!values.firstName) {
        errors.firstName = i18n.t("Validate-First-Name");
    } else if (containsEmail(values.firstName)) {
        errors.firstName = i18n.t("BulkUpload.FirstNameIsEmail");
    } else if (values.firstName && values.firstName.length > DEFAULT_MAX_CHARS) {
        errors.firstName = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    // Ensure last name exists, is not an email, and is not too long
    if (!values.lastName) {
        errors.lastName = i18n.t("Validate-Last-Name");
    } else if (containsEmail(values.lastName)) {
        errors.lastName = i18n.t("BulkUpload.LastNameIsEmail");
    } else if (values.lastName && values.lastName.length > DEFAULT_MAX_CHARS) {
        errors.lastName = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    if (values.email && !isEmail(values.email)) {
        errors.email = i18n.t("Validate-Email-Address");
    }

    if (values.email && values.email.length > 150) {
        errors.email = i18n.t("Exceeded-Max-Limit", { length: 150 });
    }

    if ((!values.organizationId || values.organizationId === null) || (!values.organizationName || values.organizationName === null)) {
        errors.organizationId = i18n.t("Validate-Organization-Select");
    }

    if (values.externalId && values.externalId.length > 60) {
        errors.externalId = i18n.t("Exceeded-Max-Limit", { length: 60 });
    }

    return errors;
}