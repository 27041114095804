import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Form, Col, Row } from "react-bootstrap";

import { RbacContext } from "../../rbac-context";
import { resetPassword } from "../../ApiServices/Login";
import { useTranslation } from "react-i18next";
import UpdatePassword from "../../Components/update-password"

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface ResetPasswordFormProps {
    email: string;
    token: string;
    buttonText: string;
    activeButtonText: string;
    onResetting: () => void;
    onReset: (resp: JsonResponseModel<ApiResponse>) => void;
}

const ResetPasswordForm = ({
    email,
    token,
    buttonText,
    activeButtonText,
    onResetting,
    onReset
}: ResetPasswordFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const { reloadContext } = React.useContext(RbacContext);
    const [password, setPassword] = React.useState("");
    const [passwordValidated, setPasswordValidated] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [resetting, setResetting] = React.useState(false);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() && passwordValidated && password === confirmPassword) {
            try {
                setResetting(true);
                onResetting();
                const resp = await resetPassword(token, password);

                if (resp.Error) {
                    setResetting(false);
                } else {
                    await reloadContext();
                }

                onReset(resp);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }           
        } 
    };

    return (
        <Form noValidate onSubmit={onSubmit} className="brand-form">
            <Row>
                <Col md="6">
                    <Form.Group controlId="email">
                        <Form.Label>{t("Email")}</Form.Label>
                        <Form.Control type="email" disabled value={email} />
                    </Form.Group>
                </Col>
            </Row>
            <UpdatePassword
                newPassword={password}
                setNewPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                confirmPasswordLabel={t("Confirm Password")}
                passwordIsValid={passwordValidated}
                setPasswordIsValid={setPasswordValidated}
                showExistingPassword={false}
                reqStyle={{marginTop: "-55px"}}
            />
            <Row>
                <Col md="6">
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" type="submit" disabled={resetting}>
                            {resetting ? (
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> {activeButtonText}
                                </>
                            ) : (
                                buttonText
                            )}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default withAITracking(reactPlugin, ResetPasswordForm);
