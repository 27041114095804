import * as React from "react";

import { MyTrainingBaseRoutes } from "./routes";
import Page from "../Pages/page";
import { CpiRoute } from "./routes";
import ProtectedRoute from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { RoleEnum, TrainingRoles } from "../Enums";
import NoAccess from "../Pages/no-access";
import CertificationAgreement from "../Pages/CertAgreement/certification-agreement";

import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import CertificationHistory from "../Pages/TrainingCenter/certification-history";
import Community from "../Pages/TrainingCenter/community";
import Membership from "../Pages/TrainingCenter/membership";
import MyOnlineCourses from "../Pages/TrainingCenter/my-online-courses";
import useFeatureFlags from "../hooks/useFeatureFlags";
import ProfessionalEnrichment from "../Pages/TrainingCenter/professional-enrichment";

import { RbacContext } from "../rbac-context";
import { ConfigContext } from "../configuration-context";

import Redirect from "./redirect";

const TrainingCenterRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (

    <ProtectedRoute {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData:any,
    rac:any,
    canAccessMyOnlineLearning:boolean
) => {
    const tempArray:CpiRoute[] = [];
    let rbac = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);

    for (const route in routesData) {
        tempArray.push(MyTrainingBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route:any, i:number) => (
            // map over CpiRoute[]
            (rac(route)) ?
            <>
                // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                <Route key={i} path={route.path}
                    element={
                        <TrainingCenterRoute>

                                <Redirect baseUrl={configContext.SystemConfiguration[route.tempBaseURLConfigKey]} path={route.tempPathURL}>
                       
                                    <div>
                                        {(route.pageId == "myOnlineCourses" && canAccessMyOnlineLearning) &&
                                            <MyOnlineCourses />
                                        }

                                        {(route.pageId == "myOnlineCourses" && !canAccessMyOnlineLearning) &&
                                            <NoAccess />
                                        }
                                        
                                        {route.pageId == "certificationHistory" &&
                                            <CertificationHistory />
                                        }

                                        { route.pageId == "membership" &&
                                            <Membership />
                                        }

                                        { route.pageId == "professionalEnrichment" &&
                                            <ProfessionalEnrichment/>
                                        }

                                        { route.pageId == "community" &&
                                            <Community />
                                        }
                                    </div>

                                </Redirect>
                       
                        </TrainingCenterRoute>
                    }
                />
                <Route path="*"
                    element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                    }
                />
            </>
            :

            (!rbac.userContext.AgreementCriteriaMet) ?
            
            <Route key={i} path={route.path}
                element={
                    <ProtectedRoute>
                        <Redirect baseUrl={configContext.SystemConfiguration["KenticoSite"]} path={"/Platform/Dashboard"}>

                            <>
                                {<CertificationAgreement />}
                            </>
                    
                        </Redirect>
                    </ProtectedRoute>
                }
            />

            :

            // we do not meet the criteria for culture and should render no-access content
            <Route key={i} path={route.path}
                element={
                    <ProtectedRoute>
                     
                            <>
                                { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */ }
                                { <NoAccess /> }
                            </>
                   
                    </ProtectedRoute>
                }
            />               
            ))}
        </>
    );
};

const TrainingRouting = () => {
    const { rac } = useRouteAccessCheck();

    const [canAccessMyOnlineLearning] = useFeatureFlags('ITLMyOnlineLearning', true);

    return (
        <Routes>           
            { createRoutes(MyTrainingBaseRoutes, rac, canAccessMyOnlineLearning) }
        </Routes>
    );
};

export default withAITracking(reactPlugin, TrainingRouting);
