import * as React from "react";
import { t } from "i18next";
import CPILockup from "../../layout/Header/cpi-lockup";

import LoadingContent from "./loading-content";

const LoadingDisplay = (): JSX.Element => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            
            <header className="site-header">
                <div className="main-navigation">
                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-wrapper">                            
                            <CPILockup />
                            <div className="navbar-buttons d-flex justify-content-end"></div>
                        </div>
                    </nav>
                </div>
                <div className="header-bar"></div>
            </header>

            <LoadingContent 
                title={t("Loading")} 
                icon={
                    <div className="loading-content-participants" style={{ fontSize: '3em' }}>
                        <i className="fas fa-duotone fa-spinner icon" />
                    </div>
                } 
            />
        </div>
    );
};

export default LoadingDisplay;