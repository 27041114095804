import * as React from "react";
import NestedCheckboxes from "../../../Components/Form/checkbox/nested-checkboxes";
import { ClassSkillModel } from "../../../Interfaces/ClassSkillModel";
import { publish, subscribe, unsubscribe } from "../../Utils/events";
import { useTranslation } from "react-i18next";

const PhysicalSkills = (props: {
    data: ClassSkillModel | undefined,
    isReadOnly?: boolean,
}): JSX.Element => {
    const [categories, setCategories] = React.useState<any[]>([]);
    const { t } = useTranslation();

    let currentSubcategoryId = 1;

    const handleSelectAll = (category: string) => {
        const categoryToAssign = categories.find(cat => cat.name === category);
        publish("handleSelectAllCategory", { category, allSelected: !categoryToAssign.allSelected });
        
        const tempCategories = categories.map((cat) => {
            if (cat.name === category) {
                cat.allSelected = !categoryToAssign.allSelected;
            }
            return cat;
        });

        setCategories(tempCategories);
    }

    const handleCheckboxSelectedTrue = (e: CustomEvent) => {
        const tempCategories = categories.map((category) => {
            if (e.detail === category.name) {
                category.allSelected = true;
            }
            return category;
        })

        setCategories(tempCategories);
    }

    React.useEffect(() => {
        const tempCategories: any[] = [];
        props.data?.categories.forEach((category) => {
            const tempCategory: any = {
                name: category.category,
                allSelected: category.allSelected
            }

            tempCategories.push(tempCategory);
        });
        
        setCategories(tempCategories);
    }, [props.data?.categories]);

    React.useEffect(() => {
        subscribe("checkboxSelectedTrue", handleCheckboxSelectedTrue);

        return () => {
            unsubscribe("checkboxSelectedTrue", handleCheckboxSelectedTrue);
        }
    }, [categories]);

    return (
        <div className="my-5">
            <p className="text-uppercase semi-bold fs14">{t("Participants-Learn-Physical-Skills")}</p>
            <div className="class-create-skills px-3 pt-3 pb-4">
                {
                    props.data?.categories.map((category) => (
                        <>
                            <p className="bold mb-2 text-capitalize">{t("Skills." + category.category)}</p>
                            {/*!props.isReadOnly && <a href="javascript:;" onClick={() => handleSelectAll(category.category)}>{t("Select All / Deselect All")}</a>*/}
                            <div className="container-fluid mt-2 mb-3">
                                <div className="row">
                                {
                                    category.subcategories.map((subcategory, index: number) => {
                                        return (
                                            <div className={`col-sm-5 mb-3 px-4 ${index % 2 === 0 ? 'class-create-skills-colRight' : 'class-create-skills-colLeft'}`}>
                                                <NestedCheckboxes
                                                    subcategory={subcategory}
                                                    isReadOnly={props.isReadOnly}
                                                    currentCategoryName={category.category}
                                                    currentSubcategoryId={currentSubcategoryId++} />
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        </div>
    );
};

export default PhysicalSkills;
