import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { ClassInfoModel, ClassModel, EnrollmentListItem } from "../../Interfaces";

import MoveLearnerModal from "./move-learner-modal";
import { RbacContext } from "../../rbac-context";
import ResendEmailModal from "./resend-email-modal";
import { StatusEnum } from "../../Enums";
import UnenrollModal from "./unenroll-modal";
import { downloadEnrolledLearners, fetchClassRoster } from "../../ApiServices/Class";
import { formatDate } from "../../locale-utils";
import moment from "moment";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";

export interface ClassRosterProps {
    classInfo: ClassInfoModel;
    classes: ClassModel[];
    onSave: (resp: JsonResponseModel<ApiResponse>, refresh?: boolean) => void;
    forceRefresh?: boolean;
    setClassRosterCount: (count: number) => void;
}

const ClassRoster = ({ classInfo, classes, onSave, forceRefresh, setClassRosterCount }: ClassRosterProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [showMoveModal, setShowMoveModal] = React.useState(false);
    const [showUnenrollModal, setShowUnenrollModal] = React.useState(false);
    const [showResendEmailModal, setShowResendEmailModal] = React.useState(false);
    const [selectedEnrollment, setSelectedEnrollment] = React.useState<EnrollmentListItem>(null);
    const [downloading, setDownloading] = React.useState(false);

    const filteredClasses = React.useMemo<ClassModel[]>(
        () => classes?.filter((_class) => _class.ClassId !== classInfo.ClassId) ?? [],
        [classes]
    );

    const statusFormatter = (cell: StatusEnum, row: EnrollmentListItem) => {
        switch (row.Status) {
            case StatusEnum.InProgress:
                return (
                    <>
                        <i className="me-2 fas fa-circle text-warning"></i>
                        <span className="alert-warning bg-none">{t("In Progress")}</span>
                    </>
                );
            case StatusEnum.Completed:
                return (
                    <>
                        <i className="me-2 fas fa-check text-success"></i>
                        <span className="alert-success bg-none">{t("Completed")}</span>
                        {row.CompletionDate && (
                            <>
                                <br />
                                <small className="ms-4 text-dark">{formatDate(row.CompletionDate)}</small>
                            </>
                        )}
                    </>
                );
            case StatusEnum.NotStarted:
            default:
                return (
                    <>
                        <i className="me-2 far fa-circle text-gray-700"></i>
                        <span className="text-gray-600">{t("Not Started")}</span>
                    </>
                );
        }
    };

    const timeSpentFormatter = (cell: number, row: EnrollmentListItem) => {
        if (cell === 0) {
            return t("Not Available");
        }

        return moment.duration(cell, "seconds").format(`d [${t("dy")}] h [${t("hr")}] m [${t("min")}] s [${t("sec")}]`);
    };

    const actionsFormatter = (cell: any, row: EnrollmentListItem) => {
        if (classInfo.IsManaged || row.Status != StatusEnum.Completed) {
            return (
                <ButtonGroup className="w-100" size="sm">
                    <Button
                        variant="outline-primary"
                        className="w-100"
                        aria-label={`${t("Remove")} ${row.EmailAddress}`}
                        onClick={() => {
                            setSelectedEnrollment(row);
                            setShowUnenrollModal(true);
                        }}
                    >
                        {t("Remove")}
                    </Button>
                    <DropdownButton title="" variant="outline-primary" as={ButtonGroup}>
                        {filteredClasses.length > 0 && (
                            <span>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => {
                                        setSelectedEnrollment(row);
                                        setShowMoveModal(true);
                                    }}
                                >
                                    {t("Move")}
                                </Dropdown.Item>
                            </span>
                        )}
                        <span>
                            <Dropdown.Item
                                eventKey={filteredClasses.length > 0 ? "2" : "1"}
                                onClick={() => {
                                    setSelectedEnrollment(row);
                                    setShowResendEmailModal(true);
                                }}
                            >
                                {t("Resend Enrollment Email")}
                            </Dropdown.Item>
                        </span>
                    </DropdownButton>
                </ButtonGroup>
            );
        }

        if (filteredClasses.length > 0) {
            return (
                <Button
                    variant="outline-primary"
                    className="w-100"
                    onClick={() => {
                        setSelectedEnrollment(row);
                        setShowMoveModal(true);
                    }}
                >
                    {t("Move")}
                </Button>
            );
        }

        return "";
    };

    const columnHelper = createColumnHelper<EnrollmentListItem>()

    const columns = React.useMemo<ColumnDef<EnrollmentListItem>[]>(
        () => [
            columnHelper.accessor('Status', {
                cell: info => statusFormatter(info.getValue(), info.row.original),
                header: t("Status"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('EmailAddress', {
                cell: info => info.getValue(),
                header: t("Email"),
                enableSorting: true,
                size: 15,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('LastName', {
                cell: info => info.getValue(),
                header: t("Last Name"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('FirstName', {
                cell: info => info.getValue(),
                header: t("First Name"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('Progress', {
                cell: (info) => `${info.getValue().CurrentLesson}/${info.getValue().TotalLessons}`,
                header: t("Progress"),
                id: "CompletedLessonCount",
                enableSorting: true,
                meta: { bodyClass: "align-middle" }
            }),
            columnHelper.accessor('TimeSpent', {
                cell: info => timeSpentFormatter(info.getValue(), info.row.original),
                header: t("Time Spent"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('LastLogin', {
                cell: info => formatDate(info.getValue()),
                header: t("Last Login"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
        ],
        [forceRefresh]
    );

    const downloadClassRoster = async () => {
        try {
            setDownloading(true);
            const resp = await downloadEnrolledLearners(classInfo.ClassId);

            if (!resp) {
                onSave(
                    {
                        Error: {
                            Code: 500,
                            MessageCode: 0,
                            Message: t("Failed to download class roster, please try again")
                        }
                    },
                    false
                );
            }

            saveAs(resp, `${classInfo.Name}_${classInfo.CourseName}.csv`);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
            onSave(
                {
                    Error: {
                        Code: 500,
                        MessageCode: 0,
                        Message: t("An unknown error occurred, please try again")
                    }
                },
                false
            );
        }
        setDownloading(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>{t("Here are all the learners in {{name}}", { name: classInfo.Name })}</h4>
                <div>
                    <Button variant="primary" disabled={downloading} onClick={downloadClassRoster}>
                        {!downloading ? (
                            <>
                                <i className="fas fa-download"></i> {t("Download Class Roster")}
                            </>
                        ) : (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Downloading")}
                            </>
                        )}
                    </Button>
                </div>
            </div>
            <div className="table-container mt-3">            
                <RemoteTable
                    keyField="LearnerCourseId"
                    fetchFunction={fetchClassRoster}
                    fetchParameters={[classInfo.ClassId]}
                    queryKey="getClassRoster"
                    columns={columns}
                    defaultSorted={{ dataField: "LastName", order: "asc" }}
                    forceRefresh={forceRefresh}
                    setTotalCountForParentComponent={setClassRosterCount}
                />
            </div>
            {showMoveModal && (
                <MoveLearnerModal
                    show={showMoveModal}
                    classId={classInfo.ClassId}
                    _className={classInfo.Name}
                    enrollment={selectedEnrollment}
                    classes={filteredClasses}
                    onSave={(resp: JsonResponseModel<ApiResponse>) => {
                        setShowMoveModal(false);
                        onSave(resp);
                    }}
                    handleCancel={() => setShowMoveModal(false)}
                />
            )}
            {showUnenrollModal && (
                <UnenrollModal
                    show={showUnenrollModal}
                    _className={classInfo.Name}
                    learnerCourseId={selectedEnrollment.LearnerCourseId}
                    learnerName={`${selectedEnrollment.FirstName} ${selectedEnrollment.LastName}`}
                    learnerEmailAddress={selectedEnrollment.EmailAddress}
                    hasReturnableSeat={selectedEnrollment.HasValidInvoiceToReturn}
                    isEventEnrollment={classInfo.IsManaged}
                    handleSaveComplete={(resp: JsonResponseModel<ApiResponse>) => {
                        setShowUnenrollModal(false);
                        onSave(resp);
                    }}
                    onHide={() => setShowUnenrollModal(false)}
                />
            )}
            {showResendEmailModal && (
                <ResendEmailModal
                    show={showResendEmailModal}
                    classId={classInfo.ClassId}
                    enrollment={selectedEnrollment}
                    handleCancel={() => setShowResendEmailModal(false)}
                    onSave={(resp: JsonResponseModel<ApiResponse>, refresh: boolean) => {
                        setShowResendEmailModal(false);
                        onSave(resp, refresh);
                    }}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, ClassRoster);
