import { getToken } from "../../helper-functions";

export const fetchEnrollment = async(apimBaseUrl: string, configContext: any, enrollmentId: string): Promise<any> => {
    try {
        const response = await fetch(apimBaseUrl + `/q/enrollment/${enrollmentId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}
