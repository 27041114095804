import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const EightByFour = (props: {
    left: JSX.Element,
    right: JSX.Element
}): JSX.Element => {

    return (
        <Container fluid>
            <Row className="mobile-padding-container">
                <Col className="pl-0" xs={12} lg={8}>
                    {props.left}
                </Col>

                <Col className="pr-0" xs={12} lg={4}>
                    {props.right}
                </Col>
            </Row>
        </Container>
    );

};

export default EightByFour;
