import * as React from "react";

import CertificatePage from "../Pages/certificate";
import CoursePage from "../Pages/course";
import { CpiRoute, LearnerBaseRoutes } from "../Routing/routes";
import MyCoursesPage from "../Pages/my-courses";
import MyLearningHistoryPage from "../Pages/my-learning-history";
import Page from "../Pages/page";
import ProtectedRoute, { ProtectedRouteProps } from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { RoleEnum, LearnerEnrolleeRoles } from "../Enums";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import NoAccess from "../Pages/no-access";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

const LearnerRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (

    <ProtectedRoute roles={LearnerEnrolleeRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData:any,
    rac:any
) => {
    let tempArray:CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(LearnerBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route:any, i:number) => (
            // map over CpiRoute[]
            (rac(route)) ?
            <>
                // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                <Route key={i} path={route.path}
                    element={
                        <LearnerRoute>
                         
                                <div>
                                    { route.pageId == "learn" &&
                                        <MyCoursesPage />
                                    }

                                    { route.pageId == "learnCertificate" &&
                                        <CertificatePage />
                                    }

                                    { route.pageId == "learnCourse" &&
                                        <CoursePage />
                                    }

                                    { route.pageId == "learnHistory" &&
                                        <MyLearningHistoryPage />
                                    }
                                </div>
                    
                        </LearnerRoute>
                    }
                />

                <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                    }
                />
            </>
            :
                // we do not meet the criteria for culture and should render no-access content
                <Route key={i} path={route.path}
                    element={
                        <ProtectedRoute>
                          
                                <>
                                    { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */ }
                                    { <NoAccess /> }
                                </>
                        
                        </ProtectedRoute>
                    }
                />               
            ))}
        </>
    );
};

const LearnerRouting = () => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>           
            { createRoutes(LearnerBaseRoutes, rac) }
        </Routes>
    );
};

export default withAITracking(reactPlugin, LearnerRouting);