import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { Learner, UpdateLearnerModel } from "../../Interfaces";

import { EMAIL_REGEX_PATTERN } from "../../constants";
import { RbacContext } from "../../rbac-context";
import { updateLearner } from "../../ApiServices/Learner";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface LearnerEditFormProps {
    isVisible: boolean;
    learner: Learner;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
    onHide: () => void;
}

const LearnerEditForm = ({ isVisible, learner, onSave, onHide }: LearnerEditFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [learnerUpdate, setLearnerUpdate] = React.useState<UpdateLearnerModel>({
        ...learner,
        OldEmailAddress: learner.EmailAddress,
        NewEmailAddress: learner.EmailAddress,
        PingUserId: learner.PingUserId
    });
    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        if (form.checkValidity()) {
            setSaving(true);
            try {
                const resp = await updateLearner(learnerUpdate);
                if (resp.Data) {
                    resp.Data.Message = t("Successfully updated {{firstName}} {{lastName}}", {
                        firstName: learnerUpdate.FirstName,
                        lastName: learnerUpdate.LastName
                    });
                }

                onSave(resp);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                onSave({
                    Error: {
                        Code: null,
                        MessageCode: 0,
                        Message: t("An unexpected error occurred, please try again.")
                    }
                });
            }
        }
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Learner Details")}
                    <h6>{learner.PrimaryOrganizationName}</h6>
                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Container>
                        <Form.Group controlId="emailAddress">
                            <Form.Label>{t("Email")}</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                maxLength={150}
                                pattern={EMAIL_REGEX_PATTERN}
                                value={learnerUpdate.NewEmailAddress}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setLearnerUpdate({
                                        ...learnerUpdate,
                                        NewEmailAddress: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>{t("First Name")}</Form.Label>
                            <Form.Control
                                required
                                maxLength={50}
                                value={learnerUpdate.FirstName}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setLearnerUpdate({
                                        ...learnerUpdate,
                                        FirstName: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>{t("Last Name")}</Form.Label>
                            <Form.Control
                                required
                                maxLength={50}
                                value={learnerUpdate.LastName}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setLearnerUpdate({
                                        ...learnerUpdate,
                                        LastName: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, LearnerEditForm);
