import * as React from "react";

import { Container } from "react-bootstrap";

import CourseLanguageSettings from "./Components/course-language";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const CourseLanguagesPage = () => {
    const { t } = useTranslation();
    const { toast } = useToast();

    return <CourseLanguageSettings toast={toast} />
};

export default withAITracking(reactPlugin, CourseLanguagesPage);
