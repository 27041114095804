import * as React from "react";
import ReactSelect from "react-select";
import TextInput from "./text-input";
import { KeyboardEvent } from "react";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";

const Select = (props: {
    label: string,
    name: string,
    id: string,
    options: FormSelectOption[],
    defaultValue?: boolean,
    isRequired?: boolean,
    isError?: boolean,
    errorLabel?: string,
    errorPosition?: string,
    value?: string,
    isReadOnly?: boolean,
    showLabel?: boolean,
    filter?: boolean,
    additionalClass?: string,
    iconBeforeTextClass?: string,
    isDisabled?: boolean;
    iconBeforeOptionClass?: Function,
    changeAction?: React.ChangeEventHandler<HTMLSelectElement>,
    changeFilterAction?: (o: FormSelectOption) => void,
}): JSX.Element => {
    const defaultProps = {
        filter: false,
        showLabel: true,
        defaultValue: false,
        errorPosition: "",
        additionalClass: "",
        iconBeforeTextClass: "",
        isReadOnly: false,
        iconBeforeOptionClass: () => {}
    };
    props = { ...defaultProps, ...props };

    const errorLabel = props.errorLabel ? props.errorLabel : "Default Alert Text";
    const fieldErrorBorderClass = props.isError ? " filter-select field-error" : "";

    const returnSelectOptions = (options:FormSelectOption[]) => {
        return (
            options.map((option) => (
                <option className={`${option.disabled ? 'disabled-option' : ''}`} disabled={option.disabled} selected={option.default} hidden={option.hidden} value={option.value}>{option.label}</option>
            ))
        )
    }

    if (props.filter) {
        return (
            <>        
                <div>
                    {props.showLabel && (
                        <>
                            <label htmlFor={props.id} className="bold form-label fs14 filter-select-label">{props.label}</label>
                            { !props.isReadOnly && (props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span>) }
                        </>
                    )}

                    {!props.isReadOnly ? (
                        <ReactSelect
                            value={props.options.filter((option: FormSelectOption) => (option) ? (option.value === props.value) : false)[0] || null}
                            onChange={props.changeFilterAction}
                            isSearchable={false}
                            isDisabled={props.isDisabled}
                            key="select-filter"
                            placeholder="Select"
                            classNames={{
                                container: () => props.additionalClass,
                                indicatorSeparator: () => 'filter-select separator',
                                control: () => `${fieldErrorBorderClass} filter-select-control`,
                                singleValue: () => props.iconBeforeTextClass,
                                option: (state) => {
                                    if (state.isSelected) return `generic-filter-menu-option-selected ${props.iconBeforeOptionClass(state.data)}`;
                                    if (state.isFocused) return `generic-filter-menu-option-focused ${props.iconBeforeOptionClass(state.data)}`;
                                    return props.iconBeforeOptionClass(state.data);
                                },
                            }}
                            id={props.id}
                            name={props.name}
                            options={props.options}
                        />
                    ) : (
                        <div>{props.options.filter((option: FormSelectOption) => option.value === props.value)[0]?.label}</div>
                    )}

                    { props.isError ? <span className={`field-validation form-field-error small-error ${props.errorPosition}`} id="firstName-required-label-1">{errorLabel}</span> : null }
                </div>
            </>
        );
    }

    return (
        <>        
            <div>
                { props.label != "" &&
                    <label htmlFor={props.id} className="bold form-label fs14">{props.label}</label>
                }
                
                { props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> 
                
                :

                (props.label != "") ? <span className="cpi-small optional-tag">(optional)</span> : null
                
                }

                <select value={props.value} onChange={props.changeAction} className={"mt-1 d-block form-select" + fieldErrorBorderClass} id={props.id} name={props.name}>
                    {returnSelectOptions(props.options)}
                </select>

                { props.isError ? <span className={`field-validation form-field-error small-error ${props.errorPosition}`} id="firstName-required-label-1">{errorLabel}</span> : null }
            </div>
        </>
    );
};

export default Select;
