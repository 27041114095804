import * as React from "react";
import KPIBlock from "./kpi-block";
import CIIDBadge from "../../../../Components/Platform/ci-id-badge";
import { ConfigContext } from "../../../../configuration-context";
import { fetchMyProfileInfo } from "../../../../ApiServices/Login";
import { RbacContext } from "../../../../rbac-context";
import { RoleEnum } from "../../../../Enums";

import CiHeaderModel from "../../../../Interfaces/Platform/ApimData/CiHeaderModel";
import { KpiData } from "../../../../Interfaces/Platform/KpiData";
import { getToken, getUserClaims } from "../../../../helper-functions";
import { useTranslation } from "react-i18next";

const CertifiedInstructorHeader = (props: {

}) => {

    const { t } = useTranslation();

    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [RenderKpiBlocks, setRenderKpiBlocks] = React.useState(false);
    const [classesTaught, setClassesTaught] = React.useState<KpiData>({ total: 0, cycle: 0 });
    const [hoursTaught, setHoursTaught] = React.useState<KpiData>({ total: 0, cycle: 0 });
    const [participantsTaught, setParticipantsTaught] = React.useState<KpiData>({ total: 0, cycle: 0 });

    const [firstName, setFirstName] = React.useState<string>((getUserClaims()) ? getUserClaims().firstName : "");
    const [lastName, setLastName] = React.useState<string>((getUserClaims()) ? getUserClaims().lastName : "");

    const [customerId, setCustomerId] = React.useState<string>("");

    React.useEffect(() => {

        setRenderKpiBlocks(kpiBlocksShouldRender(rbac.userContext.RolesByName));

        if (configContext?.SystemConfiguration?.ApimKey && rbac.userContext.PingId) {
            const rolesByName = rbac.userContext.RolesByName;
            if (rolesByName && Array.isArray(rolesByName) && rolesByName.length > 0) {
                if (rolesByName.length == 1 && rolesByName[0] == RoleEnum.Learner) {
                    // User is Learner only
                    fetchMyProfileInfo(getUserClaims().sub).then((data) => {
                        setCustomerId(data?.customerId);
                        setFirstName(data.firstName);
                        setLastName(data.lastName);
                    });
                } else {
                    // User has multiple roles
                    fetch(apimBaseUrl + "/Q/Profile/DashboardHeader/" + rbac.userContext.PingId, {
                        method: "GET",
                        headers: {
                            "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                            "Authorization": `Bearer ${getToken("accessToken")}`
                        }
                    })
                        .then(resp => resp.json())
                        .then(function(data) {
                            setCustomerId(data.customerId);
                            setFirstName(data.firstName ?? getUserClaims()?.firstName);
                            setLastName(data.lastName ?? getUserClaims()?.lastName);
                            fetchCertCycleStats(data);
                        })
                        .catch(function(error) {
        
                        });
                }
            } else {
                // User has no roles
                fetchMyProfileInfo(getUserClaims().sub).then((data) => {
                    setCustomerId(data?.customerId);
                    setFirstName(data.firstName);
                    setLastName(data.lastName);
                }); 
            }
        }         

    }, [configContext?.SystemConfiguration?.ApimKey, rbac.userContext.PingId]);

    const kpiBlocksShouldRender = (rolesByName:string[]) => {

        // if we have more than zero roles, return true, else return false
        // UNLESS we have one role and that role is learner - return false
        if (rolesByName && Array.isArray(rolesByName) && (rolesByName.length > 0)) {
            if ((rolesByName.length == 1) && (rolesByName[0] == RoleEnum.Learner)) { return false }
            else { return true }
        } else {
            return false;
        }

    }

    const fetchCertCycleStats = (headerData:any) => {
        fetch(apimBaseUrl + "/Q/Certification/User/Stats/" + rbac.userContext.PingId, {
            method: "GET",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        })
            .then(resp => resp.json())
            .then(function(data) {
                setClassesTaught({ total: headerData.totalClassesTaught, cycle: data.totalActiveClasses });
                setHoursTaught({ total: headerData.totalHoursTaught, cycle: data.totalActiveHours });
                setParticipantsTaught({ total: headerData.totalParticipantsTaught, cycle: data.totalActiveParticipants });
            })
            .catch(function(error) {

            });   
    }

    return (
        <>
            <div className="section-profile-header">
                <div className="page-content m-auto">
                    <div className="container-fluid">
                        <div className="profile-header-container">
                            <div className="name-container bold">
                                <div>
                                    <h1 className="name-text fs36 break-with-hyphen">{firstName} {lastName}</h1>
                                </div>
                                { customerId && <CIIDBadge id={customerId} style={{ style: "associate" }} /> }
                            </div>
                            
                            <div id="profile-header-highlights-fill">

                                { RenderKpiBlocks &&
                                    <>
                                        <KPIBlock label={t("Classes Taught")} cycleTotal={classesTaught.cycle} iconClass="fa-screen-users" lifetimeTotal={classesTaught.total} />
                                        <KPIBlock label={t("Hours Taught")} cycleTotal={hoursTaught.cycle} iconClass="fa-alarm-clock" lifetimeTotal={hoursTaught.total} />
                                        <KPIBlock label={t("CPI.MyAccount.Dashboard.CertStats.Participants")} cycleTotal={participantsTaught.cycle} iconClass="fa-user" lifetimeTotal={participantsTaught.total} />
                                    </>
                                }                    

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CertifiedInstructorHeader;