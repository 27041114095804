import * as React from "react";

import { Trans, useTranslation } from "react-i18next";
import { getUserClaims } from "../helper-functions";
import useParsedTranslation from "../hooks/useParsedTranslation";
import parseHtml from 'html-react-parser';
import { RbacContext } from "../rbac-context";

import OneColumnLayout from "../Components/UI/UX-Layout/Tiles/one-column-layout";
import EightByFour from "../Components/UI/UX-Layout/Content/eight-by-four";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import useCustomerSupportContactInfo from "../hooks/useCustomerSupportContactInfo";

const ProblemLoading = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const rbac = React.useContext(RbacContext);

    const { rac } = useRouteAccessCheck();    
    const { getSupportContactInfoByCultureCode } = useCustomerSupportContactInfo();

    const [supportContactInfo, setSupportContactInfo] = React.useState(getSupportContactInfoByCultureCode((getUserClaims() ? getUserClaims().culture : 'en-US')));
    
    return (        
        <>
            <OneColumnLayout
                center={
                    <EightByFour
                        left={
                            <>
                                <h1 className="page-title-h1 mb-3">{ t('ProblemLoading.Header') }</h1>
                                <p>{ t('ProblemLoading.Working') }</p>

                                <h2 className="section-title-h2 mt-3">{ t('ProblemLoading.Meantime') }</h2>
                                
                                <ul>
                                    <li>{ t('ProblemLoading.Refresh') }</li>
                                    <li>{ t('ProblemLoading.Wait') }</li>
                                    <li>{ tp('ProblemLoading.CallSupport', { phone: supportContactInfo.phone }) }</li>
                                </ul>                        
                            </>
                        }

                        right={ <></> }
                    />
                }
            />
        </>
    );
};

export default ProblemLoading;
