import * as React from "react";
import GrayTile from "./gray-tile";
import Button from "../button";
import Select from "../../Form/select";
import DestructiveButton from "../cancel-button";
import { RbacContext } from "../../../rbac-context";
import { ConfigContext } from "../../../configuration-context"; 
import { bulkCreation, fetchBulkUploadTemplate, fetchErrorReport, fetchSeatsAvailable, uploadBulkFile } from "../../../Pages/Utils/bulk-upload";
import { fetchOrganizations } from "../../../Pages/Utils/participants"; 
import { fetchClassSeats } from "../../../Pages/Utils/class";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { CustomResponse } from "../../../Pages/Utils/bulk-upload-response";
import Alert from "../alert";
import RemoteTable from "../../remote-table";
import { createColumnHelper } from "@tanstack/table-core";
import BulkUploadRow from "../../../Interfaces/Platform/BulkUploadRow";
import { getUserClaims } from "../../../helper-functions";
import FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import { ShowAlert } from "../../../Interfaces/Platform/AlertProperties";

const ImportUsingFile = (props: { 
        onSuccess: any,
        onCancel: any,
        source: string,
        isOnlineOnly?: boolean,
        blueCardRequired?: boolean,
        desiredLms?: string,
        classId?: string,
        courseId?: string,
        seatOrganizationId?: string,
        specialty?: string,
    }): JSX.Element => {
    const [file, setFile] = React.useState<any>(null);
    const [orgs, setOrgs] = React.useState<any[]>([]);
    const [organizations, setOrganizations] = React.useState<any[]>([]);
    const [template, setTemplate] = React.useState("");
    const [organizationId, setOrganizationId] = React.useState<any>();
    const [organizationName, setOrganizationName] = React.useState("");
    const [fileFormatError, setFileFormatError] = React.useState(false);
    const [fileError, setFileError] = React.useState(false);
    const [organizationIdError, setOrganizationIdError] = React.useState(false);
    const [response, setResponse] = React.useState<CustomResponse>();
    const [loading, setLoading] = React.useState(false);
    const [seatsAvailable, setSeatsAvailable] = React.useState<number>(0);
    const [seatsAlert, setSeatsAlert] = React.useState<ShowAlert>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const fileInputRef: any = React.useRef(null);
    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const ERROR_MORE_200 = 418;
    const culture = getUserClaims()?.culture ?? "en-US";

    let selectOptions = [{label: t("Select Organization"), value: ""}];

    React.useEffect(() => {
        // Check if user's organization id is in orgs value and pre-select
        const orgIsFound = orgs.findIndex((i) => i.value === userContext.OrganizationId) !== -1;
        setOrganizationId(orgIsFound ? userContext.OrganizationId : null);
    }, [orgs, userContext.OrganizationId]);
    
    const columnHelper = createColumnHelper<BulkUploadRow>()
    let columns = [   
        columnHelper.accessor('firstName', {
            cell: ({cell, row}) => {
                return <div className="break-with-hyphen">{row.original.firstName}</div>
            },
            header: () => t("First Name"),
            enableSorting: false,
        }),
        columnHelper.accessor('lastName', {
            cell: ({cell, row}) => {
                return <div className="break-with-hyphen">{row.original.lastName}</div>
            },
            header: () => t("Last Name"),
            enableSorting: false,
        }),
        columnHelper.accessor('email', {
            cell: ({cell, row}) => {
                return <div className="break-with-hyphen">{row.original.email}</div>
            },
            header: () => ((props.source === "blended") || props.isOnlineOnly) ? t("Email") : t("Email (Optional)"),
            enableSorting: false,
        }),
        columnHelper.accessor('externalID', {
            cell: ({cell, row}) => {
                return <div className="break-with-hyphen">{row.original.externalID}</div>
            },
            header: () => t("External Id (Optional)"),
            enableSorting: false,
        })
    ];

    if (props.source !== "participant") {
        columns = [
            ...columns,
            columnHelper.accessor('blueCardId', {
                cell: ({cell, row}) => {
                    return <div className="break-with-hyphen">{row.original.blueCardId}</div>
                },
                header: () => t("Blue Card ID (Optional)"),
                enableSorting: false,
            })
        ]
    }

    const removeSeatsAlert = () => {
        setSeatsAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });
    };

    const showSeatsAlert = (alert: ShowAlert) => {
        setSeatsAlert({ ...alert, show: true });
    };

    const fetchOrgs = async (userOrgId:any) => {
        const baseUrl: string = configContext?.SystemConfiguration?.ApimBaseUrl ?? "";
        const data = await fetchOrganizations(baseUrl, configContext, userOrgId);
        selectOptions = selectOptions.concat(data.map((organization: any) => ({ 
            label: organization.redundantName ? `${organization.organizationName} ${organization.city}` : organization.organizationName,
            value: organization.organizationId
        })));
        setOrgs(selectOptions);
        setOrganizations(data);
    };

    const validateData = (): boolean => {
        let isValid = true;
        if (!organizationId || organizationId === "") {
            setOrganizationIdError(true);
            isValid = false;
        }

        if (!file) {
            setFileError(true);
            isValid = false;
        }

        if (response && response.isError) {
            isValid = false;
        }

        return isValid;
    };

    const handleImport = async () => {
        const validate = validateData();
        
        if (!validate) {
            return;
        }

        setLoading(true);
        const orgId = organizations.filter((i) => i.organizationId === organizationId)[0].orgId;

        if (props.source !== 'participant' && props.classId && props.source !== 'classroom') {
              
            if (props.desiredLms != "external") {
                const seatsData = await fetchClassSeats(apimBaseUrl, configContext, { organizationId: props.seatOrganizationId, courseId: props.courseId });

                if (response?.response?.length > seatsData) {
                    setLoading(false);
                    showSeatsAlert({
                        alertLevel: { alertLevel: "error" },
                        description: tp("Seats-Remaining-Error", { numberOfSeats: seatsData, specialty: t(props.specialty) }),
                        canDismiss: true,
                        arrowLink: { label: "Buy Seats", href: `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning` }
                    });

                    return;
                }
            }
        }
    

        const body = {
            organizationId,
            organizationName: organizationName,
            data: response?.response,
            organizationIds: orgs.filter(org => org.value !== "").map((org) => org.value),
            classId: props.classId,
            desiredLms: props.desiredLms,
            trainingFormat: props.source,
            locale: culture,
            orgId
        };

        const data = await bulkCreation(body, configContext);
        if (data?.statusCode === 409) { // error
            setResponse(data);
            setLoading(false);
            
            return;
        }

        const coincidences: any[] = [];
        
        for (const [key, value] of Object.entries(data?.response.errors)) {
            const index = Number(key);
            let isInArray = response?.response[index - 1];

            if (isInArray) {
                isInArray.errorMessage = value;
                let object = {};

                if (props.source === "participant") {
                    object = {
                        firstName: isInArray.firstName,
                        lastName: isInArray.lastName,
                        email: isInArray.email,
                        externalId: isInArray.externalID,
                        errorMessage: isInArray.errorMessage
                    }
                } else {
                    object = {
                        firstName: isInArray.firstName,
                        lastName: isInArray.lastName,
                        email: isInArray.email,
                        externalId: isInArray.externalID,
                        blueCardId: isInArray.blueCardId,
                        errorMessage: isInArray.errorMessage
                    };
                }
                coincidences.push(object);
            }
        }

        props.onSuccess({
            totalCreated: data?.response.totalCreated,
            totalEnrolled: data?.response.totalEnrolled,
            totalProcessed: data?.response.totalProcessed,
            totalUpdated: data?.response.totalUpdated,
            rowsError: coincidences
        });
        
        setLoading(false);
    };

    const handleFileChange = (event) => {
        const tempFile = event.target.files[0];
        const fileExtension = tempFile.name.split(".").at(-1);

        if (fileExtension !== "csv") {
            setFileFormatError(true);
            return;
        };

        setFile(tempFile);
        setFileFormatError(false);
        setFileError(false);
    };

    const handleOrganizationChange = (event: FormSelectOption) => {
        const organizationId = event.value;
        setOrganizationId(organizationId);
        
        if (organizationId === "") {
            setOrganizationIdError(true);
            return;
        }

        const orgName = organizations.filter((i) => i.organizationId === organizationId)[0]?.organizationName;

        setOrganizationName(orgName);
        setOrganizationIdError(false);
    }

    const handleFileUpload = async () => {
        if (!organizationId || organizationId === "" || !file) {
            return;
        }

        const request = {
            organizationId,
            template: props.source,
            classId: props.classId,
            courseId: props.courseId,
            desiredLms: props.desiredLms,
            locale: culture,
            seatsAvailable
        };
        
        const data = await uploadBulkFile(request, file, configContext);
        setResponse(data);
    };

    const downloadTemplateOnLoad = async () => {
        // If we're in an online-only class, we want to use the blended template (bluecard will be false if necessary)
        // otherwise use the incoming source (classDetails.trainingFormat)
        const templateToUse = (props.isOnlineOnly) ? "blended" : props.source;

        const request = {
            template: templateToUse,
            blueCardRequired: props.blueCardRequired,
            culture
        };
        const returnedTemplate = await fetchBulkUploadTemplate(request, configContext);
        setTemplate(returnedTemplate ?? "");
    };

    const handleDownloadErrorReport = async () => {
        var request = {
            template: props.source,
            blueCardRequired: props.blueCardRequired,
            culture,
            ...response?.response
        };

        const resp = await fetchErrorReport(request, configContext);

        if (resp?.status === 404 || resp?.status === 500) {
            setResponse({
                isError: true,
                message: t("BulkUpload.DownloadErrorReport"),
                statusCode: 500
            })

            return;
        };
        
        const errorReport = await resp?.text();

        downloadTemplate(errorReport, "errorReport");
    };

    const handleBuySeats = async () => {
        const link = document.createElement("a");
        link.href = `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning`;
        link.target = "_blank";
        
        document.body.appendChild(link);
        
        link.click();
    };

    const setAlertLabel = () => {
        let label: any;

        if (response?.statusCode === 409) {
            label = {
                label: t("Download-Error-Report"),
                onClick: handleDownloadErrorReport
            }
        }

        if (response?.statusCode === 417) {
            label = {
                label: t("Buy Seats"),
                onClick: handleBuySeats
            }
        }

        return label;
    };

    const downloadTemplate = (file, name) => {
        const blob = new Blob([file], { type: "text/csv" })
        const a = document.createElement('a')

        a.download = `${name}.csv`
        a.href = window.URL.createObjectURL(blob)

        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const defaultRowsInTable = () => {
        let rows: any[] = [];
        for (let index = 0; index < 4; index++) {
            rows.push(
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {
                    props.source !== "participant" && (
                        <td></td>
                    )
                }
            </tr>)                                               
        }

        return rows;
    }

    const getSeats = async () => {
        if (props.classId && props.source === "blended") {
            const seatsData: number = await fetchSeatsAvailable(configContext, props.seatOrganizationId, props.courseId);
            setSeatsAvailable(seatsData);
        }
    };

    React.useEffect(() => {
        fetchOrgs(userContext.SelectedOrganization);
        downloadTemplateOnLoad();
    }, []);

    React.useEffect(() => {
        handleFileUpload();
    }, [organizationId, file]);

    React.useEffect(() => {
        getSeats();
    }, [props.seatOrganizationId, props.courseId]);

    if (loading) {
        return (
            <div className="loading-content-container">
                <div className="d-flex flex-column align-items-center">
                    <p className="fw400 loading-content-title">{t("Do-Not-Refresh-The-Page")}</p>
                    <p>{t("Total-Participants-To-Import", { totalParticipants: response?.response.length })}</p>
                </div>
                <div className="loading-content-participants">
                    <i className="fas fa-duotone fa-spinner icon" />
                </div>
            </div>
        )
    }

    return (
        <>
            {
                !loading && (
                    <div className="import-using-file-container">
                    {
                        response && response.isError && (
                            <Alert
                                description={response.message}
                                state={{ alertLevel: "error" }}
                                arrowLinkHandled={setAlertLabel()}
                                canDismiss={response.statusCode !== ERROR_MORE_200}
                            />
                        )
                    }
                    {
                        (organizationIdError || fileFormatError || fileError) && (
                            <Alert
                                description={t("Problem-Uploading-Participants")}
                                state={{ alertLevel: "error" }}
                                canDismiss={false}
                            />
                        )
                    }
                    {seatsAlert?.show && (
                        <Alert
                            removeAlertParentHandler={removeSeatsAlert} 
                            state={seatsAlert.alertLevel}
                            arrowLink={seatsAlert.arrowLink}
                            description={seatsAlert.description}
                            canDismiss={seatsAlert.canDismiss}
                        />
                    )}
                    <div className="import-using-file-wrapper">
                        <div className="import-using-file-row">
                            <div className="import-using-file-participant-list">
                                <h4 className="h4">{t("Upload-Preview-Participant-List")}</h4>
                                <div className="import-file-select-container">
                                    <Select
                                        isRequired
                                        label={t("Organization")}
                                        name="organizationId"
                                        value={organizationId ?? ""}
                                        filter
                                        id="organizationId"
                                        options={orgs}
                                        changeFilterAction={handleOrganizationChange}
                                    />
                                    {
                                        organizationIdError && <span className="field-validation form-field-error small-error" id="firstName-required-label-1">{t("Organization-Mandatory")}</span>
                                    }
                                    <div className="import-file-input-container">
                                        <input
                                            className="import-file-input clear-button"
                                            type="button"
                                            onClick={() => fileInputRef.current?.click()}
                                            value={t("Choose File")}
                                        />
                                        <label htmlFor="import-file">
                                            {file && file.name ? file.name : t("No file chosen")}
                                        </label>
                                        <input
                                            ref={fileInputRef}
                                            className="import-file-input-hidden"
                                            id="import-file"
                                            onChange={handleFileChange}
                                            type="file"
                                            accept=".csv"
                                            onClick={(e) => e.currentTarget.value = null}
                                        />
                                    </div>
                                    {
                                        fileFormatError && <span className="field-validation form-field-error small-error" id="firstName-required-label-1">{t("Should-Be-Csv-File")}</span>
                                    }
                                    {
                                        fileError && <span className="field-validation form-field-error small-error" id="firstName-required-label-1">{t("Must-Add-Csv-File")}</span>
                                    }
                                </div>
                            </div>
                            <div className="import-using-file-template">
                                <GrayTile>
                                    <>
                                        <h4 className="h4 fw500">{t("Things-To-Check-Before-Upload")}</h4>
                                        <ul>
                                            <li>{t("Check-First-Row-Match")}</li>
                                            <li>{t("Check-List-200-Participants")}</li>
                                            <li>{t("Check-Email-Addresses")}</li>
                                            <li>{t("Check-Duplicates")}</li>
                                        </ul>
                                        <div className="template-button-container">
                                            <Button
                                                elementId="download-template-button"
                                                isSolid={false}
                                                label={
                                                    <div className="template-button">
                                                        <i className="fa-light fa-arrow-down-to-line template-button-icon"></i>
                                                        {t("BTN-Download-Template")}
                                                    </div>
                                                }
                                                clickAction={() => downloadTemplate(template, "CPI-Import-Template")}
                                                isDisabled={!template}
                                            />
                                        </div>
                                    </>
                                </GrayTile>
                            </div>
                        </div>
                        <div className="import-using-file-preview">
                            <p className="import-using-file-preview-title">{t("Preview")}</p>
                            {
                                response && !response.isError && response.response ? (
                                    <>
                                        <RemoteTable
                                            keyField=""
                                            fetchData={false}
                                            columns={columns}
                                            defaultData={response.response.slice(0, 4) ?? []}
                                            defaultSorted={{ dataField: t("Email"), order: "desc" }}
                                            enableSearch={false}
                                            enablePaging={false}
                                        />
                                        <p className="mt-3"><b>{t("Total-Participants", { total: response.response.length })}</b> {t("Will-Be-Imported")}</p>
                                    </>
                                ) : (
                                    <table className="import-using-file-preview-table">
                                        <thead>
                                            <th>{t("First Name")}</th>
                                            <th>{t("Last Name")}</th>
                                            <th>{((props.source === "blended") || props.isOnlineOnly) ? t("Email") : t("Email (Optional)")}</th>
                                            <th>{t("External Id (Optional)")}</th>
                                            {
                                                props.source !== "participant" && (
                                                    <th>{t("Blue Card ID (Optional)")}</th>
                                                )
                                            }
                                        </thead>
                                        <tbody>{ defaultRowsInTable() }</tbody>
                                    </table>
                                )
                            }
                        </div>
                        
                        <div className="d-flex justify-content-between mt-5">
                            <DestructiveButton
                                additionalStyleClasses="inline-icon-left cancel-button"
                                label={<><i className="me-1 far fa-times" /> {t("Cancel")}</>}
                                clickAction={() => props.onCancel()}
                            />
                            <Button
                                elementId="import-participants-button"
                                label={t("BTN-Import-Participants")}
                                isSolid
                                clickAction={handleImport}
                            />
                        </div>
                    </div>
                </div>
                )
            }
        </>
    );

};

export default ImportUsingFile;
