import * as React from "react";
import { useLocation } from "react-router-dom";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "react-bootstrap";

import Footer from "../layout/footer";
import OrgBanner from "../layout/org-banner";
import { RbacContext } from "../rbac-context";
import SideNav from "../layout/SideNav/sidenav";
import TopNav from "../layout/topnav";
import Header from "../layout/Header/header";
import { useEffect } from "react";
import { CpiRoute } from "../Routing/routes";

import useNetworkStatus from "../hooks/useNetworkStatus";

const Page = (props: {
    title: string;
    children: JSX.Element;
    hideSideNav?: boolean;
    hideFooter?: boolean;
    hideBanner?: boolean;
    showDropdown?: boolean;
    hideAll?: boolean;
    route?: CpiRoute;
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { userContext, refreshPage } = React.useContext(RbacContext);
    const isOnline = useNetworkStatus();
    const [ sideNavIsOpen, setSideNavIsOpen ] = React.useState(true);

    const toggleSideNavIsOpen = () => { 
        if (sideNavIsOpen) {
            if (isMobile) {
                setSideNavIsOpen(false)
            }
        } else {
            setSideNavIsOpen(true)} 
        }

    const [viewport, setViewport] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    const [headerHeight, setHeaderHeight] = React.useState(0);
    const [footerHeight, setFooterHeight] = React.useState(0);
    const [mainnavHeight, setMainnavHeight] = React.useState(0);
    const [pageMinHeight, setPageMinHeight] = React.useState(viewport.height - (footerHeight + headerHeight) - 1);
    //const [scrollPosition, setScrollPosition] = React.useState(0);
    const [pageHeight, setPageHeight] = React.useState(0);
    const [isMobile, setIsMobile] = React.useState(null);

    const [mainnavContainerOverflowYCSS, setMainnavContainerOverflowYCSS] = React.useState<CSSProperties>({overflowY: 'initial'});

    // SCROLL DIRECTION STUFF
    const lastScrollY = React.useRef(window.scrollY);
    const [scrollDirection, setScrollDirection] = React.useState("down");
    const [siteHeaderIsAnimated, setSiteHeaderIsAnimated] = React.useState(false);
    const [siteHeaderIsHidden, setSiteHeaderIsHidden] = React.useState(false);
    const [siteHeaderIsFixed, setSiteHeaderIsFixed] = React.useState(false);

    React.useEffect(() => {
        // Scroll to top when location changes
        window.scrollTo(0,0);
    }, [location]);

    React.useEffect(() => {

        if (isMobile) {
            setSideNavIsOpen(false);
        } else {
            setSideNavIsOpen(true);
        }

    }, [isMobile]);

    React.useEffect(() => {
        setPageMinHeight(viewport.height - (footerHeight + headerHeight) - 1);
    }, [footerHeight, headerHeight]);

    React.useEffect(() => {
        const handleResize = () => {
            setViewport({
                height: window.innerHeight,
                width: window.innerWidth
            });

            setPageMinHeight(viewport.height - (footerHeight + headerHeight) - 1);
            setPageHeight(viewport.height - headerHeight);
        };

        const handleScroll = () => {
            
            if (lastScrollY.current > window.scrollY) {
                if (scrollDirection != "up") { setScrollDirection("up"); }
            } else {
                if (scrollDirection != "down") { setScrollDirection("down"); }
            }

            if (window.scrollY >= headerHeight) {
                // We are BELOW header scroll bounds

                if (scrollDirection == "up") {
                    if (!siteHeaderIsAnimated) { setSiteHeaderIsAnimated(true); }
                    if (siteHeaderIsHidden) { setSiteHeaderIsHidden(false); }
                } else {
                    //setSiteHeaderIsAnimated(false);
                    if (!siteHeaderIsHidden) { setSiteHeaderIsHidden(true); }
                }

                if (!siteHeaderIsFixed) { setSiteHeaderIsFixed(true); }
            } else {
                // We are ABOVE header scroll bounds
                if (siteHeaderIsHidden) { setSiteHeaderIsHidden(false); }

                if ((scrollDirection == "up") && (!siteHeaderIsFixed)) {
                    if (siteHeaderIsAnimated) { setSiteHeaderIsAnimated(false); }
                }

                if (!(siteHeaderIsFixed && (window.scrollY > 0))) {
                    if (siteHeaderIsFixed) { setSiteHeaderIsFixed(false); }
                }
            }

            lastScrollY.current = window.scrollY;

        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            // clean up here
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        }
    }, [headerHeight, viewport, footerHeight, siteHeaderIsFixed, siteHeaderIsHidden,siteHeaderIsAnimated, scrollDirection]);

    // Title
    React.useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    React.useEffect(() => {

        if (viewport.width <= 1279) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

    }, [viewport, pageHeight]);

    const closeSideNav = () => {
        if (isMobile) {
            setSideNavIsOpen(false);
        }
    };

    return (
        <>
            {!props.hideAll && <><div id="topnav-content-spacer" style={{height: headerHeight}}></div><Header siteHeaderIsAnimated={siteHeaderIsAnimated} siteHeaderIsHidden={siteHeaderIsHidden} siteHeaderIsFixed={siteHeaderIsFixed} setHeaderHeight={setHeaderHeight} toggleSideNavIsOpen={toggleSideNavIsOpen} route={props.route}  /></>}
            <div id="site-content" style={{minHeight: pageMinHeight}} className="site-content d-flex">
                {(!isOnline && siteHeaderIsHidden) && (
                    <div className="offline-banner banner-fixed">
                        <i className="fa-sharp fa-solid fa-triangle-exclamation offline-banner-icon mr-2"/>
                        {t("offline-alert")}
                    </div>
                )}
                {!props.hideAll && !props.hideSideNav && userContext.UserId && <SideNav setMainnavHeight={setMainnavHeight} sideNavOverflowYCSS={mainnavContainerOverflowYCSS} closeSideNav={closeSideNav} sideNavIsOpen={sideNavIsOpen} />}
                {/* Force re-render page on organization change */}
                <div className="flex-grow-1" style={{ width:"100%" }} key={refreshPage.toString()}>
                    {false && (
                        <OrgBanner showDropdown={!props.hideAll && props.showDropdown} />
                    )}

                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
            {!props.hideAll && !props.hideFooter && <Footer setFooterHeight={setFooterHeight} />}
        </>
    );
};

export default Page;
