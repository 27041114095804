import "./many-sco-play-modal.scss";

import * as React from "react";

import { Alert, Button, Modal } from "react-bootstrap";
import { fetchLessonLaunchUrl, fetchManyScoResults } from "../../ApiServices/Learn";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const ManyScoPlayModal = ({
    show,
    learnerLessonId,
    isLastLesson,
    onHide,
    onNext
}: {
    show: boolean;
    learnerLessonId: number;
    isLastLesson: boolean;
    onHide: () => Promise<void>;
    onNext: () => Promise<void>;
}) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [launchUrl, setLaunchUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [advancing, setAdvancing] = React.useState(false);
    const [showNextError, setShowNextError] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const resp = await fetchLessonLaunchUrl(learnerLessonId);
                setLoading(false);
                setLaunchUrl(resp.Data.LaunchUrl);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }         
        };

        getData();
    }, [learnerLessonId]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            className="modal-play"
            dialogClassName="modal-dialog-responsive"
            contentClassName="mx-auto"
        >
            <Modal.Body className={loading ? "loading" : ""}>
                <div className="btn-group-play-modal">
                    <Button variant="primary" onClick={onHide}>
                        <i className="fas fa-times"></i>
                    </Button>
                </div>
                {loading && (
                    <div style={{ position: "absolute", top: "50%", left: "50%" }} className="fa-3x">
                        <i className="fas fa-spinner fa-spin"></i>
                    </div>
                )}
                {!loading && (
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" allow="autoplay" src={launchUrl}></iframe>
                    </div>
                )}
                <Alert
                    variant="warning"
                    className="continue-warning mb-0"
                    dismissible
                    show={showNextError}
                    onClose={() => setShowNextError(false)}
                >
                    {t("You must complete the current lesson before you can continue on to the next.")}
                </Alert>
                <Button
                    variant="outline-primary"
                    className="btn-continue-play"
                    disabled={advancing}
                    onClick={async () => {
                        try {
                            setAdvancing(true);
                            const resp = await fetchManyScoResults(learnerLessonId);
                            if (resp.Error || !resp.Data.Completed) {
                                setShowNextError(true);
                            } else {
                                if (isLastLesson) {
                                    await onHide();
                                } else {
                                    await onNext();
                                }
                            }
                        } finally {
                            setAdvancing(false);
                        }
                    }}
                >
                    {advancing && <i className="fas fa-spinner fa-spin"></i>} {isLastLesson ? t("Finish") : t("Next")}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ManyScoPlayModal);
