import * as React from "react";

import { Modal } from "react-bootstrap";
import { SeatAdjustmentModel } from "../../Interfaces";
import { formatDate } from "../../locale-utils";
import { OrgSeatAdjustmentEnum } from "../../Enums";

import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface SeatAdjustmentInfoModalProps {
    title: string;
    data: SeatAdjustmentModel;
    isVisible: boolean;
    onHide: () => void;
}

const SeatAdjustmentInfoModal = ({ title, data, isVisible, onHide }: SeatAdjustmentInfoModalProps) => {
    const { t } = useTranslation();

    const AdjustmentType = (adjustmentType: number) => {
        switch (adjustmentType) {
            case 1:
                return 'Expiration';
            case 2:
                return 'Remove';
            case 3:
                return 'Partial Remove';
        }
    };

    return (
        <Modal show={isVisible} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t(title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>{t("User")}:</strong> {data.modifyUserName}
                </p>
                <p>
                    <strong>{t("Adjustment Date")}:</strong> {formatDate(data.adjustmentDate)}
                </p>
                <p>
                    <strong>{t("Adjustment Type")}:</strong> {data.type.name}
                </p>
                <p>
                    <strong>{t("New Value")}:</strong> {data.type.value === OrgSeatAdjustmentEnum.Expiration ? formatDate(data.newValue) : data.newValue}
                </p>
                <p>
                    <strong>{t("Requestor")}:</strong> {data.requestorName}
                </p>
                <p style={{ wordWrap: "break-word" }}>
                    <strong>{t("Reason")}:</strong> {data.reason}
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default withAITracking(reactPlugin, SeatAdjustmentInfoModal);
