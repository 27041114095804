import { useEffect, useState } from "react";
import type { RefObject } from "react";

const useDetectClick = (ref: RefObject<any>) => {
    const [clickOutside, setClickOutside] = useState(false);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => setClickOutside(ref.current && !ref.current.contains(e.target));
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, [ref]);

    return {
        clickOutside,
    } as const;
};

export default useDetectClick;
