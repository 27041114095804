import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const ChoosingYourCertifiedInstructors = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <h1 className="page-title-h1 title-margin">{t('ChoosingYourCIs-Title')}</h1>
                    <p>{t('ChoosingYourCIs-Intro')}</p>

                    <Tile title={t('ChoosingYourCIs-TileTitle')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>{t('ChoosingYourCIs-TileIntro')}</p>

                                    </>
                                }
                            />


                            <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <h3 className="sub-headings-h3">{t('ChoosingYourCIs-Subheading-1')}</h3>
                                        <p>{t('ChoosingYourCIs-SectionContent-1')}</p>
                                        <ul className="ml-3">
                                            <li>{t('ChoosingYourCIs-Section1-ListItem1')}</li>
                                            <li>{t('ChoosingYourCIs-Section1-ListItem2')}</li>
                                            <li>{t('ChoosingYourCIs-Section1-ListItem3')}</li>
                                        </ul>
                                        <h3 className="sub-headings-h3">{t('ChoosingYourCIs-Subheading-2')}</h3>
                                        <p>{t('ChoosingYourCIs-SectionContent-2')}</p>
                                        <ul className="ml-3">
                                            <li>{t('ChoosingYourCIs-Section2-ListItem1')}</li>
                                            <li>{t('ChoosingYourCIs-Section2-ListItem2')}</li>
                                            <li>{t('ChoosingYourCIs-Section2-ListItem3')}</li>
                                        </ul>
                                        <h3 className="sub-headings-h3">{t('ChoosingYourCIs-Subheading-3')}</h3>
                                        <p>{t('ChoosingYourCIs-SectionContent-3')}</p>
                                        <ul className="ml-3">
                                            <li>{t('ChoosingYourCIs-Section3-ListItem1')}</li>
                                            <li>{t('ChoosingYourCIs-Section3-ListItem2')}</li>
                                            <li>{t('ChoosingYourCIs-Section3-ListItem3')}</li>
                                        </ul>
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <h3 className="sub-headings-h3">{t('ChoosingYourCIs-Subheading-4')}</h3>
                                        <p>{t('ChoosingYourCIs-SectionContent-4')}</p>
                                        <ul className="ml-3">
                                            <li>{t('ChoosingYourCIs-Section4-ListItem1')}</li>
                                            <li>{t('ChoosingYourCIs-Section4-ListItem2')}</li>
                                            <li>{t('ChoosingYourCIs-Section4-ListItem3')}</li>
                                        </ul>
                                        <h3 className="sub-headings-h3">{t('ChoosingYourCIs-Subheading-5')}</h3>
                                        <ul className="ml-3">
                                            <li>{t('ChoosingYourCIs-Section5-ListItem1')}</li>
                                            <li>{t('ChoosingYourCIs-Section5-ListItem2')}</li>
                                        </ul>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default ChoosingYourCertifiedInstructors;
