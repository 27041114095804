import * as React from "react";
import type { SetStateAction, Dispatch } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TileTabbedContent from "../../Interfaces/Platform/TileTabbedContent";

const TileTabbed = (props: {
    tabs: TileTabbedContent[],
    onSelectTab: Dispatch<SetStateAction<number>>,
    activeTab?: number
}): JSX.Element => {
    const defaultProps = {
        activeTab: 0
    };
    props = { ...defaultProps, ...props };

    const createListTabs = (
        tabs: TileTabbedContent[]
    ) => {
        return (
            <>
            <TabList className="section-title-h2 react-tabs__tab-list">
                {tabs.map((tab, index) => (
                   <Tab key={`tab-${index}`}>{tab.title}</Tab>
                ))}
            </TabList>
            
            {tabs.map((tab, index) => (
                <TabPanel key={`tab-panel-${index}`}>
                   {tab.content}
                </TabPanel>
            ))}

            </>
        );
    };

    return (
        <>
            {
                <div className="tabbed">
                    <Tabs selectedIndex={props.activeTab} onSelect={(index) => props.onSelectTab(index)}>
                        {createListTabs(props.tabs)}                
                    </Tabs>
                </div>
            }
        </>
    );
};

export default TileTabbed;
