import * as React from "react";
import { ArrowLinkObject, ArrowLinkObjectHandled } from "../../Interfaces/Platform/ArrowLink";
import ArrowLink from "./arrow-link";
import AlertLevelString from "../../Interfaces/Platform/AlertLevelString";
import ArrowLinkHandled from "./arrow-link-handled";

const Alert = (props: {
    removeAlertParentHandler?: any,
    state: AlertLevelString,
    description: React.ReactNode,
    canDismiss: boolean,
    arrowLink?: ArrowLinkObject,
    iconClass?: string,
    arrowLinkHandled?: ArrowLinkObjectHandled,
    resetState?: any;
}): JSX.Element => {
    const [isDismissed, setIsDismissed] = React.useState(false);

    const successTimer = () => setTimeout(() => {
        if (props?.removeAlertParentHandler) props?.removeAlertParentHandler();
        setIsDismissed(true);
        if (props?.resetState) props.resetState();
    }, 10000);
    React.useEffect(() => {
        if (props.state.alertLevel === "success") {
            setIsDismissed(false);
            successTimer();
        }
        return () => clearTimeout(successTimer());
    }, [props.state, props.description]);

    const setClassIcon = (): string => {
        if (props.iconClass) return props.iconClass;

        let classIcon = "";
        switch (props.state.alertLevel) {
            case "error":
                classIcon = "fa-sharp fa-solid fa-circle-exclamation";
                break;

            case "success":
                classIcon = "fa-sharp fa-solid fa-circle-check color-blue";
                break;

            case "warning":
                classIcon = "fa-sharp fa-light fa-circle-exclamation";
                break;
        }

        return classIcon;
    };

    const handleDismiss = (): void => {
        if(props?.removeAlertParentHandler) {
            props?.removeAlertParentHandler()
        }

        setIsDismissed(true);
    };

    return (
        <>
            {!isDismissed && (
                <div className={`alert-box alert-box-${props.state.alertLevel}`}>
                    <div className="alert-box-container">
                        <div className="alert-box-icon">
                            <i className={setClassIcon()}></i>
                        </div>

                        <div className="alert-box-content-container">
                            <div className="alert-box-text" style={{ whiteSpace: "pre-wrap" }}>{props.description}</div>
                            {props.arrowLink && <ArrowLink data={{label: props.arrowLink.label, href: props.arrowLink.href}} />}
                            {props.arrowLinkHandled && <ArrowLinkHandled data={{
                                label: props.arrowLinkHandled.label,
                                onClick: props.arrowLinkHandled.onClick,
                                isButton: props.arrowLinkHandled.isButton,
                                iconClass: props.arrowLinkHandled.iconClass
                            }} />}
                        </div>

                        {props.canDismiss && (
                            <div className="alert-box-close">
                                <i className="fa-sharp fa-light fa-xmark" onClick={handleDismiss}></i>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Alert;
