import { CoursePreview, PreviewDetails, SelectModel } from "../Interfaces";
import { GET, GETWITHERROR, JsonResponseModel } from ".";

import { loadAsync } from "jszip";
import { template } from "../helper-functions";

const FETCH_DEFAULT_MARKET_TYPE = "/Preview/GetOrganizationMarketTypeAsync";
const FETCH_COURSE_LANGUAGES = "/Preview/GetCourseLanguagesByRegionAsync";
const FETCH_COURSE_INDUSTRIES = "/Preview/GetIndustriesAsync";
const FETCH_COURSE_PREVIEW_TEMPLATE = template`/Preview/GetCoursePreviewAsync?courseObjectId=${0}`;
export const FETCH_LEARNER_PREVIEWS_URL = "/Preview/GetPreviewsAsync";
export const FETCH_PREVIEW_COURSE_THUMBNAILS_URL = "/Preview/GetPreviewThumbnailsAsync";
const FETCH_LESSON_LAUNCH_URL = template`/Preview/GetLessonLaunchUrlAsync?courseId=${0}&lessonId=${1}`;

export async function fetchDefaultMarketType(): Promise<JsonResponseModel<{MarketTypeId: number}>> {
    return GETWITHERROR(FETCH_DEFAULT_MARKET_TYPE);
}

export async function fetchCourseLanguages(): Promise<{Id: number, Code: string, Display: string}[]> {
    const resp = await GETWITHERROR<{ Id: number, Code: string, Display: string }[]>(FETCH_COURSE_LANGUAGES);
    return resp.Data;
}

export async function fetchIndustries(): Promise<SelectModel[]> {
    const resp = await GETWITHERROR<SelectModel[]>(FETCH_COURSE_INDUSTRIES);
    return resp.Data;
}

export async function fetchPreviews(): Promise<PreviewDetails[]> {
    const resp = await GETWITHERROR<PreviewDetails[]>(FETCH_LEARNER_PREVIEWS_URL);
    return resp.Data;
}

export async function fetchCoursePreview(courseObjectId: number): Promise<JsonResponseModel<CoursePreview>> {
    return GETWITHERROR<CoursePreview>(FETCH_COURSE_PREVIEW_TEMPLATE(courseObjectId));
}

export async function fetchPreviewThumbnails(): Promise<{ [courseId: string]: Blob }> {
    const resp = await GET(FETCH_PREVIEW_COURSE_THUMBNAILS_URL);
    const thumbnailsZip = await resp.blob();
    const courseThumbnails: { [id: string]: Blob } = {};
    const jsZip = await loadAsync(thumbnailsZip);

    const filenames = Object.keys(jsZip.files);
    for (let i = 0; i < filenames.length; i++) {
        const filename = filenames[i];
        const blob = await jsZip.files[filename].async("blob");
        const filenameParts = filename.split("-");
        courseThumbnails[filenameParts[0]] = blob;
    }
    return courseThumbnails;
}

export async function fetchLessonLaunchUrl(
    courseId: number,
    lessonId: number
): Promise<JsonResponseModel<{ LaunchUrl: string }>> {
    return GETWITHERROR(FETCH_LESSON_LAUNCH_URL(courseId, lessonId));
}
