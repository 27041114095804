import * as React from "react";

import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { fetchCourseLanguages, fetchIndustries, fetchPreviewThumbnails, fetchPreviews } from "../ApiServices/Preview";

import CourseCard from "./Components/course-card";
import { Industry } from "../Enums";
import PageMessages from "../Components/page-messages";
import { PreviewDetails, SelectModel } from "../Interfaces";
import { RbacContext } from "../rbac-context";
import { useTranslation } from "react-i18next";

import { getLang } from "../locale-utils";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { SupportBaseRoutes } from "../Routing/routes";
import { useNavigate } from "react-router-dom";
import { isExternalURL } from "../helper-functions";

import { PlatformBaseRoutes } from "../Routing/routes";

const _URL = window.URL || window.webkitURL;

const PreviewCoursesPage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [languageOptions, setLanguageOptions] = React.useState<{ Id: number, Code: string, Display: string }[]>([]);
    const [selectedLanguagePreview, setSelectedLanguagePreview] = React.useState<{ Id: number, Code: string, Display: string }>();
    const [industryOptions, setIndustryOptions] = React.useState<SelectModel[]>([]);
    const [selectedIndustryPreview, setSelectedIndustryPreview] = React.useState<SelectModel>();
    const [previewCourses, setPreviewCourses] = React.useState<PreviewDetails[]>([]);
    const [descriptionExpands, setDescriptionExpands] = React.useState<{ [id: string]: boolean }>({});
    const [thumbnailUrls, setThumbnailUrls] = React.useState<{ [id: string]: string }>({});
    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);

    const navigate = useNavigate();

    const handleNavToContactUs = (route: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(route)) {
            event.preventDefault();
            navigate(route);
        }
    };

    React.useEffect(() => {
        const getPreviews = async () => {
            setLoading(true);
            try {
                const previews = await fetchPreviews();
                setPreviewCourses(previews);

                const languages = await fetchCourseLanguages();
                setLanguageOptions(languages);

                let language = languages.find(x => x.Code == getLang());
                if (!language) {
                    language = languages.find(x => x.Code.substring(0, 2).toLowerCase() == getLang().substring(0, 2));
                }
                setSelectedLanguagePreview(language);

                const industries = await fetchIndustries();
                setIndustryOptions(industries.filter(x => x.Id != Industry.Any));

                let industry = industries.find(x => x.Id == userContext.OrganizationIndustry);
                setSelectedIndustryPreview(industry);

                setLoading(false);

                try {
                    const previewThumbnails = await fetchPreviewThumbnails();
                    previews.forEach((preview) => {
                        if (previewThumbnails[preview.CourseId] && !thumbnailUrls[preview.CourseId]) {
                            const img = new Image();
                            img.src = _URL.createObjectURL(previewThumbnails[preview.CourseId]);
                            thumbnailUrls[preview.CourseId] = img.src;
                        }
                    });
                    setThumbnailUrls({ ...thumbnailUrls });
                } catch (err) {
                    appInsights.trackException({ error: err, properties: userContext });
                    console.error(err);
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
                setLoading(false);
            }
        };

        getPreviews();
    }, []);

    const filteredPreviews = previewCourses.filter((x) => (!selectedLanguagePreview || x.CourseLanguageId === selectedLanguagePreview?.Id) && (!selectedIndustryPreview || x.Industry === selectedIndustryPreview?.Id || x.Industry === Industry.Any));

    return (
        <>
            <h2>{t("Preview Courses")}</h2>
            <Alert variant="secondary" className="mt-2">
                {t("These are preview courses, not to be used for formal training")}.{" "}
                <a className="text-link pendo__link-contact-us" target="_blank" href={SupportBaseRoutes.SupportContactUs.fullPath} onClick={(e) => {
                    handleNavToContactUs(SupportBaseRoutes.SupportContactUs.fullPath, e);
                }}>
                    {t("Please contact us to learn more")}
                </a>
                .
            </Alert>
            <hr />
            <PageMessages loading={loading} errored={errored} />
            {!loading && <Container fluid>
                <Row>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px", width: "100%", marginTop: "10px", marginBottom: "2rem" }}>
                        <Row>
                            <Col md={3} lg={3} xl={2}>
                                <Form.Label>{t("Language")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedLanguagePreview?.Id}
                                    style={{ width: "100%", display: "block" }}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setSelectedLanguagePreview(languageOptions.find(x => x.Id === parseInt(e.target.value)))
                                    }}
                                >
                                    <option key="empty" value="">
                                        {t("All Available Options")}
                                    </option>
                                    {languageOptions.map((x) => (
                                        <option key={x.Id} value={x.Id}>{t(x.Display)}</option>
                                    ))}

                                </Form.Control>
                            </Col>
                            <Col md={3} lg={3} xl={2}>
                                <Form.Label>{t("Industry")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedIndustryPreview?.Id}
                                    style={{ width: "100%", display: "block" }}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setSelectedIndustryPreview(industryOptions.find(x => x.Id === parseInt(e.target.value)))
                                    }}
                                >
                                    <option key="empty" value="">
                                        {t("All Available Options")}
                                    </option>
                                    {industryOptions.map((x) => (
                                        <option key={x.Id} value={x.Id}>{t(x.Display)}</option>
                                    ))}

                                </Form.Control>
                            </Col>
                        </Row>
                    </div>
                </Row>                
                {!loading && !errored && filteredPreviews.length === 0 && (
                    <Alert variant="primary">{t("No previews available")}.</Alert>
                )}
                {!loading && filteredPreviews.map((previewCourse: PreviewDetails) => {
                    const descriptionExpand = descriptionExpands[previewCourse.CourseObjectId];
                    const thumbnailUrl = thumbnailUrls[previewCourse.CourseId];

                    return (
                        <CourseCard
                            key={previewCourse.CourseObjectId}
                            url={`${PlatformBaseRoutes.Preview.fullPath}/${previewCourse.CourseObjectId}`}
                            thumbnailUrl={thumbnailUrl}
                            course={previewCourse}
                            descriptionExpanded={descriptionExpand}
                            toggleDescriptionExpand={() => {
                                descriptionExpands[previewCourse.CourseObjectId] = !descriptionExpand;
                                setDescriptionExpands({ ...descriptionExpands });
                            }}
                        />
                    );
                })}
            </Container>}
        </>
    );
};

export default withAITracking(reactPlugin, PreviewCoursesPage);