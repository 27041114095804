import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Form, Modal, Col, Container, Row } from "react-bootstrap";
import { RbacContext } from "../../rbac-context";
import { changePassword } from "../../ApiServices/MyAccount";
import { useTranslation } from "react-i18next";
import { useToast } from "../../hooks/toast";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import UpdatePassword from "../../Components/update-password";

export interface ChangePasswordModalProps {
    show: boolean;
    onSend: (resp: JsonResponseModel<ApiResponse>) => void;
    handleCancel: () => void;
}

const ChangePasswordModal = ({ show, onSend, handleCancel }: ChangePasswordModalProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const { toast } = useToast();
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [validated, setValidated] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [passwordIsValid, setPasswordIsValid] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            if (passwordIsValid && newPassword == confirmPassword) {
                try {
                    setSending(true);
                    const resp = await changePassword(currentPassword, newPassword);
                    if (resp.Error) {
                        setSending(false);
                        toast(resp);
                    }
                    else {
                        onSend(resp);
                    }
                } catch (err) {
                    appInsights.trackException({ error: err, properties: userContext });
                    console.error(err);
                }
            }
            else {
                toast({ "Data": { "Success": false, "Message": "Password requirements have not been met."} });
            }
        } else {
            setValidated(true);
        }
    };
 
    return (
        <Modal show={show} onHide={handleCancel} size='lg'>
            <Form className="brand-form" noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Change Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid className="change-password-modal">
                        <Row>
                            <Col className="col-12 section-wrapper-large">
                                <div className="dashboard-tile card py-2 px-2 py-md-4 px-md-4 mt-0">
                                    <UpdatePassword
                                        newPassword={newPassword}
                                        setNewPassword={setNewPassword}
                                        confirmPassword={confirmPassword}
                                        setConfirmPassword={setConfirmPassword}
                                        passwordIsValid={passwordIsValid}
                                        setPasswordIsValid={setPasswordIsValid}
                                        showExistingPassword={true}
                                        existingPassword={currentPassword}
                                        setExistingPassword={setCurrentPassword}
                                    />
                                    <div className="field-group d-flex align-items-center">
                                        <Button className="inline-button inline-icon-left cancel-button" variant="link" disabled={sending} onClick={handleCancel}>
                                            {t("Cancel")}
                                        </Button>
                                        <Button className="button-brand-primary d-block ms-auto" variant="primary" type="submit" disabled={sending}>
                                            {sending ? (
                                                <>
                                                    <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                                                </>
                                            ) : (
                                                t("Save New Password")
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ChangePasswordModal);
