import * as React from "react";

const Toggle = (props: {
    label?: string;
    name: string;
    id: string;
    offLabel: string;
    onLabel: string;
    isError?: boolean;
    errorText?: string;
    defaultOn?: boolean;
    value?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <>
            <div className="field-group">
                {props.label ? (
                    <label className="bold form-label" htmlFor={props.id}>
                        {props.label}
                    </label>
                ) : (
                    <React.Fragment />
                )}

                <div className="toggle-wrapper">
                    <span className="toggle-label-disabled">{props.offLabel}</span>

                    <label className="toggle">
                        <input
                            value={props.value}
                            id={props.id}
                            name={props.name}
                            type="checkbox"
                            defaultChecked={props.defaultOn ? true : false}
                            onChange={handleChange}
                            disabled={props.disabled}
                            checked={props.checked}
                        />
                        <span className="switch round"></span>
                    </label>

                    <span className="toggle-label-enabled">{props.onLabel}</span>
                </div>

                {props.isError ? <span className="error">Please select a value.</span> : null}
            </div>
        </>
    );
};

export default Toggle;
