import * as React from "react";

const Button = (props: {
    label: string | JSX.Element,
    isSolid: boolean,
    elementId?: string,
    href?:string,
    additionalStyleClasses?: string,
    isDisabled?: boolean,
    clickAction?:React.MouseEventHandler<HTMLButtonElement>
}): JSX.Element => {
    const defaultProps = {
        isDisabled: false,
    };
    props = { ...defaultProps, ...props };
    const styleClass = props.isSolid ? 'solid-button' : 'clear-button';
    const additionalStyleClasses = props.additionalStyleClasses ? props.additionalStyleClasses : '';

    return (
        <>
            { props.href && <a href={props.href}><button className={styleClass + ' ' + additionalStyleClasses} id={props.elementId ? props.elementId : ''}>{props.label}</button></a> }
            { !props.href && <button disabled={props.isDisabled} onClick={props.clickAction} className={styleClass + ' ' + additionalStyleClasses} id={props.elementId ? props.elementId : ''}>{props.label}</button> }
        </>
    );
};

export default Button;
