import { getToken } from "../../helper-functions";
import useTrainableSpecialtiesByCert from "../../hooks/useTrainableSpecialtiesByCert";

// property and methods shared between participants and classes-active components.
export const pageSizes = [
    { value: 10, text: "10" },
    { value: 25, text: "25" },
    { value: 50, text: "50" },
    { value: 100, text: "100" }
];

export const pageSizesClassParticipants = [
    { value: 50, text: "50" },
    { value: 100, text: "100" },
    { value: 150, text: "150" },
    { value: 200, text: "200" }
];

export const pageSizesEnroll = [
    { value: 10, text: "10" },
    { value: 25, text: "25" },
    { value: 50, text: "50" },
    { value: 100, text: "100" },
    { value: 200, text: "200" }
];

export const expirationOffsets: { [key: string]: number } = {
    'expirationDate1': 6,
    'expirationDate2': 12,
    'expirationDate3': 18,
    'expirationDate4': 24,
    'expirationDate5': null
};

export const getTimeInSeconds = (hours: number | null, minutes: number | null): number | null => {
    let estimatedTimeHoursInSeconds: number;
    let estimatedTimeMinutesInSeconds: number;
    if (hours === null && minutes === null) {
        return null;
    } else {
        estimatedTimeHoursInSeconds = hours * 60 * 60;
        estimatedTimeMinutesInSeconds = minutes * 60;
        return estimatedTimeHoursInSeconds + estimatedTimeMinutesInSeconds;
    }
};

export const fetchInstructors = async (apimBaseUrl: string, configContext: any, organizationId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Certification/Organization/Instructors`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                "organizationId": organizationId,
                "pageNumber": 1,
                "total": 100
            })
        });
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchOrganizationBranchIds = async (apimBaseUrl: string, configContext: any, organizationId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Branch/Ids/${organizationId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchInstructorsCertifications = async (apimBaseUrl: string, configContext: any, organizationId: string, organizations: string[], userId: string, userIds?: string[]) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Certification/Organizations/Instructors`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationId,
                organizations,
                userIds,
                userId
            })
        });
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchPrograms = async (apimBaseUrl: string, configContext: any, userIds: string[]) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Certification/Users/Certifications`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                userIds,
            })
        });
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchAllPrograms = async (apimBaseUrl: string, configContext: any, culture: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Specialties?culture=${culture}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });

        const resp = await response.json();
        let allPrograms:string[] = [];

        if (Array.isArray(resp) && resp.length > 0) {
            resp.forEach((program) => {
                allPrograms.push(program.specialtyName);
            });
        }

        return allPrograms;
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchProgramsOrganizations = async (apimBaseUrl: string, apimKey: string, organizationIds: string[]) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Certification/Organizations/Certifications`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": apimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationIds,
            })
        });
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const returnInstructors = (instructors: any[] = []) => {
    return instructors && instructors.length > 0 ? instructors.map((instructor) => ({
        label: `${instructor.firstName} ${instructor.lastName}`,
        specialty: instructor.specialty,
        value: instructor.userId,
        disabled: false
    })) : [];
};

export const returnInstructorsCertifications = (instructors: any[] = []) => {
    const [trainablePrograms] = useTrainableSpecialtiesByCert(null);
    return instructors && instructors.length > 0 ? instructors.map((instructor) => {
        let allTrainablePrograms: any[] = [];
        if (Array.isArray(instructor?.certifications) && (instructor?.certifications.length > 0)) {
            instructor?.certifications.forEach((certification: any) => {
                let trainableProgramsOnThisSpecialty = trainablePrograms(certification, "", false);
                allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
            });
        }
        return {
            label: `${instructor.firstName} ${instructor.lastName}`,
            certifications: [...new Set(allTrainablePrograms)],
            value: instructor.userId,
            disabled: false
        };
    }) : [];
};

export const returnPrograms = (programs: any[] = []) => {
    return programs && programs.length > 0 ? programs.map(({ label, value }) => ({
        label,
        value
    })) : [];
};

export const returnOrganizations = (organizations: any[] = []) => {
    return organizations && organizations.length > 0 ? organizations.map((org: any) => {
        let orgLabel = org.organizationName;
        if (org.redundantName) {
            orgLabel = `${orgLabel} ${org.city}`;
        }
        return { label: orgLabel, value: org.organizationId }
    }) : [];
};