import * as React from "react";
import FormCheckbox from "../../../Interfaces/Platform/FormCheckbox";
import Checkbox from "./check-box";
import { SkillSubcategory } from "../../../Interfaces/ClassSkillModel";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { publish, subscribe, unsubscribe } from "../../../Pages/Utils/events";

// TODO: consider creating the hierarchy out of this file
const NestedCheckboxes = (props: {
    subcategory: SkillSubcategory,
    currentCategoryName: string,
    isReadOnly?: boolean,
    currentSubcategoryId: number
}): JSX.Element => {
    const { tp } = useParsedTranslation();
    const [checkboxes, setCheckboxes] = React.useState<FormCheckbox[]>([]);
    const checkboxesRef = React.useRef(checkboxes);
    checkboxesRef.current = checkboxes;

    /**
     * Handles the logic to check / uncheck a checkbox and/or its children & parent
     * @param id current checkbox's id
     * @param parentId current checkbox's parent id
     * @returns 
     */
    const handleCheckboxOnChange = (id: string, parentId: string):void => {
        const checkboxesToUpdate: any[] = [];
        let checkbox: FormCheckbox | undefined = checkboxes.find((checkbox) => checkbox.id === id);
        if (!checkbox) {
            for (let currentCheckbox of checkboxes) {
                checkbox = currentCheckbox.childrenNodes.find((checkbox) => checkbox.id === id);
                if (checkbox) break;
            }
        }

        if (!checkbox) return;

        checkbox.isChecked = !checkbox.isChecked;
        
        if (checkbox.isChecked) publish("checkboxSelectedTrue", props.currentCategoryName);
        
        // TODO: evaluate after creating the custom Checkbox component
        checkbox.booleanChecked = checkbox.isChecked ? "checked" : "unchecked";
        
        if (checkbox.isParent === undefined) checkboxesToUpdate.push({ 
            id: checkbox.id,
            name: checkbox.name,
            checked: checkbox.isChecked,
            category : props.currentCategoryName,
            subCategory: props.subcategory.subcategory
        });

        checkbox.childrenNodes.forEach((currentCheckbox) => {
            currentCheckbox.isChecked = checkbox?.isChecked ?? false;
            // TODO: evaluate after creating the custom Checkbox component
            currentCheckbox.booleanChecked = (checkbox?.isChecked ?? false) ? "checked" : "unchecked";

            checkboxesToUpdate.push({ 
                id: currentCheckbox.id,
                name: currentCheckbox.name,
                checked: currentCheckbox.isChecked,
                category : props.currentCategoryName,
                subCategory: props.subcategory.subcategory
            });
        });

        let parentCheckbox: FormCheckbox | undefined = checkboxes.find((checkbox) => checkbox.id === parentId);
        if (!parentCheckbox) {
            for (let currentCheckbox of checkboxes) {
                parentCheckbox = currentCheckbox.childrenNodes.find((checkbox) => checkbox.id === parentId);
                if (checkbox) break;
            }
        }
        
        let hasAtLeastOneChecked = false;
        if (parentCheckbox) {
            for (let currentCheckbox of parentCheckbox.childrenNodes) {
                if (currentCheckbox.isChecked) {
                    hasAtLeastOneChecked = true;
                    break;
                }
            }

            const totalOfChildren = parentCheckbox.childrenNodes.length;
            const totalOfCheckedChildren = parentCheckbox.childrenNodes.filter((checkbox) => checkbox.isChecked).length;

            parentCheckbox.isChecked = hasAtLeastOneChecked;
            if (totalOfCheckedChildren === 0) parentCheckbox.booleanChecked = "unchecked";
            if (totalOfCheckedChildren > 0 && totalOfCheckedChildren < totalOfChildren) parentCheckbox.booleanChecked = "dashed";
            if (totalOfCheckedChildren === totalOfChildren) parentCheckbox.booleanChecked = "checked";
        }

        if (checkboxesToUpdate.length > 0) publish("skillsSelected", checkboxesToUpdate);

        setCheckboxes([...checkboxes]);
    };

    // this is executed for every category/subcategory
    // runs on select/deselect all
    const handleCheckboxesSelection = (e:any) => {        

        const checkboxesToUpdate: any[] = [];
        const allCheckboxes: FormCheckbox[] = checkboxesRef.current.filter(check => check.parentCategoryName === e.detail.category);

        if (allCheckboxes.length === 0) return;

        for (let checkbox of allCheckboxes) {
           checkbox.isChecked = e.detail.allSelected;
            // TODO: evaluate after creating the custom Checkbox component
            checkbox.booleanChecked = checkbox.isChecked ? "checked" : "unchecked";

            if (checkbox.isParent === undefined) checkboxesToUpdate.push({ 
                id: checkbox.id,
                name: checkbox.name,
                checked: checkbox.isChecked,
                category : props.currentCategoryName,
                subCategory: props.subcategory.subcategory
            });

            checkbox.childrenNodes.forEach((currentCheckbox) => {
                currentCheckbox.isChecked = e.detail.allSelected;
                // TODO: evaluate after creating the custom Checkbox component
                currentCheckbox.booleanChecked = (checkbox?.isChecked ?? false) ? "checked" : "unchecked";

                checkboxesToUpdate.push({ 
                    id: currentCheckbox.id,
                    name: currentCheckbox.name,
                    checked: currentCheckbox.isChecked,
                    category : props.currentCategoryName,
                    subCategory: props.subcategory.subcategory
                });
            });
        }

        if (checkboxesToUpdate.length > 0) publish("skillsSelected", checkboxesToUpdate);
        setCheckboxes([...allCheckboxes]);
    }

    React.useEffect(() => {
        if (props.subcategory.skills.length === 1 && props.subcategory.subcategory === props.subcategory.skills[0].skillName) {
            // handles instances where subcategory has no children skills
            setCheckboxes([{
                id: props.subcategory.skills[0].skillId,
                label: tp(`Skills.${props.subcategory.skills[0].skillName}`),
                name: props.subcategory.skills[0].skillName,
                value: props.subcategory.skills[0].skillId,
                booleanChecked: (props.subcategory.skills[0].isChecked) ? "checked" : "unchecked",
                childrenNodes: [],
                isChecked: props.subcategory.skills[0].isChecked,
                isDisplayOnly: props.isReadOnly ?? false,
                parentCategoryName: props.currentCategoryName
            }]);
            // handles instance where this is a subcategory checkbox and has children beneath
            // this is where we need to handle prechecking only if a child checkbox is checked
        } else {
            const tempCheckboxes: FormCheckbox[] = [];
            const tempCheckbox: FormCheckbox = {
                id: props.currentSubcategoryId.toString(),
                label: tp(`Skills.${props.subcategory.subcategory}`),
                name: props.subcategory.subcategory,
                value: props.currentSubcategoryId.toString(),
                booleanChecked: (props.subcategory.skills.some((skill) => { skill.isChecked })) ? "checked" : "unchecked",                
                isChecked: props.subcategory.skills.some(skill => skill.isChecked),
                isDisplayOnly: props.isReadOnly ?? false,
                childrenNodes: [],
                parentCategoryName: props.currentCategoryName,
                isParent: true
            };
            tempCheckboxes.push(tempCheckbox);
            // handles children skills of subcategory
            props.subcategory.skills.map((skill) => (
                tempCheckbox.childrenNodes.push({
                    id: skill.skillId,
                    label: tp(`Skills.${skill.skillName}`),
                    name: skill.skillName,
                    value: skill.skillId,
                    booleanChecked: skill.isChecked ? "checked" : "unchecked",
                    childrenNodes: [],
                    isChecked: skill.isChecked,
                    isDisplayOnly: props.isReadOnly ?? false,
                    parentId: props.currentSubcategoryId.toString(),
                    parentCategoryName: props.currentCategoryName
                })
            ));

            setCheckboxes(tempCheckboxes);
        }
    }, [props.subcategory.skills]);

    React.useEffect(() => {
        subscribe("handleSelectAllCategory", handleCheckboxesSelection);   

        return () => {
            unsubscribe("handleSelectAllCategory", handleCheckboxesSelection);
        }
    }, []);

    const returnObject: JSX.Element[] = checkboxes.map((checkbox) => 
        <Checkbox
            checkbox={checkbox}
            handleCheckboxOnChange={handleCheckboxOnChange}
        />
    )
    
    return <>{returnObject}</>;
};

export default NestedCheckboxes;
