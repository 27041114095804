import * as React from "react";
import type AlertProperties from "../../Interfaces/Platform/AlertProperties";
import Alert from "../../Components/UI/alert";

export const returnPageLevelAlerts = (alerts:AlertProperties[] | ((alertProps: AlertProperties) => void)) => {
    let tempArray: AlertProperties[] = [];
    if (Array.isArray(alerts)) {
        tempArray = alerts;
    }

    return (
        tempArray.map((alert:AlertProperties) => (
            <Alert state={alert.alertLevel} arrowLink={alert.arrowLink} description={alert.description} canDismiss={alert.canDismiss} />
        ))
    );
};