import * as React from "react";
import { ITextInput } from "../../Interfaces/Platform/TextInput";
import AlertLevelString from "../../Interfaces/Platform/AlertLevelString";

const TextInput = (props: ITextInput): JSX.Element => {
    const defaultProps = {
        fieldClassNames: "",
        errorTextClassNames: "",
        type: "text",
        errorPosition: "",
        onKeyDown: () => {},
        displayAsText: false,
        showErrorLabel: true,
        onBlur: () => {},
        min: 0,
        removeMargin: false,
        showLabel: true
    };
    props = {...defaultProps, ...props};

    const alertLabel = props.errorLabel ? props.errorLabel : "Default Alert Text";

    const [isError, setIsError] = React.useState(props.isError);

    const optionalLabel = props.overrideOptionalLabel ? props.overrideOptionalLabel : "(Optional)";

    //function checkValidation(value:string) {
    //    if (props.isRequired && !value) {
    //        setIsError(true);
    //    } else {
    //        setIsError(false);
    //    }
    //};

    const fieldErrorBorderClass = props.isError ? " field-error" : "";

    return (
        <>
            <div>
                {props.showLabel && (
                    <>
                        {/* INPUT LABEL */}
                        <label className="semi-bold form-label fs14" htmlFor={props.id}>{props.label}</label>

                        {/* REQUIRED/OPTIONAL DISPLAY */}
                        { !props.isReadOnly && (props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="fs14 cpi-small optional-tag">{optionalLabel}</span>) }
                    </>
                )}  

                {/* MAIN INPUT ELEMENT */}                
                {                    
                    !props.isReadOnly ? 
                    /* NOT READONLY */
                    <input onKeyDown={typeof props.onKeyDown === 'function' ? props.onKeyDown : undefined} onBlur={typeof props.onBlur === 'function' ? props.onBlur : undefined} type={props.type} onChange={typeof props.changeAction === 'function' ? props.changeAction : undefined} maxLength={props.maxLength} min={props.min} className={`form-input ${props.removeMargin ? 'mt-0': 'mt-1'} d-block ${fieldErrorBorderClass} ${props.fieldClassNames}`} value={props.value} id={props.id} name={props.name} placeholder={props.placeholder}></input> :
                    /* READONLY */
                    !props.displayAsText ? (
                        <input className={"form-input readonly mt-1 d-block" + fieldErrorBorderClass} value={props.value} readOnly id={props.id} name={props.name} placeholder={props.placeholder}></input>
                    ) : (
                        /* READONLY AS TEXT */
                        <div className="mt-1 fw400">{props.value}</div>
                    )
                }

                {/* ERROR MESSAGE DISPLAY */}
                { props.showErrorLabel && props.isError ? <span className={`field-validation form-field-error small-error ${props.errorTextClassNames} ${props.errorPosition}`} id="firstName-required-label-1">{alertLabel}</span> : null }

                {/* OPTIONAL DESCRIPTION TEXT */}
                { props.description ?
                    <div className="small mt-2">{props.description}</div>
                : null }
            </div>
        </>
    );
};

export default TextInput;
