import { getToken } from "../../helper-functions";
import { OrganizationSeat } from "../../Interfaces/Platform/Classes";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";

export const returnOrganizationsSeats = (organizations: OrganizationSeat[] = []) => {
    return organizations && organizations.length > 0 ? organizations.map((org) => {
        const seats = `(${org.seats} seat${org.seats === 0 || org.seats > 1 ? 's ' : ' '}available)`;
        let orgLabel = `${org.organizationName}`;
        if (org.redundantName) {
            orgLabel = `${orgLabel} ${org.city} ${seats}`;
        } else {
            orgLabel = `${orgLabel} ${seats}`;
        }
        return { label: orgLabel, value: org.organizationId }
    }) : [];
};

export const fetchOrganizationSeats = async (configContext: any, payload: any) => {
    try {
        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/Q/Organization/Tree/Seats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204 || response.status === 400) return 0;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchTypesOfTraining = async (apimBaseUrl: string, configContext: any, specialty: string, culture: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Class/Types`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                specialty,
                culture
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchProgramEditions = async (apimBaseUrl: string, configContext: any, specialty: string, culture: string | null) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Class/Editions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                specialty,
                culture
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchTrainingFormats = async (apimBaseUrl: string, configContext: any, specialty: string, culture: string, additionalContent?: string, edition?: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Class/Formats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                specialty,
                culture,
                additionalContent,
                edition
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassLanguagesAndIndustries = async (apimBaseUrl: string, configContext: any, specialty: string, additionalContent?: string, edition?: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Class/Courses`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                specialty,
                additionalContent,
                edition
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchOrgLmsSettings = async (apimBaseUrl: string, configContext: any, organizationId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/LmsSettings/${organizationId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return {};
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassCourseObject = async (apimBaseUrl: string, configContext: any, specialty: string, edition: string, language: string, industry: string, additionalContent?: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/GetCourseObjectByClassSelections`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                specialty,
                edition,
                language,
                industry,
                additionalContent
            })
        });
        if (response.status === 404 || response.status === 204) return {};
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassSettings = async (apimBaseUrl: string, configContext: any, specialty: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/Class/Settings/${specialty}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return {};
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchAdditionalContent = async (apimBaseUrl: string, configContext: any, userIds: string[], specialty: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Certification/Users/Qualifications`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                userIds,
                specialty
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const returnTypesOfTraining = (types: string[]) => {
    return types && types.length > 0 ? types.map((type) => {
        if (type === "initial-refresher") {
            return {
                label: "Combined (Initial / Refresher)",
                value: type
            };
        }
        return {
            label: `${type.charAt(0).toUpperCase()}${type.slice(1)} Training`,
            value: type
        };
    }) : [];
};

interface TrainingFormats {
    [key: string]: string;
}
export const TRAINING_FORMATS: TrainingFormats = {
    classroom: 'Classroom Only',
    blended: 'Blended (Online Course + Classroom)',
    virtual: 'Online Course'
};

export const returnTrainingFormats = (formats: { classroom: boolean; blended: boolean; virtual: boolean }) => {
    const trainingFormats = Object.entries(formats).filter(([key, value]) => value);
    const areMultiple = trainingFormats.length > 1;
    return trainingFormats.map(([key, value], index) => {
        return {
            id: `trainingFormat-${index}`, checked: index === 0, label: TRAINING_FORMATS[key], value: key
        };
    });
};

export const returnAdditionalContent = (additionalContent: string[]):FormSelectOption[] => {
    return additionalContent && additionalContent.length > 0 ? additionalContent.sort().map((content) => ({
        label: content,
        value: content
    })) : [];
};

export const fetchSkills = async (apimBaseUrl: string, configContext: any, payload: any) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/skills`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        console.log(e);
    }
};

export interface ClassResponse {
    classId: string;
    webId: string;
    userId: string;
    organizationId: string;
    organizationName: string;
    className: string;
    specialty: string;
    specialtyEdition: string;
    qualification: any;
    lmsQualification: any;
    trainingType: string;
    expirationDate: string;
    trainingFormat: string;
    classroomFormat: any;
    desiredLms: any;
    seatOrganizationId: string;
    courseId: string;
    emailSend: any;
    emailScheduledDate: string;
    emailMessage: string;
    blueCardRequired: boolean;
    courseObjectId: string;
    courseName: string;
    contactHours: any;
    templateId: string;
    estimatedTime: any;
    scormId: string;
    modulesUrl: string;
    courseCulture: string;
    ceSubmitted: boolean;
    language: string;
    courseLanguage: string;
    industry: string;
    expirationOffset: number;
    endDate: string | Date;
    startDate: string;
    classSkills: any[];
    seatsRequired: boolean;
    thumbnailUrl: string;
    classInstructors: {
        classInstructorId: string;
        classId: string;
        userId: string;
        firstName: string;
        lastName: string;
        isPrimary: boolean;
    }[];
    classDates: {
        classDateId: string;
        classId: string;
        date: string;
        initialHours: number;
        refresherHours: number;
    }[];
}

export interface CreateClassPayload {
    classId?: string,
    userId: string;
    organizationId: string;
    organizationName: string;
    className: string;
    specialty: string;
    specialtyEdition: string;
    qualification?: any;
    lmsQualification?: any;
    trainingType: string;
    expirationDate: string;
    expirationOffset: number | null;
    trainingFormat?: string;
    classroomFormat?: any;
    desiredLms?: any;
    seatUsageOrganizationId?: string;
    seatUsageSku?: string;
    emailSend?: boolean;
    emailScheduledDate?: string;
    emailMessage?: string;
    blueCardRequired: boolean;    
    skills?: string[];
    endDate: string;
    thumbnailUrl: string;
    instructors: {
        userId: string;
        firstName: string;
        lastName: string;
        isPrimary: boolean;
    }[];
    classDates: {
        date: string;
        initialHours: string;
        refresherHours: string;
    }[];
}

export interface UpdateClassPayload {
    classId: string,
    className: string,
    specialty: string,
    specialtyEdition: string,
    qualification: string,
    trainingType: string,
    expirationDate: string,
    trainingFormat: string,
    classroomFormat: string,
    desiredLms: string,
    seatUsageOrganizationId: string,
    courseId: string,
    emailSend: boolean,
    emailScheduledDate: string,
    emailMessage: string,
    userId: string,
    organizationId: string,
    organizationName: string,
    blueCardRequired: boolean,
    language: string,
    industry: string,
    thumbnailUrl: string,
    instructors: {
        userId: string,
        deleted: boolean,
        firstName: string,
        lastName: string,
        isPrimary: true,
        classInstructorId: string
    }[],
    classDates: [
        {
            date: string,
            deleted: boolean,
            initialHours: number,
            refresherHours: number,
            classDateId: string
        }
    ],
    classSkills: {
        skillId: string,
        skillName: string
    }[]
}

export const createClass = async (apimBaseUrl: string, configContext: any, payload: CreateClassPayload): Promise<{ data: ClassResponse, status: number }> => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/CreateClass`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        //if (response.status === 404 || response.status === 204) return [];
        return {
            data: await response.json(),
            status: response.status
        }
    } catch (e) {
        throw new Error("There was a problem creating the class.");
    }
};

export const updateClass = async (apimBaseUrl: string, configContext: any, payload: UpdateClassPayload): Promise<{ data: ClassResponse, status: number }> => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/UpdateClass`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        //if (response.status === 404 || response.status === 204) return [];
        return {
            data: await response.json(),
            status: response.status
        };
    } catch (e) {
        throw new Error("There was a problem updating the class.");
    }
};
