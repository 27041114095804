import * as React from "react";

const Accordion = (props: {
    title: string,
    isOpen?: boolean,
    isComplete?: boolean,
    children:JSX.Element
}): JSX.Element => {

    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [isComplete, setIsComplete] = React.useState(props.isComplete? props.isComplete : false);

    const toggle = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        
    }

    React.useEffect(() => {
        setIsComplete(props.isComplete);
        setIsOpen(props.isOpen);
    }, [props.isOpen, props.isComplete]);

    return (
        <>
            <div className="accordion-section-wrapper">
                <div onClick={toggle} className="task-block task-rounded task-block-clickable task-block-accordion collapsed">

                    {isComplete ? <div className="task-banner-tag-container-accordion"><div className="task-banner show-banner"></div></div> : <></>}

                    <div className={isOpen ? "expanded-status-icon fa-sharp fa-light fa-minus" : "expanded-status-icon fa-sharp fa-light fa-plus"}></div>
                    <div className="task-block-content-container"><div className="task-title-text bold" >{props.title ? props.title : "Title"}</div></div>
                </div>

                <div className={isOpen ? "show accordion-content-container" : "hide accordion-content-container"}>{props.children}</div>
            </div>
        </>
    );

};

export default Accordion;
