import { getToken } from "../../helper-functions";
import { ExportParticipantsPayload } from "../../Interfaces/Platform/Participants";

export const fetchOrganizations = async (apimBaseUrl: string, configContext: any, orgId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Tree/Whole/${orgId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchOrganizationsIds = async (apimBaseUrl: string, configContext: any, orgId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Tree/Whole/Ids/${orgId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchParticipant = async (apimBaseUrl: string, configContext: any, participantId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Participant/${participantId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchParticipantByUserId = async (apimBaseUrl: string, configContext: any, userId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Participant/user/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchParticipantHistory = async (apimBaseUrl: string, configContext: any, participantId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/Participant/History/${participantId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchLearningHistory = async (apimBaseUrl: string, configContext: any, userId: string) => {
    try {
        const response = await fetch(apimBaseUrl + `/Q/Class/OnlineLearning/${userId}`, {
            method: "GET",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "content-type": "application/json",
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
        console.log(e);
    }
};

export const fetchClassParticipants = async (apimBaseUrl: string, configContext: any, classId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/Participants/${classId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchParticipantVerificationLetter = async (apimBaseUrl: string, culture: string, configContext: any, classParticipantId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/VerificationLetter`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({classParticipantId: classParticipantId, culture: culture})
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchParticipantOnlineCourseCertificate = async (apimBaseUrl: string, culture: string, configContext: any, learningHistoryIds: string[]) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/LearningHistory/Certificates`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({learningHistoryIds: learningHistoryIds, culture: culture})
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

interface ParticipantToCreate {
    organizationId: string;
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    externalId: string;
    orgId: string;
    locale: string;
    trainingFormat: string;
}

export const createParticipant = async (apimBaseUrl: string, configContext: any, payload: ParticipantToCreate) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Participant/CreateParticipant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({ ...payload, enrolled: false })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle error message
    }
};

interface UpdateParticipantData {
    participantId: string;
    firstName: string;
    lastName: string;
    email: string;
    organizationId: string;
    organizationName: string;
    externalId?: string;
    orgId: string;
    locale: string;
    enrolled: boolean;
    trainingFormat: string;
}

export const updateParticipant = async (apimBaseUrl: string, configContext: any, data: UpdateParticipantData) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Participant/UpdateParticipant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                ...data
            })
        });
        if (response.status === 200) {
            return response.json();
        }
        else {
            return null;
        }
    } catch (e) {
        throw new Error("There was a problem updating the participant, please try again.");
    }
};

interface TrainingKeyValue {
    [key: string]: string;
}
export const TRAINING_FORMATS: TrainingKeyValue = {
    classroom: 'Classroom Only',
    blended: 'Blended (Online Course + Classroom)',
    virtual: 'Online Course'
};

export const TRAINING_TYPES: TrainingKeyValue = {
    initial: "Initial",
    refresher: "Refresher",
    "initial-refresher": "Combined (Initial / Refresher)"
}

export interface SearchParticipantToEnroll {
    search: string | undefined;
    pageNumber: number;
    resultsPerPage: number;
    specialty: string;
    classId: string;
    sortBy: string;
    organizationIds: string[]
}

export const fetchParticipantToEnroll = async (apimBaseUrl: string, configContext: any, request: SearchParticipantToEnroll) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Participant/Search/Class`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                ...request
            })
        });
        if (response.status === 404 || response.status === 204) return { participants: [], totalResults: 0 };
        return await response.json();
    } catch (e) {
        // Handle error message
    }
};

interface ParticipantToEnroll {
    participantId: string;
    classId: string;
    locale: string;
    trainingFormat: string;
}

export const enrollParticipant = async (apimBaseUrl: string, configContext: any, payload: ParticipantToEnroll) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Participant/Enroll`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });

        
        if (response.status === 422) {
            return {
                status: response.status,
                data: response.statusText
            };
        }
        return {
            status: response.status,
            data: await response.json()
        };
    } catch (e) {
        // Handle error message
    }
};

interface ParticipantToCreateEnroll {
    //classId: string;
    //trainingFormat: string;
    organizationId: string;
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    organizationIds: string[];
    externalId: string;
    enrolled: boolean;
}

export const createEnrollParticipant = async (apimBaseUrl: string, configContext: any, payload: ParticipantToCreateEnroll) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Participant/CreateParticipant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle error message
    }
};

interface ClassCreateParticipant {
    classId: string;
    participantId: string;
    userId?: string;
    firstName: string;
    lastName: string;
    email?: string;
    externalId?: string;
    participantCreated: boolean;
    userCreated: boolean;
}

export const createClassParticipant = async (apimBaseUrl: string, configContext: any, payload: ClassCreateParticipant) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/CreateParticipant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status !== 202) return {
            status: response.status,
            data: response.statusText
        };;
        // 409 - Can't enroll (participant without email to blended class example)
        // 500 - General error
        return {
            status: response.status,
            data: await response.json()
        };
    } catch (e) {
        // Handle error message
    }
};

export const exportParticipants = async (apimBaseUrl: string, configContext: any, request: ExportParticipantsPayload) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Participant/ExportList`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },  
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.blob()
    } catch (e) {
        // Handle error message
    }
};
