import * as React from "react";
import { useTranslation } from "react-i18next";

const KPIBlock = (props: {
    cycleTotal: number,
    lifetimeTotal: number,
    label: string,
    iconClass?: string
}): JSX.Element => {

    const { t } = useTranslation();

    return (
        <>
            <a className="link-unstyled profile-header-highlight" href="/Training-Center/Instructor-Led-Classes" id="profileHeaderCertStatsClasses">
                <div className="profile-header-highlight-left">
                    <div className="profile-header-highlight-left-content-container">
                        <i className={"profile-header-highlight-icon fa-sharp fa-light " + props.iconClass}></i>
                        <div className="profile-header-highlight-number fs38 bold">{props.cycleTotal}</div>
                    </div>
                </div>
                <div className="profile-header-highlight-right">
                    <div className="profile-header-text-container">
                        <div className="profile-header-highlight-title cpi-bold-black">{props.label}</div>
                        <div>{t("CPI.MyAccount.Dashboard.CertStats.ThisCertCycle")}</div>
                    </div>
                    <div className="profile-header-highlight-seperator"></div>
                    <div className="profile-header-highlight-totals fs14">{props.lifetimeTotal} {t("CPI.MyAccount.Dashboard.CertStats.Total")}</div>
                </div>
            </a>
        </>
    );
};

export default KPIBlock;
