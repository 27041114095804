import * as React from "react";
import { useLocation } from "react-router-dom";

import { CpiRoute } from "../../Routing/routes";
import { allRoutes } from "../../Routing/routes";

import Crumb from "./crumb";

const Breadcrumbs = (): JSX.Element => {
    const location = useLocation();

    // Function to match the current path against the route's fullPath, considering wildcards
    const matchPath = (routePath: string, currentPath: string): boolean => {
        const routeSegments = routePath.split('/').filter(Boolean);
        const currentSegments = currentPath.split('/').filter(Boolean);

        if (routeSegments.length !== currentSegments.length) {
            return false;
        }

        return routeSegments.every((segment, index) => {
            return segment.startsWith(':') || segment === currentSegments[index];
        });
    };

    // Finds current CpiRoute from our all routes lookup based on our current URL
    const currentRoute: CpiRoute = allRoutes.find(route => matchPath(route.fullPath, location.pathname)) || { path: "", fullPath: "" };

    return (
        <>
            <div className="breadcrumb-navigation d-none d-md-block">
                <ul className="breadcrumb-link-list">

                    <Crumb route={currentRoute} />
                    
                </ul>
            </div>
        </>
    );
};

export default Breadcrumbs;
