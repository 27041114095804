import * as React from "react";
import { InstructorModel } from "../Pages/manage-classes";

interface Props {
    instructors: InstructorModel[];
    onSelectInstructor: (instructorId: string) => void;
}

const InstructorSelect = (props: Props): JSX.Element => {
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedInstructorId = e.target.value;
        props.onSelectInstructor(selectedInstructorId);
    };

    return (
        <div>
            <label htmlFor="instructor" className="form-label bold fs14">Instructor</label>
            <div className="block-input">
                <select onChange={handleSelectChange} className="form-control w-33">        
                    <option value="">Select an instructor</option>
                    {props.instructors.map((instructor) => (
                        <option key={instructor.userId} value={instructor.userId}>
                            {instructor.firstName} {instructor.lastName}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default InstructorSelect;