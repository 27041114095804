import i18n from "i18next";

export enum StatusEnum {
    NotStarted = 1,
    InProgress = 2,
    Completed = 3,
    Preview = 4,
    History = 5,
    Failed = 6
}

export namespace StatusEnum {
    export function toString(status: StatusEnum) {
        switch (status) {
            case StatusEnum.History:
                return i18n.t("History");
            case StatusEnum.Completed:
                return i18n.t("Completed");
            case StatusEnum.InProgress:
                return i18n.t("In Progress");
            case StatusEnum.NotStarted:
                return i18n.t("Not Started");
            case StatusEnum.Failed:
                return i18n.t("Failed");
            default:
                return i18n.t("Not Started");
        }
    }

    export function translateString(status: string) {
        switch (status) {
            case "History":
                return i18n.t("History");
            case "Completed":
                return i18n.t("Completed");
            case "In Progress":
                return i18n.t("In Progress");
            case "Not Started":
                return i18n.t("Not Started");
            case "Failed":
                return i18n.t("Failed");
            default:
                return i18n.t("Not Started");
        }
    }

    export function getButtonLabel(status: StatusEnum) {
        switch (status) {
            case StatusEnum.History:
                return i18n.t("CourseListing.PrintCertificate");
            case StatusEnum.Completed:
                return i18n.t("CourseListing.PrintCertificate");
            case StatusEnum.InProgress:
                return i18n.t("CourseListing.Resume");
            case StatusEnum.NotStarted:
                return i18n.t("CourseListing.StartCourse");
            case StatusEnum.Failed:
                return i18n.t("CourseListing.Incomplete");
            default:
                return i18n.t("CourseListing.StartCourse");
        }
    }
}
