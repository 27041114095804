import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import Button from "../../Components/UI/button";

const EvaluationsAndPostTrainingResponsibilities = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>            
            {/* WRITE CODE BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1 title-margin">{ t('Eval-PostTraining-Title') }</h1>
                                <p className="mw-900 mt-3">{ t('Eval-PostTraining-Intro') }</p>
                                <Tile title={ t('Eval-PostTraining-Expectations-Header') } hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <TwoColumnLayout
                                    left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <p>{ tp('Eval-PostTraining-Expectations-Intro') }</p>
                                        
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>{ t('Eval-PostTraining-Expectations-Note') }</p>
                                            </>
                                        </GrayTile>

                                    </>
                                        }

                                    right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <span>{ tp('Eval-PostTraining-Expectations-Grading') }</span>
                                    
                                    </>
                                        }
                                    />
                                    </>      
                                </Tile>
                                <Tile title="Training Documentation" hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <OneColumnLayout
                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <p>{ t('Eval-PostTraining-TrainingDoc') }</p>
                                                    <Button label={ t('BTN-Document-Class') } isSolid={false} elementId="DocumentClass" href="/Dashboard/Classes" />
                                                </>
                                            }
                                        />
                                        
                                        
                                        <TwoColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <span>{ tp('Eval-PostTraining-IssueBlueCard') }</span>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <span>{ tp('Eval-PostTraining-ParticipantOtherOrg') }</span>
                                                </>
                                            }
                                        />
                                    
                                    </>      
                                </Tile>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default EvaluationsAndPostTrainingResponsibilities;
