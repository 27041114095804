import * as React from "react";

import { LearnerCourseDetails } from "../Interfaces";
import ManyScoCourse from "./Components/many-sco-course";
import MultiScoCourse from "./Components/multisco-course";
import Page from "./page";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { fetchLearnerCourse } from "../ApiServices/Learn";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const CoursePage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const params = useParams<{ classId: string }>();
    const [learnerCourse, setLearnerCourse] = React.useState<LearnerCourseDetails>(null);
    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [invalidCourse, setInvalidCourse] = React.useState(false);
    const [invalidCourseMessage, setInvalidCourseMessage] = React.useState("");

    const classId = parseInt(params.classId);

    React.useEffect(() => {
        const getLearnerCourse = async () => {
            const errorMessage = t("The course you are trying to view could not be found.");
            if (!isNaN(classId)) {
                try {
                    setLoading(true);
                    const learnerCourse = await fetchLearnerCourse(classId);
                    if (learnerCourse.Data) {
                        setLearnerCourse(learnerCourse.Data);
                    } else {
                        setInvalidCourse(true);
                        setInvalidCourseMessage(
                            learnerCourse.Error.Code === 404 ? errorMessage : learnerCourse.Error.Message
                        );
                    }
                } catch (err) {
                    appInsights.trackException({ error: err, properties: userContext });
                    console.error(err);
                    setErrored(true);
                }
            } else {
                setInvalidCourse(true);
                setInvalidCourseMessage(errorMessage);
            }
            setLoading(false);
        };

        getLearnerCourse();
    }, []);

    const refreshCourse = async () => {
        const learnerCourse = await fetchLearnerCourse(classId);
        setLearnerCourse(learnerCourse.Data);
    };

    return (
        <Page
            title={learnerCourse?.Name ?? t("Course")}
            hideSideNav={learnerCourse?.IsMultiSco}
            hideFooter={learnerCourse?.IsMultiSco}
            hideBanner
        >
            <>
                <PageMessages
                    loading={loading}
                    errored={errored}
                    notFound={invalidCourse}
                    notFoundMessage={invalidCourseMessage}
                />
                {!loading && !errored && !invalidCourse && learnerCourse.IsMultiSco && (
                    <MultiScoCourse launchUrl={learnerCourse?.LaunchUrl} />
                )}
                {!loading && !errored && !invalidCourse && !learnerCourse.IsMultiSco && (
                    <ManyScoCourse course={learnerCourse} refresh={refreshCourse} />
                )}
            </>
        </Page>
    );
};

export default withAITracking(reactPlugin, CoursePage);
