import * as React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tile from "../../Components/UI/tile";
import LoadingContent from "../../Components/UI/loading-content";
import { ConfigContext } from "../../configuration-context";
import CourseList from "../Components/online-courses/course-list";
import OnlineCourse from "../../Interfaces/OnlineCourse";
import { Organization } from "../../Interfaces/Platform/Participants";
import { fetchOrganizations } from "../Utils/participants";
import { CourseObject } from "../../Interfaces/Platform/courseObject";
import { OnlineCourseListType } from "../../Interfaces/Platform/OnlineCourseList";
import { getToken, getUserClaims } from "../../helper-functions";
import { RbacContext } from "../../rbac-context";

const MyOnlineCourses = () => {
    const { t } = useTranslation();
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const rbac = React.useContext(RbacContext);


    const [activeCoursesFromFetch, setActiveCoursesFromFetch]:any = React.useState([]);
    const [completedCoursesFromFetch, setCompletedCoursesFromFetch]:any = React.useState([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [organization, setOrganization] = React.useState<Organization>();
    
    const sortActiveCoursesFirst = (a:CourseObject, b:CourseObject) => {
        if (a.completedDate && !b.completedDate) { return -1 }
        else if (!a.completedDate && b.completedDate) { return 1 }
        else { return 0 }
    }

    const getUserOrganization = async function () {
        const userOrgId = rbac.userContext.SelectedOrganization.toString();
        const dataFromCall: Organization[] = await fetchOrganizations(apimBaseUrl, configContext, userOrgId);
        const userOrg = dataFromCall.find((org) => org.organizationId == userOrgId);
        setOrganization(userOrg);
    }

    //Todo: Work on endpoint call when ready, for getting Active and History Courses
    const fetchCourses = () => {
        setIsLoading(true);

            fetch(apimBaseUrl + "/Q/Class/OnlineLearning/" + getUserClaims().sub, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "content-type": "application/json",
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
                .then(resp => resp.json())
                .then(function (data) {

                    if (data && data.activeCourses && Array.isArray(data.activeCourses)) data.activeCourses.sort(sortActiveCoursesFirst);
                    if (data && data.activeCourses && Array.isArray(data.activeCourses)) setActiveCoursesFromFetch(data.activeCourses);
                    if (data && data.completedCourses && Array.isArray(data.completedCourses)) setCompletedCoursesFromFetch(data.completedCourses);

                    let scormCloudIds: string[] = [];
                    for (let i = 0; i < data.activeCourses.length; i++) {
                        if (!data.activeCourses[i].modulesUrl) {
                            scormCloudIds.push(data.activeCourses[i].learningHistoryId);
                        }
                    }

                    if (scormCloudIds.length > 0) {
                        fetch(apimBaseUrl + "/C/Class/UpdateScormCloudProgress", {
                            method: "POST",
                            headers: {
                                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                                "content-type": "application/json",
                                "Authorization": `Bearer ${getToken("accessToken")}`
                            },
                            body: JSON.stringify({learningHistoryIds: scormCloudIds})
                        })
                        .catch(function (er) {
                            console.error(er);
                        });
                    }
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(" :: ERROR :: ")
                    console.log(error);
                    setIsLoading(false);
                });
    };

    React.useEffect(() => {
        let courseObjArray:OnlineCourse[] = [];

        activeCoursesFromFetch.forEach((activeCourse) => {
            courseObjArray.push({
                CourseId: activeCourse.classId,
                Specialty: activeCourse.specialty,
                Edition: activeCourse.edition,
                Status: "NotStarted",
                Progress: {
                    CurrentLesson: 2,
                    TotalLessons: 3
                }
            });
        });
    }, [activeCoursesFromFetch]);

    React.useEffect(() => {
        let courseObjArray:OnlineCourse[] = [];

        completedCoursesFromFetch.forEach((completedCourse) => {
            courseObjArray.push({
                CourseId: completedCourse.classId,
                Specialty: completedCourse.specialty,
                Edition: completedCourse.edition,
                Status: "NotStarted",
                Progress: {
                    CurrentLesson: 2,
                    TotalLessons: 3
                }
            });
        });
    }, [completedCoursesFromFetch])

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            fetchCourses();
        }
        getUserOrganization();
    }, [configContext?.SystemConfiguration?.ApimKey]);

    return (
        <>
        <div className="page-content m-auto">
            <Container fluid>
                <div className="mt-4 mb-4 classes-row">
                    <h1 className="page-title-h1">{ t("Dashboard.MyOnlineCourses") }</h1>
                </div>
                <Tile title={ t("MyTraining.ActiveCourses") }>
                    <>
                    {isLoading ? (
                        <LoadingContent
                            title="Loading"
                            icon={
                                <div className="loading-content-participants">
                                    <i className="fas fa-duotone fa-spinner icon" />
                                </div>
                            }
                        />
                    ) : (
                                    activeCoursesFromFetch.length > 0 ? <CourseList listType={{listType: "active"}} courses={activeCoursesFromFetch} organization={organization} userCulture={getUserClaims().culture}></CourseList>
                        : <div className="mt-4">{t('MyOnlineCourses.NoActiveCourses')}</div>
                    )}
                    </>
                </Tile>
                <Tile title={ t("MyTraining.History") }>
                    <>
                    {isLoading ? (
                        <LoadingContent
                            title="Loading"
                            icon={
                                <div className="loading-content-participants">
                                    <i className="fas fa-duotone fa-spinner icon" />
                                </div>
                            }
                        />
                    ) : (
                                    completedCoursesFromFetch.length > 0 ? <CourseList listType={{listType: "history"}} courses={completedCoursesFromFetch} organization={organization} userCulture={getUserClaims().culture}></CourseList>
                        : <div className="mt-4">{t('MyOnlineCourses.NoCompletedCourses')}</div>
                    )}
                    </>
                </Tile>
            </Container>
        </div>
    </>
    );
};

export default MyOnlineCourses;
