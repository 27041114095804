import * as React from "react";

import PopUpModal from "./modal-pop-up";
import { forwardRef, useRef, useImperativeHandle } from "react";

const IFrameVideoEmbed = forwardRef((
    props: {
        modulesUrl: string,
        modalId?: string
    },
    ref: any
) => {

    const [ showVideoPlayer, setShowVideoPlayer ] = React.useState(false);

    useImperativeHandle(ref, () => ({
        openModal() {
            setShowVideoPlayer(true);
        }
    }));
    

    const closeModal = () => {
        setShowVideoPlayer(false);
    }

    const openModal = () => {
        setShowVideoPlayer(true);
    }

    const [iframeSize, setIframeSize] = React.useState({
        height: window.innerHeight * 0.9,
    });
    
    const updateIframeSize = () => {
        setIframeSize({
            height: window.innerHeight * 0.9,
        })
    }

    React.useEffect(() => {
    window.addEventListener('resize', updateIframeSize)

    return () => window.removeEventListener('resize', updateIframeSize)
    }, [])

    return (
        <>
            <PopUpModal 
                modalId={(props.modalId) ? props.modalId : ""} 
                className="course-modal"
                content={
                <iframe id="iFrame" 
                    style={{
                        width: `100%`,
                        height: `${iframeSize.height}px`,
                    }}
                    src={props.modulesUrl} 
                    allowFullScreen>
                </iframe>} 
                show={showVideoPlayer}
                hide={closeModal}
                autoHide={() => {}}
                size="lg"
                />
        </>
    );
});

export default IFrameVideoEmbed;
