import { deviceType, mobileModel, osVersion } from "react-device-detect";
import { ConfigurationContext, UserContext } from "./Interfaces";
import { getLang } from "./locale-utils";

export const pendoIdentify = (userContext: UserContext, configContext: ConfigurationContext) => {
    const pendoRoles = ['Certified Instructor', 'Business Administrator', 'Learner', 'Enrollee', 'Staff Member'];
    const listRoles = userContext.RolesByName;
    const replacedRoles = listRoles.map(function (role) {
        return role == 'CI' ? 'Certified Instructor' : role == 'BA' ? 'Business Administrator' : role == 'Learner' ? 'Learner' : role == 'Enrollee' ? 'Enrollee' : role == 'Global Admin' ? 'Staff Member' : role == 'Admin' ? 'Staff Member' : role == 'Can Impersonate' ? 'Staff Member' : role == 'Staff Seat Adjustor' ? 'Staff Member' : role == 'Customer Care' ? 'Staff Member' : "";
    });
    const cleanedRoles = replacedRoles.filter(role => role !== 'Staff Member');
    if (configContext.SystemConfiguration.CPIPopulationId == userContext.Population) {
        cleanedRoles.push('Staff Member');
    }
    const filteredRoles = pendoRoles.filter((word) => cleanedRoles.includes(word));
    const displayedRoles = filteredRoles.toString().replace(/,/g, ' | ');
    try {
        pendo?.identify({
            visitor: {
                id: userContext.UserId,
                CustomerId: userContext.CustomerId,
                Email: userContext.Email,
                Name: `${userContext.FirstName} ${userContext.LastName}`,
                Role: displayedRoles,
                Culture: getLang(),
                DeviceType: deviceType,
                DeviceModel: mobileModel,
                OSVersion: osVersion,
                ScreenWidth: window.screen.width,
                ScreenHeight: window.screen.height
            },
            account: {
                id: userContext.OrganizationNumber ?? "N/A",
                Name: userContext.OrganizationName ?? "N/A",
                Industry: userContext.OrganizationIndustry ?? "N/A",
                Location: userContext.Country ?? "N/A"
            }
        });
    }
    catch (ex) { console.log(ex); }
};

export const resetPendo = () => {
    try {
        pendo?.identify({
            visitor: { id: "0" },
            account: { }
        });
    }
    catch (ex) { console.log(ex); }
};

export const setPendoCulture = (selectedCulture: string) => {
    try {
        pendo?.updateOptions({
            visitor: { Culture: selectedCulture }
        });
    }
    catch (ex) { console.log(ex); }
};
