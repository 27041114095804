import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ClassParticipant, IClassParticipant, IClassParticipantsResponse } from "../Interfaces/Platform/Participants";

interface InitialState {
    classParticipants: ClassParticipant[];
    participants: IClassParticipant[];
    pagination: {
        total: number;
        current: number;
        size: number;
    };
    participantsAlreadySubmitted: number;
    isEmpty: boolean;
}

const initialState: InitialState = {
    classParticipants: [],
    participants: [],
    pagination: {
        total: 0,
        current: 1,
        size: 100,
    },
    participantsAlreadySubmitted: 0,
    isEmpty: true
}

interface ClassParticipantsResponse extends IClassParticipantsResponse {
    classParticipants: ClassParticipant[];
}

export const classParticipantsSlice = createSlice({
    name: 'classDetails',
    initialState,
    reducers: {
        clearClassParticipants: (state) => {
            state.classParticipants = [];
            state.participants = [];
            state.pagination = {
                total: 0,
                current: 1,
                size: 100
            };
            state.isEmpty = true;
            state.participantsAlreadySubmitted = 0;
        },
        loadClassParticipantsPayload: (state, action: PayloadAction<ClassParticipantsResponse>) => {
            // SAVING CLASS PARTICIPANTS FROM RESPONSE TO STORE
            state.classParticipants = action.payload.classParticipants;
            state.participants = action.payload.participants;
            state.pagination.total = action.payload.total;
            state.isEmpty = action.payload.total === 0;
        },
        // Fix any type to new response from CreateParticipant / Class Service
        enrollClassParticipant: (state, action: PayloadAction<any>) => {
            state.pagination.total += 1;
            state.classParticipants = [
                action.payload,
                ...state.classParticipants
            ];
            state.participants = [
                action.payload,
                ...state.participants
            ];

            if (state.participants.length > state.pagination.size) {
                /* 
                    Simulates moving last participant in list to next page
                    Removes last participant in list if the page size and current class participants have the same length and one more is enrolled
                */
                state.participants = state.participants.slice(0, -1);
            }
            state.isEmpty = state.participants.length === 0;
        },
        removeClassParticipant: (state, action: PayloadAction<string>) => {
            state.pagination.total -= 1;
            state.participants = state.participants.filter((i) => i.participantId !== action.payload);
            state.classParticipants = state.classParticipants.filter((i) => i.participantId !== action.payload);
            state.isEmpty = state.participants.length === 0;
        },
        handleCheckParticipant: (state, action: PayloadAction<{ checked: boolean, id: string }>) => {
            const newState = [...state.participants];
            const selected = newState.findIndex((participant) => participant.classParticipantId === action.payload.id);
            newState[selected].isChecked = action.payload.checked;
            state.participants = newState;
        },
        handleEdit: (state, action: PayloadAction<string>) => {
            const newState = [...state.participants];
            const selected = newState.findIndex((participant) => participant.classParticipantId === action.payload);
            newState[selected].isEdit = !newState[selected].isEdit;
            state.participants = newState;
        },
        handleEditData: (state, action: PayloadAction<{ classParticipantId: string, data: string, value: string }>) => {
            state.participants = state.participants.map((i) => {
                if (i.classParticipantId === action.payload.classParticipantId) {
                    return {
                        ...i,
                        [action.payload.data]: action.payload.value
                    }
                } else {
                    return i;
                }
            });
        },
        handleUpdateBlueCardId: (state, action: PayloadAction<{ classParticipantId: string, blueCardId: string }>) => {
            const newState = [...state.participants];
            const participantToUpdate = newState.findIndex((i) => i.classParticipantId === action.payload.classParticipantId);
            
            newState[participantToUpdate].blueCardId = action.payload.blueCardId;
            state.participants = newState;
        },
        handleUpdateParticipantsSubmitted: (state, action: PayloadAction<string[]>) => {
            const newState = [...state.participants];
            action.payload.forEach((i) => {
                const participantToSubmit = newState.findIndex((idx) => idx.classParticipantId === i);
                if(participantToSubmit >= 0){
                    newState[participantToSubmit].ceSubmitted = true;
                    newState[participantToSubmit].dateSubmitted = new Date().toISOString();
                }
            });
            state.participants = newState;
        },
        handleUncheckParticipants: (state) => {
            state.participants = state.participants.map((i) => ({ ...i, isChecked: false }));
        },
        handleCheckHeader: (state, action: PayloadAction<any[]>) => {
            state.participants = action.payload;
        },
        updatePagination: (state, action: PayloadAction<any>) => {
            state.pagination = action.payload;
        },
        updateParticipantInfo: (state, action: PayloadAction<any>) => {
            const classParticipantIndex = state.classParticipants.findIndex((classParticipant) => classParticipant.participantId === action.payload.participantId);
            const participantIndex = state.participants.findIndex((participant) => participant.participantId === action.payload.participantId);

            if (classParticipantIndex !== -1) {
                const classParticipants = [...state.classParticipants];
                classParticipants[classParticipantIndex].firstName = action.payload.firstName;
                classParticipants[classParticipantIndex].lastName = action.payload.lastName;
                classParticipants[classParticipantIndex].email = action.payload.email;
                classParticipants[classParticipantIndex].externalId = action.payload.externalId;

                state.classParticipants = classParticipants;
            }

            if (participantIndex !== -1) {
                const participants = [...state.participants];
                participants[participantIndex].firstName = action.payload.firstName;
                participants[participantIndex].lastName = action.payload.lastName;
                participants[participantIndex].email = action.payload.email;
                participants[participantIndex].externalId = action.payload.externalId;

                state.participants = participants;
            }
        },

        /* EDIT REDUCERS: we can use a collection of reducers here that handle the editing of various fields on a class.
        
        With edit-class in particular, there are cases where editing certain fields will need to clear field values further down the form.
        In a particular field's reducer function here - when updating it's value - should also clear the values of affected attributes.
        
        */
    }
});

export const {
    loadClassParticipantsPayload,
    enrollClassParticipant,
    removeClassParticipant,
    handleCheckParticipant,
    handleEdit,
    handleEditData,
    handleUpdateBlueCardId,
    handleUpdateParticipantsSubmitted,
    handleUncheckParticipants,
    handleCheckHeader,
    updatePagination,
    updateParticipantInfo,
    clearClassParticipants
} = classParticipantsSlice.actions;
export default classParticipantsSlice.reducer;