import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import VideoEmbed from "../../Components/UI/video-embed";

const NCIThirdEdition = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}


            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1 title-margin">Nonviolent Crisis Intervention, 3rd Edition</h1>
                                </>
                            </>
                        }
                    />
                    <EightByFour
                        left={
                            <>
                                {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                <p>3rd Edition features updated content and flow that make it easier to teach, learn, and apply. Among the updates you’ll notice there’s a greater emphasis on understanding the role of brain science on risk behaviors, more focus on providing person-centered care inclusive of age, gender identity, and culture, and additional information is included on how trauma impacts behavior.</p>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                <div className="mt-3">
                                    <Button label="View Classroom Materials" isSolid={true} elementId="buttonId" href="/Dashboard" />

                                </div>

                            </>
                        }
                    />


                    <Tile title="Introduction" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                        <VideoEmbed maxWidth={{ mobile: "70%", tablet: "75%", laptop: "100%" }} title="Introduction Video" vimeoId="839700216" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-intro.png" />



                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                        <p className="mt-3">The introduction is meant to state the learning expectations and guidelines for the training. Connect the philosophy of the training to the participants’ values and relate the training to their experiences in the workplace.</p>
                                        <p>After completing this training, your staff will know how to:</p>
                                        <ul>
                                            <li>Use the philosophy of <em>Care, Welfare, Safety, and Security</em><sup>℠</sup> and a person-centered, trauma-informed approach when responding to a person in distress.</li>
                                            <li>Interpret distress behaviors and address the cause of the behavior to de-escalate the situation.</li>
                                            <li>Assess risk of behavior using the <em>Decision-Making Matrix</em><sup>℠</sup> and identify preventive strategies to mitigate risk.</li>
                                            <li>Identify and respond appropriately to various levels of crisis behaviors.</li>
                                            <li>Manage their emotional responses to distress behavior.</li>
                                            <li>Use communication skills to be supportive and strengthen interventions to de-escalate potential conflict situations.</li>
                                            <li>Use directive strategies when verbally intervening to de-escalate defensive behaviors.</li>
                                        </ul>
                                        <ul>
                                            <li>Use the <em>Decision-Making Matrix</em><sup>℠</sup> in the moment of Risk Behavior to determine safety interventions that represent a reasonable, proportionate, least restrictive, and last-resort course of action.</li>
                                            <li>Use safety intervention strategies to maximize safety and minimize harm in situations where behavior presents an imminent or immediate risk of harm to self or others.</li>
                                            <li>Apply a process for support and learning at the end of a crisis situation.</li>
                                            <li>Explain how you will consider the Physical Skills Review Framework and key legal and professional considerations when using non-restrictive disengagements and restrictive interventions.</li>
                                            <li>Describe and demonstrate use of non-restrictive disengagements and restrictive interventions for an individual presenting risk behavior.</li>
                                        </ul>
                                    </>
                                }
                            />


                        </>
                    </Tile>

                    <Tile title="Module 1: Understanding Behavior and Its Risk" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 1 Video" vimeoId="844034410" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod1.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">Module 1 is a new module that explains the science behind distress behaviors to help learners better understand why distress behaviors occur. Learners will see how their own behaviors and responses to challenging situations can positively or negatively impact challenging situations. And learners will be able to apply these concepts throughout training.</p>
                                        <ul>
                                            <li>Understand why distress behaviors occur by recognizing the ways our brains respond to stress and trauma.</li>
                                            <li>Begin to determine the risk behind behaviors to choose balanced and proportionate responses.</li>
                                            <li>Understand the impact that brain science has on our ability to meet the needs of those in our care.</li>
                                            <li>Show learners how to use this knowledge to better manage their own behavior and remain calm in a stressful situation.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="h4">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 40 minutes</p>
                                                <p>Blended: 20 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>

                    <Tile title="Module 2: The CPI Crisis Development Model℠" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 2 Video" vimeoId="844034679" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod2.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                        <p className="mt-3">The <em>CPI Crisis Development Model</em><sup>℠</sup> continues to be the foundation for all of CPI’s training programs. This module identifies behaviors using the Crisis Development Model℠ and will help learners apply the most effective approaches to each behavior level in order to prevent escalation.</p>
                                        <ul>
                                            <li>Understand the Integrated Experience, the importance of staff managing their emotions, and their impact on a crisis situation.</li>
                                            <li>Use the <em>Crisis Development Model</em><sup>℠</sup> to identify behaviors at different levels of a crisis moment.</li>
                                            <li>Define Integrated Experience and understand staff’s impact on a crisis situation.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 40 minutes</p>
                                                <p>Blended: 20 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>

                    <Tile title="Module 3: Integrated Experience" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 3 Video" vimeoId="844035080" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod3.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                        <p className="mt-3">Expands upon the ideas set down in Module 1 and 2. Learners will further explore underlying causes of behavior; recognize strategies for staff to maintain consistent, calm behavior in a crisis; and understand how the behavior of one person impacts the behavior of others.</p>
                                        <ul>
                                            <li>Identify factors that influence a person’s behavior and the importance of maintaining professionalism in the face of escalating behavior.</li>
                                            <li>Understand how your behavior and attitude can positively affect a situation through the concept of Integrated Experience.</li>
                                            <li>Stay rationally detached to help your “thinking brain” take charge of your “emotional brain” during moments of crisis.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 40 minutes</p>
                                                <p>Blended: 20 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title="Module 4: Supportive Communication Skills" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 4 Video" vimeoId="844035382" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod4.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">When a person is at the Anxiety level, your supportive communication is crucial to supporting them and preventing the crisis from escalating. Learners will discuss communication strategies and understand how different approaches positively or negatively impact an individual’s behavior.</p>
                                        <ul>
                                            <li>Better understand how a person in distress communicates their needs.</li>
                                            <li>Discuss communication strategies to assist staff in supporting an individual through anxiety.</li>
                                            <li>Practice awareness of position, posture, and proximity in the use of the <em>Supportive Stance</em><sup>℠</sup>.</li>
                                            <li>Apply steps that will help you prepare for a difficult conversation.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 60 minutes</p>
                                                <p>Blended: 25 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title="Module 5: Responding to Defensive Behaviors" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 5 Video" vimeoId="844035628" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod5.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">This module examines the defensive level in the CPI <em>Crisis Development Model</em><sup>℠</sup> and illustrates the stages of defensive escalation. Learners will use the <em>Verbal Escalation Continuum</em><sup>℠</sup> to help recognize behaviors at the defensive level and identify the appropriate de-escalation strategies to use for each stage.</p>
                                        <ul>
                                            <li>Identify different stages of defensive behavior using the <em>Verbal Escalation Continuum</em><sup>℠</sup>.</li>
                                            <li>Use our person-centered strategies and our knowledge of brain science to choose the appropriate intervention strategies that are most effective at each stage.</li>
                                            <li>Practice strategies, such as limit setting, in various defensive scenarios.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 65 minutes</p>
                                                <p>Blended: 45 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />


                        </>
                    </Tile>
                    <Tile title="Module 6: Safety Interventions" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 6 Video" vimeoId="844035878" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod6.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">This module examines the Risk Behavior level in the CPI <em>Crisis Development Model</em><sup>℠</sup>. The updates made here allow content to match standards used worldwide, and to provide a more accurate and effective approach in making decisions using the newly updated <em>Decision-Making Matrix</em><sup>℠</sup>.</p>
                                        <ul>
                                            <li>Understand the difference between Non-Restrictive and Restrictive interventions.</li>
                                            <li>Use the updated <em>Decision-Making Matrix</em><sup>℠</sup> to better understand risk and determine the appropriate safety intervention.</li>
                                            <li>Identify verbal and environmental non-restrictive safety interventions to maximize safety and minimize harm.</li>
                                            <li>Restrictive Interventions should only be used as a last resort, when reasonable and proportionate to the risk.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 35 minutes</p>
                                                <p>Blended: 25 minutes</p>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title="Module 7: Post-Crisis" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 7 Video" vimeoId="844036104" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod7.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">After a crisis, it is important to reestablish the relationship to create a sense of calm and safety. This module has been simplified for learners by removing the COPING acronym in favor of a much simpler explanation of: Supporting the Present, Understanding the Past, and Planning for the Future. A focus on restorative practices has been added to the module.</p>
                                        <ul>
                                            <li>Establish Therapeutic Rapport when a person is at Tension Reduction and explain how to learn from the incident to reduce the likelihood of future crisis behaviors.</li>
                                            <li>Learn to support the person in distress in the present, understand past events or factors that may have triggered the crisis and plan for the future by designing plans to prevent the behavior from occurring again.</li>
                                            <li>Learn to use the <em>Decision-Making Matrix</em><sup>℠</sup> as a debriefing tool to better understand the situation and our responses.</li>
                                            <li>Explain how to use a formal documentation process if a disengagement or restrictive intervention was used.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 50 minutes</p>
                                                <p>Blended: 30 minutes</p>

                                                <h3 className="sub-headings-h3 mt-4">Available Materials</h3>
                                                <ArrowLink data={{ label: "Critical Incident Reporting", href: "https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/Critical-Incident-Reporting.pdf", openInNewTab: true }} />
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />


                        </>
                    </Tile>
                    <Tile title="Safety Interventions: Disengagement Skills" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Safety Interventions: Disengagement Skills Video" vimeoId="844036409" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-disengagements.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">The primary focus of this module is to learn skills to keep yourself and others safe from injury if you encounter someone engaging in risk behavior. Updates to this module included embedded videos in the PowerPoint for ease of teaching and consistency. You are encouraged to use the classroom model videos to assist in your training of physical skills.</p>
                                        <ul>
                                            <li>Additional Turning Away disengagements are included to assist in turning someone away from danger.</li>
                                            <li>Describe and demonstrate use of CPI disengagement principles for risk behavior.</li>
                                            <li>Use the Physical Skills Review Framework to convey reasoning for the intervention used as a response to risk presented</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 90 minutes</p>
                                                <p>Blended: 90 minutes</p>
                                                <h3 className="sub-headings-h3 mt-4">Available Materials</h3>
                                                <ArrowLink data={{ label: "Training Videos", href: "https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/critical-incident-reporting.pdf", openInNewTab: true }} />

                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />

                        </>
                    </Tile>
                    <Tile title="Safety Interventions: Holding Skills" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Safety Interventions: Holding Skills Video" vimeoId="844036870" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-holding.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">Updates to this module include moving the Team Control skill to Advanced Physical Skills course after an independent risk assessment determined the risk to be associated with an Advanced level of risk. All of the interventions taught within this module are based on the concept that a team approach is used to maximize the <em>Care, Welfare, Safety, and Security</em>℠ of everyone when aggressive or violent individuals are held using safety interventions.</p>
                                        <ul>
                                            <li>Understand a range of intervention strategies to maintain safety for staff and the individual.</li>
                                            <li>Use risks analysis to choose balanced and proportionate responses.</li>
                                            <li>Describe and demonstrate use of CPI holding principles to maintain safety when faced with risk behavior.</li>
                                            <li>Use the Physical Skills Review Framework to convey reasoning for the intervention used as a response to risk behavior presented.</li>
                                            <li>Understanding the Risks of Restraints has been moved from the appendix to a formal part of this module.</li>
                                            <li>Learn to use the Risks of Restraints and the <em>Opt-Out Sequence</em><sup>℠</sup> when using the CPI holding principles to maintain safety.</li>
                                        </ul>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 150 minutes</p>
                                                <p>Blended: 130 minutes</p>
                                                <h3 className="sub-headings-h3 mt-4">Available Materials</h3>
                                                <ArrowLink data={{ label: "Training Videos", href: "https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/critical-incident-reporting.pdf", openInNewTab: true }} />
                                                <ArrowLink data={{ label: "Risks of Physical Restraints", href: "risks-of-physical-restraints", openInNewTab: false }} />
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title="Conclusion and Assessment" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Introduction Video" vimeoId="844037042" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-conclusion.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                        <p className="mt-3">Summarize the learning that has taken place during your training of this course. By the end of the program, your staff will have gained a whole new set of tools and understanding to identify levels of behavior and implement strategies to de-escalate a crisis at the earliest stages.</p>
                                        <p>Training your colleagues to create a workplace culture that is person-centered, trauma-informed, and geared toward safety is no easy task. We are grateful for all of the work you do to promote <em>Care, Welfare, Safety, and Security</em><sup>℠</sup> in the world.</p>
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3">Recommended Time</h3>
                                                <p className="mb-1">Classroom: 30 minutes</p>
                                                <p>Blended: 15 minutes</p>
                                                <h3 className="sub-headings-h3 mt-4">Available Materials</h3>
                                                <ArrowLink data={{ label: "Verbal Intervention Checklist", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Physical-Skills/CHECKLIST_VI_3E_Verbal_Intervention_Checklist.pdf", openInNewTab: true }} />
                                                <ArrowLink data={{ label: "Safety Intervention Checklist", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Physical-Skills/CHECKLIST_NCI_3E_Safety_Intervention_Checklist.pdf", openInNewTab: true }} />

                                            </>
                                        </GrayTile>

                                    </>
                                }
                            />


                        </>
                    </Tile>

                    <Tile title="Classroom Materials" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                        <img className="gray-border-thin mt-2" width="150" src="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/THUMB_NCI_3E_IG.jpg" alt="Nonviolent Crisis Intervention Instructor Guide" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <ArrowLink data={{ label: "Instructor Guides & Presentations", href: "", openInNewTab: false }} />
                                        <ArrowLink data={{ label: "Workbooks & Seats", href: "", openInNewTab: false }} />
                                        <ArrowLink data={{ label: "Training Videos", href: "", openInNewTab: false }} />

                                    </>
                                }
                            />
                        </>
                    </Tile>

                </Container>
            </div>

        </>
    );
};

export default NCIThirdEdition;
