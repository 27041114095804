import { useEffect, useState } from "react";

const useDetectWindowSize = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isLaptop, setIsLaptop] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);

    // --breakpoint-md = 576px;
    const bootstrapBreakpointSm = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-sm")); 

    // --breakpoint-md = 768px;
    const bootstrapBreakpointMd = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-md"));

    // --breakpoint-lg = 992px;
    const bootstrapBreakpointLg = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-lg"));

    // --breakpoint-xl = 1200px;
    const bootstrapBreakpointXl = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-xl"));

    const detectWindowSize = () => {
        setIsMobile(window.innerWidth <= 991);
        setIsTablet(window.innerWidth >= bootstrapBreakpointLg && window.innerWidth < bootstrapBreakpointXl);
        setIsLaptop(window.innerWidth >= 1280);
    };
    useEffect(() => {
        window.addEventListener("resize", detectWindowSize);
        detectWindowSize();
        return () => window.removeEventListener("resize", detectWindowSize);
    }, []);
    return { isMobile, isLaptop, isTablet } as const;
};

export default useDetectWindowSize;
