import * as React from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import TextInput from "../../../Components/Form/text-input";
import { ConfigContext } from "../../../configuration-context";
import DestructiveButton from "../../../Components/UI/cancel-button";
import Select from "../../../Components/Form/select";
import Button from "../../../Components/UI/button";
import useForm from "../../../hooks/useForm";
import { fetchOrganizations, createEnrollParticipant, createClassParticipant } from "../../Utils/participants";
import FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import { returnOrganizations } from "../../Components/DashboardPlatformUtils";
import { IClassDetails } from "../../../Interfaces/Platform/Classes";
import validateEmailRules from "../../../FormValidationRules/createAndEnrollParticipantWithEmail";
import validateWithoutEmailRules from "../../../FormValidationRules/createAndEnrollParticipantWithoutEmail";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import { getUserClaims } from "../../../helper-functions";
import { useTranslation } from "react-i18next";
import { enrollParticipantToClass } from "../../../stores/classDetailsSlice";
import { enrollClassParticipant } from "../../../stores/classParticipantsSlice";

import { RbacContext } from "../../../rbac-context";

const CreateParticipant = (props: {
    getParticipants: (page: number) => void,
    handleEmailExisting: (search: string) => void,
    class: IClassDetails,
    organizations: any[],
    seats: number,
    addPageLevelAlert: any,
    setShow: (show: boolean) => void,
    alertMessageHandler?: any,
    showAlertMessage?: boolean,
    canEnrollParticipants: boolean
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const culture = getUserClaims()?.culture ?? "en-US";
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const { isMobile } = useDetectWindowSize();
    const [isLoading, setIsLoading] = React.useState(false);
    const [organizationOptions, setOrganizationOptions] = React.useState([]);

    const getOrganizations = async () => {
        const data = await fetchOrganizations(apimBaseUrl, configContext, rbac.userContext.SelectedOrganization.toString());
        setOrganizationOptions(returnOrganizations(data));
    };

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) getOrganizations();
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        if (organizationOptions && organizationOptions.length > 0) {
            const classOrg = organizationOptions.filter((organization) => organization.value === props.class.organizationId)[0];
            if (classOrg && classOrg.value) {
                handleChangeByGetEvent({
                    organizationId: classOrg.value,
                    organizationName: classOrg.label
                });
            } else {
                handleChangeByGetEvent({
                    organizationId: organizationOptions[0].value,
                    organizationName: organizationOptions[0].label
                });
            }
        }
    }, [organizationOptions]);

    const handleChangeOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            organizationId: e.value,
            organizationName: e.label
        });
    };

    const handleCreateEnrollParticipant = async () => {
        setIsLoading(true);
        const participantData = {
            externalId: formFieldData.externalId,
            firstName: formFieldData.firstName,
            lastName: formFieldData.lastName,
            email: formFieldData.email,
            organizationId: formFieldData.organizationId,
            orgId: props.organizations.filter((i) => i.organizationId === formFieldData.organizationId)[0].orgId,
            locale: culture,
            organizationName: formFieldData.organizationName
        };
        try {

            const enrolled = (((props.class.trainingFormat != 'classroom') && ((props.seats > 0) || !props.class.seatsRequired)) || (props.class.trainingFormat == 'classroom') && props.canEnrollParticipants);
            const enroll = await createEnrollParticipant(apimBaseUrl, configContext, {
                ...participantData,
                enrolled,
                trainingFormat: props.class.trainingFormat,
                organizationIds: [...organizationOptions.map((i) => i.value)],
            });
            if(enroll.participantStatus === 'Created' || enroll.participantStatus === 'CreatedEnrolled'){
                if (((props.class.trainingFormat != 'classroom') && ((props.seats > 0) || !props.class.seatsRequired)) || (props.class.trainingFormat == 'classroom')) {
                    const enrollClassParticipantResponse = await createClassParticipant(apimBaseUrl, configContext, {
                        ...enroll.participant,
                        classId: props.class.classId,
                        participantId: enroll.participant.participantId,
                        userId: enroll.participant.userId,
                        firstName: enroll.participant.firstName,
                        lastName: enroll.participant.lastName,
                        email: enroll.participant.email,
                        externalId: enroll.participant.externalId,
                        participantCreated: enroll.participant.participantCreated,
                        userCreated: enroll.participant.userCreated
                    });
                    if (enrollClassParticipantResponse.status === 202) {
                        dispatch(enrollParticipantToClass());
                        dispatch(enrollClassParticipant(enrollClassParticipantResponse.data));
                    }
                }
                setTimeout(() => {
                    // TODO: Handle Participants in Redux (Not Class Participants)
                    props.getParticipants(1);
                    props.setShow(false);
                    setIsLoading(false);
                    props.alertMessageHandler({
                        alertLevel: { alertLevel: 'error' },
                        arrowLink: null,
                        description: null,
                        canDismiss: null,
                        show: false
                    });
                }, 5000);
            } else if (enroll.participantStatus === 'AlreadyExists') {
                props.handleEmailExisting(participantData.email);
                setIsLoading(false);

                if(!props.showAlertMessage) {
                    props.alertMessageHandler({
                        alertLevel: { alertLevel: 'error' },
                        arrowLink: null,
                        description: t("Email-Already-Exists"),
                        canDismiss: true,
                        show: true
                    });
                }
            } else {
                setIsLoading(false);
                props.addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Something-Went-Wrong"),
                    canDismiss: true,
                });
            }
        } catch (e) {
            setIsLoading(false);
            props.addPageLevelAlert({
                alertLevel: { alertLevel: "error" },
                description: t("Something-Went-Wrong"),
                canDismiss: true
            });
        }
    };

    // We don't need to check for an existing email here since we are creating a new participant
    const isEmailRequired = (props.class.trainingFormat !== "classroom") && props.class.seatsRequired;

    const {
        formFieldData,
        errors,
        handleChangeByUserEvent,
        handleChangeByGetEvent,
        handleSubmit
    } = useForm(handleCreateEnrollParticipant, isEmailRequired ? validateEmailRules : validateWithoutEmailRules);

    if (isMobile) {
        return (
            <div className="enroll-create-participant-container container-mobile">
                <h5 className="mb-4 fw600">Create Participant</h5>
                <div className="enroll-create-participant-form-mobile" style={{ gap: 8 }}>
                    <div className="enroll-create-participant-form-row">
                        <div className="enroll-create-participant-form-label">
                            <label className="bold form-label" htmlFor="firstName">{t("First Name")}</label>
                            <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"/>
                        </div>
                        <div className="enroll-create-participant-form-input">
                            <TextInput
                                label=""
                                showLabel={false}
                                name="firstName"
                                id="firstName"
                                isError={errors.hasOwnProperty('firstName')}
                                value={formFieldData.firstName}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.firstName}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="enroll-create-participant-form-row">
                        <div className="enroll-create-participant-form-label">
                            <label className="bold form-label" htmlFor="firstName">{t("Last Name")}</label>
                            <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"/>
                        </div>
                        <div className="enroll-create-participant-form-input">
                            <TextInput
                                label=""
                                showLabel={false}
                                name="lastName"
                                id="lastName"
                                isError={errors.hasOwnProperty('lastName')}
                                value={formFieldData.lastName}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.lastName}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="enroll-create-participant-form-row">
                        <div className="enroll-create-participant-form-label">
                            <label className="bold form-label" htmlFor="firstName">{t("Email")}</label>
                            <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"/>
                        </div>
                        <div className="enroll-create-participant-form-input">
                            <TextInput
                                label=""
                                showLabel={false}
                                name="email"
                                id="email"
                                isError={errors.hasOwnProperty('email')}
                                value={formFieldData.email}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.email}
                                isRequired={isEmailRequired}
                            />
                        </div>
                    </div>                    
                    <div className="enroll-create-participant-form-row mt-1">
                        <div className="enroll-create-participant-form-label">
                            <label className="bold form-label" htmlFor="firstName">{t("Organization")}</label>
                            <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"/>
                        </div>
                        <div className="enroll-create-participant-form-input">
                            <Select
                                label=""
                                filter
                                showLabel={false}
                                name="organizationId"
                                id="organizationId"
                                options={organizationOptions}
                                value={formFieldData.organizationId}
                                isRequired
                                isError={errors.hasOwnProperty('organizationId')}
                                errorLabel={errors.organizationId}
                                changeFilterAction={handleChangeOrganization}
                            />
                        </div>
                    </div>
                    <div className="enroll-create-participant-form-row">
                        <div className="enroll-create-participant-form-label">
                            <label className="bold form-label" htmlFor="firstName">{t("External ID")}</label>
                            <span className="cpi-small optional-tag">(Optional)</span>
                        </div>
                        <div className="enroll-create-participant-form-input">
                            <TextInput
                                label=""
                                showLabel={false}
                                name="externalId"
                                id="externalId"
                                value={formFieldData.externalId}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.externalId}
                                isRequired={false}
                                isError={errors.hasOwnProperty('externalId')}
                            />
                        </div>
                    </div>
                </div>
                <div className="enroll-create-participant-row">
                    <DestructiveButton
                        clickAction={() => props.setShow(false)}
                        additionalStyleClasses="inline-icon-left cancel-button"
                        label={<><i className="fa-light fa-xmark fa-sharp" /> {t("Cancel")}</>}
                    />
                    <Button
                        elementId="create-participant-button"
                        isSolid={true}
                        isDisabled={isLoading}
                        label={
                            <div className="d-flex align-items-center justify-content-center">
                                {isLoading && <i className="fas fa-spinner fa-spin mr-2"/>} { (((props.class.trainingFormat != 'classroom') && (props.seats > 0)) || (props.class.trainingFormat == 'classroom')) ? "Create & Enroll Participant" : "Create Participant" }
                            </div>
                        }
                        clickAction={handleSubmit}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="enroll-create-participant-container">
            <h5 className="mb-4 fw600">Create Participant</h5>
            <div className="enroll-create-participant-form align-items-end mb-3">
                <div className="enroll-create-participant-input mb-3">
                    <TextInput
                        label={t("First Name")}
                        name="firstName"
                        id="firstName"
                        isError={errors.hasOwnProperty('firstName')}
                        value={formFieldData.firstName}
                        changeAction={handleChangeByUserEvent}
                        errorLabel={errors.firstName}
                        errorPosition="position-absolute"
                        isRequired
                    />
                </div>
                <div className="enroll-create-participant-input mb-3">
                    <TextInput
                        label={t("Last Name")}
                        name="lastName"
                        id="lastName"
                        isError={errors.hasOwnProperty('lastName')}
                        value={formFieldData.lastName}
                        changeAction={handleChangeByUserEvent}
                        errorLabel={errors.lastName}
                        errorPosition="position-absolute"
                        isRequired
                    />
                </div>
                <div className="enroll-create-participant-input mb-3">
                    <TextInput
                        label={t("Email")}
                        name="email"
                        id="email"
                        isError={errors.hasOwnProperty('email')}
                        value={formFieldData.email}
                        changeAction={handleChangeByUserEvent}
                        errorLabel={errors.email}
                        errorPosition="position-absolute"
                        isRequired={isEmailRequired}
                    />
                </div>
                <div className="mb-3">
                    <Select
                        label={t("Organization")}
                        filter
                        name="organizationId"
                        id="organizationId"
                        options={organizationOptions}
                        value={formFieldData.organizationId}
                        isRequired
                        isError={errors.hasOwnProperty('organizationId')}
                        errorPosition="position-absolute"
                        errorLabel={errors.organizationId}
                        changeFilterAction={handleChangeOrganization}
                    />
                </div>
                <div className="mb-3 enroll-create-participant-id">
                    <TextInput
                        label={t("External ID")}
                        name="externalId"
                        id="externalId"
                        value={formFieldData.externalId}
                        changeAction={handleChangeByUserEvent}
                        errorLabel={errors.externalId}
                        errorPosition="position-absolute"
                        isRequired={false}
                        isError={errors.hasOwnProperty('externalId')}
                    />
                </div>               
            </div>
            <div className="enroll-create-participant-row">
                <DestructiveButton
                    clickAction={() => props.setShow(false)}
                    additionalStyleClasses="inline-icon-left cancel-button"
                    label={<><i className="fa-light fa-xmark fa-sharp" /> {t("Cancel")}</>}
                />
                <Button
                    elementId="create-participant-button-2"
                    isSolid={true}
                    isDisabled={isLoading}
                    label={
                        <div className="d-flex align-items-center justify-content-center">
                            {isLoading && <i className="fas fa-spinner fa-spin mr-2"/>} { (((props.class.trainingFormat != 'classroom') && ((props.seats > 0) || !props.class.seatsRequired)) || (props.class.trainingFormat == 'classroom') && props.canEnrollParticipants) ? "Create & Enroll Participant" : "Create Participant" }
                        </div>
                    }
                    clickAction={handleSubmit}
                />
            </div>
        </div>
    );
};

export default CreateParticipant;
