import * as React from "react";

import DevPage from "../Pages/dev";
import Page from "../Pages/page";
import ProtectedRoute from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { DevRoles } from "../Enums";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { CpiRoute, DevBaseRoutes } from "./routes";
import NoAccess from "../Pages/no-access";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

const DevRoute: React.FunctionComponent<RouteProps> = ({ children, ...rest }) => (
    <ProtectedRoute roles={DevRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData:any,
    rac:any
) => {
    let tempArray:CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(DevBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route:any, i:number) => (
            // map over CpiRoute[]
            (rac(route)) ?
            <>
                // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                <Route key={i} path={route.path}
                    element={
                        <DevRoute>
                          
                                <div>
                                    { route.pageId == "developmentPage" &&
                                        <DevPage />
                                    }
                                </div>
                         
                        </DevRoute>
                    }
                />

                <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                    }
                />
            </>
            :
                // we do not meet the criteria for culture and should render no-access content
                <Route key={i} path={route.path}
                    element={
                        <ProtectedRoute>
                       
                                <>
                                    { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */ }
                                    { <NoAccess /> }
                                </>
                       
                        </ProtectedRoute>
                    }
                />               
            ))}
        </>
    );
};

const DevRouting = () => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>           
            { createRoutes(DevBaseRoutes, rac) }
        </Routes>
    );
};

export default withAITracking(reactPlugin, DevRouting);
