import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import * as React from "react";

import { TypeaheadSearch } from "../Components";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OrganizationInvoicedCourse, OrganizationSearchModel } from "../Interfaces";
import { WithTranslation, withTranslation } from "react-i18next";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

import { AdminBaseRoutes } from "../Routing/routes";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withNavigator } from '../Components/with-navigator';

import Table from "../Components/table";
import { useContext } from "react";
import { ConfigContext } from "../configuration-context";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchOrganization, fetchSeatsByOrganization } from "./Utils/organization";

interface ManageSeatsPageState {
    hive: OrganizationSearchModel;
    hiveSeats: OrganizationInvoicedCourse[];
    orgId: string;
}

const ManageSeatsPage = () => {
    const configContext = useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { t } = useTranslation();
    const { orgId } = useParams();
    const navigate = useNavigate();

    const [state, setState] = React.useState<any>(
    {
        hive: null,
        hiveSeats: null,
        orgId: orgId
    });

    React.useEffect(() => {
        const fetchData = async () => { 
            const { hive, orgId } = state;
            if (orgId && !hive) {
                fetchAndSetHiveSeats(await fetchOrganization(apimBaseUrl, configContext, orgId));
            }
            else if (!orgId && hive) {
                setState({ hive: null, hiveSeats: null, orgId: null });
            }
        }
        fetchData();
    }, []);


    const resetState = () => {
        setState({ hive: null, hiveSeats: null, orgId: null });
    }

    const typeAheadRenderer = (option: any) => {
        return (
            <div key={option.organizationID}>
                <strong>
                    {option.organizationName} (#{option.orgId})
                </strong>
            </div>
        );
    }

    const renderSeatsTable = () => {
        const { hiveSeats } = state;

        if (hiveSeats === null) {
            return null;
        }
        const columnHelper = createColumnHelper<OrganizationInvoicedCourse>()
        const columns:ColumnDef<OrganizationInvoicedCourse>[] = 
        [
            columnHelper.accessor('courseName', {
                cell: info => renderCourse(info.getValue(), info.row.original.courseId),
                header: t("Course Name"),
                enableSorting: true,
            }),
            columnHelper.accessor('seatsRemaining', {
                cell: info => info.getValue(),
                header: t("Available Seats"),
                enableSorting: true,
            }),
            columnHelper.accessor('seatsPurchased', {
                cell: info => info.getValue(),
                header: t("Purchased Seats"),
                enableSorting: true,
            }),
        ];

        return (
            <Table
                fetchData={false}
                defaultData={hiveSeats}
                keyField="courseId"
                columns={columns}
                enablePaging={false}
                enableSearch={false}
            />
        );
    }

    const fetchAndSetHiveSeats = async (hive: OrganizationSearchModel, reRoute: boolean = false) => {
        let newRoute = "";
        if (hive === null || hive === undefined) {
            newRoute = AdminBaseRoutes.ManageSeats.fullPath;
            resetState();
        } else {
            const seats = await fetchSeatsByOrganization(apimBaseUrl, configContext, hive.organizationId);
            newRoute = `${AdminBaseRoutes.ManageSeats.fullPath}/${hive.organizationId}`;
            setState({ hive: hive, hiveSeats: seats, orgId: hive.organizationId });
        }

        if (reRoute) {
            navigate(newRoute);
        }
    }

    const renderCourse = (courseName: string, courseId: string) => {
        const stateCopy = {
            courseName,
            organization: state.hive,
            organizationCourseSeats: state.hiveSeats.find((seat) => seat.courseId === courseId)
        };
        return (
            <u>
                <Link to={`${courseId}`} state={stateCopy}>
                    {courseName}
                </Link>
            </u>
        );
    }

    return (
        <>
            <h1>{t("Manage Seats")}</h1>
            <Breadcrumb>
                <Breadcrumb.Item active={!orgId} linkAs={Link} onClick={() => { resetState() }} linkProps={{ to: AdminBaseRoutes.ManageSeats.fullPath }}>
                    {t("Manage Seats")}
                </Breadcrumb.Item>
                {state.hive && <Breadcrumb.Item active>{state.hive.organizationName}</Breadcrumb.Item>}
            </Breadcrumb>
            <Container fluid>
                <Row>
                    <Col lg={true}>
                        <TypeaheadSearch
                            id="HiveSearch"
                            searchUrl={apimBaseUrl + "/Q/Organization/GetOrganizations/"}
                            placeholder={t("Search for an organization")}
                            labelKey="organizationName"
                            itemRenderer={typeAheadRenderer}
                            onChange={(item) => {
                                fetchAndSetHiveSeats(item[0], true);
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            {orgId && renderSeatsTable()}
        </>
    );
}

export default withNavigator(withAITracking(reactPlugin, withTranslation()(ManageSeatsPage)));