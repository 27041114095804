import * as React from "react";
import { ImageFilename } from "../../Interfaces/Platform/ImageFileName";
import ArrowLink from "./arrow-link";
import { useNavigate } from "react-router-dom";
import { isExternalURL } from "../../helper-functions";
import { ConfigContext } from "../../configuration-context";

const LinkBlock = (props: {
    image: ImageFilename,
    label: string
    href: string
}): JSX.Element => {
    const image = props.image;

    const navigate = useNavigate();
    const configContext = React.useContext(ConfigContext);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(props.href)) {
            event.preventDefault();
            navigate(props.href);
        }
    };

    return (
        <>
            <div className="training-center-link-block">
                <a onClick={handleClick} href={props.href}>
                    <div className="link-block-image" style={{background: "transparent url('" + configContext?.SystemConfiguration?.CDNImagesUrl + "/dashboard/" + image.filename + "." + image.extension + "') no-repeat 0 0", backgroundSize: "contain", backgroundPosition: "center"}}></div>
                </a>
                
                <ArrowLink data={{ label: props.label, href: props.href }} />
            </div>
        </>
    );
};

export default LinkBlock;
