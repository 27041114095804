import * as React from "react";

import { Alert, Button, ButtonGroup, Container, Dropdown, DropdownButton, Form, Modal,
         OverlayTrigger, Tooltip } from "react-bootstrap";
import { ApiResponse, JsonResponseModel } from "../ApiServices";
import { fetchClass, deleteClass, fetchAvailableCourses, fetchInstructors, fetchClasses, fetchOnlyActiveClasses, fetchAllClassRoster } from "../ApiServices/Class";
import { fetchEditionCoursesByCourseId, fetchCourseWithCourseObject, convertLmsClass, cancelConvertLmsClass, createLmsParticipants } from "./Utils/class";
import { fetchOrganizationBranchIds, fetchProgramsOrganizations, fetchInstructorsCertifications } from "./Components/DashboardPlatformUtils";
import { fetchClassSettings } from "./Utils/classCreate";
import { fetchOrganizationsIds } from "../Pages/Utils/participants";
import { useDispatch } from "react-redux";
import { loadNewClassFromCreatePayload } from "../stores/classDetailsSlice";
import { isCertInstructor, isBusinessAdministrator } from "../helper-functions";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import { returnPageLevelAlerts } from "./Utils/alerts";

import AvailableCourseModel from "../Interfaces/AvailableCourseModel";
import { EditionCourse, LMSParticipant, Organization, EditionCourseResponse, ClassCourseObject } from "../Interfaces/Platform/Classes";
import ClassForm from "./Components/class-form";
import Tile from "../Components/UI/tile";
import ClassModel from "../Interfaces/ClassModel";
import { Link, useNavigate } from "react-router-dom";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { ConfigContext } from "../configuration-context";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../Components/remote-table";
import { UserModel, ClassInfoModel } from "../Interfaces";
import { PlatformBaseRoutes } from "../Routing/routes";
import { useToast } from "../hooks/toast";
import useDetectWindowSize from "../hooks/useDetectWindowSize";
import { useTranslation } from "react-i18next";
import { formatDate } from '../locale-utils';
import ModalPopUp from "../Components/UI/modal-pop-up";
import DocumentClass from "./Components/Classes/document-class";
import useLocalStorage from "../hooks/useLocalstorage";

import useTrainableSpecialtiesByCert from "../hooks/useTrainableSpecialtiesByCert";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { setClassInactive } from "../ApiServices/Class";
import useFeatureFlags from "../hooks/useFeatureFlags";

import { ExpectedRosterCount } from "../Interfaces/Platform/ExpectedRosterCount";

export interface InstructorModel {
    userId: string;
    firstName: string;
    lastName: string;
    certifications: string[];
}

const ManageClassesPage = () => {
    const { t } = useTranslation();
    const { toast } = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLaptop } = useDetectWindowSize();
    const rbac = React.useContext(RbacContext);

    const userId = rbac.userContext.UserId;

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const isCI = isCertInstructor(userContext);
    const isBA = isBusinessAdministrator(userContext);

    const shouldBOE = rbac.userContext?.AltOrganizationsList?.length > 0 && (isCI || isBA);
    const currentOrg = shouldBOE ? rbac.userContext?.SelectedOrganization.toString() : rbac.userContext?.OrganizationId.toString();

    const [canSetConvertedClassInactive] = useFeatureFlags("ITLSetConvertedClassInactiveInHB");

    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [isConvertLoading, setIsConvertLoading] = React.useState<{ class: number, loading: boolean }>({
        class: 0, loading: false
    });
    const [courses, setCourses] = React.useState<AvailableCourseModel[]>([]);
    const [instructors, setInstructors] = React.useState<UserModel[]>([]);
    const [orgInstructors, setOrgInstructors] = React.useState<InstructorModel[]>([]);
    const [showForm, setShowForm] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const [selectedClass, setSelectedClass] = React.useState<ClassModel>(null);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [preventConvertSpam, setPreventConvertSpam] = React.useState(false);
    const preventConvertSpamRef = React.useRef(preventConvertSpam);
    React.useEffect(() => { 
        preventConvertSpamRef.current = preventConvertSpam 
    }, [preventConvertSpam]);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [classes, setClasses] = React.useState<ClassModel[]>([]); 
    const classesRef = React.useRef(classes);
    React.useEffect(() => { 
        classesRef.current = classes 
    }, [classes]);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [isOnlineOnly, setIsOnlineOnly] = React.useState<boolean>(undefined);
    const isOnlineOnlyRef = React.useRef(isOnlineOnly);
    React.useEffect(() => { 
        isOnlineOnlyRef.current = isOnlineOnly
    }, [isOnlineOnly]);

    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [convertClassError, setConvertClassError] = React.useState<string>(null);
    const [showDocumentClass, setShowDocumentClass] = React.useState<boolean>(false);
    const [classInfo, setClassInfo] = React.useState<ClassInfoModel>(null);
    const [editionCourses, setEditionCourses] = React.useState<EditionCourse[]>([]);
    const [lmsParticipants, setLmsParticipants] = React.useState<LMSParticipant[]>();
    const [viewClassActiveTab, setViewClassActiveTab] = useLocalStorage('activeViewClassTab', { classId: null, activeTab: 1 });

    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();

    const [trainablePrograms] = useTrainableSpecialtiesByCert(false);

    const getOrganizationBranch = async () => {
        const data = await fetchOrganizationBranchIds(apimBaseUrl, configContext, currentOrg);
        console.log("Orgs: ", data);
        getInstructors(data);
    };

    const getInstructors = async (orgs: string[]) => {
        const data = await fetchInstructorsCertifications(apimBaseUrl, configContext, currentOrg, orgs, userId);        
        setOrgInstructors((prevState) => [...prevState, ...data]);
    };

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            getOrganizationBranch();
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        const getData = async () => {
            setLoading(false);
        };

        if (userContext.UserId) {
            getData();
        }
    }, [userContext]);

    const handleDelete = (resp: JsonResponseModel<ApiResponse>) => {
        setShowDelete(false);
        toast(resp);

        if (!resp.Error) {
            setForceRefresh(!forceRefresh);
        }
    };

    const nameColumn = (className: string, classId: number) => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`manageClassTooltip-${classId}`}>{t("Manage Class")}</Tooltip>}
            >
                <Link
                    className="text-link"
                    title={className}
                    to={`/Class/Manage/${classId}`}
                    aria-label={t("Manage Class")}
                >
                    {className}
                </Link>
            </OverlayTrigger>
        );
    }

    const activityColumn = (row: ClassModel) => {
        if (row.EnrolledLearners <= 100) {
            return (
                <ButtonGroup title="Action Buttons" className="w-100">
                    <Button
                        variant="primary"
                        style={{ width: "100%" }}
                        active={true}
                        onClick={() => {
                            setSelectedClass({ ...row });
                            openDocumentClass(row.ClassId);
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            {isConvertLoading.class === row.ClassId && isConvertLoading.loading && <i className="fas fa-spinner fa-spin mr-2"/>} {t("Document Class")}
                        </div>
                    </Button>
                </ButtonGroup>
            );
        } else {
            return (<></>);
        }
    }

    const columnHelper = createColumnHelper<ClassModel>()

    const columns = React.useMemo<ColumnDef<ClassModel>[]>(
        () => [
            columnHelper.accessor('Name', {
                cell: (info) => nameColumn(info.getValue(), info.row.original.ClassId),
                header: t("Class Name"),
                enableSorting: true,
                size: 30
            }),
            columnHelper.accessor('CourseName', {
                cell: info => info.getValue(),
                header: t("Course Name"),
                enableSorting: true, 
                meta: {bodyClass: "text-truncate align-middle"},
                size: 30
            }),
            columnHelper.accessor('InstructorName', {
                cell: info => info.getValue(),
                header: t("Instructor"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" },
                size: 20
            }),
            columnHelper.accessor('CreateDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Date Created"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" },                
            }),
            columnHelper.accessor('ClassDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Date of In-Person Training"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" },
                size: 15
            }),
            columnHelper.accessor('EnrolledLearners', {
                cell: info => info.getValue(),
                header: t("Enrolled Learners"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('ClassId', {
                cell: (info) => activityColumn(info.row.original),
                header: "",
                enableSorting: false,
                meta: {bodyStyle: { width: "100%" }, bodyClass: "align-middle" }
            }),
        ],
        []
    );

    let openDocumentClass = async (classId: number) => {

        // If the spam prevention state hasn't been triggered we can proceed with convert functionality.
        // Otherwise if preventConvertSpamRef is true, we'll just ignore the button click.
        if (!preventConvertSpamRef.current) {

            // Now that we've begun class convert functionality, set preventConvertSpam to true to prevent spamming the button
            setPreventConvertSpam(true);

            try {
                const _class = await fetchClass(classId);

                const _participants = await fetchAllClassRoster([classId]);
                
                setLmsParticipants(() => _participants.Results
                    .filter(i => i.PingId !== null && i.PingId !== undefined)
                    .map((i) => ({
                        participantUserId: i.PingId,
                        participantOrgId: i.OrgId,
                        participantOrganizationId: i.AccountId,
                        participantOrganizationName: i.AccountName,
                        participantFirstName: i.FirstName,
                        participantLastName: i.LastName,
                        participantEmail: i.EmailAddress,
                        lmsLearnerCourseId: i.LearnerCourseId,     
                        participantCourseStartDate: i.CourseStart,
                        participantCourseCompletedDate: i.CompletionDate,
                        participantCourseLastAccessed: i.LastAccessed,
                        participantCoursePercentComplete: i.PercentCompleted,
                        participantCourseTimeSpent: i.TimeSpent,
                        participantCourseExamScore: i.Score,
                        participantOrganizationSeatId: i.OrgSeatGuid,
                        seatPoolId: i.SeatPoolId
                    })));
                setClassInfo(_class);

                // _class.ClassId should match an item in classes.Results
                // Get the OnlineOnly value from the item in classes.Results that matches _class.ClassId
                // If it doesn't find a matching class it will be set to undefined
                const onlineOnly = classesRef.current.find(c => c.ClassId == _class.ClassId)?.OnlineOnly;

                setIsOnlineOnly(onlineOnly ? true : false);

                // Since this runs synchronously, I'm going to pass onlineOnly as a param since I'm not sure component state will update in time
                handleDocumentClass(_class, onlineOnly);
            } catch (e) {
                console.log('error', e);

                // If something goes wrong, re-enable convert button
                setPreventConvertSpam(false);
            }
            
        }
        
    };

    const handleDocumentClass = async (classData: any, onlineOnlyParam:boolean|undefined) => {

        // Getting trainable specialties here
        let specialties = [];
        if (isBA) {
            const orgData = await fetchOrganizationBranchIds(apimBaseUrl, configContext, userContext.OrganizationId.toString());
            specialties = await fetchProgramsOrganizations(apimBaseUrl, configContext.SystemConfiguration.ApimKey, orgData);
        } else {
            if (isCI) {
                specialties = userContext.CertificationData.map((i: any) => i.specialty);
            }
        }

        let allTrainablePrograms:string[] = [];

        specialties.forEach((specialty: string) => {
            allTrainablePrograms.push(specialty);
            const programs = trainablePrograms(specialty, userContext.Country, false);
            allTrainablePrograms = [...allTrainablePrograms, ...programs];
        });

        allTrainablePrograms = [...new Set(allTrainablePrograms)];

        const data: EditionCourseResponse = await fetchEditionCoursesByCourseId(apimBaseUrl, configContext, classData.CourseGuid, allTrainablePrograms);
        const response = data?.editionCourses;
        setEditionCourses(response);

        // Decision to show modal or not
        // Do not show modal if there's ONE of every data AND there is a class start date (No inputs for date needed in this case)
        // If the condition here is met, it means we do not need the modal and will just carry on with converting the class.
        // On success, the user will be redirected to the class details page.
        if (response?.length === 1 && classInfo?.ClassStartDate) {
            setIsConvertLoading({ class: classData.ClassId, loading: true });
            const courseData: ClassCourseObject = await fetchCourseWithCourseObject(apimBaseUrl, configContext, classData.CourseGuid, classData.CourseObjectGuid);
            setShowDocumentClass(false);
            // Make api call to convert class withtout opening modal
            handleConvertLmsClass({
                userId: userContext.UserId,
                classId: classData.ClassId,
                organizationId: classData.AccountId,
                organizationName: classData.AccountName,
                seatOrganizationId: classData.SeatAccountId,
                seatOrganizationName: classData.SeatAccountName,
                className: classData.Name,
                specialty: response[0]?.specialty,
                specialtyEdition: response[0]?.edition,
                qualification: response[0]?.qualification,
                // For the next two, I'm reading this from the passed param since component state may not update in time (the function runs syncronously)
                trainingType: onlineOnlyParam ? null : "initial-refresher",
                trainingFormat: onlineOnlyParam ? "virtual" : "blended",
                courseId: classData.CourseGuid,
                courseName: classData.CourseName,
                courseCulture: courseData?.courseCulture ?? null,
                courseContactHours: courseData?.contactHours ?? null,
                courseTemplateId: courseData?.templateId ?? null,
                courseEstimatedTime: courseData?.courseEstimatedTime ?? null,
                courseObjectId: courseData?.courseObjectId ?? null,
                courseObjectModulesUrl: courseData?.modulesUri ?? null,
                courseObjectScormId: courseData?.scormId ?? null,
                courseLanguage: courseData?.language ?? null,
                courseObjectIndustry: courseData?.industry ?? null,
                participants: lmsParticipants.length ?? 0,
                instructor: {
                    userId: classData.InstructorPingId,
                    firstName: classData.InstructorFirstName,
                    lastName: classData.InstructorLastName,
                    isPrimary: true
                },
                classDates: [
                    {
                        date: classData.ClassStartDate,
                        initialHours: 0,
                        refresherHours: 0
                    }
                ]
            });
        } else {
            // If we land here, it means selections need to be made by the user and the modal to do so will open.
            // We also now want to re-enable the convert button so if the user closes the modal they can open it again.
            setPreventConvertSpam(false);
            setShowDocumentClass(true);
        }
    };

    const handleDocumentClassWithForm = async (form: any) => {
        setIsConvertLoading({ class: 0, loading: true });
        const courseData: ClassCourseObject = await fetchCourseWithCourseObject(apimBaseUrl, configContext, classInfo.CourseGuid, classInfo.CourseObjectGuid);
        handleConvertLmsClass({
            userId: userContext.UserId,
            classId: classInfo.ClassId,
            organizationId: classInfo.AccountId,
            organizationName: classInfo.AccountName,
            seatOrganizationId: classInfo.SeatAccountId,
            seatOrganizationName: classInfo.SeatAccountName,
            className: classInfo.Name,
            specialty: form.specialty,
            specialtyEdition: form.edition,
            qualification: form.qualification,
            // These two can read directly from component state since they should be updated by this point
            trainingType: isOnlineOnlyRef.current ? null : "initial-refresher",
            trainingFormat: isOnlineOnlyRef.current ? "virtual" : "blended",
            courseId: classInfo.CourseGuid,
            courseName: classInfo.CourseName,
            courseCulture: courseData?.courseCulture ?? null,
            courseContactHours: courseData?.contactHours ?? null,
            courseTemplateId: courseData?.templateId ?? null,
            courseEstimatedTime: courseData?.courseEstimatedTime ?? null,
            courseObjectId: courseData?.courseObjectId ?? null,
            courseObjectModulesUrl: courseData?.modulesUri ?? null,
            courseObjectScormId: courseData?.scormId ?? null,
            courseLanguage: courseData?.language ?? null,
            courseObjectIndustry: courseData?.industry ?? null,
            participants: lmsParticipants.length ?? 0,
            instructor: {
                userId: classInfo.InstructorPingId,
                firstName: classInfo.InstructorFirstName,
                lastName: classInfo.InstructorLastName,
                isPrimary: true
            },
            classDates: classInfo.ClassStartDate ? [
                {
                    date: classInfo.ClassStartDate,
                    initialHours: 0,
                    refresherHours: 0
                }
            ] : form.classDates.map(({ id, ...rest }: any) => rest)
        });
    };

    const handleConvertLmsClass = async (payload: any) => {
        try {

            // Retrieve class settings to get extra requirements
            const classSettings = await fetchClassSettings(apimBaseUrl, configContext, payload.specialty);

            // Add extra requirements to payload
            payload = {
                ...payload,
                blueCardRequired: classSettings[0].blueCardId,
            };

            const response = await convertLmsClass(apimBaseUrl, configContext, payload);

            // If convert lms class fails (400 or 500), the service will handle the convert cancellation.
            // If create lms participants fails (400 or 500), we need to cover the cancellation here
            // by hitting another endpoint.
            if (response.status === 200) {
                const organizations = await fetchOrganizationsIds(apimBaseUrl, configContext, userContext.OrganizationId.toString());
                const participantsResponse = await createLmsParticipants(apimBaseUrl, configContext, {
                    participants: lmsParticipants,
                    classId: response.data.classId,
                    orgId: userContext.OrgId.toString(),
                    organizationId: userContext.OrganizationId.toString(),
                    organizationName: userContext.OrganizationName,
                    organizationIds: organizations
                });
                if (participantsResponse === 202) {
                    setIsConvertLoading({ class: 0, loading: false });
                    // Convert Class AND Participants success
                    // Set class inactive in HB if feature flag is enabled
                    canSetConvertedClassInactive && setClassInactive(payload.classId);                   
                    dispatch(loadNewClassFromCreatePayload({
                        language: response.data.courseLanguage,
                        ...response.data
                    }));

                    // Navigate to Class Details with data on redux (Like Class Create)
                    setViewClassActiveTab({ classId: response.data.classId, activeTab: 0 });

                    // create expectedRosterCount session data to class-details can
                    // anticipage a certain number of participants
                    const expectedRosterCount: ExpectedRosterCount = {
                        classId: response.data.classId,
                        count: lmsParticipants.length
                    };
                    sessionStorage.setItem('expectedRosterCount', JSON.stringify(expectedRosterCount));

                    navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${response.data.classId}`);
                // Create Lms Participants failed
                // We need to make an additional call to cancel the conversion
                } else if (participantsResponse === 400 || participantsResponse === 500) {
                    cancelConvertLmsClass(apimBaseUrl, configContext, response.data.classId);
                    setIsConvertLoading({ class: 0, loading: false });
                    setPreventConvertSpam(false);
                    setShowDocumentClass(false);
                    addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
                } else {
                    // Convert Participants failure
                    setIsConvertLoading({ class: 0, loading: false });
                    setPreventConvertSpam(false);
                    setShowDocumentClass(false);
                    addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
                }
            // Call to ConvertLmsClass failed.
            // We don't need to call to cancel the conversion but we do need to handle in the UI.
            } else if (response.status === 400 || response.status === 500) {
                // Convert Class failure
                setIsConvertLoading({ class: 0, loading: false });
                setPreventConvertSpam(false);
                setShowDocumentClass(false);
                addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
            // Catch-all, basically doing the same thing as 400 or 500, at least for now.
            } else {
                // Convert Class failure
                setIsConvertLoading({ class: 0, loading: false });
                setPreventConvertSpam(false);
                setShowDocumentClass(false);
                addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
            }
        } catch (e) {
            console.log('error', e);
            setIsConvertLoading({ class: 0, loading: false });
            setPreventConvertSpam(false);
            setShowDocumentClass(false);
            addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
        }
    };

    return (
        <>
        <div className="page-content m-auto">
            <Container fluid className="page-padding">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="page-title-h1 title-margin">{t("Manage Classes")}</h1>
                </div>
                <div className="alert-wrapper mt-4">{returnPageLevelAlerts(pageLevelAlerts)}</div>
                <ModalPopUp
                    title="Additional Class Details"
                    size={isLaptop ? "md" : "lg"}
                    center
                    content={
                        <DocumentClass
                            onSubmit={handleDocumentClassWithForm}
                            editionCourses={editionCourses}
                            class={classInfo}
                            classId={classInfo?.ClassId.toString()}
                            onClose={() => setShowDocumentClass(false)}
                            isLoading={isConvertLoading.loading}
                            convertClassError={convertClassError}
                            resetConvertClassError={setConvertClassError}
                            instructors={orgInstructors}
                        />
                    }
                    show={showDocumentClass}
                    showFooter={false}
                    hide={() => setShowDocumentClass(false)}
                />
                <PageMessages loading={loading} errored={errored} />
                {!loading && !errored && (
                    <Tile title="" hideTileHeader>
                        <>
                            {/* Hiding this alert since we're cutting the courses query, it's unused in what this page was repurposed for */}
                            {false && (
                                <Alert variant="danger">
                                    {t("You do not have any seats remaining.")}{" "}
                                    <a
                                        className="text-link pendo__link-contact-us ps-2 text-link"
                                        href="#"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {t("Please contact us for more seats.")}
                                    </a>
                                </Alert>
                            )}
                            <div className="table-container">
                                <RemoteTable
                                    keyField="ClassId"
                                    fetchFunction={fetchOnlyActiveClasses}
                                    columns={columns}
                                    defaultSorted={{ dataField: "CreateDate", order: "desc" }}
                                    forceRefresh={forceRefresh}
                                    setResultsForParentComponent={setClasses}
                                />
                            </div>
                        </>
                    </Tile>
                )}
                {showDelete && (
                    <DeleteClassModal
                        show={showDelete}
                        classId={selectedClass.ClassId}
                        name={selectedClass.Name}
                        handleCancel={() => setShowDelete(false)}
                        handleDelete={handleDelete}
                    />
                )}
                </Container>
            </div>
        </>
    );
};

const DeleteClassModal = ({
    show,
    classId,
    name,
    handleCancel,
    handleDelete
}: {
    show: boolean;
    classId: number;
    name: string;
    handleCancel: () => void;
    handleDelete: (resp: JsonResponseModel<ApiResponse>) => void;
}) => {
    const { t } = useTranslation();
    const [deleting, setDeleting] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDeleting(true);

        const resp = await deleteClass(classId);
        handleDelete(resp);
    };

    return (
        <Modal show={show} onHide={handleCancel}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>{t("Delete Class")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("Are you sure you want to delete the following class?")}</p>
                    <p>
                        <strong>{name}</strong>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={deleting} onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={deleting}>
                        {deleting ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Deleting")}
                            </>
                        ) : (
                            t("Delete Class")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ManageClassesPage);
