import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TwoColumnLayout = (props: {
    left: JSX.Element,
    right: JSX.Element,
    marginTop?: boolean,
}): JSX.Element => {
    const marginTop = props.marginTop ?? true;
    return (
        <Container className={`mw-1440 ${marginTop ? 'mt-4' : 'mt-0'}`} fluid>
            <Row className="mobile-padding-container">
                <Col className="pl-0" xs={12} lg={6}>
                    {props.left}
                </Col>

                <Col className="pr-0" xs={12} lg={6}>
                    {props.right}
                </Col>
            </Row>
        </Container>
    );

};

export default TwoColumnLayout;
