import * as React from "react";
import Button from "./button";

import useMonthLabelByMonthCode from "../../hooks/useMonthLabelByMonthCode";
import useParsedTranslation from "../../hooks/useParsedTranslation";
import { useTranslation } from "react-i18next";

const EnrollmentListing = (props: {
    enrollmentData:any,
    eventData:any,
    todaysDate:any
}) => {
    // REFERENCE https://crisisprevention.invisionapp.com/console/Certified-Instructor---Revised-ckrp5a6sz03mb0171b7kg1edg/ckzcudwd202ct010h7qnj6lxt/play
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const { month } = useMonthLabelByMonthCode();

    const todaysDate = props.todaysDate;
    const startDate = new Date(props.eventData.startDate);
    const day1Date = new Date(props.eventData.day1Date);
    const day2Date = new Date(props.eventData.day2Date);
    const day3Date = new Date(props.eventData.day3Date);
    const day4Date = new Date(props.eventData.day4Date);
    const day5Date = new Date(props.eventData.day5Date);

    let classIsToday = (
        todaysDate.setHours(0,0,0,0) == day1Date.setHours(0,0,0,0) ||
        todaysDate.setHours(0,0,0,0) == day2Date.setHours(0,0,0,0) ||
        todaysDate.setHours(0,0,0,0) == day3Date.setHours(0,0,0,0) ||
        todaysDate.setHours(0,0,0,0) == day4Date.setHours(0,0,0,0) ||
        todaysDate.setHours(0,0,0,0) == day5Date.setHours(0,0,0,0) 
    );

    const timeZone = (props.eventData.timeZone) ? props.eventData.timeZone : "";

    const seatDay1StartTime = props.eventData.seatDay1StartTime;
    const seatDay2StartTime = props.eventData.seatDay2StartTime;
    const seatDay3StartTime = props.eventData.seatDay3StartTime;
    const seatDay4StartTime = props.eventData.seatDay4StartTime;
    const seatDay5StartTime = props.eventData.seatDay5StartTime;

    const seatDay1EndTime = props.eventData.seatDay1EndTime;
    const seatDay2EndTime = props.eventData.seatDay2EndTime;
    const seatDay3EndTime = props.eventData.seatDay3EndTime;
    const seatDay4EndTime = props.eventData.seatDay4EndTime;
    const seatDay5EndTime = props.eventData.seatDay5EndTime;

    const iconStyleClass = (props.enrollmentData.isVirtual) ? "icon-before-video" : "icon-before-screen-users";

    return (
        <div>
           <div id="complete-cert-training-container">        
                <div className="courseListing-container pt-0 d-flex justify-content-start position-relative">

                    { (props.enrollmentData.status && props.enrollmentData.status.toLowerCase() == "attended-passed") &&
                        <div className="task-banner-tag-container">
                            <div className="task-banner"></div>
                        </div>
                    }

                    <div className="mr-2">
                        <div className="enrollment-listing-tile-header position-relative">
                            <span className="mr-1">{ month(startDate.getMonth()) }</span>
                            <span>{ startDate.getFullYear() }</span>
                        </div>

                        <div className="enrollment-listing-tile gray-border-thick-bottom gray-border-thick-left gray-border-thick-right d-flex justify-content-center align-items-center enrollment-listing-width-narrow">
                            <span className="extra-large">{ startDate.getDate() }</span>
                        </div>
                    </div>

                    <div className="w100">
                        <div className="d-flex mb-2">
                            <div className={ iconStyleClass + " ml-1 sub-section-heading bold" }>{ props.enrollmentData.isVirtual ? <span>{t("CPI.Platform.EventRegistration.UI.VirtualClass")}</span> : <span>{t("CPI.Platform.EventRegistration.UI.InPersonClass")}</span> }</div>
                        </div>
                        <div>{ tp(props.enrollmentData.seatSpecialty) }</div>
                        <div className="d-flex pl-3 pt-1 justify-content-between flex-wrap">
                            <div>
                                <ul className="mb-2">

                                    { day1Date && seatDay1StartTime && seatDay1EndTime && 
                                        <li><span>{ t('dynamicDate', { val: day1Date, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " (" + seatDay1StartTime + " - " + seatDay1EndTime + " " + timeZone + ")" }</span></li>
                                    }

                                    { day2Date && seatDay2StartTime && seatDay2EndTime && 
                                        <li><span>{ t('dynamicDate', { val: day2Date, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " (" + seatDay2StartTime + " - " + seatDay2EndTime + " " + timeZone + ")" }</span></li>
                                    }

                                    { day3Date && seatDay3StartTime && seatDay3EndTime && 
                                        <li><span>{ t('dynamicDate', { val: day3Date, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " (" + seatDay3StartTime + " - " + seatDay3EndTime + " " + timeZone + ")" }</span></li>
                                    }

                                    { day4Date && seatDay4StartTime && seatDay4EndTime && 
                                        <li><span>{ t('dynamicDate', { val: day4Date, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " (" + seatDay4StartTime + " - " + seatDay4EndTime + " " + timeZone + ")" }</span></li>
                                    }

                                    { day5Date && seatDay5StartTime && seatDay5EndTime && 
                                        <li><span>{ t('dynamicDate', { val: day5Date, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " (" + seatDay5StartTime + " - " + seatDay5EndTime + " " + timeZone + ")" }</span></li>
                                    }                                

                                </ul>
                                
                                { (props.enrollmentData.isVirtual && classIsToday) ?
                                    <div className="align-items-center">
                                        <div className="mr-1">{t("CPI.MyAccount.Dashboard.Enrollment.VirtualClass.ZoomMeeting")} {props.eventData.zoomMeetingId}</div>
                                        <Button isSolid={true} label={t("CPI.MyAccount.Dashboard.Enrollment.VirtualClass.JoinZoomMeeting")} additionalStyleClasses="solid-button-video-text mt-2" />
                                    </div>
                                : null }
                                
                            </div>

                            { (props.eventData.address1 && !props.enrollmentData.isVirtual) ?
                                <div className="mt-2">
                                    <div>{ props.eventData.address1 }</div>
                                </div>
                            : null }

                            { (props.eventData.address2 && !props.enrollmentData.isVirtual) ?
                                <div className="mt-2">
                                    <div>{ props.eventData.address2 }</div>
                                </div>
                            : null }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EnrollmentListing;