import "./multisco-course.scss";

import * as React from "react";

import { Alert, Badge } from "react-bootstrap";

import { PlatformBaseRoutes } from "../../Routing/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { isExternalURL } from "../../helper-functions";
import { useNavigate } from "react-router-dom";

import { SupportBaseRoutes } from "../../Routing/routes";

const MultiScoPreview = ({ launchUrl }: { launchUrl: string }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const handleNavToContactUs = (route: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(route)) {
            event.preventDefault();
            navigate(route);
        }
    };

    return (
        <>
            <h5 className="multisco-course__banner">
                <Badge className="ms-2" pill variant="dark">
                    <i className="fas fa-eye me-2"></i>
                    {t("Preview")}
                </Badge>
            </h5>
            <Alert variant="secondary" className="mt-2">
                {t("This is a preview course, not to be used for formal training.")}{" "}
                <a className="text-link pendo__link-contact-us" target="_blank" href={SupportBaseRoutes.SupportContactUs.fullPath} onClick={(e) => {
                    handleNavToContactUs(SupportBaseRoutes.SupportContactUs.fullPath, e);
                }}>
                    {t("Please contact us to learn more")}
                </a>
                .
            </Alert>
            <hr />
            <iframe className="multisco-course__iframe" allow="autoplay" src={launchUrl}></iframe>
        </>
    );
};

export default withAITracking(reactPlugin, MultiScoPreview);
