import * as React from "react";

import { ConfigContext } from "../../../../configuration-context";

import EnrollmentListing from "../../../../Components/UI/enrollment-listing";
import EnrollmentListingOnline from "../../../../Components/UI/enrollment-listing-online";
import EnrollmentExamListing from "../../../../Components/UI/enrollment-exam-listing";
import { getToken } from "../../../../helper-functions";

const Enrollment = (props: {
    enrollmentData:any,
    addPageLevelAlert:any,
    todaysDate:any
}) => {
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [eventData, setEventData] = React.useState({});

    React.useEffect(() => {
        fetch(apimBaseUrl + "/Q/Events/Event", {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "content-type": "application/json",
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({eventNumber: props.enrollmentData.eventNumber, seatSku: props.enrollmentData.seatSku})
            
        })
            .then(resp => resp.json())
            .then(function(data) {
                setEventData(data);
            })
            .catch(function(error) {
                console.log(JSON.stringify(error));
            }); 
    }, [props.enrollmentData]);

    return (
        <>
            { props.enrollmentData.isBlended && <>
                <EnrollmentListingOnline todaysDate={props.todaysDate} enrollmentData={props.enrollmentData} eventData={eventData} addPageLevelAlert={props.addPageLevelAlert} />
            </> }

            <EnrollmentListing todaysDate={props.todaysDate} enrollmentData={props.enrollmentData} eventData={eventData} />

            { props.enrollmentData.isBlended && <>        
                <EnrollmentExamListing addPageLevelAlert={props.addPageLevelAlert} todaysDate={props.todaysDate} enrollmentData={props.enrollmentData} eventData={eventData} />
            </> }
        </>
    )
};

export default Enrollment;