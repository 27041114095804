import i18n from "i18next";

export enum InvoiceStatus {
    Active = 1,
    Expired = 2,
    Removed = 3,
    Adjusted = 4
}

export namespace InvoiceStatus {
    export function toString(status: InvoiceStatus): string {
        switch (status) {
            case InvoiceStatus.Expired:
                return i18n.t("Expired");
            case InvoiceStatus.Removed:
                return i18n.t("Removed");
            case InvoiceStatus.Adjusted:
                return i18n.t("Adjusted");
            case InvoiceStatus.Active:
            default:
                return i18n.t("Active");
        }
    }
}
