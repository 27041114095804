import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";
import Accordion from "../../Components/UI/accordion";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const TeachingSafetyInterventions = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <>
    <div className="page-content m-auto">
        <Container fluid className="page-padding">
            {/* WRITE HTML BELOW THIS LINE */}
            <OneColumnLayout
                marginTop={true}
                center={
                    <>
                        {/* WRITE HTML BELOW THIS LINE */}
                        <h1 className="page-title-h1">{ t('TeachingSafetyInterventions-Title') }</h1>
                        <p className="mw-900 mt-3">{ t('TeachingSafetyInterventions-Intro') }</p>

                        <Tile title={ t('TeachingSafetyInterventions-Header') } hideTileHeader={false}>
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <GrayTile percentWidth={100}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <h3 className="sub-headings-h3 m-0 text-uppercase">{ t('TeachingSafetyInterventions-ShowClassroomModel') }</h3>
                                    </>
                            </GrayTile>
                                    { tp('TeachingSafetyInterventions-ShowClassroomModel') }

                                    <GrayTile percentWidth={100}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <h3 className="sub-headings-h3 m-0 text-uppercase">{ t('TeachingSafetyInterventions-DemonstrateClassroomModel') }</h3>
                                    </>
                            </GrayTile>
                                    { tp('TeachingSafetyInterventions-DemonstrateContent') }
                                    <GrayTile percentWidth={100}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <h3 className="sub-headings-h3 m-0 text-uppercase">{ t('TeachingSafetyInterventions-PracticeClassroomModel') }</h3>
                                    </>
                            </GrayTile>
                            { tp('TeachingSafetyInterventions-PracticeContent') }

                                    <GrayTile percentWidth={100}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <h3 className="sub-headings-h3 m-0 text-uppercase">{ t('TeachingSafetyInterventions-Assessment') }</h3>
                                            </>
                                    </GrayTile>
                                    { tp('TeachingSafetyInterventions-AssessmentContent') }
                            
                                    <GrayTile percentWidth={100}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <h3 className="sub-headings-h3 m-0 text-uppercase">{ t('TeachingSafetyInterventions-CriticalThinking') }</h3>
                                            </>
                                    </GrayTile>
                                    <div className="ml-3 mt-3">
                                    { tp('TeachingSafetyInterventions-DynamicPractice') }
                                    <h5 className="sub-section-heading text-uppercase">{ t('Additional-Examples') }</h5>
                                    <Accordion 
                                        title={ t('DisengagementSkills') }
                                        isOpen={false}
                                        isComplete={false}
                                        children={<>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-DP-BlockandMove') }
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-DP-Grab') }
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-DP-TurningAway') }
                                                </>
                                            }
                                        />

                                        </>}
                                    />


                                        <Accordion 
                                            title={ t('HoldingSkills') }
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <TwoColumnLayout
                                                left={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        { tp('TeachingSafetyInterventions-DP-SeatedHolding') }
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        { tp('TeachingSafetyInterventions-DP-StandingHolding') }
                                                    </>
                                                }
                                            />

                                            </>}
                                        />
                                        <Accordion 
                                        title={ t('APS') }
                                        isOpen={false}
                                        isComplete={false}
                                        children={<>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-DP-TeamControl') }
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-DP-StandSupine') }
                                                   
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    
                                                    { tp('TeachingSafetyInterventions-DP-StandProne') }
                                                </>
                                            }


                                        />

                                            <TwoColumnLayout
                                                            left={
                                                                <>
                                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                                    { tp('TeachingSafetyInterventions-DP-Supine') }
                                                                </>
                                                            }

                                                            right={
                                                                <>
                                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                                    { tp('TeachingSafetyInterventions-DP-SupportedProne') }
                                                                </>
                                                            }
                                                        />

                                        </>}
                                    />
                                    </div>

                                    <div className="ml-3 mt-3">
                                    { tp('TeachingSafetyInterventions-ProblemSolving') }
                                    <h5 className="sub-section-heading text-uppercase">{ t('Additional-Examples') }</h5>
                                    <Accordion 
                                        title={ t('DisengagementSkills') }
                                        isOpen={false}
                                        isComplete={false}
                                        children={<>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-PS-BlockandMove') }
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-PS-Grab') }
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    { tp('TeachingSafetyInterventions-PS-TurningAway') }
                                                </>
                                            }
                                        />

                                        </>}
                                    />


                                        <Accordion 
                                            title={ t('HoldingSkills') }
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <TwoColumnLayout
                                                left={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        { tp('TeachingSafetyInterventions-PS-SeatedHolding') }
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        { tp('TeachingSafetyInterventions-PS-StandingHolding') }
                                                    </>
                                                }
                                            />

                                            </>}
                                        />
                                        <Accordion 
                                        title={ t('APS') }
                                        isOpen={false}
                                        isComplete={false}
                                        children={<>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <OneColumnLayout
                                                center={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        { tp('TeachingSafetyInterventions-PS-Scenarios') }
                                                    </>
                                                }
                                            />
                                     </>}
                                      />

                                    </div>


                            </>      
                        </Tile>


                    </>
                }
            />
        </Container>
    </div>
</>
            
        </>
    );
};

export default TeachingSafetyInterventions;
