import * as React from "react";
import { t } from "i18next";
import moment from "moment";

const ProgressBar = (props: {
    onlineCoursePercentComplete: number,
    startedDate?: string | null,
    lastLogin?: string | null,
    completedDate?: Date,
    totalTime?: number,
    score?: number,
    contactHours?: number,
    widthBar?: number,
    heightBar?: number,
    minified?: boolean,
}): JSX.Element => {
    const defaultProps: any = {
        minified: false,
        startedDate: null,
        lastLogin: null,
    };
    props = { ...defaultProps, ...props };

    const getTotalTime = () => {
        if (props.totalTime >= 3600) {
            const hours = Math.floor(props.totalTime / 3600);
            const totalSeconds = (props.totalTime - (3600 * hours));
            if (totalSeconds >= 60) {
                const minutes = Math.floor(totalSeconds / 60);
                return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
            }
            return `${hours} hr${hours > 1 ? 's' : ''}`;
        } else {
            const minutes = Math.floor(props.totalTime / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }
    };

    const startDate = props.startedDate !== null ? (moment(new Date(props.startedDate)).format('MM/DD/YYYY')) : null;
    const completedDate = props.completedDate ? (moment(props.completedDate).format('MM/DD/YYYY')) : null;
    const lastLoginDate = props.lastLogin !== null ? (moment(new Date (props.lastLogin)).format('MM/DD/YYYY')) : null;
    const totalTime = (props.totalTime !== null && props.totalTime > 0) ? getTotalTime() : null;
    const score = props.score ? props.score : null;
    const contactHours = props.contactHours ? props.contactHours : null;

    if (props.minified) {
        const completed = (props.onlineCoursePercentComplete == 100) ? true : false;
        return (
            <>
                <div className="progressbar_container" style={{width: `${props.widthBar? props.widthBar : 50}%`}}>
                    <div className="mb-2 progressbar_box">
                        <progress max="100" value={props.onlineCoursePercentComplete}></progress>
                        <span className="percent_label">{props.onlineCoursePercentComplete}%</span>
                    </div>
                    {startDate !== null ? (
                        <>
                            <div className="title small">{completed ? t("CPI.ActionItem.Completed") : t("CourseListing.InProgress")}</div>
                            { (props.completedDate) && <div className="title small">{t("dynamicDate", { val: new Date(props.completedDate), formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } })}</div>}
                        </>
                    ): (
                        <div className="title small">{t("CourseListing.NotStarted")}</div>
                    )}
                    {startDate && (
                        <div className="title small">{lastLoginDate !== null && lastLoginDate}</div>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            {
                <>
                    <div className="progressbar_container" style={{width: `${props.widthBar? props.widthBar : 50}%`}}>
                        <div className="mb-2 progressbar_box">
                            <progress max="100" value={props.onlineCoursePercentComplete}></progress>
                            <span className="percent_label">{props.onlineCoursePercentComplete}%</span>
                        </div>

                        <div className="label_values">
                            <div className="label_value">
                                <div className="title small">{t("Started")}</div>
                                <div className="value small">{startDate != null ? startDate : <i className="far fa-minus"></i>}</div>

                                <div className="title small">{t("Last login")} </div>
                                <div className="value small">{lastLoginDate != null ? lastLoginDate : <i className="far fa-minus"></i>}</div>

                                <div className="title small">{t("Total time")} </div>
                                <div className="value small">{totalTime != null ? totalTime : <i className="far fa-minus"></i>}</div>
                            </div>
                            <div className="label_value">
                                <div className="title small">{t("Score")} </div>
                                <div className="value small">{score != null ? score : <i className="far fa-minus"></i>}</div>

                                <div className="title small">{t("Contact hours")}</div>
                                <div className="value small">{contactHours != null ? contactHours : <i className="far fa-minus"></i>}</div>                                
                            </div>
                        </div >
                    </div>
                </>
            }
        </>
    );
};

export default ProgressBar;
