import * as React from "react";
import { BadgeDisplayStyle } from "../../Interfaces/Platform/BadgeDisplayStyle";

const Badge = (props: {
    children: JSX.Element,
    style: BadgeDisplayStyle
}): JSX.Element => {
    const style = props.style.style;

    return (
        <>
            <div className={"badge " + style + " id-label"}>{props.children}</div>
        </>
    );
};

export default Badge;
