import * as React from "react";

const DestructiveButton = (props: {
    label: string | React.ReactElement,
    elementId?: string,
    additionalStyleClasses?: string,
    isDisabled?: boolean,
    clickAction?: React.MouseEventHandler<HTMLButtonElement>
}): JSX.Element => {
    const isDisabled = props.isDisabled ?? false;
    const additionalStyleClasses = props.additionalStyleClasses ? props.additionalStyleClasses : '';

    return (
        <>
            <button disabled={isDisabled} onClick={props.clickAction} className={additionalStyleClasses + " inline-button inline-icon-left cancel-button"}>{props.label}</button>
        </>
    );
};

export default DestructiveButton;
