import React, { useEffect } from 'react';

import { injectCultureToRoute } from '../Pages/Utils/routing';
import { RbacContext } from '../rbac-context';

import LoadingContent from '../Components/UI/loading-content';

import useFeatureFlags from '../hooks/useFeatureFlags';
import { useTranslation } from 'react-i18next';

import { RoleEnum } from '../Enums';

import useRouteAccessCheck from '../hooks/useRouteAccessCheck';

interface RedirectProps {
    baseUrl: string;
    path: string;
    children: React.ReactNode;
}

const Redirect = ({ baseUrl, path, children }:RedirectProps) => {
    const rbac = React.useContext(RbacContext);
    const { t } = useTranslation();
    const { rac, isCPIStaff } = useRouteAccessCheck();

    const [ITLTransitionRedirects] = useFeatureFlags("ITLTransitionRedirects", "pending");

    const baseAndPathUrlsArePresent = (a:string, b:string) => {
        return !!(a && b);
    }

    const isLinkingToDashboard = (path:string) => {
        return path == "/Platform/Dashboard";
    }

    const isLinkingToProfile = (path:string) => {
        return (path == "/Platform/Dashboard/Profile") || (path == "/Platform/Dashboard/Profile/Edit-Profile");
    }

    const isLinkingToDashboardOrProfile = (path:string) => {
        return isLinkingToDashboard(path) || isLinkingToProfile(path);
    }

    const isLearnerOrNoRole = () => {
        return (rbac.userContext.RolesByName.every(role => role == RoleEnum.Learner) || !rbac.userContext.RolesByName || (rbac.userContext.RolesByName.length == 0)) && !isCPIStaff();
    }

    const doesRedirectForDashboardOrProfile = (path:string) => {
        return (isLinkingToDashboard(path) || isLinkingToProfile(path)) && !isLearnerOrNoRole();
    }

    useEffect(() => {

        // if the feature flag is on
        // and we have a base URL and path values for temp redirects
        // and we are linking to the dashboard/profile and are not only a learner
        // or we are not linking to the dashboard/profile
        // then redirect
        if ((ITLTransitionRedirects === true) && (baseAndPathUrlsArePresent(baseUrl, path) && ((isLinkingToDashboardOrProfile(path) && !isLearnerOrNoRole()) || !isLinkingToDashboardOrProfile(path)))) {
            const urlString = injectCultureToRoute(rbac.userContext.Country, baseUrl, path);
            window.location.href = urlString;
        }

    }, [baseUrl, path, rbac.userContext.Country, ITLTransitionRedirects]);

    return <>{ (!ITLTransitionRedirects || (!baseUrl && !path) || !doesRedirectForDashboardOrProfile(path)) ? children : <>
    
        <LoadingContent 
                    title={t("Loading")} 
                    icon={
                        <div className="loading-content-participants" style={{ fontSize: '3em' }}>
                            <i className="fas fa-duotone fa-spinner icon" />
                        </div>
                    } 
                />

        </>

    }</>;
};

export default Redirect;