import {} from "../../ApiServices/AppSettings";

import * as React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { RbacContext } from "../../rbac-context";

import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../../configuration-context";
import CourseEditionModel from "../../Interfaces/CourseEditionModel";
import { createCourseEdition, fetchSpecialtyQualifications } from "../Utils/course";
import { fetchProgramEditions } from "../Utils/classCreate";

interface CreateCourseEditionFormProps {
    specialties: any; 
    languages: any;
    courseLanguageId: string;
    courseId: string;
    isVisible: boolean;
    onSave: (response: any) => void;
    onHide: () => void;    
}

const CourseEditionForm = ({
    specialties,
    languages,
    courseLanguageId,
    courseId,
    isVisible,
    onSave,
    onHide,    
}: CreateCourseEditionFormProps) => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [courseEdition, setCourseEdition] = React.useState<CourseEditionModel>(
        {
            courseEditionId: "",
            specialty: undefined,
            edition: undefined,
            qualification: "",
            courseId: courseId,
            culture: undefined,
        });
    const [specialtyEditions, setSpecialtyEditions] = React.useState<any>([]);
    const [qualifications, setQualifications] = React.useState<any>([]);
    const [validationErrors, setValidationErrors] = React.useState<any>(undefined);

    React.useEffect(() => {
        const culture = languages.find((language: any) => language.courseLanguageId === courseLanguageId)?.code;  
        setCourseEdition({ ...courseEdition, culture: culture });
    }, [courseLanguageId]);

    React.useEffect(() => {
        if(!courseEdition.specialty) return;   

        const loadData = async () => {
            const specialtyEditions = await fetchProgramEditions(apimBaseUrl, configContext, courseEdition.specialty.specialtyName, null);
            setSpecialtyEditions(specialtyEditions);
            const qualifications = await fetchSpecialtyQualifications(configContext, courseEdition.specialty.specialtyId);    
            setQualifications(qualifications);
        } 
        loadData();

    }, [courseEdition.specialty]);    

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setSaving(true);

                var payload = {
                    ...courseEdition,
                    specialty: courseEdition.specialty.specialtyName,
                    edition: courseEdition.edition.edition,
                    qualification: qualifications?.find((qualification: any) => qualification.specialtyQualificationId === courseEdition.qualification)?.qualification 
                        || courseEdition.qualification 
                        || (courseEdition.edition?.hasAdditionalContent ? courseEdition.edition.defaultContent : "None"),
                };

                const response = await createCourseEdition(configContext, payload);

                setSaving(false);

                if (response.status === 200) {
                    onSave(response);
                    setValidated(true);
                    return;
                } else if (response.status === 409) {
                    const validationError = await response.json();
                    setValidationErrors({CourseEditions: [validationError]});
                } else {
                    setValidationErrors({CourseEditions: ["There was an error processing the request."]});
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
        }
        setValidated(false);
    };

    return (
        <Modal show={isVisible} size="lg" onHide={() => {}} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Create Edition")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group className="row-padding" as={Row} controlId="specialty">
                        <Form.Label className="label-title" column sm="3">
                            {t("Specialty")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                required
                                value={courseEdition.specialty?.specialtyId || ""}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseEdition({
                                        ...courseEdition,
                                        specialty: specialties.find(specialty => specialty.specialtyId === e.target.value),
                                        edition: undefined,
                                        qualification: "",
                                    })

                                }
                            >
                                <option value="">{t("Select Specialty")}</option>
                                {specialties?.map((specialty: any) => (
                                    <option key={specialty.specialtyId} value={specialty.specialtyId}>
                                        {tp(specialty.specialtyName)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className="row-padding" as={Row} controlId="edition">
                        <Form.Label className="label-title" column sm="3">
                            {t("Edition")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                required 
                                value={courseEdition.edition?.edition || "" }
                                disabled={!specialtyEditions || specialtyEditions.length === 0}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        const newEdition = specialtyEditions.find(edition => edition.edition === e.target.value);
                                        setCourseEdition({ 
                                            ...courseEdition, 
                                            edition: newEdition
                                        });
                                    }
                                }
                            >
                                <option value="">{t("Select Edition")}</option>
                                {specialtyEditions?.map((edition: any, index: number) => (
                                    <option key={index} value={edition.edition}>
                                        {tp("Edition." + edition.edition) == "Edition." + edition.edition ? edition.edition : tp("Edition." + edition.edition)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className="row-padding" as={Row} controlId="qualification">
                        <Form.Label className="label-title" column sm="3">
                            {t("Qualification")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                required
                                value={courseEdition.qualification || ""}
                                disabled={(!qualifications || qualifications.length === 0) && !courseEdition.edition?.hasAdditionalContent}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseEdition({ ...courseEdition, qualification: e.target.value })
                                }
                            >                                
                                {courseEdition.edition?.hasAdditionalContent && (<option value={courseEdition.edition.defaultContent}>{courseEdition.edition.defaultContent}</option>)}
                                <option value="None">{t("None")}</option>
                                {qualifications?.map((qualification: any, index: number) => (
                                    <option key={index} value={qualification.specialtyQualificationId}>
                                        {tp(qualification.qualification)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    {validationErrors?.CourseEditions?.length > 0 && (
                        <Form.Group>
                            <Col
                                xs="12"
                                className="course-form-error"
                            >
                            {
                                validationErrors?.CourseEditions[0]
                            }
                            </Col>
                        </Form.Group>
                        )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, CourseEditionForm);
