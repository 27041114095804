import * as React from "react";

import useIncludesBySpecialtyCode from "../../../../hooks/useIncludesBySpecialtyCode";
import QualificationString from "../../../../Interfaces/Platform/QualificationString";

import parseHtml from 'html-react-parser';

import  useParsedTranslation from "../../../../hooks/useParsedTranslation";

const MyCertificationSummary = (props: {
    certTitle: string,
    additives?: QualificationString[]
}) => {
    const { tp } = useParsedTranslation();

    const createIncludes = (
        specialtyCode: string
    ) => {
        return (
            <>
                {useIncludesBySpecialtyCode(props.certTitle).map((specialtyCode, i) => (
                    <div className="my-cert-subtitle">{ "includes " + tp(specialtyCode) }</div>
                ))}
            </>
        )
    }

    const createAdditives = (
        additives: QualificationString[]
    ) => {
        return (
            <>
                {additives.map((qualification, i) => (
                    <div className="my-cert-additive">{ "+ " + tp(qualification.qualification) }</div>
                ))}
            </>
        );
    };

    return (
        <div>
            <div className="my-cert">
                <div className="my-cert-title h4">{ tp(props.certTitle.toString()) }</div>
                { createIncludes(props.certTitle) }
                { props.additives ? createAdditives(props.additives) : null }
            </div>
        </div>
    )
};

export default MyCertificationSummary;