import * as React from "react";
import { ConfigContext } from "../../configuration-context";

const CartIcon = (props: {
    numberOfItemsInCart: number
}): JSX.Element => {

    const configContext = React.useContext(ConfigContext);

    return (
        <>
            <a className="nav-link btn-cart d-flex" id="btn-Cart" href={configContext.SystemConfiguration.PublicSiteShoppingCart}>
                <span className="nav-icon icon-cart"><span className="sr-only">Cart</span></span>
            </a>
        </>
    );
};

export default CartIcon;
