import * as React from "react";

import useMonthLabelByMonthCode from "../../../../hooks/useMonthLabelByMonthCode";
import { CertStatusEnum } from "../../../../Enums/CertStatusEnum";
import { useTranslation } from "react-i18next";

const MyCertificationStatus = (props: {
    status:string,
    startDate:Date,
    endDate:Date
}) => {

    const { t } = useTranslation();

    const inactiveStyleClass = (props.status.toLocaleLowerCase() == CertStatusEnum.Inactive) ? ' error': '';
    const { month } = useMonthLabelByMonthCode();

    return (
        <div className="my-cert-status">
            <div className={"cert-status-label sub-section-heading" + inactiveStyleClass}>{CertStatusEnum.toTranslatedString(props.status.toLowerCase())}</div>
            <div className="cert-dates-label small">{t('dynamicDate', { val: props.startDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) + " - " + t('dynamicDate', { val: props.endDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } })}</div>
        </div>
    )
};

export default MyCertificationStatus;