import * as React from "react";

import { useTranslation } from "react-i18next";
import { Form, Col, Row } from "react-bootstrap";

export interface UpdatePasswordProps {
    newPassword: string,
    setNewPassword: any,
    newPasswordLabel?: string,
    confirmPassword: string,
    setConfirmPassword: any,
    confirmPasswordLabel?: string,
    passwordIsValid: boolean,
    setPasswordIsValid: any,
    existingPassword?: string,
    setExistingPassword?: any,
    existingPasswordLabel?: string,
    showExistingPassword?: boolean,
    rowClass?: string,
    reqStyle?: any
}

const UpdatePassword = ({
    newPassword,
    setNewPassword,
    newPasswordLabel,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordLabel,
    passwordIsValid,
    setPasswordIsValid,
    existingPassword,
    setExistingPassword,
    existingPasswordLabel,
    showExistingPassword,
    rowClass,
    reqStyle
}: UpdatePasswordProps) =>
{
    const { t } = useTranslation();
    rowClass = rowClass ?? "row field-wrapper d-flex mt-3";
    const regexList = [/.{8,}/, /[A-Z]/, /[a-z]/, /[0-9]/, /[!@#$%`~^&*()\-_=+[\]{}.,<>\/\\?'";:|]/];

    const validatePassword = async (newPwd: string, confirmPwd: string) => {
        const newPass = newPwd ?? newPassword;
        const confirmPass = confirmPwd ?? confirmPassword;
        const pwdRequirements = document.getElementById("password-requirements");
        let isValidPwd = true;
        for (let j = 0; j < regexList.length; j++) {
            if (newPass.match(regexList[j])) {
                pwdRequirements.children[j].classList.add("complete");
            }
            else {
                isValidPwd = false;
                pwdRequirements.children[j].classList.remove("complete");
            }
        }

        if (!validateConfirmPassword(newPass, confirmPass)) {
            setPasswordIsValid(false);
        }
        else {
            setPasswordIsValid(isValidPwd);
        }
    };

    const validateConfirmPassword = async (newPass: string, confirmPass: string) => {
        const pwdRequirements = document.getElementById("password-requirements");
        if (newPass === confirmPass && newPass != "") {
            pwdRequirements.children[regexList.length].classList.add("complete");
            return true;
        }
        else {
            pwdRequirements.children[regexList.length].classList.remove("complete");
            return false;
        }
    };

    return (
        <Row className={rowClass} >
            <Col className="col-12 col-sm-6 change-password-fields">
                <ul className="brand-form-fieldset form-snippet-field-list">
                    {showExistingPassword && (
                        <li className="form-item required">
                            <Form.Group controlId="existing-password" className="field-group">
                                <Form.Label className="" htmlFor="existing-password">
                                    {existingPasswordLabel ?? t("Current Password")}</Form.Label>
                                <input className="default-text-field-control" id="existing-password" name="existing-password" type="password" autoComplete="off" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setExistingPassword(event.target.value);}}
                                    value={existingPassword} />
                                <Form.Control.Feedback type="invalid" className="field-validation default-field-control-error-explanation">
                                    {t("Current password is required.")}</Form.Control.Feedback>
                            </Form.Group>
                        </li>
                    )}
                    <li className="form-item required confirmed-field">
                        <Form.Group controlId="new-password" className="field-group">
                            <Form.Label className="" htmlFor="new-password">
                                {newPasswordLabel ?? t("New Password")}</Form.Label>
                            <input className="default-text-field-control"
                                id="new-password"
                                name="new-password"
                                type="password"
                                autoComplete="off"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setNewPassword(event.target.value); validatePassword(event.target.value, null); }}
                                value={newPassword}
                            />
                            <Form.Control.Feedback type="invalid" className="field-validation default-field-control-error-explanation">
                                {t("New password is required")}</Form.Control.Feedback>
                        </Form.Group>
                    </li>
                    <li className="form-item required confirm-field">
                        <Form.Group controlId="confirm-new-password" className="field-group">
                            <Form.Label className="" htmlFor="confirm-new-password">
                                {confirmPasswordLabel ?? t("Confirm New Password")}</Form.Label>
                            <input className="default-text-field-control"
                                id="confirm-new-password"
                                name="confirm-new-password"
                                type="password"
                                autoComplete="off"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setConfirmPassword(event.target.value); validatePassword(null, event.target.value); }}
                                value={confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid" className="field-validation default-field-control-error-explanation">
                                {t("Confirm password is required.")}</Form.Control.Feedback>
                        </Form.Group>
                    </li>
                </ul>
            </Col>
            <Col className="col-12 col-sm-6 mt-3 mt-sm-0 change-password-requirements">
                <div className="password-requirement-box" style={reqStyle}>
                    <h3 className="fs18 bold mb-1">{t("New Password Requirements")}</h3>
                    <ul id="password-requirements" className="password-requirement-list">
                        <li>{t("At least 8 characters")}</li>
                        <li>{t("Uppercase character")}</li>
                        <li>{t("Lowercase character")}</li>
                        <li>{t("Number")}</li>
                        <li>{t("Symbol")}</li>
                        <li>{t("New password matches password confirmation")}</li>
                    </ul>
                </div>
            </Col>
        </Row>
    );
};

export default UpdatePassword;
