import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

import Accordion from "../../Components/UI/accordion";
import VideoEmbed from "../../Components/UI/video-embed";


const IntroducingThirdEdition = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}

            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                        marginTop={false}

                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1 title-margin">Introducing 3rd Edition</h1>
                                    <p>We’re thrilled to announce the 3rd Edition of our foundational training programs: <span className="font-italic">Verbal Intervention</span><sup>™</sup>, <span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup>, and NCI<sup>™</sup> With Advanced Physical Skills.</p>
                                </>
                            </>
                        }
                    />

                    <Tile title="" hideTileHeader={true}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <h2 className="section-title-h2 text-center mt-4 mb-5">We’ve updated the program to make it easier to train and improve learner comprehension and retention.</h2>


                            <ThreeColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <div className="align-items-center d-flex flex-column">
                                                    <img width="150" src="https://cdn.crisisprevention.com/media/training-center/images/brain.png" alt="Brain Science" />
                                                    <p className="mt-2 text-center">Greater emphasis on <span className="bold">understanding distress behaviors</span> and the role of brain science.</p>
                                                </div>
                                            </>
                                        </GrayTile>
                                    </>
                                }

                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <div className="align-items-center d-flex flex-column">
                                                    <img width="150" src="https://cdn.crisisprevention.com/media/training-center/images/trauma.png" alt="Trauma" />
                                                    <p className="mt-2 text-center">Additional information on <span className="bold">person-centered and trauma-informed</span> approaches.</p>
                                                </div>
                                            </>
                                        </GrayTile>
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <div className="align-items-center d-flex flex-column">

                                                    <img width="150" src="https://cdn.crisisprevention.com/media/training-center/images/physicals.png" alt="Brain Science" />
                                                    <p className="mt-2 text-center">An approach to training physical interventions that stresses <span className="callout">the principles behind the techniques</span>, with video demonstrations that reinforce what you teach.</p>
                                                </div>
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />

                    
                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <>
                                            <h2 className="section-title-h2 text-center mt-3 mb-4">Everything you need to know about CPI's 3rd Edition Training</h2>
                                            <VideoEmbed title="Module 3 Video" vimeoId="839700216" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/__thumbnails/Thumb-3EditionVideo.png" />
                                        </>
                                    </>
                                }
                            />
                        </>
                    </Tile>


                    <EightByFour
                        left={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <Tile title="General Questions" hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <Accordion
                                            title="Am I able to start training 3rd Edition?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>You do not need to attend your renewal to start teaching 3rd edition. You will need to purchase 3rd Edition workbooks or blended learning packages for your participants. Your 3rd Edition Instructor guide is also available for download.</p>
                                                <ArrowLink data={{ label: "View Instructor Guides & Presentations", href: "Dashboard/TrainingMaterials", openInNewTab: false }} />
                                                <ArrowLink data={{ label: "Order Workbooks & Seats", href: "", openInNewTab: false }} />
                                            </>}
                                        />

                                        <Accordion
                                            title="How is 3rd edition an improvement over the last edition?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>CPI improved both the content and flow with this latest edition.</p>
                                                <p className="bold">Content</p>
                                                <ul>
                                                    <li>A new Module 1 focuses on interpreting the brain’s response to threat as one of the causes of distress behavior, identifying the level of risk of the behavior, and identifying preventive strategies to mitigate risk.</li>
                                                    <li>An increased focus on a person-centered and trauma-informed approach throughout the core content.</li>
                                                </ul>
                                                <p className="bold">Flow</p>
                                                <ul>
                                                    <li>An instructional design based on adult learning focused on connecting the knowledge to the experience of the learner and applying that knowledge in real-life situations.</li>
                                                    <li>A new Instructor Guide design that makes it easier to teach by incorporating instructor tips and facilitation pages throughout.</li>
                                                </ul>
                                            </>}
                                        />

                                        <Accordion
                                            title="Since 3rd Edition is more trauma-informed, how is it different from the Trauma Specialized Topic?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>Trauma in 3rd Edition is taught from the perspective of Precipitating Factors and person-centered care. The Trauma Specialized Topic goes into greater detail on how trauma impacts behavior and how the Trauma Responsive Practice can assist you in better meeting the needs of those in your care who have experienced trauma.</p>

                                            </>}
                                        />

                                        <Accordion
                                            title="What is different about Verbal Intervention™ 3rd Edition vs. 2nd Edition?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>The new Module 1 introduces core concepts that are built upon and reinforced throughout the rest of the program. There are also new activities to reinforce these concepts. The goal is to more strongly emphasize the role <span className="font-italic">Verbal intervention</span><sup>™</sup> plays in assessing risk and defusing a potential crisis situation before it requires hands-on intervention or restraint, which is always used as a last resort.</p>
                                            </>}
                                        />

                                        <Accordion
                                            title="What is different about physical interventions in 3rd Edition?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>With 3rd Edition, Certified Instructors will find that CPI has added structure to the teaching of physical interventions.</p>

                                                <p>3rd Edition focuses on the principle behind each technique, with video support for each set of physical interventions to ensure fidelity and consistency of the training across organizations. The instructor then demonstrates what was in the video to help learners absorb the content. Training that emphasizes the principle over the choreography of a specific technique gives learners the flexibility to identify the right principle and technique for a specific risk or situation.</p>

                                                <p>The Instructor Guide features key points to help Certified Instructors facilitate and assess the competency of learners.</p>

                                                <p>As in previous editions, the physical interventions taught in 3rd Edition have been risk assessed by an independent organization to ensure the safety of all individuals. We are featuring these assessments more for this edition. Certified Instructors and learners can view the third-party assessment by scanning a QR code within both the 3rd Edition Instructor Guide and workbook. Bringing in an outside third party to assess the risk of each technique ensures that CPI continues living out its mission of <span className="font-italic">Care, Welfare, Safety, and Security</span><sup>℠</sup> for staff and the people they serve.</p>
                                            </>}
                                        />

                                        <Accordion
                                            title="What has changed between Nonviolent Crisis Intervention® and NCI™ With Advanced Physical Skills in terms of the physical intervention skills taught?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}

                                                <p>CPI adds supports to the teaching of Safety Interventions, while maintaining an easy to learn principle-based approach when teaching Disengagement and Holding Skills.</p>
                                                <p>3rd Edition builds on this foundational base by providing video support of classroom model techniques to ensure fidelity and consistency of training. This will allow instructors to begin instruction by playing a consistent video that clearly explains the principles and demonstration of each technique. An instructor can then follow up this video with their own in-class demonstration to reinforce learning and help learners absorb the content.</p>
                                                <p>The Instructor Guide features key points to help Certified Instructors facilitate and assess the competency of learners.</p>
                                                <p className="bold">New Safety Interventions</p>
                                                <p><span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup> 3rd Edition has new content that assists in “Turning Away,” a technique designed to safely redirect or separate individuals from an area or potential crisis situation.</p>
                                                <p className="bold">Updates to Safety Interventions</p>
                                                <p>CPI has repositioned some of our physical interventions to better align with our most recent Independent Risk Assessment. The <span className="font-italic">Team Control Position</span><sup>℠</sup> will now be within our NCI<sup>™</sup> With Advanced Physical Skills, 3rd Edition program.</p>
                                                <p>CPI recommends that any organization that wants to continue training our full set of safety interventions should receive certification in NCI<sup>™</sup> With Advanced Physical Skills, 3rd Edition.</p>
                                                <p className="bold">Rules for Teaching Physical Intervention Skills With <span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup> training</p>
                                                <ul>
                                                    <li>An existing <span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup> Certified Instructor
                                                        <ul>
                                                            <li>Can continue to teach and use all physicals skills taught to them in their last program.</li>
                                                        </ul>
                                                    </li>
                                                    <li>A new <span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup> Certified Instructor
                                                        <ul>
                                                            <li>Can only teach the physical interventions taught in the <span className="font-italic">Nonviolent Crisis Intervention</span><sup>®</sup> 3rd Edition program.</li>
                                                            <li>That wants/needs to teach <span className="font-italic">Team Control Position</span><sup>℠</sup> will need to be certified in NCI<sup>™</sup> With Advanced Physical Skills, 3rd Edition.</li>
                                                            <li> Can team teach <span className="font-italic">Team Control Position</span><sup>℠</sup> only with an existing Instructor who was trained in <span className="font-italic">Team Control Position</span><sup>℠</sup>.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </>}
                                        />

                                        <Accordion
                                            title="We currently teach and use all of the physical interventions taught in Nonviolent Crisis Intervention® 2nd Edition. If we move to 3rd Edition, do I need to start training my learners on NCI™ With Advanced Physical Skills?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>CPI recommends that any organization that wants to continue training on all physical interventions to consider moving to NCI<sup>™</sup> With Advanced Physical Skills.</p>

                                            </>}
                                        />

                                        <Accordion
                                            title="Can I still use my inventory of 2nd Edition workbooks?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>Yes. We encourage you to exhaust your current inventory of 2nd Edition materials before ordering 3rd Edition materials. Please do not mix your 2nd and 3rd Edition workbooks and instructor guides, as the modules, content flow, and teaching approach have changed.</p>
                                            </>}
                                        />

                                        <Accordion
                                            title="What if I have staff who have been trained in 3rd Edition, but we only have 2nd Edition books?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>You can certainly still train 2nd Edition. You have all the skills and content you need. You can download the 2nd Edition Instructor Guide, co-train a program with one of your current Certified Instructors or contact the Training and Development Consultant Team for assistance in training 2nd Edition.</p>
                                            </>}
                                        />

                                        <Accordion
                                            title="I'm doing an informal refresher to get my 2nd Edition learners up to speed on 3rd Edition. Can I wait to purchase 3rd Edition workbooks until next year when their current Blue Card® is due for a formal renewal?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>I'm doing an informal refresher to get my 2nd Edition learners up to speed on 3rd Edition. Can I wait to purchase 3rd Edition workbooks until next year when their current Blue Card<sup>®</sup> is due for a formal renewal?</p>
                                            </>}
                                        />

                                        <Accordion
                                            title="I'm not comfortable teaching this without seeing the new edition, and I just completed a 2nd Edition training program. Do I have to pay again to take the class?"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <p>No. CPI offers a number of options to help you become comfortable with 3rd Edition content:</p>

                                                <ul>
                                                    <li><a href="/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources/Training-Videos">Review the videos for teaching physical interventions</a></li>
                                                    <li>Schedule a call with a CPI Training and Development Consultant.
                                                        <ul>
                                                            <li>Call: 800-558-8976</li>
                                                            <li>Email: <a className="inline-link" href="mailto: instructor@crisisprevention.com">instructor@crisisprevention.com</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </>}
                                        />
                                    </>
                                </Tile>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <Tile title="Classroom Materials" hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <h4 className="h4 mt-4">Can I order materials without going through 3rd Edition training?</h4>
                                        <p>Yes. You can order workbooks or blended seats to start training 3rd Edition. Note that <span className="font-italic">Verbal Intervention</span><sup>™</sup> 3rd Edition product materials will be available as both a blended and classroom only training.</p>
                                        <Button label="Buy Workbooks & Seats" isSolid={false} elementId="buyWorkbooks" href="/Dashboard" />

                                        <h4 className="h4 mt-4">Will I have access to 3rd Edition video resources if I have not attended a training?</h4>
                                        <p>You will have access to 3rd Edition video resources regardless of whether you have taken the training from a Global Professional Instructor.</p>
                                        <Button label="View Training Videos" isSolid={false} elementId="viewVideos" href="/Dashboard" />

                                        <h4 className="h4 mt-4">How do I access a 3rd Edition Instructor Guide?</h4>
                                        <p>You will receive printed copies of the Instructor Guide and other 3rd Edition materials at your next in-person renewal training. You also have access to download a digital version anytime.</p>
                                        <Button label="View Instructor Guides & Presentations " isSolid={false} elementId="viewGuides" href="/Dashboard" />

                                        <h4 className="h4 mt-4">I have a recent purchase of 2nd Edition materials. Can I return them for 3rd Edition?</h4>
                                        <p>I have a recent purchase of 2nd Edition materials. Can I return them for 3rd Edition?</p>

                                    </>
                                </Tile>


                            </>
                        }
                    />

                </Container>

            </div>

        </>
    );
};

export default IntroducingThirdEdition;
