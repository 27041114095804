import * as React from 'react';
import { getFeatureFlagValue, getFeatureFlags } from '../ApiServices/FeatureFlags';

type BooleanOrPending = boolean | "pending";

const useFeatureFlags = (featureFlagId:string, initialValue?:BooleanOrPending) => {

    const [ featureFlagValue, setFeatureFlagValue ] = React.useState((initialValue) ? initialValue : false);
    const [ featureFlags, setFeatureFlags ] = React.useState([]);

    const getFeatureFlagValueAsync = async () => {
        const val = await getFeatureFlagValue(featureFlagId);
        setFeatureFlagValue(val);
    }

    const getFeatureFlagsAsync = async () => {
        const val = await getFeatureFlags();
        setFeatureFlags(val);
    }

    React.useEffect(() => {
        getFeatureFlagValueAsync();
        getFeatureFlagsAsync();
    }, []);

    return [ featureFlagValue, featureFlags ] as const;

}

export default useFeatureFlags;