import * as React from "react";

import { Accordion, AccordionContext, Button, Card, ListGroup, useAccordionToggle } from "react-bootstrap";
import { PreviewLesson, PreviewModule } from "../../Interfaces";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const ContextAwareToggle = ({ module, callback }: { module: PreviewModule; callback?: (eventKey: string) => void }) => {
    const eventKey = module.ModuleId.toString();
    const currentEventKey = React.useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <Button
            variant="light"
            className="d-flex justify-content-between align-items-center w-100 test-start rounded-0"
            onClick={decoratedOnClick}
        >
            <span>
                <i className="me-2 fa fa-play-circle"></i>
                {module.Name}
            </span>
            <div className="fa-stack float-end accordion-icon">
                <i className="fas fa-circle fa-stack-2x"></i>
                <i className={`fas fa-stack-1x ${isCurrentEventKey ? "fa-minus" : "fa-plus"} fa-inverse`}></i>
            </div>
        </Button>
    );
};

const CoursePreviewBreakdown = ({
    modules,
    onPlay
}: {
    modules: PreviewModule[];
    onPlay: (learnerModule: PreviewModule, learnerLesson: PreviewLesson) => void;
}) => {
    return (
        <Accordion defaultActiveKey={modules[0].ModuleId.toString()}>
            {modules.map((m, i) => (
                <Card key={m.ModuleId}>
                    <Card.Header className="p-0">
                        <ContextAwareToggle module={m} />
                    </Card.Header>
                    <Accordion.Collapse eventKey={m.ModuleId.toString()}>
                        <Card.Body className="p-0">
                            <ListGroup onSelect={() => {}}>
                                {m.Lessons.map((l) => (
                                    <ListGroup.Item
                                        key={l.LessonId}
                                        action
                                        className="border-0 rounded-0"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onPlay(m, l);
                                        }}
                                    >
                                        <i className="me-2 fa fa-play-circle"></i>
                                        {l.Name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    );
};

export default withAITracking(reactPlugin, CoursePreviewBreakdown);
