import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const OneColumnLayout = (props: {
    center: JSX.Element,
    marginTop?: boolean,
}): JSX.Element => {
    const marginTop = props.marginTop ?? true;

    return (
        <Container className={`mw-1440 ${marginTop ? 'mt-4' : 'mt-0'}`} fluid>
            <Row className="justify-content-center">
                <Col className="pl-0 pr-0" xs={12}>
                    {props.center}
                </Col>                    
            </Row>
        </Container>
    );

};

export default OneColumnLayout;
