import { GETWITHERROR } from ".";
import { JsonResponseModel } from ".";

const GET_FEATURE_FLAG_VALUE_URL = "/Configuration/GetFeatureFlagValue/";
const GET_FEATURE_FLAGS_URL = "/Configuration/GetFeatureFlags"

export function getFeatureFlagValue(flagId: string) : Promise<JsonResponseModel<boolean>> {    
    return GETWITHERROR<boolean>(GET_FEATURE_FLAG_VALUE_URL + "?flagId=" + flagId);
}

export function getFeatureFlags() : Promise<JsonResponseModel<string[]>> {
    return GETWITHERROR<string[]>(GET_FEATURE_FLAGS_URL);
}