import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const PreparingForTraining = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('PrepareTraining-Title') }</h1>
                                <TwoColumnLayout
                                    left={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <Tile title={ t('PrepareTraining-Planning-Header') } hideTileHeader={false}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <span className="parsed-text">{ tp('PrepareTraining-Planning-Intro') }</span>
                                                    <h3 className="sub-headings-h3">{ t('PrepareTraining-Considerations-Title') } </h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Considerations') }</span>
                                                    <h3 className="sub-headings-h3">{ t('PrepareTraining-Resources-Title') } </h3>
                                                    <ArrowLink data={{ label:  t('PrepareTraining-Resources-InstructorGuides')  , href: "URL", openInNewTab: false }} />
                                                    <ArrowLink data={{ label:  t('PrepareTraining-Resources-ElectronicPresentations')  , href: "URL", openInNewTab: false }} />
                                                    <ArrowLink data={{ label:  t('PrepareTraining-Resources-TrainingEnvironment')  , href: "Training-Environment-Setup", openInNewTab: false }} />
                                                    <ArrowLink data={{ label:  t('PrepareTraining-Resources-Virtual')  , href: "virtual-training", openInNewTab: false }} />

                                                </>      
                                            </Tile>
                                        </>
                                    }

                                    right={
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <Tile title={ t('PrepareTraining-Presenting-Title') }  hideTileHeader={false}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <h3 className="sub-headings-h3">{ t('PrepareTraining-Presenting-BeforeClass-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Presenting-BeforeClass') }</span>

                                                    <h3 className="sub-headings-h3">{ t('PrepareTraining-Presenting-ClassMgmt-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Presenting-ClassMgmt') }</span>

                                                    <ArrowLink data={{ label: t('BTN-MoreAboutManagingParticipants'), href: "managing-your-participants-and-classroom", openInNewTab: false }} />

                                                    <h3 className="sub-headings-h3 mt-4">{ t('PrepareTraining-Presenting-GivingLectures-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Presenting-GivingLectures') }</span>

                                                    <h3 className="sub-headings-h3">{ t('PrepareTraining-Presenting-SafetyInteventions-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Presenting-SafetyInteventions') }</span>

                                                    <ArrowLink data={{ label: t('BTN-TeachingSafetyInterventions'), href: "teaching-safety-interventions", openInNewTab: false }} />
                                                    <ArrowLink data={{ label: t('BTN-TrainingVideos'), href: "URL", openInNewTab: false }} />

                                                    <h3 className="sub-headings-h3 mt-4">{ t('PrepareTraining-Processing-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Processing') }</span>
                                                
                                                </>      
                                            </Tile>
                                        </>
                                    }
                                />
                                            <Tile title={ t('PrepareTraining-Timeline-Title') } hideTileHeader={false}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <h3 className="sub-headings-h3">{ tp('PrepareTraining-Timeline-Month-Title') }</h3>
                                                    { tp('PrepareTraining-Timeline-Month-Intro') }
                                                    <TwoColumnLayout
                                                        left={
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Month-Announcement') }</span>
                                                            </>
                                                        }

                                                        right={
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Month-Requirements') }</span>
                                                            </>
                                                        }
                                                    />


                                                    <h3 className="sub-headings-h3 mt-4">{ tp('PrepareTraining-Timeline-Weeks-Title') }</h3>
                                                    <span className="parsed-text">{ tp('PrepareTraining-Timeline-Weeks') }</span>


                                                    <h3 className="sub-headings-h3 mt-5">{ tp('PrepareTraining-Timeline-Days-Title') }</h3>
                                                    <TwoColumnLayout
                                                        left={
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-ConfirmAttendance-Title') }</h4>
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-ConfirmAttendance') }</span>

                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-Room-Title') }</h4>
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-Room') }</span>

                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-Materials-Title') }</h4>
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-Materials') }</span>
                                                            </>
                                                        }

                                                        right={
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-Refreshments-Title') }</h4>
                                                                        
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-Refreshments') }</span>

                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-Signin-Title') }</h4>
                                                                        
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-Signin') }</span>

                                                                <GrayTile percentWidth={100}>
                                                                    <>
                                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                                        <h4 className="h4">{ t('PrepareTraining-Timeline-Days-Distraction-Title') }</h4>
                                                                        
                                                                    </>
                                                                </GrayTile>
                                                                <span className="parsed-text">{ tp('PrepareTraining-Timeline-Days-Distraction') }</span>
                                                            </>
                                                        }
                                                    />


                                                
                                                </>      
                                            </Tile>

                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default PreparingForTraining;
