import * as React from "react";

import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import PageMessages from "../Components/page-messages";
import { formatDate } from "../locale-utils";
import { AdminBaseRoutes } from "../Routing/routes";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Table from "../Components/table";
import useCurriculumApi from "../hooks/useCurriculumApi";

interface CurriculumCourse {
    courseId: string
    name: string
    courseLanguage: string
    viewPeriod: string
    modifyDate: string
}

interface CurriculumCourseAccesors {
    CourseId: string
    Name: string
    courseLanguage: string
    ViewPeriod: string
    ModifyDate: string
}

const ManageCoursesPage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [forceRefresh, setForceRefresh] = React.useState(false);

    const { fetchData, isLoading } = useCurriculumApi(
        "/Q/Curriculum/AllCourses",
        "POST"
    )
    
    const columnHelper = createColumnHelper<CurriculumCourseAccesors>()
    const columns = React.useMemo<ColumnDef<CurriculumCourse>[]>(
        () => [
            columnHelper.accessor('Name', {
                cell: info => nameColumn(info.row.original.name, info.row.original.courseId),
                header: t("Course"),
                enableSorting: true,
            }),
            columnHelper.accessor('CourseLanguage', {
                cell: info => info.row.original.courseLanguage,
                header: t("Language"),
                enableSorting: true,
                size: 10
            }),
            columnHelper.accessor('ViewPeriod', {
                cell: info => viewPeriodColumn(info.getValue(), info.row.original.viewPeriod),
                header: t("View Period"),
                enableSorting: false,
                meta: { bodyStyle: { whiteSpace: "nowrap" } },
                size: 15
            }),
            columnHelper.accessor('ModifyDate', {
                cell: info => formatDate(info.getValue() ?? info.row.original.modifyDate),
                header: t("Last Updated"),
                enableSorting: true,
                size: 15
            }),
        ],
        []
    );

    const nameColumn = (courseName: string, courseId: string) => {
        return (
            <u>
                <Link to={AdminBaseRoutes.ManageCoursesEdit.fullPath.replace(':courseId', courseId)} >
                    {courseName}
                </Link>
            </u>
        );
    }

    const viewPeriodColumn = (viewPeriod: number, defaultViewPeriod: number) => {
        return viewPeriod || (
            <small>
                <i>({t("default")}: {defaultViewPeriod})</i>
            </small>
        );
    }

    if (loading) {
        return <PageMessages loading={loading} />;
    }

    const handleCreateCourse = () => {
        navigate(AdminBaseRoutes.ManageCoursesCreate.fullPath);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="10">
                        <Breadcrumb>
                            <Breadcrumb.Item active>{t("Manage Courses")}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col sm="2">
                        <Button onClick={() => handleCreateCourse()}>+ {t("Create Course")}</Button>
                    </Col>
                </Row>
            </Container>
            <Table
                fetchFunction={fetchData}
                columns={columns}
                defaultSorted={{ dataField: "ModifyDate", order: "desc" }}
                isLoading={isLoading}
                forceRefresh={forceRefresh}
            />
        </>
    );
};

export default withAITracking(reactPlugin, ManageCoursesPage);
