import * as React from "react";

import { Container } from "react-bootstrap";

import HistoryCard from "./Components/history-card";
import { LearnerHistoryViewModel } from "../Interfaces";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import SyncMyCoursesComponent from "./Components/sync-my-courses-component";
import { fetchLearnerHistory } from "../ApiServices/Learn";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const MyLearningHistory = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [learnerCourseHistory, setLearnerCourseHistory] = React.useState<LearnerHistoryViewModel[]>([]);

    React.useEffect(() => {
        const getHistory = async () => {
            try {
                const history = await fetchLearnerHistory();
                setLearnerCourseHistory(history);
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
                setLoading(false);
            }
        };

        getHistory();
    }, []);

    const handleOnSynced = async () => {
        const history = await fetchLearnerHistory();
        setLearnerCourseHistory(history);
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h2 style={{ display: "inline-flex", marginBottom: 0 }}>{t("My Learning History")}</h2>
                <SyncMyCoursesComponent pageLoad={loading} onSynced={handleOnSynced} />
            </div>

            <PageMessages loading={loading} errored={errored} />
            <hr />
            <Container fluid>
                {learnerCourseHistory.map((learnerCourse: LearnerHistoryViewModel) => {
                    return (
                        <HistoryCard
                            key={learnerCourse.LearnerCourseId}
                            course={learnerCourse}
                        />
                    );
                })}
            </Container>
        </>
    );
};

export default withAITracking(reactPlugin, MyLearningHistory);
