import * as React from "react";
import CustomTable from "../../../Components/custom-table";
import EnrollParticipantList from "../Enroll-Participants-Modal/enroll-participant-list";
import EnrollParticipantListing from "./enroll-participant-listing";
import { IEnrollParticipant, EnrollParticipants } from "../../../Interfaces/Platform/Participants";
import { ConfigContext } from "../../../configuration-context";
import { fetchParticipantToEnroll } from "../../Utils/participants";
import { RbacContext } from "../../../../ClientApp/rbac-context";
import { fetchOrganizationsIds } from "../../Utils/participants";
import { fetchOrganizations } from "../../Utils/participants";
import { fetchClassSeats } from "../../Utils/class";
import { SelectedFiltersBasic } from "../../../Interfaces/Platform/GenericFilter";
import { Pagination } from "../../../Interfaces/Platform/CustomTable";
import { pageSizesEnroll } from "../DashboardPlatformUtils";
import { returnPageLevelAlerts } from "../../Utils/alerts";
import usePageLevelAlerts from "../../../hooks/usePageLevelAlerts";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import GenericFilterBasic from "../UI/generic-filter-basic";
import Button from "../../../Components/UI/button";
import EmptyContent from "../../../Components/UI/empty-content";
import CreateParticipant from "./create-participant";
import { IClassDetails } from "../../../Interfaces/Platform/Classes";
import ImportUsingFile from "../../../Components/UI/Content-Pages/import-using-file";
import ModalPopUp from "../../../Components/UI/modal-pop-up";
import useDebounce from "../../../hooks/use-debounce";
import Alert from "../../../Components/UI/alert";
import type { ShowAlert } from "../../../Interfaces/Platform/AlertProperties";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { useAppSelector } from "../../../hooks/useReduxDefaultHooks";

interface Column {
    id: string,
    enableSorting: boolean,
    accessorKey: string,
    header: () => JSX.Element
}

const EnrollParticipantsModal = (props: {
    class: IClassDetails,
    seatsRequired: boolean,
    canCreateParticipants: boolean,
    canEditParticipants: boolean,
    canEnrollParticipants: boolean,
    close: () => void,
    classOrganization: string,
    setParticipantsEnrolled: (participants: number) => void;
    displayBulkImportSuccess: any
}): JSX.Element => {
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const [singleAlert, setSingleAlert] = React.useState({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [seatsAlert, setSeatsAlert] = React.useState<ShowAlert>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const [columns, setColumns] = React.useState<Column[]>([]);
    const [showCreateParticipant, setShowCreateParticipant] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
    const [participantData, setParticipantData] = React.useState<IEnrollParticipant[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [organizationIds, setOrganizationIds] = React.useState<string[]>([]);
    const [organizations, setOrganizations] = React.useState([]);
    const [classSeats, setClassSeats] = React.useState(null);
    const [isLoadingSeats, setIsLoadingSeats] = React.useState(false);
    const [organizationOptions, setOrganizationOptions] = React.useState([]);
    const { isMobile, isLaptop } = useDetectWindowSize();
    const [pagination, setPagination] = React.useState<Pagination>({
        current: 1,
        total: 0,
        size: 10,
    });
    const [filters, setFilters] = React.useState<SelectedFiltersBasic>({
        searchTerm: '',
        sortBy: 'date',
    });

    const [showImportFromFile, setShowImportFromFile] = React.useState<boolean>(false);

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);

    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const classDetails = useAppSelector((state) => state.classDetails);

    const handleEditParticipant = (id: string) => {
        setParticipantData((prevState) => {
            const newState = [...prevState];
            const selected = newState.findIndex((participant) => participant.participantId === id);
            newState[selected].isEdit = !newState[selected].isEdit;
            return newState;
        });
    };

    const getSeats = async () => {
        setIsLoadingSeats(true);
        try {
            const data = await fetchClassSeats(apimBaseUrl, configContext, { organizationId: props.class.seatOrganizationId, courseId: props.class.courseId });
            setClassSeats(data);
            return data;
        } catch (e) {

        } finally {
            setIsLoadingSeats(false);
        }
    };

    React.useEffect(() => {
        if (props.class && props.class.trainingFormat !== "classroom" && props.seatsRequired) {
            getSeats();
        }
    }, [props.class]);

    const returnParticipants = () => participantData && participantData.map((participant) => {
        return <EnrollParticipantListing
            class={props.class}
            seats={classSeats}
            seatsRequired={props.seatsRequired}
            setParticipantsEnrolled={props.setParticipantsEnrolled}
            organizations={organizations}
            addPageLevelAlert={addPageLevelAlert}
            showSeatsAlert={showSeatsAlert}
            getParticipants={() => getPaginatedData(pagination.current)}
            onEditParticipant={handleEditParticipant}
            canEditParticipants={props.canEditParticipants}
            canEnrollParticipants={props.canEnrollParticipants}
            key={participant.participantId}
            {...participant}
        />
    });
    const getParticipants = async (page: number, size: number) => {
        if (organizationIds.length === 0) return;
        setIsLoading(true);
        const data: EnrollParticipants = await fetchParticipantToEnroll(apimBaseUrl ?? "", configContext, {
            sortBy: filters.sortBy,
            organizationIds: organizationIds,
            classId: props.class.classId,
            specialty: props.class.specialty,
            pageNumber: page,
            resultsPerPage: size,
            search: filters.searchTerm
        });
        return data;
    };

    const updatePageSize = (size: number) => setPagination({ ...pagination, current: 1, size });
    const updatePage = (page: number) => setPagination({ ...pagination, current: page });
    const updateFilter = (filter: string, value: string) => setFilters({ ...filters, [filter]: value });
    const clearFilters = () => {
        setFilters({
            searchTerm: '',
            sortBy: 'date'
        });
    };

    const debouncedSortBy = useDebounce(filters.sortBy, 500);

    const getPaginatedData = async (page: number) => {
        const data = await getParticipants(page, pagination.size);
        
        setPagination({ ...pagination, total: data?.totalResults ?? 1 });
        setParticipantData(data.participants.map((i) => ({ ...i, isEdit: false })));  
        setIsLoading(false);              
    };

    const getOrgs = async () => {
        const data = await fetchOrganizations(apimBaseUrl, configContext, userContext.SelectedOrganization.toString());
        setOrganizations(data);  
    };

    React.useEffect(() => {
        async function getOrganizations() {
            const guids = await fetchOrganizationsIds(apimBaseUrl ?? "", configContext, userContext.SelectedOrganization.toString());
            setOrganizationIds(guids);
        }

        if (configContext?.SystemConfiguration?.ApimKey) {
            getOrganizations();
            getOrgs();
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        if (props.seatsRequired && classSeats !== null) {
            if (classSeats > 5) {
                showSeatsAlert({
                    alertLevel: { alertLevel: "success" },                    
                    description: tp("Seats-Remaining-Success", { numberOfSeats: classSeats, specialty: t(classDetails.specialty) }),
                    canDismiss: true
                });
            }

            if (classSeats === 0) {
                showSeatsAlert({
                    alertLevel: { alertLevel: "error" },
                    description: tp("Seats-Remaining-Error", { numberOfSeats: '0', specialty: t(classDetails.specialty) }),
                    canDismiss: true,
                    arrowLink: { label: "Buy Seats", href: `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning` }
                });
            }

            if (classSeats < 6 && classSeats > 0) {
                showSeatsAlert({
                    alertLevel: { alertLevel: "warning" },                    
                    description: tp("Seats-Remaining-Warning", { numberOfSeats: classSeats, specialty: t(classDetails.specialty) }),
                    canDismiss: true,
                    arrowLink: {
                        label: "Buy Seats", href: `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning` }
                });
            }
        }
    }, [classSeats]);

    React.useEffect(() => {
        // First data load
        if (!hasLoaded && organizationIds?.length !== 0 && configContext?.SystemConfiguration?.ApimKey) {
            getPaginatedData(pagination.current);
            setHasLoaded(true);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, organizationIds, pagination.current, pagination.size]);

    React.useEffect(() => {
        // On pagination change data load
        if (hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            getPaginatedData(pagination.current);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, pagination.current, pagination.size]);

    React.useEffect(() => {
        // If data has already been loaded and value on search is cleared
        if (hasLoaded && filters.searchTerm === '') {
            const handler = setTimeout(() => {
                getPaginatedData(pagination.current);
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [filters.searchTerm]);

    React.useEffect(() => {
        // On filters change data load
        if (hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            setIsLoading(true);
            const handler = setTimeout(() => {
                getPaginatedData(pagination.current);
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [
        configContext?.SystemConfiguration?.ApimKey,
        filters.sortBy,
    ]);

    React.useEffect(() => {
        const filteredColumns: Column[] = [{
            id: "actions",
            enableSorting: false,
            accessorKey: "",
            header: () => (<></>)
        }, {
            id: "name",
            enableSorting: false,
            accessorKey: "Name",
            header: () => (<div>{t("Name")}</div>)
        }, {
            id: "email",
            enableSorting: false,
            accessorKey: "Email",
            header: () => (<div>{t("Email")}</div>)
        }, {
            id: "organization",
            enableSorting: false,
            accessorKey: "Organization",
            header: () => (<div>{t("Organization")}</div>)
        }, {
            id: "externalId",
            enableSorting: false,
            accessorKey: "External ID",
            header: () => (<div>{t("External ID")}</div>)
        }, {
            id: "status",
            enableSorting: false,
            accessorKey: "Status",
            header: () => (<div>{t("Status")}</div>)
        }, {
            id: "edit",
            enableSorting: false,
            accessorKey: "",
            header: () => (<div></div>)
        }];

        setColumns(filteredColumns);
    }, []);

    const handleEmailExisting = (search: string) => {
        setFilters({
            ...filters,
            searchTerm: search
        });
    }

    const getFiltersTemplate = () => (
        <>
            <GenericFilterBasic
                searchLabel={isMobile ? t("SEARCH-NAME-EMAIL") : t("SEARCH-NAME-EMAIL-EXTERNALID")}
                filters={filters}
                sortByOptions={[
                    { label: t('Date Added'), value: 'date' },
                    { label: t('Email'), value: 'email' },
                    { label: t('First Name'), value: 'firstname' },
                    { label: t('Last Name'), value: 'lastname' },
                    { label: t('Organization'), value: 'organization' }
                ]}
                canCreateParticipants={props.canCreateParticipants}
                onCreate={() => setShowCreateParticipant(true)}
                onSearch={() => getPaginatedData(pagination.current)}
                // clearFilters={clearFilters}
                updateFilter={updateFilter}
            />
            {showCreateParticipant && (
                <CreateParticipant
                    class={props.class}
                    seats={classSeats}
                    organizations={organizations}
                    canEnrollParticipants={props.canEnrollParticipants}
                    getParticipants={getPaginatedData}
                    addPageLevelAlert={addPageLevelAlert}
                    handleEmailExisting={handleEmailExisting}
                    setShow={setShowCreateParticipant}
                    alertMessageHandler={showAlertMessage}
                    showAlertMessage={singleAlert.show}
                />
            )}
        </>
    );

    const returnImportFromFileComponent = (): JSX.Element => (
        <ImportUsingFile
            onSuccess={() => props.displayBulkImportSuccess}
            onCancel={() => setShowImportFromFile(false)}
            source={props.class.trainingFormat}
            isOnlineOnly={(props.class.trainingFormat == "virtual") && !props.class.classroomFormat}
            blueCardRequired={props.class.blueCardRequired}
            classId={props.class.classId}
            desiredLms={props.class.desiredLms}
            courseId={props.class.courseId}
            specialty={props.class.specialty}
            seatOrganizationId={props.class.seatOrganizationId}
        />
    );

    const removeAlertMessage = () => {
        setSingleAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });
    };

    const removeSeatsAlert = () => {
        setSeatsAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });
    };

    const showAlertMessage = (alert:any) => {
        setSingleAlert(alert);
    };

    const showSeatsAlert = (alert: ShowAlert) => {
        setSeatsAlert({ ...alert, show: true });
    };

    return (
        <>
            <div className="alert-wrapper mt-4">{returnPageLevelAlerts(pageLevelAlerts)}</div>
            <div className="alert-wrapper mt-4">
                {singleAlert?.show && (
                    <Alert
                        removeAlertParentHandler={removeAlertMessage} 
                        state={singleAlert.alertLevel}
                        arrowLink={singleAlert.arrowLink}
                        description={singleAlert.description}
                        canDismiss={singleAlert.canDismiss}
                    />
                )}
            </div>
            {props.seatsRequired && (
                <div className="alert-wrapper mt-4">
                    {seatsAlert?.show && (
                        <Alert
                            removeAlertParentHandler={removeSeatsAlert} 
                            state={seatsAlert.alertLevel}
                            arrowLink={seatsAlert.arrowLink}
                            description={seatsAlert.description}
                            canDismiss={seatsAlert.canDismiss}
                        />
                    )}
                </div>
            )}
            <ModalPopUp
                title={t("ClassParticipants.ImportUsingAFile")}
                size="md"
                content={returnImportFromFileComponent()}
                show={showImportFromFile}
                hide={() => setShowImportFromFile(false)}
            />
            <CustomTable
                enableFilter={false}
                enablePaging
                enableSearch={false}
                enableHeader={isLaptop}
                columns={columns}
                empty={!!!participantData?.length}
                scrollable
                loading={isLoading}
                emptyComponent={
                    <div className="p-5">
                        <EmptyContent
                            button={
                                <div className="d-flex">
                                    <Button additionalStyleClasses="mr-3" clickAction={() => setShowCreateParticipant(true)} isSolid label={t("Create Participant")} />
                                    <Button isSolid={false} label={t("ClassParticipants.ImportUsingAFile")} clickAction={() => setShowImportFromFile(true)} />
                                </div>
                            }
                            title={t("No-Participants-Yet")}
                            subtitle={t("Create-Some-Participants")}
                        />
                    </div>
                }
                pageSizes={pageSizesEnroll}
                pageSize={pagination.size}
                handlePageSize={updatePageSize}
                handlePage={updatePage}
                currentPage={pagination.current}
                total={pagination.total}
                filtersTemplate={getFiltersTemplate()}
                paginationAction={<Button isSolid label={t("Done")} clickAction={props.close} />}
            >
                <EnrollParticipantList
                    participants={participantData}
                    listItems={returnParticipants() || []}
                />
            </CustomTable>
        </>
    );
}

export default EnrollParticipantsModal;
