import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

const CustomizationNewTermsTransitions = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
       <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={true}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1 title-margin">{ t('NewTerms-Title') }</h1>
                                <Tile title={ t('NewTerms-Customization-Header') } hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <TwoColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <span className="parsed-text">{ tp('NewTerms-Customization') }</span>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <span>{ tp('NewTerms-Transitions') }</span>
                                                    <GrayTile percentWidth={80}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <p>{ t('NewTerms-ReachingBack-Note') }</p>
                                                        </>
                                                    </GrayTile>
                                                    <p className="mt-3">{ t('NewTerms-Foreshadow-Intro') }</p>
                                                    <GrayTile percentWidth={80}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <p>{ t('NewTerms-Foreshadow-Note') }</p>
                                                        </>
                                                    </GrayTile>
                                                    <p className="mt-3">{ t('NewTerms-Example-Close') }</p>
                                                </>
                                            }
                                        />
                                    </>      
                                </Tile>

                            </>
                        }
                    />
                </Container>
            </div>
       </>
    );
};

export default CustomizationNewTermsTransitions;
