import * as React from "react";

const LoadingContent = (props: {
    title?: string,
    subtitle?: string,
    button?: React.ReactElement,
    icon?: React.ReactElement,
}): JSX.Element => {

    return (
        <div className="loading-content-container">
            {props.icon}
            <div className="d-flex flex-column align-items-center">
                <p className="fw400 loading-content-title">{props.title}</p>
            </div>
        </div>
    );
};

export default LoadingContent;
