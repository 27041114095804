import * as React from "react";

interface ConditionalWrapperProps {
    condition: boolean;
    wrapper: (children: React.ReactElement) => JSX.Element;
    children: React.ReactElement;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) => {
    return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
