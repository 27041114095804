import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useSkills from "../../../hooks/useSkills";

import CustomTable from "../../../Components/custom-table";
import ClassParticipantList from "../Participants/class-participant-list";
import ClassParticipantListing from "../Participants/class-participant-listing";
import Button from "../../../Components/UI/button";
import ModalPopUp from "../../../Components/UI/modal-pop-up";
import EmptyContent from "../../../Components/UI/empty-content";
import { fetchClassParticipants, fetchClassParticipant, submitParticipants, submitAllParticipants, exportClassParticipants } from "../../Utils/class";

import { ConfigContext } from "../../../configuration-context";
import { RbacContext } from "../../../rbac-context";

import type { IClassParticipant, IParticipant } from "../../../Interfaces/Platform/Participants";
import type AlertProperties from "../../../Interfaces/Platform/AlertProperties";
import { pageSizesClassParticipants } from "../DashboardPlatformUtils";
import type { IClassDetails } from "../../../Interfaces/Platform/Classes";
import type { Pagination } from "../../../Interfaces/Platform/CustomTable";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import EnrollParticipantsModal from "../Enroll-Participants-Modal/enroll-participant";
import ImportUsingFile from "../../../Components/UI/Content-Pages/import-using-file";
import ViewParticipantDetails from "../../../Components/UI/view-participant-detail";
import { saveAs } from "file-saver";
import {
    submitParticipantToClass,
    submitAllParticipantsToClass,
    removeParticipantFromClass,
    loadEnrolledParticipants
} from "../../../stores/classDetailsSlice";
import {
    removeClassParticipant,
    handleCheckParticipant,
    handleEdit,
    handleEditData,
    handleUpdateBlueCardId,
    handleUpdateParticipantsSubmitted,
    handleUncheckParticipants,
    handleCheckHeader,
    updatePagination,
} from "../../../stores/classParticipantsSlice";
import { ClassSkillModel, ClassSkillModelMapToClassSkillSubmittedList, ClassSkillSubmitted } from "../../../Interfaces/ClassSkillModel";

import { Column } from "../../../Interfaces/Platform/CustomTable";
import { fetchSkills } from "../../Utils/classCreate";
import { useAppSelector } from "../../../hooks/useReduxDefaultHooks";
import useFeatureFlags from "../../../hooks/useFeatureFlags";

interface Props {
    classDetails: IClassDetails,
    isLoading: boolean,
    canEditClassParticipants: boolean,
    canCreateParticipants: boolean,
    canEditParticipants: boolean,
    canSendEnrollmentEmail: boolean,
    canTransferParticipants: boolean,
    canEnrollParticipants: boolean,
    canViewCertificates: boolean,
    addPageLevelAlert: any,
    participantsToSubmit: any,
    showAlertSubmitError: boolean,
    showAlertSubmitSuccess: boolean,
    setSubmittedErrors: any,
    alertSubmitError: (alert: { show: boolean } & AlertProperties) => void,
    alertSubmitSuccess: (alert: { show: boolean } & AlertProperties) => void,
    showParticipantsSubmitted: (numberOfSubmitted: number, total: number) => void,
    setParticipantsToSubmit: React.Dispatch<React.SetStateAction<string[] | 'all'>>,
    setParticipantsEnrolled: (participants: number) => void,
    setParticipantsInClass: React.Dispatch<React.SetStateAction<number>>,
    setShowAlerts?: React.Dispatch<React.SetStateAction<boolean>>,
    setParticipantsSubmittedInClass?: React.Dispatch<React.SetStateAction<number>>,
    setClassWebId?: React.Dispatch<React.SetStateAction<string>>,
    setBulkResult: any,
    getClassParticipants: any,
    canBulkUploadClassParticipants: boolean
}
const ClassParticipants = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { prepareSkillsDataForSubmission } = useSkills();
    
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const isMigrated = props.classDetails.imported === true ? true : false;

    const { isMobile } = useDetectWindowSize();
    //const [isLoading, setIsLoading] = React.useState(true);
    const participants = useAppSelector((state) => state.classParticipants.participants);
    const pagination = useAppSelector((state) => state.classParticipants.pagination);
    const isEmpty = useAppSelector((state) => state.classParticipants.isEmpty);
    const participantsSubmitted = useAppSelector((state) => state.classDetails.participantsSubmitted);
    const classParticipantsRef = React.useRef<any>();
    const [submitRecordsText, setSubmitRecordsText] = React.useState<string>("");
    const [headerCheck, setHeaderCheck] = React.useState<boolean>(false);
    //const [isEmpty, setIsEmpty] = React.useState(false);
    const [columns, setColumns] = React.useState<Column[]>([]);
    const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
    const [participantsNeedBlueCard, setParticipantsNeedBluecard] = React.useState<string[]>([]);
    const [duplicatedBlueCardIds, setDuplicatedBlueCardIds] = React.useState<string[]>([]);
    const [showAddParticipant, setShowAddParticipant] = React.useState(false);
    //const [participants, setParticipants] = React.useState<IClassParticipant[]>([]);
    const [participantsBlueCardDuplicated, setParticipantsBlueCardDuplicated] = React.useState([]);
    const [participantsAlreadySubmitted, setParticipantsAlreadySubmitted] = React.useState<{ [page: number]: number }>(null);
    //const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const [selectedParticipantsIds, setSelectedParticipantsIds] = React.useState<string[]>([]);
    const [participantsToSubmit, setParticipantsToSubmit] = React.useState<string[]>(null);
    const [showParticipantDetails, setShowParticipantDetails] = React.useState<boolean>(false);
    const [viewEditParticipant, setViewEditParticipant] = React.useState<IParticipant>(null);
    const [skills, setSkills] = React.useState<ClassSkillModel>();

    const [showImportFromFile, setShowImportFromFile] = React.useState<boolean>(false);

    const isBlueCardRequired = props.classDetails?.blueCardRequired;

    // compare with class details
    const getSkills = async () => {
        const payload: any = {
            specialty: props.classDetails?.specialty,
            edition: props.classDetails?.specialtyEdition,
            additionalContent: (props.classDetails?.qualification === 'none' || !props.classDetails?.qualification) ? null : props.classDetails?.qualification,
        };

        // retrieve class skills from class service
        const data = await fetchSkills(apimBaseUrl ?? "", configContext, payload);
        // create array of skills to be submitted with participants
        const skills = ClassSkillModelMapToClassSkillSubmittedList(data);
        // update skillName attribute with translation
        const translatedSkills = prepareSkillsDataForSubmission(skills);
        return translatedSkills;
    };

    const updatePageSize = (size: number) => dispatch(updatePagination({...pagination, current: 1, size }));
    const updatePage = (page: number) => dispatch(updatePagination({ ...pagination, current: page }));

    React.useEffect(() => {
        const blueCardIds = participants.map((i) => i.blueCardId);
        setDuplicatedBlueCardIds([]);
        const duplicates = blueCardIds.some((i, idx) => {
            if (blueCardIds.indexOf(i) !== idx) {
                setDuplicatedBlueCardIds((prevState) => [...new Set([...prevState, i])]);
            }
            return blueCardIds.indexOf(i) !== idx;
        });
    }, [participants]);

    const allParticipantsSubmitted = props.classDetails.totalParticipants === props.classDetails.participantsSubmitted;
    const classIsExpired = new Date(props.classDetails?.expirationDate) < new Date();
    const endDateObject = new Date(props.classDetails?.endDate);
    const startDateObject = new Date(props.classDetails?.startDate);
    const classIsEnded = endDateObject.setHours(0,0,0,0) <= new Date().setHours(0,0,0,0);
    const classHasStarted = new Date().setHours(0,0,0,0) >= startDateObject.setHours(0,0,0,0);
    const endDate = t('dynamicDate', { val: endDateObject, formatParams: { val: { month: 'long', day: 'numeric', year: 'numeric' } } });
    const startDate = t('dynamicDate', { val: startDateObject, formatParams: { val: { month: 'long', day: 'numeric', year: 'numeric' } } });

    /*const getPaginatedData = async (page: number) => {
        const { totalResults, classes } = await fetchClasses(page, pagination.size);
        setPagination({ ...pagination, total: totalResults });
        setClasses(classes);    
    };*/

    const handleCheck = (checked: boolean, id: string) => {
        dispatch(handleCheckParticipant({ checked, id }))
        if (checked) {
            setSelectedParticipantsIds((prevState) => [...prevState, id]);
        } else {
            setSelectedParticipantsIds((prevState) => prevState.filter((selected) => selected !== id));
            setParticipantsNeedBluecard((prevState) => prevState.filter((i) => i !== id));
        }
    };

    React.useEffect(() => {
        if (selectedParticipantsIds.length > 0) {
            if (headerCheck) {
                setParticipantsToSubmit([]);
                setSubmitRecordsText(t("Submit All Records"));
            } else {
                setParticipantsToSubmit(selectedParticipantsIds);
                setSubmitRecordsText(`Submit ${selectedParticipantsIds.length} Record${selectedParticipantsIds.length > 1 ? "s" : ""}`);
            }
        } else {
            setHeaderCheck(false);
            setParticipantsToSubmit([]);
            setSubmitRecordsText(t("Submit All Records"));
        }
    }, [selectedParticipantsIds, participants]);

    const handleEditParticipant = (id: string) => dispatch(handleEdit(id));

    const handleHeaderCheck = (isHeaderCheck: boolean): void => {
        setHeaderCheck(isHeaderCheck);
        const newParticipants = [...participants].map((participant) => ({...participant, isChecked: !participant.ceSubmitted && isHeaderCheck}))
        dispatch(handleCheckHeader(newParticipants));
        
        const selected = [...newParticipants];
        const selectedIds = selected.filter((i) => i.isChecked).map((participant) => participant.classParticipantId);
        if (isHeaderCheck) {
            setSelectedParticipantsIds([...new Set([...selectedIds, ...selectedParticipantsIds])]);
        } else {
            setSelectedParticipantsIds([
                ...new Set(selectedParticipantsIds.filter(
                    (selected) => !participants.map((participant) => participant.classParticipantId)
                    .find((classParticipantId) => selected === classParticipantId)
                ))
            ]);
        }
    };

    React.useEffect(() => {
        if (props.classDetails !== null) {
            let filteredColumns:Column[] = [
                { id: "checkbox", enableSorting: false, accessorKey: "Checkbox", header: () => (
                    <div className="d-flex">
                        <input
                            onChange={(e) => handleHeaderCheck(e.target.checked)}
                            className="form-checkbox participant-listing-checkbox"
                            id="all-check"
                            checked={headerCheck}
                            name=""
                            type="checkbox"
                        />
                    </div>
                )},
                { id: "name", enableSorting: false, accessorKey: "Name", header: () => (
                    <div className={`${(!allParticipantsSubmitted && props.classDetails?.trainingFormat !== "virtual") ? "" : "ml-3"} ${!classIsEnded || allParticipantsSubmitted ? 'ml-3': 'ml-0'}`}>{t("Name")}</div>
                )}
            ];
            if (props.classDetails?.trainingFormat === "virtual") {
                if (!props.classDetails?.imported) {
                    filteredColumns = [
                        ...filteredColumns,
                        { id: "onlineCourse", enableSorting: false, accessorKey: "Online Course", header: () => <div>{t("Online Course")}</div> },
                    ];
                }
                filteredColumns = [
                    ...filteredColumns,
                    { id: "actions", enableSorting: false, accessorKey: "Actions", header: () => <></> }
                ];
                filteredColumns = filteredColumns.filter((column) => column.id !== "checkbox");
            }
            if (props.classDetails?.trainingFormat === "classroom") {
                filteredColumns = [
                    ...filteredColumns,
                    { id: "blueCardId", sizePx: 220, enableSorting: false, accessorKey: "Blue Card #", header: () => <div>{props.classDetails?.specialty === "DCC" ? t("Credential Number") : t("Blue-Card-Number")}</div> },
                    { id: "status", sizePx: 160, enableSorting: false, accessorKey: "Status", header: () => <div>{t("Status")}</div> },
                    { id: "type", enableSorting: false, accessorKey: "Type", header: () => <div>{t("Type")}</div> },
                    { id: "actions", enableSorting: false, accessorKey: "Actions", header: () => <></> }
                ];
            }
            if (props.classDetails?.trainingFormat === "blended") {
                if (!props.classDetails?.imported) {
                    filteredColumns = [
                        ...filteredColumns,
                        { id: "onlineCourse", enableSorting: false, accessorKey: "Online Course", header: () => <div>{t("Online Course")}</div> },
                    ];
                }
                filteredColumns = [
                    ...filteredColumns,
                    { id: "blueCardId", sizePx: 220, enableSorting: false, accessorKey: "Blue Card #", header: () => <div>{props.classDetails?.specialty === "DCC" ? t("Credential Number") : t("Blue-Card-Number")}</div> },
                    { id: "status", sizePx: 160, enableSorting: false, accessorKey: "Status", header: () => <div>{t("Status")}</div> },
                    { id: "type", enableSorting: false, accessorKey: "Type", header: () => <div>{t("Type")}</div> },
                    { id: "actions", enableSorting: false, accessorKey: "Actions", header: () => <></> }
                ];

                if (isMigrated){
                    filteredColumns = filteredColumns.filter((column) => column.id !== "onlineCourse");
                }
            }
            if (props.classDetails?.trainingType !== "initial-refresher") {
                filteredColumns = filteredColumns.filter((column) => column.id !== "type");
            }
            if (props.classDetails?.trainingFormat === "classroom" || (props.classDetails?.trainingFormat === "blended" && props.classDetails?.desiredLms === "external")) {
                filteredColumns = filteredColumns.filter((column) => column.id !== "onlineCourse");
            }
            if (!props.classDetails?.blueCardRequired) {
                filteredColumns = filteredColumns.filter((column) => column.id !== "blueCardId");
            }
            if (allParticipantsSubmitted || !classIsEnded) {
                filteredColumns = filteredColumns.filter((i) => i.id !== "checkbox");
            }
            setColumns(filteredColumns);
        }
    }, [props.classDetails, headerCheck, participants]);

    /*React.useEffect(() => {
        setAllParticipantsSubmitted(allParticipantsSubmitted);
    }, [participants]);*/

    const onEditData = (classParticipantId: string, data: string, value: string) => dispatch(handleEditData({ classParticipantId, data, value }));

    const handleCloseAddParticipants = () => setShowAddParticipant(false);

    const handleSubmitAll = async () => {
        setIsSubmitLoading(true);
        try {
            const classSkillsSubmitted = await getSkills();
            const response = await submitAllParticipants(apimBaseUrl, configContext, { classId: props.classDetails?.classId, skills: classSkillsSubmitted });
            const responseJson = await response.json();
            if (response.status === 202) {
                updateParticipantsSubmitted(participants.map((i) => i.classParticipantId));
                props.setClassWebId(responseJson.webId);
                setSelectedParticipantsIds([]);
                setIsSubmitLoading(false);
                // Add total number of submitted
                dispatch(submitAllParticipantsToClass());
                props.setShowAlerts(false);
                props.alertSubmitSuccess({
                    alertLevel: { alertLevel: "success" },
                    arrowLink: null,                    
                    description: t("ClassParticipants.Total.Records.Submit.All", { paginationTotal: responseJson.totalSubmitted, participantVerb: responseJson.totalParticipants > 1 ? 's have' :' has' }),
                    canDismiss: true,
                    show: true
                });
            } else if (response.status === 409) {
                setIsSubmitLoading(false);
                if (!props.showAlertSubmitError) {
                    props.alertSubmitError({
                        alertLevel: { alertLevel: "error" },
                        arrowLink: null,
                        description: t("Submit-Participant-Error"),
                        canDismiss: true,
                        show: true
                    });
                }
                setParticipantsNeedBluecard(responseJson);
            } else {
                setIsSubmitLoading(false);
                if (!props.showAlertSubmitError) {
                    props.alertSubmitError({
                        alertLevel: { alertLevel: "error" },
                        arrowLink: null,
                        description: t("Submit-Participant-Error"),
                        canDismiss: true,
                        show: true
                    });
                }
            }
        } catch (e) {
            setIsSubmitLoading(false);
            if (!props.showAlertSubmitError) {
                props.alertSubmitError({
                    alertLevel: { alertLevel: "error" },
                    arrowLink: null,
                    description: e.message,
                    canDismiss: true,
                    show: true
                });
            }
        }
    };

    const updateBlueCardId = (classParticipantId: string, blueCardId: string) => dispatch(handleUpdateBlueCardId({classParticipantId, blueCardId }));

    const updateParticipantsSubmitted = (classParticipantIds: string[]) => dispatch(handleUpdateParticipantsSubmitted(classParticipantIds));

    React.useEffect(() => {
        if (selectedParticipantsIds.length === 0) {
            dispatch(handleUncheckParticipants());
        }
    }, [selectedParticipantsIds]);

    const handleSubmit = async () => {
        const participantsSubmit = participants.filter((i) => participantsToSubmit.includes(i.classParticipantId));
        const classParticipantIdErrors: string[] = [];

        for (let index = 0; index < participantsSubmit.length; index++) {
            const participant = participantsSubmit[index];

            const hasError = (participant.blueCardId
                                && participant.blueCardId.length > 0
                                && (participant.blueCardId.length < 6 || participant.blueCardId.length > 12 || !participant.blueCardIsValid))
                            || (!participant.blueCardId && participant.status === "pass");

            if (isBlueCardRequired && hasError) {
                classParticipantIdErrors.push(participant.classParticipantId);
            }
        }

        if (classParticipantIdErrors.length > 0) {
            setParticipantsNeedBluecard(classParticipantIdErrors);

            if (!props.showAlertSubmitError) {
                props.alertSubmitError({
                    alertLevel: { alertLevel: "error" },
                    arrowLink: null,
                    description: t("Submit-Participant-Error"),
                    canDismiss: true,
                    show: true
                });
            }
        } else {
            setParticipantsNeedBluecard([]);
            setIsSubmitLoading(true);
            try {
                const classSkillsSubmitted =await getSkills();
                const response = await submitParticipants(apimBaseUrl, configContext, { classId: props.classDetails?.classId, classParticipantIds: participantsToSubmit, skills: classSkillsSubmitted });
                const responseJson = await response.json();
                if (response.status === 202) {
                    setParticipantsAlreadySubmitted({ ...participantsAlreadySubmitted, [pagination.current]: participants.filter((i) => i.ceSubmitted ).length + participantsToSubmit.length });                    
                    updateParticipantsSubmitted(participantsToSubmit);
                    setSelectedParticipantsIds([]);
                    setIsSubmitLoading(false);
                    dispatch(submitParticipantToClass(responseJson.totalSubmitted));
                    setTimeout(() => {
                        props.setShowAlerts(false);
                        props.alertSubmitSuccess({
                            alertLevel: { alertLevel: "success" },
                            arrowLink: null,                          
                            description: t("ClassParticipants.Total.Records.Submit", { totalParticipantsToSubmit: responseJson.totalSubmitted, paginationTotal: responseJson.totalParticipants, participantVerb: participantsSubmitted > 1 ? 's have' :' has' }),
                            canDismiss: true,
                            show: true
                        });
                    }, 0);
                } else if (response.status === 409) {
                    if (!props.showAlertSubmitError) {
                        props.alertSubmitError({
                            alertLevel: { alertLevel: "error" },
                            arrowLink: null,
                            description: t("Submit-Participant-Error"),
                            canDismiss: true,
                            show: true
                        });
                    }
                    setParticipantsNeedBluecard(responseJson);
                } else {
                    setIsSubmitLoading(false);
                    if (!props.showAlertSubmitError) {
                        props.alertSubmitError({
                            alertLevel: { alertLevel: "error" },
                            arrowLink: null,
                            description: t("Submit-Participant-Error"),
                            canDismiss: true,
                            show: true
                        });
                    }
                }
            } catch (e) {
                setIsSubmitLoading(false);
                if (!props.showAlertSubmitError) {
                    props.alertSubmitError({
                        alertLevel: { alertLevel: "error" },
                        arrowLink: null,
                        description: e.message,
                        canDismiss: true,
                        show: true
                    });
                }
            }
        }
    };

    const handleSubmitRecord = async (classParticipantId: string) => {
        const participant = participants.find((i) => i.classParticipantId === classParticipantId);

        if (!participant) return;

        const hasError = (participant.blueCardId
                            && participant.blueCardId.length > 0)
                            && (participant.blueCardId.length < 6 || participant.blueCardId.length > 12 || !participant.blueCardIsValid)
                        || (!participant.blueCardId && participant.status === "pass");

        if (isBlueCardRequired && hasError) {
            setParticipantsNeedBluecard([classParticipantId]);
            if (!props.showAlertSubmitError) {
                props.alertSubmitError({
                    alertLevel: { alertLevel: "error" },
                    arrowLink: null,
                    description: "There was a problem submitting a participant record.",
                    canDismiss: true,
                    show: true
                });
            }
        } else {
            setParticipantsNeedBluecard([]);
            setIsSubmitLoading(true);
            try {
                const classSkillsSubmitted = await getSkills();
                const response = await submitParticipants(apimBaseUrl, configContext, { classId: props.classDetails?.classId, classParticipantIds: [classParticipantId], skills:classSkillsSubmitted });
                const responseJson = await response.json();
                if (response.status === 202) {
                    setParticipantsAlreadySubmitted({ ...participantsAlreadySubmitted, [pagination.current]: participants.filter((i) => i.ceSubmitted ).length + 1 });                    
                    updateParticipantsSubmitted([classParticipantId]);
                    setSelectedParticipantsIds([]);
                    props.setClassWebId(responseJson.webId);
                    dispatch(submitParticipantToClass(responseJson.totalSubmitted));
                    setIsSubmitLoading(false);
                    setTimeout(() => {
                        props.setShowAlerts(false);
                        props.alertSubmitSuccess({
                            alertLevel: { alertLevel: "success" },
                            arrowLink: null,
                            description: `Congratulations! ${responseJson.totalSubmitted}/${responseJson.totalParticipants} participant${responseJson.totalSubmitted > 1 ? 's have' :' has'} been recorded.`,
                            canDismiss: true,
                            show: true
                        });
                    }, 0);
                } else if (response.status === 409) {
                    if (!props.showAlertSubmitError) {
                        props.alertSubmitError({
                            alertLevel: { alertLevel: "error" },
                            arrowLink: null,
                            description: "There was a problem submitting a participant record.",
                            canDismiss: true,
                            show: true
                        });
                    }
                    setParticipantsNeedBluecard(responseJson);
                } else {
                    setIsSubmitLoading(false);
                    if (!props.showAlertSubmitError) {
                        props.alertSubmitError({
                            alertLevel: { alertLevel: "error" },
                            arrowLink: null,
                            description: "There was a problem submitting a participant record.",
                            canDismiss: true,
                            show: true
                        });
                    }
                }
            } catch (e) {
                setIsSubmitLoading(false);
                if (!props.showAlertSubmitError) {
                    props.alertSubmitError({
                        alertLevel: { alertLevel: "error" },
                        arrowLink: null,
                        description: e.message,
                        canDismiss: true,
                        show: true
                    });
                }
            }
        }
    };

    const seatsRequired = props.classDetails?.seatsRequired;
    const handleRemoveParticipant = async (funcProps: any) => {
        dispatch(removeClassParticipant(funcProps.participantId));
        dispatch(removeParticipantFromClass());

        props.setShowAlerts(false);

        props.alertSubmitSuccess({
            alertLevel: { alertLevel: "success" },
            arrowLink: null,
            description: t("Participant Removed"),
            canDismiss: true,
            show: true
        });
    }

    const handleResendEnrollmentEmail = (isError: boolean) => {
        props.addPageLevelAlert({
            alertLevel: { alertLevel: isError ? "error" : "success" },
            arrowLink: null,
            description: isError ? t("Class.ResendEmailError") : t("Class.ResendEmailSuccess"),
            canDismiss: true,
            show: true
        });
    };

    const displayBulkImportSuccess = (data: any) => {
        props.setBulkResult({
            isError: data.rowsError.length > 0,            
            message: t("Bulk-Import-Class-Participant-Errors", {
                totalProcessed: data.totalProcessed,
                totalCreated: data.totalCreated,
                totalUpdated: data.totalUpdated,
                totalEnrolled: data.totalEnrolled
            }),
            statusCode: 200,
            response: data.rowsError
        });

        setShowImportFromFile(false);
        setTimeout(() => props.getClassParticipants(props.classDetails.classId, true), 2000);
    };

    const handleViewEditParticipant = async (participantId: string) => {
        try {
            // Fetch participant data
            const data = await fetchClassParticipant(apimBaseUrl, configContext, participantId);
            if (data && Object.entries(data).length > 0) {
                // If participant was found show modal and data
                setShowParticipantDetails(true);
                setViewEditParticipant(data);
            }
        } catch (e) {

        }
    };

    const returnParticipants = () => participants.map((participant, index) => {
        return <ClassParticipantListing
            {...participant}
            indexOfList={index+1}
            percentComplete={participant.percentComplete}
            classDetails={props.classDetails !== null && props.classDetails}
            canSubmitParticipant={((!allParticipantsSubmitted && classIsExpired) || !classIsExpired) && classHasStarted && props.canEditClassParticipants && (props.classDetails?.specialty != "PF") && (props.classDetails?.specialty != "DEB")}
            canEditClassParticipants={props.canEditClassParticipants}
            canEditParticipants={props.canEditParticipants}
            canSendEnrollmentEmail={props.canSendEnrollmentEmail}
            canTransferParticipants={props.canTransferParticipants}
            canViewCertificates={props.canViewCertificates}
            displayCheckbox={!allParticipantsSubmitted && classIsEnded}
            updateBlueCard={updateBlueCardId}
            isBlueCardRequiredForSubmit={participantsNeedBlueCard.some((i) => i === participant.classParticipantId)}
            isBlueCardIdDuplicated={participant.blueCardId && participant.blueCardId.length > 0 && duplicatedBlueCardIds.some((i) => i === participant.blueCardId)}
            onRemovePartipantFromClass={(props) => { handleRemoveParticipant(props) }}
            onSubmitRecord={handleSubmitRecord}
            participantCanSubmit={!participant.ceSubmitted && classIsEnded && !classIsExpired}
            onEditParticipant={handleEditParticipant}
            handleEditDataParticipant={onEditData}
            onCheck={handleCheck}
            setSubmittedErrors={props.setSubmittedErrors}
            addPageLevelAlert={props.addPageLevelAlert}
            key={participant.classParticipantId}
            onResendOnlineEmail={(isError) => handleResendEnrollmentEmail(isError)}
            seatsRequired={seatsRequired}
            onViewEditParticipant={() => handleViewEditParticipant(participant.participantId)}
        />
    });

    const returnAddParticipants = () => {
        return <EnrollParticipantsModal
            class={props.classDetails}
            seatsRequired={seatsRequired}
            setParticipantsEnrolled={props.setParticipantsEnrolled}
            canCreateParticipants={props.canCreateParticipants}
            canEditParticipants={props.canEditParticipants}
            canEnrollParticipants={props.canEnrollParticipants}
            close={handleCloseAddParticipants}
            classOrganization={props.classDetails?.organizationId}
            displayBulkImportSuccess={displayBulkImportSuccess} 
        />;
    }

    const returnImportFromFileComponent = (): JSX.Element => (
        <ImportUsingFile
            onSuccess={displayBulkImportSuccess}
            onCancel={() => setShowImportFromFile(false)}
            source={props.classDetails.trainingFormat}
            isOnlineOnly={(props.classDetails.trainingFormat == "virtual") && !props.classDetails.classroomFormat}
            blueCardRequired={props.classDetails.blueCardRequired}
            classId={props.classDetails.classId}
            desiredLms={props.classDetails.desiredLms}
            courseId={props.classDetails.courseId}
            specialty={props.classDetails.specialty}
            seatOrganizationId={props.classDetails.seatOrganizationId}
        />
    );

    const handleSubmitBtn = () => {
        if (!headerCheck) {
            if (participantsToSubmit.length === 0) {                
                handleSubmitAll();
            } else {
                handleSubmit();
            }
        } else {
            handleSubmitAll();
        }
    };       

    const handleExportClassParticipants = async () => {
        try {            
            const resp = await exportClassParticipants(apimBaseUrl, configContext, props.classDetails?.classId, rbac.userContext.Country);
            if (resp) {                
                saveAs(resp, `${props.classDetails?.className}.xlsx`);                 
            }
        } catch (err) {            
            console.error(err);            
        }
    };

    const handleCloseViewEditParticipant = () =>{
        setShowParticipantDetails(false); 
        setViewEditParticipant(null);
    };

    const isArchived = classIsExpired && props.classDetails.totalParticipants === props.classDetails.participantsSubmitted && props.classDetails.totalParticipants > 0;

    return (
        <>            
            <ModalPopUp
                title={t("ClassParticipants.AddParticipant")}
                size="lg"
                content={returnAddParticipants()}
                show={showAddParticipant}
                scrollable
                hide={handleCloseAddParticipants}
            />
            <ModalPopUp
                title={t("ClassParticipants.ImportUsingAFile")}
                size="lg"
                content={returnImportFromFileComponent()}
                show={showImportFromFile}
                hide={() => setShowImportFromFile(false)}
            />
            <ModalPopUp
                title={t("Participant Details")}
                size="md"
                show={showParticipantDetails}
                hide={handleCloseViewEditParticipant}
                actionButton={{
                    label:t("Close"),
                    isSolid:false,
                    clickAction: (e) => {
                        e.preventDefault();
                        handleCloseViewEditParticipant()
                    }
            }}
                content={
                    <ViewParticipantDetails
                        participant={viewEditParticipant}
                        canEditParticipants={props.canEditParticipants}
                        canEditClassParticipants={props.canEditClassParticipants}
                        canTransferParticipants={props.canTransferParticipants}
                        canViewCertificates={props.canViewCertificates}
                        canSendEnrollmentEmail={props.canSendEnrollmentEmail}
                        organizationId={viewEditParticipant?.organizationId}
                        organizationName={viewEditParticipant?.organizationName}
                        addPageLevelAlert={props.addPageLevelAlert}
                        onClose={handleCloseViewEditParticipant}
                        classId={props.classDetails.classId}
                        fromParticipants={false}
                        trainingFormat={props.classDetails.trainingFormat}
                    />
                }
            />
            <div className="classes-actions-row">
                <span>{pagination.total} {t("ClassParticipants.Participants")}</span>
                <div className="classes-actions">
                    {(!isArchived && (props.classDetails?.trainingFormat !== "blended" || (props.classDetails?.trainingFormat === "blended" && !isMigrated))) && (
                        <Button elementId="add-participant-button" clickAction={() => setShowAddParticipant(true)} isSolid={false} label={t("ClassParticipants.AddParticipant")} />
                    )}                    
                    {!isMobile && (
                        <>
                            {
                                !isArchived && props.canBulkUploadClassParticipants && (props.canCreateParticipants || props.canEnrollParticipants) 
                                && (props.classDetails?.trainingFormat !== "blended" || (props.classDetails?.trainingFormat === "blended" && !isMigrated))
                                && (
                                    <Button
                                        elementId="import-participants-button"
                                        isSolid={false}
                                        label={t("ClassParticipants.ImportUsingAFile")}
                                        clickAction={() => setShowImportFromFile(true)}
                                    />
                                )
                            }
                            <Button
                                elementId="export-list-button"
                                clickAction={() => handleExportClassParticipants()}
                                isSolid={false}
                                label={t("ClassParticipants.ExportList")}
                            />
                        </>
                    )}
                </div>
            </div>
            <CustomTable
                enableFilter={false}
                enablePaging
                pageSize={pagination.size}
                pageSizes={pageSizesClassParticipants}
                handlePageSize={updatePageSize}
                handlePage={updatePage}
                currentPage={pagination.current}
                total={pagination.total}
                enableSearch={false}
                enableHeader
                columns={columns}
                loading={props.isLoading}
                empty={isEmpty}
                emptyComponent={
                    <EmptyContent
                        icon={
                            <div className="empty-content-participants">
                                <i className="fa-sharp fa-solid fa-chair icon" />
                            </div>
                        }
                        button={!isArchived ? (
                                <Button elementId="lets-add-participants-button" clickAction={() => setShowAddParticipant(true)} isSolid label={t("ClassParticipants.AddParticipant")} />
                            ) : <></>
                        }
                        title={t("ClassParticipants.YourClassIsEmpty")}
                        subtitle={t("ClassParticipants.LetsAdd")}
                    />
                }
            >
                <ClassParticipantList participants={participants} listItems={returnParticipants()} />
            </CustomTable>
            {(
                    <>
                        {(!classIsExpired && !classHasStarted && (props.classDetails?.specialty != "PF") && (props.classDetails?.specialty != "DEB")) && (
                            <div className="participants-selection">                                                                
                                <p>{t("ClassParticipants.Submit.Participants.After.Class.EndDate", {endDate: startDate})}</p>
                            </div>
                        )}
                        {((props.classDetails.totalParticipants > 0) && !allParticipantsSubmitted && classHasStarted && props.canEditClassParticipants && (props.classDetails?.specialty != "PF") && (props.classDetails?.specialty != "DEB")) && (
                            <div className="participants-selection">
                                <Button
                                    elementId="submit-participants-button"
                                    isSolid
                                    isDisabled={isSubmitLoading}
                                    label={
                                        <div className="d-flex align-items-center justify-content-center">
                                            {isSubmitLoading && <i className="fas fa-spinner fa-spin mr-2"/>}
                                            {submitRecordsText}
                                        </div>
                                    }
                                    clickAction={handleSubmitBtn}
                                />
                            </div>
                        )}  
                    </>
                )}
        </>
    );
};

export default ClassParticipants;
