import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const DccQualityStandards = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={true}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('QualityStandard-DCC-Title') }</h1>
                                <p className="mw-900 mt-3">{ tp('QualityStandard-Intro') }</p>
                                <Tile title={ t('QualityStandard-Header') } hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <TwoColumnLayout
                                        left={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-TrainingStandard') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-NumberClasses-TS') } </p>

                                            </>
                                        }

                                        right={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-ValueBenefit') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-ToOrganization') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-NumberClasses-VB-O') }</span>
                                                <p>{ t('QualityStandard-ToCertifiedInstructor') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-NumberClasses-VB-CI') }</span>
                                            </>
                                        }
                                    />
                                    <TwoColumnLayout
                                        left={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-TrainingStandard') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-Renewal-TS') }</p>

                                            </>
                                        }

                                        right={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-ValueBenefit') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-ToOrganization') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Renewal-VB-O') }</span>
                                                <p>{ t('QualityStandard-ToCertifiedInstructor') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Renewal-VB-CI') }</span>
                                            </>
                                        }
                                    />
                                    
                                    <TwoColumnLayout
                                        left={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-TrainingStandard') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-Materials-TS') }</p>
                                                
                                                <ul className="ml-3">
                                                    <li><ArrowLink data={{ label:  t('BTN-Instructor-Guides-and-Presentations') , href: "URL Here", openInNewTab: false }} /></li>
                                                    <li><ArrowLink data={{ label:  t('BTN-Training-Materials') , href: "URL Here", openInNewTab: false }} /></li>
                                                </ul>   
                                            </>
                                        }

                                        right={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-ValueBenefit') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-ToOrganization') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Materials-VB-O') }</span>
                                                <p>{ t('QualityStandard-ToCertifiedInstructor') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Materials-VB-CI') }</span>
                                            </>
                                        }
                                    />
                                    
                                    <TwoColumnLayout
                                        left={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-TrainingStandard') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-Document-DCC-TS') }</p>
                                                <ul className="ml-3">
                                                    <li><ArrowLink data={{ label: t('BTN-Document-Class') , href: "URL Here", openInNewTab: false }} /></li>
                                                </ul>
                                        
                                                
                                            </>
                                        }

                                        right={
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <h3 className="sub-headings-h3">{ t('QualityStandard-ValueBenefit') }</h3>
                                                    </>
                                                </GrayTile>
                                                <p className="mt-2">{ t('QualityStandard-ToOrganization') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Document-VB-O') }</span>
                                                <p>{ t('QualityStandard-ToCertifiedInstructor') }</p>
                                                <span className="parsed-text">{ tp('QualityStandard-Document-DCC-VB-CI') }</span>
                                            </>
                                        }
                                    />
                                    </>      
                                </Tile>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default DccQualityStandards;
