import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const GuidingParticipantsToDebrief = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}

                                <h1 className="page-title-h1 title-margin">Guiding Participants to Debrief</h1>
                                <p className="mb-3 mw-900">If activities are going to be valuable training tools, they must accomplish their goals. How will learners know if they've met the objective? Usually this involves some form of debriefing. Individually, in small groups, or with the whole group, raise questions that will help them see how this activity can help them in their daily lives. A full list of questions to encourage reflection is included in the activity writing guide.</p>
                            </>
                        }
                    />
                    <Tile title="Understanding the Activity Purpose" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <a href="https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf" target="_new"><img width="250px" className="mt-3" src="https://cdn.crisisprevention.com/media/training-center/images/debrief.png" alt="Activity Writing Guide"></img></a>
                                        <ArrowLink data={{ label: "Download the Activity Writing Guide", href: "https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf", openInNewTab: true }} />


                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <p className="mt-3">When writing activities, we always include an “Explain” section with discussion questions that guide learners as they debrief the activity. This allows them to reflect on how they will use the concepts in the workplace.</p>
                                        <p>Here are some questions you could include to get participants thinking:</p>
                                        <ul className="ml-3">
                                            <li>What did you observe during the activity? How is that relevant to your work?</li>
                                            <li>How did you feel during your participation?</li>
                                            <li>Were there any surprises during the activity?</li>
                                            <li>What principles or ideas did you implement during the activity?</li>
                                            <li>What did you learn? How can you apply that in your work?</li>
                                            <li>What conclusions can you draw from the activity?</li>
                                            <li>What interventions did you practice in this activity that you can capture in your workbook and use in your workplace?</li>
                                        </ul>
                                        
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="mb-3">
                                    <Button label="Specify Purpose, Directions, & Setup" isSolid={false} elementId="buttonId" href="Specifying-Purpose-Directions-and-Setup" />
                                    </div>
                                    <div className="mb-3">
                                    <Button label="Step 6 | Evaluate Effectiveness" isSolid={true} elementId="buttonId" href="evaluating-effectiveness" />
                                    </div>
                                    </div>
                            </>
                        }
                    />

                </Container>
            </div>
        </>
    );
};

export default GuidingParticipantsToDebrief;
