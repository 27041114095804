import * as React from "react";

import { Accordion, Card, ListGroup } from "react-bootstrap";
import { LearnerLessonDetails, LearnerModuleDetails } from "../../Interfaces";

import { StatusEnum } from "../../Enums";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const CourseBreakdown = ({
    modules,
    onPlay
}: {
    modules: LearnerModuleDetails[];
    onPlay: (learnerModule: LearnerModuleDetails, learnerLesson: LearnerLessonDetails) => void;
}) => {
    return (
        <Accordion
            defaultActiveKey={
                modules.find((m) => m.Status === StatusEnum.InProgress)?.LearnerModuleId.toString() ??
                modules[0].LearnerModuleId.toString()
            }
        >
            {modules.map((lm, i) => {
                let headerIconClass = "fa-lock text-muted";
                if (lm.Status === StatusEnum.Completed) {
                    headerIconClass = "fa-check text-dark";
                }
                else if (lm.Status === StatusEnum.InProgress || lm.Order === 1) {
                    headerIconClass = "fa-play-circle";
                 }
                return (
                    <Accordion.Item key={lm.LearnerModuleId.toString()} eventKey={lm.LearnerModuleId.toString()}>
                        <Accordion.Header className="p-0 d-flex justify-content-between align-items-center w-100 test-start rounded-0">
                            <span>
                                <i
                                    className={`mr-2 fa ${headerIconClass}`}
                                ></i>
                                {lm.Name}
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body className="p-0">
                                <ListGroup onSelect={() => { }}>
                                    {lm.LearnerLessons.map((ll) => {
                                        let bodyIconClass = "fa-lock";
                                        if (ll.Status === StatusEnum.Completed) {
                                            bodyIconClass = "fa-check";
                                        }
                                        else if (ll.Status === StatusEnum.InProgress || (lm.Order === 1 && ll.Order === 1)) {
                                            bodyIconClass = "fa-play-circle";
                                        }
                                        return (
                                            <ListGroup.Item
                                                key={ll.LearnerLessonId}
                                                action
                                                className="border-0 rounded-0"
                                                disabled={
                                                    ll.Status === StatusEnum.NotStarted
                                                }
                                                variant={
                                                    ll.Status === StatusEnum.InProgress
                                                        ? "secondary"
                                                        : null
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onPlay(lm, ll);
                                                }}
                                            >
                                                <i
                                                    className={`mr-2 fa ${bodyIconClass}`}
                                                ></i>
                                                <span className="ps-1">{ll.Name}</span>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                );
            })}
        </Accordion>
    );
};

export default withAITracking(reactPlugin, CourseBreakdown);
