import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const RecordedWebinars = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1 title-margin">Recorded Webinars</h1>
                                <Tile title="Expectations for Classroom Tests" hideTileHeader={true}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <GrayTile percentWidth={100}>
                <>
                    {/* WRITE HTML BELOW THIS LINE */}
                    
                
                                <WebinarListing 
                                    arrowLinkArr={[
                                    {label:"• The Hear of Loving-Kindness Practice", href:"https://cdn.crisisprevention.com/media/training-center/classroom-resources/Heart-Loving-Kindness.pdf", openInNewTab: true },
                                    {label:"• Meditation Habit Worksheet", href:"https://cdn.crisisprevention.com/media/training-center/classroom-resources/Meditation-Habit-Worksheet.pdf", openInNewTab: true },
                                    {label:"• Pause and Begin Again", href:"https://cdn.crisisprevention.com/media/training-center/classroom-resources/Pause-Begin-Again.pdf", openInNewTab: true }
                                    ]} 
                                    image="/training-center/images/Webinar-CalmInCrisis.png" // can be any file extension 
                                    title="Calm In a Crisis: Mindfulness For Stress And Risk Management" 
                                    presenterName="Claire Parsons" 
                                    description="Claire Parsons is a practicing attorney with more than a decade of experience defending and advising teachers and administrators in special education matters. She is also a meditation, yoga, and compassion teacher and professional speaker. In this session, Claire will empower you with the tools you need to practice mindfulness, even in the most difficult situations."
                                    webinarDate={new Date('4/19/2023')
                                }
                                />
                                </>
            </GrayTile>
                            <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-WritingWorkplaceViolencePrevention.png" // can be any file extension 
                                            title="Your Board Of Directors" 
                                            presenterName="Odette Perriel" 
                                            description="Odette Perriel, MS, is an accomplished leader in the hospitality and health care industry. In this session, Odette will share how insights she gained from the hospitality industry can be applied by those working in the health care industry."
                                            webinarDate={new Date('3/15/2023')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-ReducingUsePhysicalRestraints.png" // can be any file extension 
                                            title="Strategies For Reducing The Use Of Physical Restraint And Time Out" 
                                            presenterName="Kristine Jones" 
                                            description={ tp('RecordedWebinars-KirstineJones-Description') }
                                            webinarDate={new Date('2/15/2023')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-DCCEssential.png" // can be any file extension 
                                            title="Dementia Capable Care: Essential Training For Health Care Workers" 
                                            presenterName="Kim Warchol" 
                                            description={ tp('RecordedWebinars-KimWarchol-Description') }
                                            webinarDate={new Date('12/14/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-WritingWorkplaceViolencePrevention.png" // can be any file extension 
                                            title="Writing The Book On Workplace Violence Prevention" 
                                            presenterName="Industry Experts" 
                                            description={<span className="parsed-text">{tp('RecordedWebinars-IndustryExperts-Description')}</span>}
                                            webinarDate={new Date('11/16/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-EquityIdentityLeadership.png" // can be any file extension 
                                            title="Equity, Identity, And Leadership" 
                                            presenterName="Tiffany S. Brunson Ph.D." 
                                            description="As an educational leader, Tiffany Brunson, Ph.D., has worked diligently over the past 28 years to establish classrooms, schools, and educational environments that respect diversity and provide experiences and space for all students to learn and thrive. These inclusive environments invite each of them to show up as their true authentic selves and fosters a sense of belonging. Dr. Brunson earned a B.S. in special education from the University of Illinois-Champaign/Urbana, a M.A. in Educational Administration from Roosevelt University-Chicago, and a Ph.D. in Educational Administration from Loyola University-Chicago. She currently serves as Superintendent of Elementary School District 159 in Matteson, Illinois."
                                            webinarDate={new Date('10/19/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-FundamentalsReslience.png" // can be any file extension 
                                            title="The Fundamentals Of Resilience" 
                                            presenterName="Dean Becker" 
                                            description={ tp('RecordedWebinars-DeanBecker-Description') }
                                            webinarDate={new Date('9/21/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-StandingTall.png" // can be any file extension 
                                            title="Standing Tall Through It All" 
                                            presenterName="Steve Bollar" 
                                            description={ tp('RecordedWebinars-SteveBollar-Description') }
                                            webinarDate={new Date('7/21/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-RaisingBar.png" // can be any file extension 
                                            title="Raising The Bar On Service Excellence" 
                                            presenterName="Kristin Baird" 
                                            description={ tp('RecordedWebinars-KristinBaird-Description') }
                                            webinarDate={new Date('6/22/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-Trauma.png" // can be any file extension 
                                            title="The Trauma Healing Process–Mind & Body" 
                                            presenterName="Dr. Lori Desautels" 
                                            description={ tp('RecordedWebinars-LoriDesautels-Description') }
                                            webinarDate={new Date('5/18/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-DeescalationCOVID.png" // can be any file extension 
                                            title="De-Escalation In The Time Of COVID" 
                                            presenterName="De-Escalation In The Time Of COVID" 
                                            description={ tp('RecordedWebinars-DanaPelke-Description') }
                                            webinarDate={new Date('4/21/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-WorkplaceViolencePrevention.png" // can be any file extension 
                                            title="Workplace Violence Prevention In Healthcare" 
                                            presenterName="Kimberly Urbanek & Kyle Graham" 
                                            description={ tp('RecordedWebinars-KimUrbanek-Description') }
                                            webinarDate={new Date('4/20/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-InterveningAlone.png" // can be any file extension 
                                            title="Intervening Alone" 
                                            presenterName="John Connor & Robyn Wade" 
                                            description="John has worked in the field of Developmental Services for 40 years. Currently he is the Person-Centered Planning Facilitator with Community Living London in London Ontario, Canada where he trains Direct Support Professionals in honoring the autonomy, rights, and choices of those they assist. John also teaches part-time at Fanshawe College in London, Ontario. Robyn works for Eastern Health, the largest health authority in Newfoundland and Labrador, Canada where she trains staff to develop and implement skills teaching and behavioral programming for children with Autism Spectrum Disorder (ASD) and other developmental disabilities. With 20 years of clinical experience working alone in the community, Robyn also trains staff in client site risk assessments, working alone policies and mentors them as they work alone in the community and to ensure safe and optimal care for both staff and clients. About the Session: In this session the panel will address the practical aspects of how individuals that work alone can conduct effective client assessments prior to meeting alone with individuals and families. Particular emphasis will be placed on preparing for the unpredictable and working with people whose anxieties or developmental disabilities can create unique challenges for personal safety. Those attending this session will leave with a Five-Point Plan for auditing risky environments and employing best practices that optimize the client interactions that occur in these environments."
                                            webinarDate={new Date('4/19/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-LivingWithAnxiety.png" // can be any file extension 
                                            title="Living With Anxiety And Depression" 
                                            presenterName="Scott Ste Marie" 
                                            description={ tp('RecordedWebinars-ScottSteMarie-Description') }
                                            webinarDate={new Date('3/23/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                                <p></p>
                                <GrayTile percentWidth={100}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <WebinarListing 
                                            image="/training-center/images/Webinar-CaregiverFatigue.png" // can be any file extension 
                                            title="Caregiver Fatigue And How To Rejuvenate" 
                                            presenterName="Patricia Smith" 
                                            description="In this recording, Patricia will focus on the symptoms and causes of Compassion Fatigue among caregivers as well as address the differences between compassion fatigue and burnout. A natural storyteller who draws upon her real-life experiences working in the field, Patricia will share strategies and stories that will help you understand the factors that contribute to human wellness and how you can recognize compassion fatigue in yourself and those you work with."
                                            webinarDate={new Date('2/23/2022')}
                                        />                     
                                                            </>
                                </GrayTile>
                            
                                </>      
                    </Tile>

                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default RecordedWebinars;
