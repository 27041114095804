import * as React from 'react';
import { useTranslation } from "react-i18next";
import { ClassSkillModel, SkillCategory, SkillSubcategory, Skill, ClassSkillSubmitted } from '../Interfaces/ClassSkillModel';

const useSkills = () => {

    const { t } = useTranslation();

    const processSkillsData = (data:ClassSkillModel, classDetailsSkillsData?:any) => {
        if (data && Array.isArray(data.categories)) {
            data.categories.forEach((category, i) => {
                if (Array.isArray(category.subcategories)) {
                    category.subcategories.forEach((subcategory, j) => {
                        if (Array.isArray(subcategory.skills)) {
                            // filter out inactive (or missing on class details) individual skills here
                            subcategory.skills = filterActiveSkills(subcategory.skills, classDetailsSkillsData);
                        }                        
                    });

                    // filter out empty subcategories on the category here
                    category.subcategories = filterPopulatedSubcategories(category.subcategories);
                } 
            });

            //filter out empty categories on the data here
            data.categories = filterPopulatedCategories(data.categories);
        }

        return data;
    }

    const prepareSkillsDataForSubmission = (data:ClassSkillSubmitted[]) => { 
        return data.map((skill) => {
            return {
                skillId: skill.skillId,
                skillName: t('Skills.' + skill.skillName),
                subCategory: t('Skills.' + skill.subCategory),
                category: t('Skills.' + skill.category)
            }
        });
    }

    const prepareSkillsDataForEdit = (data:ClassSkillSubmitted[], classDetailsSkillsData?:any) => { 

        if (data && data.categories) {
            let flatSkillsArray = [];

            data.categories.forEach((category) => {
                category.subcategories.forEach((subcategory) => {
                    subcategory.skills.forEach((skill) => {
                        skill.category = category.category;
                        skill.subCategory = subcategory.subcategory;
                        flatSkillsArray.push(skill);
                    });
                });
            });

            return flatSkillsArray.map((skill) => {
                // classSkillId is null when it's unchecked on load (id doesn't exist)
                return {
                    skillId: skill.skillId,
                    classSkillId: skill.classSkillId,
                    skillName: t('Skills.' + skill.skillName),
                    subCategory: t('Skills.' + skill.subCategory),
                    category: t('Skills.' + skill.category),
                    deleted: !classDetailsSkillsData.some((classSkill:any) => classSkill.skillId === skill.skillId) // to set as deleted = true unchecked ones on load
                }
                // Filter to only submit to payload the ones checked/unchecked from load
            }).filter((skill) => classDetailsSkillsData.some((classSkill:any) => classSkill.skillId === skill.skillId));
        } else {
            return [];
        }

    }

    const prepareSkillsDataForCreate = (data:ClassSkillSubmitted[]) => { 

        if (data && data.categories) {
            let flatSkillsArray = [];

            data.categories.forEach((category) => {
                category.subcategories.forEach((subcategory) => {
                    subcategory.skills.forEach((skill) => {
                        flatSkillsArray.push(skill);
                    });
                });
            });

            return flatSkillsArray.map((skill) => {
                return {
                    skillId: skill.skillId
                }
            });
        } else {
            return [];
        }

    }

    const filterActiveSkills = (skills:Skill[], classDetailsSkillsData?:any) => {
        // classDetailsSkillsData is a flat array of skills - will plan to interface soon
        // skill is a specifically structured model

        // if classDetailsSkillsData is not provided, return all active skills
        if (classDetailsSkillsData == undefined || classDetailsSkillsData == null) {
            const filteredSkills = skills.filter((skill) => skill.active); 
            return filteredSkills.map((skill) => {
                return {
                    ...skill,
                    isChecked: true
                }
            });

        // else retain inactive skills if they do appear in classDetailsSkillsData
        // precheck any skill that appears in classDetailsSkillsData in the returned array
        } else {
            if (!Array.isArray(classDetailsSkillsData) || !classDetailsSkillsData.length) {
                const filteredSkills = skills.filter((skill) => skill.active); 
                return filteredSkills.map((skill) => {
                    return {
                        ...skill,
                        isChecked: false
                    }
                });
            } else {
                // filter to skills that either appear in class details or are active
                const filteredSkills = skills.filter((skill) => skill.active || classDetailsSkillsData.some((classSkill:any) => classSkill.id === skill.skillId));

                // now, on filteredSkills, add isChecked=true to any skill that appear in classDetailsSkillsData, otherwise it is false
                return filteredSkills.map((skill) => {
                    const matchingClassSkill = classDetailsSkillsData.find((classSkill:any) => classSkill.skillId === skill.skillId);

                    return {
                        ...skill,
                        classSkillId: matchingClassSkill ? matchingClassSkill.classSkillId : null,
                        isChecked: classDetailsSkillsData.some((classSkill:any) => classSkill.skillId === skill.skillId)
                    }
                });
            }        
        }
    }

    const filterPopulatedSubcategories = (subcategories:SkillSubcategory[]) => {
        return subcategories.filter((subcategory) => subcategory.skills.length > 0);
    }

    const filterPopulatedCategories = (categories:SkillCategory[]) => {
        return categories.filter((category) => category.subcategories.length > 0);
    }

    return { processSkillsData, prepareSkillsDataForSubmission, prepareSkillsDataForEdit, prepareSkillsDataForCreate };
}

export default useSkills;