import "./many-sco-course.scss";

import * as React from "react";

import { Alert, Badge, Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { CoursePreview, PreviewLesson, PreviewModule } from "../../Interfaces";

import CoursePreviewBreakdown from "./course-preview-breakdown";
import { PlatformBaseRoutes } from "../../Routing/routes";
import { Link } from "react-router-dom";
import ManyScoPreviewModal from "./many-sco-preview-modal";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { isExternalURL } from "../../helper-functions";
import { useNavigate } from "react-router-dom";

import { SupportBaseRoutes } from "../../Routing/routes";

const ManyScoPreview = ({ preview }: { preview: CoursePreview }) => {
    const { t } = useTranslation();
    const [show, setShow] = React.useState(false);
    const [module, setModule] = React.useState<PreviewModule>(null);
    const [lesson, setLesson] = React.useState<PreviewLesson>(null);
    const [showPreviewAlert, setShowPreviewAlert] = React.useState(true);

    const modules = React.useMemo(() => {
        const sortedModules = preview.Modules.sort((a, b) => a.Order - b.Order);
        sortedModules.forEach((lm) => (lm.Lessons = lm.Lessons.sort((a, b) => a.Order - b.Order)));
        return sortedModules;
    }, [preview.Modules]);

    const onPlay = (module: PreviewModule, lesson: PreviewLesson) => {
        setShow(true);
        setModule(module);
        setLesson(lesson);
    };

    const navigate = useNavigate();
    const handleNavToContactUs = (route: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(route)) {
            event.preventDefault();
            navigate(route);
        }
    };

    return (
        <>
            <h1>
                {preview.Name}{" "}
                <Badge variant="dark" pill className="align-middle" style={{ fontSize: "24px" }}>
                    <i className="fas fa-eye me-2"></i> {t("Preview")}
                </Badge>
            </h1>
            <Alert variant="info" className="fw-bold">
                {t("This is a preview course, not to be used for formal training.")}{" "}
                <a className="text-link pendo__link-contact-us" target="_blank" href={SupportBaseRoutes.SupportContactUs.fullPath} onClick={(e) => {
                    handleNavToContactUs(SupportBaseRoutes.SupportContactUs.fullPath, e);
                }}>
                    {t("Please contact us to learn more")}
                </a>
            </Alert>
            <Row className="ms-0">
                <Col md={4} className="d-none d-md-block h-100 mb-4 ps-0">
                    <CoursePreviewBreakdown modules={modules} onPlay={onPlay} />
                </Col>
                <Col md={8} className="h-100 ps-0">
                    <div className="play-course ps-0 mx-0">
                        <a
                            className="text-decoration-none"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModule(modules[0]);
                                setLesson(modules[0].Lessons[0]);
                                setShow(true);
                            }}
                        >
                            <div
                                className="card-img d-flex flex-row"
                                style={{ backgroundImage: `url("/Thumbnails/${preview.ThumbnailFilename}")` }}
                            >
                                <span className="fa-stack fa-5x align-self-center justify-content-center d-flex flex-column w-100">
                                    <i className="fa fa-circle fa-stack-1x text-white pos-t-0"></i>
                                    <i className="fa fa-play-circle fa-stack-1x text-secondary pos-t-0"></i>
                                </span>
                            </div>
                            <p className="text-center">{t("Click to preview course")}</p>
                        </a>
                    </div>
                    <div className="d-block d-md-none mt-4">
                        <CoursePreviewBreakdown modules={modules} onPlay={onPlay} />
                    </div>
                    <div className="mt-4">
                        <Card className="w-100">
                            <Card.Header as="h4">{t("Course Information")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <h5>{t("Online Course Description")}</h5>
                                        <p className="ms-2">{preview.Description}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
            {show && (
                <ManyScoPreviewModal
                    show={show}
                    courseId={preview.CourseObjectId}
                    lessonId={lesson.LessonId}
                    isLastLesson={
                        !module.Lessons.some((l) => l.Order === lesson.Order + 1) &&
                        !preview.Modules.some((m) => m.Order === module.Order + 1)
                    }
                    onHide={() => {
                        setShow(false);
                    }}
                    onNext={() => {
                        const nextLesson = module.Lessons.find((l) => l.Order === lesson.Order + 1);

                        if (nextLesson) {
                            setLesson(nextLesson);
                        } else {
                            const nextModule = preview.Modules.find((m) => m.Order === module.Order + 1);
                            if (nextModule) {
                                setModule(nextModule);
                                setLesson(nextModule.Lessons[0]);
                            }
                        }
                    }}
                    showAlert={showPreviewAlert}
                    onAlertClose={() => setShowPreviewAlert(false)}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, ManyScoPreview);
