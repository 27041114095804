import * as React from "react";

import { Routes, Route } from "react-router-dom";

import BulkClassEnrollPage from "../Pages/bulk-class-enroll";
import BulkLearnerUpload from "../Pages/bulk-learner-upload";
import CertificatePage from "../Pages/certificate";
import CoursePreviewPage from "../Pages/course-preview";
import CourseSeatsPage from "../Pages/course-seats";
import { CpiRoute, InstructorBaseRoutes } from "../Routing/routes";
import LearnerHistoryPage from "../Pages/learner-history";
import ManageClassPage from "../Pages/manage-class";
import ManageClassesPage from "../Pages/manage-classes";
import ManageLearnersPage from "../Pages/manage-learners";
import Page from "../Pages/page";
import PreviewCoursesPage from "../Pages/preview-courses";
import ProtectedRoute, { ProtectedRouteProps } from "./protected-route";
import { RoleEnum, InstructorRoles } from "../Enums";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import NoAccess from "../Pages/no-access";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

const InstructorRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (
    <ProtectedRoute roles={InstructorRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const createClassRoutes = (
    routesData: any,
    rac: any
) => {
    let tempArray: CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(InstructorBaseRoutes.Class[route]);
    }

    return (
        <>
            {tempArray.map((route: any, i: number) => (
                // map over CpiRoute[]
                (rac(route)) ?

                <>
                    // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                    <Route key={i} path={route.path}
                        element={
                            <InstructorRoute>
                               
                                    <div>
                                        {route.pageId == "class" &&
                                            <ManageClassesPage />
                                        }
                                        {route.pageId == "classBulkUpload" &&
                                            <BulkClassEnrollPage />
                                        }
                                        {route.pageId == "classManage" &&
                                            <ManageClassPage />
                                        }
                                    </div>
                            
                            </InstructorRoute>
                        }
                    />

                    <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                        }
                    />
                </> 
                    :
                    // we do not meet the criteria for culture and should render no-access content
                    <Route key={i} path={route.path}
                        element={
                            <ProtectedRoute>
                                <Page title={route.title()} route={route.path}>
                                    <>
                                        { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */}
                                        {<NoAccess />}
                                    </>
                                </Page>
                            </ProtectedRoute>
                        }
                    />
            ))}
        </>
    );
};

const createLearnerRoutes = (
    routesData: any,
    rac: any
) => {
    let tempArray: CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(InstructorBaseRoutes.Learner[route]);
    }

    return (
        <>
            {tempArray.map((route: any, i: number) => (
                // map over CpiRoute[]
                (rac(route)) ?
                <>
                    // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                    <Route key={i} path={route.path}
                        element={
                            <InstructorRoute>
                                <Page title={route.title()} route={route} showDropdown>
                                    <div>
                                        {route.pageId == "learner" &&
                                            <ManageLearnersPage />
                                        }
                                        {route.pageId == "bulkLearnerUpload" &&
                                            <BulkLearnerUpload />
                                        }
                                        {route.pageId == "learnerHistory" &&
                                            <LearnerHistoryPage />
                                        }
                                        {route.pageId == "learnerCertificate" &&
                                            <CertificatePage />
                                        }
                                    </div>
                                </Page>
                            </InstructorRoute>
                        }
                        
                    />
                    <Route path="*"
                            element={
                            <ProtectedRoute>
                                {<NoAccess />}
                            </ProtectedRoute>                                
                        }
                    />
                </>
                    :
                    // we do not meet the criteria for culture and should render no-access content
                    <Route key={i} path={route.path}
                        element={
                            <ProtectedRoute>
                                <Page title={route.title()} route={route.path}>
                                    <>
                                        { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */}
                                        {<NoAccess />}
                                    </>
                                </Page>
                            </ProtectedRoute>
                        }
                    />
            ))}
        </>
    );
};

const createCourseSeatRoutes = (
    routesData: any,
    rac: any
) => {
    let tempArray: CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(InstructorBaseRoutes.CourseSeat[route]);
    }

    return (
        <>
            {tempArray.map((route: any, i: number) => (
                // map over CpiRoute[]
                (rac(route)) ?
                <>
                    // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                    <Route key={i} path={route.path}
                        element={
                            <InstructorRoute>
                                <Page title={route.title()} route={route} showDropdown>
                                    <div>
                                        {route.pageId == "courseSeats" &&
                                            <CourseSeatsPage />
                                        }
                                    </div>
                                </Page>
                            </InstructorRoute>
                        }
                    />

                    <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                        }
                    />
                </>
                    :
                    // we do not meet the criteria for culture and should render no-access content
                    <Route key={i} path={route.path}
                        element={
                            <ProtectedRoute>
                                <Page title={route.title()} route={route.path}>
                                    <>
                                        { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */}
                                        {<NoAccess />}
                                    </>
                                </Page>
                            </ProtectedRoute>
                        }
                    />
            ))}
        </>
    );
};

export const InstructorClassRouting = withAITracking(reactPlugin,() => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>
            {createClassRoutes(InstructorBaseRoutes.Class, rac)}
        </Routes>
    );
});

export const InstructorLearnerRouting = withAITracking(reactPlugin,() => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>
            {createLearnerRoutes(InstructorBaseRoutes.Learner, rac)}
        </Routes>
    );
});

export const InstructorCourseSeatRouting =  withAITracking(reactPlugin,() => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>
            {createCourseSeatRoutes(InstructorBaseRoutes.CourseSeat, rac)}
        </Routes>
    );
});