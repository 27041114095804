import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.scss";

import * as React from "react";

import { Button, InputGroup } from "react-bootstrap";

import Picker from "react-datepicker";
import { dateFormatPattern } from "../locale-utils";
import i18next from "i18next";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

interface DatePicker {
    id?: string;
    required?: boolean;
    value: Date;
    onChange: (date: Date) => void;
    label: string | JSX.Element;
}

const DatePicker = ({ id, required, value, onChange, label }: DatePicker) => {
    const inputId = id ?? "datePicker";
    const ref = React.createRef();
    const pattern = dateFormatPattern();
    //@ts-ignore
    const locale = getLocale(i18next.language) ?? getLocale("en-US");
    return (
        <>
            <label htmlFor={inputId}>{label}</label>
            <InputGroup className="mb-3">
                <Picker
                    //@ts-ignore
                    ref={ref}
                    id={inputId}
                    required={required}
                    locale={locale}
                    placeholderText={pattern.toLowerCase()}
                    dateFormat={pattern}
                    className="form-control date-picker-input"
                    wrapperClassName="date-picker-wrapper"
                    selected={value}
                    onChange={onChange}
                />
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        //@ts-ignore
                        ref.current.setOpen(true);
                    }}
                >
                    <i className="far fa-calendar-alt" aria-hidden="true"></i>
                </Button>
            </InputGroup>
        </>
    );
};

export default DatePicker;
