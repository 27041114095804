import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Form, Modal, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";
import { useToast } from "../../hooks/toast";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { fetchMfaDevice, addMfaDevice, deleteMfaDevice, emailOtpCode, validateOtpCode } from '../../ApiServices/MyAccount';

export interface MultiFactorAuthenticationModalProps {
	show: boolean;
	onSend: (resp: JsonResponseModel<ApiResponse>) => void;
    handleCancel: () => void;
}

const MultiFactorAuthenticationModal = ({ show, onSend, handleCancel }: MultiFactorAuthenticationModalProps) => {
	const { userContext } = React.useContext(RbacContext);
	const { toast } = useToast();

	const { t } = useTranslation();
	const [mfa, setMFA] = React.useState("");
	const [validated, setValidated] = React.useState(false);
	const [sending, setSending] = React.useState(false);
	const [showSecondPage, setShowSecondPage] = React.useState(false);
	const [mfaEnabled, setMfaEnabled] = React.useState(false);
	const [mfaEnabledInitial, setMfaEnabledInitial] = React.useState(false);
	const [otpId, setOtpId] = React.useState("");
	const [otpCode, setOtpCode] = React.useState("");
	const [deviceId, setDeviceId] = React.useState("");
	const [mfaEmail, setMfaEmail] = React.useState(userContext.Email);

	React.useEffect(() => {
		const getMfaDevice = async () => {
			try {
				const deviceResp = await fetchMfaDevice(); 
				setDeviceId(deviceResp.Data.DeviceId);
				if (deviceResp.Data.Email !== null && deviceResp.Data.Email.length > 0) {
					setMfaEnabled(true);
					setMfaEnabledInitial(true);
					setMfaEmail(deviceResp.Data.Email);
				}
			} catch (err) {
				appInsights.trackException({ error: err, properties: userContext });
				console.error(err);
			}
		};

		if (userContext.UserId) {
			getMfaDevice();
		}
	}, [userContext]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();

		if (form.checkValidity()) {
			try {
				setSending(true);
				const validationResp = await validateOtpCode(otpId, otpCode);
				if (validationResp.Error) {
					toast(validationResp);
                }
				else if (validationResp.Data) {
					if (validationResp.Data.Success) {
						const resp = await addMfaDevice(mfaEmail);
						onSend(resp);
					}
					else {
						toast(validationResp);
                    }
                }
			} catch (err) {
				appInsights.trackException({ error: err, properties: userContext });
				console.error(err);
			}
		} else {
			setValidated(true);
		}
	};

	const saveChanges = async () => {
		if (mfaEnabled != mfaEnabledInitial) {
			if (mfaEnabled) {
				const resp = await emailOtpCode(userContext.Email);
				setOtpId(resp.Data.OtpId);
				if (resp.Data.OtpId !== null && resp.Data.OtpId.length > 0) {
					setShowSecondPage(true);
				}
			}
			else {
				const resp = await deleteMfaDevice(deviceId);
				onSend(resp);
            }
		}
	};

    return (
		<Modal show={show} onHide={handleCancel}>
			<Form className="brand-form" noValidate onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("Multi-Factor Authentication")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid className="multi-factor-authentication-modal">
						{!showSecondPage && (
							<Row>
								<Col className="col-12 section-wrapper-medium">
									<div className="dashboard-tile card py-2 px-2 py-md-4 px-md-4">
										<ul className="brand-form-fieldset">
											<li className="form-item my-3">
												<Form.Group controlId="mfa" className="field-group">
													<label htmlFor="field-mfa">{t("Multi-Factor Authentication")}</label>
													<div className="toggle-wrapper">
														<span className="toggle-label-disabled">{t("Off")}</span>
														<label className="toggle">
															<input id="field-mfa" name="mfa" type="checkbox" checked={mfaEnabled}
																onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setMfaEnabled(!mfaEnabled) }} />
															<span className="switch round"></span>
														</label>
														<span className="toggle-label-enabled">{t("On")}</span>
													</div>
													<Form.Control.Feedback type="invalid" className="field-validation message-hidden">{t("Please select a Multi-Factor Authentication option.")}</Form.Control.Feedback>
												</Form.Group>
											</li>
											<li className="form-item mb-3">
												<Form.Group controlId="email" className="field-group">
													<Form.Label>{t("Authentication Method")}</Form.Label>
													<span className="inline-label d-block">{t("Email")}</span>
													<span className="field-text d-block">{mfaEmail}</span>
												</Form.Group>
											</li>
											<li className="form-item mb-0">
												<div className="d-flex align-items-center">
													<Button className="inline-button inline-icon-left cancel-button" variant="link" disabled={sending} onClick={handleCancel}>
														{t("Cancel")}
													</Button>
													<Button className="button-brand-primary d-block ms-auto" variant="primary" onClick={saveChanges} disabled={sending}>
														{sending ? (
															<>
																<i className="fas fa-spinner fa-spin"></i> {t("Saving")}
															</>
														) : (
															t("Save Changes")
														)}
													</Button>
												</div>
											</li>
										</ul>
									</div>
								</Col>
							</Row>
						)}
						{showSecondPage && (
							<Row>
								<Col className="col-12 section-wrapper-medium">
									<div className="dashboard-tile card py-2 px-2 py-md-4 px-md-4">
										<h2 className="fs36 bold text-center mb-2">{t("You Are Almost Done")}</h2>
										<p className="section-description text-center">{t("To finish setting up multi-factor authentication, enter the verification code that was sent to")} { mfaEmail }</p>
										<ul className="brand-form-fieldset">
											<li className="form-item my-3">
												<Form.Group controlId="verify" className="field-group mx-auto">
													<label className="" htmlFor="field-verify">{t("Verification Code")}</label>
													<input className="default-text-field-control" id="field-verify" name="verify" type="text" required={true} value={otpCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setOtpCode(event.target.value);} } />
													<Form.Control.Feedback type="invalid" className="field-validation message-hidden">{t("Please enter a valid verification code.")}</Form.Control.Feedback>
												</Form.Group>
											</li>
											<li className="form-item mb-3">
												<div className="d-flex align-items-center">
													<button className="button-brand-primary d-block mx-auto" type="submit">{t("Continue")}</button>
												</div>
											</li>
											<li className="form-item mb-0">
												<p className="form-details text-center">{t("If you did not receive the code, check your spam folder.")}{" "}
													<a href="#" className="text-link pendo__link-contact-us" onClick={(e) => e.preventDefault()}>
														{t("Contact us")}
													</a>
													{" "} {t("for additional assistance.")}</p>
												<p className="form-details text-center">
													<a href="#" className="text-link" onClick={(e) => { e.preventDefault(); saveChanges(); }}>
														{t("Click here")}
													</a>
													{" "} {t("to resend the verification code.")}
												</p>
											</li>
										</ul>
									</div>
								</Col>
							</Row>
						)}
					</Container>
				</Modal.Body>
			</Form>
		</Modal>
    );
};

export default withAITracking(reactPlugin, MultiFactorAuthenticationModal);
