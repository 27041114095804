import React from 'react'

import { useQuery } from '@tanstack/react-query'

import DebouncedInput from './debounced-input';
import GenericFilter from "../Pages/Components/UI/generic-filter";
import LoadingContent from "../Components/UI/loading-content";

import './remote-table.scss';

import {
    PaginationState,
    SortingState,
    useReactTable,
    getCoreRowModel,
    flexRender,
    ColumnSort,
} from '@tanstack/react-table';

import type { CustomTableProps } from "../Interfaces/Platform/CustomTable";

import { PagingOptions, PagedRows } from "./../ApiServices";
import Select from "../Components/Form/select";
import FormSelectOption from "../Interfaces/Platform/FormSelectOption";
import { SelectedFiltersBasic } from '../Interfaces/Platform/GenericFilter';

const defaultProps: CustomTableProps = {
    parentTrigger: null,
    classes: true,
    fetchFunction: [],
    fetchData: false,
    loading: false,
    fetchParameters: [],
    queryKey: "default",
    columns: [],
    defaultData: [],
    enablePaging: true,
    empty: false,
    enableSearch: true,
    defaultSorted: {dataField: "", order: "asc"},
    pageSizes: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 99999999999, text: "All" }
    ],
    pageSize: 10,
    currentPage: 1,
    handlePageSize: () => {},
    handlePage: () => {},
    total: 0,
    enableFilter: false,
    enableHeader: true,
    scrollable: false
};

function CustomTable(props: CustomTableProps) {
    props = { ...defaultProps, ...props };
    const minPageIndex = 1;
    const pages = Math.ceil(props.total / props.pageSize);
    const [pagination, setPagination] =
        React.useState<PaginationState>({
            pageIndex: minPageIndex,
            pageSize: props.enablePaging ? 10 : 999999999999999,
        });

    const [fetchDataOptions, setFetchDataOptions] = React.useState<PagingOptions>(
        {
            currentPage: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortBy: props.defaultSorted.dataField,
            sortDirection: props.defaultSorted.order,
            searchTerm: ""
        });

    async function fetchData(): Promise<PagedRows<any>>{
        if (props.fetchData) {
            const data = props.fetchParameters.length > 0 ? await props.fetchFunction(props.fetchParameters, fetchDataOptions) : await props.fetchFunction(fetchDataOptions);
            const pagedRows: PagedRows<any> = {
                rows: data?.Results ?? [],
                pageCount: data?.PageCount ?? 0
            }
            return pagedRows;
        }
        return { rows: [], pageCount: 0 };
    }

    const dataQuery = useQuery(
        [props.queryKey, fetchDataOptions], () => fetchData(), { keepPreviousData: false }
    ); 

    const [globalFilter, setGlobalFilter] = React.useState('');
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const columns = props.columns;

    const table = useReactTable({
        data: props.fetchData ? dataQuery.data?.rows ?? props.defaultData : props.defaultData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        defaultColumn: {
            size: 0,
            minSize: 0,
            maxSize: 100,
        },
    });

    const getPageNumbers = () => {
        let pageNums: number[] = [];
        const maxPageAdj = minPageIndex - 1;
        const pageCount = pages + maxPageAdj;
        if (pages > 5) {
            if (props.currentPage <= 3 + maxPageAdj) {
                pageNums = [minPageIndex, minPageIndex + 1, minPageIndex + 2, minPageIndex + 3, minPageIndex + 4];
            } else if (props.currentPage >= pageCount - 2) {
                pageNums = [pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
            } else {
                pageNums = [props.currentPage - 1, props.currentPage, props.currentPage + 1, props.currentPage + 2];
            }
        }
        else if (pages > 1 && pages < 6) {
            for (let i = 1; i <= pageCount; i++) {
                pageNums.push(i);
            }
        }
        return pageNums?.map(pageNum => (
            paginationButton((pageNum - maxPageAdj).toString(), pageNum - maxPageAdj, pageNum, false, pageNum, pageNum == props.currentPage)
        ));
    };

    const paginationButton = (title: string, text: string | number, value: number, hidden: boolean, key: string | number, active: boolean = false) => {
        return (
            <li key={key} className={`page-item${active ? " active" : ""}`} title={title} >
                <button className="page-link custom-table-pagination-btn"
                    onClick={(e) => { props.handlePage(value); e.currentTarget.blur(); }}
                    hidden={hidden}>
                    {text}
                </button>
            </li>
        );
    };

    const options = props.pageSizes.map((pageSize) => ({ value: pageSize.value.toString(), label: pageSize.text }));

    return (
        <div className="custom-table">
            {props.enableSearch && (
                <div className="container-fluid">
                    <div className="col">
                        <div className="row">
                            <DebouncedInput
                                value={globalFilter ?? ''}
                                onChange={(value) => {
                                    if(props.fetchData) setFetchDataOptions({
                                        ...fetchDataOptions,
                                        searchTerm: value.toString()
                                    });
                                    setGlobalFilter(String(value));
                                }}
                                className="form-control"
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                </div>
            )}
            {props.enableFilter && (
                <GenericFilter
                    parentTrigger={props.parentTrigger}
                    filtersTemplate={props.filters.template}
                    searchLabel={props.filters.search.label}
                    updateFilter={props.filters.update}
                    clearFilters={props.filters.clear}
                    showClearFilter={props.filters.showClear}
                    onSearch={() => props.filters.search.handle(1)}
                    sortByOptions={props.filters.options.sortByOptions}
                    programOptions={props.filters.options.programOptions}
                    instructorOptions={props.filters.options.instructorOptions}
                    statusOptions={props.filters.options.statusOptions}
                    expirationDateOptions={props.filters.options.expirationDateOptions}
                    lastTrainedOptions={props.filters.options.lastTrainedOptions}
                    organizationOptions={props.filters.options.organizationOptions}
                    percentageRecordedOptions={props.filters.options.percentageRecordedOptions}
                    setSearchParams={props.filters.set}
                />
            )}
            {
                props.filtersTemplate && props.filtersTemplate
            }
            <div className="h-2" />
            <table className={`${props.classes ? 'table table-striped table-borderless table-nohover' : 'w100'}`}>
                {props.enableHeader && (
                    <thead className={`${props.scrollable ? 'custom-table-header-scrollable' : ''}`}>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    let cellStyle = header.column.columnDef.meta?.headerStyle;
                                    if (header.column.columnDef.size > 0) {
                                        cellStyle = { ...cellStyle, width: `${header.column.columnDef.size}%` };
                                    }
                                    if (header.column.columnDef.sizePx && header.column.columnDef.sizePx > 0) {
                                        cellStyle = { ...cellStyle, width: `${header.column.columnDef.sizePx}px` };
                                    }
                                    let sortIconClass = "order-4";
                                    if (header.column.id == fetchDataOptions.sortBy) {
                                        sortIconClass = fetchDataOptions.sortDirection == "asc" ? "caret-4-asc" : "caret-4-desc";
                                    }
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}
                                            style={cellStyle}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            role: header.column.getCanSort() ? "button" : "",
                                                            className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                            onClick: (e) =>
                                                            {
                                                                if (header.column.getCanSort()) {
                                                                    let sortDir: "asc" | "desc" = "asc";
                                                                    if (fetchDataOptions.sortBy == header.column.id && fetchDataOptions.sortDirection == "asc") {
                                                                        sortDir = "desc";
                                                                    }
                                                                    setFetchDataOptions({
                                                                        ...fetchDataOptions,
                                                                        sortBy: header.column.id,
                                                                        sortDirection: sortDir
                                                                    });
                                                                    const colSort: ColumnSort = { id: header.column.id, desc: sortDir == "desc" };
                                                                    setSorting([colSort]);
                                                                }
                                                            },
                                                            }}
                                                    >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                        )}
                                                        {header.column.getCanSort() && (
                                                            <span className={sortIconClass}>
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                )}
                {(!props.loading && !props.empty) && (
                    props.children ? (
                        <tbody>
                            {props.children}
                        </tbody>
                    ) : (
                        <tbody>
                            {table.getRowModel().rows.map(row => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => {
                                            return (
                                                <td key={cell.id} className={cell.column.columnDef.meta?.bodyClass} style={cell.column.columnDef.meta?.bodyStyle }
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    )
                )}
            </table>
            {props.loading ? (
                <LoadingContent
                    title="Loading"
                    icon={
                        <div className="loading-content-participants">
                            <i className="fas fa-duotone fa-spinner icon" />
                        </div>
                    }
                />
            ) : props.empty && props.emptyComponent}
            <div className="h-2 mt-3" />
            {(!props.empty && props.enablePaging && !props.loading && props.total > 0) && (
                <div className={`row react-bootstrap-table-pagination ${props.scrollable ? 'custom-table-footer-scrollable' : ''}`}>
                    <div className={`${props.paginationAction ? 'col-md-4 col-xs-4 col-sm-4 col-lg-4' : 'col-md-6 col-xs-6 col-sm-6 col-lg-6'}`}>
                        <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: 'visible' }}>
                            <Select
                                name="pageSize"
                                id="pageSize"
                                filter
                                key="pageSize"
                                label=""
                                showLabel={false}
                                additionalClass="select-btn rounded custom-table-page-size"
                                value={props.pageSize.toString()}
                                options={options}
                                changeFilterAction={(e: FormSelectOption) => {
                                    props.handlePageSize(Number(e.value));
                                }}
                            />
                        </span>
                        {props.fetchData && (
                            <span className="pagination-info ps-2">
                                Showing {pagination.pageIndex * pagination.pageSize - (pagination.pageSize - 1)} - {pagination.pageIndex < table.getPageCount() ? pagination.pageIndex * pagination.pageSize : props.total} of {props.total}
                            </span>
                        )}
                        {!props.fetchData && (
                            <span className="pagination-info ps-2">
                                Showing {props.currentPage * props.pageSize - (props.pageSize - 1)} - {props.currentPage < pages ? props.currentPage * props.pageSize : props.total} of {props.total}
                            </span>
                        )}

                    </div>
                    <div className={`custom-table-pagination react-bootstrap-table-pagination-list ${props.paginationAction ? 'col-md-4 col-xs-4 col-sm-4 col-lg-4' : 'col-md-6 col-xs-6 col-sm-6 col-lg-6'}`}>
                        <ul className="pagination float-end mt-0">
                            {props.currentPage > 3 && (
                                <>
                                    {paginationButton("Go to first", minPageIndex, minPageIndex, props.currentPage <= minPageIndex, "First")}
                                    <i className="fa-solid fa-sharp fa-ellipsis custom-table-pagination-ellipsis" />
                                </>
                            )}
                            {getPageNumbers()}
                            {props.currentPage < pages - 2 && (
                                <>
                                    <i className="fa-solid fa-sharp fa-ellipsis custom-table-pagination-ellipsis" />
                                    {paginationButton("Go to last", pages, pages, props.currentPage >= pages + (minPageIndex - 1), "Last")}
                                </>
                            )}
                        </ul>
                    </div>
                    {props.paginationAction && (
                        <div className="d-flex align-items-start justify-content-end col-md-4 col-sm-4 col-xs-4 col-lg-4">
                            {props.paginationAction}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CustomTable;
