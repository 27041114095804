import * as React from 'react'; 
import AlertProperties from '../Interfaces/Platform/AlertProperties';

const usePageLevelAlerts = () => {

    const [pageLevelAlerts, setPageLevelAlerts] = React.useState<AlertProperties[]>([]);

    const addPageLevelAlert = (alertProps:AlertProperties) => {
        setPageLevelAlerts(previousState => [...previousState, alertProps]);
    };

    const removePageLevelAlerts = () => {
        setPageLevelAlerts([]);
    };

    return [pageLevelAlerts, addPageLevelAlert, removePageLevelAlerts] as const;

}

export default usePageLevelAlerts;