const useEmailRegex = () => {

    // Email Regex pattern from emailregex.com
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    // Same email validation but allows leading and trailing characters by removing the ^ and $ from the ends
    // Use this if you need to detect an email pattern within a larger string
    const emailRegexAllowTrailingAndLeading = new RegExp(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);

    const whitespaceRegex = /\s/;

    const isEmail = (email:string) => {
        return (emailRegex.test(email) && !whitespaceRegex.test(email));
    }

    // Checks for an email within the string, return true if one is found
    const containsEmail = (text:string) => {
        return emailRegexAllowTrailingAndLeading.test(text);
    }

    return { isEmail, containsEmail }
}

export default useEmailRegex;