import * as React from "react";
import { PropsWithChildren } from "react";

import { ConfigurationContext } from "./Interfaces/ConfigurationContext";
import { fetchConfiguration } from "./ApiServices/Configuration";

export const ConfigContext = React.createContext<ConfigurationContext>({
    SystemConfiguration: {
        EnableCourseProgressSync: false,
        Localize: false,
        Locales: [{ LangTag: "en-US", Name: "English (United States)" }]
    }
});
ConfigContext.displayName = "ConfigContext";

export const HoneyBeeConfigContextApp: React.FunctionComponent<PropsWithChildren> = (props) => {
    const [currentConfig, setCurrentConfig] = React.useState<ConfigurationContext>({
        SystemConfiguration: {
            EnableCourseProgressSync: false,
            Localize: false,
            Locales: [{ LangTag: "en-US", Name: "English (United States)" }]
        }
    });
    React.useEffect(() => {
        async function getData() {
            try {
                var resp = await fetchConfiguration();
                setCurrentConfig({ SystemConfiguration: resp.Data });
            } catch (error) {
                console.error(error);
            }
        }

        getData();
    }, []);

    return <ConfigContext.Provider value={currentConfig}>{props.children}</ConfigContext.Provider>;
};

export const createHoneyBeeConfigApp = (WrappedComponent: React.ElementType) => (
    <HoneyBeeConfigContextApp>
        <WrappedComponent />
    </HoneyBeeConfigContextApp>
);
