import i18n from "i18next";
import useEmailRegex from "../hooks/useEmailRegex";

export default function validate(values:any) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors:any = {};

    // Ensure first name exists and is not an email
    if (!values.firstName) {
        errors.firstName = i18n.t("Enter First Name");
    } else if (containsEmail(values.firstName)) {
        errors.firstName = i18n.t("BulkUpload.FirstNameIsEmail");
    }

    // Ensure last name exists and is not an email
    if (!values.lastName) {
        errors.lastName = i18n.t("Enter Last Name");
    } else if (containsEmail(values.lastName)) {
        errors.lastName = i18n.t("BulkUpload.LastNameIsEmail");
    }

    if (!values.title) {
        errors.title = i18n.t("Enter Title");
    }

    if (!values.addressLine1) {
        errors.addressLine1 = i18n.t("Enter Address");
    }

    if (!values.country || values.country == '-') {
        errors.country = i18n.t("Enter Country");
    }

    if (!values.city) {
        errors.city = i18n.t("Enter City");
    }

    if (!values.state || values.state == "Select") {
        errors.state = i18n.t("Enter State");
    }

    if (!values.zipCode) {
        errors.zipCode = i18n.t("Enter Postal Code");
    }

    if (!values.phone) {
        errors.phone = i18n.t("Enter Phone Number");
    }

    return errors;
}