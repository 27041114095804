import i18n from "i18next";
import type { ICreateClassNote } from "../Interfaces/Platform/Classes";

export function validate(values: ICreateClassNote) {
    let errors: any = {};

    if (!values.note) {
        errors.note = i18n.t("Validate-Class-Note");
    }

    if (values.note && values.note.length > 1000) {
        errors.note = i18n.t("Exceeded-Max-Limit", { length: 1000 });
    }

    return errors;
}

export function validateNote(values: ICreateClassNote) {
    let errors: any = {};

    if (values.note && values.note.length > 1000) {
        errors.note = i18n.t("Exceeded-Max-Limit", { length: 1000 });
    }

    return errors;
}
