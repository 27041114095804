import * as React from "react";

import { Navigate } from "react-router-dom";

import { RbacContext } from "../rbac-context";
import { getToken, isAuthenticated } from "../helper-functions";
import NoAccess from "../Pages/no-access";

export interface ProtectedRouteProps {
    children?: React.ReactNode,
    roles?: any[];
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, roles }) => {
    const { userContext } = React.useContext(RbacContext);
    const token = getToken("accessToken");
    if (!!token) {
        if (userContext.Email == null) {
            return <Navigate to={window.location.pathname} replace />;
        }
        if (!roles || roles.length === 0 || roles.some((role) => userContext.RolesByName?.includes(role))) {
            return children;
        }
        return <NoAccess />;
    }
    
    window.location.href = `/Login?redirect=${encodeURIComponent(window.location.href)}`;
    return null;
};

export default ProtectedRoute;
