import i18n from "i18next";

export default function validate(values:any) {
    let errors: any = {};

    if (!values.firstName) {
        errors.firstName = i18n.t("Enter first name");
    }

    if (!values.lastName) {
        errors.lastName = i18n.t("Enter last name");
    }

    return errors;
}