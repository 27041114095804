import { isNullOrUndefined } from "./helper-functions";

export function parseMSDate(date: string): Date {
    if (isNullOrUndefined(date)) {
        return null;
    }
    return new Date(parseInt(date.substr(6)));
}

export function addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}
