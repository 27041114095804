export enum RoleEnum {
    Developer = 'Global Admin',
    Administrator = 'Course Admin',
    StaffSeatAdjustor = 'Staff Seat Adjustor',
    StaffSeatReadOnly = 'Staff Seat - Read Only',
    CertifiedInstructor = 'CI',
    BusinessAdministrator = 'BA',
    Learner = 'Learner',    
    Enrollee = 'Enrollee',
    ParticipantAdmin = 'Participant Admin'
}

export const AdminRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.Developer
];

export const BusinessAdministratorRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.BusinessAdministrator,
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const CertifiedInstructorRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.CertifiedInstructor,
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const DashboardRoles: RoleEnum[] = [];

export const DevRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.Developer
];

export const ErrorRoles: RoleEnum[] = AdminRoles;

export const InstructorRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.BusinessAdministrator,
    RoleEnum.CertifiedInstructor,
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const LearnerEnrolleeRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.BusinessAdministrator,
    RoleEnum.CertifiedInstructor,
    RoleEnum.Developer,
    RoleEnum.Enrollee,
    RoleEnum.Learner,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const ParticipantManagementRoles: RoleEnum[] = [
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin
];

export const PlatformRoles: RoleEnum[] = [];

export const SupportRoles: RoleEnum[] = [];

export const StaffAndCourseRoles: RoleEnum[] = [
    RoleEnum.Administrator,
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const StaffSeatEditorRoles: RoleEnum[] = [    
    RoleEnum.Developer,
    RoleEnum.StaffSeatAdjustor
];

export const StaffSeatManagementRoles: RoleEnum[] = [
    RoleEnum.Developer,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const StaffSupportRoles: RoleEnum[] = [
    RoleEnum.Developer,
    RoleEnum.ParticipantAdmin,
    RoleEnum.StaffSeatAdjustor,
    RoleEnum.StaffSeatReadOnly
];

export const TrainingCenterRoles: RoleEnum[] = InstructorRoles;

export const TrainingRoles: RoleEnum[] = LearnerEnrolleeRoles;