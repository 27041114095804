import { useContext, useState } from "react";
import { ConfigContext } from "../configuration-context";
import { getToken } from "../helper-functions";

type ApiData<Payload> = {
    url: string,
    method: "POST" | "GET",
    payload: Payload,
}

type ApiResponse<Data> = {
    isLoading: boolean,
    fetchData: () => void,
    data: Data
}

const useApi = <Payload, Data>(props: ApiData<Payload>): ApiResponse<Data> => {
    const configContext = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState(null);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const fetchData = async () => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            setIsLoading(true);
            
            const defaultOptions = {
                method: props.method,
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
            };
            try {
                const options = props.method === 'POST' ? {
                    ...defaultOptions,
                    body: JSON.stringify(props.payload)
                } : defaultOptions;

                const response = await fetch(`${apimBaseUrl}/${props.url}`, options);
                setData(response.ok ? await response.json() : response.status);
            } catch(e) {
                // Handle error
            } finally {
                setIsLoading(false);
            }
        }
    };

    return { fetchData, isLoading, data } as const;
};

export default useApi;
