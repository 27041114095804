import * as React from "react";

import { HoneyBeeSettingDefinition } from "./Interfaces/HoneyBeeSettingDefinition";

export enum InputTypes {
    string = "text",
    int = "number",
    double = "number",
    bool = "checkbox"
}

export enum InputPatterns {
    string = "",
    int = "[0-9]*",
    double = "[0-9]+([.,][0-9]+)?",
    bool = ""
}

export function getInputProps(
    stateObj: any,
    definition: HoneyBeeSettingDefinition,
    setStateFn: (value: React.SetStateAction<any>) => void
): {
    type: InputTypes;
    pattern?: InputPatterns;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateFn({ ...stateObj, Value: e.target.value });
    };

    return { type: InputTypes[definition.DataType], pattern: InputPatterns[definition.DataType], onChange };
}

export function getDefaultValue(definition: HoneyBeeSettingDefinition): string | number | boolean {
    switch (definition.DataType) {
        case "string":
            return "";
        case "int":
        case "double":
            return "0";
        case "bool":
            return "false";
    }
}
