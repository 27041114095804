import { ApiResponse, GETWITHERROR, JsonResponseModel, POSTWITHERROR, DELETE } from ".";

import CreateLearnerModel from "../Interfaces/CreateLearnerModel";
import ExistingLearnerResultModel from "../Interfaces/ExistingLearnerResultModel";
import { template } from "../helper-functions";

const FETCH_USER_WITH_EMAIL_URL_TEMPLATE = template`/MyAccount/GetUserWithEmailAsync?email=${0}`;
const POST_REGISTER_ACCOUNT_URL = "/MyAccount/RegisterAccountAsync";
const FETCH_MFA_DEVICE_URL = "/MyAccount/GetMfaDeviceAsync";
const POST_ADD_MFA_DEVICE_URL = "/MyAccount/AddMfaDeviceAsync";
const DELETE_MFA_DEVICE_URL_TEMPLATE = template`/MyAccount/DeleteMfaDeviceAsync?deviceId=${0}`;
const POST_EMAIL_OTP_CODE_URL = "/MyAccount/EmailOtpCodeAsync";
const POST_VALIDATE_OTP_CODE_URL = "/MyAccount/ValidateOtpCodeAsync";
const POST_CHANGE_PASSWORD_URL = "/MyAccount/ChangePasswordAsync";
const FETCH_USER_PING_GROUPS_URL = template`/MyAccount/GetUserPingGroupsAsync?id=${0}`;

export async function fetchUserWithEmail(email: string): Promise<JsonResponseModel<ExistingLearnerResultModel>> {
    return GETWITHERROR(FETCH_USER_WITH_EMAIL_URL_TEMPLATE(email));
}

export async function createLearner(
    model: CreateLearnerModel
): Promise<JsonResponseModel<{ Created: boolean; Enrolled: boolean }>> {
    return POSTWITHERROR(POST_REGISTER_ACCOUNT_URL, model);
}

export async function fetchMfaDevice(): Promise<JsonResponseModel<{ DeviceId: string, Email: string}>>{
    return GETWITHERROR(FETCH_MFA_DEVICE_URL);
}

export async function addMfaDevice(emailAddress: string): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_ADD_MFA_DEVICE_URL, emailAddress );
}

export async function deleteMfaDevice(deviceId: string): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE(DELETE_MFA_DEVICE_URL_TEMPLATE(deviceId));
}

export async function emailOtpCode(emailAddress: string): Promise<JsonResponseModel<{ OtpId: string}>> {
    return POSTWITHERROR(POST_EMAIL_OTP_CODE_URL, emailAddress );
}

export async function validateOtpCode(otpId: string, otpCode: string): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_VALIDATE_OTP_CODE_URL, { otpId, otpCode });
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_CHANGE_PASSWORD_URL, { oldPassword, newPassword });
}

export async function fetchUserPingGroups(id: string): Promise<JsonResponseModel<string[]>> {
    return await GETWITHERROR(FETCH_USER_PING_GROUPS_URL(id));
}