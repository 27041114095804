import "./bulk-upload-preview.scss";

import * as React from "react";

import { BootstrappedTable, Column, textColumn } from "../../Components";
import { BulkUploadRow, OrganizationResult } from "../../Interfaces";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";


import { ErrorModel } from "../../ApiServices";
import { Link } from "react-router-dom";
import { downloadBulkUploadTemplate } from "../../ApiServices/Class";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";



interface BulkUploadPreviewProps {
    previewError?: ErrorModel;
    previewUsers: BulkUploadRow[];
}

const BulkUploadPreview = ({ previewUsers, previewError }: BulkUploadPreviewProps) => {
    const { t } = useTranslation();

    const columnHelper = createColumnHelper<BulkUploadRow>()
    const columns = React.useMemo<ColumnDef<BulkUploadRow>[]>(
        () => [
            columnHelper.accessor('Email', {
                cell: info => info.getValue(),
                header: t("Email"),
                enableSorting: true,
            }),
               
                columnHelper.accessor('FirstName', {
                cell: info => info.getValue(),
                header: t("First Name"),
                enableSorting: true,
            }),
                

                columnHelper.accessor('LastName', {
                cell: info => info.getValue(),
                header: t("LastName"),
                enableSorting: true,
                
            }),
        ],
        []
    );

    return (
        <>
            <h5>{t("Preview")}</h5>
            {previewError && (
                <Card border="danger" className="mb-4">
                    <Card.Header className="fade alert-danger show">
                        <strong>
                            {t(
                                "There was a problem generating the preview. Please review the following errors and upload the file again."
                            )}
                        </strong>
                    </Card.Header>
                    <Card.Body>
                        {previewError.Errors &&
                            previewError.Errors.map((x, i) => <Card.Text key={i}>{x.Message}</Card.Text>)}
                        {!previewError.Errors && <Card.Text>{previewError.Message}</Card.Text>}
                    </Card.Body>
                </Card>
            )}
            <div className="mb-3">
                <>
                    <RemoteTable
                        keyField=""
                        fetchData={false}
                        columns={columns}
                        defaultData={previewUsers?.slice(0, 3) ?? []}
                        defaultSorted={{ dataField: "Email", order: "desc" }}
                        enableSearch={false}

                    />
                    {previewUsers?.length > 3 && <div id="ZigZagBorder" className="w-100"></div>}
                </>
            </div>
            
        </>
    );
};

interface BulkUploadFormProps {
    validated: boolean;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    onFileChange: (e: any) => void;
    previewUsers: BulkUploadRow[];
    previewError: ErrorModel;
    userOrganizations: OrganizationResult[];
    selectedOrganization: OrganizationResult;
    onOrganizationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    previewMessageElement: JSX.Element;
    saveError: ErrorModel;
    saveErrorHeaderText: string;
    createButtonText: string;
    backToButtonText: string;
    backToButtonHref: string;
}

const BulkUploadForm = ({
    validated,
    handleSubmit,
    onFileChange,
    previewUsers,
    previewError,
    userOrganizations,
    selectedOrganization,
    onOrganizationChange,
    previewMessageElement,
    saveError,
    saveErrorHeaderText,
    createButtonText,
    backToButtonText,
    backToButtonHref
}: BulkUploadFormProps) => {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row className="mb-4">
                <Col>
                    <h4>
                        {t("Step 1")}: {t("Format Data Using Template")}
                    </h4>
                    <p>
                        {t(
                            "To ensure that your learners' information is uploaded correctly, download and follow the format in our template. Your .csv file must match this format or your upload will not work."
                        )}
                    </p>
                    <Button
                        variant="outline-primary"
                        onClick={async () => {
                            const resp = await downloadBulkUploadTemplate();
                            if (resp) {
                                saveAs(resp, "BulkUploadTemplate.csv");
                            }
                        }}
                    >
                        <i className="fas fa-download"></i> {t("Download .csv Template")}
                    </Button>
                </Col>
            </Row>

            <Row className="mb-4 pt-4">
                <Col>
                    <h4>
                        {t("Step 2")}: {t("Upload Learner List")}
                    </h4>
                    <p>
                        {t("Once you have populated the .csv file with your learners, upload it here.")}{" "}
                        <em>{t("Limit 200 learners per upload")}</em>
                    </p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ maxWidth: "1200px" }}>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                style={{
                                    width: "450px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                                required
                                id="fileUpload"
                                accept=".csv"
                                onClick={(e: any) => (e.target.value = null)}
                                onChange={onFileChange}
                            />
                            <Form.Control.Feedback type="invalid">{t("Please select a file.")}</Form.Control.Feedback>
                        </Form.Group>

                        <BulkUploadPreview previewUsers={previewUsers} previewError={previewError} />

                        {userOrganizations.length > 1 && (
                            <Form.Group controlId="organization">
                                <Form.Label>{t("Organization")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    style={{ width: "300px", display: "block" }}
                                    value={selectedOrganization.OrganizationId}
                                    onChange={onOrganizationChange}
                                >
                                    {userOrganizations.map((x) => (
                                        <option value={x.OrganizationId} key={x.OrganizationId}>
                                            {x.Name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        )}

                        {previewUsers && <p>{previewMessageElement}</p>}

                        {saveError && (
                            <Card border="danger" className="mb-4">
                                <Card.Header className="fade alert-danger show">
                                    <strong>{saveErrorHeaderText}</strong>
                                </Card.Header>
                                <Card.Body>
                                    {saveError.Errors &&
                                        saveError.Errors.length > 0 &&
                                        saveError.Errors.map((x, i) => <Card.Text key={i}>{x.Message}</Card.Text>)}
                                    {(!saveError.Errors || saveError.Errors.length === 0) && (
                                        <Card.Text>{saveError.Message}</Card.Text>
                                    )}
                                </Card.Body>
                            </Card>
                        )}

                        <Button
                            variant="primary"
                            type="submit"
                            style={{ width: "150px", minHeight: "62px", margin: "5px" }}
                        >
                            {createButtonText}
                        </Button>
                        <Button
                            as={Link}
                            to={backToButtonHref}
                            variant="light"
                            style={{ width: "150px", minHeight: "62px", margin: "5px" }}
                        >
                            {backToButtonText}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default withAITracking(reactPlugin, BulkUploadForm);
