import "./multisco-course.scss";

import * as React from "react";

import { LearnerBaseRoutes } from "../../Routing/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const MultiScoCourse = ({ launchUrl }: { launchUrl: string }) => {
    const { t } = useTranslation();

    return (
        <>
            <h5 className="multisco-course__banner">
                <Link to={LearnerBaseRoutes.Learn.fullPath} className="multisco-course__link">
                    <i className="fas fa-arrow-left"></i>
                    <span>{t("Back to My Courses")}</span>
                </Link>
                <a
                    className="text-link float-end pendo__link-helpful-tips"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                >
                    <small>{t("Helpful Tips")}</small>
                </a>
                <hr />
            </h5>
            <iframe className="multisco-course__iframe" allow="autoplay" src={launchUrl}></iframe>
        </>
    );
};

export default withAITracking(reactPlugin, MultiScoCourse);
