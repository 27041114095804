import * as React from "react";

import { Row, Col } from "react-bootstrap";
import Button from "./button";
import VideoPlayer from "../video-player";
import PopUpModal from "./modal-pop-up";
import { CSSProperties } from "react";
import useDetectWindowSize from "../../hooks/useDetectWindowSize";

interface IMaxWidth {
    mobile: string;
    tablet: string;
    laptop: string;
}

const VideoEmbed = (props: {
    thumbnailUrl:string,
    vimeoId:string,
    titleText?:string,
    descriptionText?:string;
    buttonAlignment?:CanvasTextAlign,
    modalId?:string,
    maxWidth?:IMaxWidth,
    autoplay?: boolean
}): JSX.Element => {
    props = {autoplay: false, ...props};

    const [ showVideoPlayer, setShowVideoPlayer ] = React.useState(false);

    const buttonAlignStyle:CSSProperties = (props.buttonAlignment) ? { textAlign: props.buttonAlignment } : { textAlign: 'center' };

    const closeModal = () => {
        setShowVideoPlayer(false);
    }

    const openModal = () => {
        setShowVideoPlayer(true);
    }

    const { isMobile, isLaptop, isTablet } = useDetectWindowSize();

    const videoThumbnailMaxWidth = (props:any) => {
        // this is the 'old' default value that can be used if no prop is passed down here
        if(!props.maxWidth)
            return "600px"

        if(isMobile)
            return props.maxWidth?.mobile;

        if(isTablet)
            return props.maxWidth?.tablet;
        
        if(isLaptop)
            return props.maxWidth?.laptop;
    }

    return (
        <>
            <PopUpModal modalId={(props.modalId) ? props.modalId : ""} content={<VideoPlayer autoplay={props.autoplay} vimeoId={props.vimeoId} />} show={showVideoPlayer} hide={closeModal} />

            <div className="m-auto" style={{ maxWidth: videoThumbnailMaxWidth(props) }}>
                <div className="video-embed-thumbnail-container mb-4">
                    <img role="button" className="video-embed-thumbnail-image" onClick={openModal} src={props.thumbnailUrl} />
                    <i className="fa-sharp fa-solid fa-circle-play video-embed-thumbnail-icon" onClick={openModal} />
                    <div className="video-embed-thumbnail-cover" onClick={openModal} />
                </div>

                { props.titleText && <div className="mb-2 h4">{props.titleText}</div> }
                { props.descriptionText && <div className="mb-4">{props.descriptionText}</div> }

                <div style={buttonAlignStyle}><Button label="Play Video" isSolid={false} clickAction={openModal} /></div>
            </div>
        </>
    );
};

export default VideoEmbed;
