import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";

import CertifiedInstructorHeader from "./Components/Dashboard/CIHeader/ci-header";
import Button from "../Components/UI/button";
import LinkBlock from "../Components/UI/link-block";
import ArrowLink from "../Components/UI/arrow-link";
import Tile from "../Components/UI/tile";
import Enrollment from "./Components/Dashboard/MyCertification/enrollment";
import Alert from "../Components/UI/alert";
import AlertProperties from "../Interfaces/Platform/AlertProperties";
import { Organization } from "../Interfaces/Platform/Participants";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import PreDashboard from "./Components/Dashboard/PreDashboard/pre-dashboard";
import useCustomerSupportContactInfo from "../hooks/useCustomerSupportContactInfo";
import CourseList from "./Components/online-courses/course-list";
import { fetchOrganizations } from "./Utils/participants";

import MyCertificationSummary from "./Components/Dashboard/MyCertification/my-certification-summary";
import CITaskBlock from "../Components/UI/ci-task-block";

import { RoleEnum } from "../Enums";

import { PlatformBaseRoutes, MyTrainingBaseRoutes, ExternalBaseRoutes } from "../Routing/routes";

import Certification from "./Components/Dashboard/MyCertification/certification";
import { getToken, getUserClaims } from "../helper-functions";
import useParsedTranslation from "../hooks/useParsedTranslation";
import useFeatureFlags from "../hooks/useFeatureFlags";

import ProblemLoading from "./problem-loading";

const DashboardPage = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const { rac } = useRouteAccessCheck();
    const { getSupportContactInfoByCultureCode } = useCustomerSupportContactInfo();

    const [ canAccessMyOnlineLearning ] = useFeatureFlags("ITLMyOnlineLearning", true);

    //
    // SETTING CONFIGCONTEXT
    //
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    //
    //
    //
    const [certData, setCertData] = React.useState<any[]>([]);
    const [standaloneEnrollmentData, setStandaloneEnrollmentData] = React.useState<any[]>([]);
    const [onlineCourseData, setOnlineCourseData] = React.useState<any[]>([]);
	const [orgTreeNode, setOrgTreeNode] = React.useState<Organization>();

    const [todaysDate, setTodaysDate] = React.useState(((localStorage.getItem('platformTestConfigData') && JSON.parse(localStorage.getItem('platformTestConfigData')).date) && (/*configContext?.SystemConfiguration?.ReadLocalStorageTestingConfig*/true)) ? new Date(JSON.parse(localStorage.getItem('platformTestConfigData')).date) : new Date());
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const [showPreDashboard, setShowPreDashboard] = React.useState<Boolean>(false);
    const [showLearnerDashboard, setShowLearnerDashboard] = React.useState<Boolean>(false);

    const getArrayOfCertificationIds = (certData: any[]) => {
        let certsArray: any[] = [];
        if (Array.isArray(certData) && (certData.length > 0)) {
            certData.forEach(function (cert: any) {
                certsArray.push(cert.specialty);
            })
        }

        return certsArray;
    };
	
    const getUserOrganizationTreeNode = async function () {
        const userOrgId = rbac.userContext.SelectedOrganization.toString();
        const dataFromCall: Organization[] = await fetchOrganizations(apimBaseUrl, configContext, userOrgId);
        const userOrg = dataFromCall.find((org) => org.organizationId == userOrgId);
        setOrgTreeNode(userOrg);		
	}

    const getUserOnlineLearningCourses = () => {
        if (configContext?.SystemConfiguration?.ApimKey && getUserClaims().sub) {
            fetch(apimBaseUrl + "/Q/class/onlinelearning/" + getUserClaims().sub, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
            .then(resp => resp.json())
            .then(function (data) {
                setOnlineCourseData(data.activeCourses);
            })
            .catch(function (error) {
                console.log(" :: " + JSON.stringify(error));
            });

        }
    }

    const getStandaloneEnrollments = (certData: any[]) => {
        fetch(apimBaseUrl + "/Q/Enrollment/Standalone/", {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "content-type": "application/json",
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                userId: rbac.userContext.PingId,
                specialties: getArrayOfCertificationIds(certData)
            })
        })
            .then(resp => resp.json())
            .then(function (data) {
                setStandaloneEnrollmentData(data);
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
            });
    };

    React.useEffect(() => {
      if (configContext?.SystemConfiguration?.ApimKey && rbac.userContext.PingId) {
            fetch(apimBaseUrl + "/Q/profile/Account/Role/Updated?pingUsrId=" + rbac.userContext.PingId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
            .then(resp => resp.json())
            .then(function (data) {
               if(data == true){
                fetch("/Refresh", {
                    method: "GET",                    
                })                
                .catch(function (error) {
                    console.log(" :: " + JSON.stringify(error));
                });
               }
            })
            .catch(function (error) {
                console.log(" :: " + JSON.stringify(error));
            });

        }
    }, []);


    React.useEffect(() => {
        // check for learner dashboard display
        if ((getUserClaims().rolesByName && getUserClaims().rolesByName.includes('Learner') && !getUserClaims().rolesByName.includes('Enrollee') && !getUserClaims().rolesByName.includes('CI') && !getUserClaims().rolesByName.includes('BA')) || !getUserClaims().rolesByName) {
            setShowLearnerDashboard(true);
			getUserOrganizationTreeNode();
            getUserOnlineLearningCourses();
        }

    }, [])

    React.useEffect(() => {
        // set to show predashboard if there are zero certs, more than zero standalone enrollments and the user is NOT a BA
        if ((Array.isArray(certData) && certData.length == 0) && (Array.isArray(standaloneEnrollmentData) && standaloneEnrollmentData.length > 0) && (getUserClaims().rolesByName && !getUserClaims().rolesByName.includes(RoleEnum.BusinessAdministrator))) {
            setShowPreDashboard(true);
        }
    }, [standaloneEnrollmentData, certData])

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey && rbac.userContext.PingId) {
            fetch(apimBaseUrl + "/Q/Certification/User/" + rbac.userContext.PingId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
                .then(resp => {
                    if (resp.status == 204) {
                        getStandaloneEnrollments([]);
                    } else {
                       return resp.json();
                    }
                })
                .then(function (data) {
        
                    if (Array.isArray(data) && data.length > 0) {
                        setCertData(data);
                        getStandaloneEnrollments(data);
                    }
                })
                .catch(function (error) {
                    console.log(" :: " + JSON.stringify(error));
                });

        }

    }, [configContext?.SystemConfiguration?.ApimKey, rbac.userContext.PingId]);

    const returnCertComponents = (data: any) => {

        // if we have certification data, return certification components
        // we're specifically checking for an array because the endpoint will return a string of the userId if no certs exist
        if (data && Array.isArray(data) && data.length > 0) {
            return (
                data.map((cert: any) => (
                    <Certification todaysDate={todaysDate} certificationModel={cert} addPageLevelAlert={addPageLevelAlert} />
                ))
            );
        }
    
    };

    const returnZeroCertsEnrollmentsDisplay = () => {
        return (
            <>
                <div className="mt-4">{ t('CPI.MyAccount.Dashboard.MyCertification.NotCertifiedToTrain') }</div>

                <div className="mb-4">
                    <ArrowLink data={{href: '#', label: t('CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining')}} />
                </div>
            </>
        );
    }

    const returnEnrollmentComponents = (data: any) => {

        // if we have enrollment data, return enrollment components
        // we're specifically checking for an array because the endpoint may return a string of the userId if no enrollments exist
        if (data && Array.isArray(data) && data.length > 0) {
            return (
                data.map((enrollment: any) => (
                    <>
                        <MyCertificationSummary certTitle={enrollment.programSpecialty} />
                        <CITaskBlock title={t("CPI.MyAccount.Dashboard.MyCertification.CompleteInstructorTraining")} />
                        <Enrollment todaysDate={todaysDate} enrollmentData={enrollment} addPageLevelAlert={addPageLevelAlert} />
                        <CITaskBlock title={t("CPI.MyAccount.Dashboard.MyCertification.DocumentClasses")} />
                    </>
                ))
            );
        }

    };

    const returnPageLevelAlerts = (alerts: AlertProperties[] | ((alertProps: AlertProperties) => void)) => {
        // checking to ensure datatype
        let tempArray: AlertProperties[] = [];
        if (Array.isArray(alerts)) {
            tempArray = alerts;
        }

        return (
            tempArray.map((alert: AlertProperties) => (
                <Alert state={alert.alertLevel} description={alert.description} canDismiss={alert.canDismiss} arrowLink={(alert.arrowLink) ? alert.arrowLink : null} />
            ))
        );
    };

    return (
        <>
            { !showLearnerDashboard &&
                <div>
                    <CertifiedInstructorHeader />

                    <div className="page-content m-auto">
                        <Container fluid>
                            <Row>
                                <Col lg={3}></Col>
                                <Col xs={12} lg={6}>
                                    <div className="alert-wrapper mt-4">
                                        <>
                                            {
                                                returnPageLevelAlerts(pageLevelAlerts)
                                            }
                                        </>
                                    </div>
                                </Col>
                                <Col lg={3}></Col>
                            </Row>
                            <Row>
                                { showPreDashboard &&
                                    <>
                                        <Col xs={12} lg={12} xl={8}>
                                            <Tile title={t('CPI.MyAccount.Dashboard.MyCertification.MyCertification')}>
                                                <>
                                                    {(standaloneEnrollmentData && 
                                                            showPreDashboard &&
                                                            <PreDashboard 
                                                                standaloneEnrollmentData={standaloneEnrollmentData}
                                                                returnEnrollmentComponents={() => returnEnrollmentComponents(standaloneEnrollmentData)}
                                                            />
                                                            
                                                            )
                                                    }
                                                    
                                                    {(Array.isArray(certData) && certData.length > 0) &&
                                                        returnCertComponents(certData)                                        
                                                    }
                                                    
                                                    {((!certData || !Array.isArray(certData) || (Array.isArray(certData) && (certData.length === 0))) && (!standaloneEnrollmentData || !Array.isArray(standaloneEnrollmentData) || (Array.isArray(standaloneEnrollmentData) && (standaloneEnrollmentData.length === 0)))) &&
                                                        returnZeroCertsEnrollmentsDisplay()
                                                    }   
                                                    
                                                    {!showPreDashboard && (
                                                            standaloneEnrollmentData &&
                                                                returnEnrollmentComponents(standaloneEnrollmentData)
                                                        )
                                                    }

                                                    {(certData) && Array.isArray(certData) && certData.some((cert) => { return ((cert.specialty == "NCI") || (cert.specialty == "CPI") || (cert.specialty == "VI") || (cert.specialty == "APS") || (cert.specialty == "DCC") || (cert.specialty == "CC")) }) &&

                                                        <div>
                                                            <a className="link-standard print-id-card" href="/CPI/Pages/ReprintIDCard.aspx">
                                                                <span className="mr-1 fas fa-sharp fa-light fa-print" aria-hidden="true"></span>
                                                                <span>{ t('CPI.MyAccount.Dashboard.MyCertification.PrintIdCard') }</span>
                                                            </a>
                                                        </div>

                                                    }
                                    
                                                </>
                                            </Tile>
                                        </Col>
                                    </>
                                }
                                
                                { !showPreDashboard &&
                                    <>
                                        <Col xs={12} lg={6} xl={4}>
                                            <Tile title={t('CPI.MyAccount.Dashboard.MyCertification.MyCertification')}>
                                                <>
                                                    {(standaloneEnrollmentData && 
                                                            showPreDashboard &&
                                                            <PreDashboard 
                                                                standaloneEnrollmentData={standaloneEnrollmentData}
                                                                returnEnrollmentComponents={() => returnEnrollmentComponents(standaloneEnrollmentData)}
                                                            />
                                                            
                                                            )
                                                    }
                                                    
                                                    {(Array.isArray(certData) && certData.length > 0) &&
                                                        returnCertComponents(certData)                                        
                                                    }
                                                    
                                                    {((!certData || !Array.isArray(certData) || (Array.isArray(certData) && (certData.length === 0))) && (!standaloneEnrollmentData || !Array.isArray(standaloneEnrollmentData) || (Array.isArray(standaloneEnrollmentData) && (standaloneEnrollmentData.length === 0)))) &&
                                                        returnZeroCertsEnrollmentsDisplay()
                                                    }   
                                                    
                                                    {!showPreDashboard && (
                                                            standaloneEnrollmentData &&
                                                                returnEnrollmentComponents(standaloneEnrollmentData)
                                                        )
                                                    }

                                                    {(certData) && Array.isArray(certData) && certData.some((cert) => { return ((cert.specialty == "NCI") || (cert.specialty == "CPI") || (cert.specialty == "VI") || (cert.specialty == "APS") || (cert.specialty == "DCC") || (cert.specialty == "CC")) }) &&

                                                        <div>
                                                            <a className="link-standard print-id-card" href="/CPI/Pages/ReprintIDCard.aspx">
                                                                <span className="mr-1 fas fa-sharp fa-light fa-print" aria-hidden="true"></span>
                                                                <span>{ t('CPI.MyAccount.Dashboard.MyCertification.PrintIdCard') }</span>
                                                            </a>
                                                        </div>

                                                    }
                                    
                                                </>
                                            </Tile>
                                        </Col>

                                        <Col xs={12} lg={6} xl={8}>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <Tile title={t('Dashboard.TrainingMyOrganization')}>
                                                        <>
                                                            <div className="tile-section tile-section-flex-space-between">
                                                                <div className="tile-section-content" id="planning-and-facilitation-content">
                                                                    <LinkBlock image={{ filename: "instructor-guides", extension: "png" }} label={t('Dashboard.TrainingMaterials')} href={PlatformBaseRoutes.DashboardTrainingMaterials.fullPath} />
                                                                    <LinkBlock image={{ filename: "document-training", extension: "png" }} label={PlatformBaseRoutes.DashboardClasses.title()} href={PlatformBaseRoutes.DashboardClasses.fullPath} />
                                                                </div>
                                                            </div>
                                                            <div className="tile-section mt-2 tile-section-flex-space-between">
                                                                <div className="tile-section-content" id="post-training-content">
                                                                    <LinkBlock image={{ filename: "ParticipantsTile", extension: "jpg" }} label={PlatformBaseRoutes.DashboardParticipants.title()} href={PlatformBaseRoutes.DashboardParticipants.fullPath} />
                                                                </div>
                                                            </div>
                                                            <div className="tile-section d-flex gray-border-dashed-top mt-4 justify-content-end" id="training-center-clear-button-section">
                                                                <div className="clear-button-container mt-4">
                                                                    <Button label={t('CPI.MyAccount.Dashboard.OrderMaterials.OrderMaterials')} isSolid={false} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Tile>
                                                </Col>

                                                <Col xs={12} xl={6}>
                                                    <Tile title={t('Dashboard.MyTraining')}>
                                                        <div className="tile-section" id="professional-development-links">

                                                            {(rac(MyTrainingBaseRoutes.Membership)) &&
                                                                <ArrowLink data={{ label: t("CPI.MyAccount.Dashboard.ProfessionalDevelopment.MembershipBenefits"), href: MyTrainingBaseRoutes.Membership.fullPath }} />
                                                            }                                                

                                                            {(rac(MyTrainingBaseRoutes.CertificationHistory)) &&
                                                                <ArrowLink data={{ label: MyTrainingBaseRoutes.CertificationHistory.title(), href: MyTrainingBaseRoutes.CertificationHistory.fullPath }} />
                                                            }                                

                                                            {(rac(MyTrainingBaseRoutes.MyOnlineCourses)) &&
                                                                <ArrowLink data={{ label: MyTrainingBaseRoutes.MyOnlineCourses.title(), href: MyTrainingBaseRoutes.MyOnlineCourses.fullPath }} />
                                                            }
                                                            
                                                            {(rac(ExternalBaseRoutes.EventRegistration)) &&
                                                                <ArrowLink data={{ label: ExternalBaseRoutes.EventRegistration.title(), href: ExternalBaseRoutes.EventRegistration.fullPath }} />
                                                            }

                                                        </div>
                                                    </Tile>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
            }

            { showLearnerDashboard &&
                <>
                    { canAccessMyOnlineLearning &&
                        <>
                            <CertifiedInstructorHeader />
                            <div className="page-content m-auto">
                                <Container fluid>
                                    <Row>
                                        <Col xs={12}>                                    
                                            <Tile title={t("Dashboard.MyOnlineClasses")} hideTileHeader={true}>
                                                <>
                                                    { /* If there are no online classes */ }
                                                    { onlineCourseData.length == 0 &&
                                                        <>                                                
                                                            <div className="mt-4">{tp('Dashboard.NotEnrolled', {phone: getSupportContactInfoByCultureCode(getUserClaims().culture).phone})}</div>
                                                        </>
                                                    }                                  

                                                    {/* If we have one or more online course */}
                                                    { onlineCourseData.length > 0 &&
                                                        <>
                                                <CourseList listType={{listType: "active"}} courses={onlineCourseData} organization={orgTreeNode} userCulture={getUserClaims().culture} />
                                                        </>
                                                    }      
                                                </>
                                            </Tile>                                                                        
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                    }

                    { !canAccessMyOnlineLearning &&
                        <ProblemLoading />
                    }
                </>                
            }
        </>
    );
};

export default DashboardPage;