import * as React from "react";

const FormDisplay = (props: {
    label: string,
    content: string
}): JSX.Element => {

    return (
        <>        
            <div>
                <span className="bold form-label">{props.label}</span>
                <div className="mt-1">{props.content}</div>
            </div>
        </>
    );
};

export default FormDisplay;
