import * as React from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

type QueryParam = { [key: string]: string };

export const withNavigator = (Component: any) => {
    const Wrapper = (props: any) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [searchParams] = useSearchParams();
        return (
            <Component
                location={location}
                navigate={navigate}
                params={params}
                searchParams={searchParams}
                {...props}
            />
        );
    };

    return Wrapper;
};