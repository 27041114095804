import * as React from "react";

import { Button, Form, Modal } from "react-bootstrap";

import { EMAIL_REGEX_PATTERN } from "../../constants";
import { isValidEmail } from "../../helper-functions";
import { RbacContext } from "../../rbac-context";
import { requestPasswordReset } from "../../ApiServices/Login";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface ResetPasswordModalProps {
    show: boolean;
    onSend: (email: string) => void;
    handleCancel: () => void;
}

const ResetPasswordModal = ({ show, onSend, handleCancel }: ResetPasswordModalProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [email, setEmail] = React.useState("");
    const [validated, setValidated] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setSending(true);
                await requestPasswordReset(email);
                onSend(email);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }           
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show} onHide={handleCancel}>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Create / Reset Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="email">
                        <Form.Label>{t("Email")}</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            isInvalid={validated && !isValidEmail(email)}
                            maxLength={150}
                            pattern={EMAIL_REGEX_PATTERN}
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
                            onBlur={() => setValidated(true)}
                        />
                        <Form.Text>
                            {t("Enter the email your administrator enrolled you with.")}{" "}
                            {t(
                                "Please reach out to your administrator if you do not receive an email to complete this process."
                            )}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            {t("Please enter a valid email address")}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={sending} onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={sending}>
                        {sending ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Sending")}
                            </>
                        ) : (
                            t("Send")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ResetPasswordModal);
