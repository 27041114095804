import * as React from "react";
import { useParams } from "react-router-dom";
import { Container,  } from "react-bootstrap";
import Tile from "../Components/UI/tile";
import FormDisplay from "../Components/Form/form-display";
import TwoColumnLayout from "../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../Components/UI/UX-Layout/Tiles/three-column-layout";
import ArrowLink from "../Components/UI/arrow-link";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";
import ProfileDataModel from "../Interfaces/Platform/ApimData/ProfileDataModel";
import ViewProfileModel from "../Interfaces/Platform/ApimData/ProfileDataModel";
import InstructorCertificationHistory from "./instructorCertificationHistory";
import { getToken } from "../helper-functions";
import { Trans } from "react-i18next";
import useParsedTranslation from "../hooks/useParsedTranslation";


const InstructorProfile = (

) => {
  
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const { instructorId } = useParams();
    const [certData, setCertData] = React.useState<any>(null);
    const [certHistoryData, setCertHistoryData] = React.useState<any>(null);
    const [classHistoryData, setClassHistoryData] = React.useState<any>(null);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [profileData, setProfileData] = React.useState<ViewProfileModel>({
        id: "",
        lastName:"",
        firstName:"",
        customerId:"",
        organizationId:"",
        email:"",
        title:"",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        zipCode:"",
        country:"",
        phone:""
    });

    const [organizationData, setOrganizationData] = React.useState({
        organizationName: "",
        city: "",
        state: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        phone: "",
        zipCode: "",
        accountingEmail: "" 
    });

    const { tp } = useParsedTranslation();

    const [userProfileHasLoaded, setUserProfileHasLoaded] = React.useState(false);
    const [organizationDataHasLoaded, setOrganizationDataHasLoaded] = React.useState(false);


    React.useEffect(() => {

        if (configContext?.SystemConfiguration?.ApimKey && instructorId) {
            fetch(apimBaseUrl + "/Q/Certification/User/" + instructorId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
                .then(resp => resp.json())
                .then(function (data) {
                    setCertData(data);
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                });

        }

        if (configContext?.SystemConfiguration?.ApimKey && instructorId) {
            fetch(apimBaseUrl + "/Q/profile/Account/Info/" + instructorId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
                .then(resp => resp.json())
                .then(function(data) {
                    setProfileData(data);
                    getOrgInfo(data);
                })
                .catch(function(error) {
                });   
            
        }
        /* "dab0489d-faa4-4823-83a7-2f28c4d9b7c0" "9ea5b832-8d14-4c4f-b85a-b82e83e806c2"  "c8c22557-a73d-4a84-8706-c9e4463de0f9" */
        let tempId = "9ea5b832-8d14-4c4f-b85a-b82e83e806c2";
        if (configContext?.SystemConfiguration?.ApimKey && instructorId) {
            fetch(apimBaseUrl + `/Q/enrollment/User/${instructorId}`, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
                .then(resp => resp.json())
                .then(function (data) {
                    setCertHistoryData(data);
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                });

        }
    }, [configContext?.SystemConfiguration?.ApimKey, rbac.userContext.PingId]);

    const getOrgInfo = (profileData:ProfileDataModel) => {
        if (configContext?.SystemConfiguration?.ApimKey && profileData.organizationId) {
            fetch(apimBaseUrl + "/Q/Organization/Details/" + profileData.organizationId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
            .then(resp => resp.json())
            .then(function (data) {
                setOrganizationData(data);
                setOrganizationDataHasLoaded(true);
            })
            .catch(function (error) {
                setOrganizationDataHasLoaded(true);
            });
        }
    
    }

    return (
        <>
            <div className="page-content m-auto">
                <Container fluid>
                    <Tile title={`${profileData.firstName} ${profileData.lastName}`}>
                        <ThreeColumnLayout
                            left={
                                <>
                                    {certData && certData.map((cert) => (
                                        <div>
                                            <div className="mt-3"><h5>{tp(cert.specialty)}</h5></div>
                                            <div className="my-4">
                                                <h5 className="d-inline fw-bold">{cert.status}</h5>
                                                <label className="d-inline float-right mr-3">{new Date(cert.startDate).toDateString()} - {new Date(cert.endDate).toDateString()}</label>
                                            </div>
                                            <div className="tile-title-separator dashed"></div>
                                        </div>
                                    ))
                                }
                                </>
                            }
                            center={
                                <>
                                    {profileData && (
                                        <div>
                                            <div className="mt-3"><FormDisplay label="INSTRUCTOR ID" content={profileData.customerId} /></div>
                                            <div className="mt-3"><FormDisplay label="EMAIL" content={profileData.email} /></div>
                                            <div className="mt-3"><FormDisplay label="PHONE" content={profileData.phone} /></div>
                                            <div className="mt-3"><FormDisplay label="ADDRESS" content={profileData.addressLine1 + ", " + profileData.addressLine2} /></div>
                                            <div className="mt-3"><FormDisplay label="JOB TITLE" content={profileData.title} /></div>
                                            <div className="mt-3"><FormDisplay label="ORGANIZATION / BASE OF EMPLOYMENT" content={organizationData.organizationName} /></div>
                                            <div className="mt-3"><FormDisplay label="BILLING EMAIL" content={organizationData.accountingEmail} /></div>
                                        </div>
                                    )}
                                </>
                            }
                            right={
                                <div className="float-right mr-4">
                                    <div>
                                        <div className="mt-3"><ArrowLink data={{label: "Register for Training", href: "www.google.com"}} /></div>
                                        <div className="mt-3"><ArrowLink data={{label: "Remove from Organization", href: "www.google.com"}} /></div>
                                    </div>
                                </div>
                           }
                        />
                    </Tile>
                    {certHistoryData && (
                        <Tile title="Certification History">
                            <InstructorCertificationHistory certHistoryData={certHistoryData} />
                        </Tile>
                    )}
                    {certHistoryData && (
                        <Tile title="Classes">
                            
                        </Tile>
                    )}
                </Container>
            </div>
        </>
    );
};

export default InstructorProfile;
