import { getToken } from "../../helper-functions";
import { fetchMfaDevice } from '../../ApiServices/MyAccount'
import StaffSelectModel from "../../Interfaces/StaffSelectModel";
import { getUserClaims } from "../../helper-functions";
import { UpdateAccountRequest } from "../../Interfaces/Platform/Account";
import { fetchProgramsOrganizations } from "../Components/DashboardPlatformUtils";
import { RoleEnum } from "../../Enums";

import { fetchMyProfileInfo, fetchMyCertInfo, fetchMyEnrollmentInfo, fetchOrganizationInfo, fetchAltOrganizations } from "../../ApiServices/Login";
import { fetchConfiguration } from "../../ApiServices/Configuration";

interface Payload {
    oldPassword: string;
    newPassword: string;
}

interface MfaPayload {
    activationCode: string, 
    email: string,
}

export const changePassword = async (payload: Payload) => {
    try {
        const response = await fetch("/api/MyAccount/ChangePasswordAsync", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "CsrfToken": getCsrfCookie().toString()
            },
            credentials: "same-origin",
            body: JSON.stringify(payload)
        });
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};
export const getMfaStatus = async () => {
    try {
        const response = await fetchMfaDevice();
        return response
    } catch (e) {
        // Handle fetch error
   }
};

function getCsrfCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; CsrfToken=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return "";
}

export const fetchStaffMembers = async (configContext: any): Promise<StaffSelectModel[]|undefined> => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/Q/Profile/Accounts/Members/@crisisprevention.com", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();            
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchProfile = async(apimBaseUrl: string, configContext: any, userId: string): Promise<any> => {
    try {
        const response = await fetch(apimBaseUrl + `/q/profile/Account/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

// Ensure org id comes back on profile call, if not, keep trying for 30 seconds (send null if not found)
export const fetchProfileWithOrganizationId = async (sub: string) => {
    let profile;
    let attempts = 0;

    do {
        profile = await fetchMyProfileInfo(sub);

        // mock bad data for testing
        //if (attempts < 5) { profile.organizationId = "" }
        //if (attempts == 3) { return null }

        if (!profile) {
            if (attempts >= 6) {
                return null;
            }
            await new Promise(resolve => setTimeout(resolve, 5000));
            attempts++;
        }
    } while ((!profile) && attempts < 6);
    return profile;
};

export const compileUserDataFromApim = async () => {
    const resp = getUserClaims();

    const profile = await fetchProfileWithOrganizationId(resp.sub);
    if (!profile) {
        return {};
    }

    const cert = await fetchMyCertInfo(resp.sub);
    const enrollments = await fetchMyEnrollmentInfo(resp.sub);
    const organization = !profile.organizationId ? null : await fetchOrganizationInfo(profile.organizationId);
    const altOrganizations = !profile.organizationId ? null : await fetchAltOrganizations(resp.sub, profile.organizationId);

    return {
        profile: profile,
        cert: cert,
        enrollments: enrollments,
        organization: organization,
        altOrganizations: altOrganizations
    }
};

export const fetchIsBAOfActiveCIs = async (rolesByName:string[], organizationNumber:string) => {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;

    if (rolesByName.includes(RoleEnum.BusinessAdministrator)) {
        const data = await fetchProgramsOrganizations(apimBaseUrl, config.Data?.ApimKey, [organizationNumber]);
        if (rolesByName.includes(RoleEnum.BusinessAdministrator) && data && Array.isArray(data) && data.length > 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const updateAccount = async (apimBaseUrl: string, configContext: any, data: UpdateAccountRequest) : Promise<any> => {
    const response = await fetch(apimBaseUrl + `/C/Profile/UpdateAccount`, {
        method: "POST",
        headers: {
            "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken("accessToken")}`
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) return true;
    return false;
}

// Holds rules for absolutely necessary data to allow for a usable experience.
// Currently only checks to ensure the userData object is not null/undefined.
// Things like organizationId and other basic datapoints are checked prior to this so this
// function will just be here to cover more particular scenarios.
export const userDataIsComplete = (userData: any) => {
    return (userData) ? true : false;
}