import type { Dispatch, SetStateAction, MouseEventHandler } from "react";
import FormSelectOption from "./FormSelectOption";

export const enum FiltersTemplate {
    Classes,
    Participants
}

export interface SearchParams {
    parentTrigger?: number;
    filtersTemplate?: FiltersTemplate;
    sortByOptions: FormSelectOption[];
    statusOptions?: FormSelectOption[];
    percentageRecordedOptions?: FormSelectOption[];
    programOptions:  FormSelectOption[];
    instructorOptions:  FormSelectOption[];
    expirationDateOptions: FormSelectOption[];
    lastTrainedOptions: FormSelectOption[];
    organizationOptions: FormSelectOption[];
    setSearchParams : Dispatch<SetStateAction<SelectedFilters>>;
    searchLabel?: string;
    showClearFilter: boolean;
    onSearch: () => void;
    updateFilter: (filter: string, value: string) => void;
    clearFilters: () => void;
}

export interface SearchParamsBasic {
    sortByOptions: FormSelectOption[];
    filters: SelectedFiltersBasic;
    searchLabel?: string;
    onSearch?: () => Promise<void>;
    onCreate?: () => void;
    updateFilter: (filter: string, value: string) => void;
    canCreateParticipants: boolean;
}

export interface SelectedFilters extends SelectedFiltersBasic {
    status?: string;
    percentageRecorded?: string;
    program: string;
    instructor: string;    
    expirationDate?: string;
    lastTrained?: string;  
    organization?:string;  
}

export interface SelectedFiltersBasic {
    searchTerm: string;
    sortBy: string;
}
