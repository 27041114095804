import "./toast.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { ApiResponse, JsonResponseModel } from "../ApiServices";

import { Toast } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ToastContext = React.createContext<{
        toast: (resp: string | ApiResponse | JsonResponseModel<any>, isError?: boolean) => void;
    }>({
        toast: () => {}
    });

export const ToastProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const [showToast, setShowToast] = React.useState(false);
    const [isErrorToast, setIsErrorToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");

    const toast = (resp: ApiResponse | JsonResponseModel<any>) => {
        setShowToast(true);
        if (_.isString(resp)) {
            setToastMessage(resp);
        } else if ((resp as JsonResponseModel<ApiResponse>).Data) {
            const response = resp as JsonResponseModel<any>;
            setIsErrorToast(!response.Data.Success);
            setToastMessage(response.Error ? response.Error.Message : response.Data.Message);
        } else if ((resp as JsonResponseModel<any>).Error) {
            const response = resp as JsonResponseModel<any>;
            setIsErrorToast(!!response.Error);
            setToastMessage(response.Error ? response.Error.Message : response.Data.Message);
        }
        else if ((resp as ApiResponse).Success !== undefined) {
            const response = resp as ApiResponse;
            setIsErrorToast(!response.Success);
            setToastMessage(response.Message);
        }
    };

    return (
        <ToastContext.Provider value={{ toast }}>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                animation
                className="toast-wrapper"
            >
                <Toast.Header
                    className={`${isErrorToast ? "bg-danger" : "bg-success"} text-white justify-content-between`}
                >
                    <img src="/Content/images/toast_logo.svg" style={{ height: 30 }} alt="CPI Learning Logo" />
                </Toast.Header>
                <Toast.Body className="text-break">
                    {toastMessage ??
                        `${
                            isErrorToast
                                ? t("Your request could not be processed at this time")
                                : t("Your request was processed successfully")
                            }`}
                </Toast.Body>
            </Toast>
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = () => React.useContext(ToastContext);

export interface WithToast {
    toast: (resp: string | ApiResponse | JsonResponseModel<any>) => void;
}

export const withToast = (WrappedComponent: React.ElementType<WithToast>) => {
    return (props: any) => {
        const { toast } = useToast();
        return <WrappedComponent toast={toast} {...props} />;
    };
};
