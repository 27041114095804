import * as React from "react";
import FormRadio from "../../../Interfaces/FormRadio";
import Radio from "./radio";

const Radiolist = (props: {
    label: string,
    name: string,
    id: string,
    radios: FormRadio[],
    isRequired?: boolean,
    isReadOnly?: boolean,
    isError?: boolean,
    errorLabel?: string,
    showLabel?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}): JSX.Element => {
    const defaultProps = { isReadOnly: false, showLabel: true };
    props = { ...defaultProps, ...props };

    const errorLabel = props.errorLabel ? props.errorLabel : "Default Alert Text";

    const returnRadios = (radios:FormRadio[]) => {
        return (
            radios.map((radio) => (
                <Radio onChange={props.onChange} label={radio.label} id={radio.id} name={props.name} value={radio.value} checked={radio.checked} />
            ))
        )
    }

    return (
        <>  
            {!props.isReadOnly ? (
                <div>
                    {props.showLabel && (
                        <>
                            <label className="bold form-label fs14">{props.label}</label>
                            { props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span> }
                        </>
                    )}     

                    <div className="mt-1">
                        {returnRadios(props.radios)}
                    </div>

                    { props.isError ? <span className="field-validation form-field-error small-error" id="firstName-required-label-1">{errorLabel}</span> : null }
                </div>
            ): (
                <div>
                    <label className="bold form-label fs14">{props.label}</label>
                    <p>{props.radios[0].label}</p>
                </div>
            )}
        </>
    );
};

export default Radiolist;
