import * as React from "react";
import { Container,  } from "react-bootstrap";
import Tile from "../Components/UI/tile";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";
import CustomTable from "../Components/custom-table";
import InstructorCertificateHistoryListItem from "./Components/InstructorCertificateHistory/instructor-certificate-history-list-item";
import { fetchOrganizations } from "./Utils/participants";
import Select from "../Components/Form/select";
import { fetchInstructors } from "./Components/DashboardPlatformUtils";

const InstructorCertificationHistory = (props: {
    certHistoryData?: JSX.Element[]
}): JSX.Element => {
  
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [organizationOptions, setOrganizationOptions] = React.useState([]);
    const [instructors, setInstructors] = React.useState([]);
   
    const [standaloneEnrollmentData, setStandaloneEnrollmentData] = React.useState<any>(null);

    const columns = [
        { id: "courseName", enableSorting: false, accessorKey: "Course Name" },
        { id: "seatType", enableSorting: false, accessorKey: "Seat Type" },
        { id: "date", enableSorting: false, accessorKey: "Date" },
        { id: "status", enableSorting: false, accessorKey: "Status" },
        { id: "certificate", enableSorting: false, accessorKey: "Certificate"},
    ];

    const getArrayOfCertificationIds = (certData: any[]) => {
        let certsArray: any[] = [];
        if (Array.isArray(certData) && (certData.length > 0)) {
            certData.forEach(function (cert: any) {
                certsArray.push(cert.specialty);
            })
        }

        return certsArray;
    }

    return (
        <>
            <CustomTable
                enableFilter={false}
                enableSearch={false}
                columns={columns}
                enablePaging={false}
            >
                <InstructorCertificateHistoryListItem listItems={props.certHistoryData}/>
            </CustomTable>
        </>
    );
};

export default InstructorCertificationHistory;
