import * as React from "react";
import SelectWorkbook, { Workbook } from "./select-workbook";

const SelectWorkbookList = (props: {
    id: string,
    name: string,
    label: string,
    errorLabel?: string,
    isRequired?: boolean,
    isError?: boolean,
    editable?: boolean,
    archived?: boolean,
    workbooks: Workbook[],
    selectWorkbook: (id: string) => void
}): JSX.Element => {
    props = { editable: true, ...props };
    const errorLabel = props.errorLabel ? props.errorLabel : "Default Alert Text";

    const returnWorkbooks = (workbooks: Workbook[]) => {
        return workbooks && workbooks.length > 0 && workbooks.map((workbook) => {
            // If workbook is not active and not already selected on a class, return null
            if (!workbook.active && !workbook.isSelected) {
                return null;
            }
            // Otherwise return the SelectWorkbook component
            // If it's inactive but already selected, it will still be displayed
            return (
                <SelectWorkbook
                    workbook={workbook}
                    editable={props.editable}
                    archived={props.archived}
                    selectWorkbook={props.selectWorkbook}
                />
            );
        });
    };
    return (
        <>
            <div>
                <label htmlFor={props.id} className="select-workbook-list-description bold form-label fs14">{props.label}</label>
                { props.editable && (props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span>) }
                <div className="mb-3 select-workbook-list">
                    {returnWorkbooks(props.workbooks)}
                </div>
                { props.isError ? <span className="field-validation form-field-error small-error" id="select-workbook-required-label-1">{errorLabel}</span> : null }
            </div>
        </>
    );

};

export default SelectWorkbookList;
