import * as React from "react";

import DashboardPage from "../Pages/dashboard";
import ClassesPage from "../Pages/classes";
import ClassPage from "../Pages/class";
import ClassCreatePage from "../Pages/classCreate";
import ParticipantsPage from "../Pages/participants";
import ParticipantsImportPage from "../Pages/participantsImport";
import InstructorProfile from "../Pages/instructorProfile";
import InstructorManagement from "../Pages/instructorManagement";
import ProgramMaterials from "../Pages/programClassMaterials";
import TrainingMaterials from "../Pages/trainingMaterials";
import CertificationAgreement from "../Pages/CertAgreement/certification-agreement";
import CourseSeatsPage from "../Pages/course-seats";
import PreviewCourses from "../Pages/preview-courses";
import CoursePreview from "../Pages/course-preview";

import { PlatformBaseRoutes } from "./routes";
import Page from "../Pages/page";
import { CpiRoute } from "./routes";
import ProtectedRoute from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { RoleEnum, PlatformRoles } from "../Enums";

import { ConfigContext } from "../configuration-context";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import NoAccess from "../Pages/no-access";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import useFeatureFlags from "../hooks/useFeatureFlags";
import { RbacContext } from "../rbac-context";

import Redirect from "./redirect";


const PlatformRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (

    <ProtectedRoute {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData: any,
    rac: any,
    canAccessClasses: boolean,
    canAccessParticipants: boolean,
    canViewClassDetails: boolean
) => {
    let tempArray: CpiRoute[] = [];
    let rbac = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);

    for (let route in routesData) {
        tempArray.push(PlatformBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route: any, i: number) => (

                    // map over CpiRoute[]
                    (rac(route)) ?

                    <>
                    // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                    <Route key={i} path={route.path}
                        element={
                            <PlatformRoute>

                                    <Redirect baseUrl={configContext.SystemConfiguration[route.tempBaseURLConfigKey]} path={route.tempPathURL}>
                              
                                        <div>                                
                                            {(route.pageId == "dashboardClasses" && canAccessClasses) &&
                                                <ClassesPage />
                                            }
                                            {(route.pageId == "dashboardClasses" && !canAccessClasses) &&
                                                <NoAccess />
                                            }
                                            {(route.pageId == "dashboardClass" && canViewClassDetails) &&
                                                <ClassPage />
                                            }
                                            {(route.pageId == "dashboardClass" && !canViewClassDetails) &&
                                                <NoAccess />
                                            }
                                            {route.pageId == "dashboardClassCreate" &&
                                                <ClassCreatePage/>
                                            }
                                            {(route.pageId == "dashboardParticipants" && canAccessParticipants) &&
                                                <ParticipantsPage />
                                            }
                                            {(route.pageId == "dashboardParticipants" && !canAccessParticipants) &&
                                                <NoAccess />
                                            }
                                            {route.pageId == "dashboardParticipantsImport" &&
                                                <ParticipantsImportPage />
                                            }
                                            {route.pageId == "dashboardInstructorManagement" &&
                                                <InstructorManagement />
                                            }
                                            {route.pageId == "dashboardInstructorProfile" &&
                                                <InstructorProfile />
                                            }
                                            {route.pageId == "dashboardProgramMaterials" &&
                                                <ProgramMaterials />
                                            }
                                            {route.pageId == "dashboardTrainingMaterials" &&
                                                <TrainingMaterials />
                                            }
                                            {route.pageId == "dashboardViewSeats" &&
                                                <CourseSeatsPage />
                                            }
                                            {route.pageId == "preview" &&
                                                <PreviewCourses />
                                            }
                                            {route.pageId == "coursePreview" &&
                                                <CoursePreview />
                                            }
                                        </div>

                                    </Redirect>
                           
                            </PlatformRoute>
                        }
                    />
                    <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                        }
                    />
                    </>
                    :

                    (!rbac.userContext.AgreementCriteriaMet) ?

                    <Route key={i} path={route.path}
                        element={
                            <ProtectedRoute>
                                <Redirect baseUrl={configContext.SystemConfiguration["KenticoSite"]} path={"/Platform/Dashboard"}>
                               
                                    <>
                                        {<CertificationAgreement />}
                                    </>
                          
                                </Redirect>
                            </ProtectedRoute>
                        }
                    />

                    :

                    // we do not meet the criteria for culture and should render no-access content
                    <Route key={i} path={route.path}
                        element={
                            <ProtectedRoute>
                            
                                    <>
                                        { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */}
                                        {<NoAccess />}
                                    </>
                              
                            </ProtectedRoute>
                        }
                    />
            ))}
        </>
    );
};

const PlatformRouting = () => {
    const { rac } = useRouteAccessCheck();
    const [canAccessClasses] = useFeatureFlags("ITLAccessListOfClasses", true);
    const [canViewClassDetails] = useFeatureFlags("ITLViewClassDetails", true);
    const [canAccessParticipants] = useFeatureFlags("ITLAccessListOfParticipants", true);

    return (
        <Routes>
            {createRoutes(PlatformBaseRoutes, rac, canAccessClasses, canAccessParticipants, canViewClassDetails)}
        </Routes>
    );
};

export default withAITracking(reactPlugin, PlatformRouting);

