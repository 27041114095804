import { useContext, useState } from "react";
import { ConfigContext } from "../configuration-context";
import { getToken } from "../helper-functions";

const useCurriculumApi = (url: string, method: 'POST' | 'GET', payload?: any) => {

    const configContext = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(false);

    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    
    const fetchData = async (payloadFetch?: any) => {
        const payloadToSend = payload ?? payloadFetch
        if (configContext?.SystemConfiguration?.ApimKey) {
            setIsLoading(true);
            
            const defaultOptions = {
                method,
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
            };
            try {
                const options = method === 'POST' ? {
                    ...defaultOptions,
                    body: JSON.stringify(payloadToSend)
                } : defaultOptions;

                const response = await fetch(`${apimBaseUrl}/${url}`, options);

                if (response.status == 200) {
                    const jsonResponse = await response.json();
                    return { ...jsonResponse } as const
                } 

                else if (response.status == 204) {
                    return { data: [], totalResults: 0 } as const
                }
                
                return { data: [], totalResults: 0 } as const

            } catch(e) {
                // Handle error
            } finally {
                setIsLoading(false);
            }
        }
    };
    return { fetchData, isLoading } as const;
};

export default useCurriculumApi;
