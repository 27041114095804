import * as React from "react";

import { Route, Routes } from "react-router-dom";

import { AdminBaseRoutes, CpiRoute } from "./routes";
import CourseLanguagesPage from "../Pages/course-languages";
import ManageParticipantsPage from "../Pages/manage-participants";
import ManageCoursesPage from "../Pages/manage-courses";
import CourseFormPage from "../Pages/course-form";
import ManageSeatsPage from "../Pages/manage-seats";
import SeatUsageHistoryPage from "../Pages/seat-usage-history";
import ManageSkusPage from "../Pages/manage-skus";
import OrgSettingsPage from "../Pages/org-settings";
import Page from "../Pages/page";
import ProtectedRoute, { ProtectedRouteProps } from "./protected-route";
import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import NoAccess from "../Pages/no-access";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import AdminPage from "../Pages/Admin/admin";
import { StaffAndCourseRoles } from "../Enums";

const AdminRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (
    <ProtectedRoute roles={StaffAndCourseRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const getAdminPageControl = (route: CpiRoute) => {
    return (
        <AdminRoute>
           
                <div>
                    {route.pageId == "courseLanguages" &&
                        <CourseLanguagesPage />
                    }
                    {route.pageId == "manageParticipants" &&
                        <ManageParticipantsPage />
                    }
                    {route.pageId == "manageParticipantsParticipant" &&
                        <ManageParticipantsPage />
                    }
                    {route.pageId == "manageSkus" &&
                        <ManageSkusPage />
                    }
                    {route.pageId == "organizationSettings" &&
                        <OrgSettingsPage />
                    }
                    {route.pageId == "admin" &&
                        <AdminPage />
                    }
                    {route.pageId == "manageSeats" &&
                        <ManageSeatsPage />
                    }
                    {route.pageId == "ManageSeatsOrg" &&
                        <ManageSeatsPage />
                    }
                    {route.pageId == "ManageSeatsUsage" &&
                        <SeatUsageHistoryPage />
                    }
                    {route.pageId == "manageCourses" &&
                        <ManageCoursesPage />
                    }
                    {route.pageId == "manageCoursesCreate" &&
                        <CourseFormPage />
                    }
                    {route.pageId == "manageCoursesEdit" &&
                        <CourseFormPage />
                    }
                </div>
       
        </AdminRoute>
    );
}

const createRouteWithChildren = (route: CpiRoute, key: number) => {    
    if (!route.children || route.children.length == 0) { //If no children, render the route.      
        return (<>
            <Route key={key} path={route.path}
                element={
                    getAdminPageControl(route)
                }
            />
        </>);
    }
    //otherwise (so, there are children), let's recurse.'
    return (<>
        <Route key={key} path={`${route.path}/*`} element={
            <Routes>
                <Route key="0" path="" element={
                    getAdminPageControl(route)
                } />
                {route.children.map((child: any, i: number) => (
                    (child.children && child.children.length > 0) ?
                        createRouteWithChildren(child, i)
                        :
                        <Route key={i + 1} path={child.path} element={
                            getAdminPageControl(child)
                        } />
                    ))}
            </Routes>
        } />
    </>);
}

const createRoutes = (
    routesData: any,
    rac: any
) => {
    let tempArray: CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(AdminBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route: any, i: number) => (
                // map over CpiRoute[]
                (rac(route)) ?
                
                    // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                    createRouteWithChildren(route, i)   ||
                    <>  
                        <Route path="*"
                            element={
                                <ProtectedRoute>
                                    {<NoAccess />}
                                </ProtectedRoute>                                
                            }
                        />
                    </>                 
                :
                // we do not meet the criteria for culture and should render no-access content
                <Route key={i} path={route.path}
                    element={
                        <ProtectedRoute>
                           
                                <>
                                    { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */}
                                    {<NoAccess />}
                                </>
                          
                        </ProtectedRoute>
                    }
                />
            ))}
        </>
    );
};

const AdminRouting = () => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>
            {createRoutes(AdminBaseRoutes, rac)}
        </Routes>
    );
};

export default withAITracking(reactPlugin, AdminRouting);
