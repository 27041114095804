import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createReminderSetting, updateReminderSetting } from "../../ApiServices/OrganizationSettings";

import { RbacContext } from "../../rbac-context";
import { ReminderSettingModel } from "../../Interfaces";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';


interface CreateReminderSettingFormProps {
    isCreateForm?: boolean;
    existingSetting?: ReminderSettingModel;
    isVisible: boolean;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
    onHide: () => void;
    existingSettings: ReminderSettingModel[];
}

const ReminderSettingForm = ({
    existingSetting,
    isCreateForm,
    isVisible,
    onSave,
    onHide,
    existingSettings
}: CreateReminderSettingFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [reminderSetting, setReminderSetting] = React.useState<ReminderSettingModel>(
        isCreateForm
            ? {
                  ReminderSettingId: -1,
                  ReminderInterval: 0,
                  EmailTemplateId: null,
                  Active: true,
                  CreateDate: ""
              }
            : existingSetting
    );
    const [validReminderInterval, setValidReminderInterval] = React.useState(true);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        try {
            const validReminderInterval = !existingSettings.some(
                (setting) =>
                    setting.ReminderInterval === reminderSetting.ReminderInterval &&
                    setting.ReminderSettingId !== reminderSetting.ReminderSettingId
            );
            setValidReminderInterval(validReminderInterval);
            if (form.checkValidity() && validReminderInterval) {
                setSaving(true);
                const resp = isCreateForm
                    ? await createReminderSetting(reminderSetting)
                    : await updateReminderSetting(reminderSetting);
                setSaving(false);
                onSave(resp);
            }

            setValidated(true);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }
        
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Create Reminder")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group as={Row} controlId="interval">
                        <Form.Label column sm="2">
                            {t("Reminder Interval")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                type="number"
                                pattern="[0-9]*"
                                isInvalid={!validReminderInterval}
                                value={reminderSetting.ReminderInterval}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setReminderSetting({
                                        ...reminderSetting,
                                        ReminderInterval: parseInt(e.target.value)
                                    })
                                }
                            />
                            {!validReminderInterval && validated && (
                                <Form.Control.Feedback type="invalid">
                                    {t("A setting with this reminder interval already exists.")}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="template">
                        <Form.Label column sm="2">
                            {t("SendGrid Template")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                maxLength={255}
                                value={reminderSetting.EmailTemplateId}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setReminderSetting({ ...reminderSetting, EmailTemplateId: e.target.value })
                                }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="active">
                        <Form.Label column sm="2">
                            {t("Active")}
                        </Form.Label>
                        <Col>
                            <Form.Check
                                custom
                                type="switch"
                                id="active"
                                label=" "
                                className="col-form-label"
                                checked={reminderSetting.Active}
                                onChange={() =>
                                    setReminderSetting({ ...reminderSetting, Active: !reminderSetting.Active })
                                }
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ReminderSettingForm);
