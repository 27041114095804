import * as React from "react";
import Button from "./button";
import EnrollmentClassType from "../../Interfaces/Platform/EnrollmentClassType";
import EnrollmentListingMonthDateYear from "../../Interfaces/Platform/EnrollmentListingMonthDateYear";
import OnlineClassEstimatedTime from "../../Interfaces/Platform/OnlineClassEstimatedTime";

import useParsedTranslation from "../../hooks/useParsedTranslation";
import useMonthLabelByMonthCode from "../../hooks/useMonthLabelByMonthCode";

import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const EnrollmentListingOnline = (props: {
    enrollmentData:any,
    eventData:any,
    addPageLevelAlert:any,
    todaysDate:any
}) => {
    // REFERENCE https://crisisprevention.invisionapp.com/console/Certified-Instructor---Revised-ckrp5a6sz03mb0171b7kg1edg/ckzcudwd202ct010h7qnj6lxt/play

    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const { month } = useMonthLabelByMonthCode();

    const todaysDate = props.todaysDate;
    const todaysDateInDays = Math.ceil(todaysDate.getTime() / 1000 / 60 / 60 / 24);
    const startDate = new Date(props.eventData.startDate);
    const startDateInDays = Math.ceil(startDate.getTime() / 1000 / 60 / 60 / 24);
    const endDate = new Date(props.eventData.endDate);

    const withinTenDaysOfStart = (todaysDateInDays + 10) >= startDateInDays;
    const beforeTenDayWindow = (todaysDateInDays + 10) < startDateInDays;

    const thumbnailStyleClass = (props.enrollmentData.programSpecialty) ? props.enrollmentData.programSpecialty.toLowerCase() : "default";

    React.useEffect(() => {
        if (props.eventData.startDate && props.todaysDate) {
            const startDate = new Date(props.eventData.startDate);
            const startDateInDays = Math.ceil(startDate.getTime() / 1000 / 60 / 60 / 24);

            const todaysDate = props.todaysDate
            const todaysDateInDays = Math.ceil(todaysDate.getTime() / 1000 / 60 / 60 / 24);

            const withinThreeDaysOfStart = (todaysDateInDays + 3) >= startDateInDays;
            const isOverdue = (todaysDateInDays + 3) > (startDateInDays + 3);

            if (withinThreeDaysOfStart && !isOverdue) {
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CourseListing.StartCourse") }, alertLevel: {alertLevel:"warning"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Enrollment.OnlineCourse.Warning"
                values={{ string0: t('dynamicDate', { val: startDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }} />, canDismiss: true });
            } else if (isOverdue) {          
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CourseListing.StartCourse") }, alertLevel: {alertLevel:"error"}, description: tp("CPI.MyAccount.Dashboard.Enrollment.OnlineCourse.Error"), canDismiss: false });
            }
        }
    }, [props.eventData.startDate, props.todaysDate])     

    return (
        <div>
           <div id="complete-cert-training-container">
                <div className="courseListing-container d-flex justify-content-start">

                    <div className="mr-2 position-relative">
                        
                        { (props.enrollmentData.status && props.enrollmentData.status.toLowerCase() == "confirmed-passed") &&
                            <div className="task-banner-tag-container">
                                <div className="task-banner"></div>
                            </div>
                        }                        

                        <div className="enrollment-listing-tile gray-border-thin border-radius-small page-title enrollment-listing-width-narrow">
                            <div className={"onlineCourse-thumb h-100 w-100 " + thumbnailStyleClass}></div>
                        </div>
                    </div>

                    <div className="w100">
                        <div className="d-flex mb-1">
                        <div className="icon-before-display"></div>
                            <div className="ml-1 sub-section-heading bold">{"ONLINE CLASS"}</div>
                        </div>
                        <div>{ tp(props.enrollmentData.seatSpecialty) }</div>

                        { ((props.enrollmentData.status && props.enrollmentData.status.toLowerCase() !== "confirmed-passed") && withinTenDaysOfStart) &&
                            <div className="pt-1">
                                <div className="bold my-2">{"Complete By " + t('dynamicDate', { val: startDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }</div>                                                    
                                <Button label="Start Course" isSolid={true} additionalStyleClasses="mt-1" />
                            </div>           
                        }

                        { (props.enrollmentData.examScore || (props.enrollmentData.status && props.enrollmentData.status.toLowerCase() == "confirmed-passed")) &&
                            <div className="pt-1">
                                <div className="bold my-2">{"Completed on " + t('dynamicDate', { val: endDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }</div>        
                                <Button label="Review Course" isSolid={false} additionalStyleClasses="mt-1" />
                            </div>    
                        }

                        { (beforeTenDayWindow) &&
                            <div>{t("Before-10-Day-Window")}</div>
                        }
                        
                    </div>
                </div>                
            </div>
        </div>
    )
};

export default EnrollmentListingOnline;