import * as React from "react";
import { Container,  } from "react-bootstrap";
import Tile from "../Components/UI/tile";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";
import CustomTable from "../Components/custom-table";
import InstructorListItem from "./Components/InstructorManagement/instructor-list-item";
import { fetchOrganizations } from "./Utils/participants";
import Select from "../Components/Form/select";
import { fetchInstructors } from "./Components/DashboardPlatformUtils";

const InstructorManagement = (

) => {
  
    const configContext = React.useContext(ConfigContext);
    const rbacContext = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [organizationOptions, setOrganizationOptions] = React.useState([]);
    const [instructors, setInstructors] = React.useState([]);

    const columns = [
        { id: "instructorName", enableSorting: false, accessorKey: "Instructor Name" },
        { id: "certification", enableSorting: false, accessorKey: "Certification" },
        { id: "dates", enableSorting: false, accessorKey: "Dates" },
        { id: "status", enableSorting: false, accessorKey: "Status" },
        { id: "details", enableSorting: false, accessorKey: ""},
    ];

    const getOrganizations = async () => {
        const data = await fetchOrganizations(apimBaseUrl, configContext, rbacContext.userContext.SelectedOrganization.toString());
        setOrganizationOptions(data.map((organization: any) => ({ label: organization.organizationName, value: organization.organizationId })));

        if(data) getInstructors(data[0].organizationId);
    };

    const getInstructors = async (organizationId) => {
        const { instructors } = await fetchInstructors(apimBaseUrl, configContext, organizationId);
        setInstructors((prevState) => [...instructors]);
    };

    const returnOrgs = (orgs: any[]) => {
        return orgs && orgs.length > 0 ? orgs.map(({ label, value }) => ({
            label,
            value
        })) : [];
    };

    React.useEffect(() => {
        if(organizationOptions.length === 0) {
            getOrganizations();
        }
    }, [configContext?.SystemConfiguration?.ApimKey, rbacContext.userContext.PingId, instructors]);

    const onOrgSelectChange = (value) => {
        getInstructors(value);
    }

    return (
        <>
            <div className="instructor-management-container page-content m-auto">
                <Container fluid>
                    <h1>Instructor Management</h1>
                    <div className="selectInputWrapper">
                        <Select 
                            id="organizationSelect"
                            name="organizationSelect"
                            label=""
                            options={returnOrgs(organizationOptions)}
                            changeAction={(e) => onOrgSelectChange(e.target.value)}
                        />
                    </div>
                    <Tile title="Instructors">
                        <CustomTable
                            enableFilter={false}
                            enableSearch={false}
                            columns={columns}
                            enablePaging={false}
                        >
                            <InstructorListItem listItems={instructors}/>
                        </CustomTable>
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default InstructorManagement;
