import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";
import Accordion from "../../Components/UI/accordion";


import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import VideoEmbed from "../../Components/UI/video-embed";

const VISecondEdition = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">

                <Container fluid className="page-padding">

                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1 title-margin">{t('VI2E-Title')}</h1>
                                    <p>{t('VI2E-Intro')}</p>

                                    <Tile title={t('VI2E-IntroSection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        {tp('VI2E-IntroSection-Content1')}
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Introduction Video" vimeoId={t('VI2E-IntroSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-Introduction.png" />
                                                                {tp('VI2E-IntroSection-Content2')}

                                                            </>
                                                        </GrayTile>

                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-CDMSection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                                        <SixBySix
                                                            left={
                                                                <>
                                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                    <img className="img-fluid mt-2 mb-2" src="https://cdn.crisisprevention.com/media/training-center/images/Crisis-Development-Model.png" alt="The Crisis Development Model" />

                                                                </>
                                                            }

                                                            right={
                                                                <>
                                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                    {tp('VI2E-CDMSection-Content2')}
                                                                    <h3 className="sub-headings-h3">{t('VI2E-AvailableMaterials')}</h3>
                                                                    <ArrowLink data={{ label: t('VI2E-CDMLink'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/Crisis-Development-Model.pdf", openInNewTab: true }} />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            }
                                                        />

                                                        <h3 className="sub-headings-h3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={tp('VI2E-CDMSection-Content2')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CDMSection-Activity1-Content1')}

                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CDMSection-Activity1-Content2')}

                                                                        </>
                                                                    }
                                                                />


                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-CDMSection-Activity2-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CDMSection-Activity2-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CDMSection-Activity2-Content2')}

                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Crisis Development Model Video" vimeoId={t('VI2E-IntroSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-CDM.png" />
                                                                {tp('VI2E-CDMSection-Content1')}

                                                            </>
                                                        </GrayTile>
                                                    </>
                                                }
                                            />
                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-IESection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        <SixBySix
                                                            left={
                                                                <>
                                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                    <img className="img-fluid mt-2 mb-2" src="https://cdn.crisisprevention.com/media/training-center/images/Integrated-Experience.png" alt="Integrated Experience" />

                                                                </>
                                                            }

                                                            right={
                                                                <>
                                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                    {tp('VI2E-IESection-Content1')}
                                                                    <h3 className="sub-headings-h3">{t('VI2E-AvailableMaterials')}</h3>
                                                                    <ArrowLink data={{ label: t('VI2E-IESection-Title'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/IntegratedExperience.pdf", openInNewTab: true }} />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            }
                                                        />


                                                        <h3 className="sub-headings-h3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={t('VI2E-IESection-Activity1-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity1-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity1-Content2')}
                                                                        </>
                                                                    }
                                                                />


                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-IESection-Activity2-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <OneColumnLayout
                                                                    center={
                                                                        <>
                                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity2-Content1')}
                                                                        </>
                                                                    }
                                                                />

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity2-Content2')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity2-Content3')}

                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-IESection-Activity3-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity3-Content1')}

                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity3-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-IESection-Activity4-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity4-Content1')}


                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity4-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-IESection-Activity5-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity5-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-IESection-Activity5-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Integrated Experience Video" vimeoId={t('VI2E-IESection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-IntegratedExperience.png" />
                                                                {tp('VI2E-IESection-Content2')}
                                                            </>
                                                        </GrayTile>

                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-CSSection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        {tp('VI2E-CSSection-Content1')}
                                                        <h3 className="sub-headings-h3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={t('VI2E-CSSection-Activity1-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity1-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity1-Content2')}
                                                                        </>
                                                                    }
                                                                />


                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-CSSection-Activity2-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity2-Content1')}

                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity2-Content2')}

                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-CSSection-Activity3-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity3-Content1')}

                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity3-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-CSSection-Activity4-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity4-Content1')}


                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-CSSection-Activity4-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Communication Skills Video" vimeoId={tp('VI2E-CSSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-CommunicationSkills.png" />
                                                                {tp('VI2E-CSSection-Content2')}

                                                            </>
                                                        </GrayTile>

                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-DBSection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        <SixBySix
                                                            left={
                                                                <>
                                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                    <img className="img-fluid mt-2 mb-2" src="https://cdn.crisisprevention.com/media/training-center/images/Verbal-Escalation-Continuum.png" alt="Responding to Defensive Behaviors" />

                                                                </>
                                                            }

                                                            right={
                                                                <>
                                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                    {tp('VI2E-DBSection-Content1')}

                                                                    <h4 className="h4">{t('VI2E-AvailableMaterials')}</h4>
                                                                    <ArrowLink data={{ label: t('VI2E-DBSection-Link1'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/VerbalEscalationContinuum.pdf", openInNewTab: true }} />
                                                                    <ArrowLink data={{ label: t('VI2E-DBSection-Link2'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/LimitSetting.pdf", openInNewTab: true }} />
                                                                    <ArrowLink data={{ label: t('VI2E-DBSection-Link3'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/FrameworkDifficultConversation.pdf", openInNewTab: true }} />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            }
                                                        />


                                                        <h3 className="sub-headings-h3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={t('VI2E-DBSection-Activity1-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity1-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity1-Content2')}
                                                                        </>
                                                                    }
                                                                />


                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-DBSection-Activity2-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <OneColumnLayout
                                                                    center={
                                                                        <>
                                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity2-Content1')}
                                                                        </>
                                                                    }
                                                                />

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity2-Content2')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity2-Content3')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-DBSection-Activity3-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity3-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity3-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-DBSection-Activity4-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity4-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity4-Content2')}
                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                        <Accordion
                                                            title={t('VI2E-DBSection-Activity5-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity5-Content1')}


                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-DBSection-Activity5-Content1')}


                                                                        </>
                                                                    }
                                                                />

                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Responding to Defensive Behaviors Video" vimeoId={tp('VI2E-DBSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-DefensiveBehaviors.png" />
                                                                {tp('VI2E-DBSection-Content2')}

                                                            </>
                                                        </GrayTile>

                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={tp('VI2E-SISection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        {tp('VI2E-SISection-Content1')}
                                                        <h3 className="sub-headings-h3 mt-3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={t('VI2E-SISection-Activity1-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-SISection-Activity1-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-SISection-Activity1-Content2')}
                                                                        </>
                                                                    }
                                                                />
                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}



                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-PCSection-Title')}
                                        hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}

                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        <SixBySix
                                                            left={
                                                                <>
                                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                    <img className="img-fluid mt-2 mb-2" src="https://cdn.crisisprevention.com/media/training-center/images/Coping-Model.png" alt="COPING Model" />

                                                                </>
                                                            }

                                                            right={
                                                                <>
                                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                    {tp('VI2E-PCSection-Content1')}
                                                                    <h4 className="h4">{t('VI2E-AvailableMaterials')}</h4>
                                                                    <ArrowLink data={{ label: t('VI2E-PCSection-Link1'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Critical-Incident-Reporting.pdf", openInNewTab: true }} />
                                                                    <ArrowLink data={{ label: t('VI2E-PCSection-Link2'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Incident-Report-Form-Template.pdf", openInNewTab: true }} />
                                                                    <ArrowLink data={{ label: t('VI2E-PCSection-Link3'), href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/VI/CopingModel.pdf", openInNewTab: true }} />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            }
                                                        />


                                                        <h3 className="sub-headings-h3">{t('VI2E-SupplementalActivities')}</h3>
                                                        <Accordion
                                                            title={t('VI2E-PCSection-Activity1-Title')}
                                                            isOpen={false}
                                                            isComplete={false}
                                                            children={<>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <SixBySix
                                                                    left={
                                                                        <>
                                                                            {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-PCSection-Activity1-Content1')}
                                                                        </>
                                                                    }

                                                                    right={
                                                                        <>
                                                                            {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                                            {tp('VI2E-PCSection-Activity1-Content2')}
                                                                        </>
                                                                    }
                                                                />


                                                            </>}
                                                        />
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}

                                                                <VideoEmbed title="Post-Crisis Video" vimeoId={t('VI2E-PCSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-Postvention.png" />
                                                                {tp('VI2E-PCSection-Content2')}

                                                            </>
                                                        </GrayTile>

                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>
                                    <Tile title={t('VI2E-CASection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}


                                            <EightByFour
                                                left={
                                                    <>
                                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                        {tp('VI2E-CASection-Content1')}
                                                        <h3 className="sub-headings-h3">{t('VI2E-AvailableMaterials')}</h3>
                                                        {tp('VI2E-CASection-Content2')}

                                                        
                                                        <ArrowLink data={{ label: t('VI2E-English'), href: "https://cdn.crisisprevention.com/media/training-center/Intervention Checklists/YCMAS20VBI_Verbal-intervention-checklist.pdf", openInNewTab: true }} />
                                                        <ArrowLink data={{ label: t('VI2E-French'), href: "https://cdn.crisisprevention.com/media/training-center/Intervention Checklists/PDF_Verbal_Intervention_Checklist_French.pdf", openInNewTab: true }} />
                                                    
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}


                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>

                                    <Tile title={t('VI2E-CMSection-Title')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            {tp('VI2E-CMSection-Content1')}

                                            <ArrowLink data={{ label: t('VI2E-CMSection-Link1'), href: "", openInNewTab: true }} />

                                        </>
                                    </Tile>
                                </>
                            </>
                        }
                    />

                </Container>
            </div >

        </>
    );
};

export default VISecondEdition;
