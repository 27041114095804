import * as React from "react";

import { Container, Row, Col } from "react-bootstrap";

import { WithTranslation, withTranslation } from "react-i18next";
import { fetchEnrollment } from "../Utils/enrollment";
import { formatDate } from "../../locale-utils";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface ParticipantEnrollmentDetailsProps extends WithTranslation {
    course: any;
    configContext: any;
    apimBaseUrl: string;
}

interface ParticipantEnrollmentDetailsState {
    course: any;
    enrollment: any;
}

class ParticipantEnrollmentDetails extends React.Component<ParticipantEnrollmentDetailsProps, ParticipantEnrollmentDetailsState> {
    constructor(props: ParticipantEnrollmentDetailsProps) {
        super(props);
        this.state = {
            course: null,
            enrollment: null
        };
    }

    componentDidMount = async () => {
        this.setInitialData();
    };

    componentDidUpdate = async () => {
        if (this.props.course.learningHistoryId !== this.state.course.learningHistoryId) {
            this.setInitialData();
        }
    };

    setInitialData = async () => {
        this.setState({ course: this.props.course });
        if (this.props.course && this.props.course.ceEnrollmentId) {
            this.setState({ enrollment: await fetchEnrollment(this.props.apimBaseUrl, this.props.configContext, this.props.course.ceEnrollmentId) });
        }
        else {
            this.setState({ enrollment: null });
        }
    }

    render() {
        const {
            course,
            enrollment
        } = this.state;

        const { t } = this.props;

        return (
            <Container className="p-0">
                <h5 className="mb-3">
                    <strong>{t("Enrollment Details")}</strong>
                </h5>
                <Row>
                    <Col>
                        <strong>{t("Event")}:</strong> {enrollment?.eventNumber ?? t("Not Applicable")}
                    </Col>
                    <Col>
                        <strong>{t("Enrollment")}:</strong> {enrollment?.enrollmentNumber ?? t("Not Applicable")}
                    </Col>
                    <Col>
                        <strong>{t("Enrollment Date")}:</strong> {formatDate(course?.enrolledDate)}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>{t("Class Date")}:</strong> {formatDate(course?.ClassDate) ?? t("Not Applicable")}
                    </Col>
                    <Col>
                        <strong>{t("Total Time")}:</strong> {course?.TotalTimeString}
                    </Col>
                    <Col>
                        <strong>{t("Score")}:</strong> {course?.examScore ?? "--"}
                    </Col>
                </Row>
            </Container>
        );
    }
}

//export default withAITracking(reactPlugin, ParticipantEnrollmentDetails);
export default withAITracking(reactPlugin, withTranslation()(ParticipantEnrollmentDetails));
