import * as React from "react";

const ClassParticipantStatus = (props: { date: string; status: string, locale: string }): JSX.Element => {
    const formattedDate = new Date(props.date).toLocaleString(props.locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
    return (
        <>
            {props.status === "pass" && (
                <>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <i aria-label="Pass Icon" className="fa-light fa-sharp fa-check mr-2 class-participant-listing-status pass" />
                        <span className="bold participant-listing-text text-capitalize">{props.status}</span>
                    </div>
                    {props.date && <p className="small participant-listing-text">{formattedDate}</p>}
                </>
            )}
            {props.status === "fail" && (
                <>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <i aria-label="Fail Icon" className="fa-light fa-sharp fa-xmark mr-2 class-participant-listing-status fail" />
                        <span className="bold participant-listing-text text-capitalize">{props.status}</span>
                    </div>
                    {props.date && <p className="small participant-listing-text">{formattedDate}</p>}
                </>
            )}
        </>
    );
};

export default ClassParticipantStatus;
