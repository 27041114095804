import React, { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import DebouncedInput from './debounced-input';

import './remote-table.scss'

import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getSortedRowModel,
    SortingState,
    ColumnSort,
    getPaginationRowModel,
    Row
} from '@tanstack/react-table'

import { DefaultSortedField, Renderer } from "./bootstrapped-table";

import { PagedRows } from "../ApiServices";
import { formatDate } from '../locale-utils';

export interface TableProps {
    fetchFunction?: any;
    fetchData?: boolean;
    fetchParameters?: any[];
    queryKey?: string;
    keyField?: string;
    columns: ColumnDef<any>[];
    expandRow?: boolean | Renderer;
    defaultSorted?: DefaultSortedField;     
    forceRefresh?: boolean;
    defaultData?: any[];
    enablePaging?: boolean;
    enableSearch?: boolean;
    pageNumber?: number;
    pageSize?: number;
    isLoading?: boolean
    handleTotalResults?: (totalRecords: number) => void
}


interface PagingOptions {
    searchTerm: string,
    sortBy: string,
    pageNumber: number,
    resultsPerPage: number
}

const defaultProps : TableProps = {
    fetchFunction: [],
    fetchData: true,
    fetchParameters: [],
    queryKey: "default",
    columns: [],
    defaultData: [],
    enablePaging: true,
    enableSearch: true,
    defaultSorted: {dataField: "", order: "asc"}
};

function Table(props: TableProps) {

    const MAXITEMS = 100000

    props = { ...defaultProps, ...props };

    const minPageIndex = props.fetchData ? 1 : 0;

    const [pagination, setPagination] =
        React.useState<PaginationState>({
            pageIndex: minPageIndex,
            pageSize: props.enablePaging ? 10 : MAXITEMS,
        });
    
    const [fetchDataOptions, setFetchDataOptions] = React.useState<PagingOptions>(
        {
            searchTerm: "",
            sortBy: "",
            pageNumber: pagination.pageIndex,
            resultsPerPage: pagination.pageSize,
            sortDirection: props.defaultSorted?.order
        });

    const [totalRecords, setTotalRecords] = React.useState<number>(0);

    async function fetchData(): Promise<PagedRows<any>>{
        if (props.fetchData && props.fetchFunction) {
            const { data, totalResults } = await props.fetchFunction(fetchDataOptions);
            if(props.handleTotalResults){
                props?.handleTotalResults(totalResults ?? 0);
            }
            setTotalRecords(totalResults ?? 0);
            const pagedRows: PagedRows<any> = {
                rows: data ?? [],
                pageCount: Math.ceil(totalResults / fetchDataOptions.resultsPerPage) ?? 0
            }
            return pagedRows;
        }
        return { rows: [], pageCount: 0 };
    }

    
    const dataQuery = useQuery(
        [props.queryKey, fetchDataOptions], () => fetchData(), { keepPreviousData: false }
    ); 


    const updatePage = (pageIndex: number, pageSize: number) => {
        if(props.fetchData) {
            setFetchDataOptions({
                ...fetchDataOptions, 
                resultsPerPage: pageSize ?? fetchDataOptions.resultsPerPage, 
                pageNumber: pageIndex ?? fetchDataOptions.pageNumber 
            });
        }
        setPagination({pageIndex, pageSize});
    }

    const [refreshState, setRefreshState] = React.useState(props.forceRefresh);

    React.useEffect(() => {
        if (props.fetchData && refreshState != props.forceRefresh) {
            dataQuery.refetch();
            setRefreshState(props.forceRefresh);
        }
    });

    const [globalFilter, setGlobalFilter] = React.useState('');

    const [sorting, setSorting] = React.useState<SortingState>([]);

    const columns = props.columns;
    
    const table = useReactTable({
        data: props.fetchData ? dataQuery.data?.rows ?? props.defaultData : props.defaultData,
        columns,
        pageCount: props.fetchData ? dataQuery.data?.pageCount ?? -1 : Math.ceil((props.defaultData?.length ?? 1) / pagination.pageSize),
        enableFilters: props.enableSearch,
        enableSorting: true,
        state: {
            pagination,
            globalFilter,
            sorting
        },
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: getPaginationRowModel(),
        manualPagination: props.fetchData,
        manualSorting: props.fetchData,
        manualFiltering: props.fetchData,
        defaultColumn: {
            size: 0,
            minSize: 0,
            maxSize: 100,
        },
        globalFilterFn: globalFilterFn
    });

    function globalFilterFn(row: Row<any>, columnId: string, filterValue: string) {
        const safeValue = (() => {
            const value = row.getValue(columnId);
            if (value === 'number') return String(value);
            return (value?.toString().includes("Date") ? formatDate(value.toString()) : value);
        })();

        return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
    }

    const getPageNumbers = () => {
        let pageNums: number[] = [];
        const maxPageAdj = minPageIndex - 1;
        const pageCount = table.getPageCount() + maxPageAdj;
        if (table.getPageCount() >= 5) {
            if (pagination.pageIndex <= 3 + maxPageAdj) pageNums = [minPageIndex, minPageIndex + 1, minPageIndex + 2, minPageIndex + 3, minPageIndex + 4];
            else if (pagination.pageIndex >= pageCount - 2) pageNums = [pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount]
            else pageNums = [pagination.pageIndex - 2, pagination.pageIndex - 1, pagination.pageIndex, pagination.pageIndex + 1, pagination.pageIndex + 2];
        }
        else if (table.getPageCount() > 1) {
            for (let i = 1; i <= pageCount; i++) {
                pageNums.push(i);
            }
        }
        return (
            pageNums?.map(pageNum => (
                paginationButton((pageNum - maxPageAdj).toString(), pageNum - maxPageAdj, pageNum, false, pageNum, pageNum == pagination.pageIndex)
            )));
    }

    const paginationButton = (title: string, text: string | number, value: number, hidden: boolean, key: string | number, active: boolean = false) => {
        return (
            <li key={key} className={`page-item${active ? " active" : ""}`} title={title} >
                <button className="page-link"
                    onClick={(e) => { updatePage(value, table.getState().pagination.pageSize); e.currentTarget.blur(); }}
                    hidden={hidden}>
                    {text}
                </button>
            </li>
        );
    }

    return (
        <div className="p-2">
            {props.enableSearch && (
                <div className="container-fluid">
                    <div className="col">
                        <div className="row">
                            <DebouncedInput
                                value={globalFilter ?? ''}
                                onChange={(value) => {
                                    if(props.fetchData) setFetchDataOptions({
                                        ...fetchDataOptions,
                                        searchTerm: value.toString()
                                    });
                                    setGlobalFilter(String(value));
                                }}
                                className="form-control"
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="h-2" />
            <table className="table table-striped table-borderless table-nohover">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                let cellStyle = header.column.columnDef.meta?.headerStyle;
                                if (header.column.columnDef.size > 0) {
                                    cellStyle = { ...cellStyle, width: `${header.column.columnDef.size}%` };
                                }
                                let sortIconClass = "order-4";
                                if (header.column.id == fetchDataOptions.sortBy) {
                                    sortIconClass = fetchDataOptions.sortDirection == "asc" ? "caret-4-asc" : "caret-4-desc";
                                }
                                return (
                                    <th key={header.id} colSpan={header.colSpan}
                                        style={cellStyle}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        role: header.column.getCanSort() ? "button" : "",
                                                        className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                        onClick: (e) =>
                                                        {
                                                            if (header.column.getCanSort()) {
                                                                let sortDir: "asc" | "desc" = "asc";
                                                                if (fetchDataOptions.sortBy == header.column.id && fetchDataOptions.sortDirection == "asc") {
                                                                    sortDir = "desc";
                                                                }
                                                                setFetchDataOptions({
                                                                    ...fetchDataOptions,
                                                                    sortBy: header.column.id,
                                                                    sortDirection: sortDir
                                                                });
                                                                const colSort: ColumnSort = { id: header.column.id, desc: sortDir == "desc" };
                                                                setSorting([colSort]);
                                                            }
                                                        },
                                                        }}
                                                >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                    )}
                                                    {header.column.getCanSort() && (
                                                        <span className={sortIconClass}>
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td
                                            key={cell.id}
                                            className={cell.column.columnDef.meta?.bodyClass}
                                            style={cell.column.columnDef.meta?.bodyStyle}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="h-2" />
            {props.enablePaging && (
                <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                        <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: 'visible' }}>
                            <select
                                className="btn-dark select-btn rounded"
                                value={table.getState().pagination.pageSize}
                                onChange={e => {
                                    updatePage(minPageIndex, Number(e.target.value));
                                }}
                            >
                                {
                                    [
                                        { value: 10, text: "10" },
                                        { value: 25, text: "25" },
                                        { value: 50, text: "50" },
                                        { value: MAXITEMS, text: "All" }
                                    ].map(pageSize => (
                                        <option key={pageSize.value} value={pageSize.value}>
                                            {pageSize.text}
                                        </option>
                                    ))}
                            </select>
                        </span>
                        {props.fetchData && (
                            <span className="pagination-info ps-2">
                                Showing {pagination.pageIndex * pagination.pageSize - (pagination.pageSize - 1)} - {pagination.pageIndex < table.getPageCount() ? pagination.pageIndex * pagination.pageSize : totalRecords} of {totalRecords}
                            </span>
                        )}
                        {!props.fetchData && (
                            <span className="pagination-info ps-2">
                                Showing {(pagination.pageIndex + 1) * pagination.pageSize - (pagination.pageSize - 1)} - {pagination.pageIndex + 1 < table.getPageCount() ? (pagination.pageIndex + 1) * pagination.pageSize : props.defaultData?.length} of {props.defaultData?.length}
                            </span>
                        )}

                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                        <ul className="pagination float-end mt-0">
                            {paginationButton("Go to first", "First", minPageIndex, pagination.pageIndex <= minPageIndex, "First")}
                            {paginationButton("Go to previous", "Prev", table.getState().pagination.pageIndex - 1, pagination.pageIndex <= minPageIndex, "Prev")}
                            {getPageNumbers()}
                            {paginationButton("Go to next", "Next", table.getState().pagination.pageIndex + 1, pagination.pageIndex >= table.getPageCount() + (minPageIndex - 1), "Next")}
                            {paginationButton("Go to last", "Last", table.getPageCount(), pagination.pageIndex >= table.getPageCount() + (minPageIndex - 1), "Last")}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Table;
