import * as React from "react";

import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";
import { formatDate } from "../../locale-utils";


import { Button, Col, Container, Row } from "react-bootstrap";
import { CertificateOptionModel, CourseLanguageModel } from "../../Interfaces";
import { fetchCertificateOptions, fetchCourseLanguages } from "../../ApiServices/AppSettings";

import CourseLanguageForm from "./course-language-form";
import PageMessages from "../../Components/page-messages";
import { RbacContext } from "../../rbac-context";
import { WithToast } from "../../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Table from "../../Components/table";
import { getToken } from "../../helper-functions";
import { ConfigContext } from "../../configuration-context";

interface CourseLanguageSettingsProps extends WithToast {}

const CourseLanguageSettings = ({ toast }: CourseLanguageSettingsProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [courseLanguage, setCourseLanguage] = React.useState<CourseLanguageModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [showCreateModal, setShowCreateModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState<CourseLanguageModel>(null);
    const [certOptions, setCertOptions] = React.useState<CertificateOptionModel[]>([]);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const fetchLanguages = async (): Promise<CourseLanguageModel[]|undefined> => {
        try {
            const response = await fetch(apimBaseUrl + "/Q/Curriculum/AllLanguages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({})
            });
            if (response.status === 404 || response.status === 204) return undefined;
            return await response.json();
            
        } catch (e) {
            console.error(e);
            // Handle fetch error
        }
    };

    React.useEffect(() => {
        async function getCourseLanguages() {
            try {
                setLoading(true);
                const languages = await fetchLanguages();
                setCourseLanguage(languages);
                setLoading(false);
                ////TODO replace with Curriculum new API call
                const certOptions = await fetchCertificateOptions();
                setCertOptions(certOptions.Data);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
        }

        getCourseLanguages();        
    }, []);

    const refreshCourseLanguages = async () => {
        try {
            const languages = await fetchLanguages();
            setCourseLanguage(languages);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }
    };

    const columnHelper = createColumnHelper<CourseLanguageModel>()
    const columns = React.useMemo<ColumnDef<CourseLanguageModel>[]>(
        () => [

            columnHelper.accessor('language', {
                cell: info => info.getValue(),
                header: t("Language"),
                enableSorting: true,
            }),

            columnHelper.accessor('code', {
                cell: info => info.getValue(),
                header: t("Code"),
                enableSorting: true,
            }),

            columnHelper.accessor('crmoptionSet', {
                cell: info => info.getValue(),
                header: t("CRM Option Set Value"),
                enableSorting: true,
            }),

            columnHelper.accessor('certificateImagePath', {
                cell: info => info.getValue(),
                header: t("Cert Image"),
                enableSorting: true,
            }),

            columnHelper.accessor('courseLanguageId', {
                cell: info => actionsColumn(info.row.original),
                header: t("Actions"),
                enableSorting: false,

            }),

        ],
        []
    );

    const actionsColumn = (row: CourseLanguageModel) => {
        return (
            <Button
                variant="outline-primary"
                className="me-2"
                onClick={() => {
                    setSelectedLanguage(row);
                    setShowEditModal(true);
                }}
            >
                <i className="me-1 fas fa-edit"></i>
                {t("Edit")}
            </Button>
        );
    }

    return (
        <>
            <PageMessages loading={loading} />

            <Container fluid>
                <Row>
                    <Col sm="10">
                        <h3>{t("Course Languages")}</h3>
                    </Col>
                    <Col sm="2">
                        <Button onClick={() => setShowCreateModal(true)}>
                            + {t("Create Language")}
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Table
                keyField="courseLanguageId"
                fetchData={false}
                columns={columns}
                defaultData={courseLanguage}
                defaultSorted={{ dataField: "courseLanguageId", order: "asc" }}
                enablePaging={true}
                enableSearch={false}
                forceRefresh={refreshTable}
            />
            {showCreateModal && (
                <CourseLanguageForm
                    isCreateForm
                    isVisible={showCreateModal}
                    certOptions={certOptions}
                    onSave={(resp) => {
                        setShowCreateModal(false);
                        toast(resp);
                        refreshCourseLanguages();
                    }}
                    onHide={() => setShowCreateModal(false)}
                />
            )}
            {showEditModal && (
                <CourseLanguageForm
                    isVisible={showEditModal}
                    existingSetting={selectedLanguage}
                    certOptions={certOptions}
                    onSave={(resp) => {
                        setShowEditModal(false);
                        // toast(resp);
                        refreshCourseLanguages();
                    }}
                    onHide={() => setShowEditModal(false)}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, CourseLanguageSettings);
