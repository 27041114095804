import * as React from "react";

import { Button, Col, Container, Form, Modal, Row, Tooltip } from "react-bootstrap";
import { ConditionalOverlay } from "../../Components";
import { InvoiceDetailsModel, InvoiceReturnModel, OrganizationInvoiceModel } from "../../Interfaces";

import { useTranslation } from "react-i18next";

import PageMessages from "../../Components/page-messages";
import { RbacContext } from "../../rbac-context";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { fetchLearnersCountBySeatPoolId } from "../Utils/class";
import { ConfigContext } from "../../configuration-context";
import { RemoveOrganizationSeat } from "../Utils/organization";

export interface RemoveInvoiceModalProps {
    orgSeat: OrganizationInvoiceModel;
    isVisible: boolean;
    onHide: () => void;
    handleRemove: (seatRemovedResponse: OrganizationInvoiceModel) => void;
}

const RemoveInvoiceModal = ({ orgSeat, isVisible, onHide, handleRemove }: RemoveInvoiceModalProps) => {

    const { t } = useTranslation();    
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { userContext } = React.useContext(RbacContext);

    const formRef = React.useRef<HTMLFormElement>();
    const [loading, setLoading] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);
    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [invoice, setInvoice] = React.useState<InvoiceDetailsModel>(
        {
            orgSeatId: null,
            courseId: null,
            organizationId: null,
            orgId: null,
            orgName: null,
            invoiceNumber: null,
            expirationDate: null,
            seatsPurchased: 0,
            seatsUsed: 0,
            seatsRemaining: 0,
            learnersComplete: 0,
            learnersIncomplete: 0

        });
    const [invoiceUpdate, setInvoiceUpdate] = React.useState<InvoiceReturnModel>(
        {
            seatPoolId: null,
            fullReturn: true,
            unenrollLearners: false,
            unenrolledLearnersCount: 0,
            seatsRemoved: 0,
            reason: null
        });

    const [fullRadio, setFullRadio] = React.useState(true);
    const [partialRadio, setPartialRadio] = React.useState(false);

    React.useEffect(() => {
        async function getInvoiceDetails() {
            try {
                setFullRadio(true);
                setPartialRadio(false);
                var data = await fetchLearnersCountBySeatPoolId(apimBaseUrl, configContext, orgSeat.seatPoolId);
                setInvoice({ 
                    ...invoice,
                    orgSeatId: orgSeat.seatPoolId,
                    organizationId: orgSeat.organizationId,
                    orgName: orgSeat.organizationName,
                    invoiceNumber: orgSeat.invoiceNumber,
                    seatsPurchased: orgSeat.seatsPurchased,
                    seatsUsed: orgSeat.seatsUsed,
                    seatsRemaining: orgSeat.seatsRemaining, 
                    learnersComplete: data.learnersComplete, 
                    learnersIncomplete: data.learnersIncomplete 
                });
                if (orgSeat.seatsUsed > 0) {
                    setFullRadio(false);
                    setPartialRadio(true);
                    setInvoiceUpdate(
                        {
                            ...invoiceUpdate,
                            seatPoolId: orgSeat.seatPoolId,
                            fullReturn: false,
                            seatsRemoved: orgSeat.seatsRemaining != 0 ? orgSeat.seatsRemaining : 0
                        });
                }
                else {
                    setInvoiceUpdate(
                        {
                            ...invoiceUpdate,
                            seatPoolId: orgSeat.seatPoolId,
                            seatsRemoved: orgSeat.seatsRemaining
                        });
                }
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
            
        }
        getInvoiceDetails();
    }, [orgSeat, refresh]);

    const handleMaxReturn = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value) || 0;
        const max = invoiceUpdate.unenrollLearners ? invoice.seatsRemaining + invoice.learnersIncomplete : invoice.seatsRemaining;
        const newValue = value < max ? value : max;
        setInvoiceUpdate({ ...invoiceUpdate, seatsRemoved: newValue });
    }

    const handleSubmit = async () => {
        const isValid = formRef.current.checkValidity();
        setValidated(true);
        if (isValid) {
            try {
                setLoading(true);
                const seatRemoveModel = { 
                    ...invoiceUpdate,
                    userId: userContext.sub,
                    userFirstName: userContext.firstName,
                    userLastName: userContext.lastName,
                    requestorId: userContext.sub,
                    requestorFirstName: userContext.firstName,
                    requestorLastName: userContext.lastName,
                }
                const seatRemovedResponse = await RemoveOrganizationSeat(configContext, seatRemoveModel);
                setLoading(false);
                setSaving(false);
                setValidated(false);
                handleRemove(seatRemovedResponse);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
            setSaving(true);           
        }
        setInvoiceUpdate({ seatPoolId: null, fullReturn: true, unenrollLearners: false, unenrolledLearnersCount: 0, seatsRemoved: 0, reason: null });
        setRefresh(!refresh);
    };

    const renderForm = () => {
        return (
            // @ts-ignore
            <Form noValidate ref={formRef}>
                <Container>
                    <Form.Group>
                        <Row>
                            <ConditionalOverlay
                                condition={invoice.seatsUsed > 0}
                                overlay={
                                    <Tooltip id="disabled-full-return-tooltip">
                                        {t("There are used seats!")}
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <Form.Check
                                        name="return-type"
                                        id={`radio-remove-full`}
                                        type="radio"
                                        label={t("Remove All Seats")}
                                        checked={fullRadio}
                                        disabled={invoice.seatsUsed > 0}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPartialRadio(!partialRadio);
                                            setFullRadio(!fullRadio);
                                            setInvoiceUpdate({ ...invoiceUpdate, fullReturn: true, unenrollLearners: false, seatsRemoved: invoice.seatsRemaining });
                                        }}
                                    />
                                </span>
                            </ConditionalOverlay>
                        </Row>
                        <Row>
                            <Form.Check
                                name="return-type"
                                id={`radio-remove-partial`}
                                type="radio"
                                label={t("Remove Partial Seats")}
                                checked={partialRadio}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFullRadio(!fullRadio);
                                    setPartialRadio(!partialRadio);
                                    setInvoiceUpdate({ ...invoiceUpdate, fullReturn: false, seatsRemoved: invoice.seatsRemaining });
                                }}
                            />
                        </Row>
                    </Form.Group>
                    {partialRadio && (
                        <Container>
                            <Form.Group controlId="" style={{ paddingLeft: "50px" }}>
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>
                                            {t("Purchased")}:
                                        </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>
                                            {invoice.seatsPurchased}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>
                                            {t("Fully Used")}:
                                        </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>
                                            {invoice.learnersComplete}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>
                                            {t("Partially Used")}:
                                        </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>
                                            {invoice.learnersIncomplete}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>
                                            {t("Available to Remove")}:
                                        </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>
                                            {invoice.seatsRemaining + invoice.learnersIncomplete}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={10} style={{ paddingLeft: "50px", paddingBottom: "10px" }}>
                                        <ConditionalOverlay
                                            condition={invoice.learnersIncomplete == 0}
                                            overlay={
                                                <Tooltip id="disabled-full-return-tooltip">
                                                    {t("There are no incomplete enrollments!")}
                                                </Tooltip>
                                            }
                                        >
                                            <span style={{ display: "inline-block" }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={t("Unenroll Incomplete Learners")}
                                                    id={`checkbox-unenroll-learners`}
                                                    disabled={invoice.learnersIncomplete == 0}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setInvoiceUpdate(
                                                            {
                                                                ...invoiceUpdate,
                                                                unenrollLearners: !invoiceUpdate.unenrollLearners,
                                                                unenrolledLearnersCount: invoice.learnersIncomplete,
                                                                seatsRemoved: (!invoiceUpdate.unenrollLearners ? invoice.seatsRemaining + invoice.learnersIncomplete : invoice.seatsRemaining)
                                                            });
                                                    }}
                                                />
                                            </span>
                                        </ConditionalOverlay>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Container>
                    )}
                    <Container>
                        <Form.Group style={{ paddingLeft: "50px" }}>
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <Form.Label>
                                        <strong>{t("Seats to Remove")}:</strong>
                                    </Form.Label>
                                </Col>
                                <Col md={4} style={{ paddingLeft: "0px" }}>
                                    <ConditionalOverlay
                                        condition={invoiceUpdate.unenrollLearners}
                                        overlay={
                                            <Tooltip id="disabled-full-return-tooltip">
                                                {invoiceUpdate.unenrollLearners
                                                    ? t("You must unenroll all learners")
                                                    : t("Seats to be removed")}
                                            </Tooltip>
                                        }
                                    >
                                        <span style={{ display: "inline-block", width: "100%" }}>
                                            <Form.Control
                                                type="number"
                                                id="num_seats-removed"
                                                pattern="[0-9]*"
                                                min="1"
                                                max={invoice.seatsRemaining || ""}
                                                value={invoiceUpdate.seatsRemoved}
                                                disabled={invoiceUpdate.unenrollLearners || invoiceUpdate.fullReturn}
                                                style={{ width: "75%", paddingLeft: "15px" }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleMaxReturn(e);
                                                }}
                                            />
                                        </span>
                                    </ConditionalOverlay>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Container>
                    <Form.Group>
                        <Form.Label>{t("Reason")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            id="textarea-reason"
                            rows={5}
                            required
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInvoiceUpdate({ ...invoiceUpdate, reason: e.target.value })}
                            isInvalid={validated && !invoiceUpdate.reason}
                        />
                    </Form.Group>
                </Container>
            </Form>
        );
    };

    return (
        <Modal show={isVisible} onHide={onHide} md={6}>
            <PageMessages loading={loading} />
            {!loading && (
                <React.Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Remove Seats")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{renderForm()}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={onHide} disabled={saving}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                            {saving ? (
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                                </>
                            ) : (
                                    "Save"
                                )}
                        </Button>
                    </Modal.Footer>
                </React.Fragment>
            )}
        </Modal>
    );
};

export default withAITracking(reactPlugin, RemoveInvoiceModal);
