import * as React from 'react';

const useLocalStorage = (key:string, initialValue:any) => {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = () => {
      if (typeof window === 'undefined') {
        return initialValue;
      }
  
      try {
        const item = window.localStorage.getItem(key);

        if (item) {
            return JSON.parse(item);
        } else {
            // Doesn't yet exist, save to local storage
            window.localStorage.setItem(key, JSON.stringify(initialValue));            
            return initialValue;
        }

      } catch (error) {
        console.warn(`Error reading localStorage key “${key}”:`, error);
        return initialValue;
      }
    };
  
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState(readValue);

    // Listen for changes to localStorage
    // If another instance of this hook updates the same key, ensure all instances are updated
    React.useEffect(() => {
        const handleStorageChange = () => {
            setStoredValue(readValue());
        };

        // Add event listener
        window.addEventListener('local-storage', handleStorageChange);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('local-storage', handleStorageChange);
        };
    }, []);

  
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value:any) => {
      if (typeof window == 'undefined') {
        console.warn(
          `Tried setting localStorage key “${key}” even though environment is not a client`
        );
      }
  
      try {
        // Allow value to be a function so we have same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value;
  
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue));
  
        // Save state
        setStoredValue(newValue);
  
        // We dispatch a custom event so every useLocalStorage hook are notified
        window.dispatchEvent(new Event('local-storage'));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    };
  
    return [storedValue, setValue];
}

export default useLocalStorage;