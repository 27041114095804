import * as React from "react";

import { Alert, Container } from "react-bootstrap";
import { fetchLearnerCourseThumbnails, fetchLearnerCourses } from "../ApiServices/Learn";

import CourseCard from "./Components/course-card";
import { LearnerCourseDetails } from "../Interfaces";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import SyncMyCoursesComponent from "./Components/sync-my-courses-component";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const _URL = window.URL || window.webkitURL;

const MyCoursesPage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [learnerCourses, setLearnerCourses] = React.useState<LearnerCourseDetails[]>([]);
    const [descriptionExpands, setDescriptionExpands] = React.useState<{ [id: string]: boolean }>({});
    const [thumbnailUrls, setThumbnailUrls] = React.useState<{ [id: string]: string }>({});
    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);

    React.useEffect(() => {
        const getLearnerCourses = async () => {
            try {
                const courses = await fetchLearnerCourses();
                setLearnerCourses(courses);
                setLoading(false);

                try {
                    const courseThumbnails = await fetchLearnerCourseThumbnails();
                    courses.forEach((course) => {
                        if (courseThumbnails[course.CourseId] && !thumbnailUrls[course.CourseId]) {
                            const img = new Image();
                            img.src = _URL.createObjectURL(courseThumbnails[course.CourseId]);
                            thumbnailUrls[course.CourseId] = img.src;
                        }
                    });
                    setThumbnailUrls({ ...thumbnailUrls });
                } catch (err) {
                    appInsights.trackException({ error: err, properties: userContext });
                    console.error(err);
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
                setLoading(false);
            }
        };

        getLearnerCourses();
    }, []);

    const handleOnSynced = async () => {
        const courses = await fetchLearnerCourses();
        setLearnerCourses(courses);
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h2 style={{ display: "inline-flex" }}>{t("My Courses")}</h2>
                <SyncMyCoursesComponent pageLoad={loading} onSynced={handleOnSynced} />
            </div>
            <PageMessages loading={loading} errored={errored} />
            {!loading && !errored && learnerCourses.length === 0 && (
                <Alert variant="primary">{t("You haven't been enrolled in any courses yet")}.</Alert>
            )}
            <hr />
            <Container fluid>
                {learnerCourses.map((learnerCourse: LearnerCourseDetails) => {
                    const descriptionExpand = descriptionExpands[learnerCourse.LearnerCourseId];
                    const thumbnailUrl = thumbnailUrls[learnerCourse.CourseId];

                    return (
                        <CourseCard
                            key={learnerCourse.LearnerCourseId}
                            url={`/Learn/Course/${learnerCourse.ClassId}`}
                            thumbnailUrl={thumbnailUrl}
                            course={learnerCourse}
                            descriptionExpanded={descriptionExpand}
                            toggleDescriptionExpand={() => {
                                descriptionExpands[learnerCourse.LearnerCourseId] = !descriptionExpand;
                                setDescriptionExpands({ ...descriptionExpands });
                            }}
                        />
                    );
                })}
            </Container>
        </>
    );
};

export default withAITracking(reactPlugin, MyCoursesPage);
