import i18n from "i18next";
import type { EditClassParticipant } from "../Interfaces/Platform/Participants";
import useEmailRegex from "../hooks/useEmailRegex";

export function validateEditRules(values: EditClassParticipant) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors: any = {};

    if (!values.blueCardId && values.blueCardRequired && values.status === "pass") {
        errors.blueCardId = i18n.t("Validate-Blue-Card-Chars");
    } else if (containsEmail(values.blueCardId)) {
        errors.blueCardId = i18n.t("BulkUpload.BlueCardIdIsEmail");
    }

    if (!values.status) {
        errors.status = i18n.t("Validate-Status-Select")
    }

    if (!values.type) {
        errors.type = i18n.t("Validate-Type-Select")
    }

    return errors;
}

export function validateEditCredentialNumberRules(values: EditClassParticipant) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors: any = {};

    if (!values.blueCardId && values.blueCardRequired && values.status === "pass") {
        errors.blueCardId = i18n.t("Validate-Credential-Number-Chars");
    } else if (containsEmail(values.blueCardId)) {
        errors.blueCardId = i18n.t("BulkUpload.BlueCardIdIsEmail");
    }

    if (!values.status) {
        errors.status = i18n.t("Validate-Status-Select")
    }

    if (!values.type) {
        errors.type = i18n.t("Validate-Type-Select")
    }

    return errors;
}

export function validateSubmitBlueCardIdRules(values: EditClassParticipant) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors: any = {};
    
    if (!values.blueCardId && values.status === "pass") {
        errors.blueCardId = i18n.t("Validate-Blue-Card-Chars");
    } else if (containsEmail(values.blueCardId)) {
        errors.blueCardId = i18n.t("BulkUpload.BlueCardIdIsEmail");
    }

    return errors;
}

export function validateSubmitCredentialNumberRules(values: EditClassParticipant) {
    const { isEmail, containsEmail } = useEmailRegex();
    let errors: any = {};

    if (!values.blueCardId && values.status === "pass") {
        errors.blueCardId = i18n.t("Validate-Credential-Number-Chars");
    } else if (containsEmail(values.blueCardId)) {
        errors.blueCardId = i18n.t("BulkUpload.CredentialNumberIsEmail");
    }

    return errors;
}

export function validateSubmitStatusRules(values: EditClassParticipant) {
    let errors: any = {};

    if (!values.status) {
        errors.status = i18n.t("Validate-Status");
    }

    return errors;
}

export function validateSubmitTypeRules(values: EditClassParticipant) {
    let errors: any = {};

    if (!values.type) {
        errors.type = i18n.t("Validate-Type");
    }

    return errors;
}