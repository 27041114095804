import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { ConfigContext } from "../../configuration-context";
import FormDisplay from "../../Components/Form/form-display";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";
import Tile from "../../Components/UI/tile";
import useRouteAccessCheck from "../../hooks/useRouteAccessCheck";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { SupportBaseRoutes } from "../../Routing/routes";
import { RbacContext } from "../../rbac-context";
import ProfileDataModel from "../../Interfaces/Platform/ApimData/ProfileDataModel";
import ArrowLink from "../../Components/UI/arrow-link";
import ViewProfileModel from "../../Interfaces/Platform/ApimData/ProfileDataModel";
import { getToken, getUserClaims, isOnlyLearner } from "../../helper-functions";
import { useTranslation } from "react-i18next";
import { fetchMyProfileInfo } from "../../ApiServices/Login";

import { RoleEnum } from "../../Enums";

const ProfilePage = () => {
    const rbac = React.useContext(RbacContext);
    const userTokenData = getUserClaims();
    const { rac } = useRouteAccessCheck();
    const { t } = useTranslation();
    const [canManageProfile] = useFeatureFlags("ITLManageProfile", true);

    //
    // SETTING CONFIGCONTEXT
    //
    const configContext = React.useContext(ConfigContext);
    const isParticipant = isOnlyLearner(rbac.userContext);

    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [profileData, setProfileData] = React.useState<ViewProfileModel>({
        id: "",
        lastName: "",
        firstName: "",
        customerId: "",
        organizationId: "",
        email: "",
        title: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phone: ""
    });

    const [organizationData, setOrganizationData] = React.useState({
        organizationName: "",
        city: "",
        state: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        phone: "",
        zipCode: "",
        accountingEmail: ""
    });

    const [userProfileHasLoaded, setUserProfileHasLoaded] = React.useState(false);
    const [organizationDataHasLoaded, setOrganizationDataHasLoaded] = React.useState(false);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            fetchMyProfileInfo(getUserClaims().sub).then((data) => {
                setProfileData(data);
                setUserProfileHasLoaded(true);

                if (data.organizationId !== null) {
                    getOrgInfo(data);
                }
            });
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    //
    // GET Account Organization data once the profile data is returned (org id will be in profile data)
    //
    const getOrgInfo = (profileData: ProfileDataModel) => {
        if (configContext?.SystemConfiguration?.ApimKey && profileData.organizationId) {
            fetch(apimBaseUrl + "/Q/Organization/Details/" + profileData.organizationId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    Authorization: `Bearer ${getToken("accessToken")}`
                }
            })
                .then((resp) => resp.json())
                .then(function (data) {
                    setOrganizationData(data);
                    setOrganizationDataHasLoaded(true);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <Row>
                        <Col xs={12}>
                            <h1 className="page-title-h1 title-margin">{t("Profile")}</h1>
                        </Col>
                    </Row>
                    <EightByFour
                        left={
                            <>
                                {!isParticipant ? (
                                    <Tile
                                        customClassName="mt-0"
                                        title={profileData.firstName + " " + profileData.lastName}
                                    >
                                        <>
                                            <SixBySix
                                                left={
                                                    <>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("Support.Profile.InstructorId")}
                                                                content={profileData.customerId}
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("Email")}
                                                                content={profileData.email}
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("CPI.UI.Phone")}
                                                                content={profileData.phone}
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("CPI.UI.Form.CreateAccount.Address")}
                                                                content={profileData.addressLine1}
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("Support.Profile.JobTitle")}
                                                                content={profileData.title}
                                                            />
                                                        </div>
                                                        {organizationDataHasLoaded && (
                                                            <>
                                                                <div className="mt-3">
                                                                    <FormDisplay
                                                                        label={t(
                                                                            "Support.Profile.OrganizationBaseEmployment"
                                                                        )}
                                                                        content={organizationData.organizationName}
                                                                    />
                                                                </div>
                                                                <div className="mt-3">
                                                                    <FormDisplay
                                                                        label={t("Support.Profile.BillingEmail")}
                                                                        content={organizationData.accountingEmail}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                }
                                                right={
                                                    <>
                                                        
                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Support.Profile.EditContactInformation"),
                                                                        href: "/Support/Profile/Edit-Profile"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.NameAddressEtc")}</div>
                                                            </div>
                                                        </>)}  

                                                        
                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Change Password"),
                                                                        href: "/Support/Profile/Change-Password"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.AccountPassword")}</div>
                                                            </div>
                                                        </>)}                                                            
                                                                                                                
                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Multifactor Authentication"),
                                                                        href: "/Support/Profile/Multi-Factor-Authentication"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.TurnOnOffMultifactorAuthentication")}</div>
                                                            </div>
                                                        </>)}   
                                                
                                                        <div className="mt-4">
                                                            <ArrowLink
                                                                data={{
                                                                    label: t("CPI.UI.MyAccount.Profile.ChangeBOE"),
                                                                    href: "#"
                                                                }}
                                                            />
                                                            <div>{t("Support.Profile.ChangeJobs")}</div>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </>
                                    </Tile>
                                ) : (
                                    <Tile
                                        customClassName="mt-0"
                                        title={
                                            (profileData.firstName || userTokenData.firstName) +
                                            " " +
                                            (profileData.lastName || userTokenData.lastName)
                                        }
                                    >
                                        <>
                                            <SixBySix
                                                left={
                                                    <>
                                                        <div className="mt-3">
                                                            <FormDisplay
                                                                label={t("Email")}
                                                                content={profileData.email || userTokenData.email}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                right={
                                                    <>
                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Support.Profile.EditContactInformation"),
                                                                        href: "/Support/Profile/Edit-Profile"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.NameAddressEtc")}</div>
                                                            </div>
                                                        </>)}

                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Change Password"),
                                                                        href: "/Support/Profile/Change-Password"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.AccountPassword")}</div>
                                                            </div>
                                                        </>)}

                                                        {canManageProfile && (<>
                                                            <div className="mt-4">
                                                                <ArrowLink
                                                                    data={{
                                                                        label: t("Multifactor Authentication"),
                                                                        href: "/Support/Profile/Multi-Factor-Authentication"
                                                                    }}
                                                                />
                                                                <div>{t("Support.Profile.TurnOnOffMultifactorAuthentication")}</div>
                                                            </div>
                                                        </>)}
                                                    </>
                                                }
                                            />
                                        </>
                                    </Tile>
                                )}
                            </>
                        }
                        right={
                            <>
                                <Tile
                                    customClassName="mt-0"
                                    hide={isParticipant}
                                    title={t("Support.Profile.Certification")}
                                >
                                    <>
                                        <div className="mt-4">
                                            <ArrowLink
                                                data={{
                                                    label: t(
                                                        "CPI.MyAccount.Dashboard.ProfessionalDevelopment.CertificationHistory"
                                                    ),
                                                    href: "#"
                                                }}
                                            />
                                            <div>{t("Support.Profile.CertStatusTraining")}</div>
                                        </div>

                                        {rac(SupportBaseRoutes.SupportCertificationAgreement) && (
                                            <div className="mt-4">
                                                <ArrowLink
                                                    data={{
                                                        label: t("CPI.UI.Default.CertificationAgreement"),
                                                        href: SupportBaseRoutes.SupportCertificationAgreement.fullPath
                                                    }}
                                                />
                                                <div>{t("Support.Profile.GuidelinesCert")}</div>
                                            </div>
                                        )}
                                    </>
                                </Tile>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default ProfilePage;
