import * as React from "react";

interface VideoPlayerProps {
    vimeoId: string;
    width?: string;
    height?: string;
    autoplay?: boolean;
 }
const VideoPlayer = ({
    vimeoId,
    width = "100%",
    height = "100%",
    autoplay = false
}: VideoPlayerProps) => {
    
    return (
        <div style={{"height" : `${height}`, "width" : `${width}`}}>

            <>
                <div style={{"padding":"56.25% 0 0 0","position":"relative"}}>
                    <iframe src={`https://player.vimeo.com/video/${vimeoId}?h=de9c1187a2&autoplay=${autoplay ? 1 : 0}`}
                    style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}} 
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>                
                    </iframe>
                </div><script src="https://player.vimeo.com/api/player.js"></script>
            </>
           
        </div>
    );
};

export default VideoPlayer;
