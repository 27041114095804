import * as React from "react";
import { ArrowLinkObject } from "../../../Interfaces/Platform/ArrowLink";
import ArrowLink from "../arrow-link";
import Button from "../button";
import { ConfigContext } from "../../../configuration-context";

const WebinarListing = (props: {
    image: string,
    imageAltText?: string,
    title: string,
    presenterName: string,
    webinarDate: Date,
    description?: any,
    arrowLinkArr?: ArrowLinkObject[],
    linkId?: string
}): JSX.Element => {

    const configContext = React.useContext(ConfigContext);

    return (
        <div className="webinar-listing-block-container">
            <div className="webinar-listing">
                <div className="webinar-listing-thumbnail-wrapper">
                    <div className="webinar-listing thumbnail">
                        <i className={`fa-sharp fa-solid fa-circle-play icon`}></i>
                        <img 
                            className="image"
                            alt={props.imageAltText ? props.imageAltText : ""}
                            src={`${configContext?.SystemConfiguration?.CDNMediaUrl}/${props.image}`}
                        />
                    </div>
                    <Button label="Play Video" isSolid={false} elementId={props.linkId}/>
                </div>
                <div className="webinar-listing-information-wrapper ml-3">
                    <div className="webinar-listing-heading">
                        <h3 className="webinar-listing-title sub-headings-h3">{props.title}</h3>
                        <h6 className="webinar-listing-presenter sub-headings-h6">{props.presenterName}</h6>
                        <h6 className="webinar-listing-date sub-headings-h6">{props.webinarDate.toDateString()}</h6>
                    </div>
                    
                    <p className="webinar-listing-block-description">{props.description}</p>
                    {
                        <ul>
                        { props.arrowLinkArr &&
                            props.arrowLinkArr.map((arrowLinkItem) => (
                                <ArrowLink data={arrowLinkItem} />
                            ))
                        }
                        </ul>
                    }
                </div>
                
            </div>
        </div>
    );

};

export default WebinarListing;
