import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import useParsedTranslation from './useParsedTranslation';
import parseHtml from 'html-react-parser';

const useClassMaterials = (certificationIds:string[], qualificationIds:string[], searchParameters?:any) => {
    const { t } = useTranslation();

    const classMaterialsManifest:any = {
        "CC": {
            "Versions": [
                {
                "Program": "Classroom Culture",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "PDF_CC_2E_IG", "PDF_CC_2E_EP_Blended", "PDF_CC_2E_EP_Classroom", "ZIP_CC_2E_EP_Blended", "ZIP_CC_2E_EP_Classroom", "PDF_CC_2E_SPWB" ]
                }
            ],
            "Inherited Programs": [ "VI" ]
        },
        "APS": {
            "Versions": [
                {
                "Program": "Advanced Physical Skills ",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "PDF_APS_3E_IG", "PPT_APS_3E_EP_Blended", "PPT_APS_3E_EP_Classroom", "PDF_APS_3E_SPWB", "PDF_APS_3E_SI_Checklist", "PDF_VI_3E_Checklist", "PDF_APS_3E_Critical_Thinking_Practice_Creating_Activities", "PDF_APS_3E_Critical_Thinking_Practice_Dynamic_Practice", "PDF_APS_3E_Critical_Thinking_Practice_Problem_Solving", "PDF_APS_3E_Guidance_Doc" ]
                },
                {
                "Program": "Advanced Physical Skills Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Advanced Physical Skills Classroom",
                "Version": "2nd Edition",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Advanced Physical Skills Foundation",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ],
            "Inherited Programs": [ "NCI" ]
        },
        "APS-ASD": {
            "Versions": [
                {
                "Program": "Advanced Physical Skills Autism Spectrum Disorder",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "PDF_NCI_3E_ASD_IG", "PPT_APS_3E_ASD_EP_Blended", "PPT_APS_3E_ASD_EP_Classroom" ]
                },
                {
                "Program": "Advanced Physical Skills Autism Spectrum Disorder",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ],
            "Inherited Programs": [ "NCI" ]
        },
        "APS-MH": {
            "Versions": [
                {
                "Program": "Advanced Physical Skills Mental Health",
                "Version": "3rd Edition",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Advanced Physical Skills Mental Health",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "APS-TRA": {
            "Versions": [
                {
                "Program": "Advanced Physical Skills Trauma",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Advanced Physical Skills Trauma",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "NCI": {
            "Versions": [
                {
                "Program": "Nonviolent Crisis Intervention",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Positive Behavioral Interventions and Support",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Dementia Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Dementia Classroom",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Positive Behavioral Interventions and Support",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Refresher (DO NOT INCLUDE)",
                "Version": "2nd Edition",
                "Active": "False",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Blended",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Refresher Blended",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Refresher Classroom",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Integrating Positive Behavioral Interventions and Supports",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Positive Behavioral Interventions and Supports",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Hybrid",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Hybrid Refresher",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Key Point Refresher",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ],
            "Inherited Programs": [ "VI" ]
        },
        "NCI-ASD": {
            "Versions": [
                {
                "Program": "Nonviolent Crisis Intervention Autism Spectrum Disorder",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Autism Spectrum Disorder",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Foundation Autism Spectrum Disorder",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "NCI-MH": {
            "Versions": [
                {
                "Program": "Nonviolent Crisis Intervention Mental Health",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Mental Health",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "NCI-TRA": {
            "Versions": [
                {
                "Program": "Nonviolent Crisis Intervention Trauma",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Trauma Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Nonviolent Crisis Intervention Trauma Classroom",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "VI": {
            "Versions": [
                {
                "Program": "Verbal Intervention",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention Virtual",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention Refresher (DO NOT INCLUDE)",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "VI-ASD": {
            "Versions": [
                {
                "Program": "Verbal Intervention Autism Spectrum Disorder",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention Autism Spectrum Disorder",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "VI-MH": {
            "Versions": [
                {
                "Program": "Verbal Intervention Mental Health",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention Mental Health",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "VI-TRA": {
            "Versions": [
                {
                "Program": "Verbal Intervention Trauma",
                "Version": "3rd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Verbal Intervention Trauma Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "DCC": {
            "Versions": [
                {
                "Program": "Dementia Capable Care Blended",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Dementia Capable Care Classroom",
                "Version": "2nd Edition",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Dementia Capable Care Foundation",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Dementia Capable Care Foundation and Behaviors",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "PT": {
            "Versions": [
                {
                "Program": "Prepare Training: After the Crisis",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Challenged by Mental Illness at Work",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Crisis Response Teams",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Foundation",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Giving Bad News",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: How to Document Incidents",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Instructor Portfolio",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Opening the Lines of Electronic Communication",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Setting Effective Limits",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Situational Applications",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Workplace Bullying",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Workplace Substance Abuse",
                "Version": "Foundation",
                "Active": "True",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Negotiating Your Way Through Conflict",
                "Version": "Foundation",
                "Active": "False",
                "Files": [ "", "", "", "" ]
                },
                {
                "Program": "Prepare Training: Responding to Violence, Effective Policies and Procedures",
                "Version": "Foundation",
                "Active": "False",
                "Files": [ "", "", "", "" ]
                }
            ]
        },
        "EFE": {
            "Versions": [
                {
                "Program": "Essentials for Educators (DO NOT INCLUDE)",
                "Version": "3rd Edition",
                "Active": "False",
                "Files": [ "", "", "", "" ]
                }
            ]
        }
    }

    const classMaterials:any = { 
        "PDF_CC_2E_IG": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Instructor Guides",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/instructor-guides/PDF_CC_2E_IG.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/THUMB_CC_2E_IG.jpg"
        },
        "PDF_CC_2E_EP_Blended": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Blended"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/electronic-presentations/PDF_CC_2E_EP_Blended.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/THUMB_CC_2E_EP.jpg"
        },
        "PDF_CC_2E_EP_Classroom": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Classroom"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/electronic-presentations/PDF_CC_2E_EP_Classroom.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/THUMB_CC_2E_EP.jpg"
        },
        "ZIP_CC_2E_EP_Blended": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Blended"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "ZIP",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/electronic-presentations/ZIP_CC_2E_EP_Blended.zip",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/THUMB_CC_2E_EP.jpg"
        },
        "ZIP_CC_2E_EP_Classroom": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Classroom"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "ZIP",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/electronic-presentations/ZIP_CC_2E_EP_Classroom.zip",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/THUMB_CC_2E_EP.jpg"
        },
        "PDF_CC_2E_SPWB": {
            "Program": "Classroom Culture",
            "Program-Code": "CC",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Classroom"],
            "Content-Type": "Sample Participant Workbooks",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "ZIP",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/sample-participant-workbooks/PDF_CC_2E_SPWB.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/classroom-culture/thumbnails/XXXXX.jpg"
        },
        "PDF_APS_3E_IG": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Instructor Guides",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/instructor-guides/PDF_APS_3E_IG.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_IG.jpg"
        },
        "PPT_APS_3E_EP_Blended": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PowerPoint",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/electronic-presentations/PPT_APS_3E_EP_Blended.pptx",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_EP.jpg"
        },
        "PPT_APS_3E_EP_Classroom": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Classroom"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PowerPoint",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/electronic-presentations/PPT_APS_3E_EP_Classroom.pptx",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_EP.jpg"
        },
        "PDF_APS_3E_SPWB": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Sample Participant Workbook",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/sample-participant-workbooks/PDF_APS_3E_SPWB.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_SPWB.jpg"
        },
        "PDF_APS_3E_SI_Checklist": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Checklists",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/checklists/PDF_APS_3E_SI_Checklist.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_SI_Checklist.jpg"
        },
        "PDF_APS_3E_Critical_Thinking_Practice_Creating_Activities": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "2nd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Activities",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/checklists/PDF_APS_3E_Critical_Thinking_Practice_Creating_Activities.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_Critical_Thinking_Practice.jpg"
        },
        "PDF_APS_3E_Critical_Thinking_Practice_Dynamic_Practice": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Activities",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/checklists/PDF_APS_3E_Critical_Thinking_Practice_Dynamic_Practice.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_Critical_Thinking_Practice.jpg"
        },
        "PDF_APS_3E_Critical_Thinking_Practice_Problem_Solving": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Activities",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/checklists/PDF_APS_3E_Critical_Thinking_Practice_Problem_Solving.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_Critical_Thinking_Practice.jpg"
        },
        "PDF_APS_3E_Guidance_Doc": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Aditional Materials",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/checklists/PDF_APS_3E_Guidance_Doc.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/XXXXX.jpg"
        },
        "PPT_APS_3E_ASD_EP_Blended": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "ASD",
            "Version": "3rd Edition",
            "Class-Type": ["Blended"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PowerPoint",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/electronic-presentations/PPT_APS_3E_ASD_EP_Blended.pptx",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_ASD_EP.jpg"
        },
        "PPT_APS_3E_ASD_EP_Classroom": {
            "Program": "Advanced Physical Skills",
            "Program-Code": "APS",
            "Additional-Content": "ASD",
            "Version": "3rd Edition",
            "Class-Type": ["Classroom"],
            "Content-Type": "Electronic Presentations",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PowerPoint",
            "Download": "True",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/electronic-presentations/PPT_APS_3E_ASD_EP_Classroom.pptx",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/advanced-physical-skills/thumbnails/THUMB_APS_3E_ASD_EP.jpg"
        },
    
        "PDF_NCI_3E_ASD_IG": {
            "Program": "Nonviolent Crisis Intervention",
            "Program-Code": "NCI",
            "Additional-Content": "ASD",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Instructor Guides",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/nonviolent-crisis-intervention/instructor-guides/PDF_NCI_3E_ASD_IG.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/nonviolent-crisis-intervention/thumbnails/THUMB_NCI_3E_ASD_IG.jpg"
        },
    
        "PDF_VI_3E_Checklist": {
            "Program": "Verbal Intervention",
            "Program-Code": "VI",
            "Additional-Content": "",
            "Version": "3rd Edition",
            "Class-Type": ["Blended", "Classroom"],
            "Content-Type": "Checklists",
            "Region": ["US", "CA"],
            "Language": "English",
            "File-Type": "PDF",
            "Download": "False",
            "URL": "https://cdn.crisisprevention.com/resources/class-materials/verbal-intervention/checklists/PDF_VI_3E_Checklist.pdf",
            "Thumbnail": "https://cdn.crisisprevention.com/resources/class-materials/verbal-intervention/thumbnails/THUMB_VI_3E_Checklist.jpg"
        }
    }

    const [programIds, setProgramIds] = React.useState(new Set());
    const [programMaterialIds, setProgramMaterialIds] = React.useState(new Set());
    const [programMaterials, setProgramMaterials] = React.useState([]);

    React.useEffect(() => {
        addInheritedPrograms(certificationIds);
        addInheritedPrograms(qualificationIds);
    }, [])

    React.useEffect(() => {
        addProgramMaterials(Array.from(programIds));
    }, [programIds]);

    React.useEffect(() => {
        setProgramMaterials([]);
        Array.from(programMaterialIds).forEach((materialId:any) => {
            if (classMaterials[materialId]) {
                setProgramMaterials(prevState => ([
                    ...prevState,
                    classMaterials[materialId]
                ]).sort(function(a, b) {
                    return sortMaterials(a, b);
                }));
            }
        });
    }, [programMaterialIds])

    const addInheritedPrograms = (idArray:string[]) => {
        idArray.forEach((id) => {
            // add the current programId to the state array if it has an associated manifest
            if (classMaterialsManifest[id]) {
                setProgramIds(prevState => new Set(prevState).add(id));
    
                // check for inherited ids
                if (classMaterialsManifest[id]['Inherited Programs']) {
                    addInheritedPrograms(classMaterialsManifest[id]['Inherited Programs']);
                }
            }
        });
    }

    const addProgramMaterials = (programsArray:any[]) => {
        programsArray.forEach((programId) => {
            classMaterialsManifest[programId].Versions.forEach((version:any) => {
                version.Files.forEach((file:string) => {
                    if (classMaterials[file] && !shouldFilterOut(classMaterials[file])) {
                        setProgramMaterialIds(prevState => new Set(prevState).add(file));
                    }
                })                
            })
        })
    }

    const shouldFilterOut = (material:any):boolean => {
        if (searchParameters && searchParameters['Program-Code'] && material && (material['Program-Code'] != searchParameters['Program-Code'])) {
            return true;
        }

        return false;
    }

    const sortMaterials = (a:any, b:any):number => {

        // prototype sorting by program code alphabetically
        var programCodeA = a['Program-Code'].toUpperCase();
        var programCodeB = b['Program-Code'].toUpperCase();

        // secondary sort on version
        var versionA = a['Version'].toUpperCase();
        var versionB = b['Version'].toUpperCase();

        // if prototype program value and version values are all equal, return 0
        if ((programCodeA == programCodeB) && (versionA == versionB)) return 0
        
        // if program values are equal, compare on version, most recent first (here they will not be equal)
        if ((programCodeA == programCodeB)) return (versionA < versionB) ? -1 : 0;
        
        // do the regular prototype sort on programCode
        return (programCodeA > programCodeB) ? -1 : (programCodeA < programCodeB) ? 1 : 0;
    }

    return [ programMaterials ];
}

export default useClassMaterials;