import * as React from "react";

import { useTranslation } from "react-i18next";

const ParticipantsImportPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <div>The Import Participants Page</div>
        </>
    );
};

export default ParticipantsImportPage;
