import * as React from "react";
import i18next from "i18next";
import { setLanguage } from "../../ApiServices/Language";
import { setPendoCulture } from "../../pendo";
import Select from "../../Components/Form/select";
import useLanguages from "../../hooks/useLanguages";
import useRouteAccessCheck from "../../hooks/useRouteAccessCheck";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";
import { RbacContext } from "../../rbac-context";
import { RoleEnum } from "../../Enums";

const CultureSelector = (props: {
    
}): JSX.Element => {

    const { getLanguageLabel, updateLanguage } = useLanguages();
    const { setCountry } = React.useContext(RbacContext);
    const rbac = React.useContext(RbacContext);
    const htmlTag = document.querySelector('html');

    const { canChangeRegion } = useRouteAccessCheck();

    const [ showCultureSelector, setShowCultureSelector ] = React.useState(false);
    const [ languageLabel, setLanguageLabel ] = React.useState(getLanguageLabel(i18next.language));
    const [ canChangeRegionState, setCanChangeRegionState ] = React.useState(false);
    const toggleCultureSelector = () => { if (showCultureSelector) { setShowCultureSelector(false) } else { setShowCultureSelector(true) } };
    const closeCultureSelector = () => { setShowCultureSelector(false) }

    const handleUpdateLanguage = (e:any) => {
        htmlTag.setAttribute('lang', e.target.value);
        updateLanguage(e.target.value);
        setLanguageLabel(getLanguageLabel(e.target.value));
    };

    const handleUpdateRegion = (e:any) => {
        window.sessionStorage.setItem("country", e.target.value);
        setCountry(e.target.value);
    };

    const regions:FormSelectOption[] = [
        { label: "Australia", value: "en-AU" },
        { label: "Canada", value: "en-CA" },
        { label: "France", value: "fr-FR" },
        { label: "Ireland", value: "en-IE" },
        { label: "New Zealand", value: "en-NZ" },
        { label: "United Kingdom", value: "en-GB" },
        { label: "United States", value: "en-US" }
    ]

    const languages:FormSelectOption[] = [
        { label: "English (United States)", value: "en-US" },
        { label: "English (United Kingdom)", value: "en-GB" },
        { label: "Français", value: "fr-FR" }
    ]

    React.useEffect(() => {
        setCanChangeRegionState(canChangeRegion);
        htmlTag.setAttribute('lang', i18next.language);
    }, [rbac.userContext])

    return (
        <>
            { showCultureSelector && <div onClick={closeCultureSelector} style={{zIndex:"1", left:"0", right:"0", position:"fixed", height:"100%", width:"100%"}}></div> }
            <div className="header-culture-selector d-md-flex align-items-center">
                <div className="region-selector dropdown">
                    <button type="button" className="dropdown-toggle" onClick={toggleCultureSelector} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="icon-globe"></span>
                        <span id="culture-code" className="culture-code">{languageLabel}</span>
                    </button>

                    { showCultureSelector &&
                        <>
                            <div style={{ zIndex:"2" }} className="dropdown-menu-platform p-4">
                                { canChangeRegionState &&
                                    <>
                                        <div className="table-header">REGION</div>
                                        <Select label="" showLabel={false} changeAction={handleUpdateRegion} value={rbac.userContext.Country} name="region" id="region" options={regions} />
                                        <div className="mt-4"></div>
                                    </>                                
                                }
                
                                <div className="table-header">LANGUAGE</div>
                                <Select label="" showLabel={false} changeAction={handleUpdateLanguage} value={i18next.language} name="language" id="language" options={languages} />
                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    );
};

export default CultureSelector;
