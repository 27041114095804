import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const BaseOfEmployment = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('BaseOfEmployment-Title') }</h1>
                                <Tile title={ t('BTN-Base-Of-Employment-Standards') } hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <h3 className="sub-headings-h3 mt-4">{ t('BaseOfEmployment-WhatIsBOE-Q') }</h3>
                                        <span> { tp('BaseOfEmployment-WhatIsBOE-A') } </span>
                                        <h3 className="sub-headings-h3 mt-4">{ t('BaseOfEmployment-WhyBOE-Q') }</h3>
                                        <span> { tp('BaseOfEmployment-WhyBOE-A') } </span>
                                        <h3 className="sub-headings-h3 mt-4">{ t('BaseOfEmployment-WhatIfLeave-Q') }</h3>
                                        <span> { tp('BaseOfEmployment-WhatIfLeave-A') } </span>
                                        <Button label={ t('BTN-Request-Base-Of-Employment-Change') } isSolid={false} elementId="RequestBOEChange" href="URL" />
                                        <h3 className="sub-headings-h3 mt-4">{ t('BaseOfEmployment-Multiple-Q') }</h3>
                                        <span> { tp('BaseOfEmployment-Multiple-A') } </span>
                                        <Button label={ t('BTN-Request-Base-Of-Employment-Exception') } isSolid={false} elementId="RequestBOEException" href="mailto:info@crisisprevention.com?subject=Request%20Base%20of%20Employment%20Exception%20Application" />


                                    </>      
                                </Tile>

                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default BaseOfEmployment;
