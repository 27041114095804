import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
    // load translation using http
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: {
            'en-CA': ['en-US'],
            'en-GB': ['en-US'],
            'fr-FR': ['en-US'],
            'default': ['en-GB']
        },
        preload: ['en-US', 'en-CA', 'fr-CA', 'en-GB', 'en-IE', 'en-AU', 'en-NZ', 'en-FI', 'fr-FR'],
        saveMissing: false, // send not translated keys to endpoint
        returnEmptyString: false,
        keySeparator: false, // we do not use keys in form messages.welcome,
        nsSeparator: "::",
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        ns: ["translation", "messageCodes"],
        defaultNS: "translation",

        backend: {
            // for all available options read the backend's repository readme file
            loadPath: "/locale/{{lng}}/{{ns}}.json",
            // path to post missing resources
            addPath: "/locale/add/{{lng}}/{{ns}}",
            allowMultiLoading: false,
            requestOptions: () => {
                cache: "false";
            }
        },

        detection: {
            order: ["cookie", "htmlTag", "navigator"],
            lookupCookie: "i18n.langtag",
            htmlTag: document.documentElement
        }
    });

export default i18next;
