import * as React from "react";

import { Button, Modal } from "react-bootstrap";

import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
    show: boolean;
    hide: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
    message?: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title || t("Confirm")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message || t("Are you sure?")}</Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onCancel}>
                    {t("No")}
                </Button>
                <Button variant="primary" onClick={props.onConfirm}>
                    {t("Yes")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
