import * as React from "react";

import { CpiRoute } from "../../Routing/routes";

import { useNavigate } from "react-router-dom";
import { isExternalURL } from "../../helper-functions";

const Crumb = (props: {
    route: CpiRoute,
    isHigherLevelRoute?: boolean
}): JSX.Element => {

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(props.route.fullPath)) {
            event.preventDefault();
            navigate(props.route.fullPath);
        }
    };

    const getParentRoute = () => {
        if (props.route ? props.route.parent : false) {
            return (

                <>
                    <Crumb isHigherLevelRoute={true} route={props.route.parent} />
                    <span className="separator">/</span>
                </>

            )
        }
    }

    if (props.route ? props.route.title : false) {
        return props.isHigherLevelRoute ? <>
            { props.route.parent ? getParentRoute() : null }

            <li className="breadcrumb-item">
                <a onClick={handleClick} href={props.route.fullPath} className="breadcrumb-link">{props.route.title()}</a>
            </li>
        </>

        :

        <>
            { props.route.parent ? getParentRoute() : null }

            <li className="breadcrumb-item">
                <span>{props.route.title()}</span>
            </li>
        </>

    }   
};

export default Crumb;
