import * as React from "react";

import { CoursePreview } from "../Interfaces";
import ManyScoPreview from "./Components/many-sco-preview";
import MultiScoPreview from "./Components/multisco-preview";
import Page from "./page";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { fetchCoursePreview } from "../ApiServices/Preview";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const CoursePreviewPage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const params = useParams<{ courseObjectId: string }>();
    const [preview, setPreview] = React.useState<CoursePreview>(null);
    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [invalidPreview, setInvalidPreview] = React.useState(false);
    const [invalidPreviewMessage, setInvalidPreviewMessage] = React.useState("");

    const courseObjectId = parseInt(params.courseObjectId);

    React.useEffect(() => {
        const getCoursePreview = async () => {
            const errorMessage = t("The course you are trying to preview could not be found");
            if (!isNaN(courseObjectId)) {
                try {
                    setLoading(true);
                    const preview = await fetchCoursePreview(courseObjectId);
                    if (preview.Data) {
                        setPreview(preview.Data);
                    } else {
                        setInvalidPreview(true);
                        setInvalidPreviewMessage(preview.Error.Code === 404 ? errorMessage : preview.Error.Message);
                    }
                } catch (err) {
                    appInsights.trackException({ error: err, properties: userContext });
                    console.error(err);
                    setErrored(true);
                }
            } else {
                setInvalidPreview(true);
                setInvalidPreviewMessage(errorMessage);
            }
            setLoading(false);
        };

        getCoursePreview();
    }, []);

    return (
        <>
            {!loading && !errored && !invalidPreview && preview.IsMultiSco && (
                <MultiScoPreview launchUrl={preview.LaunchUrl} />
            )}
            {!loading && !errored && !invalidPreview && !preview.IsMultiSco && <ManyScoPreview preview={preview} />}
        </>
    );
};

export default withAITracking(reactPlugin, CoursePreviewPage);
