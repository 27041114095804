import * as React from "react";

const ParticipantList = (props: {
    listItems: JSX.Element[],
    participants: any
}): JSX.Element => {
    const [isMobile, setIsMobile] = React.useState(false);
    const detectWindowSize = () => setIsMobile(window.innerWidth <= 768);
    React.useEffect(() => {
        window.addEventListener("resize", detectWindowSize);
        detectWindowSize();
        return () => window.removeEventListener("resize", detectWindowSize);
    }, []);
    const length = props.listItems.length;

    const getClassNames = (index: number) => {
        if (length > 1) {
            if (index === 0) return "first-participant-listing";
            if (index === length - 1) return "last-participant-listing";
            return "inner-participant-listing";
        }
        return "unique-participant-listing";
    };

    const returnListing = (): any => props.listItems.map((participantListing, index: number) => {
        return !isMobile ? (
            <tr className={`${participantListing.props.isEdit ? 'class-participant-list listing-edit' : ''} participant-list-container ${getClassNames(index)} ${participantListing.props.isChecked ? 'participant-list-checked' : 'participant-list-unchecked'}`}>
                {participantListing}
            </tr>
        ) : (
            <div className={`mobile-custom-table participant-list-container ${getClassNames(index)} ${participantListing.props.isChecked ? 'participant-list-checked' : 'participant-list-unchecked'}`}>
                {participantListing}
            </div>
        );
    });

    return returnListing();

};

export default ParticipantList;
