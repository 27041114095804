import * as React from "react";

import { IClass } from "../../../Interfaces/Platform/Classes";

export interface Props {
    listItems: React.ReactElement<IClass>[];
}
const ClassList = (
    props: Props
): JSX.Element => {
    const length = props.listItems.length;

    const getClassNames = (index: number) => {
        if (length > 1) {
            if (index === 0) return "first-class-listing";
            if (index === length - 1) return "last-class-listing";
            return "inner-class-listing";
        }
        return "unique-class-listing";
    };

    const returnListing = () => props.listItems.map((classListing, index: number) => {
        return (
            <td
                className={getClassNames(index)}
                key={`class-listing-${index}`}
            >
                {classListing}
            </td>
        );
    });

    return (
        <tr className="class-list-container">
            {returnListing()}
        </tr>
    );

};

export default ClassList;
