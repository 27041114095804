import * as React from "react";

import { Alert, Col, Container, Row } from "react-bootstrap";
import { InstructorBaseRoutes, LearnerBaseRoutes } from "../Routing/routes";
import { Navigate } from "react-router-dom";

import { RbacContext } from "../rbac-context";
import ResetPasswordForm from "./Components/reset-password-form";
import ResetPasswordModal from "./Components/reset-password-modal";
import { RoleEnum } from "../Enums";
import { fetchPasswordResetInfo } from "../ApiServices/Login";
import { isAuthenticated } from "../helper-functions";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";
import { withNavigator } from '../Components/with-navigator';

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';


const FirstTimeLoginPage = (props: any) => {
    const { t } = useTranslation();
    const { toast } = useToast();

    const token = React.useMemo(() => {
        return props.searchParams.get('token');
    }, []);

    const { userContext } = React.useContext(RbacContext);
    const [loading, setLoading] = React.useState(true);
    const [email, setEmail] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [resetEmail, setResetEmail] = React.useState("");
    const [redirect, setRedirect] = React.useState("");
    const [resetting, setResetting] = React.useState(false);

    React.useEffect(() => {
        const getInfo = async () => {
            try {
                const resp = await fetchPasswordResetInfo(token);
                if (resp.Error || resp.Data.LastLogin) {
                    setRedirect("/");
                } else {
                    setEmail(resp.Data.Email);
                }
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
            
        };

        getInfo();
    }, []);

    if (!resetting && isAuthenticated()) {
        if (redirect) {
            return <Navigate to={redirect} />;
        }

        if (
            userContext.Roles.some(
                (r) =>
                    r === RoleEnum.BusinessAdministrator ||
                    r === RoleEnum.CertifiedInstructor ||
                    r === RoleEnum.Developer
            )
        ) {
            return <Navigate to={InstructorBaseRoutes.Class.Class.fullPath} />;
        }

        return <Navigate to={redirect || LearnerBaseRoutes.Learn.fullPath} />;
    }

    if (redirect) {
        
        window.location.href = redirect;
        return;
    }

    return (
        <Container fluid>
            <Row>
                <Col md={8} lg={6} className="mx-auto">
                    <h1 className="mb-4 text-center">{t("Set Your Password")}</h1>
                    {loading && (
                        <div className="mt-4 fa-3x">
                            <i className="fas fa-spinner fa-spin position-absolute " style={{ left: "50%" }}></i>
                        </div>
                    )}
                    {!!resetEmail && (
                        <Alert variant="success">
                            <p className="text-break">
                                {t("A password reset link email has been sent to {{emailAddress}}.", {
                                    emailAddress: resetEmail
                                })}{" "}
                                {t("If you do not receive an email, please contact your instructor.")}
                            </p>
                        </Alert>
                    )}
                    {!loading && (
                        <ResetPasswordForm
                            email={email}
                            token={token}
                            buttonText={t("Set Password")}
                            activeButtonText={t("Setting")}
                            onResetting={() => setResetting(true)}
                            onReset={(resp) => {
                                if (resp.Error) {
                                    toast(resp);
                                } else {
                                    setRedirect(LearnerBaseRoutes.Learn.fullPath);
                                }
                            }}
                        />
                    )}
                </Col>
            </Row>
            {showModal && (
                <ResetPasswordModal
                    show={showModal}
                    onSend={(email) => {
                        setResetEmail(email);
                        setShowModal(false);
                    }}
                    handleCancel={() => setShowModal(false)}
                />
            )}
        </Container>
    );
};

export default withNavigator(withAITracking(reactPlugin, FirstTimeLoginPage));
