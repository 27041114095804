import * as React from "react";

import { ConfirmationModal } from "../../Components";
import { Button, Col, Row } from "react-bootstrap";
import { deleteReminderSetting, fetchReminderSettings } from "../../ApiServices/OrganizationSettings";

import PageMessages from "../../Components/page-messages";
import { RbacContext } from "../../rbac-context";
import ReminderSettingForm from "./reminder-setting-form";
import { ReminderSettingModel } from "../../Interfaces";
import { WithToast } from "../../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";
import { formatDate } from "../../locale-utils";

interface RemindersProps extends WithToast {}

const Reminders = (props: RemindersProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [loading, setLoading] = React.useState(false);

    const [reminderSettings, setReminderSettings] = React.useState<ReminderSettingModel[]>([]);
    const [selectedSetting, setSelectedSetting] = React.useState<ReminderSettingModel>(null);

    const [showCreateModal, setShowCreateModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    React.useEffect(() => {
        async function getReminderSettings() {
            try {
                setLoading(true);
                const settings = await fetchReminderSettings();
                setReminderSettings(settings);
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }       
        }

        getReminderSettings();
    }, []);

    const refreshReminderSettings = async () => {
        try {
            const settings = await fetchReminderSettings();
            setReminderSettings(settings);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }    
    };

    const handleDelete = async () => {
        try {
            const resp = await deleteReminderSetting(selectedSetting.ReminderSettingId);
            setShowDeleteModal(false);
            props.toast(resp);
            await refreshReminderSettings();
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }      
    };

    const emailTemplateColumn = (emailTemplateId: string) => {
        return emailTemplateId || (
            <small>
                <i>({t("default")}: d-e367c9d9cac44d97a3dc248f9a0add6c)</i>
            </small>
        );
    }

    const columnHelper = createColumnHelper<ReminderSettingModel>()
    const columns = React.useMemo<ColumnDef<ReminderSettingModel>[]>(
        () => [
            columnHelper.accessor('ReminderInterval', {
                cell: info => info.getValue(),
                header: t("Reminder Interval"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" } }
            }),
            columnHelper.accessor('EmailTemplateId', {
                cell: info => emailTemplateColumn(info.getValue()),
                header: t("SendGrid Email Template"),
                enableSorting: false,
                meta: { headerStyle: { whiteSpace: "nowrap" }, bodyStyle: { whiteSpace: "nowrap" }, bodyClass: "text-truncate" }
            }),
            columnHelper.accessor('Active', {
                cell: info => info.getValue().toString(),
                header: t("Active"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "150px" } }
            }),
            columnHelper.accessor('CreateDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Created"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" } }
            }),
            columnHelper.accessor('ModifyDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Last Updated"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" } }
            }),
            columnHelper.accessor('ReminderSettingId', {
                cell: info => actionsColumn(info.row.original),
                header: "",
                enableSorting: false,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "250px" } }
            }),
        ],
        []
    );

    const actionsColumn = (row: ReminderSettingModel) => {
        return (
            <>
                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedSetting(row);
                        setShowEditModal(true);
                    }}
                >
                    <i className="me-1 fas fa-edit" />
                    {t("Edit")}
                </Button>
                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedSetting(row);
                        setShowDeleteModal(true);
                    }}
                >
                    <i className="me-1 fas fa-trash" />
                    {t("Delete")}
                </Button>
            </>
        );
    }

    return (
        <>
            <PageMessages loading={loading} />
            <div className="mt-4">
                <Row>
                    <Col>
                        <h3>{t("Reminders")}</h3>
                    </Col>
                    <Col sm="auto">
                        <Button onClick={() => setShowCreateModal(true)}>+ {t("Create Reminder")}</Button>
                    </Col>
                </Row>
                <RemoteTable
                    fetchData={false}
                    columns={columns}
                    defaultData={reminderSettings ?? []}
                    defaultSorted={{ dataField: "ReminderInterval", order: "asc" }}
                    enablePaging={false}
                    enableSearch={false}
                />
            </div>
            {showCreateModal && (
                <ReminderSettingForm
                    isCreateForm
                    isVisible={showCreateModal}
                    onSave={(resp) => {
                        setShowCreateModal(false);
                        props.toast(resp);
                        refreshReminderSettings();
                    }}
                    onHide={() => setShowCreateModal(false)}
                    existingSettings={reminderSettings}
                />
            )}
            {showEditModal && (
                <ReminderSettingForm
                    isVisible={showEditModal}
                    existingSetting={selectedSetting}
                    onSave={(resp) => {
                        setShowEditModal(false);
                        props.toast(resp);
                        refreshReminderSettings();
                    }}
                    onHide={() => setShowEditModal(false)}
                    existingSettings={reminderSettings}
                />
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={showDeleteModal}
                    hide={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                    onCancel={() => setShowDeleteModal(false)}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, Reminders);
