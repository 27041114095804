import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const RisksOfPhysicalRestraints = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}


            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                    marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1 title-margin">{t('RisksOfRestraints-Title')}</h1>

                                    <Tile title={t('RisksOfRestraints-Section1-Title1')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            {tp('RisksOfRestraints-Section1-Title1')}
                                            
                                            <GrayTile percentWidth={100}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <h2 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-Section1-Title2')}</h2>
                                                </>
                                            </GrayTile>
                                            {tp('RisksOfRestraints-Section1-Content2')}
                                            

                                        </>
                                    </Tile>

                                    <Tile title={t('RisksOfRestraints-Section2-Title1')} hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <h2 className="sub-headings-h3 mt-4">{t('RisksOfRestraints-TreatAsImportant')}</h2>

                                            <TwoColumnLayout
                                                left={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-WarningSigns')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content2')}
                                                     
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-CorrectiveActions')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content3')}

                                                        
                                                        <ArrowLink data={{ label: "Download Opt-Out Sequence", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Physical-Skills/Opt-Out-Sequence_poster.pdf", openInNewTab: true }} />
                                                    </>
                                                }
                                            />
                                            <hr />
                                            <h2 className="sub-headings-h3 mt-4">{t('RisksOfRestraints-TreatAsUrgent')}</h2>

                                            <TwoColumnLayout
                                                left={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-WarningSigns')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content4')}

                                                        
                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-CorrectiveActions')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content5')}
                                                    </>
                                                }
                                            />

                                            <hr />
                                            <h2 className="sub-headings-h3 mt-4">{t('RisksOfRestraints-TreatAsMedEmergency')}</h2>

                                            <TwoColumnLayout
                                                left={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-WarningSigns')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content6')}

                                                    </>
                                                }

                                                right={
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}

                                                        <GrayTile percentWidth={100}>
                                                            <>
                                                                {/* WRITE HTML BELOW THIS LINE */}
                                                                <h3 className="bold sub-headings-h3 mb-0">{t('RisksOfRestraints-CorrectiveActions')}</h3>
                                                            </>
                                                        </GrayTile>
                                                        {tp('RisksOfRestraints-Section2-Content7')}
                                                    </>
                                                }
                                            />

                                        </>
                                    </Tile>

                                </>
                            </>
                        }
                    />


                </Container>

            </div>

        </>
    );
};

export default RisksOfPhysicalRestraints;
