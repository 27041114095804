import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const MaintenancePage = () => {
    const { t } = useTranslation();
    const mailTo = "mailto:" + t("info@crisisprevention.com");
    
    return (
        <Container fluid>
            <Row>
                <Col className="col-3"></Col>
                <Col className="text-center">
                    <h1 className="mt-5 mb-4 text-center text-primary fw-bold">{t("Temporarily Down for Maintenance")}</h1>
                    <h3 className="text-center mb-3">
                        {t("To continue providing our customers with a world-class experience, our website is undergoing maintenance.")}
                    </h3>
                    <h3 className="text-center mt-5 mb-3">
                        {t("Please contact customer support for any urgent concerns.")}
                    </h3>
                    <h3 className="text-center mt-5">
                        {t("CPI Customer Support")}<br/>
                        {t("888.426.2184")}<br/>
                        <a href={mailTo} className="text-link">{t("info@crisisprevention.com")}</a>
                    </h3>
                </Col>
                <Col className="col-3"></Col>
            </Row>
        </Container>
    );
};

export default MaintenancePage;
