import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import Accordion from "../../Components/UI/accordion";
import VideoEmbed from "../../Components/UI/video-embed";


const FacilitatingTrainingActivities = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <h1 className="page-title-h1 title-margin">Facilitating Training Activities</h1>
                    <p className="mw-900 mt-3">
                        <p>Think back to a great training experience you've had. Really try to imagine it.</p>
                        <ul className="ml-3">
                            <li>What was your trainer like?</li>
                            <li>How did they engage you?</li>
                            <li>What did they say and do to make it a memorable experience?</li>
                        </ul>
                        <p>You may have thought about the trainer's excitement for the topic, their enthusiasm around activities, the interesting stories they told, or their understanding of the content. Maybe they did a great job of allowing participants to share their experiences with each other, and incorporated activities into their training that were engaging and realistic. All of these are marks of a great trainer.</p>
                        <p>Adult learners are most engaged when they share their own experiences, and interact with other participants. The facilitator's role is to guide the conversation, and make connection to the skills and material being taught in the training. A master facilitator brings activities to life, but reaching that level requires practice.</p>
                        <p>Strengthen your facilitation skill set by exploring the pattern each CPI activity follows, tips and tricks, deep dives into the most common activity types, and key strategies to help you improve before your next training.</p>
                    </p>
                    <Tile title="Follow the CPI Activity Pattern" hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        {/* ADD NUMBER COMPONENTS HERE */}

                                        <div className="d-flex flex-column flex-md-row">
                                            <div className="d-flex w-100 flex-row mr-lg-4">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img width="75px" alt="Set the Purpose" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Purpose.png" title="Set the Purpose"></img>

                                                </div>

                                                <div className="ml-3 mb-0 pb-2 d-flex flex-column justify-content-center pr-2">
                                                    <h3 className="mb-1 sub-headings-h3 uppercase">1. Set the Purpose</h3>

                                                    <p>Tell participants why the knowledge they will gain is beneficial to them, and how these skills connect to the overall goals of the training.</p>
                                                </div>
                                                <div className="pr-2 d-flex flex-column justify-content-center">
                                                </div>
                                            </div>

                                            <div className="d-flex w-100 flex-rowmt-lg-0">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img width="75px" alt="Provide Directions &amp; Demonstrate" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Directions.png" title="Provide Directions &amp; Demonstrate"></img>

                                                </div>
                                                <div className="ml-3 mb-0 d-flex pt-2 pb-2 flex-column justify-content-center pr-2">
                                                    <h3 className="mb-1 mt-2 sub-headings-h3 uppercase">2. Provide Directions &amp; Demonstrate</h3>

                                                    <p>Tell participants why the knowledge they will gain is beneficial to them, and how these skills connect to the overall goals of the training.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column flex-md-row mt-3">
                                            <div className="d-flex w-100 flex-row mr-lg-4">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img width="75px" alt="Set the Purpose" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Participate.png" title="Set the Purpose"></img>
                                                </div>
                                                <div className="ml-3 mb-0 d-flex pt-2 pb-2 flex-column justify-content-center pr-2">
                                                    <h3 className="mb-1 mt-2 sub-headings-h3 uppercase">3. Participate</h3>
                                                    <p>Allow participants to try the activity while you give feedback and answer questions.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex w-100 flex-row mt-lg-0">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img width="75px" alt="Provide Directions &amp; Demonstrate" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Explain.png" title="Provide Directions &amp; Demonstrate"></img>
                                                </div>
                                                <div className="ml-3 mb-0 d-flex pt-2 pb-2 flex-column justify-content-center pr-2">
                                                    <h3 className="mb-1 mt-2 sub-headings-h3 uppercase">4. Explain</h3>
                                                    <p>Bring the group back together. Have the participants reflect on their takeaways from the activity. Then explain how the activity outcomes connect to your overall training goals.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }
                            />

                        </>
                    </Tile>

                    <Tile title="Facilitating Different Activity Types" hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        {/* ADD EXPAND AND COLLAPSE COMPONENT */}


                                        <Accordion
                                            title="Think-Pair-Share"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}

                                                <h3 className="sub-headings-h3 uppercase text-center mb-3">What Is Think-Pair-Share?</h3>
                                                <div className="d-flex flex-column flex-md-row">
                                                    <div className="mr-2 w-100">
                                                        <p>In think-pair-share activities, participants work together to solve a problem or answer a question. First, they think individually about a topic, and then they share ideas with a partner. This process allows learners to relate what they've learned to their own experiences and that of their peers.</p>

                                                        <p>A think-pair-share can help you facilitate partner discussions or table talks. It also gives participants a breath to gather their thoughts before a whole group discussion.</p>
                                                    </div>
                                                    <div className="w-100">
                                                        <h4 className="h4">Benefits of Think-Pair-Share</h4>
                                                        <ul>
                                                            <li>Allows learners to construct meaning</li>
                                                            <li>Encourages richer discussion</li>
                                                            <li>Enhances whole-group discussion</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h4 className="h4 text-center mb-3">Watch as this trainer facilitates a Think-Pair-Share activity.</h4>
                                                <VideoEmbed title="Think-Pair-Share Activity" vimeoId="805684094" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-ThinkPairShare.png" />
                                                <br />
                                                <br />
                                                <br />
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <div>
                                                            <h3 className="sub-headings-h3 uppercase text-center mb-3">Reflection</h3>
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <div className="mr-2 w-100">
                                                                    <h4 className="h4">What does the trainer say and do? What impact does it have?</h4>
                                                                    <ul>
                                                                        <li>Clear directions told learners what to expect at each point during the activity.</li>
                                                                        <li>Reflecting on previous experiences led to a rich discussion.</li>
                                                                        <li>Hearing the experiences of others helped learners deepen their understanding and find common themes among the group.</li>
                                                                        <li>When the trainer makes connections before moving on to the next activity, this allows learners to process the context and relevance of what they've just done.</li>
                                                                    </ul>
                                                                    <ArrowLink data={{ label: "Download Think-Pair-Share Guide", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson2_Think-Pair-Share-Activity_Setup.pdf", openInNewTab: true }} />

                                                                </div>
                                                                <div className="w-100">
                                                                    {/* ADD VIDEO */}
                                                                    <VideoEmbed title="Think-Pair-Share Activity Tips" vimeoId="805684108" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-ThinkPairShareTips.png" />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </GrayTile>



                                            </>}
                                        />

                                        <Accordion
                                            title="Case Study"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3 uppercase text-center mb-3">What Is A Case Study?</h3>
                                                <div className="d-flex flex-column flex-md-row">
                                                    <div className="mr-2 w-100">
                                                        <p>Case studies are written versions of real-life scenarios. A strong case study doesn’t have just one right answer. That's important. It means that learners need to apply their knowledge to uncover multiple solutions, perspectives or methods of analyzing the problem.</p>
                                                        <p>Case studies are a great way to get participants to think critically and apply what they've learned to situations they might experience in the workplace. It's a collaborative exercise which fosters an exchange of new ideas.</p>
                                                    </div>
                                                    <div className="w-100">
                                                        <h4 className="h4">Case Studies Will Help Participants</h4>
                                                        <ul>
                                                            <li>Think critically to apply what they've learned to situations they are likely to experience in the workplace.</li>
                                                            <li>Explore new ideas based on collaboration with peers.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h4 className="h4 mb-3 text-center">Watch as this trainer facilitates a case study activity.</h4>
                                                {/* ADD VIDEO */}
                                                <VideoEmbed title="Case Study Tips" vimeoId="805684124" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-CaseStudy.png" />
                                                <br />
                                                <br />
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <div>
                                                            <h3 className="sub-headings-h3 uppercase text-center mb-3">Reflection</h3>
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <div className="mr-2 w-100">
                                                                    <h4 className="h4">What does the trainer say and do? What impact does it have?</h4>
                                                                    <ul>
                                                                        <li>The case study was realistic and relevant. Participants applied new knowledge to a scenario they are likely to experience in the workplace.</li>
                                                                        <li>Participants asked clarifying questions in a safe environment.</li>
                                                                        <li>Each group shared their reflections after participating. Since there was no single correct answer, participants got to see multiple solutions.</li>
                                                                        <li>The trainer tied the activity back to the core program. Participants reviewed new learning and applications to their workplace.</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="w-100">
                                                                    <VideoEmbed title="Case Study Tips" vimeoId="805684034" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-CaseStudyTips.png" />

                                                                </div>
                                                            </div>
                                                            <ArrowLink data={{ label: "Download Case Study Guide", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson3_Case-Study-Activity_Setup.pdf", openInNewTab: true }} />
                                                        </div>
                                                    </>
                                                </GrayTile>

                                            </>}
                                        />
                                        <Accordion
                                            title="Role-Play"
                                            isOpen={false}
                                            isComplete={false}
                                            children={<>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h3 className="sub-headings-h3 uppercase text-center mb-3">What Is A Role-Play Activity?</h3>
                                                <div className="d-flex flex-column flex-md-row">
                                                    <div className="mr-2 w-100">
                                                        <p>In a role-play activity, particpants use what they've learned to act out an imaginary, but realistic, scenario. These are most useful when participants are at a point that they understand the key concepts and now just need practice before applying them in the workplace. They are a great way for learners to fine tune the approaches they will use in a safe environment. With role-play scenarios it's important to set clear expectations upfront around safety, the specific roles each person will play, and how you will start and stop practice.</p>
                                                    </div>
                                                    <div className="w-100">
                                                        <h4 className="h4">Benefits of Role-Play</h4>
                                                        <ul>
                                                            <li>Moves participants from understanding to application.</li>
                                                            <li>Prepares participants to use training skills at work.</li>
                                                            <li>Provides opportunities to fine-tune approach in a safe environment.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4 className="h4 text-center">Watch as this trainer facilitates a role-play activity.</h4>
                                                    <VideoEmbed title="Role Play Tips" vimeoId="805684051" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-RolePlay.png" />

                                                </div>
                                                <br />
                                                <br />
                                                <GrayTile percentWidth={100}>
                                                    <>
                                                        {/* WRITE HTML BELOW THIS LINE */}
                                                        <div>
                                                            <h3 className="sub-headings-h3 uppercase text-center mb-3">Reflection</h3>
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <div className="mr-2 w-100">
                                                                    <h4 className="h4">What does the trainer say and do? What impact does it have?</h4>
                                                                    <ul>
                                                                        <li>Clear directions made the activity safe, efficient, and effective.</li>
                                                                        <li>Safe participation expectations were set prior to practice.</li>
                                                                        <li>Each participant got to try every role to gain confidence using these skills in the future.</li>
                                                                        <li>Participants got feedback on their performance that they can apply when they use these skills in their workplace.</li>
                                                                    </ul>
                                                                    <ArrowLink data={{ label: "Download role-play guide", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson4_Role-Play-Activity_Setup.pdf", openInNewTab: true }} />

                                                                </div>
                                                                <div className="w-100">
                                                                    <VideoEmbed title="Role Play Tips" vimeoId="805684077" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-RolePlayTips.png" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </GrayTile>


                                            </>}
                                        />


                                    </>
                                }

                            />

                        </>
                    </Tile>

                    <Tile title="Incorporate Expert Facilitation Strategies" hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>Let's look at some facilitation techniques that enhance every activity.</p>


                                        <div className="d-flex flex-column flex-lg-row mt-2 justify-content-between">
                                            <div className="border p-3 d-flex flex-column mr-lg-4 mb-2 w-100 w-lg-50">
                                                <img alt="Think Time" className="mx-auto w-25" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-ThinkTime.png" title="Think Time"></img>
                                                <h3 className="sub-headings-h3 uppercase mt-3">Add Think Time</h3>
                                                <p>Allow learners to connect new content to previous experiences. Even for a facilitator, silence can feel uncomfortable. Giving adults time to think allows them to make connections to prior experiences. Think time allows learners to collect their thoughts and make connections.</p>
                                                <p>You should:</p>
                                                <ul className="ml-3">
                                                    <li>Allow 5-10 seconds to pass before asking participants to discuss.</li>
                                                    <li>Ask participants to take 30 seconds to think and jot down notes before they discuss.</li>
                                                </ul>
                                            </div>

                                            <div className="border p-3 d-flex flex-column mr-lg-4 mb-2 w-100 w-lg-50">
                                                <img alt="Encourage Discussion" className="mx-auto w-25" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Discussion.png" title="Encourage Discussion"></img>
                                                <h3 className="sub-headings-h3 uppercase mt-3">Encourage Discussion</h3>
                                                <p>Discussion deepens shared understanding. To get the most out of these discussions, vary your format. Have participants discuss with partners, in small groups, or with the whole group. If you like a discussion question, but it doesn't fully fit your workplace, rewrite it so it does fit your context.</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-lg-row mt-2 justify-content-between">
                                            <div className="border p-3 d-flex flex-column mb-2 w-100 w-lg-50">
                                                <img alt="Chart Participant Responses" className="mx-auto w-25" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Chart.png" title="Chart Participant Responses"></img>
                                                <h3 className="sub-headings-h3 uppercase mt-3">Chart Participant Responses</h3>
                                                <p>Charting responses solidifies important information and lets you fill in concepts participants may have missed. These charts can then be a visual reference as you progress through your training. And most importantly, when you chart learners responses you're recognizing and validating their contributions.</p>
                                                <p>To Chart Effectively:</p>
                                                <ul className="ml-3">
                                                    <li>Pre-write your chart headings. Don't worry how it looks for now. If it's legible, it'll be effective.</li>
                                                    <li>During training, some instructors ask participants to write for them freeing them up to move around the room.</li>
                                                    <li>After the training, save your charts and post them in a high traffic area so your learners can refresh their knowledge between programs.</li>
                                                </ul>
                                            </div>

                                            <div className="border p-3 d-flex flex-column mb-2 w-100 w-lg-50">
                                                <img alt="Allow Time To Reflect" className="mx-auto w-25" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-Reflect.png" title="Allow Time To Reflect"></img>
                                                <h3 className="sub-headings-h3 uppercase mt-3">Allow Time to Reflect</h3>
                                                <p>While training time is precious, so is time to reflect.</p>
                                                <ul className="ml-3">
                                                    <li>Before moving from one concept to the next, give learners time to process and synthesize what they've learned.</li>
                                                    <li>Review the reflection questions at the end of each module in your instructor guide. Highlight one to two questions you will ask before moving to the next module.</li>
                                                    <li>You can also start an activity with reflection questions to bring prior knowledge and experience to the forefront.</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </>
                                }

                            />
                        </>


                    </Tile>

                    <Tile title="Providing Feedback and Self-Evaluation" hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>You can strengthen your facilitation skillset by giving a novice instructor feedback on their delivery, seeing an experienced trainer facilitate the same activity, and getting advice for practicing your own activity facilitation.</p>

                                        <div className="d-flex">
                                            <div className="mr-3">
                                                <img alt="Facilitating Checklist" className="mx-auto" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-FacilitationChecklist.png" title="Facilitation Checklist"></img>
                                            </div>
                                            <div>
                                                <h4 className="h4">Watch the trainer in the video below lead a role-play activity in their very first training.</h4>
                                                <p>What feedback would you give them to improve for next time. Use the facilitation checklist to help you generate this feedback.</p>
                                                <ArrowLink data={{ label: "Download Facilitation Checklist", href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson5_Facilitation_checklist.pdf", openInNewTab: true }} />

                                            </div>

                                        </div>
                                        {/* INSERT VIDEO */}
                                        <VideoEmbed title="Role Play Tips" vimeoId="805682082" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-SelfEval-2.png" />

                                    </>
                                }

                            />

                            <br />
                            <br />
                            <GrayTile percentWidth={100}>
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}


                                    <div className="mt-2">

                                        <h3 className="sub-headings-h3 uppercase mt-3 text-center mb-2">Reflection</h3>
                                        <div className="d-flex flex-column flex-md-row">
                                            <div className="mr-2 w-100">
                                                <p className="bold">Compare your notes to some by an experienced trainer.</p>
                                                <ul className="ml-3">
                                                    <li>Set an activity purpose</li>
                                                    <li>Give clear directions</li>
                                                    <li>Demonstrate what should be written down</li>
                                                    <li>Explain roles clearly</li>
                                                    <li>Describe the scenario before participants move</li>
                                                    <li>Explain the facilitator's role during the activity</li>
                                                    <li>Give a cue for participants to get started</li>
                                                    <li>Give a cue for participants to stop or give feedback</li>
                                                    <li>Choose which group will start whole-group discussion</li>
                                                    <li>Chart participant responses</li>
                                                    <li>Ask post-activity discussion questions</li>
                                                    <li>Explain the connection between the activity &amp; the overall program goals</li>
                                                    <li>Ask participants to reflect on their takeaways</li>
                                                </ul>
                                            </div>
                                            <div className="w-100">
                                                <div className="text-center">
                                                    <img alt="Reflect Icon" className="mb-2" src="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/icon-Help.png" title="Reflect Icon"></img>
                                                    <p>Did they notice anything you didn't?</p>
                                                    <p>What would you add to their feedback?</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            </GrayTile>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>Since their first training, our novice trainer reviewed feedback and practiced their delivery. Lets see how this training goes now that they've had time to practice and implement some of these changes.</p>

                                        {/* ADD VIDEO */}
                                        <VideoEmbed title="Activity Facilitation: Implementing New Strategies" vimeoId="805682839" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/facilitation-activities/fac-activities-SelfEval.png" />

                                        <p className="mt-3">While practicing, it's important to evaluate your own facilitation. An easy way to do this is to record yourself with a phone or webcam. Use the facilitation checklist to give yourself feedback for improvement.</p>
                                        <p className="bold">Before you go, consider the following reflection questions.</p>
                                        <ul className="ml-3">
                                            <li>How would you rate your current confidence in facilitating training?</li>
                                            <li>What are your next steps or areas to practice before your next training?</li>
                                        </ul>

                                    </>
                                }

                            />




                        </>
                    </Tile >
                </Container>
            </div>
        </>
    );
};

export default FacilitatingTrainingActivities;
