import * as React from "react";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import useCustomerSupportContactInfo from "../../hooks/useCustomerSupportContactInfo";
import { getUserClaims } from "../../helper-functions";
import { ConfigContext } from "../../configuration-context";
import useParsedTranslation from "../../hooks/useParsedTranslation";
import { t } from "i18next";

const BannerMessage = (): JSX.Element => {
    const [isVisibleBanner, setIsVisibleBanner] = React.useState(false);
    const [isVisibleSiteBanner] = useFeatureFlags("ITLDisplaySiteBanner", true);
    const configContext = React.useContext(ConfigContext);
    const { tp } = useParsedTranslation();

    const { getSupportContactInfoByCultureCode } = useCustomerSupportContactInfo();
    const [supportContactInfo] = React.useState(getSupportContactInfoByCultureCode((getUserClaims() ? (getUserClaims().culture || "") : "en-US")));

    const date = new Date(configContext.SystemConfiguration.BannerStartDate);

    const timeAmPm = () => {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
        
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minuteString = minutes < 10 ? `0${minutes}` : minutes.toString();

        return `${hours}:${minuteString} ${ampm}`;
    };

    const handleVisibleBanner = () => {
        let storageForBanner = localStorage.getItem('isVisibleBanner');

        if (!storageForBanner) {
            localStorage.setItem('isVisibleBanner', 'true');
        } else {
            localStorage.setItem('isVisibleBanner', 'false');
        }
        
        storageForBanner = localStorage.getItem('isVisibleBanner');
        setIsVisibleBanner(storageForBanner === 'true');
    }

    React.useEffect(() => {
        const storageForBanner = localStorage.getItem('isVisibleBanner');

        if (storageForBanner) {
            setIsVisibleBanner(storageForBanner === 'true');
        }
    }, [isVisibleBanner]);

    return (
        <>
            {isVisibleBanner && isVisibleSiteBanner &&
            <div className="banner-message d-flex">
                <p className="fs18 m-0 p-5">{tp("MaintenanceContent", {
                    maintenanceStartDate: t("dynamicDate", {
                        val: date,
                        formatParams: {
                            val: {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric'
                            }
                        }
                    }),
                    maintenanceStartTime: timeAmPm(),
                    phone: supportContactInfo?.phone, 
                    email: supportContactInfo?.email, 
                    startTime: supportContactInfo?.startTime, 
                    endTime: supportContactInfo?.endTime, 
                    timezone: supportContactInfo?.timezone, 
                    startDay: supportContactInfo?.startDay, 
                    endDay: supportContactInfo?.endDay
                })}</p>
                
                <a
                    href="javascript:;"
                    className="nostyle m-3"
                    onClick={handleVisibleBanner}
                >
                    <i className="fa-light fa-xmark fs24" />
                </a>
            </div>}
        </>
    );
};

export default BannerMessage;
