import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TrainingCenter = () => {
    const { t } = useTranslation();
    
    return (
       <>teh training centerer</>
    );
};

export default TrainingCenter;
