import "./history-card.scss";

import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { LearnerHistoryViewModel } from "../../Interfaces";

import { StatusEnum } from "../../Enums";
import { formatDate } from "../../locale-utils";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface HistoryCardProps {
    course: LearnerHistoryViewModel;
}

const HistoryCard = ({ course }: HistoryCardProps) => {
    const { t } = useTranslation();
    const learnerCourse = course as LearnerHistoryViewModel;

    return (
        <div className="history-card">
            <Container fluid>
                <Row>
                    <Col>
                        <div className="me-4 history-card__details">
                            <h4>
                                {course.CourseName}
                            </h4>
                            {learnerCourse.StatusEnum === StatusEnum.Completed && (
                                <a
                                    href={`/Learn/ViewCertificate/${learnerCourse.LearnerCourseId}`}
                                    className="text-link"
                                    title={t("View Certificate")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={t("View")}
                                >
                                    <i className="fas fa-certificate me-1"></i>
                                    {t("View Certificate")}
                                </a>
                            )}
                        </div>
                        <div className="me-4 history-card__details">
                            <Row>
                                <Col md>
                                    <h6 className="history-card__subheading">{t("Enrollment Date")}</h6>
                                    {formatDate(learnerCourse.EnrollmentDate)}
                                </Col>
                                <Col md>
                                    <h6 className="history-card__subheading">{t("Completion Date")}</h6>
                                    {formatDate(learnerCourse.CompletionDate)}
                                </Col>
                                <Col md>
                                    <h6 className="history-card__subheading">{t("Contact Hours")}</h6>
                                    {learnerCourse.ContactHours}
                                </Col>
                                <Col md>
                                    <h6 className="history-card__subheading">{t("Score")}</h6>
                                    {learnerCourse.Score}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr />
        </div>
    );
};

export default withAITracking(reactPlugin, HistoryCard);
