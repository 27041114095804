import * as React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SixBySix from "../../../Components/UI/UX-Layout/Content/six-by-six";
import useForm from "../../../hooks/useForm";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import TextInput from "../../../Components/Form/text-input";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";
import validate from "../../../FormValidationRules/changePasswordProfileValidationRules";
import AlertProperties from "../../../Interfaces/Platform/AlertProperties";
import Alert from "../../../Components/UI/alert";
import Tile from "../../../Components/UI/tile";
import { changePassword } from "../../Utils/profile";
import { returnPageLevelAlerts } from "../../Utils/alerts";
import usePageLevelAlerts from "../../../hooks/usePageLevelAlerts";
import { useTranslation } from "react-i18next";

interface Error {
    Domain: any;
    Message: string;
    MessageCode: number;
    MessageParams: any;
    Reason: string;
}

const ChangePassword = () => {
    const navigate = useNavigate();
    const { tp } = useParsedTranslation();
    const [pageLevelAlerts, addPageLevelAlert, removePageLevelAlerts] = usePageLevelAlerts();
    const [errorAlert, setErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [submitErrorAlert, setSubmitErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const { t } = useTranslation();

    const handleChangePassword = async () => {
        try {
            const response = await changePassword({ oldPassword: formFieldData.currentPassword, newPassword: formFieldData.newPassword });
            removeSubmitErrorAlert();
            removePageLevelAlerts();
            if (response.Data && response.Data.Success) {
                showErrorAlert({
                    alertLevel: { alertLevel: "success" },
                    arrowLink: null,
                    description: tp("Your password has been changed successfully."),
                    canDismiss: true,
                    show: true
                });
                removeSubmitErrorAlert();
                removePageLevelAlerts();
                handleChangeByGetEvent({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
            }
            if (response.Error) {
                if (response.Error.Errors && response.Error.Errors.length > 0) {
                    response.Error.Errors.forEach((i: Error) => {
                        return addPageLevelAlert({
                            alertLevel: { alertLevel: "error" },
                            arrowLink: null,
                            description: tp(i.Reason),
                            canDismiss: true
                        });
                    })
                } else {
                    showErrorAlert({
                        alertLevel: { alertLevel: "error" },
                        arrowLink: null,
                        description: tp("There was a problem changing the password, please try again."),
                        canDismiss: true,
                        show: true
                    });
                }
            }
        } catch (e) {
            showErrorAlert({
                alertLevel: { alertLevel: "error" },
                arrowLink: null,
                description: tp("There was a problem changing the password, please try again."),
                canDismiss: true,
                show: true
            });
        }
    };

    const removeErrorAlert = () => setErrorAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const removeSubmitErrorAlert = () => setSubmitErrorAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const {
        formFieldData,
        errors,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit
    } = useForm(handleChangePassword, validate);

    const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Uses regex patterns from HoneyBee component
        handleChangeByGetEvent({ newPassword: e.target.value });
        if (e.target.value) delete errors.newPassword;
        if (!e.target.value.match(/.{8,}/)) {
            errors.minLength = true;
        } else {
            delete errors.minLength;
        }
        if (!e.target.value.match(/[A-Z]/)) {
            errors.upperCase = true;
        } else {
            delete errors.upperCase;
        }
        if (!e.target.value.match(/[a-z]/)) {
            errors.lowerCase = true;
        } else {
            delete errors.lowerCase;
        }
        if (!e.target.value.match(/[0-9]/)) {
            errors.number = true;
        } else {
            delete errors.number;
        }
        if (!e.target.value.match(/[!@#$%`~^&*()\-_=+[\]{}.,<>\/\\?'";:|]/)) {
            errors.symbol = true;
        } else {
            delete errors.symbol;
        }
        if (e.target.value === "" && formFieldData.confirmPassword === "") {
            errors.passwordsMatch = true;
        } else if (e.target.value !== formFieldData.confirmPassword) {
            errors.passwordsMatch = true;
        } else {
            delete errors.passwordsMatch;
        }
    };

    const formExists = formFieldData && Object.entries(formFieldData).length > 0;

    const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeByGetEvent({ confirmPassword: e.target.value });
        if (e.target.value) delete errors.confirmPassword;
        if (formExists && formFieldData.newPassword !== "") {
            if ((e.target.value !== formFieldData.newPassword)) {
                errors.passwordsMatch = true;
            } else {
                delete errors.passwordsMatch;
            }
        }
    };

    const checkErrors = errors && Object.entries(errors).length > 0;
    const showErrorAlert = (alert: { show: boolean } & AlertProperties) => setErrorAlert(alert);
    const showSubmitErrorAlert = (alert: { show: boolean } & AlertProperties) => setSubmitErrorAlert(alert);

    const displayValidators = () => {
        const validations = {
            minLength: t('At least 8 characters'),
            upperCase: t('Uppercase character'),
            lowerCase: t('Lowercase character'),
            number: t('Number'),
            symbol: t('Symbol'),
            passwordsMatch: t('New password matches password confirmation')
        };
        const formExists = formFieldData && Object.entries(formFieldData).length > 0;
        return Object.entries(validations).map(([key, value], index: number) => {
            const classes = () => {
                if (formExists && formFieldData.newPassword) {
                    if (!checkErrors && formFieldData.newPassword !== formFieldData.confirmPassword) {
                        return 'fa-light change-password-validator-error';
                    } else {
                        if (errors.hasOwnProperty(key)) {
                            return 'fa-light change-password-validator-error';
                        } else {
                            return 'fa-solid change-password-validator-success';
                        }
                    }
                } else {
                    return 'fa-light change-password-validator-error';
                }
            };
            return (
                <div key={`password-validation-${index}`}>
                    <i className={`${classes()} fa-sharp fa-circle-check mr-2`}/>
                    {value}
                </div>
            );
        });
    };

    const haveSubmitErrors = () => {
        setSubmitErrorAlert({
            alertLevel: { alertLevel: 'error' },
            show: (errors?.submitMinLength || errors?.submitUpperCase || errors?.submitLowerCase || errors?.submitNumber || errors?.submitSymbol || errors?.submitPasswordsMatch),
            description: (
                <div>
                    <p>{ t("Support.EditProfile.PasswordReqs") }</p>
                    <ul>
                        {errors?.submitMinLength && <li>{ t("Support.EditProfile.Password8Chars")}</li>}
                        {errors?.submitUpperCase && <li>{ t("Support.EditProfile.Password1Upper") }</li>}
                        {errors?.submitLowerCase && <li>{ t("Support.EditProfile.Password1Lower") }</li>}
                        {errors?.submitNumber && <li>{ t("Support.EditProfile.Password1Number") }</li>}
                        {errors?.submitSymbol && <li>{ t("Support.EditProfile.Password1Symbol") }</li>}
                        {errors?.submitPasswordsMatch && <li>{ t("Support.EditProfile.PasswordMustMatch") }</li>}
                    </ul>
                </div>
            ),
            canDismiss: true,
            arrowLink: null
        });
    };

    React.useEffect(() => {
        if (errors && Object.entries(errors).length > 0) {
            haveSubmitErrors();
            removeErrorAlert();
            removePageLevelAlerts();
        }
    }, [errors]);

    return (
        <div className="page-content m-auto">
            <Container fluid className="page-padding mt-4">
                {errorAlert.show && (
                    <Alert
                        state={errorAlert.alertLevel}
                        description={errorAlert.description}
                        arrowLink={errorAlert.arrowLink}
                        canDismiss={errorAlert.canDismiss}
                        removeAlertParentHandler={removeErrorAlert}
                    />
                )}
                {submitErrorAlert.show && (
                    <Alert
                        state={submitErrorAlert.alertLevel}
                        description={submitErrorAlert.description}
                        arrowLink={submitErrorAlert.arrowLink}
                        canDismiss={submitErrorAlert.canDismiss}
                        removeAlertParentHandler={removeSubmitErrorAlert}
                    />  
                )}
                <>{returnPageLevelAlerts(pageLevelAlerts)}</>
                <Tile title={ t("Change Password") } customClassName="mt-0 p-4 change-password-tile">
                    <SixBySix
                        left={
                            <div className="change-password-form-container">
                                <TextInput
                                    label={ t("Current Password") }
                                    isError={errors.hasOwnProperty("currentPassword")}
                                    name="currentPassword"
                                    value={formFieldData.currentPassword}
                                    type="password"
                                    changeAction={handleChangeByUserEvent}
                                    id="currentPassword"
                                    isRequired
                                    errorLabel={errors.currentPassword}
                                />
                                <TextInput
                                    label={ t("New Password") }
                                    isError={errors.hasOwnProperty("newPassword")}
                                    name="newPassword"
                                    value={formFieldData.newPassword}
                                    type="password"
                                    changeAction={handleNewPassword}
                                    id="newPassword"
                                    isRequired
                                    errorLabel={errors.newPassword}
                                />
                                <TextInput
                                    label={ t("Confirm Password") }
                                    isError={errors.hasOwnProperty("confirmPassword")}
                                    name="confirmPassword"
                                    value={formFieldData.confirmPassword}
                                    type="password"
                                    changeAction={handleConfirmPassword}
                                    id="confirmPassword"
                                    isRequired
                                    errorLabel={errors.confirmPassword}
                                />
                                <div className="change-password-form-actions">
                                    <DestructiveButton
                                        label={<><i className="me-1 far fa-times" /> { t("Cancel") }</>}
                                        clickAction={() => navigate("/Support/Profile")}
                                    />
                                    <Button
                                        elementId="change-password-button"
                                        label={ t("Save New Password") }
                                        isSolid
                                        clickAction={handleSubmit}
                                    />
                                </div>
                            </div>
                        }
                        right={
                            <div className="change-password-validators-container">
                                <span className="fs18 mb-1 bold">{ t("New Password Requirements") }</span>
                                {displayValidators()}
                            </div>
                        }
                    />
                </Tile>
            </Container>
        </div>
    );
};

export default ChangePassword;