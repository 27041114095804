import * as React from "react";
import 'react-tagsinput/react-tagsinput.css'

import { Alert, Breadcrumb, Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Course, CourseObject, CourseSkuModel, SelectModel, CourseEditionModel, CourseEditionListModel } from "../Interfaces";
import { useParams, useLocation, Link, Navigate, useNavigate } from "react-router-dom";
import Select, { Options } from "react-select";
import { postQuickAddSeats } from "../ApiServices/Courses";
import { AdminBaseRoutes } from "../Routing/routes";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import Tagsinput from "react-tagsinput";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../hooks/useParsedTranslation";
import CourseIndustryForm from "./Components/course-industry-form";
import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../configuration-context";
import useApi from "../hooks/useApi";
import useCurriculumApi from "../hooks/useCurriculumApi";
import Toggle from "../Components/Form/toggle";
import { createCourse, fetchCourse, fetchRegions, fetchSpecialties, updateCourse } from "./Utils/course";
import CourseEditionForm from "./Components/course-edition-form";
import useFeatureFlags from "../hooks/useFeatureFlags";
import Table from "../Components/table";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';

const _URL = window.URL || window.webkitURL;

type Skus = {
    courseSkuId: string
    skuId: string
    code: string
    deleted: boolean
}

const CourseFormPage = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);

    const { pathname } = useLocation();
    const { courseId } = useParams();

    const navigate = useNavigate()

    const isCreateForm = pathname.includes("Create") as boolean;

    const ceuInputRef = React.createRef<HTMLInputElement>();

    const [course, setCourse] = React.useState<Course>(
        isCreateForm
            ? {
                courseId: "",
                name: "",
                createDate: "",
                onlineOnly: false,
                estimatedTimeHours: 0,
                estimatedTimeMinutes: 0,
                courseLanguageId: "",
                viewPeriod: null,
                productSkus: [],
                programSkus: [],
                previewRegions: [],
                courseObjects: []
            }
            : null
    )

    const [courseObjects, setCourseObjects] = React.useState<CourseObject[]>([]);
    const [selectedIndustries, setSelectedIndustries] = React.useState<string[]>([]);
    const [regionOptions, setRegionsOptions] = React.useState([]);
    const [previewRegions, setPreviewRegions] = React.useState<Options<{ value: number; label: string }>>([]);
    const [productSkus, setProductSkus] = React.useState<Skus[]>(null);
    const [programSkus, setProgramSkus] = React.useState<Skus[]>(null);
    const [thumbnail, setThumbnail] = React.useState<File>(null);
    const [thumbnailMetadata, setThumbnailMetadata] = React.useState<{
        height: number;
        width: number;
        img: HTMLImageElement;
    }>({
        height: 0,
        width: 0,
        img: null
    });
    const [newIndustryCount, setNewIndustryCount] = React.useState(1);
    const [newId, setNewId] = React.useState<string>("");

    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(!isCreateForm);
    const [validated, setValidated] = React.useState(false);
    const [courseNotFound, setCourseNotFound] = React.useState(false);
    const [errored, setErrored] = React.useState(false);
    const [showSaveError, setShowSaveError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [addingSeats, setAddingSeats] = React.useState(false);

    const [validationErrors, setValidationErrors] = React.useState({})
    const [duplicateCourseSkus, setDuplicateCourseSkus] = React.useState<string[]>(null);
    const [emptyNameError, setEmptyNameError] = React.useState(false);

    const [thumbnailPreviewSrc, setThumbnailPreviewSrc] = React.useState("");
    const [showEditionForm, setShowEditionForm] = React.useState(false);
    const [showEditionButton] = useFeatureFlags("ITLShowSpecialtyEditionCourseEditor");
    const [specialties, setSpecialties] = React.useState<any>([]);
    const [specialtyEditionQualifications, setSpecialtyEditionQualifications] = React.useState<CourseEditionModel[]>();
    const [deletedEditionCourse, setDeletedEditionCourse] = React.useState<null|CourseEditionModel>();

    const refreshSelectedIndustries = (newSelectedIndustries) => {
        if (newSelectedIndustries) {
            setSelectedIndustries(newSelectedIndustries);
        } else {
            setSelectedIndustries(courseObjects.map(a => a.industryId));
        }
    }

    const { fetchData: fetchLanguages, isLoading: languagesLoading, data: languages } = useApi({
        url: "/Q/Curriculum/AllLanguages",
        method: "POST",
        payload: {}
    });

    const { fetchData: fetchIndustries, isLoading: industriesLoading, data: industries } = useApi({
        url: "/Q/Curriculum/Industries",
        method: "GET",
        payload: {}
    });

    const { fetchData: fetchEditionCourses, isLoading: editionCoursesLoading, data: editionCourses } = useApi({
        url: "Q/Curriculum/AllEditionsOfCourse/" + courseId,
        method: "POST",
        payload: {}
    });

    const { fetchData: deleteThisEditionCourse, isLoading: deleteEditionCourseLoading } = useCurriculumApi("/C/Curriculum/RemoveEditionCourse", "POST");

    const columnHelper = createColumnHelper<CourseEditionModel>();
    const columns = React.useMemo<ColumnDef<CourseEditionModel>[]>(
        () => [
            columnHelper.accessor('specialty', {
                cell: info => tp(info.row.original.specialty),
                header: tp("Specialty"),
                size: 16,
                enableSorting: true,
            }),
            columnHelper.accessor('edition', {
                cell: info => tp("Edition." + info.row.original.edition) == "Edition." + info.row.original.edition ? info.row.original.edition : tp("Edition." + info.row.original.edition),
                header: tp("Edition"),
                enableSorting: true,
                size: 16
            }),
            columnHelper.accessor('qualification', {
                cell: info => tp(info.row.original.qualification),
                header: tp("Qualification"),
                enableSorting: true,
                size: 16
            }),
            columnHelper.accessor('courseEditionId', {
                cell: info => generateDeleteLink(info.row.original),
                header: tp("Delete"),
                enableSorting: false,
                size: 16
            })
        ],
        []
    );

    
    const generateDeleteLink = (editionCourse: CourseEditionModel) => {
        const deleteThisSpecialtyEditionQualification = { EditionId: editionCourse.editionCourseId , Specialty: editionCourse.specialty, Edition: editionCourse.edition, Qualification: editionCourse.qualification, CourseId: courseId };
        return (
            <>
                <a onClick={() => { setDeletedEditionCourse(editionCourse); deleteThisEditionCourse(deleteThisSpecialtyEditionQualification) }}>
                    <i className={'fal fa-trash-alt'} />
                </a >
            </>
        );
    }
    

    const returnEditionCourseRows = () => {
        return (
            <>
                <Row>
                    <Col sm="9" />
                    <Col sm="3">
                        <Button onClick={() => setShowEditionForm(true)}>+ {t("Create Edition")}</Button>
                    </Col>
                </Row>
                <Table
                    fetchData={false}
                    defaultData={specialtyEditionQualifications}                    
                    enableSearch={false}
                    enablePaging={false}
                    columns={columns}
                    defaultSorted={{ dataField: "specialty", order: "asc" }}
                    isLoading={editionCoursesLoading}
                    forceRefresh={forceRefresh}
                />
            </>
        );
    };

    React.useEffect(() => {

        async function getData() {
            try {
                setLoading(true);

                fetchEditionCourses();

                fetchLanguages();

                fetchIndustries();

                const specialties = await fetchSpecialties(configContext);
                setSpecialties(specialties);

                if (!isCreateForm) {

                    const courseLocalStorage = localStorage.getItem("course")

                    const course = courseLocalStorage ? JSON.parse(courseLocalStorage) : await fetchCourse(configContext, courseId)

                    const regionsLocal = await fetchRegions(configContext);
                    setRegionsOptions(regionsLocal);

                    if (course) {
                        setCourse(course);
                        setProductSkus(course?.productSkus);
                        setProgramSkus(course?.programSkus);
                        setCourseObjects(course?.courseObjects);
                        setSelectedIndustries(course?.courseObjects?.map(a => a.industryId));
                    } else {
                        setCourseNotFound(true);
                    }
                    const thumbnailPreviewSrc = course?.thumbnailFilename ? course?.thumbnailUrl : `${configContext?.SystemConfiguration?.CDNImagesUrl}/_logos/cpi-mark.svg`
                    setThumbnailPreviewSrc(thumbnailPreviewSrc)

                    let filteredRegions = regionsLocal?.filter((region) => course?.previewRegions?.some(previewRegion => previewRegion.regionId === region.regionId));
                    setPreviewRegions(
                        filteredRegions?.map(region => ({ value: region.regionId, label: region.name }))
                    );

                    if (courseLocalStorage) {
                        localStorage.removeItem("course")
                    }

                } else {
                    const thumbnailPreviewSrc = `${configContext?.SystemConfiguration?.CDNImagesUrl}/_logos/cpi-mark.svg`
                    setThumbnailPreviewSrc(thumbnailPreviewSrc)

                    const regionsLocal = await fetchRegions(configContext);
                    setRegionsOptions(regionsLocal);

                    let filteredRegions = regionsLocal?.filter((region) => course?.previewRegions?.some(previewRegion => previewRegion.regionId === region.regionId));
                    setPreviewRegions(
                        filteredRegions?.map(region => ({ value: region.regionId, label: region.name }))
                    );
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
            }
            setLoading(false);
        }

        getData();
    }, []);

    React.useEffect(() => {
        if (!editionCoursesLoading && editionCourses) {
            if (editionCourses.editionCourses && editionCourses.editionCourses.length > 0) {
                setSpecialtyEditionQualifications(editionCourses.editionCourses);
            } else {
                setSpecialtyEditionQualifications([]);
            }
            setForceRefresh(true);
        } else {
            setSpecialtyEditionQualifications([]);
        }
    }, [editionCoursesLoading]);

    React.useEffect(() => {
        if (!deleteEditionCourseLoading && specialtyEditionQualifications && specialtyEditionQualifications.length > 0) {
            setSpecialtyEditionQualifications(specialtyEditionQualifications.filter((editionCourse) => editionCourse.editionCourseId !== deletedEditionCourse.editionCourseId));
            setDeletedEditionCourse(null);
        }
    },
        [deleteEditionCourseLoading]);

    const handleThumbnailMetadata = (thumbnail: Blob) => {
        if (thumbnailMetadata.img) {
            _URL.revokeObjectURL(thumbnailMetadata.img.src);
        }

        if (thumbnail) {
            const img = new Image();
            img.src = _URL.createObjectURL(thumbnail);

            img.onload = function () {
                const width = img.naturalWidth,
                    height = img.naturalHeight;

                setThumbnailMetadata({ height, width, img });
                setThumbnailPreviewSrc(img.src)
            };
        }
    };

    const handleProductSkuChange = (tags: string[]) => {

        if (tags.length > 0) {
            tags[tags.length - 1] = tags[tags.length - 1].toUpperCase()
        }

        if (isCreateForm) {
            const newTags = tags.map(tag => ({ code: tag }))
            setProductSkus([...newTags])
        } else {
            const updatedProductSkus = productSkus.map(sku => ({
                ...sku,
                deleted: !tags.includes(sku.code),
            }))

            const newSkusToAdd = tags.filter(tag =>
                !productSkus.some(sku => sku.code === tag && !sku.deleted)).
                map(tag => ({ code: tag, deleted: false }))

            const combinedProductSkus = [...updatedProductSkus, ...newSkusToAdd,]

            const filteredSkus = Object.values(combinedProductSkus.reduce((acc, cur) => {
                acc[cur.code] = acc[cur.code] && acc[cur.code].skuId ? acc[cur.code] : cur
                return acc
            }, {}))

            const cleanSkus = filteredSkus.filter((item: any) => !(item.deleted && item.courseSkuId == undefined))

            setProductSkus([...cleanSkus])
        }
    }

    const handleProgramSkuChange = (tags: string[]) => {

        if (tags.length > 0) {
            tags[tags.length - 1] = tags[tags.length - 1].toUpperCase()
        }

        if (isCreateForm) {
            var newTags = tags.map(tag => ({ code: tag }))
            setProgramSkus([...newTags])
        } else {
            const updatedProgramSkus = programSkus.map(sku => ({
                ...sku,
                deleted: !tags.includes(sku.code),
            }))

            const newSkusToAdd = tags.filter(tag =>
                !programSkus.some(sku => sku.code === tag && !sku.deleted)
            ).map(tag => ({ code: tag, deleted: false }))

            const combinedProgramSkus = [...updatedProgramSkus, ...newSkusToAdd,]

            const filteredSkus = Object.values(combinedProgramSkus.reduce((acc, cur) => {
                acc[cur.code] = acc[cur.code] && acc[cur.code].skuId ? acc[cur.code] : cur
                return acc
            }, {}))

            const cleanSkus = filteredSkus.filter((item: any) => !(item.deleted && item.courseSkuId == undefined))

            setProgramSkus([...cleanSkus])
        }
    }

    const handleAddingIndustry = () => {
        if (!courseObjects) {
            let thisWillBecomeCourseObjects = [];
            thisWillBecomeCourseObjects.push({
                courseObjectId: undefined,
                courseId: course.courseId,
                code: "",
                description: ""
            });
            setCourseObjects(thisWillBecomeCourseObjects);
        } else {
            setCourseObjects([...courseObjects, {
                courseObjectId: undefined,
                courseId: course.courseId,
                code: "",
                description: ""
            }]);
        }
        refreshSelectedIndustries();
        courseObjects.map(a => a.industryId)
        setNewIndustryCount(newIndustryCount + 1);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setShowSaveError(false);
        setEmptyNameError(false)
        setValidationErrors({})

        const duplicateCourseSkus = productSkus?.filter(productSku => programSkus?.some(programSku => productSku.code === programSku.code)) ?? []

        setDuplicateCourseSkus(duplicateCourseSkus.map(duplicateSku => duplicateSku.code))

        if (!course.name.trim()) {
            setEmptyNameError(true)
        }

        if (
            form.checkValidity() === true &&
            duplicateCourseSkus.length === 0 &&
            (!thumbnail || (thumbnailMetadata.width === 500 && thumbnailMetadata.height === 500))
        ) {
            try {
                setSaving(true);

                course.contactHours = !ceuInputRef.current.value ? null : parseFloat(ceuInputRef.current.value);

                if (isCreateForm) {

                    const filteredRegions = previewRegions.map((region) => {
                        return {
                            regionId: region.value,
                        }
                    })

                    const newCourse = {
                        ...course,
                        previewRegions: filteredRegions,
                        productSkus: productSkus ?? [],
                        programSkus: programSkus ?? [],
                        isXylemeCourse: course.isXyleme ?? false,
                    }

                    const formData = new FormData();
                    formData.append("thumbnail", thumbnail);
                    formData.append("command", JSON.stringify(newCourse));

                    const newCourseId = await createCourse(configContext, formData, setValidationErrors);

                    if (newCourseId.length > 0) {

                        const courseForStorage = {
                            ...course,
                            previewRegions: filteredRegions,
                            productSkus: productSkus ?? [],
                            programSkus: programSkus ?? [],
                            isXylemeCourse: course.isXyleme ?? false,
                            courseId: newCourseId
                        }

                        localStorage.setItem("course", JSON.stringify(courseForStorage))
                        setSaving(false);
                        setNewId(newCourseId);
                        setRedirect(true)
                    } else {
                        console.error(courseId);
                        setShowSaveError(true);
                        setSaving(false);
                    }
                } else {
                    const cleanRegions = previewRegions.map((region) => region.value)

                    const updatedRegions = course.previewRegions.map(region => ({
                        ...region,
                        deleted: !cleanRegions.includes(region.regionId),
                    }))

                    const newRegionsToAdd =
                        previewRegions.filter(newRegion =>
                            !updatedRegions.some(region => region.regionId === newRegion.value && !region.deleted))
                            .map(filteredRegionId => ({ regionId: filteredRegionId.value, deleted: false }))

                    const combinedRegions = [...updatedRegions, ...newRegionsToAdd]

                    const updatedCourse = {
                        ...course,
                        productSkus: productSkus ?? [],
                        programSkus: programSkus ?? [],
                        previewRegions: combinedRegions,
                        isXylemeCourse: course.isXyleme ?? false,
                    }

                    const formData = new FormData();
                    formData.append("thumbnail", thumbnail);
                    formData.append("command", JSON.stringify(updatedCourse));

                    const response = await updateCourse(configContext, formData, setValidationErrors);
                    if (response) {
                        setSaving(false);
                        setRedirect(false)
                        navigate(0)
                    } else {
                        console.error(response.Message);
                        setShowSaveError(true);
                        setSaving(false);
                    }
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setShowSaveError(true);
                setSaving(false);
            }
        }

        setValidated(true);
    };

    const handleQuickAddSeats = async () => {
        setAddingSeats(true);
        await postQuickAddSeats(course.CourseId);
        setAddingSeats(false);
    };

    if (redirect) {
        if (newId.length > 0) {
            return <Navigate to={AdminBaseRoutes.ManageCoursesEdit.fullPath.replace(":courseId", newId.toString())} />;
        } else {
            return <Navigate to={AdminBaseRoutes.ManageCourses.fullPath} />;
        }
    }

    if (loading || errored || courseNotFound || languagesLoading || industriesLoading || editionCoursesLoading) {
        return (
            <PageMessages
                loading={loading}
                errored={errored}
                notFound={courseNotFound}
                notFoundMessage={t("Course with courseId {{courseId}} was not found.", { courseId: courseId })} //`Error: Course with CourseId ${courseId} not found.`
            />
        );
    }

    let fileLabel = t("Course File");
    const invalidThumbnail = thumbnail && (thumbnailMetadata.height !== 500 || thumbnailMetadata.width !== 500);
    
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: AdminBaseRoutes.ManageCourses.fullPath }}>
                    {t("Manage Courses")}
                </Breadcrumb.Item>
                {isCreateForm ? (
                    <Breadcrumb.Item active>{t("New Course")}</Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item active>{course.name}</Breadcrumb.Item>
                )}
            </Breadcrumb>
            {showSaveError && <Alert variant="danger">{t("saveError")}</Alert>}
            {/*Error: An error occurred while saving, please try again.*/}
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="text-gray-700">{t("Course Information")}</h4>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ maxWidth: "768px" }}>
                            <Form.Group className="row-padding" as={Row} controlId="name">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Name")}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        required
                                        maxLength={255}
                                        value={course.name}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            const newValue = e.target.value
                                            setCourse({ ...course, name: newValue })
                                            if (newValue.trim()) {
                                                setEmptyNameError(false)
                                            }
                                        }}
                                    />
                                </Col>
                                {emptyNameError && (
                                    <Col
                                        xs="12"
                                        className="course-form-error"
                                    >
                                        <Form.Label column>
                                            {"The course name cannot be empty."}
                                        </Form.Label>
                                    </Col>
                                )}
                            </Form.Group>
                            {!isCreateForm && returnEditionCourseRows()}
                            <Form.Group as={Row} controlId="contact-hours">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Contact Hours")}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        ref={ceuInputRef}
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        maxLength={255}
                                        pattern="[0-9]*.[0-9]*"
                                        defaultValue={course.contactHours}
                                    />
                                </Col>
                                <Form.Label className="label-title" column>
                                    {t("Template Id")}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        maxLength={255}
                                        pattern="[0-9]*"
                                        value={course.templateId}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            if (e.target.value == "") {
                                                setCourse({ ...course, templateId: null });
                                            } else {
                                                setCourse({ ...course, templateId: isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value) });
                                            }
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="score">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Minimum Score")}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        maxLength={255}
                                        pattern="[0-9]*"
                                        value={course.minimumScore}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            if (e.target.value == "") {
                                                setCourse({ ...course, minimumScore: null });
                                            } else {
                                                setCourse({ ...course, minimumScore: isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value) })
                                            }
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="viewPeriod">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Course Viewability Period")}
                                </Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            pattern="[0-9]*[1-9][0-9]*"
                                            aria-describedby="daysInputAppend"
                                            value={course.viewPeriod}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                if (e.target.value == "") {
                                                    setCourse({ ...course, viewPeriod: null });
                                                } else {
                                                    setCourse({ ...course, viewPeriod: parseInt(e.target.value) });
                                                }
                                            }}
                                        />
                                        <InputGroup.Text id="daysInputAppend">
                                            {t("days")}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col xs="12" md="4">
                                    <Form.Group as={Row} controlId="onlineOnly">
                                        <Form.Label className="label-title" column>{t("Is Online Only")}</Form.Label>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="onlineOnly"
                                                label=" "
                                                className="col-form-label"
                                                checked={course.onlineOnly}
                                                onChange={() =>
                                                    setCourse({ ...course, onlineOnly: !course.onlineOnly })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col xs="12" md="8">
                                    <Row>
                                        <Col xs="4" sm="2">
                                            <Form.Label className="label-title" column>{"Est Time"}</Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="estTimeHours">
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        aria-describedby="hoursInputAppend"
                                                        value={course.estimatedTimeHours?.toString()}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            setCourse({
                                                                ...course,
                                                                estimatedTimeHours:
                                                                    e.target.value === ""
                                                                        ? 0
                                                                        : parseFloat(e.target.value)
                                                            })
                                                        }
                                                    />
                                                    <InputGroup.Text id="hoursInputAppend">
                                                        {t("hrs")}
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="estTimeMin">
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        aria-describedby="minutesInputAppend"
                                                        value={course.estimatedTimeMinutes?.toString()}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            setCourse({
                                                                ...course,
                                                                estimatedTimeMinutes:
                                                                    e.target.value === ""
                                                                        ? 0
                                                                        : parseFloat(e.target.value)
                                                            })
                                                        }
                                                    />
                                                    <InputGroup.Text id="minutesInputAppend">
                                                        {t("min")}
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Form.Group className="row-padding" as={Row} controlId="language">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Language")}
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        as="select"
                                        required
                                        value={course.courseLanguageId}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                            setCourse({
                                                ...course,
                                                courseLanguageId: e.target.value ? e.target.value : null
                                            })
                                        }
                                    >
                                        <option key="0" value="">
                                            {t("Select")}
                                        </option>
                                        {languages?.map((x) => (
                                            <option key={`${x.courseLanguageId}`} value={`${x.courseLanguageId}`}>{`${x.language}`}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column sm="2">
                                    {t("Product SKUs")}
                                </Form.Label>
                                <Col>
                                    <Tagsinput
                                        value={productSkus?.filter((sku: Skus) => !sku.deleted).map(s => s.code) ?? []}
                                        onChange={handleProductSkuChange} />
                                </Col>
                                {validationErrors?.ProductSkus?.length > 0 && (
                                    <Col
                                        xs="12"
                                        className="course-form-error"
                                    >
                                        {
                                            validationErrors?.ProductSkus[0]
                                        }
                                    </Col>
                                )}
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column sm="2">
                                    {t("Program SKUs")}
                                </Form.Label>
                                <Col>
                                    <Tagsinput
                                        value={programSkus?.filter((sku: Skus) => !sku.deleted).map(s => s.code) ?? []}
                                        onChange={handleProgramSkuChange} />
                                </Col>
                                {validationErrors?.ProgramSkus?.length > 0 && (
                                    <Col
                                        xs="12"
                                        className="course-form-error"
                                    >
                                        {
                                            validationErrors?.ProgramSkus[0]
                                        }
                                    </Col>
                                )}
                                {duplicateCourseSkus && duplicateCourseSkus.length > 0 && (
                                    <Col
                                        xs="12"
                                        className="course-form-error"
                                    >
                                        {`${t(
                                            "Course cannot contain same SKU as different types"
                                        )}: ${duplicateCourseSkus?.join(", ")}`}
                                    </Col>
                                )}
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column sm="2">
                                    {t("Preview Regions")}
                                </Form.Label>
                                <Col>
                                    <Select
                                        classNamePrefix="react-select"
                                        options={regionOptions?.map(region => ({ value: region.regionId, label: region.name }))}
                                        isMulti
                                        onChange={(options: Options<{ value: number; label: string }>) => {
                                            setPreviewRegions(options)
                                        }}
                                        value={previewRegions}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column>
                                    {t("Is Xyleme")}
                                </Form.Label>
                                <Toggle
                                    label=""
                                    name="isXyleme"
                                    id="xyleme-toggle"
                                    onLabel="Yes"
                                    offLabel="No"
                                    defaultOn={course.isXyleme ?? false}
                                    onChange={(event) => {
                                        setCourse({
                                            ...course,
                                            isXyleme: event.target.checked,
                                        })
                                    }} />
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column sm="2">
                                    {t("Thumbnail")}
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control
                                        id="thumbnailUpload"
                                        type="file"
                                        accept="image/*"
                                        isInvalid={invalidThumbnail}
                                        onChange={(e: any) => {
                                            if (e.target.files[0]) {
                                                handleThumbnailMetadata(e.target.files[0]);
                                                setThumbnail(e.target.files[0]);
                                            } else {
                                                _URL.revokeObjectURL(thumbnailMetadata.img.src);
                                                setThumbnail(null);
                                                setThumbnailMetadata({ height: 0, width: 0, img: null });
                                            }
                                        }}
                                    />
                                    {(!invalidThumbnail || !validated) && (
                                        <Form.Text>{t("Image must be 500x500px dimensions.")}</Form.Text>
                                    )}
                                    {invalidThumbnail && validated && (
                                        <Form.Control.Feedback type="invalid">
                                            {t("Image must be 500x500px dimensions.")}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                <Col sm="2">
                                    <Button
                                        variant="light"
                                        size="sm"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            setThumbnail(null);
                                            setThumbnailMetadata({ height: 0, width: 0, img: null });
                                            const thumbnailUpload = document.getElementById(
                                                "thumbnailUpload"
                                            ) as HTMLInputElement;
                                            thumbnailUpload.value = "";
                                        }}
                                    >
                                        {t("Remove")}
                                    </Button>
                                </Col>
                                <Col sm="3">
                                    <img src={thumbnailPreviewSrc} style={{ height: "150px", width: "150px" }} />
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col sm="6" >                                    
                                </Col>
                                <Col xs="6" sm="3">
                                    <Button
                                        variant="light"
                                        style={{ width: "100%" }}
                                        onClick={() => setRedirect(true)}
                                        disabled={saving}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs="6" sm="3">
                                    <Button variant="primary" type="submit" style={{ width: "100%" }} disabled={saving}>
                                        {saving ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                                            </>
                                        ) : (
                                            t("Save")
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <div className="mt-5 border-top border-dark pt-4">
                            <h4 className="text-gray-700">{t("Industries")}</h4>
                            {courseObjects?.map(function (object, index) {
                                return (<CourseIndustryForm key={index} index={index}
                                    courseObjects={courseObjects}
                                    setCourseObjects={setCourseObjects}
                                    industryOptions={industries}
                                    courseId={course.courseId}
                                    isXyleme={course.isXyleme}
                                    forceSelectedIndustryRefresh={refreshSelectedIndustries}
                                    selectedIndustries={selectedIndustries}
                                    setSelectedIndustries={setSelectedIndustries}
                                />)
                            })}

                            <div>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`addIndustryTooltip`}>
                                        {isCreateForm ? (
                                            t("Save the Course first before adding an industry.")
                                        ) : (t("Add new industry specific course objects"))}
                                    </Tooltip>} children={undefined}                                >
                                    <span className="ps-1 pe-1 pb-3 pt-3">
                                        <Button
                                            variant="primary"
                                            style={{}}
                                            onClick={() => handleAddingIndustry()}
                                            disabled={isCreateForm}
                                        >
                                            {t("+ Add Industry")}
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>

                    </Col>
                    <Col>
                        <Row style={{ flexDirection: "row-reverse" }}>
                            {course?.CourseId && (
                                <Button onClick={handleQuickAddSeats} disabled={isCreateForm || addingSeats}>
                                    {addingSeats ? (
                                        <>
                                            <i className="fas fa-spinner fa-spin"></i> {t("Adding")}
                                        </>
                                    ) : (
                                        t("Quick add seats")
                                    )}
                                </Button>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
            {showEditionForm &&
                <CourseEditionForm
                specialties={specialties}
                languages={languages}
                courseLanguageId={course.courseLanguageId}
                courseId={course.courseId}
                isVisible={showEditionForm}
                onHide={() => setShowEditionForm(false)}
                onSave={
                    (response) => {
                        response.json().then((editionCourse) => {
                            setSpecialtyEditionQualifications([{ specialty: editionCourse.specialty, edition: editionCourse.edition, qualification: editionCourse.qualification, courseEditionId: editionCourse.editionCourseId, courseId: editionCourse.courseId, culture: editionCourse.culture }, ...specialtyEditionQualifications]);
                            setShowEditionForm(false);
                        });
                    }}
                />}
        </>
    );
};

export default withAITracking(reactPlugin, CourseFormPage);
