import { ApiResponse, DELETE, GETWITHERROR, JsonResponseModel, POSTWITHERROR, PUT } from ".";
import { AppSettingModel, CertificateOptionModel, CourseLanguageModel } from "../Interfaces";

import { HoneyBeeSettingDefinition } from "../Interfaces/HoneyBeeSettingDefinition";
import { template } from "../helper-functions";

const FETCH_APP_SETTING_DEFINITIONS_URL = "/AppSettings/GetAppSettingDefinitions";
const FETCH_APP_SETTINGS_URL = "/AppSettings/GetAppSettingsAsync";
const POST_APP_SETTING_URL = "/AppSettings/CreateAppSettingAsync";
const PUT_APP_SETTING_URL = "/AppSettings/UpdateAppSettingAsync";
const DELETE_APP_SETTING_URL_TEMPLATE = template`/AppSettings/DeleteAppSettingAsync?appSettingId=${0}`;

const FETCH_COURSE_LANGUAGES_URL = "/AppSettings/GetCourseLanguagesAsync";
const POST_COURSE_LANGUAGE_URL = "/AppSettings/CreateCourseLanguageAsync";
const PUT_COURSE_LANGUAGE_URL = "/AppSettings/UpdateCourseLanguageAsync";

const GET_CERTIFICATE_OPTIONS_URL = "/AppSettings/GetCertificateOptions";

export function fetchAppSettingDefinitions(): Promise<JsonResponseModel<HoneyBeeSettingDefinition[]>> {
    return GETWITHERROR<HoneyBeeSettingDefinition[]>(FETCH_APP_SETTING_DEFINITIONS_URL);
}

export function fetchAppSettings(): Promise<JsonResponseModel<AppSettingModel[]>> {
    return GETWITHERROR<AppSettingModel[]>(FETCH_APP_SETTINGS_URL);
}

export function createAppSetting(appSetting: AppSettingModel): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR<ApiResponse>(POST_APP_SETTING_URL, appSetting);
}

export function updateAppSetting(appSetting: AppSettingModel): Promise<JsonResponseModel<ApiResponse>> {
    return PUT<ApiResponse>(PUT_APP_SETTING_URL, appSetting);
}

export async function deleteAppSetting(appSettingId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_APP_SETTING_URL_TEMPLATE(appSettingId));
}

export async function fetchCourseLanguages(): Promise<JsonResponseModel<CourseLanguageModel[]>> {
    return GETWITHERROR<CourseLanguageModel[]>(FETCH_COURSE_LANGUAGES_URL);
}

export function createCourseLanguage(courseLanguage: CourseLanguageModel): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR<ApiResponse>(POST_COURSE_LANGUAGE_URL, courseLanguage);
}

export function updateCourseLanguage(courseLanguage: CourseLanguageModel): Promise<JsonResponseModel<ApiResponse>> {
    return PUT<ApiResponse>(PUT_COURSE_LANGUAGE_URL, courseLanguage);
}

export function fetchCertificateOptions(): Promise<JsonResponseModel<CertificateOptionModel[]>> {
    return GETWITHERROR<CertificateOptionModel[]>(GET_CERTIFICATE_OPTIONS_URL);
}
