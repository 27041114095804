import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const EvaluatingEffectiveness = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}

                                <h1 className="page-title-h1 title-margin">{t('EvalEff-Title')}</h1>
                                <p className="mw-900">{t('EvalEff-Intro')}</p>
                            </>
                        }
                    />
                    <Tile title={ t('EvalEff-SectionTitle') } hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <a href="https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf" target="_new"><img width="250px" className="mt-3" src="https://cdn.crisisprevention.com/media/training-center/images/evaluate.png" alt="Activity Writing Guide"></img></a>
                                        <ArrowLink data={{ label:  t('EvalEff-DownloadGuide') , href: "https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity-Writing-Guide.pdf", openInNewTab: true }} />
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('EvalEff-SectionContent')}
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="mb-3">
                                        <Button label={t('BTN-GuideParticipants')} isSolid={false} elementId="buttonId" href="guiding-participants-to-debrief" />
                                    </div>
                                    <div className="mb-3">
                                        <Button label={t('BTN-DownloadResources')} isSolid={true} elementId="buttonId" href="download-resources" />
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default EvaluatingEffectiveness;
