import * as React from 'react'; getUserClaims()
import { getUserClaims } from '../helper-functions';
import { CpiRoute } from '../Routing/routes';
import { RbacContext } from '../rbac-context';
import { RoleEnum } from '../Enums';
import { ConfigContext } from '../configuration-context';

import { fetchProgramsOrganizations } from '../Pages/Components/DashboardPlatformUtils';

const useRouteAccessCheck = () => {

    /* example data from jwt (still need role added?)

        {
            culture: 'en-US'
        }

    */

    const rbac = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const iAm = {
        region: "en-US",
        roles: ["ci"],
        certifications: [{id: "vi", isActive: true}],
        qualification: "tra"
    }

    const hasCert = (certData:any[], certObjectArray:any[]) => {
        // checks for a matching cert, also checks if active status is required - if so compare on that too
        //return certObjectArray.some(certObject => ((certId == certObject.id) && ((certObject.needsActiveStatus && iAm.certification.isActive) || (!certObject.needsActiveStatus))));        

        let intersectedArray:any[] = [];

        // {id: "vi", needsActiveStatus: true}
        if (certData && Array.isArray(certData) && (certData.length > 0)) {
            intersectedArray = certObjectArray.filter(certObject => {

                if (certObject.needsActiveStatus) {
                    let hasCert = false;

                    certData.forEach((cert) => {
                        if (!hasCert) {
                            hasCert = (cert.specialty == certObject.id) && (cert.status == "Active");
                        }
                    });

                    return hasCert;

                } else {
                    let hasCert = false;

                    certData.forEach((cert) => {
                        if (!hasCert) {
                            hasCert = (cert.specialty == certObject.id);
                        }
                    });

                    return hasCert;

                }

            });
        }

        return (intersectedArray.length > 0);
    }

    const hasQualification = (certData:any[], qualificationAccessArray:string[]) => {
        const myQualifications = getAllQualifications(certData);

        let intersectedArray:string[] = [];
        if (myQualifications && myQualifications.length > 0) {
            intersectedArray = qualificationAccessArray.filter(accessQualification => myQualifications.includes(accessQualification));
        }

        return (intersectedArray.length > 0);
    }

    const hasRoles = (myRoles:string[], routeRoles:string[]) => {
        // filters the roles on the route access list down to what is included on the user account.
        // if this array is longer than zero, we know we have at least one matching value and can grant access
        let intersectedArray:string[] = [];
        if (myRoles && myRoles.length > 0) {
            intersectedArray = routeRoles.filter(role => myRoles.includes(role));
        }

        // return true if we've found more than zero matching values
        return (intersectedArray.length > 0);
    }

    const getAllQualifications = (certs:any[]) => {
        let qualificationsArray:string[] = [];
        if (Array.isArray(certs) && (certs.length > 0)) {
            certs.forEach((cert) => {
                if (cert.qualifications && Array.isArray(cert.qualifications) && (cert.qualifications.length > 0)) {
                    cert.qualifications.forEach((qualification:string) => {
                        qualificationsArray.push(qualification);
                    })
                }
            })
        }

        return qualificationsArray;
    }

    const isActiveCI = () => {
        // check if user has CI role, and that at least one certification is active
        if (
            rbac.userContext.RolesByName.includes(RoleEnum.CertifiedInstructor) &&
            rbac.userContext.CertificationData &&
            rbac.userContext.CertificationData.length > 0 &&
            rbac.userContext.CertificationData.some((certification: any) => certification.status.toLowerCase() == "active")
        ) {
            return true;
        } else {
            return false;
        }
    }

    const rac = (route:CpiRoute) => {
        return (((route.requiresCompleteUserData && rbac.userContext && rbac.userContext.UserDataIsComplete) || (!route.requiresCompleteUserData)) && 
        ((route.accessWithCertAgreementOnly && rbac.userContext && rbac.userContext.AgreementCriteriaMet) || (!route.accessWithCertAgreementOnly)) &&
        ((route.accessWithAnyActiveCertOnly && (rbac.userContext.IsBAOfActiveCIs || isActiveCI())) || (!route.accessWithAnyActiveCertOnly)) &&
        ((route.accessCultures && (route.accessCultures.indexOf((rbac.userContext.Country) ? rbac.userContext.Country : 'en-US') > -1) || (!route.accessCultures || (Array.isArray(route.accessCultures) && (route.accessCultures.length == 0)))) &&
        (route.accessRoles && hasRoles(rbac.userContext.RolesByName, route.accessRoles) || (!route.accessRoles || (Array.isArray(route.accessRoles) && (route.accessRoles.length == 0)))) &&
        (((route.accessCertifications && hasCert(rbac.userContext.CertificationData, route.accessCertifications)) || (!route.accessCertifications || (Array.isArray(route.accessCertifications) && (route.accessCertifications.length == 0))))) &&
        (route.accessQualifications && (hasQualification(rbac.userContext.CertificationData, route.accessQualifications)) || (!route.accessQualifications || (Array.isArray(route.accessQualifications) && (route.accessQualifications.length == 0)))))) ||
        (route.accessWithStaffPopulation && isCPIStaff())
    }

    const isCPIStaff = () => {        
        return (configContext?.SystemConfiguration?.CPIPopulationId && getUserClaims() && getUserClaims().populationId && (getUserClaims().populationId == configContext?.SystemConfiguration?.CPIPopulationId)) ? true : false;
    }

    const canChangeRegion = () => {
        return (isCPIStaff() || (rbac.userContext.RolesByName && rbac.userContext.RolesByName.includes(RoleEnum.BusinessAdministrator)));
    }

    return { rac, isCPIStaff, canChangeRegion }
}

export default useRouteAccessCheck;