import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ConfigContext } from "../../configuration-context";
import { RbacContext } from "../../rbac-context";
import useForm from "../../hooks/useForm";
import usePageLevelAlerts from "../../hooks/usePageLevelAlerts";
import { IParticipant } from "../../Interfaces/Platform/Participants";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";
import validateRules from "../../FormValidationRules/editParticipant";
import DestructiveButton from "../UI/cancel-button";
import Button from "../UI/button";
import TextInput from "../Form/text-input";
import Select from "../Form/select";
import { ClassParticipantHistory } from "../../Interfaces/Platform/Participants";
import ClassDetail from "./view-participant-class-detail";
import { returnPageLevelAlerts } from "../../Pages/Utils/alerts";
import { getUserClaims } from "../../helper-functions";
import useCustomerSupportContactInfo from "../../hooks/useCustomerSupportContactInfo";
import { returnOrganizations } from "../../Pages/Components/DashboardPlatformUtils";

import { fetchOrganizations, fetchParticipantHistory, updateParticipant } from "../../Pages/Utils/participants";
import validateWithEmail from "../../FormValidationRules/editParticipantWithEmail";
import { updateParticipantInfo } from "../../stores/classParticipantsSlice";

const ViewParticipantDetails = (props: {
    participant: IParticipant,
    organizationId: number | string,
    handleEdit?: () => void,
    editParticipantInfo?: (p: IParticipant) => void,
    onClose: () => void,
    addPageLevelAlert: any,
    onRemoveParticipantFromClass?: () => void,
    canEditParticipants: string | boolean,
    canEditClassParticipants: string | boolean,
    canViewCertificates: string | boolean,
    canTransferParticipants: string | boolean,
    canSendEnrollmentEmail: string | boolean,
    fromParticipants?: boolean,
    classId: string,  
    trainingFormat: string,
}): JSX.Element => {
    const culture = getUserClaims()?.culture ?? "en-US";
    const { getSupportContactInfoByCultureCode } = useCustomerSupportContactInfo();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const configContext = React.useContext(ConfigContext);
    const rbacContext = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [participantInfo, setParticipantInfo] = React.useState({
        firstName: props.participant.firstName,
        lastName: props.participant.lastName,
        email: props.participant.email,
        externalId: props.participant.externalId,
        organizationId: props.participant.organizationId,
        organizationName: props.participant.organizationName               
    });
    const [showEdit, setShowEdit] = React.useState(false);
    const [participantHistory, setParticipantHistory] = React.useState([]);
    const [organizationOptions, setOrganizationOptions] = React.useState([]);
    const [orgs, setOrgs] = React.useState([]);
    const participantName = `${participantInfo.firstName} ${participantInfo.lastName}`;

    const getOrganizations = async () => {
        const data = await fetchOrganizations(apimBaseUrl, configContext, rbacContext.userContext.SelectedOrganization.toString());
        setOrganizationOptions(returnOrganizations(data));
        setOrgs(data);
    };

    const getParticipantHistory = async () => {
        const data = await fetchParticipantHistory(apimBaseUrl, configContext, props.participant.participantId);
        setParticipantHistory(data);
    };

    const handleRemoveParticipant = (certification: ClassParticipantHistory) => {
        // Removes Class from Participants Detail Modal after Removing From Class
        setParticipantHistory((prevState) => {
            if (props.onRemoveParticipantFromClass) props.onRemoveParticipantFromClass();
            return prevState.filter((i) => i.classId !== certification.classId);
        });
    };

    React.useEffect(() => {
        getParticipantHistory();
        getOrganizations();
    }, []);

    React.useEffect(() => {
        if (organizationOptions.length > 0) {
            handleChangeByGetEvent({ organizationId: props.organizationId.toString() });
            const organizationName = organizationOptions.find((i) => i.value === participantInfo.organizationId).label
            setParticipantInfo({ ...participantInfo, organizationName });
            handleChangeByGetEvent({
                organizationName
            });
        }
    }, [organizationOptions]);

    const handleSaveParticipant = async () => {
        setIsLoading(true);
        try {
            const data = {
                ...formFieldData,
                orgId: orgs.filter((i) => i.organizationId === formFieldData.organizationId)[0].orgId,
                organizationIds: [...organizationOptions.filter((i) => i.value !== null).map((i) => i.value)],
                enrolled: false,
                locale: culture,
                trainingFormat: props.trainingFormat
            };
            const response = await updateParticipant(apimBaseUrl, configContext, data);
            setIsLoading(false);
            if (response.participantStatus === "Updated") {
                const organizationName = organizationOptions.find((i) => i.value === formFieldData.organizationId).label;
                setParticipantInfo({ ...participantInfo, ...formFieldData, organizationName });
                dispatch(updateParticipantInfo(data));
                setShowEdit(false);
            }
            else {
                handleResponseStatus(response.participantStatus);
            }
        } catch (e) {
            console.error(e);
            setIsLoading(false);
            addPageLevelAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
        } finally {
        }
    };

    const handleResponseStatus = (status?: any) => {
        switch (status) {
            case "Updated":
                break;
            case "AlreadyExists":
                addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Response-Already-Exists"),
                    canDismiss: true
                });
                break;
            case "400":
                addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Response-400"),
                    canDismiss: true
                });
                break;
            case "500":
                addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Response-500"),
                    canDismiss: true
                });
                break;
            default:
                addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Response-Default"),
                    canDismiss: true
                });
                break;
        }
    };



    const isEmailRequired = props.participant.email !== null && props.participant.email !== undefined && props.participant.email.length > 0;
    const {
        formFieldData,
        errors,
        handleChangeByUserEvent,
        handleChangeByGetEvent,
        handleSubmit
    } = useForm(handleSaveParticipant, !isEmailRequired ? validateRules : validateWithEmail);

    React.useEffect(() => {
        handleChangeByGetEvent({
            participantId: props.participant.participantId,
            firstName: props.participant.firstName,
            lastName: props.participant.lastName,
            email: props.participant.email,
            organizationId: props.participant.organizationId,
            organizationName: props.participant.organizationName,
            externalId: props.participant.externalId
        });
    }, []);

    const handleChangeOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            organizationId: e.value,
            organizationName: e.label
        });
    };

    const handleResendEnrollmentEmail = (isError: boolean) => {
        addPageLevelAlert({
            alertLevel: { alertLevel: isError ? "error" : "success" },
            arrowLink: null,
            description: isError ? t("Class.ResendEmailError") : t("Class.ResendEmailSuccess"),
            canDismiss: true,
            show: true
        });
    };

    const displayParticipantHistory = () => {
        return participantHistory && participantHistory.length > 0 && participantHistory.map((history, index) => {
            return (
                <React.Fragment key={`participant-history-${index}`}>
                    {index === 0 && <div className="view-participant-detail-separator my-3" />}
                    <ClassDetail
                        onRemoveParticipantFromClass={handleRemoveParticipant}
                        canTransferParticipants={props.canTransferParticipants}
                        canEditClassParticipants={props.canEditClassParticipants}
                        canViewCertificates={props.canViewCertificates}
                        canSendEnrollmentEmail={props.canSendEnrollmentEmail}
                        participantHistory={history}
                        classId={props.classId}
                        fromParticipants={props.fromParticipants}
                        onResendEnrollmentEmail={(isError) => handleResendEnrollmentEmail(isError)}
                        onClose={props.onClose}
                    />
                    {participantHistory.length > 1 && index < participantHistory.length - 1 && <div className="view-participant-detail-separator my-3" />}
                </React.Fragment>
            );
        });
    };

    const handleShowEdit = () => {
        if (props.canEditParticipants) {
            setShowEdit(true);
        }
        return;
    };

    return (
        <div className="view-participant-detail-container mb-3">
            <div className="alert-wrapper mt-4">
                <>{returnPageLevelAlerts(pageLevelAlerts)}</>
            </div>
            <div className="view-participant-detail-container-wrapper mt-2 mb-2">
                <h3 className="mb-3 fw600 color-black participant-listing-text-modal">{participantName}</h3>
                <p className="mb-0 small participant-listing-text-modal">{participantInfo.organizationName}</p>
                <p className="mb-0 small participant-listing-text-modal">{participantInfo.email}</p>
                <p className="mb-0 small participant-listing-text-modal">{participantInfo.externalId}</p>
                {(!showEdit && props.canEditParticipants) && (
                    <div onClick={handleShowEdit} className="edit-participant-button mt-1">
                        <span className="small"><i className="fas fa-edit"></i> { t("Edit Participant Info") }</span>
                    </div>
                )}                
                {showEdit && (
                    <div className="edit-participant-container">
                       {props.participant.contactId !== null && (
                            <p className="small">{t("Unable-To-Edit-Participant", { phone: getSupportContactInfoByCultureCode(rbacContext.userContext.Country).phone })}</p>
                        )}                        
                        <div className="edit-participant-form">                            
                            <TextInput
                                label={t("First Name")}
                                name="firstName"
                                id="firstName"
                                maxLength={50}
                                isError={errors.hasOwnProperty('firstName')}
                                value={formFieldData.firstName}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.firstName}
                                isReadOnly={props.participant.contactId !== null}
                                displayAsText={props.participant.contactId !== null}
                                isRequired                                                                
                            />
                            <br />
                            <TextInput
                                label={t("Last Name")}
                                name="lastName"
                                id="lastName"
                                maxLength={50}
                                isError={errors.hasOwnProperty('lastName')}
                                value={formFieldData.lastName}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.lastName}
                                isReadOnly={props.participant.contactId !== null}
                                displayAsText={props.participant.contactId !== null}
                                isRequired
                            />
                            <br />
                            <TextInput
                                label={t("Email")}
                                name="email"
                                id="email"
                                maxLength={150}
                                isError={errors.hasOwnProperty('email')}
                                value={formFieldData.email}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.email}
                                isReadOnly={props.participant.contactId !== null}
                                displayAsText={props.participant.contactId !== null}
                                isRequired={isEmailRequired}
                            />
                            <br />
                            <Select
                                label={t("Organization")}
                                filter
                                name="organizationId"
                                id="organizationId"
                                options={organizationOptions}
                                value={formFieldData.organizationId}
                                isRequired
                                isError={errors.hasOwnProperty('organizationId')}
                                errorLabel={errors.organizationId}
                                changeFilterAction={handleChangeOrganization}
                                isReadOnly={props.participant.contactId !== null}
                            />
                            <br />
                            <TextInput
                                label={t("External ID")}
                                name="externalId"
                                id="externalId"
                                value={formFieldData.externalId}
                                changeAction={handleChangeByUserEvent}
                                errorLabel={errors.externalId}
                                isRequired={false}
                                isError={errors.hasOwnProperty('externalId')}
                            />
                            <br />
                        </div>
                        <div className="edit-participant-row">
                            <DestructiveButton
                                clickAction={() => setShowEdit(false)}
                                additionalStyleClasses="inline-icon-left cancel-button"
                                label={<><i className="fa-light fa-xmark fa-sharp" /> {t("Cancel")}</>}
                            />
                            <Button
                                elementId="save-participant-button"
                                isSolid={true}
                                label={
                                    <div className="d-flex align-items-center justify-content-center">
                                        {isLoading && <i className="fas fa-spinner fa-spin mr-2"/>} {t("Save Participant")}
                                    </div>
                                }
                                clickAction={handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </div>
            {displayParticipantHistory()}
        </div>
    );

};

export default ViewParticipantDetails;
