import ClassSeatUsageModel from "../../Interfaces/ClassSeatUsageModel";
import type { LMSParticipant } from "../../Interfaces/Platform/Classes";
import { ClassSkillSubmitted } from "../../Interfaces/ClassSkillModel";
import { getToken } from "../../helper-functions";

interface ClassParticipantsFetch {
    classId: string;
    pageNumber: number;
    resultsPerPage: number;
}
export const fetchClassParticipants = async (apimBaseUrl: string, configContext: any, payload: ClassParticipantsFetch) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/Participants`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204) return {
            participants: [],
            total: 0
        };
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassParticipant = async (apimBaseUrl: string, configContext: any, participantId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Participant/${participantId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchEditionCoursesByCourseId = async (apimBaseUrl: string, configContext: any, courseId: string, specialties: string[]) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/EditionCourses`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                courseId,
                specialties
            })
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchCourseWithCourseObject = async (apimBaseUrl: string, configContext: any, courseId: string, courseObjectId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Curriculum/CourseWithCourseObject`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                courseId,
                courseObjectId
            })
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchOrganization = async (apimBaseUrl: string, configContext: any, orgId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Details/${orgId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const convertLmsClass = async (apimBaseUrl: string, configContext: any, payload: any) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/ConvertLmsClass`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204) return null;
        if (response.status === 400 || response.status === 500) return { status: response.status };
        return {
            data: await response.json(),
            status: response.status
        }
    } catch (e) {
        // Handle fetch error
    }
};

interface CreateLMSParticipants {
    participants: LMSParticipant[];
    classId: number;
    organizationId: string;
    orgId: string;
    organizationName: string;
    organizationIds: string[];
}

export const createLmsParticipants = async (apimBaseUrl: string, configContext: any, payload: CreateLMSParticipants) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Participant/CreateLmsParticipants`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204) return null;
        return response.status;
    } catch (e) {
        // Handle fetch error
    }
};

interface ClassParticipantUpdate {
    classParticipantId: string;
    classId: string;
    participantId: string;
}

interface ClassParticipantUpdateBlueCard extends ClassParticipantUpdate {
    blueCardId?: string;
}

interface ClassParticipantUpdateStatus extends ClassParticipantUpdate {
    status: string;
}

interface ClassParticipantUpdateType extends ClassParticipantUpdate {
    classType: string;
}

export const cancelConvertLmsClass = async (apimBaseUrl: string, configContext: any, classId: string) => {
    console.log(" :: Cancelling class conversion :: ");
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/CancelConvertLmsClass`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({ classId: classId })
        });

        return {
            status: response.status
        }
    } catch (e) {
        // Handle fetch error
    }
};

export const updateClassParticipantBlueCard = async (apimBaseUrl: string, configContext: any, payload: ClassParticipantUpdateBlueCard) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participant/Update/BlueCard`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return {
            status: response.status,
            message: await response.json(),
        }
    } catch (e) {
        throw new Error(e.message ?? "There was a problem submitting a blue card number");
    }
};

export const updateClassParticipantStatus = async (apimBaseUrl: string, configContext: any, payload: ClassParticipantUpdateStatus) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participant/Update/Status`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return response.status;
    } catch (e) {
        throw new Error("There was a problem submitting a status");
    }
};

export const updateClassParticipantType = async (apimBaseUrl: string, configContext: any, payload: ClassParticipantUpdateType) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participant/Update/ClassType`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return await response.status;
    } catch (e) {
        throw new Error("There was a problem submitting a class type");
    }
};

export const fetchClassDetails = async (apimBaseUrl: string, configContext: any, classId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/Details/${classId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return {};
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassSeats = async (apimBaseUrl: string, configContext: any, payload: { organizationId: string, courseId: string }) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Seats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 404 || response.status === 204 || response.status === 400) return 0;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchSeatsUsage = async (apimBaseUrl: string, configContext: any, seatPoolId: string ): Promise<ClassSeatUsageModel[]|undefined> => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/SeatsUsage/${seatPoolId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204 || response.status === 400) return undefined;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchLearnersCountBySeatPoolId = async (apimBaseUrl: string, configContext: any, seatPoolId: string ): Promise<any> => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/LearnersCount/${seatPoolId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204 || response.status === 400) return undefined;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

interface CreateNotePayload {
    classId: string;
    userId: string;
    firstName: string;
    lastName: string;
    notes: {
        note: string;
        deleted: boolean;
        classNoteId: string | null;
    }[];
}

export const createClassNote = async (apimBaseUrl: string, configContext: any, payload: CreateNotePayload) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/UpdateClass/Notes`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload),
        });
        return response.status;
    } catch (e) {
        // Handle fetch error
    }
};

export const fetchClassNotes = async (apimBaseUrl: string, configContext: any, classId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Class/Notes/${classId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return {
            status: response.status,
            data: null
        };
        return {
            status: response.status,
            data: await response.json()
        };
    } catch (e) {
        // Handle fetch error
    }
};

export const submitAllParticipants = async (apimBaseUrl: string, configContext: any, payload: { classId: string, skills: ClassSkillSubmitted[] }) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participants/Submit/All`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return await response;
    } catch (e) {
        throw new Error("There was a problem submitting a participant record.");
    }
};

export const submitParticipants = async (apimBaseUrl: string, configContext: any, payload: { classId: string, classParticipantIds: string[], skills: ClassSkillSubmitted[] }) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participants/Submit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return response;
    } catch (e) {
        throw new Error("There was a problem submitting a participant record.");
    }
};

export const removeParticipantFromClass = async (apimBaseUrl: string, configContext: any, payload: { classParticipantId: string, participantId: string, classId: string }) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participant/Remove`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return response;
    } catch (e) {
        throw new Error("There was a problem submitting a participant record.");
    }
};

export const resendOnlineEmail = async (apimBaseUrl: string, configContext: any, classParticipantId: any, classId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/C/Class/Participant/Resend/OnlineEmail/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                classParticipantId,
                classId
            })
        });
        return response;
    } catch (e) {
        // Handle error message
    }
};

export const exportClassParticipants = async (apimBaseUrl: string, configContext: any, classId: any, culture: string) => {
    try {            
        const response = await fetch(`${apimBaseUrl}/Q/Class/Details/Export`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },       
            body: JSON.stringify({
                classId: classId,
                culture: culture
            })         
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.blob()
        
    } catch (e) {
        console.error(e);            
    }
}

// Returns all participants' verification letters on a class
export const printVerificationLetters = async (configContext: any, request: any) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Class/VerificationLetters`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const printTrainingRecord = async (configContext: any, request: any) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Class/TrainingRecord`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const printCertificates = async (configContext: any, request: any) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Class/Certificates`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const updateLearningHistoryProgress = async (configContext: any, request: any) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/C/Class/UpdateLearningHistoryProgress`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return null;
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};

export const updateClassParticipantSubmitted = async (configContext: any, payload: any) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/C/Class/Update/Submitted/Participant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return {
            status: response.status,
            message: await response.json(),
        }
    } catch (e) {
        throw new Error(e.message ?? "There was a problem updating participant");
    }
};
