import * as React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../Components/Form/text-input";
import Button from "../../../Components/UI/button";
import { addMfaDevice, deleteMfaDevice, emailOtpCode, validateOtpCode } from "../../../ApiServices/MyAccount";
import AlertProperties from "../../../Interfaces/Platform/AlertProperties";
import DestructiveButton from "../../../Components/UI/cancel-button";
import Alert from "../../../Components/UI/alert";
import Tile from "../../../Components/UI/tile";
import Toggle from "../../../Components/Form/toggle";
import { useTranslation } from "react-i18next";
import { getMfaStatus } from "../../Utils/profile";
import { RbacContext } from "../../../rbac-context";
import AlertLevelString from "../../../Interfaces/Platform/AlertLevelString";

const MultiFactorAuthentication = () => {
    const [errorAlert, setErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const { userContext } = React.useContext(RbacContext);

    const [mfaEnabled, setMfaEnabled] = React.useState<boolean | null>(null);
    const [sending, setSending] = React.useState<boolean>(false);
    const [showSecondPage, setShowSecondPage] = React.useState<boolean>(false);
    const [mfaCode, setMfaCode] = React.useState<string>("");
    const [deviceId, setDeviceID] = React.useState<string>("");
    const [otpId, setOtpId] = React.useState<string>("");
    const [originalState, setOriginalState] = React.useState<boolean>();

    const { Email: mfaEmail } = userContext;
    const navigate = useNavigate();

    const { t } = useTranslation();

    const removeErrorAlert = () =>
        setErrorAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });

    const saveChanges = async () => {
        try {
            setSending(true);
            const response = await validateOtpCode(otpId, mfaCode);
            if (response.Error) {
                throw new Error(response.Error.Message);
            }
            setShowSecondPage(false);
            setSending(false);
            navigate("/Support/Profile");
        } catch (e) {
            setSending(false);
            setErrorAlert({
                alertLevel: "error" as unknown as AlertLevelString,
                description: t("Invalid code"),
                canDismiss: true,
                show: true
            });
        }
    };

    const handleContinue = async () => {
        const response = mfaEnabled && (await emailOtpCode(mfaEmail));
        !mfaEnabled && navigate("/Support/Profile");
        if (originalState === mfaEnabled) {
            return navigate("/Support/Profile");
        }
        setOtpId(response.Data.OtpId);
        mfaEnabled && setShowSecondPage(true);
    };

    const addDevice = async () => {
        await addMfaDevice(mfaEmail);
        const response = await getMfaStatus();
        setMfaEnabled(response.Data.DeviceId ? true : false);
        setDeviceID(response.Data.DeviceId);
    };

    const handleMfaChange = async () => {
        setMfaEnabled(!mfaEnabled);
        if (!mfaEnabled) {
            // if the mfa wasn't enable we enable it and add the device
            await addDevice();
        } else {
            await deleteMfaDevice(deviceId);
        }
    };

    const handleCancel = async () => {
        setShowSecondPage(false);
        if (mfaEnabled !== originalState) {
            setMfaEnabled(originalState);
            if (originalState) {
                await addMfaDevice(mfaEmail);
            } else {
                await deleteMfaDevice(deviceId);
            }
        }
        navigate("/Support/Profile");
    };

    const handleResend = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const response = await emailOtpCode(mfaEmail);
        setOtpId(response.Data.OtpId);
    };

    React.useEffect(() => {
        const getMfaDevices = async () => {
            const response = await getMfaStatus();
            // Update state after asynchronous operation completes
            setMfaEnabled(response.Data.DeviceId ? true : false);
            setOriginalState(response.Data.DeviceId ? true : false);
            setDeviceID(response.Data.DeviceId);
        };

        getMfaDevices();
        const getStatus = async () => {
            const response = await getMfaStatus();
            if (response != null) {
                setMfaEnabled(response.Data.DeviceId ? true : false);
            }
        };
        getStatus();
    }, []);

    return (
        <div className="page-content m-auto">
            {!showSecondPage ? (
                <>
                    <Container fluid className="page-padding mt-4">
                        {errorAlert.show && (
                            <Alert
                                state={errorAlert.alertLevel}
                                description={errorAlert.description}
                                arrowLink={errorAlert.arrowLink}
                                canDismiss={errorAlert.canDismiss}
                                removeAlertParentHandler={removeErrorAlert}
                            />
                        )}
                    </Container>
                    <Tile
                        title={t("Multi-Factor Authentication")}
                        customClassName="mt-0 p-4 profile-multi-factor-authentication"
                    >
                        <>
                            <Toggle
                                name="isMultiFactorEnabled"
                                id="isMultiFactorEnabled"
                                offLabel={t("Off")}
                                onLabel={t("On")}
                                label={t("Multi-Factor Authentication")}
                                onChange={handleMfaChange}
                                defaultOn={mfaEnabled}
                                checked={mfaEnabled}
                                disabled={mfaEnabled === null}
                            />
                            <p className="bold from-label">{t("AUTHENTICATION METHOD:")}</p>
                            <p className="mfa-subtitle">{t("Email")}</p>
                            <p>{mfaEmail}</p>
                            <div className="mfa-actions-container">
                                <DestructiveButton
                                    isDisabled={sending}
                                    clickAction={handleCancel}
                                    label={t("Cancel")}
                                />

                                <Button
                                    clickAction={handleContinue}
                                    label={sending ? t("Saving") : t("Save Changes")}
                                    isSolid
                                />
                            </div>
                        </>
                    </Tile>
                </>
            ) : (
                <div className="verification">
                    <h1 className="verification-title">{t("You are almost done")}</h1>
                    <>
                        <h2 className="verification-subtitle">
                            {t(
                                "To finish setting up multi-factor authentication, enter the verification code that was sent to"
                            )}{" "}
                            {mfaEmail}{" "}
                        </h2>
                        <div className="pl-1 pr-1 city-wrapper">
                            <TextInput
                                isError={errorAlert.show}
                                maxLength={60}
                                errorLabel={t("Invalid code")}
                                label={t("Verification code")}
                                name="mfaCode"
                                id="mfaCode"
                                value={mfaCode}
                                changeAction={(event) => {
                                    setMfaCode(event.target.value);
                                }}
                                isRequired={true}
                            />
                        </div>
                        <Button
                            additionalStyleClasses="verification-button"
                            clickAction={saveChanges}
                            label={sending ? t("Saving") : t("Continue")}
                            isSolid
                        />
                        <p>
                            {t("If you didn't receive the code check your spam folder.")}{" "}
                            <a
                                href="https://www.crisisprevention.com/Contact-Us"
                                className="text-link pendo__link-contact-us"
                                target="_blank"
                            >
                                {t("Contact us")}
                            </a>{" "}
                            {t("for additional assistance")}{" "}
                        </p>
                        <p>
                            <button className="text-link pendo__link-contact-us" onClick={handleResend}>
                                {t("Click here")}
                            </button>{" "}
                            {t("to resend the verification code.")}
                        </p>
                    </>
                </div>
            )}
        </div>
    );
};

export default MultiFactorAuthentication;
