import { deviceType, mobileModel, osVersion } from "react-device-detect";
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module";

import { Industry, RoleEnum } from "./Enums";
import { UserContext } from "./Interfaces";
import { enumToString } from "./helper-functions";
import { getLang } from "./locale-utils";
import { fetchConfiguration } from "./ApiServices/Configuration";

let config;
const readConfig = async () => {
    config = await fetchConfiguration();
    const args: TagManagerArgs = {
        gtmId: "GTM-MG8974RK",
        auth: config.Data?.GTMAuth,
        preview: config.Data?.GTMEnv
    };

    TagManager.initialize(args);
}

readConfig();

export const triggerDataLayer = () => {
    TagManager.dataLayer({
        dataLayer: {
            event: "dataLayerUpdated"
        }
    });
};

export const dataLayer = (userContext: UserContext) => {
    const pendoRoles = ['Certified Instructor', 'Business Administrator', 'Learner', 'Staff Member'];
    const dataLayerArgs: DataLayerArgs = {
        dataLayer: {
            event: "dataLayerUpdated",
            userid: userContext.KenticoId,
            customerid: userContext.CustomerId,
            email: userContext.Email,
            username: `${userContext.FirstName} ${userContext.LastName}`,           
            role:
                (userContext.Roles?.length ?? 0) === 0
                    ? "N/A"
                    : userContext.Roles.map((role) => enumToString(RoleEnum[role])).filter((role) => pendoRoles.includes(role)).sort().join(" | "),
            culture: getLang(),
            devicetype: deviceType,
            devicemodel: mobileModel,
            osversion: osVersion,
            screenwidth: window.screen.width,
            screenheight: window.screen.height,
            accountid: userContext.OrganizationNumber ?? "N/A",
            accountname: userContext.OrganizationName ?? "N/A",
            industry: enumToString(Industry[userContext.OrganizationIndustry]) ?? "N/A",
            location: userContext.Country ?? "N/A"
        }
    };

    TagManager.dataLayer(dataLayerArgs);
};
