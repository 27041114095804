import {
    ApiResponse,
    DELETE,
    GETFILE,
    GETWITHERROR,
    JsonResponseModel,
    POSTFORM,
    POSTWITHERROR,
    PUT,
    PagedResult,
    handleError,
    GETPAGED,
    PagingOptions
} from ".";
import {
    BulkUploadResponse,
    ClassInfoModel,
    ClassModel,
    EnrolleeModel,
    OrganizationResult,
    UserModel
} from "../Interfaces";

import AvailableCourseModel from "../Interfaces/AvailableCourseModel";
import EnrollmentListItem from "../Interfaces/EnrollmentListItem";
import { template } from "../helper-functions";

const FETCH_CLASSES_BY_COURSE_ID_TEMPLATE = template`/Class/GetClassesByCourseIdAsync?courseObjectId=${0}`;
export const FETCH_AVAILABLE_COURSES_URL = "/Class/GetAvailableCoursesAsync";
export const FETCH_INSTRUCTORS_URL = "/Class/GetInstructorsAsync";
export const CREATE_CLASS_URL = "/Class/CreateClassAsync";
export const UPDATE_CLASS_URL_TEMPLATE = template`/Class/UpdateClassAsync?classId=${0}`;
const DELETE_CLASS_URL_TEMPLATE = template`/Class/DeleteClassAsync?classId=${0}`;
const POST_UNENROLL_LEARNER_URL = "/Class/UnenrollLearnerAsync";
const FETCH_CLASS_INFO_TEMPLATE = template`/Class/FetchClassAsync?classId=${0}`;
const FETCH_USER_ORGANIZATION_TREE_URL = "/Class/FetchOrganizationsAsync";
const FETCH_ENROLLED_LEARNERS_URL_TEMPLATE = template`/Class/GetEnrolledLearnersAsync?classId=${0}`;
const DOWNLOAD_ENROLLED_LEARNERS_URL_TEMPLATE = template`/Class/ExportEnrolledLearnersAsync?classId=${0}`;
const FETCH_ENROLLEES_URL_TEMPLATE = template`/Class/GetEnrolleesAsync?classId=${0}`;
const POST_ENROLL_LEARNER_URL = "/Class/EnrollLearnerAsync";
const POST_RESEND_ENROLLMENT_EMAIL_URL = "/Class/ResendEnrollmentEmailAsync";
const POST_MOVE_ENROLLMENT_URL = "/Class/MoveEnrollmentAsync";
const POST_SET_CLASS_INACTIVE_URL = `/Class/SetClassInactiveAsync`;
const DOWNLOAD_BULK_UPLOAD_TEMPLATE = "/Class/BulkUploadTemplate";
const POST_BULK_UPLOAD_URL = "/Class/UploadFileAsync";
const FETCH_CLASSES = "/Class/GetClassesAsync";
const FETCH_CLASS_ROSTER = template`/Class/GetClassRosterAsync/${0}`
const FETCH_CLASS_ENROlLEES = template`/Class/GetClassEnrolleesAsync/${0}`


export async function fetchClasses(pagingOptions: PagingOptions): Promise<PagedResult<ClassModel>> {
    const resp = await GETPAGED<PagedResult<ClassModel>>(FETCH_CLASSES, pagingOptions);
    return resp.Data;
}

export async function fetchOnlyActiveClasses(pagingOptions: PagingOptions): Promise<PagedResult<ClassModel>> {
    const resp = await GETPAGED<PagedResult<ClassModel>>(FETCH_CLASSES, pagingOptions);
    return {...resp.Data, Results: resp.Data.Results};
}

export async function setClassInactive(classId: number): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_SET_CLASS_INACTIVE_URL + '/' + classId, {});
}

export async function fetchClassRoster(fetchParameters: number[], pagingOptions: PagingOptions): Promise<PagedResult<EnrollmentListItem>> {
    const resp = await GETPAGED<PagedResult<EnrollmentListItem>>(FETCH_CLASS_ROSTER(fetchParameters[0]), pagingOptions);
    return resp.Data;
}

export async function fetchAllClassRoster(fetchParameters: number[], pagingOptions?: PagingOptions): Promise<PagedResult<EnrollmentListItem>> {
    const resp = await GETPAGED<PagedResult<EnrollmentListItem>>(FETCH_CLASS_ROSTER(fetchParameters[0]), { currentPage: 1, pageSize: 99999, sortBy: 'LastName', sortDirection: 'asc', searchTerm: '' });
    return resp.Data;
}

export async function fetchClassEnrollees(fetchParameters: number[], pagingOptions: PagingOptions): Promise<PagedResult<EnrolleeModel>> {
    const resp = await GETPAGED<PagedResult<EnrolleeModel>>(FETCH_CLASS_ENROlLEES(fetchParameters[0]), pagingOptions);
    return resp.Data;
}

export async function fetchClassesByCourseId(courseId: number): Promise<PagedResult<ClassModel>> {
    const resp = await GETWITHERROR<PagedResult<ClassModel>>(FETCH_CLASSES_BY_COURSE_ID_TEMPLATE(courseId));
    return resp.Data;
}

export async function fetchAvailableCourses(): Promise<JsonResponseModel<AvailableCourseModel[]>> {
    return GETWITHERROR(FETCH_AVAILABLE_COURSES_URL);
}

export async function fetchInstructors(): Promise<JsonResponseModel<UserModel[]>> {
    return GETWITHERROR(FETCH_INSTRUCTORS_URL);
}

export async function createClass(model: ClassModel): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(CREATE_CLASS_URL, model);
}

export async function updateClass(model: ClassModel): Promise<JsonResponseModel<ApiResponse>> {
    return PUT(UPDATE_CLASS_URL_TEMPLATE(model.ClassId), model);
}

export function deleteClass(classId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_CLASS_URL_TEMPLATE(classId));
}

export function moveEnrollment(
    userId: number,
    fromClassId: number,
    toClassId: number
): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_MOVE_ENROLLMENT_URL, { userId, fromClassId, toClassId });
}

export function unenrollLearner(learnerCourseId: number): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_UNENROLL_LEARNER_URL, { learnerCourseId });
}

export async function fetchClass(classId: number): Promise<ClassInfoModel> {
    const resp = await GETWITHERROR<ClassInfoModel>(FETCH_CLASS_INFO_TEMPLATE(classId));
    if (resp.Error?.Code === 404) {
        return null;
    }
    return resp.Data;
}

export async function fetchOrganizationTree(): Promise<OrganizationResult[]> {
    const resp = await GETWITHERROR<OrganizationResult[]>(FETCH_USER_ORGANIZATION_TREE_URL);
    return resp.Data;
}

export async function fetchEnrolledLearners(classId: number): Promise<EnrollmentListItem[]> {
    const resp = await GETWITHERROR<EnrollmentListItem[]>(FETCH_ENROLLED_LEARNERS_URL_TEMPLATE(classId));
    return resp.Data;
}

export async function downloadEnrolledLearners(classId: number): Promise<Blob> {
    const resp = await GETFILE(DOWNLOAD_ENROLLED_LEARNERS_URL_TEMPLATE(classId), ".csv");
    if (resp.ok) {
        return resp.blob();
    }
    return null;
}

export async function fetchEnrollees(classId: number): Promise<EnrolleeModel[]> {
    const resp = await GETWITHERROR<EnrolleeModel[]>(FETCH_ENROLLEES_URL_TEMPLATE(classId));
    return resp.Data;
}

export async function enrollLearner(classId: number, userId: number): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_ENROLL_LEARNER_URL, { classId, userId });
}

export function resendEnrollmentEmail(classId: number, userId: number): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_RESEND_ENROLLMENT_EMAIL_URL, { classId, userId });
}

export async function downloadBulkUploadTemplate(): Promise<Blob> {
    const resp = await GETFILE(DOWNLOAD_BULK_UPLOAD_TEMPLATE, ".csv");
    if (resp.ok) {
        return resp.blob();
    }
    return null;
}

export function bulkUpload(
    classId: number,
    organizationId: number,
    file: File
): [Promise<JsonResponseModel<BulkUploadResponse>>, AbortController] {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("classId", classId.toString());
    formData.append("organizationId", organizationId.toString());

    const controller = new AbortController();
    const signal = controller.signal;
    const fetchCall = POSTFORM(POST_BULK_UPLOAD_URL, formData, signal).then(async (resp) =>
        handleError<BulkUploadResponse>(resp)
    );
    return [fetchCall, controller];
}
