import * as React from "react";

const GrayTile = (props: {
    children:JSX.Element,
    percentWidth?: number
}): JSX.Element => {

    const style = {
        style: {
            width: props.percentWidth ? props.percentWidth + '%' : '100%'
        }
    }

    return (
        <blockquote style={style.style} className="gray-tile">{props.children}</blockquote>
    );

};

export default GrayTile;
