import * as React from "react";
import { useDispatch } from "react-redux";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { IEnrollParticipant } from "../../../Interfaces/Platform/Participants";
import { useTranslation } from "react-i18next";
import EnrollParticipantListingMobile from "./enroll-participant-listing-mobile";
import EnrollParticipantListingNonMobile from "./enroll-participant-listing-non-mobile";
import { enrollParticipant, createClassParticipant } from "../../Utils/participants";
import { ConfigContext } from "../../../configuration-context";
import type { IClassDetails } from "../../../Interfaces/Platform/Classes";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import { getUserClaims } from "../../../helper-functions";
import { enrollParticipantToClass } from "../../../stores/classDetailsSlice";
import { enrollClassParticipant } from "../../../stores/classParticipantsSlice";
import { fetchClassSeats } from "../../Utils/class";
import type { ShowAlert } from "../../../Interfaces/Platform/AlertProperties";

interface Props extends IEnrollParticipant {
    onEditParticipant: (participantId: string) => void;
    organizations: any[];
    setParticipantsEnrolled: (participants: number) => void;
    seats: number;
    seatsRequired: boolean;
    class: IClassDetails;
    getParticipants: () => void;
    addPageLevelAlert: any;
    showSeatsAlert: (alert: ShowAlert) => void;
    canEditParticipants: boolean;
    canEnrollParticipants: boolean;
}

const EnrollParticipantListing = (props: Props): JSX.Element => {
    const dispatch = useDispatch();
    const { tp } = useParsedTranslation();
    const culture = getUserClaims()?.culture ?? "en-US";
    const { isMobile, isLaptop } = useDetectWindowSize();
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [isEnrolling, setIsEnrolling] = React.useState<boolean>(false);
    const [hasEnrolled, setHasEnrolled] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const handleAddToClass = async () => {
        setIsEnrolling(true);
        if (props.class.trainingFormat !== 'classroom' && props.seatsRequired) {
            const data = await fetchClassSeats(apimBaseUrl, configContext, { organizationId: props.class.seatOrganizationId, courseId: props.class.courseId });
            if (data < 1) {
                setIsEnrolling(false);
                props.showSeatsAlert({
                    alertLevel: { alertLevel: "error" },
                    description: tp("Seats-Remaining-Error", { numberOfSeats: '0', specialty: t(props.class.specialty) }),
                    canDismiss: true,
                    arrowLink: { label: "Buy Seats", href: `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning` }
                });
                return;
            }
        }
        try {
            const enrollResponse = await createClassParticipant(apimBaseUrl, configContext, {
                participantId: props.participantId,
                classId: props.class.classId,
                firstName: props.firstName,
                lastName: props.lastName,
                email: props.email,
                externalId: props.externalId,
                userId: props.userId,
                participantCreated: false,
                userCreated: false
            });

            if (enrollResponse?.status === 202) {
                setHasEnrolled(true);
                dispatch(enrollClassParticipant(enrollResponse.data));
                dispatch(enrollParticipantToClass());
                props.setParticipantsEnrolled(1);
                setIsEnrolling(false);
            } else {
                // 409 - 500
                setIsEnrolling(false);
                props.addPageLevelAlert({ 
                    alertLevel: { alertLevel: "error" },
                    description: enrollResponse.data, 
                    canDismiss: true
                });
            }
        } catch (e) {
            setIsEnrolling(false);
        } finally {
            //setIsLoading(false);
        }
    };

    return isLaptop
        ? (
            <EnrollParticipantListingNonMobile
                isEnrolling={isEnrolling}
                hasEnrolled={hasEnrolled}
                handleAddToClass={handleAddToClass}
                {...props}
            />
        )
        : (
            <EnrollParticipantListingMobile
                isEnrolling={isEnrolling}
                hasEnrolled={hasEnrolled}
                handleAddToClass={handleAddToClass}
                {...props}
            />
        );
}

export default EnrollParticipantListing;
