import * as React from "react";
import Button from "./button";

import useParsedTranslation from "../../hooks/useParsedTranslation";
import { Trans, useTranslation } from "react-i18next";

const EnrollmentExamListing = (props: {
    enrollmentData:any,
    eventData:any,
    todaysDate:any,
    addPageLevelAlert:any
}) => {
    // REFERENCE https://crisisprevention.invisionapp.com/console/Certified-Instructor---Revised-ckrp5a6sz03mb0171b7kg1edg/ckzcudwd202ct010h7qnj6lxt/play

    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    React.useEffect(() => {
        if (props.enrollmentData.examLink && (props.enrollmentData.examScore == null)) {
            // CPI.MyAccount.Dashboard.Alerts.ExamReady
            props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.ActionItem.TakeExam") }, alertLevel: {alertLevel:"warning"}, description: <Trans
            defaults="CPI.MyAccount.Dashboard.Alerts.ExamReady" values={{ specialtyName: props.programSpecialty }} />, canDismiss: true });
        } else if (props.enrollmentData.examLink && (props.enrollmentData.examScore != null) && (props.enrollmentData.examScore < 80)) {
            props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.ActionItem.TakeExam") }, alertLevel: {alertLevel:"warning"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Alerts.ExamRetake"
                values={{ string0: "80", specialtyName: props.programSpecialty }} />, canDismiss: false });
        }
    }, [props.enrollmentData.examLink, props.enrollmentData.examScore])

    return (
        <>
            { !props.enrollmentData.examLink &&
                <div className="mt-3 mb-4">{tp("CPI.MyAccount.Dashboard.Enrollment.Exam.NotAvailable")}</div>
            }

            { props.enrollmentData.examLink && (props.enrollmentData.examScore == null) &&
                <div className="mt-3 mb-4">
                    <div>{t("Your-Exam-Is-Ready")}</div>
                    <div className="mt-2">
                        <Button label={tp("CPI.ActionItem.TakeExam")} isSolid={true} href={props.enrollmentData.examLink} />
                    </div>
                </div>
            }

            { props.enrollmentData.examLink && (props.enrollmentData.examScore != null) && (props.enrollmentData.examScore < 80) &&
                <div className="mt-3 mb-4">
                    <div><Trans defaults="CPI.MyAccount.Dashboard.Enrollment.Exam.Failed" values={{ string0: props.enrollmentData.examScore }} /></div>
                    <div className="mt-2">
                        <Button label={tp("CPI.ActionItem.RetakeExam")} isSolid={true} href={props.enrollmentData.examLink} />
                    </div>
                </div>
            }

            { props.enrollmentData.examLink && (props.enrollmentData.examScore != null) && (props.enrollmentData.examScore >= 80) &&
                <div className="mt-3 mb-4">{tp("CPI.MyAccount.Dashboard.Enrollment.Exam.Complete")}</div>
            }
        </>
    )
};

export default EnrollmentExamListing;