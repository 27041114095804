import "./bulk-class-enroll.scss";

import * as React from "react";

import { Alert, Breadcrumb, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { BulkUploadRow, ClassInfoModel, OrganizationResult } from "../Interfaces";
import { Link, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { bulkUpload, fetchClass, fetchOrganizationTree } from "../ApiServices/Class";

import BulkUploadForm from "./Components/bulk-upload-form";
import { ErrorModel } from "../ApiServices";
import { InstructorBaseRoutes } from "../Routing/routes";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import SeatCountAlert from "./Components/seat-count-alert";
import { generateBulkUploadPreview } from "../ApiServices/Learner";
import { isIE } from "../helper-functions";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const SaveModal = (props: {
    show: boolean;
    onCancel: () => void;
    success: boolean;
    count?: number;
    name?: string;
    classId?: number;
    onReturn: () => Promise<void>;
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="saving-modal">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="fa-3x saving-icon mt-4 mb-4">
                                {props.success ? (
                                    <i className="fas fa-check"></i>
                                ) : (
                                    <i className="fas fa-spinner fa-spin"></i>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>{props.success ? t("Success!") : t("Creating & Enrolling Learners")}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {props.success
                                    ? t("{{count}} learners were created and enrolled in {{name}}.", {
                                          count: props.count,
                                          name: props.name
                                      })
                                    : t(
                                          "Please do not close your browser window or navigate away or progress will be lost. This could take up to 5 minutes."
                                      )}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {!props.success && (
                    <Button variant="primary" onClick={props.onCancel}>
                        {t("Cancel")}
                    </Button>
                )}
                {props.success && (
                    <>
                        <Button as={Link} to={`/Class/Manage/${props.classId}`} variant="primary">
                            {t("Back to Class")}
                        </Button>
                        <Button variant="outline-primary" onClick={props.onReturn}>
                            {t("Upload More Learners")}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

const BulkClassEnrollPage = () => {
    const { t } = useTranslation();
    const params = useParams<{ classId: string }>();
    const classId = parseInt(params.classId);

    const { userContext } = React.useContext(RbacContext);

    const [enrollingClass, setEnrollingClass] = React.useState<ClassInfoModel>(null);
    const [userOrganizations, setUserOrganizations] = React.useState<OrganizationResult[]>([]);
    const [selectedOrganization, setSelectedOrganization] = React.useState<OrganizationResult>(null);
    const [userCount, setUserCount] = React.useState(0);

    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [classNotFound, setClassNotFound] = React.useState(false);
    const [notFoundMessage, setNotFoundMessage] = React.useState<string>(null);
    const [file, setFile] = React.useState<File>(null);
    const [saving, setSaving] = React.useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = React.useState(false);
    const [saveErrors, setSaveErrors] = React.useState<ErrorModel>(null);
    const [validated, setValidated] = React.useState(false);
    const [cancelToken, setCancelToken] = React.useState<AbortController>(null);

    const [previewUsers, setPreviewUsers] = React.useState<BulkUploadRow[]>(null);
    const [previewError, setPreviewError] = React.useState<ErrorModel>(null);

    React.useEffect(() => {
        async function getData() {
            try {
                const theClass = await fetchClass(classId);
                if (!theClass) {
                    setClassNotFound(true);
                    setNotFoundMessage(t("The requested class was not found")); //Error: The requested class was not found.
                    return;
                }
                setEnrollingClass(theClass);

                const orgs = await fetchOrganizationTree();
                setUserOrganizations(orgs);
                setSelectedOrganization(orgs.find((org) => org.OrganizationId === theClass.OrganizationId));
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setSaveErrors(null);
        if (form.checkValidity() === true) {
            try {
                if (isIE()) {
                    setSaveErrors({
                        Code: null,
                        MessageCode: 0,
                        Message: t(
                            "The Upload Learners feature is not officially supported for Internet Explorer. Please use another browser to complete your upload."
                        )
                    });
                    return;
                }

                setSaving(true);

                const [fetch, token] = bulkUpload(enrollingClass.ClassId, selectedOrganization.OrganizationId, file);

                fetch
                    .then((resp) => {
                        setCancelToken(null);
                        if (resp.Error) {
                            console.log(resp.Error);

                            clearForm();
                            setSaving(false);
                            setSaveErrors(resp.Error);
                        } else {
                            setShowSaveSuccess(true);
                            setUserCount(resp.Data.Count);
                            return;
                        }
                    })
                    .catch((err) => {
                        setValidated(false);
                        if (err.name === "AbortError") {
                            console.log("fetch is cancelled");
                        } else {
                            console.error(err);
                            setSaveErrors({
                                Code: null,
                                MessageCode: 0,
                                Message: t("An unexpected error occurred. Please try again.")
                            });
                            setSaving(false);
                        }
                    });
                setCancelToken(token);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setSaveErrors({
                    Code: null,
                    MessageCode: 0,
                    Message: t("An unexpected error occurred. Please try again.")
                });
                setSaving(false);
            }
        }

        setValidated(true);
    };

    const handleCancel = () => {
        cancelToken.abort();
        setCancelToken(null);
        setSaving(false);
    };

    const handleReturn = async () => {
        try {
            const theClass = await fetchClass(classId);
            setEnrollingClass(theClass);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
            setErrored(true);
        }

        clearForm();
        setSaveErrors(null);
        setShowSaveSuccess(false);
        setSaving(false);
        setPreviewError(null);
    };

    const generatePreview = async (file: File) => {
        setPreviewError(null);
        if (file) {
            try {
                const resp = await generateBulkUploadPreview(file);
                if (resp.Error) {
                    setPreviewError(resp.Error);
                    setPreviewUsers(null);
                } else {
                    setPreviewUsers(resp.Data);
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setPreviewError({
                    Code: null,
                    MessageCode: 0,
                    Message: t("An unexpected error occurred. Please try again.")
                });
                setPreviewUsers(null);
            }
        } else {
            setPreviewUsers(null);
        }
    };

    const clearForm = () => {
        const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;
        fileUpload.value = "";
        setFile(null);
        setPreviewUsers(null);
    };

    if (loading || errored || classNotFound) {
        return (
            <PageMessages
                loading={loading}
                errored={errored}
                notFound={classNotFound}
                notFoundMessage={notFoundMessage}
            />
        );
    }

    return (
        <>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: InstructorBaseRoutes.Class.Class.fullPath }}>
                        {t("Manage Classes")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkAs={Link}
                        linkProps={{ to: InstructorBaseRoutes.Class.Class.fullPath.replace(":classId", classId.toString()) }}
                    >
                        {enrollingClass.Name}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {t("Upload Many Learners")}: {t("Create & Enroll")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                {isIE() && (
                    <Alert variant="danger">
                        {t(
                            "The Upload Learners feature is not officially supported for Internet Explorer. Please use another browser to complete your upload."
                        )}
                    </Alert>
                )}

                <Row className="mb-4">
                    <Col>
                        <h1>
                            {t("Upload Many Learners")}: {t("Create & Enroll")}
                        </h1>
                        <p>
                            {t(
                                "Save time by uploading a .csv file to create and enroll many learners in this class at once. Once enrolled, the system will automatically send all participants an email with a link to view their online course."
                            )}
                        </p>
                        <SeatCountAlert classInfo={enrollingClass} />
                    </Col>
                </Row>
            </Container>

            <BulkUploadForm
                validated={validated}
                handleSubmit={handleSubmit}
                onFileChange={(e) => {
                    setFile(e.target.files[0]);
                    generatePreview(e.target.files[0]);
                }}
                previewUsers={previewUsers}
                previewError={previewError}
                userOrganizations={userOrganizations}
                selectedOrganization={selectedOrganization}
                onOrganizationChange={(e) => {
                    const organizationId = parseInt(e.target.value);
                    setSelectedOrganization(userOrganizations.find((uo) => uo.OrganizationId === organizationId));
                }}
                previewMessageElement={
                    <Trans
                        defaults="<bold>{{count}} learners</bold> will be created and enrolled in <bold>{{name}}</bold>. We will automatically send your learners emails so that they can log in and take their course."
                        values={{ count: previewUsers?.length, name: enrollingClass?.Name }}
                        components={{ bold: <strong /> }}
                    />
                }
                saveError={saveErrors}
                saveErrorHeaderText={t(
                    "There was a problem, and no learners were created or enrolled. Please review the following errors and upload the file again."
                )}
                createButtonText={t("Create & Enroll Learners")}
                backToButtonText={t("Back to Manage Classes")}
                backToButtonHref={InstructorBaseRoutes.Class.Class.fullPath}
            />

            {saving && (
                <SaveModal
                    show={saving}
                    onCancel={handleCancel}
                    onReturn={handleReturn}
                    success={showSaveSuccess}
                    name={enrollingClass.Name}
                    count={userCount}
                    classId={enrollingClass.ClassId}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, BulkClassEnrollPage);
