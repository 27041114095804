import "./manage-participants.scss";

import * as React from "react";

import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AdminBaseRoutes } from "../Routing/routes";
import { fetchProfile } from "./Utils/profile";
import ParticipantDetails from "./components/participant-details";
import { TypeaheadSearch } from "../Components";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withNavigator } from '../Components/with-navigator';

const ManageParticipantsPage = (props: any) => {
    const configContext = useContext(ConfigContext);
    const rbac = useContext(RbacContext);
    const { t } = useTranslation();
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { userId } = useParams();
    const navigate = useNavigate();

    const [state, setState] = React.useState<any>(
    {
        user: null,
        userId: userId
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const { user, userId } = state;
            if (userId && !user) {
                fetchAndSetParticipantDetails(await fetchProfile(apimBaseUrl, configContext, userId)); 
            }
            else if (!userId && user) {
                resetState();
            }
        }
        fetchData();
    }, []);

    const fetchAndSetParticipantDetails = async (participant: any) => {
        if (participant === null || participant === undefined) {
            resetState();
            navigate(AdminBaseRoutes.ManageParticipants.fullPath);
        } else {
            setState({ user: participant, userId: participant.id});
        }
    }

    const resetState = () => {
        setState({ participant: null, participantId: null});
    }

    const setNavigation = async (participant: any) => {
        navigate(AdminBaseRoutes.ManageParticipantsParticipant.fullPath.replace(":userId", participant.userId), { replace: true });
        fetchAndSetParticipantDetails({ id: participant.userId, firstName: participant.firstName, lastName: participant.lastName, email: participant.email, customerId: participant.customerId, organizationId: participant.organizationId, orgId: participant.orgId, contactId: participant.contactId });
    };

    const renderParticipantDetails = () => {
        return (
            <>
                {state.user && (
                    <ParticipantDetails
                        user={state.user}
                        configContext={configContext}
                        userContext={rbac.userContext}
                        apimBaseUrl={apimBaseUrl}
                    />
                )}
            </>
        );
    }

    return (
        <div className="page-content m-auto">
            <Container fluid>
                <div className="mt-4 mb-4 classes-row">
                    <h1 className="page-title-h1">{t("Manage Participants")}</h1>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item active={!state.userId} linkAs={Link} onClick={() => { resetState() }} linkProps={{ to: AdminBaseRoutes.ManageParticipants.fullPath }}>
                        {t("Manage Participants")}
                    </Breadcrumb.Item>
                    {state.user && <Breadcrumb.Item active>{state.user.firstName} {state.user.lastName} {state.user.customerId && <span>({state.user.customerId})</span> }</Breadcrumb.Item>}
                </Breadcrumb>
                <Row>
                    <Col>
                        <TypeaheadSearch
                            id="ParticipantSearch"
                            searchUrl={apimBaseUrl + "/Q/Profile/Account/Search"}
                            method="POST"
                            searchBody='{"SearchTerm": "{query}", "PageNumber": 1, "ResultsPerPage": 20}'
                            placeholder={t("Search for a participant")}
                            labelKey="email"
                            itemRenderer={(option: any) => (
                                <div key={option.userId}>
                                    <strong>
                                        {option.firstName} {option.lastName}
                                    </strong>
                                    {` (${option.email}) ${option.customerId ?? ""}`}
                                </div>
                            )}
                            onChange={(item: any[]) => {
                                setNavigation(item[0]);
                            }}
                        />
                        {state.userId && renderParticipantDetails()}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withNavigator(withAITracking(reactPlugin, ManageParticipantsPage));
