import * as React from "react";

const EmptyContentBasic = (props: {
    title: string,
    primary?: boolean,
    subtitle?: string
}): JSX.Element => {

    return (
        <div className="empty-content-container basic">
            <div className="d-flex flex-column align-items-center">
                <p className={`fw600 ${props.primary ? 'fs24' : 'fs18'}`}>{props.title}</p>
                {props.subtitle && <p className="fs16">{props.subtitle}</p>}
            </div>
        </div>
    );
};

export default EmptyContentBasic;
