//import "./footer.scss";
import { useRef } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../configuration-context";

const Footer = (props: {
    setFooterHeight: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const configContext = React.useContext(ConfigContext);

    return (

        <footer ref={ref} className="site-footer">
            <div className="footer-wrapper">
                <div className="footer-copyright">
                    {t("FooterCopyright", { year: new Date().getFullYear() })}
                    <a class="ml-1 inline-footer-link" id="privacyNotice" href={configContext?.SystemConfiguration?.PublicSitePrivacyPolicy} target="_blank">Privacy Notice</a>
                    <a class="ml-1 inline-footer-link" id="manageCookies" href="#" onClick={() => Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}>Manage Cookies</a>
                </div>
            </div>

            
        </footer>

    );
};

export default Footer;
