import * as React from "react";
import "./progress-bar.scss";

export interface ProgressBarProps {
    currentLesson: number,
    totalLessons: number
}

const ProgressBar = ({ currentLesson, totalLessons }: ProgressBarProps) => {
    const percentCompleted = (currentLesson / totalLessons) * 100;
    return (
        <>
            {currentLesson > 0 &&
                <div role="progressbar_frame" className="progress-bar-container-style">
                    <div role="progressbar_fill" className="progress-bar-filler-style" style={{ width: `${percentCompleted}%` }}>
                        <span className="progress-bar-label-style">{`${currentLesson} / ${totalLessons}`}</span>
                    </div>
                </div>}
            {currentLesson == 0 &&
                <div role="progressbar_frame" className="progress-bar-container-style">
                    <span className="progress-bar-label-style">{`${currentLesson} / ${totalLessons}`}</span>
                </div>}
        </>
    );
};

export default ProgressBar;
