import i18n from "i18next";
import type { ICreateClass } from "../Interfaces/Platform/Classes";
import { DEFAULT_MAX_CHARS } from "../FormValidationRules/commonValues";

export default function validate(values: ICreateClass) {
    let errors: any = {};
    const FORMAT_BLENDED = "blended";

    if (!values.className) {
        errors.className = i18n.t("Validate-Class-Name");
    }

    if (values.className && values.className.length > 200) {
        errors.className = i18n.t("Exceeded-Max-Limit", { length: 200 });
    }

    if (!values.instructors) {
        errors.instructors = i18n.t("Validate-Instructor");
    }

    if (values.instructors && values.instructors.length - 1 < values.additionalInstructors) {
        errors.instructors = i18n.t("Validate-Instructor");
    }

    if (!values.specialty) {
        errors.specialty = i18n.t("Validate-CPI-Program");
    }

    if (!values.specialtyEdition) {
        errors.specialtyEdition = i18n.t("Validate-Participant-Workbook");
    }

    if (!values.trainingType && (values.specialty != "PF") && (values.specialty != "DEB")) {
        errors.trainingType = i18n.t("Validate-Type-Of-Training");
    }

    if ((values.classDates[0] && !values.classDates[0].date) || (values.classDates[1] && !values.classDates[1].date) || (values.classDates[2] && !values.classDates[2].date) || (values.classDates[3] && !values.classDates[3].date) || (values.classDates[4] && !values.classDates[4].date)) {
        errors.classDates = i18n.t("Validate-Date");
    }

    if (values.trainingType === "initial") {
        if (values.classDates[0] && values.classDates[0].initialHours && values.classDates[0].initialHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates0 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if (values.classDates[0] && (!values.classDates[0].initialHours || values.classDates[0].initialHours === '0') && (values.specialty != "PF") && (values.specialty != "DEB")) {
            errors.classDates0 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[1] && values.classDates[1].initialHours && values.classDates[1].initialHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates1 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }
    
        if (values.classDates[1] && (!values.classDates[1].initialHours || values.classDates[1].initialHours === '0')) {
            errors.classDates1 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[2] && values.classDates[2].initialHours && values.classDates[2].initialHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates2 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }
    
        if (values.classDates[2] && (!values.classDates[2].initialHours || values.classDates[2].initialHours === '0')) {
            errors.classDates2 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[3] && values.classDates[3].initialHours && values.classDates[3].initialHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates3 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if (values.classDates[3] && (!values.classDates[3].initialHours || values.classDates[3].initialHours === '0')) {
            errors.classDates3 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[4] && values.classDates[4].initialHours && values.classDates[4].initialHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates4 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }
    
        if (values.classDates[4] && (!values.classDates[4].initialHours || values.classDates[4].initialHours === '0')) {
            errors.classDates4 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }
    }

    /*if ((values.classDates[0] && !values.classDates[0].initialHours) || (values.classDates[1] && !values.classDates[1].initialHours) || (values.classDates[2] && !values.classDates[2].initialHours) || (values.classDates[3] && !values.classDates[3].initialHours) || (values.classDates[4] && !values.classDates[4].initialHours)) {
        errors.classDates = "Hours must be greater than 0";
    }*/

    /*if (values.typeOfTraining === "refresher" || values.typeOfTraining === "initial-refresher") {
        if ((values.classDates[0] && !values.classDates[0].refresherHours) || (values.classDates[1] && !values.classDates[1].refresherHours) || (values.classDates[2] && !values.classDates[2].refresherHours) || (values.classDates[3] && !values.classDates[3].refresherHours) || (values.classDates[4] && !values.classDates[4].refresherHours)) {
            errors.classDates = "Hours must be greater than 0";
        }
    }*/

    if (values.trainingType === "refresher") {
        if (values.classDates[0] && values.classDates[0].refresherHours && values.classDates[0].refresherHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates0 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[0] && (!values.classDates[0].refresherHours || values.classDates[0].refresherHours === '0'))) {
            errors.classDates0 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[1] && values.classDates[1].refresherHours && values.classDates[1].refresherHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates1 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[1] && (!values.classDates[1].refresherHours || values.classDates[1].refresherHours === '0'))) {
            errors.classDates1 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[2] && values.classDates[2].refresherHours && values.classDates[2].refresherHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates2 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[2] && (!values.classDates[2].refresherHours || values.classDates[2].refresherHours === '0'))) {
            errors.classDates2 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[3] && values.classDates[3].refresherHours && values.classDates[3].refresherHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates3 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[3] && (!values.classDates[3].refresherHours || values.classDates[3].refresherHours === '0'))) {
            errors.classDates3 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if (values.classDates[4] && values.classDates[4].refresherHours && values.classDates[4].refresherHours.length > DEFAULT_MAX_CHARS) {
            errors.classDates4 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[4] && (!values.classDates[4].refresherHours || values.classDates[4].refresherHours === '0'))) {
            errors.classDates4 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }
    }

    if (values.trainingType === "initial-refresher") {
        if ((values.classDates[0] && values.classDates[0].refresherHours && values.classDates[0].refresherHours.length > DEFAULT_MAX_CHARS)
            || (values.classDates[0] && values.classDates[0].initialHours && values.classDates[0].initialHours.length > DEFAULT_MAX_CHARS)) {
            errors.classDates0 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }
        
        if ((values.classDates[0] && ((!values.classDates[0].refresherHours || values.classDates[0].refresherHours === '0') && (!values.classDates[0].initialHours || values.classDates[0].initialHours === '0')))) {
            errors.classDates0 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if ((values.classDates[1] && values.classDates[1].refresherHours && values.classDates[1].refresherHours.length > DEFAULT_MAX_CHARS)
            || (values.classDates[1] && values.classDates[1].initialHours && values.classDates[1].initialHours.length > DEFAULT_MAX_CHARS)) {
            errors.classDates1 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[1] && ((!values.classDates[1].refresherHours || values.classDates[1].refresherHours === '0') && (!values.classDates[1].initialHours || values.classDates[1].initialHours === '0')))) {
            errors.classDates1 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if ((values.classDates[2] && values.classDates[2].refresherHours && values.classDates[2].refresherHours.length > DEFAULT_MAX_CHARS)
            || (values.classDates[2] && values.classDates[2].initialHours && values.classDates[2].initialHours.length > DEFAULT_MAX_CHARS)) {
            errors.classDates2 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[2] && ((!values.classDates[2].refresherHours || values.classDates[2].refresherHours === '0') && (!values.classDates[2].initialHours || values.classDates[2].initialHours === '0')))) {
            errors.classDates2 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if ((values.classDates[3] && values.classDates[3].refresherHours && values.classDates[3].refresherHours.length > DEFAULT_MAX_CHARS)
            || (values.classDates[3] && values.classDates[3].initialHours && values.classDates[3].initialHours.length > DEFAULT_MAX_CHARS)) {
            errors.classDates3 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[3] && ((!values.classDates[3].refresherHours || values.classDates[3].refresherHours === '0') && (!values.classDates[3].initialHours || values.classDates[3].initialHours === '0')))) {
            errors.classDates3 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }

        if ((values.classDates[4] && values.classDates[4].refresherHours && values.classDates[4].refresherHours.length > DEFAULT_MAX_CHARS)
            || (values.classDates[4] && values.classDates[4].initialHours && values.classDates[4].initialHours.length > DEFAULT_MAX_CHARS)) {
            errors.classDates4 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
        }

        if ((values.classDates[4] && ((!values.classDates[4].refresherHours || values.classDates[4].refresherHours === '0') && (!values.classDates[4].initialHours || values.classDates[4].initialHours === '0')))) {
            errors.classDates4 = i18n.t("Validate-Hours-Greater-Than-Zero");
        }
    }

    // Regardless of the training type, check each classDate to ensure they all occur before the expiration date
    // Ensure Class Date 0 occurs before the expiration date
    if (values.classDates[0] && values.expirationDate && new Date(values.classDates[0].date) > new Date(values.expirationDate)) {
        errors.classDateSelect0 = i18n.t("Validate-Date-Before-Expiration");
    }

    // Ensure Class Date 1 occurs before the expiration date
    if (values.classDates[1] && values.expirationDate && new Date(values.classDates[1].date) > new Date(values.expirationDate)) {
        errors.classDateSelect1 = i18n.t("Validate-Date-Before-Expiration");
    }

    // Ensure Class Date 2 occurs before the expiration date
    if (values.classDates[2] && values.expirationDate && new Date(values.classDates[2].date) > new Date(values.expirationDate)) {
        errors.classDateSelect2 = i18n.t("Validate-Date-Before-Expiration");
    }

    // Ensure Class Date 3 occurs before the expiration date
    if (values.classDates[3] && values.expirationDate && new Date(values.classDates[3].date) > new Date(values.expirationDate)) {
        errors.classDateSelect3 = i18n.t("Validate-Date-Before-Expiration");
    }

    // Ensure Class Date 4 occurs before the expiration date
    if (values.classDates[4] && values.expirationDate && new Date(values.classDates[4].date) > new Date(values.expirationDate)) {
        errors.classDateSelect4 = i18n.t("Validate-Date-Before-Expiration");
    }

    if (!values.expirationOffset && !values.expirationDate) {
        errors.expirationDate = i18n.t("Validate-Expiration-Date");
    }

    if (values.trainingFormat === FORMAT_BLENDED && values.desiredLms === 'internal' && !values.classroomIndustry) {
        errors.classroomIndustry = i18n.t("Examples-Industry");
    }

    if (values.trainingFormat === FORMAT_BLENDED && values.desiredLms === 'internal' && !values.classroomLanguage) {
        errors.classroomIndustry = i18n.t("Online-Course-Language");
    }

    if (values.trainingFormat === FORMAT_BLENDED && values.desiredLms === 'internal' && !values.classroomOrganization) {
        errors.classroomOrganization = i18n.t("Organization-Seats");
    }

    return errors;
}