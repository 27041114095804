import "./certificate.scss";

import * as React from "react";

import { Button } from "react-bootstrap";
import CertificateModel from "../Interfaces/CertificateModel";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { fetchCertificateData } from "../ApiServices/Learn";
import { formatDate } from "../locale-utils"
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const CertificatePage = () => {
    const { t } = useTranslation();
    const { toast } = useToast();

    const { userContext } = React.useContext(RbacContext);

    const routeParams = useParams<{ learnerCourseId: string }>();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<CertificateModel>(null);
    const [errored, setErrored] = React.useState(false);
    const [invalidCert, setInvalidCert] = React.useState("");
    const [downloading, setDownloading] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            const data = await fetchCertificateData(parseInt(routeParams.learnerCourseId));
            setData(data.Data);

            if (!!data.Error) {
                if (data.Error.Code < 500) {
                    setInvalidCert(data.Error.Message);
                } else {
                    setErrored(true);
                }
            }

            setLoading(false);
        };

        document.title = t("View Certificate");
        getData();
    }, []);

    if (loading || errored || !!invalidCert) {
        return (
            <PageMessages loading={loading} errored={errored} notFound={!!invalidCert} notFoundMessage={invalidCert} />
        );
    }

    return (
        <>
            <div className="print-bar">
                <Button
                    variant="primary"
                    disabled={downloading}
                    onClick={() => {
                        try {
                            setDownloading(true);
                            const element = document.getElementById("certificate");
                            const opt = {
                                filename: `${data.CourseName} Certficate.pdf`,
                                image: { type: "png", quality: 0.95 },
                                html2canvas: { scale: 1 },
                                jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
                                pagebreak: { mode: "css" }
                            };
                            html2pdf()
                                .set(opt)
                                .from(element)
                                .save()
                                .then(() => {
                                    setDownloading(false);
                                    toast(t("Certificate downloaded"));
                                })
                                .catch((err: Error) => {
                                    console.error(err);
                                    setDownloading(false);
                                    toast(t("Certificate failed to download. Please try again."), true);
                                });
                        } catch (err) {
                            appInsights.trackException({ error: err, properties: userContext });
                            console.error(err);
                            setDownloading(false);
                            toast(t("Certificate failed to download. Please try again."), true);
                        }
                    }}
                >
                    {downloading ? (
                        <>
                            <i className="fas fa-spinner fa-spin"></i> {t("Downloading")}
                        </>
                    ) : (
                        t("Download")
                    )}
                </Button>
            </div>
            <Certificate data={data} />
        </>
    );
};

const Certificate = ({ data }: { data: CertificateModel }) => {
    return (
        <div id="certificate" className="container-fluid">
            <div className="certificate-page">
                <div>
                    <img className="certificate-image" src={data.BackgroundImage} />
                    <div className="certificate-content-container">
                        <div className="certificate-content">
                            <div className="certificate-name">{data.LearnerName}</div>
                            <div className={`certificate-course ${data.CourseName.length > 40 ? "shrink" : ""}`}>
                                {data.CourseName}
                            </div>
                            <div className="certificate-summary">
                                <div className="certificate-detail">{formatDate(data.CompletionDate)}</div>
                                <div className="certificate-detail">{Math.round(data.Score)}</div>
                                <div className="certificate-detail">{data.ContactHours}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {data.ContactHours && data.CeuText && (
                    <div className="ceu-page">
                        <div className="accreditation" dangerouslySetInnerHTML={{ __html: data.CeuText }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withAITracking(reactPlugin, CertificatePage);
