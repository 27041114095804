import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import useParsedTranslation from './useParsedTranslation';
import parseHtml from 'html-react-parser';

import { CultureEnum } from '../Enums';

const useCustomerSupportContactInfo = () => {
    const { t } = useTranslation();

    const getSupportContactInfoByCultureCode = (languageCode:string) => {
        languageCode = CultureEnum.getProperlyCasedCultureCode(languageCode);
        switch (languageCode) {
            case "en-US":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-US' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-US' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-US' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-US' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-US' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-US' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-US' }),
                };
            case "en-CA":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-CA' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-CA' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-CA' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-CA' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-CA' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-CA' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-CA' }),
                }; 
            case "fr-CA":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'fr-CA' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'fr-CA' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'fr-CA' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'fr-CA' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'fr-CA' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'fr-CA' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'fr-CA' }),
                };    
            case "en-GB":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-GB' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-GB' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-GB' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-GB' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-GB' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-GB' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-GB' }),
                };
            case "en-IE":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-IE' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-IE' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-IE' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-IE' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-IE' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-IE' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-IE' }),
                };
            case "en-AU":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-AU' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-AU' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-AU' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-AU' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-AU' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-AU' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-AU' }),
                };
            case "en-NZ":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-NZ' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-NZ' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-NZ' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-NZ' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-NZ' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-NZ' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-NZ' }),
                };
            case "en-FI":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'en-FI' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'en-FI' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'en-FI' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'en-FI' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'en-FI' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'en-FI' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'en-FI' }),
                };
            case "fr-FR":
                return {
                    'phone': t('CPI.ContactInfo.SupportPhone', { lng: 'fr-FR' }),
                    'email': t('ContactUs.NotificationEmail', { lng: 'fr-FR' }),
                    'startTime': t('ContactUs.StartTime', { lng: 'fr-FR' }),
                    'endTime': t('ContactUs.EndTime', { lng: 'fr-FR' }),
                    'timezone': t('ContactUs.Timezone', { lng: 'fr-FR' }),
                    'startDay': t('ContactUs.StartDay', { lng: 'fr-FR' }),
                    'endDay': t('ContactUs.EndDay', { lng: 'fr-FR' }),
                };            
        }
    }

    return { getSupportContactInfoByCultureCode }
}

export default useCustomerSupportContactInfo;