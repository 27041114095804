import * as React from "react";

import { Container, Row } from "react-bootstrap";
import { DashboardBaseRoutes, InstructorBaseRoutes, LearnerBaseRoutes } from "../Routing/routes";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../helper-functions";

import { RbacContext } from "../rbac-context";
import { RoleEnum } from "../Enums";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const BlankPage = () => {
    const { userContext } = React.useContext(RbacContext);

    if (isAuthenticated()) {
        if (!userContext.UserId) {
            return <></>;
        }

        if (userContext.Roles?.every((r) => r === RoleEnum.Learner)) {
            return <Navigate to={DashboardBaseRoutes.Dashboard.fullPath} replace />;
        }

        return <Navigate to={DashboardBaseRoutes.Dashboard.fullPath} replace />;
    }

    return (
        <Container fluid>
            <Row>
                
            </Row>
        </Container>
    );
};

export default withAITracking(reactPlugin, BlankPage);
