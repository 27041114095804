import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { ClassModel, EnrollmentListItem } from "../../Interfaces";

import { moveEnrollment } from "../../ApiServices/Class";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface MoveLearnerModalProps {
    show: boolean;
    classId: number;
    _className: string;
    classes: ClassModel[];
    enrollment: EnrollmentListItem;
    handleCancel: () => void;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
}

const MoveLearnerModal = ({
    show,
    classId,
    _className,
    classes,
    enrollment,
    handleCancel,
    onSave
}: MoveLearnerModalProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [saving, setSaving] = React.useState(false);

    const sortedClasses = React.useMemo(() => classes.sort((a, b) => a.Name.localeCompare(b.Name)), [classes]);

    const [selectedClass, setSelectedClass] = React.useState<ClassModel>(sortedClasses[0]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setSaving(true);
                const resp = await moveEnrollment(enrollment.UserId, classId, selectedClass.ClassId);
                setSaving(false);
                if (!resp.Error) {
                    resp.Data.Message = t("{{name}} ({{emailAddress}}) has been moved to {{className}}.", {
                        name: `${enrollment.FirstName} ${enrollment.LastName}`,
                        emailAddress: enrollment.EmailAddress,
                        className: selectedClass.Name
                    });
                }
                onSave(resp);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }          
        }
    };

    return (
        <Modal show={show} onHide={handleCancel}>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <h5>{t("Move Learner")}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form.Group controlId="class">
                            <Form.Label>{t("Move to Class")}</Form.Label>
                            <Form.Control
                                as="select"
                                custom
                                value={selectedClass.ClassId}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setSelectedClass(
                                        sortedClasses.find((cl) => cl.ClassId === parseInt(e.target.value))
                                    )
                                }
                            >
                                {sortedClasses.map((cl) => (
                                    <option key={cl.ClassId} value={cl.ClassId}>
                                        {cl.Name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <p className="text-break">
                            {t("Are you sure you want to move the following learner from {{className}}?", {
                                className: _className
                            })}
                        </p>
                        <p className="text-break">
                            <strong>
                                {enrollment.FirstName} {enrollment.LastName}
                            </strong>{" "}
                            ({enrollment.EmailAddress})
                        </p>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {!saving && t("Move Learner")}
                        {saving && (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Processing")}
                            </>
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, MoveLearnerModal);
