import { ApiResponse, GET, GETFROMAPIM, GETWITHERROR, JsonResponseModel, POSTWITHERROR, POSTFROMAPIM } from ".";
import { fetchConfiguration } from "./Configuration";
import { UserContext, UserModel } from "../Interfaces";
import { getUserClaims } from "../helper-functions";

import { RoleEnum } from "../Enums";

// Set Your Password Interfaces
import { SetYourPasswordValidationHandlerModel } from "../Interfaces/Platform/SetYourPassword";
import { SetYourPasswordUserData } from "../Interfaces/Platform/SetYourPassword";

import { template } from "../helper-functions";

export const LOGIN_URL = "/Login/LoginAsync";
export const LOGOUT_URL = "/Login/Logout";
export const REQUEST_PASSWORD_RESET_URL = "/Login/RequestPasswordResetAsync";
const FETCH_PASSWORD_RESET_INFO_TEMPLATE = template`/Login/GetPasswordResetInfoAsync?token=${0}`;
const RESET_PASSWORD_URL = "/Login/ResetPasswordAsync";
const CHANGE_PASSWORD_URL = "/Login/ChangePasswordAsync";
export const FETCH_MY_USER_INFO_URL = "/Login/GetUserInfo";

export async function login(email: string, password: string): Promise<JsonResponseModel<UserModel>> {
    return POSTWITHERROR(LOGIN_URL, { email, password });
}

export async function logout(): Promise<any> {
    return POSTWITHERROR(LOGOUT_URL, null);
}

export async function requestPasswordReset(email: string): Promise<JsonResponseModel<unknown>> {
    return POSTWITHERROR(REQUEST_PASSWORD_RESET_URL, { email });
}

export async function fetchPasswordResetInfo(
    token: string
): Promise<JsonResponseModel<{ Email: string; LastLogin?: string }>> {
    return GETWITHERROR(FETCH_PASSWORD_RESET_INFO_TEMPLATE(token));
}

export async function resetPassword(userId: string, password: string): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(RESET_PASSWORD_URL, { UserId: userId.replace(/ /g, "+"), password });
}

export async function changePassword(
    currentPassword: string,
    newPassword: string
): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(CHANGE_PASSWORD_URL, { currentPassword, newPassword });
}

export async function fetchMyUserInfo(): Promise<UserContext> {
    const resp = await GET(FETCH_MY_USER_INFO_URL);
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401) {
        return null;
    }
    return await resp.json();
}

export async function fetchMyProfileInfo(pingId:string): Promise<any> {
    const token = getUserClaims();
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;

    // if user is just a learner, we call /Account, otherwise we call /Account/Info
    const endpointPath = (token.rolesByName && token.rolesByName.every(role => role === RoleEnum.Learner)) ? '/q/profile/Account/' : '/q/profile/Account/Info/';

    const resp = await GETFROMAPIM(apimBaseUrl + endpointPath + pingId, config.Data.ApimKey);
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401 || resp.status === 423) {
        return null;
    }
    return await resp.json();
}

export async function fetchMyCertInfo(pingId:string): Promise<any> {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;
    const resp = await GETFROMAPIM(apimBaseUrl + '/q/certification/user/' + pingId, config.Data.ApimKey);
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401) {
        return null;
    }
    return await resp.json();
}

export async function fetchMyEnrollmentInfo(pingId:string): Promise<any> {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;
    const resp = await GETFROMAPIM(apimBaseUrl + '/q/enrollment/active/user/' + pingId, config.Data.ApimKey);
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401) {
        return null;
    }
    return await resp.json();
}

export async function fetchOrganizationInfo(orgId:string): Promise<any> {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;
    const resp = await GETFROMAPIM(apimBaseUrl + '/q/organization/details/' + orgId, config.Data.ApimKey);
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401) {
        return null;
    }
    return await resp.json();
}

export async function fetchAltOrganizations(userId: string, organizationId:string): Promise<any> {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;
    const resp = await POSTFROMAPIM(apimBaseUrl + '/q/organization/alternates/', config.Data.ApimKey, { userId, organizationId });
    if (resp.status === 204 || resp.status === 404 || resp.status === 302 || resp.status === 401) {
        return [];
    }
    return await resp.json();
}

export async function validateSetPasswordToken(): Promise<SetYourPasswordValidationHandlerModel> {
    const config = await fetchConfiguration();
    const apimBaseUrl = config.Data?.ApimBaseUrl;
    const token = new URLSearchParams(window.location.search).get('token');

    const resp = await GETFROMAPIM(apimBaseUrl + "/Q/profile/TokenValid/" + token, config.Data.ApimKey);
    const responseData:SetYourPasswordUserData = (resp.status == 200) ? await resp.json() : { userId: null, email: null };
    return { status: resp.status, data: responseData }
}
