import "./i18n";

import * as React from "react";

import { AdminBaseRoutes, PlatformBaseRoutes, DashboardBaseRoutes, TrainingCenterBaseRoutes, InstructorBaseRoutes, LearnerBaseRoutes, ErrorBaseRoutes, DevBaseRoutes, SupportBaseRoutes, MyTrainingBaseRoutes } from "./Routing/routes";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import FirstTimeLoginPage from "./Pages/first-time-login";
import { ConfigContext, HoneyBeeConfigContextApp } from "./configuration-context";
import { HoneyBeeUserContextApp } from "./rbac-context";
import MaintenancePage from "./Pages/maintenance";
import LoginPage from "./Pages/login";
import BlankPage from "./Pages/blank";
import MyAccountPage from "./Pages/my-account";
import Page from "./Pages/page";
import ProtectedRoute from "./Routing/protected-route";
import ResetPasswordPage from "./Pages/reset-password";
import ErrorPage from "./Pages/error";
import { ToastProvider } from "./hooks/toast";
import browserUpdate from "./browser-update";
import i18n from "./i18n";
import moment from "moment";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import ConditionalWrapper from "./Components/conditional-wrapper";
import LearnerRouting from "./Routing/learner-routing";
import PlatformRouting from "./Routing/platform-routing";
import DashboardRouting from "./Routing/dashboard-routing";
import DevRouting from "./Routing/dev-routing";
import AdminRouting from "./Routing/admin-routing";
import { InstructorClassRouting, InstructorLearnerRouting, InstructorCourseSeatRouting } from "./Routing/instructor-routing";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./application-insights";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import TrainingCenterRouting from "./Routing/training-center-routing";
import SupportRouting from "./Routing/support-routing";
import TrainingRouting from "./Routing/training-routing";
import LoadingDisplay from "./Components/UI/loading";

import UserTokenHandler from "./Components/FunctionalComponents/user-token-handler";

// Redux Toolkit
import { store } from "./store";
import { Provider } from "react-redux";
import NoAccess from "./Pages/no-access";
import BannerMessage from "./Pages/Components/banner-message";

moment.locale(i18n.language);
browserUpdate();

const queryClient = new QueryClient();

const App = () => {
    const { t } = useTranslation();
    const configContext = React.useContext(ConfigContext);
    const maint = process.env.ENVIRONMENT == "maint";
    const local = process.env.ENVIRONMENT == "local";
    const ping = configContext?.SystemConfiguration?.UsePing;

    const [fadeIn, setFadeIn] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => setFadeIn(true), 20); // adjust delay as needed

        const storageForBanner = localStorage.getItem('isVisibleBanner');

        if (!storageForBanner) {
            localStorage.setItem('isVisibleBanner', 'true');
        }

        return () => clearTimeout(timer);
    }, []);

    const fadeInStyle = {
        transition: 'opacity 0.2s',
        opacity: fadeIn ? 1 : 0,
    };

    if (maint) {
        return (
            <div className="wrap" style={fadeInStyle}>
                <Router>
                    <Page title={t("Site Maintenance")}>
                        <Routes>
                            <Route path="*" element={<MaintenancePage />} />
                        </Routes>
                    </Page>
                </Router>
            </div>
        );
    }
    else {
        return (
            <ConditionalWrapper condition={!local} wrapper={children => <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>}>
                <>
                <div className="wrap" style={fadeInStyle}>
                  
                        <Router>
                            <Page title={t("Crisis Prevention Institute")}>
                                <Routes>
                                    <Route path="" 
                                        element={
                                            <BlankPage />
                                        }
                                    />
                                    {ping && (
                                        <Route path="/Login" 
                                            element={
                                                <BlankPage />
                                            }
                                        />
                                    )}
                                    {!ping && (
                                        <Route path="/Login" 
                                            element={
                                                <LoginPage />
                                            }
                                        />
                                    )}
                                    <Route path="/Login/ResetPassword"
                                        element={
                                            <ResetPasswordPage />
                                        }
                                    />
                                    <Route path="/Login/FirstTimeLogin"
                                        element={
                                            <FirstTimeLoginPage />
                                        }
                                    />
                                    <Route path="/Error"
                                        element={
                                            <ErrorPage />
                                        }
                                    />
                                    <Route path={`${DevBaseRoutes.Development.fullPath}/*`}
                                        element={
                                            <DevRouting />
                                        }
                                    />
                                    <Route path={`${DashboardBaseRoutes.Dashboard.fullPath}/*`}
                                        element={
                                            <DashboardRouting />
                                        }
                                    />
                                    <Route path={`${PlatformBaseRoutes.PlatformBaseRoute.fullPath}/*`}
                                        element={
                                            <PlatformRouting />
                                        }
                                    />
                                    <Route path={`${TrainingCenterBaseRoutes.TrainingCenter.fullPath}/*`}
                                        element={
                                            <TrainingCenterRouting />
                                        }
                                    />
                                    <Route path={`${LearnerBaseRoutes.Learn.fullPath}/*`}
                                        element={
                                            <LearnerRouting />
                                        }
                                    />
                                    <Route path={`${AdminBaseRoutes.Admin.fullPath}/*`}
                                        element={
                                            <AdminRouting />
                                        }
                                    />
                                    <Route path={`${InstructorBaseRoutes.Class.Class.fullPath}/*`}
                                        element={
                                            <InstructorClassRouting />
                                        }
                                    />
                                    <Route path={`${InstructorBaseRoutes.Learner.Learner.fullPath}/*`}
                                        element={
                                            <InstructorLearnerRouting />
                                        }
                                    />
                                    <Route path={`${InstructorBaseRoutes.CourseSeat.CourseSeat.fullPath}`}
                                        element={
                                            <InstructorCourseSeatRouting />
                                        }
                                    />
                                    <Route path="/MyAccount"
                                        element={
                                            <ProtectedRoute>
                                                <MyAccountPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route path={`${MyTrainingBaseRoutes.MyTraining.fullPath}/*`}
                                        element={
                                            <TrainingRouting />
                                        }
                                    />
                                    <Route path={`${SupportBaseRoutes.Support.fullPath}/*`}
                                        element={
                                            <SupportRouting />
                                        }
                                    />
                                    <Route path="*"
                                        element={
                                            <NoAccess />
                                        }
                                    />
                                </Routes>
                            </Page> 
                        </Router>
        
                </div>
                <BannerMessage />
                </>
            </ConditionalWrapper>
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(
    <React.Suspense fallback={<LoadingDisplay />}>   
            <UserTokenHandler /> {/* Listens for user interaction and maintains session tokens */}   
            <HoneyBeeConfigContextApp>
                <HoneyBeeUserContextApp>
                    <QueryClientProvider client={queryClient}>
                         <ToastProvider>
                            <Provider store={store}>
                                <App />
                            </Provider>                    
                        </ToastProvider>
                    </QueryClientProvider>
                </HoneyBeeUserContextApp>
            </HoneyBeeConfigContextApp>        
    </React.Suspense>
);
