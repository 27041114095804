import * as React from "react";

const Tile = (props: {
    children: JSX.Element,
    title: string,
    subtitle?: string,
    sideLabel?: string,
    endSubtitle?: string,
    hideTileHeader?: boolean,
    customClassName?: string,
    hide?:boolean
}): JSX.Element => {
    const hide = (props.hide) ? props.hide : false;
    const customClassName = props.customClassName ?? '';

    return (
        <>
            { !hide &&
                <div className={`tile ${customClassName}`}>
                    <div className="tile-inner-wrapper">
                        { !props.hideTileHeader ? 
                            <>
                                <div className="mt-2 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <h2 className="section-title-h2">{props.title}</h2>
                                        <div>{props.sideLabel}</div>
                                    </div>

                                    <div className="basic-tile-subtitle">{props.subtitle}</div>

                                    { props.endSubtitle != '' ?
                                        <div className="basic-tile-end-subtitle">{props.endSubtitle}</div> : null
                                    }

                                </div>

                                <div className="tile-title-separator mt-1"></div>
                            </> 

                            : 
                            
                            null 
                        }
                        <div className="dashed-separated">
                            
                            {props.children}

                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Tile;
