import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin,  } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { fetchConfiguration } from './ApiServices/Configuration';

let config;
let appInsights;

const reactPlugin = new ReactPlugin();

const readConfig = async () => {
    config = await fetchConfiguration();
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config?.Data?.ApplicationInsights ?? '',
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });

    appInsights.loadAppInsights();
}

readConfig();

const browserHistory = createBrowserHistory({ basename: '' });

export { appInsights, reactPlugin };
