import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../configuration-context";

export interface Workbook {
    isSelected: boolean;
    edition: string;
    specialty: string;
    hasAdditionalContent: boolean;
    hasSupplimentContent: boolean;
    active: boolean;
    defaultContent: string;
}
const SelectWorkbook = (props: {
    workbook: Workbook,
    editable: boolean,
    archived?: boolean,
    selectWorkbook: (id: string) => void
}): JSX.Element => {
    const { t } = useTranslation();

    const configContext = React.useContext(ConfigContext);

    return (
        <>
            <div className="select-workbook-container">
                <div className={`${props.workbook.isSelected ? 'workbook-selected' : 'workbook-unselected'}`}>
                    <div className={`select-workbook-inner-wrapper ${props.editable ? 'editable' : ''}`} onClick={() => props.editable ? props.selectWorkbook(props.workbook.edition) : {}}>
                        { !props.archived && 
                            <div className="select-workbook-thumbnail mx-auto">                            
                                    <img
                                        className="thumbnail-image"
                                        alt={`thumbnail-${props.workbook.edition}`}
                                        src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/platform/components/select-workbook/${props.workbook.specialty}-${props.workbook.edition}.jpg`}
                                    />
                            </div>
                        }   
                        <p className="select-workbook-label text-capitalize">
                            {t(`Edition.${props.workbook.edition}`)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );

};

export default SelectWorkbook;
