import * as React from 'react'; 
import { ConfigContext } from '../configuration-context';

const useForm = (submitFormValues:any, validate:any) => {
    const [errors, setErrors] = React.useState<any>({});
    const [formFieldData, setFormFieldData] = React.useState<any>({});
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleSubmit = (event:any, scroll: boolean = false) => {
        if (event) {event.preventDefault();}
        setIsSubmitting(true);
        setErrors(validate(formFieldData));
        if (Object.entries(validate(formFieldData))?.length > 0 && scroll) window.scrollTo(0,0);
    };

    const handleValidate = () => setErrors(validate(formFieldData));
    const handleCheckErrorsExist = () => Object.entries(validate(formFieldData)).length > 0;

    const handleChangeByUserEvent = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        e.persist();
        
        const { name, value } = e.target;
        setFormFieldData(previousState => {
            const newState = {
                ...previousState,
                [name]: value
            };
            if (Object.entries(errors).length > 0) setErrors(validate(newState));
            return newState;
        });

        //alert(JSON.stringify(formFieldData));
    };

    const handleChangeByGetEvent = (newData:any) => {

        for (const key in newData) {
            setFormFieldData(previousState => {
                const newState = {
                    ...previousState,
                    [key]: newData[key]
                };
                if (Object.entries(errors).length > 0) setErrors(validate(newState));
                return newState;
            });
        }
        
    }

    const handleResetErrors = () => setErrors({});

    React.useEffect(() => {

        // Prevents auto re-submit after first submit and cleared errors
        if (Object.keys(errors).length > 0) setIsSubmitting(false);
        if (Object.keys(errors).length == 0 && isSubmitting) { submitFormValues(formFieldData); }
    }, [errors]);

    return {
        handleChangeByUserEvent,
        handleChangeByGetEvent,
        handleSubmit,
        handleResetErrors,
        handleValidate,
        handleCheckErrorsExist,
        formFieldData,
        errors
    }
}

export default useForm;