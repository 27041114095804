import * as React from "react";

import { Container, Tab, Tabs } from "react-bootstrap";

import EmailTemplates from "./Components/email-templates";
import GeneralOrgSettings from "./Components/general-org-settings";
import Reminders from "./Components/reminders";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";

const OrganizationSettings = () => {
    const { t } = useTranslation();
    const { toast } = useToast();

    return (
        <>
            <h1>{t("Organization Settings")}</h1>
            <Container fluid className="mt-4">
                <Tabs id="org-settings-tabs" defaultActiveKey="general">
                    <Tab eventKey="general" title={t("General")}>
                        <GeneralOrgSettings toast={toast} />
                    </Tab>
                    <Tab eventKey="email-templates" title={t("Email Templates")}>
                        <EmailTemplates toast={toast} />
                    </Tab>
                    <Tab eventKey="reminders" title={t("Reminders")}>
                        <Reminders toast={toast} />
                    </Tab>
                </Tabs>
            </Container>
        </>
    );
};

export default OrganizationSettings;
