import "./sync-my-courses-component.scss";

import * as React from "react";

import { ConfigContext } from "../../configuration-context";
import { RbacContext } from "../../rbac-context";
import { syncCourseProgress } from "../../ApiServices/Learn";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface SyncMyCoursesComponentProps {
    pageLoad: boolean;
    onSynced: () => Promise<any>;
}

const SyncMyCoursesComponent = (props: SyncMyCoursesComponentProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const { SystemConfiguration } = React.useContext(ConfigContext);

    const [syncing, setSyncing] = React.useState(false);
    const [synced, setSynced] = React.useState(false);
    const [syncError, setSyncError] = React.useState(false);

    React.useEffect(() => {
        const sync = async () => {
            try {
                setSyncing(true);
                var resp = await syncCourseProgress();
                if (!resp.Data.Success) {
                    setSyncError(true);
                } else {
                    await props.onSynced();
                    setSynced(true);
                    setSyncing(false);
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setSyncing(false);
                setSyncError(true);
            }
        };

        if (SystemConfiguration.EnableCourseProgressSync && !props.pageLoad && !synced) {
            sync();
        }
    }, [SystemConfiguration, props.pageLoad]);

    return (
        <>
            {SystemConfiguration.EnableCourseProgressSync && (
                <div className="sync-course-progress" >
                    {syncing && (
                        <>
                            <span className="me-1">
                                <i className="fas fa-sync fa-spin"></i>
                            </span>
                            {t("Updating course progress")}
                        </>
                    )}
                    {synced && (
                        <>
                            <span className="me-1">
                                <i className="fas fa-check"></i>
                            </span>
                            {t("Course progress updated")}
                        </>
                    )}
                    {syncError && (
                        <>
                            <span className="me-1">
                                <i className="fas fa-times"></i>
                            </span>
                            {t("Course progress failed to update")}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default withAITracking(reactPlugin, SyncMyCoursesComponent, "SyncMyCoursesComponent", "sync-course-progress");
