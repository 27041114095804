import * as React from "react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";
import Select from "../Form/select";
import Button from "../UI/button";
import PopUpModal from "../UI/modal-pop-up";
import ClassInstructorChange from "../../Pages/Components/Classes/instructor-change";
import ClassInstructorRemove from "../../Pages/Components/Classes/instructor-remove";
import TextInput from "../Form/text-input";
import DestructiveButton from "../UI/cancel-button";
import type { IAdditionalInstructor, IAdditionalInstructorProps } from "../../Interfaces/Platform/AdditionalInstructor";

const AdditionalInstructor = (props: IAdditionalInstructorProps): JSX.Element => {
    const defaultProps = {
        preselectable: false,
        preselectedOption: "",
        editable: true,
        specialtySelected: "",
        fromClassDetails: false,
        handleChangeBySpecialty: () => {},
        removable: false
    };
    const { t } = useTranslation();    
    props = {...defaultProps, ...props};
    const errorLabel = props.errorLabel ? props.errorLabel : t("Default Alert Text");
    const fieldErrorBorderClass = props.isError ? "additionalInstructorFieldError" : "";
    const [selectInputArr, setSelectInputArr] = React.useState([]);
    const [showPrimaryInstructorChange, setShowPrimaryInstructorChange] = React.useState<boolean>(false);
    const [newPrimaryInstructorId, setNewPrimaryInstructorId] = React.useState<string | null>(null);
    const [showAdditionalInstructorChange, setShowAdditionalInstructorChange] = React.useState<boolean>(false);
    const [showAdditionalInstructorRemove, setShowAdditionalInstructorRemove] = React.useState<boolean>(false);
    const [additionalInstructorToRemove, setAdditionalInstructorToRemove] = React.useState<string>();
    const [newAdditionalInstructor, setNewAdditionalInstructor] = React.useState<{ id: string, value: string } | null>(null);
    const [isDisabled, setIsDisabled] = React.useState(false)
    
    const [optionsArr, setOptionsArr] = React.useState<FormSelectOption[]>([        //Mock data just for testing
        // {label: "Select", value: null, disabled: false},
    ]);

    React.useEffect(() => {
        if (props.options && props.options.length > 0) {
            setOptionsArr([...props.options]);
        }
    }, [props.options]);

    const [selectedOptionsArr, setSelectedOptionsArr] = React.useState<any>({});

    const isSelectable = optionsArr.map((option) => option.value === props.preselectedOption);
    React.useEffect(() => {
        if (props.preselectable && isSelectable) {
            setSelectedOptionsArr((prevState: any) => {
                props.setSelected({...prevState, primaryInstructor: props.preselectedOption });
                return {...prevState, primaryInstructor: props.preselectedOption };
            });
        }
    }, [props.preselectedOption]);

    const hasMoreAdditional = props.options.length > Object.entries(props.selectedOptions).length;

    const ids = Object.values(props.selectedOptions).map((value) => value);
    React.useEffect(() => {
        setOptionsArr((prevState) => {
            return prevState.map((option) => {
                return ids.some((id) => id === option.value) ? { ...option, disabled: true } : { ...option, disabled: false };
            });
        });
    }, [selectedOptionsArr, props.options]);

    const onChange = (event:any) => {
        let _optionsArr = [...optionsArr];
        let _selectedOptionsArr = {...selectedOptionsArr}

        if(_selectedOptionsArr[event.target.id] === undefined) {        //check if this selected option has not ben used yet
            _selectedOptionsArr[event.target.id] = event.target.value;
            _optionsArr[event.target.selectedIndex].disabled = true;    //disable current selected value
            setSelectedOptionsArr(_selectedOptionsArr);
            props.setSelected(_selectedOptionsArr);
        }
        else if(_selectedOptionsArr[event.target.id] !== event.target.value) {
            const index = optionsArr.map(e => e.value).indexOf(_selectedOptionsArr[event.target.id]);
            _optionsArr[index].disabled = false;                        //un-disable previously selected value
            _selectedOptionsArr[event.target.id] = event.target.value;
            _optionsArr[event.target.selectedIndex].disabled = true;    //disable current selected value
            setSelectedOptionsArr(_selectedOptionsArr);
            props.setSelected(_selectedOptionsArr);
        }
        setOptionsArr(_optionsArr);
    }

    const instructorHasSpecialty = (value: string) => {
        // Get CURRENT specialty selected
        const specialty = props.specialtySelected;
        // Get NEW SELECTED instructor specialty from response
        const instructorCertifications = props.options.filter((i) => i.value === value)[0]?.certifications;
        // Check if NEW instructor has SPECIALTY on its list
        return instructorCertifications.includes(specialty);
    };

    const onChangePrimary = (event: any) => {
        let _selectedOptionsArr = {...selectedOptionsArr};

        if (props.specialtySelected) {
            if (_selectedOptionsArr.primaryInstructor === undefined) {        //check if this selected option has not ben used yet
                _selectedOptionsArr.primaryInstructor = event.value;
                setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: event.value}));
                props.setSelected(_selectedOptionsArr);
            } else if (_selectedOptionsArr.primaryInstructor !== event.value) {
                if (!props.fromClassDetails) {
                    _selectedOptionsArr.primaryInstructor = event.value;
                    setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: event.value}));
                    props.setSelected(_selectedOptionsArr);
                } else {
                    // Class Details
                    if (instructorHasSpecialty(event.value)) {
                        // If the instructor has specialty listed, let the NEW instructor be selected
                        _selectedOptionsArr.primaryInstructor = event.value;
                        setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: event.value}));
                        props.setSelected(_selectedOptionsArr);
                    } else {
                        // Check if any instructors other than the already selected primary have specialty under certifications
                        if (otherInstructorsThanPrimaryHaveSpecialty(event.value, selectedOptionsArr.primaryInstructor)) {
                            // If any other instructors have specialty, change primary instructor
                            _selectedOptionsArr.primaryInstructor = event.value;
                            setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: event.value}));
                            props.setSelected(_selectedOptionsArr);
                        } else {
                            // Open Modal
                            // If the instructor doesn't have the specialty listed, open modal to let user know
                            setShowPrimaryInstructorChange(true);
                            setNewPrimaryInstructorId(event.value);
                            // Instructor change or Cancel action will be handled by modal actions
                        }
                    }
                }
            }
        } else {
            _selectedOptionsArr.primaryInstructor = event.value;
            setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: event.value}));
            props.setSelected(_selectedOptionsArr);
        }
    };

    React.useEffect(() => {
        if (props.selectedOptions.primaryInstructor) {
            setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: optionsArr.filter((option) => option.value === props.selectedOptions?.primaryInstructor)[0]?.value }));
        } else {
            props.additionalInstructors.filter((instructor) => instructor.show).forEach((i) => {
                setSelectedOptionsArr((prevState: any) => ({...prevState, [i.id]: i.value }));
            });
        }
    }, [props.selectedOptions]);

    const selectedInstructorsIds = Object.entries(props.selectedOptions).map(([key, value]) => value);
    const otherInstructorsThanPrimaryHaveSpecialty = (instructorId: string, alreadySelectedId: string) => {
        return optionsArr.filter((i) => i.value !== instructorId && i.value !== alreadySelectedId).filter((i) => selectedInstructorsIds.includes(i.value)).filter((i) => i.certifications.includes(props.specialtySelected)).length > 0;
    };
    const otherInstructorsThanAdditionalHaveSpecialty = (instructorId: string, previouslySelected: string) => {
        return optionsArr.filter((i) => i.value !== instructorId && i.value !== previouslySelected).filter((i) => selectedInstructorsIds.includes(i.value)).filter((i) => i.certifications.includes(props.specialtySelected)).length > 0;
    };
    const otherInstructorsHaveSpecialty = (instructorId: string) => {
        return optionsArr.filter((i) => i.value !== instructorId).filter((i) => selectedInstructorsIds.includes(i.value)).filter((i) => i.certifications.includes(props.specialtySelected)).length > 0;
    };

    const changePrimaryInstructor = () => {
        let _selectedOptionsArr = {...selectedOptionsArr};
        _selectedOptionsArr.primaryInstructor = newPrimaryInstructorId;
        setSelectedOptionsArr((prevState: any) => ({...prevState, primaryInstructor: newPrimaryInstructorId}));
        props.setSelected(_selectedOptionsArr);
        // Reset all form below of instructors
        props.handleChangeBySpecialty();
        setShowPrimaryInstructorChange(false);
    };

    const onChangeAdditional = (event: FormSelectOption, id: string) => {
        let _selectedOptionsArr = { ...props.selectedOptions };

        props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => prevState.map((instructor) => {
            return instructor.id === id ? { ...instructor, value: event.value } : instructor;
        }));

        if (props.specialtySelected) {
            if (_selectedOptionsArr[id] === undefined) {        //check if this selected option has not ben used yet
                _selectedOptionsArr[id] = event.value;
                setSelectedOptionsArr((prevState: any) => ({...prevState, [id]: event.value}));
                props.setSelected(_selectedOptionsArr);
            } else if (_selectedOptionsArr[id] !== event.value) {
                if (!props.fromClassDetails) {
                    _selectedOptionsArr[id] = event.value;
                    setSelectedOptionsArr((prevState: any) => ({...prevState, [id]: event.value}));
                    props.setSelected(_selectedOptionsArr);
                } else {
                    // Class Details
                    if (instructorHasSpecialty(event.value)) {
                        // If the instructor has specialty listed, let the NEW instructor be selected
                        _selectedOptionsArr[id] = event.value;
                        setSelectedOptionsArr((prevState: any) => ({...prevState, [id]: event.value}));
                        props.setSelected(_selectedOptionsArr);
                    } else {
                        // Check if any other instructor selected have specialty under certifications
                        if (otherInstructorsThanAdditionalHaveSpecialty(event.value, selectedOptionsArr[id])) {
                            // Change additional if any other instructor selected have specialty
                            _selectedOptionsArr[id] = event.value;
                            setSelectedOptionsArr((prevState: any) => ({...prevState, [id]: event.value}));
                            props.setSelected(_selectedOptionsArr);
                        } else {
                            // Open Modal
                            // If the other instructors don't have the specialty listed, open modal to let user know
                            setShowAdditionalInstructorChange(true);
                            setNewAdditionalInstructor({id, value: event.value});
                            // Instructor change or Cancel action will be handled by modal actions
                        }
                    }
                }
            }
        } else {
            _selectedOptionsArr[id] = event.value;
            setSelectedOptionsArr((prevState: any) => ({...prevState, [id]: event.value}));
            props.setSelected(_selectedOptionsArr);
        }
    };

    const changeAdditionalInstructor = () => {
        let _selectedOptionsArr = {...selectedOptionsArr};
        _selectedOptionsArr[newAdditionalInstructor.id] = newAdditionalInstructor.value;
        setSelectedOptionsArr((prevState: any) => ({...prevState, [newAdditionalInstructor.id]: newAdditionalInstructor.value}));
        props.setSelected(_selectedOptionsArr);
        // Reset all form below of instructors
        props.handleChangeBySpecialty();
        setShowAdditionalInstructorChange(false);
    };

    const removeAdditionalInstructor = () => {
        props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
            return prevState.filter((instructor) => instructor.id !== additionalInstructorToRemove);
        });
        props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
            const lastAdditionalInstructor = parseInt(prevState.at(-1).id.split("-")[1]);
            return [...prevState, { id: `additionalInstructor-${lastAdditionalInstructor + 1}`, show: false, value: null }];   
        });
        props.setSelected((prevState: any) => {
            const selected = { ...prevState };
            delete selected[additionalInstructorToRemove];
            return selected;
        });
        setSelectedOptionsArr((prevState: any) => {
            const selectedOptions = { ...prevState };
            delete selectedOptions[additionalInstructorToRemove];
            return selectedOptions;
        });
        props.handleChangeBySpecialty();
        setShowAdditionalInstructorRemove(false);
        setAdditionalInstructorToRemove("");
    };

    const addAdditionalInstructor = () => {
        setSelectInputArr([...selectInputArr, { selectInput: SelectInputComponent(), toDelete: false }]);
    }
    
    const SelectInputComponent = () => {
        return (
            <Select
                label={t("Additional Instructor")}
                name={`additionalInstructor-${selectInputArr.length}`}
                id={`additionalInstructor-${selectInputArr.length}`}
                options={optionsArr}
                isRequired={true}
                isError={false}
                errorLabel="error"
                changeAction={(event) => onChange(event)}
            ></Select>
        )
    }

    const cancelClick = (index:number) => {
        let tempSelectInputArr = [...selectInputArr];
        tempSelectInputArr[index].toDelete = true;
        setSelectInputArr(tempSelectInputArr);
    }

    // ToDo: probably an undo button will be included. hence, this code
    const undoCancelClick = (index:number) => {
        let tempSelectInputArr = [...selectInputArr];
        tempSelectInputArr[index].toDelete = false;
        setSelectInputArr(tempSelectInputArr);
    }

    React.useEffect(() => {
        selectInputArr.length > 3 && !isDisabled ? setIsDisabled(true) : setIsDisabled(false)
    }, [selectInputArr, optionsArr, selectedOptionsArr]);

    React.useEffect(() => {
        props.additionalInstructors.filter((instructor) => instructor.show).length > 3 && !isDisabled ? setIsDisabled(true) : setIsDisabled(false)
    }, [props.additionalInstructors, optionsArr, props.selectedOptions]);

    const handleAddAdditional = () => {
        props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
            const additionalInstructor = prevState.find((instructor) => !instructor.show);
            return prevState.map((instructor) => {
                return additionalInstructor.id === instructor.id ? { ...instructor, show: true } : instructor;
            });
        });
    };

    const handleRemoveAdditional = (additionalInstructor: IAdditionalInstructor) => {

        // Class Details
        // Check if other instructors have specialty under certifications
        if (additionalInstructor?.value && props.specialtySelected) {
            if (!otherInstructorsHaveSpecialty(additionalInstructor.value)) {
                // If no other instructor have specialty, show modal to warn user
                setShowAdditionalInstructorRemove(true);
                setAdditionalInstructorToRemove(additionalInstructor.id);
            } else {
                // If there are other instructors with specialty remove additional instructor
                props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
                    return prevState.filter((instructor) => instructor.id !== additionalInstructor.id);
                });
                props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
                    const lastAdditionalInstructor = parseInt(prevState.at(-1).id.split("-")[1]);
                    return [...prevState, { id: `additionalInstructor-${lastAdditionalInstructor + 1}`, show: false, value: null }];   
                });
                props.setSelected((prevState: any) => {
                    const selected = { ...prevState };
                    delete selected[additionalInstructor.id];
                    return selected;
                });
                setSelectedOptionsArr((prevState: any) => {
                    const selectedOptions = { ...prevState };
                    delete selectedOptions[additionalInstructor.id];
                    return selectedOptions;
                });
            }
        } else {
            // If no instructor and program has been selected don't verify specialty from instructors and just remove additional instructor
            props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
                return prevState.filter((instructor) => instructor.id !== additionalInstructor.id);
            });
            props.setAdditionalInstructors((prevState: IAdditionalInstructor[]) => {
                const lastAdditionalInstructor = parseInt(prevState.at(-1).id.split("-")[1]);
                return [...prevState, { id: `additionalInstructor-${lastAdditionalInstructor + 1}`, show: false, value: null }];   
            });
            props.setSelected((prevState: any) => {
                const selected = { ...prevState };
                delete selected[additionalInstructor.id];
                return selected;
            });
            setSelectedOptionsArr((prevState: any) => {
                const selectedOptions = { ...prevState };
                delete selectedOptions[additionalInstructor.id];
                return selectedOptions;
            });
        }
    };

    return (
        <div className="additional-instructor-container">
            <PopUpModal
                title="Primary Instructor"
                content={
                    <ClassInstructorChange
                        specialty={props.specialtySelected}
                        onClose={() => setShowPrimaryInstructorChange(false)}
                        onSuccess={changePrimaryInstructor}
                    />
                }
                show={showPrimaryInstructorChange}
                showFooter={false}
                hide={() => setShowPrimaryInstructorChange(false)}
            />
            <PopUpModal
                title="Additional Instructor"
                content={
                    <ClassInstructorChange
                        specialty={props.specialtySelected}
                        onClose={() => setShowAdditionalInstructorChange(false)}
                        onSuccess={changeAdditionalInstructor}
                    />
                }
                show={showAdditionalInstructorChange}
                showFooter={false}
                hide={() => setShowAdditionalInstructorChange(false)}
            />
            <PopUpModal
                title="Additional Instructor"
                content={
                    <ClassInstructorRemove
                        specialty={props.specialtySelected}
                        onClose={() => setShowAdditionalInstructorRemove(false)}
                        onSuccess={removeAdditionalInstructor}
                    />
                }
                show={showAdditionalInstructorRemove}
                showFooter={false}
                hide={() => setShowAdditionalInstructorRemove(false)}
            />
            <div className="primaryInstructorBox">
                <div className="primaryInstructorLabel">
                    <label className="form-label bold fs14">{t("Primary Instructor")}</label>
                    { props.editable && (props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span>) }
                </div>
                {(props.editable && !props.lockPrimaryInstructor) ? (
                    <ReactSelect
                        name="primaryInstructor"
                        id="primaryInstructor"
                        key="primaryInstructor"
                        placeholder={t("Select Instructor")}
                        isSearchable={false}
                        classNames={{
                            indicatorSeparator: () => 'select-separator',
                            control: () => !ids[0] && fieldErrorBorderClass
                        }}
                        filterOption={(option) => ids.every((id) => id !== option.value)}
                        options={optionsArr}
                        value={optionsArr.filter((option) => option.value === props.selectedOptions.primaryInstructor)[0] || null}
                        onChange={(event) => onChangePrimary(event)}
                    />
                ): (
                    <div>{optionsArr.filter((option) => option.value === props.selectedOptions.primaryInstructor)[0]?.label}</div>
                )}
                { props.isError && !ids[0] ? <span className="field-validation form-field-error small-error" id="primary-instructor-required-label-1">{errorLabel}</span> : null }
            </div>
            <div className="additionalInstructorsWrapper">
                {props.additionalInstructors.filter((instructor) => instructor.show).map((instructor, index) => {
                    return (
                        <>
                            <br/>
                            <div className="additionalInstructorBox">
                                <div className="primaryInstructorLabel">
                                    <label className="form-label bold fs14">{t("Additional Instructor")}</label>
                                    { props.editable && (props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span>) }
                                </div>
                                <div className="additionalInstructorSelect mb-0">
                                    {(props.editable && !instructor.locked) ? (
                                        <>
                                            <ReactSelect
                                                name={instructor.id}
                                                id={instructor.id}
                                                className="w-100"
                                                classNames={{
                                                    indicatorSeparator: () => 'select-separator',
                                                    control: () => !ids[index + 1] && fieldErrorBorderClass
                                                }}
                                                filterOption={(option) => ids.every((id) => id !== option.value)}
                                                isSearchable={false}
                                                options={optionsArr}
                                                value={optionsArr.filter((option) => option.value === props.selectedOptions[instructor.id])[0]}
                                                onChange={(event) => onChangeAdditional(event, instructor.id)}
                                            />
                                            <div className="additionalInstructorClose" onClick={() => handleRemoveAdditional(instructor)}>
                                                <i className="fa-light fa-sharp fa-xmark additionalInstructorClose-Icon" />
                                            </div>
                                        </>
                                    ) : (
                                        <div>{optionsArr.filter((option) => option.value === props.selectedOptions[instructor.id])[0]?.label}</div>
                                    )}
                                    { props.isError && !ids[index + 1] ? <span className="field-validation form-field-error small-error" id="primary-instructor-required-label-1">{errorLabel}</span> : null }
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            {(!isDisabled && props.editable && hasMoreAdditional && props.options.length > 1) && (
                <>
                    <br/>
                    <div className={`${isDisabled ? 'is-disabled' : 'none'}`} aria-disabled={isDisabled}>
                        <DestructiveButton
                            label={<><i className="fa-light fa-sharp fa-plus" /> {t("Add Instructor")}</>}
                            isDisabled={isDisabled}
                            elementId="addInstructorButton"
                            clickAction={() => handleAddAdditional()}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default AdditionalInstructor;
