import * as React from "react";
import { useNavigate } from "react-router-dom"
import Button from "../../../Components/UI/button";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import i18next, { t } from "i18next";

import { PlatformBaseRoutes } from "../../../Routing/routes";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const InstructorListItem = (props: {
    listItems?: JSX.Element[]
}): JSX.Element => {
    const { isMobile } = useDetectWindowSize();
    const navigate = useNavigate();

    const goToInstructorDetails = (instructorUserId:any) => {
        navigate(`${PlatformBaseRoutes.DashboardInstructorProfile.fullPath.replace(':instructorId', instructorUserId)}`);
    }

    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const displayDate = (date: string) => {
        return t("dynamicDate", { val: new Date(date), formatParams: { month: "numeric", day: "numeric", year: "numeric" } });
    };

    return (
        <>
            {
                props.listItems?.map((instructor) => (
                    <tr>
                        <td className="instructor-name">
                            <div>{instructor.firstName} {instructor.lastName}</div>
                        </td>
                        <td className="instructor-cert">
                            {instructor.specialty}
                        </td>
                        <td className="instructor-start-end-dates">
                            {displayDate(instructor.startDate)} - {displayDate(instructor.endDate)}
                        </td>
                        <td className="instructor-status">
                            {instructor.status}
                        </td>
                        <td>
                            <Button 
                                label="View Details"
                                isSolid={false}
                                elementId="viewInstructorDetailsButton"
                                additionalStyleClasses={null}
                                clickAction={() => goToInstructorDetails(instructor.userId)}
                            />
                        </td>
                    </tr>
                ))
            }
        </>
    )

};

export default InstructorListItem;
