import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import ArrowLink from "../../Components/UI/arrow-link";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

const ImplemeningCpiAtYourOrganization = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE CODE BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}

                                <h1 className="page-title-h1 title-margin">{t('ImplementCPI-Title')}</h1>
                                <p className="mw-900 mt-1 mb-0">{tp('ImplementCPI-Intro')}</p>
                            </>
                        }
                    />


                    <TwoColumnLayout
                        marginTop={false}
                        left={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}


                                <Tile title={t('ImplementCPI-Implement')} hideTileHeader={false}>

                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <h3 className="sub-headings-h3">{t('ImplementCPI-Discover-Title')}</h3>
                                        {tp('ImplementCPI-Discover-Content')}

                                        <h3 className="sub-headings-h3 mt-4">{t('ImplementCPI-Diagnose-Title')}</h3>
                                        {tp('ImplementCPI-Diagnose-Content')}
                                        <ArrowLink data={{ label: t('ChoosingYourCIs-Title'), href: "choosing-your-certified-instructors", openInNewTab: false }} />


                                        <h3 className="sub-headings-h3 mt-4">{t('ImplementCPI-Design-Title')}</h3>
                                        {tp('ImplementCPI-Design-Content')}

                                        <h3 className="sub-headings-h3 mt-4">{t('ImplementCPI-Deliver-Title')}</h3>
                                        {tp('ImplementCPI-Deliver-Content')}
                                        <ArrowLink data={{ label: t('CommunicatingInternally-Title'), href: "communicating-internally", openInNewTab: false }} />

                                    </>
                                </Tile>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <Tile title={t('ImplementCPI-PostImplementation')} hideTileHeader={false}>

                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <h3 className="sub-headings-h3">{t('ImplementCPI-Evaluate-Title')}</h3>
                                        {tp('ImplementCPI-Evaluate-Content')}

                                    </>
                                </Tile>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default ImplemeningCpiAtYourOrganization;
