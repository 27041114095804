import * as React from "react";

import { Alert, Button, Container, Form, Modal } from "react-bootstrap";
import { ApiResponse, JsonResponseModel } from "../ApiServices";

import { PASSWORD_REGEX_PATTERN } from "../constants";
import { RbacContext } from "../rbac-context";
import { changePassword } from "../ApiServices/Login";
import { isValidPassword } from "../helper-functions";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";
import ChangePasswordModal from "./Components/change-password-modal";
import MultiFactorAuthenticationModal from "./Components/multi-factor-authentication-modal";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../configuration-context";

const MyAccountPage = () => {
    const { t } = useTranslation();
    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const [show, setShow] = React.useState(false);
    const [showMfa, setShowMfa] = React.useState(false);
    const { toast } = useToast();
    const ping = configContext?.SystemConfiguration?.UsePing;

    return (
        <>
            <h2 className="mb-4">{t("My CPI Learning Account")}</h2>
            <p className="mb-2">{t("Name")}</p>
            <p className="font-larger">
                {userContext.FirstName} {userContext.LastName}
            </p>
            <p className="mb-2">{t("Email")}</p>
            <p className="font-larger">{userContext.Email}</p>
            <Alert variant="warning" className="d-inline-block">
                {t("Do you need to change your email or name?")}
                <br />
                <a href="#" className="text-link pendo__link-contact-us" onClick={(e) => e.preventDefault()}>
                    {t("Contact support.")}
                </a>
            </Alert>
            <div>
                <Button variant="primary" onClick={() => setShow(true)}>
                    {t("Change Password")}
                </Button>
            </div>
            {ping && (
                <div className="pt-3">
                    <Button variant="outline-primary" onClick={() => setShowMfa(true)}>
                        {t("Multifactor Authentication")}
                    </Button>
                </div>
            )}
            {show && ping && (
                <ChangePasswordModal
                    show={show}
                    handleCancel={() => setShow(false)}
                    onSend={(resp) => {
                        setShow(false);
                        toast(resp);
                    }}
                />
            )}
            {show && !ping && (
                <HBChangePasswordModal
                    show={show}
                    onHide={() => setShow(false)}
                    onSave={(resp) => {
                        setShow(false);
                        toast(resp);
                    }}
                />
            )}
            {showMfa && (
                <MultiFactorAuthenticationModal
                    show={showMfa}
                    handleCancel={() => setShowMfa(false)}
                    onSend={(resp) => {
                        setShowMfa(false);
                        toast(resp);
                    }}
                />
            )}
        </>
    );
};

const HBChangePasswordModal = ({
    show,
    onHide,
    onSave
}: {
    show: boolean;
    onHide: () => void;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
}) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [currentPassword, setCurrentPassword] = React.useState("");
    const [currentPasswordValidated, setCurrentPasswordValidated] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordValidated, setNewPasswordValidated] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordValidated, setConfirmPasswordValidated] = React.useState(false);
    const [changing, setChanging] = React.useState(false);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        if (form.checkValidity() && newPassword === confirmPassword) {
            try {
                setChanging(true);
                const resp = await changePassword(currentPassword, newPassword);

                if (resp.Error) {
                    setChanging(false);
                }
                onSave(resp);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }          
        } else {
            setCurrentPasswordValidated(true);
            setNewPasswordValidated(true);
            setConfirmPasswordValidated(true);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Form noValidate onSubmit={onSubmit}>
                <Modal.Header>
                    <Modal.Title>{t("Change Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form.Group controlId="currentPassword">
                            <Form.Label>{t("Current Password")}</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                autoComplete="off"
                                isInvalid={currentPasswordValidated && !isValidPassword(currentPassword)}
                                pattern={PASSWORD_REGEX_PATTERN}
                                value={currentPassword}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCurrentPassword(e.target.value)
                                }
                            />
                            {(!currentPasswordValidated || isValidPassword(currentPassword)) && (
                                <Form.Text>
                                    {t(
                                        "Password should be at least 8 characters and contain letters and at least one number."
                                    )}
                                </Form.Text>
                            )}
                            {!isValidPassword(currentPassword) && (
                                <Form.Control.Feedback type="invalid">
                                    {t(
                                        "Password should be at least 8 characters and contain letters and at least one number."
                                    )}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>{t("New Password")}</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                autoComplete="off"
                                isInvalid={newPasswordValidated && !isValidPassword(newPassword)}
                                pattern={PASSWORD_REGEX_PATTERN}
                                value={newPassword}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewPassword(e.target.value)}
                            />
                            {(!newPasswordValidated || isValidPassword(newPassword)) && (
                                <Form.Text>
                                    {t(
                                        "Password should be at least 8 characters and contain letters and at least one number."
                                    )}
                                </Form.Text>
                            )}
                            {!isValidPassword(newPassword) && (
                                <Form.Control.Feedback type="invalid">
                                    {t(
                                        "Password should be at least 8 characters and contain letters and at least one number."
                                    )}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>{t("Confirm Password")}</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                autoComplete="off"
                                isInvalid={confirmPasswordValidated && newPassword !== confirmPassword}
                                pattern={PASSWORD_REGEX_PATTERN}
                                value={confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setConfirmPassword(e.target.value)
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("Passwords do not match, please re-enter your new password.")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={changing}>
                        {changing ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Changing")}
                            </>
                        ) : (
                            t("Change Password")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, MyAccountPage);
