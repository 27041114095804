import "./org-banner.scss";

import * as React from "react";

import Cookies from "universal-cookie";
import { Dropdown } from "react-bootstrap";
import { RbacContext } from "../rbac-context";

const OrgBanner = ({ showDropdown }: { showDropdown: boolean }) => {
    const { userContext, setContext } = React.useContext(RbacContext);

    function changeOrganization(orgId: number, orgName: string) {
        console.log(orgName);
        const cookies = new Cookies();
        cookies.set("CurrentOrgContext", orgId);
        cookies.set("CurrentOrgNameContext", orgName);
        setContext(
            { ...userContext, OrganizationId: orgId, OrganizationName: orgName },
            true
        );
    }

    if (userContext.Organizations?.length > 1) {
        const org = userContext.Organizations.find((org) => org.Id === userContext.OrganizationId);

        if (showDropdown) {
            return (
                <Dropdown className="w-100">
                    <Dropdown.Toggle id="dropdownMenuButton" variant="primary" className="org-dropdown">
                        {org.Name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {userContext.Organizations.map((org) => (
                            <Dropdown.Item
                                key={org.Id}
                                onClick={() => changeOrganization(org.Id, org.Name)}
                            >
                                {org.Name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }

        return <p className="mt-3 mb-0">{org.Name}</p>;
    }

    return null;
};

export default OrgBanner;
