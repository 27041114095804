import bu from "browser-update";
import { isNullOrUndefined } from "./helper-functions";

const browserUpdate = () => {
    const localStorage = window.localStorage;
    function onClose() {
        localStorage.setItem("dismissUpdate", new Date().toString());
    }

    const buOptions = {
        required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
        api: 2020.06,
        reminder: 24,
        reminderClosed: 24,
        onclose: onClose,
        test: false,
        no_permanent_hide: true,
        text_for_i: {
            msg: "Internet Explorer is no longer supported.",
            msgmore: "Please use Microsoft Edge or Google Chrome to use this site."
        }
    };

    function shouldShow(date: Date) {
        var today = new Date();
        var hours = Math.abs(today.getTime() - date.getTime()) / 36e5;
        return hours >= 24;
    }

    const dismissUpdate = localStorage.getItem("dismissUpdate");
    if (isNullOrUndefined(dismissUpdate) || shouldShow(new Date(dismissUpdate))) {
        bu(buOptions);
    }
};

export default browserUpdate;
