import "./many-sco-course.scss";

import * as React from "react";

import { Alert, Breadcrumb, Card, Col, ProgressBar, Row } from "react-bootstrap";
import { LearnerCourseDetails, LearnerLessonDetails, LearnerModuleDetails } from "../../Interfaces";

import CourseBreakdown from "./course-breakdown";
import { LearnerBaseRoutes } from "../../Routing/routes";
import { Link } from "react-router-dom";
import ManyScoPlayModal from "./many-sco-play-modal";
import { StatusEnum } from "../../Enums";
import { fetchManyScoResults } from "../../ApiServices/Learn";
import { formatLongDateWithDay } from "../../locale-utils";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const ManyScoCourse = ({ course, refresh }: { course: LearnerCourseDetails; refresh: () => Promise<void> }) => {
    const { t } = useTranslation();

    const [show, setShow] = React.useState(false);
    const [learnerModule, setLearnerModule] = React.useState<LearnerModuleDetails>(null);
    const [learnerLesson, setLearnerLesson] = React.useState<LearnerLessonDetails>(null);

    const modules = React.useMemo(() => {
        const sortedModules = course.LearnerModules.sort((a, b) => a.Order - b.Order);
        sortedModules.forEach((lm) => (lm.LearnerLessons = lm.LearnerLessons.sort((a, b) => a.Order - b.Order)));
        return sortedModules;
    }, [course.LearnerModules]);

    const { currentModule, currentLesson } = React.useMemo(() => {
        const module = modules.find((lm) => lm.Status === StatusEnum.InProgress);
        const lesson = module?.LearnerLessons.find((ll) => ll.Status === StatusEnum.InProgress);

        return { currentModule: module, currentLesson: lesson };
    }, [course]);

    const onPlay = (learnerModule: LearnerModuleDetails, learnerLesson: LearnerLessonDetails) => {
        setShow(true);
        setLearnerModule(learnerModule);
        setLearnerLesson(learnerLesson);
    };

    const playCurrentLesson = () => {
        if (currentLesson) {
            setLearnerModule(currentModule);
            setLearnerLesson(currentLesson);
        } else {
            setLearnerModule(modules[0]);
            setLearnerLesson(modules[0].LearnerLessons[0]);
        }
        setShow(true);
    };

    return (
        <>
            <Breadcrumb listProps={{ className: "bg-none p-0" }}>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: LearnerBaseRoutes.Learn.fullPath }}>
                    {t("My Courses")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{course.Name}</Breadcrumb.Item>
            </Breadcrumb>
            <h1>{course.Name}</h1>
            <h3>{course.Title}</h3>
            <Row className="ms-0">
                <Col md={4} className="d-none d-md-block h-100 mb-4 ps-0">
                    <CourseBreakdown modules={modules} onPlay={onPlay} />
                </Col>
                <Col md={8} className="h-100 ps-0">
                    <div className="play-course ps-0 mx-0">
                        {currentLesson && !!currentLesson.Score && currentLesson.Score < currentLesson.MinimumScore && (
                            <Alert variant="warning">
                                {t(
                                    "Your score was {{score}}% on your last attempt.  You need {{minimumScore}} to pass the quiz.",
                                    { score: currentLesson.Score, minimumScore: currentLesson.MinimumScore }
                                )}{" "}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        playCurrentLesson();
                                    }}
                                >
                                    {t("Click here to retake the quiz.")}
                                </a>
                            </Alert>
                        )}
                        <a
                            className="text-decoration-none"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                playCurrentLesson();
                            }}
                        >
                            <div
                                className="card-img d-flex flex-row"
                                style={{ backgroundImage: `url("/Thumbnails/${course.ThumbnailFilename}")` }}
                            >
                                <span className="fa-stack fa-5x align-self-center justify-content-center d-flex flex-column w-100">
                                    <i className="fa fa-circle fa-stack-1x text-white pos-t-0"></i>
                                    <i className="fa fa-play-circle fa-stack-1x text-secondary pos-t-0"></i>
                                </span>
                            </div>
                            <p className="text-center">
                                {course.Status === StatusEnum.NotStarted && t("Click to start course")}
                                {course.Status === StatusEnum.InProgress && t("Click to resume course")}
                                {course.Status === StatusEnum.Completed && t("Click to restart course")}
                            </p>
                        </a>
                        <ProgressBar
                            className="course-progress-bar mb-4"
                            now={course.Progress.CurrentLesson}
                            min={0}
                            max={course.Progress.TotalLessons}
                            label={`${course.Progress.CurrentLesson} / ${course.Progress.TotalLessons}`}
                            bsPrefix="text-dark progress"
                            // @ts-ignore
                            variant="course"
                        />
                    </div>
                    <div className="d-block d-md-none mt-4">
                        <CourseBreakdown modules={modules} onPlay={onPlay} />
                    </div>
                    <div className="mt-4">
                        <Card className="w-100">
                            <Card.Header as="h4">{t("Course Information")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6} xl={4}>
                                        <h5>{t("Classroom Location")}</h5>
                                        <p className="ms-2">
                                            {course.ClassLocation ? (
                                                <>
                                                    {course.ClassLocation}
                                                    <br />
                                                </>
                                            ) : (
                                                <em className="text-muted">{t("Not yet available")}</em>
                                            )}
                                        </p>
                                    </Col>
                                    {!course.IsManaged && course.ClassDate && (
                                        <Col md={6} xl={4}>
                                            <h5>{t("Date of In-Person Training")}</h5>
                                            <p className="ms-2">{formatLongDateWithDay(course.ClassDate)}</p>
                                        </Col>
                                    )}
                                    <Col md={6} xl={4}>
                                        <h5>{t("Instructor")}</h5>
                                        <p className="ms-2">
                                            {course.InstructorFirstName} {course.InstructorLastName} <br />{" "}
                                            <a href={`mailto:${course.InstructorEmailAddress}`} className="text-link">
                                                <i className="fas fa-envelope me-1"></i>
                                                {t("Send Email")}
                                            </a>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5>{t("Online Course Description")}</h5>
                                        <p className="ms-2">{course.Description}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
            {show && (
                <ManyScoPlayModal
                    show={show}
                    learnerLessonId={learnerLesson.LearnerLessonId}
                    isLastLesson={
                        !learnerModule.LearnerLessons.some((ll) => ll.Order === learnerLesson.Order + 1) &&
                        !course.LearnerModules.some((lm) => lm.Order === learnerModule.Order + 1)
                    }
                    onHide={async () => {
                        setShow(false);
                        await fetchManyScoResults(learnerLesson.LearnerLessonId);
                        await refresh();
                    }}
                    onNext={async () => {
                        const nextLesson = learnerModule.LearnerLessons.find(
                            (ll) => ll.Order === learnerLesson.Order + 1
                        );

                        if (nextLesson) {
                            setLearnerLesson(nextLesson);
                        } else {
                            const nextModule = course.LearnerModules.find((lm) => lm.Order === learnerModule.Order + 1);
                            if (nextModule) {
                                setLearnerModule(nextModule);
                                setLearnerLesson(nextModule.LearnerLessons[0]);
                            }
                        }

                        await refresh();
                    }}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, ManyScoCourse);
