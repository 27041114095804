import * as React from "react";

import { AdminRoles, InstructorRoles, LearnerEnrolleeRoles, StaffSupportRoles } from "../../Enums/RoleEnum";
import { CpiRoute, UrlGroups } from "../../Routing/routes";
import { useEffect, useRef } from "react";

import { ConfigContext } from "../../configuration-context";
import FeaturedLink from "./featured-link";
import IconLink from "./icon-link";
import { RbacContext } from "../../rbac-context";
import useRouteAccessCheck from "../../hooks/useRouteAccessCheck";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { useTranslation } from "react-i18next";

const SideNav = (props: {
    sideNavIsOpen: boolean,
    closeSideNav: Function,
    sideNavOverflowYCSS: React.CSSProperties,
    setMainnavHeight: Function
}) => {
    const { t } = useTranslation();
    const { rac, isCPIStaff } = useRouteAccessCheck();

    // Nav related feature flags
    const [canAccessClasses] = useFeatureFlags("ITLAccessListOfClasses", true);
    const [canAccessParticipants] = useFeatureFlags("ITLAccessListOfParticipants", true);
    const [canAccessMyOnlineLearning] = useFeatureFlags("ITLMyOnlineLearning", true);
    const [canViewProfile] = useFeatureFlags("ITLViewProfile", true);
    const [canPreviewCourses] = useFeatureFlags("ITLPreviewCourses", true);

    const { userContext } = React.useContext(RbacContext);
    const maint = process.env.ENVIRONMENT == "maint";

    const ref = useRef(null);

    useEffect(() => {
        props.setMainnavHeight(ref.current.clientHeight);
    }, [ref])

    const createUrlComponents = (
        title: string,
        group: { id: string; title: () => string; href: string; icon: string; paths: string[]; route: CpiRoute }[]
    ) => {
        return (
            <>
                <div className="main-nav-section" id="sectionTrainingMyOrganization">
                <div className="main-nav-title">{title}</div>
                
                {group.map((url, i) => {
                    if (url.id === 'id-classes') {
                        return canAccessClasses && (
                            (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />
                        );
                    } else if (url.id === 'id-participants') {
                        return canAccessParticipants && (
                            (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />
                        );
                    } else if (url.id === 'id-my-online-courses') {
                        return canAccessMyOnlineLearning && (
                            (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />
                        );
                    } else if (url.id === 'id-support-profile') {
                        return canViewProfile && (
                            (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />
                        );
                    } else if (url.id === 'id-preview-courses') {
                        return canPreviewCourses && (
                            (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />
                        );
                    }
                    else {
                        return (url.route && rac(url.route) || (!url.route)) && <IconLink key={i} url={url} action={props.closeSideNav} />;
                    }
                })}
                </div>
            </>
        );
    };

    if (maint) {
        return (
                <span></span>
            );
    }
    else {
        if (props.sideNavIsOpen) {
            return (
                <>
                    <div className="main-nav-screen" id="main-nav-overlay">
                        
                        <div className="main-nav-container" style={props.sideNavOverflowYCSS}>
                            <div ref={ref} className="main-nav-wrapper">
                
                                <FeaturedLink action={props.closeSideNav} />

                                {userContext.RolesByName && AdminRoles.some((role) => userContext.RolesByName.includes(role)) &&
                                    createUrlComponents(t("Admin"), UrlGroups.Admin)}

                                {userContext.RolesByName && StaffSupportRoles.some((role) => userContext.RolesByName.includes(role)) &&
                                    createUrlComponents(t("Staff Support"), UrlGroups.StaffSupport)}

                                {((userContext.RolesByName && InstructorRoles.some((role) => userContext.RolesByName.includes(role)) && userContext.AgreementCriteriaMet) || isCPIStaff()) &&
                                    createUrlComponents(t("Dashboard.TrainingMyOrganization"), UrlGroups.Platform, true)}

                                {((userContext.RolesByName && LearnerEnrolleeRoles.some((role) => userContext.RolesByName.includes(role)) && userContext.AgreementCriteriaMet) || isCPIStaff()) &&
                                    createUrlComponents(t("Dashboard.MyTraining"), UrlGroups.MyTraining, true)}

                                { /* general auth needed only */ }
                                {createUrlComponents(t("Support"), UrlGroups.Support, true)}    
    
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
};

export default SideNav;
