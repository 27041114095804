import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IClassDetails, IClassUpdateDetails } from "../Interfaces/Platform/Classes";
import { CreateClassPayload, ClassResponse } from "../Pages/Utils/classCreate";

interface InitialState extends IClassDetails {
    participantsEnrolled: number;
    participantsSubmitted: number;
    totalParticipants: number;
    classSkills?: any;
    seatSku?: any;
    courseObjectId?: string;
    courseCulture?: string;
    courseLanguage?: string;
    templateId?: string;
    estimatedTime?: string;
    courseName?: string;
    contactHours?: number;
    scormId?: string;
    modulesUrl?: string;
    webId: string;
    thumbnailUrl?: string;
}

const initialState: InitialState = {
    blockEdit: false,
    classId: "",
    className: "",
    specialty: "",
    specialtyEdition: "",
    qualification: "",
    trainingType: "",
    trainingFormat: "",
    classroomFormat: "",
    desiredLms: "",
    courseId: "",
    courseLanguage: "",
    language: "",
    industry: "",
    organizationId: "",
    blueCardRequired: false,
    emailSend: false,
    emailScheduledDate: "",
    emailMessage: "",
    classSkills: "",
    classInstructors: [],
    classDates: [],
    endDate: "",
    startDate: "",
    expirationDate: "",
    expirationOffset: 0,
    seatOrganizationId: "",
    participantsEnrolled: 0,
    participantsSubmitted: 0,
    totalParticipants: 0,
    courseObjectId: "",
    courseName: "",
    courseCulture: "",
    templateId: "",
    estimatedTime: "",
    contactHours: 0,
    scormId: "",
    modulesUrl: "",
    imported: false,
    webId: "",
    thumbnailUrl: ""
}

export const classDetailsSlice = createSlice({
    name: 'classDetails',
    initialState,
    reducers: {
        // LOADNEWCLASS: handle a refresh that unloads the current state and passes the state for the new instance
        loadNewClassFromCreatePayload: (state, action: PayloadAction<ClassResponse>) => {
            state.classId = action.payload.classId;
            state.className = action.payload.className;
            state.specialty = action.payload.specialty;
            state.specialtyEdition = action.payload.specialtyEdition;
            state.qualification = action.payload.qualification;
            state.trainingType = action.payload.trainingType;
            state.trainingFormat = action.payload.trainingFormat;
            state.classroomFormat = action.payload.classroomFormat;
            state.desiredLms = action.payload.desiredLms;
            state.courseId = action.payload.courseId;
            state.courseLanguage = action.payload.courseLanguage;
            state.language = action.payload.courseLanguage;
            state.industry = action.payload.industry;
            state.organizationId = action.payload.organizationId;
            state.blueCardRequired = action.payload.blueCardRequired;
            state.emailSend = action.payload.emailSend;
            state.emailScheduledDate = action.payload.emailScheduledDate;
            state.emailMessage = action.payload.emailMessage;
            state.classInstructors = action.payload.classInstructors;
            state.classDates = action.payload.classDates;
            state.classSkills = action.payload.classSkills;
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
            state.expirationDate = action.payload.expirationDate;
            state.seatOrganizationId = (action.payload.seatOrganizationId) ? action.payload.seatOrganizationId : null;
            state.expirationOffset = action.payload.expirationOffset;
            state.courseObjectId = action.payload.courseObjectId;
            state.courseId = action.payload.courseId;
            state.courseName = action.payload.courseName;
            state.contactHours = action.payload.contactHours;
            state.templateId = action.payload.templateId;
            state.estimatedTime = action.payload.estimatedTime;
            state.scormId = action.payload.scormId;
            state.modulesUrl = action.payload.modulesUrl;
            state.courseCulture = action.payload.courseCulture;
            state.blockEdit = false;
            state.participantsSubmitted = 0;
            state.totalParticipants = 0;
            state.webId = action.payload.webId;
            state.seatsRequired = action.payload.seatsRequired;
            state.thumbnailUrl = action.payload.thumbnailUrl;
        },
        loadClassFromDetailsPayload: (state, action: PayloadAction<IClassDetails>) => {
            // SAVING CLASS DETAILS FROM RESPONSE TO STORE
            state.classId = action.payload.classId;
            state.className = action.payload.className;
            state.specialty = action.payload.specialty;
            state.specialtyEdition = action.payload.specialtyEdition;
            state.qualification = action.payload.qualification;
            state.trainingType = action.payload.trainingType;
            state.trainingFormat = action.payload.trainingFormat;
            state.classroomFormat = action.payload.classroomFormat;
            state.desiredLms = action.payload.desiredLms;
            state.courseId = action.payload.courseId;
            state.courseLanguage = action.payload.language;
            state.language = action.payload.language;
            state.industry = action.payload.industry;
            state.organizationId = action.payload.organizationId;
            state.blueCardRequired = action.payload.blueCardRequired;
            state.emailSend = action.payload.emailSend;
            state.emailScheduledDate = action.payload.emailScheduledDate;
            state.emailMessage = action.payload.emailMessage;
            state.classInstructors = action.payload.classInstructors;
            state.classDates = action.payload.classDates;
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
            state.expirationDate = action.payload.expirationDate;
            state.seatOrganizationId = (action.payload.seatOrganizationId) ? action.payload.seatOrganizationId : null;
            state.expirationOffset = action.payload.expirationOffset;
            state.blockEdit = action.payload.blockEdit;
            state.classSkills = action.payload.classSkills;
            state.participantsSubmitted = action.payload.participantsSubmitted;
            state.totalParticipants = action.payload.totalParticipants;
            state.imported = action.payload.imported;
            state.webId = action.payload.webId;
            state.seatsRequired = action.payload.seatsRequired;
            state.thumbnailUrl = action.payload.thumbnailUrl;
        },
        loadUpdatedClassFromPayload: (state, action: PayloadAction<ClassResponse>) => {
            // SAVING CLASS DETAILS FROM RESPONSE TO STORE
            state.classId = action.payload.classId;
            state.className = action.payload.className;
            state.classSkills = action.payload.classSkills;
            state.specialty = action.payload.specialty;
            state.specialtyEdition = action.payload.specialtyEdition;
            state.qualification = action.payload.qualification;
            state.trainingType = action.payload.trainingType;
            state.trainingFormat = action.payload.trainingFormat;
            state.classroomFormat = action.payload.classroomFormat;
            state.desiredLms = action.payload.desiredLms;
            state.courseId = action.payload.courseId;
            state.courseLanguage = action.payload.courseLanguage;
            state.language = action.payload.courseLanguage;
            state.industry = action.payload.industry;
            state.organizationId = action.payload.organizationId;
            state.blueCardRequired = action.payload.blueCardRequired;
            state.emailSend = action.payload.emailSend;
            state.emailScheduledDate = action.payload.emailScheduledDate;
            state.emailMessage = action.payload.emailMessage;
            state.classInstructors = action.payload.classInstructors;
            state.classDates = action.payload.classDates;
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
            state.expirationDate = action.payload.expirationDate;
            state.seatOrganizationId = (action.payload.seatOrganizationId) ? action.payload.seatOrganizationId : null;
            state.expirationOffset = action.payload.expirationOffset;
            state.courseObjectId = action.payload.courseObjectId;
            state.courseId = action.payload.courseId;
            state.courseName = action.payload.courseName;
            state.contactHours = action.payload.contactHours;
            state.templateId = action.payload.templateId;
            state.estimatedTime = action.payload.estimatedTime;
            state.scormId = action.payload.scormId;
            state.modulesUrl = action.payload.modulesUrl;
            state.courseCulture = action.payload.courseCulture;
            state.thumbnailUrl = action.payload.thumbnailUrl;
        },
        loadEnrolledParticipants: (state, action: PayloadAction<number>) => {
            state.participantsEnrolled = action.payload;
            state.blockEdit = action.payload > 0;
        },
        enrollParticipantToClass: (state) => {
            state.participantsEnrolled += 1;
            state.totalParticipants += 1;
            // ensure blockedit is true of at least 1 participant is enrolled
            state.blockEdit = true;
        },
        removeParticipantFromClass: (state) => {
            state.participantsEnrolled -= 1;
            state.totalParticipants -= 1;
            if (state.trainingFormat !== 'classroom') {
                // If Training Format !== classroom and > 1 participants enrolled, BlockEdit true; else false;
                state.blockEdit = state.participantsEnrolled > 0;
            }
            if (state.trainingFormat === 'classroom') {
                // If at least 1 participant submitted BlockEdit = true; else false;
                if (state.participantsEnrolled === 0) {
                    state.blockEdit = false;
                } else {
                    state.blockEdit = true;
                }
            }
        },

        loadClassDetailsInstructors: (state, action: PayloadAction<any[]>) => {
            state.classInstructors = action.payload;
        },

        loadClassParticipantsPayload: (state, action: PayloadAction<any>) => {
            state.totalParticipants = action.payload.total;
        },

        submitParticipantToClass: (state, action: PayloadAction<number>) => {
            state.participantsSubmitted = action.payload;
            state.blockEdit = true;
        },
        submitAllParticipantsToClass: (state) => {
            state.participantsSubmitted = state.participantsEnrolled;
            state.blockEdit = true;
        },

        /* EDIT REDUCERS: we can use a collection of reducers here that handle the editing of various fields on a class.
        
        With edit-class in particular, there are cases where editing certain fields will need to clear field values further down the form.
        In a particular field's reducer function here - when updating it's value - should also clear the values of affected attributes.
        
        */
    }
});

export const {
    loadNewClassFromCreatePayload,
    loadClassFromDetailsPayload,
    loadUpdatedClassFromPayload,
    loadEnrolledParticipants,
    enrollParticipantToClass,
    removeParticipantFromClass,
    loadClassDetailsInstructors,
    submitParticipantToClass,
    submitAllParticipantsToClass,
} = classDetailsSlice.actions;
export default classDetailsSlice.reducer;