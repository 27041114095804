import { CustomResponse } from "./bulk-upload-response";

export const bulkUploadParser = async (response: Response) => {
    const customResponse: CustomResponse = {
        message: response.statusText,
        isError: !response.ok,
        statusCode: response.status
    };

    if (response.ok) {
        customResponse.response = await response.json();
    } else {
        switch (response.status) {
            case 500: // rows with errors
                customResponse.message = "There was a problem uploading participants. Please try again.";
                customResponse.response = await response.json();
                break;
        }
    }

    return customResponse;
}
