import { ComponentElement, JSXElementConstructor } from "react";

import AccessCert from "../Interfaces/Platform/accessCert";
import { injectCultureToRoute } from "../Pages/Utils/routing";
import { getUserClaims } from "../helper-functions";
import { RoleEnum, AdminRoles, BusinessAdministratorRoles, CertifiedInstructorRoles, ErrorRoles, InstructorRoles, LearnerEnrolleeRoles, ParticipantManagementRoles, PlatformRoles, StaffSeatManagementRoles, TrainingCenterRoles, TrainingRoles } from "../Enums"
import i18next from "i18next";
import { StaffSupportRoles } from "../Enums/RoleEnum";

export interface CpiRoute{
    pageId?: string,
    path: string,
    fullPath: string,
    accessCultures?: string[], // en-GB, en-US etc.
    accessCertifications?: AccessCert[], // nci, vi etc.
    accessQualifications?: string[], // tra etc.
    accessRoles?: string[], // ci, ba, learner, enrollee, cpistaff
    accessWithCertAgreementOnly?: boolean,
    accessWithAnyActiveCertOnly?: boolean,
    accessWithStaffPopulation?: boolean,
    title?: () => string,
    parent?: CpiRoute,
    children?: CpiRoute[],
    isChild?: boolean,
    isOutsideAuth?: boolean,
    requiresCompleteUserData?: boolean,
    tempBaseURLConfigKey?: string,
    tempPathURL?: string
}



const EventRegistration: CpiRoute = { path: "https://www.crisisprevention.com/event-registration-auth", title: () => i18next.t("Nav.EventRegistration"), fullPath: "https://www.crisisprevention.com/event-registration-auth" }

export const ExternalBaseRoutes = {
    EventRegistration
}

const adminRouteBase = "/Admin";

const Admin: CpiRoute = { pageId:"admin", path: "", title: () => i18next.t("Admin"), accessRoles: StaffSupportRoles, fullPath: adminRouteBase };

const CourseLanguages: CpiRoute = { pageId:"courseLanguages", path: "/CourseLanguages", title: () => i18next.t("Course Languages"), accessRoles: AdminRoles, parent: Admin, fullPath: `${Admin.fullPath}/CourseLanguages` };

const ManageParticipants: CpiRoute = { pageId:"manageParticipants", path: "/ManageParticipants", title: () => i18next.t("Manage Participants"), accessRoles: ParticipantManagementRoles, parent: Admin, fullPath: `${Admin.fullPath}/ManageParticipants` };
const ManageParticipantsParticipant: CpiRoute = { pageId: "manageParticipantsParticipant", path: "/:userId", title: () => i18next.t("Manage Participants"), accessRoles: ParticipantManagementRoles, parent: Admin, fullPath: `${ManageParticipants.fullPath}/:userId`, isChild: true };
ManageParticipants.children = [ManageParticipantsParticipant];

const ManageSeats: CpiRoute = { pageId:"manageSeats", path: "/ManageSeats", title: () => i18next.t("Manage Seats"), accessRoles: StaffSeatManagementRoles, fullPath: `${Admin.fullPath}/ManageSeats` };
const ManageSeatsOrg: CpiRoute = { pageId: "ManageSeatsOrg", path: "/:orgId", title: () => i18next.t("Manage Seats"), accessRoles: StaffSeatManagementRoles, fullPath: `${ManageSeats.fullPath}/:orgId`, isChild: true };
const ManageSeatsUsage: CpiRoute = { pageId: "ManageSeatsUsage", path: "/:orgId/:courseId", title: () => i18next.t("Manage Seats"), accessRoles: StaffSeatManagementRoles, fullPath: `${ManageSeats.fullPath}/:orgId/:courseId`, isChild: true };
ManageSeats.children = [ManageSeatsOrg, ManageSeatsUsage];

const ManageSkus: CpiRoute = { pageId:"manageSkus", path: "/ManageSkus", title: () => i18next.t("Manage SKUs"), accessRoles: AdminRoles, parent: Admin, fullPath: `${Admin.fullPath}/ManageSkus` };

const OrganizationSettings: CpiRoute = { pageId:"organizationSettings", path: "/OrganizationSettings", title: () => i18next.t("Organization Settings"), accessRoles: AdminRoles, parent: Admin, fullPath: `${Admin.fullPath}/OrganizationSettings` };

const ManageCourses: CpiRoute = { pageId: "manageCourses", path: "/ManageCourses", title: () => i18next.t("Manage Courses"), accessRoles: AdminRoles, fullPath: `${Admin.fullPath}/ManageCourses` };
const ManageCoursesCreate: CpiRoute = { pageId: "manageCoursesCreate", path: "/Create", fullPath: `${ManageCourses.fullPath}/Create`, isChild: true, title: () => i18next.t("New Course"), accessRoles: AdminRoles };
const ManageCoursesEdit: CpiRoute = { pageId: "manageCoursesEdit", path: "/Edit/:courseId", fullPath: `${ManageCourses.fullPath}/Edit/:courseId`, isChild: true, title: () => i18next.t("Edit Course"), accessRoles: AdminRoles};
ManageCourses.children = [ManageCoursesCreate, ManageCoursesEdit];

export const AdminBaseRoutes = {
    Admin,
    CourseLanguages,
    ManageParticipants,
    ManageParticipantsParticipant,
    ManageSeats,
    ManageSeatsOrg,
    ManageSeatsUsage,
    ManageSkus,
    OrganizationSettings,
    ManageCourses,
    ManageCoursesCreate,
    ManageCoursesEdit
};

const classBase = "/Class",
    learnerBase = "/Learner",
    previewBase = "/Preview",
    dashboardBase = "/Dashboard",
    trainingCenterBase = "/Training-Center",
    devBase = "/Development",
    logoutBase = "/Logout";

const Development: CpiRoute = { pageId:"developmentPage", path: "", fullPath: devBase };
const Logout: CpiRoute = { pageId: "logout", title: () => i18next.t("Logout"), path: logoutBase, fullPath: logoutBase };
 
export const DevBaseRoutes = {
    Development
}

const Dashboard: CpiRoute = {
    pageId: "dashboard", path: "", title: () => i18next.t("Dashboard"), accessWithCertAgreementOnly: true, requiresCompleteUserData: true, fullPath: dashboardBase, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard" };

const PlatformBaseRoute: CpiRoute = { pageId:"platformBaseRoute", path: "/Training-My-Organization", title: () => i18next.t("Dashboard"), parent: Dashboard, requiresCompleteUserData: true, accessWithStaffPopulation: true, accessWithCertAgreementOnly: true, fullPath: `${Dashboard.fullPath}/Training-My-Organization` }
const DashboardClasses: CpiRoute = { pageId:"dashboardClasses", path: "/Classes", title: () => i18next.t("Nav.Classes"), parent: Dashboard, requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, fullPath: `${PlatformBaseRoute.fullPath}/Classes` }
const DashboardClass: CpiRoute = { pageId: "dashboardClass", path: "/Classes/:className", title: () => i18next.t("Nav.ViewClass"), requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: DashboardClasses, fullPath: `${PlatformBaseRoute.fullPath}/Classes/:className` }
const DashboardClassCreate: CpiRoute = { pageId: "dashboardClassCreate", path: "/Classes/Create", title: () => i18next.t("Create Class"), requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: Dashboard, fullPath: `${PlatformBaseRoute.fullPath}/Classes/Create` }
const DashboardParticipants: CpiRoute = { pageId: "dashboardParticipants", path: "/Participants", title: () => i18next.t("ClassParticipants.Participants"), requiresCompleteUserData: true, parent: Dashboard, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, fullPath: `${PlatformBaseRoute.fullPath}/Participants` }
const DashboardParticipantsImport: CpiRoute = { pageId: "dashboardParticipantsImport", path: "/Participants/Import", title: () => i18next.t("ClassParticipants.ImportParticipants"), requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: DashboardParticipants, fullPath: `${PlatformBaseRoute.fullPath}/Participants/Import` }
const DashboardInstructorManagement: CpiRoute = { pageId:"dashboardInstructorManagement", path: "/InstructorManagement", accessWithCertAgreementOnly: true, requiresCompleteUserData: true, accessWithStaffPopulation: true, accessRoles: InstructorRoles, title: () => i18next.t("CPI.MyAccount.Dashboard.TrainingCenter.InstructorManagement"), tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Training-Center/Instructor-Management", parent: Dashboard, fullPath: `${PlatformBaseRoute.fullPath}/InstructorManagement` }
const DashboardInstructorProfile: CpiRoute = { pageId:"dashboardInstructorProfile", path: "/InstructorManagement/InstructorProfile/:instructorId", accessWithCertAgreementOnly: true, requiresCompleteUserData: true, accessWithStaffPopulation: true, accessRoles: InstructorRoles, title: () => i18next.t("Nav.InstructorProfile"), tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Training-Center/Instructor-Management", parent: DashboardInstructorManagement, fullPath: `${PlatformBaseRoute.fullPath}/InstructorManagement/InstructorProfile/:instructorId` }
const DashboardProgramMaterials: CpiRoute = { pageId: "dashboardProgramMaterials", path: "/ProgramMaterials", title: () => i18next.t("Nav.ProgramMaterials"), requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: Dashboard, fullPath: `${PlatformBaseRoute.fullPath}/ProgramMaterials` }
const DashboardTrainingMaterials: CpiRoute = { pageId: "dashboardTrainingMaterials", path: "/TrainingMaterials", title: () => i18next.t("Dashboard.TrainingMaterials"), requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: Dashboard, fullPath: `${PlatformBaseRoute.fullPath}/TrainingMaterials` }
const DashboardViewSeats: CpiRoute = { pageId: "dashboardViewSeats", path: "/ViewSeats", title: () => i18next.t("Dashboard.ViewSeats"), accessWithCertAgreementOnly: true, requiresCompleteUserData: true, accessWithStaffPopulation: true, accessRoles: TrainingCenterRoles, parent: Dashboard, fullPath: `${PlatformBaseRoute.fullPath}/ViewSeats` }

const Preview: CpiRoute = { pageId: "preview", path: "/Preview", title: () => i18next.t("Preview"), parent: DashboardTrainingMaterials, accessWithAnyActiveCertOnly: true, accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles, fullPath: `${PlatformBaseRoute.fullPath}/Preview` };
const PreviewCourse: CpiRoute = { pageId: "coursePreview", path: "/Preview/:courseObjectId", title: () => i18next.t("Preview Course"), parent: Preview, accessWithAnyActiveCertOnly: true, accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles, fullPath: `${Preview.fullPath}/:courseObjectId` };

export const PlatformBaseRoutes = {
    PlatformBaseRoute,
    DashboardClasses,
    DashboardClass,
    DashboardClassCreate,
    DashboardParticipants,
    DashboardParticipantsImport,
    DashboardInstructorManagement,
    DashboardInstructorProfile,
    DashboardProgramMaterials,
    DashboardTrainingMaterials,
    DashboardViewSeats,
    Preview,
    PreviewCourse
}

export const DashboardBaseRoutes = {
    Dashboard
}

const TrainingCenter: CpiRoute = { pageId: "trainingCenter", path: "", title: () => i18next.t("CPI.MyAccount.TrainingCenter"),  requiresCompleteUserData: true, accessWithCertAgreementOnly: true, fullPath: trainingCenterBase };
const TrainingCenterEvalAndPostTraining: CpiRoute = { pageId: "evalAndPostTrainingResponsibilities", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Evaluations-and-Post-Training-Responsibilities", accessCultures: ['en-US'], parent: TrainingCenter, title: () => i18next.t("Eval-PostTraining-Title"), fullPath: `${TrainingCenter.fullPath}/Evaluations-and-Post-Training-Responsibilities` };
const TrainingCenterCustomizationNewTermsAndTransitions: CpiRoute = { pageId: "customizationNewTermsAndTransitions", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Customization-New-Terms-and-Transitions", parent: TrainingCenter, title: () => i18next.t("NewTerms-Title"), fullPath: `${TrainingCenter.fullPath}/Customization-New-Terms-and-Transitions` };
const TrainingCenterQualityStandards: CpiRoute = { pageId: "qualityStandards", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/CPI-Quality-Standards", parent: TrainingCenter, title: () => i18next.t("QualityStandard-Title"), fullPath: `${TrainingCenter.fullPath}/CPI-Quality-Standards` };
const TrainingCenterDccQualityStandards: CpiRoute = { pageId: "dccQualityStandards", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/DCC-Quality-Standards", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("QualityStandard-DCC-Title"), fullPath: `${TrainingCenter.fullPath}/DCC-Quality-Standards` };
const TrainingCenterBaseOfEmployment: CpiRoute = { pageId: "baseOfEmployment", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Base-of-Employment-BOE", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("BaseOfEmployment-Title"), fullPath: `${TrainingCenter.fullPath}/Base-of-Employment-BOE` };
const TrainingCenterInstructionsForCeBoardApproval: CpiRoute = { pageId: "instructionsForCeBoardApproval", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Instructions-for-CE-Board-Approval", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("CEBoardApproval-Title"), fullPath: `${TrainingCenter.fullPath}/Instructions-for-CE-Board-Approval` };
const TrainingCenterChoosingYourCertifiedInstructors: CpiRoute = { pageId: "choosingYourCertifiedInstructors", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Choosing-Your-Certified-Instructors", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("ChoosingYourCIs-Title"), fullPath: `${TrainingCenter.fullPath}/Choosing-Your-Certified-Instructors` };
const TrainingCenterCommunicatingInternally: CpiRoute = { pageId: "communicatingInternally", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Communicating-Internally", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-GB', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("CommunicatingInternally-Title"), fullPath: `${TrainingCenter.fullPath}/Communicating-Internally` };
const TrainingCenterAfterVirtualTraining: CpiRoute = { pageId: "afterVirtualTraining", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/After-Virtual-Training", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("AfterVirtualTraining-Title"), fullPath: `${TrainingCenter.fullPath}/After-Virtual-Training` };
const TrainingCenterDownloadResources: CpiRoute = { pageId: "downloadResources", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Download-Resources", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("WriteTrainingActivities-DownloadResources-Title"), fullPath: `${TrainingCenter.fullPath}/Download-Resources` };
const TrainingCenterCpiLogoBrandMaterials: CpiRoute = { pageId: "cpiLogoBrandMaterials", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/CPI-Logo-and-Brand-Materials", accessCultures: ['en-US', 'en-AU', 'en-IE', 'en-NZ', 'en-GB'], parent: TrainingCenter, title: () => i18next.t("CPIBrandMaterials-Title"), fullPath: `${TrainingCenter.fullPath}/CPI-Logo-and-Brand-Materials` };

const TrainingCenterPreparingForTraining: CpiRoute = { pageId: "preparingForTraining", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Preparing-For-Training", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-UK', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("PrepareTraining-Title"), fullPath: `${TrainingCenter.fullPath}/Preparing-For-Training` };
const TrainingCenterTraningEnvironmentSetup: CpiRoute = { pageId: "trainingEnvironmentSetup", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Training-Environment-Setup", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-UK', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("TrainingEnvironmentSetup-Title"), fullPath: `${TrainingCenter.fullPath}/Training-Environment-Setup` };
const TrainingCenterVirtualTraining: CpiRoute = { pageId: "virtualTraining", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Virtual-Training", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("VirtualTraining-Title"), fullPath: `${TrainingCenter.fullPath}/Virtual-Training` };
const TrainingCenterRefresherTraining: CpiRoute = { pageId: "refresherTraining", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Refresher-Training", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Refreshers-Title"), fullPath: `${TrainingCenter.fullPath}/Refresher-Training` };
const TrainingCenterIntroductionsAndExpectations: CpiRoute = { pageId: "introductionsAndExpectations", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Introductions-and-Course-Expectations", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("IntroductionsCourse-Title"), fullPath: `${TrainingCenter.fullPath}/Introductions-and-Course-Expectations` };
const TrainingCenterFacilitatingTrainingActivities: CpiRoute = { pageId: "facilitatingTrainingActivities", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Facilitating-Training-Activities", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Facilitating-Training-Activities-Title"), fullPath: `${TrainingCenter.fullPath}/Facilitating-Training-Activities` };

const TrainingCenterManagingYourParticipantsAndClassroom: CpiRoute = { pageId: "managingYourParticipantsAndClassroom", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Managing-Your-Participants-and-Classroom", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-GB', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("Managing-Participants-Classroom-Title"), fullPath: `${TrainingCenter.fullPath}/Managing-Your-Participants-and-Classroom` };
const TrainingCenterTeamTeachingStrategies: CpiRoute = { pageId: "teamTeachingStrategies", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Team-Teaching-Strategies", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-GB', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("TeamTeachingStrategy-Title"), fullPath: `${TrainingCenter.fullPath}/Team-Teaching-Strategies` };
const TrainingCenterRecordedWebinars: CpiRoute = { pageId: "recordedWebinars", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Recorded-Webinars", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Recorded-Webinars-Title"), fullPath: `${TrainingCenter.fullPath}/Recorded-Webinars`, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources/Professional-Enrichment/Recorded-Webinars" };

const TrainingCenterImplementingCpiAtYourOrganization: CpiRoute = { pageId: "implementingCpiAtYourOrganization", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Implementing-CPI-At-Your-Organization", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-GB', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("ImplementCPI-Title"), fullPath: `${TrainingCenter.fullPath}/Implementing-CPI-At-Your-Organization` };
const TrainingCenterProperUseOfTerms: CpiRoute = { pageId: "properUseOfTerms", path: "/Proper-Use-Of-Terms", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Proper-Use-Terms-Title"), fullPath: `${TrainingCenter.fullPath}/Proper-Use-Of-Terms` };

const TrainingCenterWriteYourOwnTrainingActivities: CpiRoute = { pageId: "writeYourOwnTrainingActivities", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Write-Your-Own-Training-Activities", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("WriteYourOwn-Title"), fullPath: `${TrainingCenter.fullPath}/Write-Your-Own-Training-Activities` };
const TrainingCenterTeachingSafetyInterventions: CpiRoute = { pageId: "teachingSafetyInterventions", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Teaching-Safety-Interventions", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("TeachingSafetyInterventions-Title"), fullPath: `${TrainingCenter.fullPath}/Teaching-Safety-Interventions` };
const TrainingCenterIntroducingThirdEdition: CpiRoute = { pageId: "introducingThirdEdition", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Introducing-3rd-Edition", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Introducing-3rd-Edition-Title"), fullPath: `${TrainingCenter.fullPath}/Introducing-3rd-Edition` };
const TrainingCenterSelectingATrainingTopic: CpiRoute = { pageId: "selectingATrainingTopic", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Selecting-A-Training-Topic", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Selecting-Training-Topic-Title"), fullPath: `${TrainingCenter.fullPath}/Selecting-A-Training-Topic` };
const TrainingCenterIdentifyingParticipantNeedsAndGoals: CpiRoute = { pageId: "identifyingParticipantNeedsAndGoals", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Identifying-Participant-Needs-And-Goals", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Identifying-Participant-Needs-Title"), fullPath: `${TrainingCenter.fullPath}/Identifying-Participant-Needs-And-Goals` };
const TrainingCenterOutliningActivityFormatAndTiming: CpiRoute = { pageId: "outliningActivityFormatAndTiming", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Outlining-Activity-Format-And-Timing", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Outlining-Format-Timing-Title"), fullPath: `${TrainingCenter.fullPath}/Outlining-Activity-Format-And-Timing` };
const TrainingCenterSpecifyingPurposeDirectionsAndSetup: CpiRoute = { pageId: "specifyingPurposeDirectionsAndSetup", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Specifying-Purpose-Directions-And-Setup", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Specifying-Purpose-Direction-Setup-Title"), fullPath: `${TrainingCenter.fullPath}/Specifying-Purpose-Directions-And-Setup` };
const TrainingCenterGuidingParticipantsToDebrief: CpiRoute = { pageId: "guidingParticipantsToDebrief", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Guiding-Participants-To-Debrief", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("Guiding-Participants-Debrief-Title"), fullPath: `${TrainingCenter.fullPath}/Guiding-Participants-To-Debrief` };
const TrainingCenterEvaluatingEffectiveness: CpiRoute = { pageId: "evaluatingEffectiveness", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Evaluating-Effectiveness", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("EvalEff-Title"), fullPath: `${TrainingCenter.fullPath}/Evaluating-Effectiveness` };

const TrainingCenterRisksOfPhysicalRestraints: CpiRoute = { pageId: "risksOfPhysicalRestaints", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Risks-Of-Physical-Restraints", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-UK', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("RisksOfRestraints-Title"), fullPath: `${TrainingCenter.fullPath}/Risks-Of-Physical-Restraints` };
const TrainingCenterNCIThirdEdition: CpiRoute = { pageId: "nciThirdEdition", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Nonviolent-Crisis-Intervention-Third-Edition", accessCultures: ['en-US', 'en-CA', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-UK', 'fr-FR'], parent: TrainingCenter, title: () => i18next.t("NCI3E-Title"), fullPath: `${TrainingCenter.fullPath}/Nonviolent-Crisis-Intervention-Third-Edition` };

const TrainingCenterVerbalInterventionThirdEdition: CpiRoute = { pageId: "verbalInterventionThirdEdition", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Verbal-Intervention-3rd-Edition", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("VI3E-Title"), fullPath: `${TrainingCenter.fullPath}/Verbal-Intervention-3rd-Edition` };
const TrainingCenterVerbalInterventionSecondEdition: CpiRoute = { pageId: "verbalInterventionSecondEdition", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Verbal-Intervention-2nd-Edition", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("VI2E-Title"), fullPath: `${TrainingCenter.fullPath}/Verbal-Intervention-2nd-Edition` };
const TrainingCenterNonviolentCrisisInterventionSecondEdition: CpiRoute = { pageId: "nonviolentCrisisInterventionSecondEdition", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Nonviolent-Crisis-Intervention-2nd-Edition", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("NCI2E-Title"), fullPath: `${TrainingCenter.fullPath}/Nonviolent-Crisis-Intervention-2nd-Edition` };

const TrainingCenterVideos: CpiRoute = { pageId: "trainingVideos", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Training-Videos", accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: TrainingCenter, title: () => i18next.t("CPI.UI.MyAccount.VideosResources.TrainingVideos.Title"), fullPath: `${TrainingCenter.fullPath}/Training-Videos` };

export const TrainingCenterBaseRoutes = {
    TrainingCenter,
    TrainingCenterEvalAndPostTraining,
    TrainingCenterCustomizationNewTermsAndTransitions,
    TrainingCenterQualityStandards,
    TrainingCenterDccQualityStandards,
    TrainingCenterBaseOfEmployment,
    TrainingCenterInstructionsForCeBoardApproval,
    TrainingCenterChoosingYourCertifiedInstructors,
    TrainingCenterCommunicatingInternally,
    TrainingCenterAfterVirtualTraining,
    TrainingCenterDownloadResources,
    TrainingCenterCpiLogoBrandMaterials,
    TrainingCenterPreparingForTraining,
    TrainingCenterTraningEnvironmentSetup,
    TrainingCenterVirtualTraining,
    TrainingCenterRefresherTraining,
    TrainingCenterIntroductionsAndExpectations,
    TrainingCenterFacilitatingTrainingActivities,
    TrainingCenterManagingYourParticipantsAndClassroom,
    TrainingCenterTeamTeachingStrategies,
    TrainingCenterRecordedWebinars,
    TrainingCenterImplementingCpiAtYourOrganization,
    TrainingCenterProperUseOfTerms,
    TrainingCenterWriteYourOwnTrainingActivities,
    TrainingCenterTeachingSafetyInterventions,
    TrainingCenterIntroducingThirdEdition,
    TrainingCenterSelectingATrainingTopic,
    TrainingCenterIdentifyingParticipantNeedsAndGoals,
    TrainingCenterOutliningActivityFormatAndTiming,
    TrainingCenterSpecifyingPurposeDirectionsAndSetup,
    TrainingCenterGuidingParticipantsToDebrief,
    TrainingCenterEvaluatingEffectiveness,
    TrainingCenterRisksOfPhysicalRestraints,
    TrainingCenterNCIThirdEdition,
    TrainingCenterVerbalInterventionThirdEdition,
    TrainingCenterVerbalInterventionSecondEdition,
    TrainingCenterNonviolentCrisisInterventionSecondEdition,
    TrainingCenterVideos
}

const Class: CpiRoute = { pageId:"class", path: "", title: () => i18next.t("Class"), parent: Dashboard, fullPath: classBase };
const ClassBulkUpload: CpiRoute = { pageId:"classBulkUpload", path: "/BulkUpload/:classId", title: () => i18next.t("Bulk Upload"), parent: Class, fullPath: `${Class.fullPath}/BulkUpload/:classId` };
const ClassManage: CpiRoute = { pageId:"classManage", path: "/Manage/:classId", title: () => i18next.t("Manage Class"), parent: Class, fullPath: `${Class.fullPath}/Manage/:classId` };

const CourseSeat: CpiRoute = { pageId:"courseSeats", path: "/CourseSeat", title: () => i18next.t("View Seats"), parent: Dashboard, fullPath: "/CourseSeat" };

const Learner: CpiRoute = { pageId:"learner", path: "", title: () => i18next.t("Learner"), parent: Dashboard, fullPath: learnerBase };
const LearnerBulkUpload: CpiRoute = { pageId:"bulkLearnerUpload", path: "/BulkUpload", title: () => i18next.t("Bulk Upload"), parent: Learner, fullPath: `${Learner.fullPath}/BulkUpload` };
const LearnerCertificate: CpiRoute = { pageId:"learnerCertificate", path: "/ViewCertificate/:learnerCourseId", title: () => i18next.t("View Certificate"), parent: Learner, fullPath: `${Learner.fullPath}/ViewCertificate/:learnerCourseId` };
const LearnerHistory: CpiRoute = { pageId:"learnerHistory", path: "/History/:userId", title: () => i18next.t("Learner History"), parent: Learner, fullPath: `${Learner.fullPath}/History/:userId` };

export const InstructorBaseRoutes = {
    Class: {
        Class,
        ClassBulkUpload,
        ClassManage
    },

    CourseSeat: {
        CourseSeat
    },

    Learner: {
        Learner,
        LearnerBulkUpload,
        LearnerCertificate,
        LearnerHistory
    }
};

const routeBase = "/Learn";

const Learn: CpiRoute = { pageId:"learn", path: "", title: () => i18next.t("Learn"), parent: Dashboard, fullPath: routeBase };
const LearnCertificate: CpiRoute = { pageId:"learnCertificate", path: "/ViewCertificate/:learnerCourseId", title: () => i18next.t("Certificate"), parent: Learn, fullPath: `${Learn.fullPath}/ViewCertificate/:learnerCourseId` };
const LearnCourse: CpiRoute = { pageId:"learnCourse", path: "/Course/:classId", title: () => i18next.t("Course"), parent: Learn, fullPath: `${Learn.fullPath}/Course/:classId` };
const LearnHistory: CpiRoute = { pageId:"learnHistory", path: "/History", title: () => i18next.t("History"), parent: Learn, fullPath: `${Learn.fullPath}/History` };

export const LearnerBaseRoutes = {
    Learn,
    LearnCertificate,
    LearnCourse,
    LearnHistory
};

const Error: CpiRoute = { pageId: "error", path: "", title: () => i18next.t("Error"), parent: Dashboard, fullPath: "/Error", accessRoles: ErrorRoles };

export const ErrorBaseRoutes = {
    Error
};

const myTrainingBase = "/MyTraining";
const MyTraining: CpiRoute = { pageId: "myTrainingBase", requiresCompleteUserData: true, accessWithStaffPopulation: true, accessWithCertAgreementOnly: true, path: "", title: () => i18next.t("Dashboard.MyTraining"), fullPath: myTrainingBase };
const MyOnlineCourses: CpiRoute = { pageId:"myOnlineCourses", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/MyOnlineCourses", title: () => i18next.t("Dashboard.MyOnlineCourses"), parent: MyTraining, fullPath: `${MyTraining.fullPath}/MyOnlineCourses`, accessWithStaffPopulation: true, accessRoles: LearnerEnrolleeRoles };
const CertificationHistory: CpiRoute = { pageId: "certificationHistory", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/CertificationHistory", title: () => i18next.t("CPI.MyAccount.Dashboard.ProfessionalDevelopment.CertificationHistory"), parent: MyTraining, fullPath: `${MyTraining.fullPath}/CertificationHistory`, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Profile/Certification-History/",  accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles };
const Membership: CpiRoute = { pageId: "membership", path: "/membership", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, title: () => i18next.t("Membership"), parent: MyTraining, fullPath: `${MyTraining.fullPath}/Membership`, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Membership", accessCultures: ['en-US', 'en-CA', 'fr-CA'], accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles };
const ProfessionalEnrichment: CpiRoute = { pageId: "professionalEnrichment", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path:"/Professional-Enrichment", parent: MyTraining, title: () => i18next.t("Professional Enrichment"), accessWithStaffPopulation: true, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources/Professional-Enrichment/Recorded-Webinars", accessRoles: CertifiedInstructorRoles, accessCultures: ['en-US', 'en-CA', 'fr-CA'], fullPath: `${MyTraining.fullPath}/Professional-Enrichment` };
const Community: CpiRoute = { pageId: "community", requiresCompleteUserData: true, accessWithCertAgreementOnly: true, path: "/Community", title: () => i18next.t("Community"), accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: MyTraining, fullPath: `${MyTraining.fullPath}/Community`, tempBaseURLConfigKey: "YammerSite", tempPathURL: "/cpiinstructorcommunity/", accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles };


export const MyTrainingBaseRoutes = {
    MyTraining,
    MyOnlineCourses,
    CertificationHistory,
    Membership,
    ProfessionalEnrichment,
    Community,
}

const culture = getUserClaims().culture;
const contactUsLink = ((!culture) || (culture && culture.toLowerCase() === "en-us")) ? "https://www.crisisprevention.com/contact-us/" : `https://www.crisisprevention.com/${culture}/contact-us/`;


const supportBase = "/Support";
const Support: CpiRoute = { pageId: "supportBase", path: "", title: () => i18next.t("Support"), requiresCompleteUserData: true, fullPath: supportBase };
const SupportProfile: CpiRoute = { pageId: "supportProfile", path: "/Profile", title: () => i18next.t("Profile"), requiresCompleteUserData: true, parent: Dashboard, fullPath: `${Support.fullPath}/Profile`, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Profile" };
const SupportCertificationAgreement: CpiRoute = { pageId: "supportCertificationAgreement", requiresCompleteUserData: true, accessWithStaffPopulation: true, accessRoles: CertifiedInstructorRoles, parent: SupportProfile, path: "/Profile/Certification-Agreement", title: () => i18next.t("CPI.ActionItem.CertificationAgreement"), fullPath: `${Support.fullPath}/Profile/Certification-Agreement` };
const SupportEditProfile: CpiRoute = { pageId: "supportEditProfile", path: "/Profile/Edit-Profile", title: () => i18next.t("CPI.UI.MyAccount.Profile.EditProfile"), requiresCompleteUserData: true, parent: SupportProfile, fullPath: `${Support.fullPath}/Profile/Edit-Profile`, tempBaseURLConfigKey: "KenticoSite", tempPathURL: "/Platform/Dashboard/Profile/Edit-Profile" };
const SupportProfileChangePassword: CpiRoute = { pageId:"supportProfileChangePassword", path: "/Profile/Change-Password", title: () => i18next.t("Change Password"), requiresCompleteUserData: true, parent: SupportProfile, fullPath: `${Support.fullPath}/Profile/Change-Password` };
const SupportProfileBaseOfEmployment: CpiRoute = { pageId: "baseOfEmployment", path:"/Base-of-Employment-BOE", requiresCompleteUserData: true, accessCultures: ['en-US', 'en-CA', 'fr-CA'], parent: Support, title: () => i18next.t("BaseOfEmployment-Title"), fullPath: `${Support.fullPath}/Base-of-Employment-BOE` };
const SupportOrderHistory: CpiRoute = { pageId: "supportOrderHistory", path: "/Order-History", title: () => i18next.t("Order History"), requiresCompleteUserData: true, parent: Support, fullPath: `${Support.fullPath}/Order-History`, tempBaseURLConfigKey: "PublicSite", tempPathURL: "/Order-History/", accessCultures: ['en-US', 'en-CA', 'fr-CA'] };
const SupportContactUs: CpiRoute = { pageId: "supportContactUs", title: () => i18next.t("Contact Us"), path: "", fullPath: contactUsLink };
const SupportMultiFactorAuthentication: CpiRoute = { pageId:'supportMultiFactorAuthentication', path: "/Profile/Multi-Factor-Authentication", requiresCompleteUserData: true, parent: SupportProfile, title: () => i18next.t("Multi Factor Authentication"), fullPath: `${Support.fullPath}/Profile/Multi-Factor-Authentication` };
const SupportSetYourPassword: CpiRoute = {pageId: 'setYourPassword', path: "/Set-Your-Password", title: () => i18next.t("Set Your Password"), isOutsideAuth: true, fullPath: `${Support.fullPath}/Set-Your-Password`}
const SupportSomethingWentWrong: CpiRoute = { pageId: "somethingWentWrong", path: "/Something-Went-Wrong", title: () => i18next.t("Something Went Wrong"), parent: Support, isOutsideAuth: true, fullPath: `${Support.fullPath}/Something-Went-Wrong` };


export const SupportBaseRoutes = {
    Support,
    SupportCertificationAgreement,
    SupportProfile,
    SupportEditProfile,
    SupportProfileChangePassword,
    SupportProfileBaseOfEmployment,
    SupportOrderHistory,
    SupportContactUs,
    SupportMultiFactorAuthentication,
    Logout,
    SupportSetYourPassword,
    SupportSomethingWentWrong
}

export const allRoutes:CpiRoute[] = [
    ...Object.values(PlatformBaseRoutes),
    ...Object.values(DashboardBaseRoutes),
    ...Object.values(AdminBaseRoutes),
    ...Object.values(InstructorBaseRoutes.Class),
    ...Object.values(InstructorBaseRoutes.CourseSeat),
    ...Object.values(InstructorBaseRoutes.Learner),
    ...Object.values(LearnerBaseRoutes),
    ...Object.values(MyTrainingBaseRoutes),
    ...Object.values(SupportBaseRoutes)
];

export const UrlGroups = {
    Platform: [
        {
            id: "id-training-materials",
            title: () => i18next.t("Dashboard.TrainingMaterials"),
            href: PlatformBaseRoutes.DashboardTrainingMaterials.fullPath,
            icon: "fal fa-books",
            paths: [PlatformBaseRoutes.DashboardTrainingMaterials.fullPath],
            route: PlatformBaseRoutes.DashboardTrainingMaterials
        },
       {
            id: "id-classes",
            title: () => i18next.t("Nav.Classes"),
            href: PlatformBaseRoutes.DashboardClasses.fullPath,
            icon: "fal fa-chalkboard-user",
            paths: [PlatformBaseRoutes.DashboardClasses.fullPath],
            route: PlatformBaseRoutes.DashboardClasses
        },
        {
            id: "id-participants",
            title: () => i18next.t("ClassParticipants.Participants"),
            href: PlatformBaseRoutes.DashboardParticipants.fullPath,
            icon: "fal fa-user",
            paths: [PlatformBaseRoutes.DashboardParticipants.fullPath],
            route: PlatformBaseRoutes.DashboardParticipants
        },
        {
            id: "id-instructor-management",
            title: () => i18next.t("CPI.MyAccount.Dashboard.TrainingCenter.InstructorManagement"),
            href: PlatformBaseRoutes.DashboardInstructorManagement.fullPath,
            icon: "fal fa-user-gear",
            paths: [PlatformBaseRoutes.DashboardInstructorManagement.fullPath],
            route: PlatformBaseRoutes.DashboardInstructorManagement
        },
        {
            id: "id-view-seats",
            title: () => i18next.t("Dashboard.ViewSeats"),
            href: PlatformBaseRoutes.DashboardViewSeats.fullPath,
            icon: "fal fa-screen-users",
            paths: [PlatformBaseRoutes.DashboardViewSeats.fullPath],
            route: PlatformBaseRoutes.DashboardViewSeats
        }
    ],
    Admin: [
        {
            id: "id-manage-skus",
            title: () => i18next.t("Manage SKUs"),
            href: AdminBaseRoutes.ManageSkus.fullPath,
            icon: "fal fa-tags",
            paths: [AdminBaseRoutes.ManageSkus.fullPath],
            route: AdminBaseRoutes.ManageSkus
        },
        {
            id: "id-manage-courses",
            title: () => i18next.t("Manage Courses"),
            href: AdminBaseRoutes.ManageCourses.fullPath,
            icon: "fal fa-flower-daffodil",
            paths: [
                AdminBaseRoutes.ManageCourses.fullPath,
                AdminBaseRoutes.ManageCoursesCreate.fullPath,
                AdminBaseRoutes.ManageCoursesEdit.fullPath
            ],
            route: AdminBaseRoutes.ManageCourses
        },
        {
            id: "id-course-languages",
            title: () => i18next.t("Course Languages"),
            href: AdminBaseRoutes.CourseLanguages.fullPath,
            icon: "fal fa-tools",
            paths: [AdminBaseRoutes.CourseLanguages.fullPath],
            route: AdminBaseRoutes.CourseLanguages
        },
        {
            id: "id-organization-settings",
            title: () => i18next.t("Organization Settings"),
            href: AdminBaseRoutes.OrganizationSettings.fullPath,
            icon: "fal fa-cogs",
            paths: [AdminBaseRoutes.OrganizationSettings.fullPath],
            route: AdminBaseRoutes.OrganizationSettings
        }
    ],
    StaffSupport: [
        {
            id: "id-manage-participants",
            title: () => i18next.t("Manage Participants"),
            href: AdminBaseRoutes.ManageParticipants.fullPath,
            icon: "fab fa-forumbee fs18 fa-light mw24",
            paths: [AdminBaseRoutes.ManageParticipants.fullPath, AdminBaseRoutes.ManageParticipantsParticipant.fullPath],
            route: AdminBaseRoutes.ManageParticipants
        },
        {
            id: "id-manage-seats",
            title: () => i18next.t("Manage Seats"),
            href: AdminBaseRoutes.ManageSeats.fullPath,
            icon: "far fa-hexagon fs18 mw24",
            paths: [AdminBaseRoutes.ManageSeats.fullPath, AdminBaseRoutes.ManageSeatsOrg.fullPath, AdminBaseRoutes.ManageSeatsUsage.fullPath],
            route: AdminBaseRoutes.ManageSeats
        }
    ],
    Instructor: [
        {
            id: "id-manage-classes",
            title: () => i18next.t("Manage Classes"),
            href: InstructorBaseRoutes.Class.Class.fullPath,
            icon: "fal fa-chalkboard-teacher",
            paths: [InstructorBaseRoutes.Class.Class.fullPath, InstructorBaseRoutes.Class.ClassBulkUpload.fullPath, InstructorBaseRoutes.Class.ClassManage.fullPath]
        },
        {
            id: "id-manage-learners",
            title: () => i18next.t("Manage Participants"),
            href: InstructorBaseRoutes.Learner.Learner.fullPath,
            icon: "fal fa-users",
            paths: [
                InstructorBaseRoutes.Learner.Learner.fullPath,
                InstructorBaseRoutes.Learner.LearnerBulkUpload.fullPath,
                InstructorBaseRoutes.Learner.LearnerHistory.fullPath,
                InstructorBaseRoutes.Learner.LearnerCertificate.fullPath
            ]
        },
        {
            id: "id-preview-courses",
            title: () => i18next.t("Preview Courses"),
            href: PlatformBaseRoutes.Preview.fullPath,
            icon: "fal fa-eye",
            paths: [PlatformBaseRoutes.Preview.fullPath, PlatformBaseRoutes.PreviewCourse.fullPath]
        },
        {
            id: "id-view-seats",
            title: () => i18next.t("View Seats"),
            href: InstructorBaseRoutes.CourseSeat.CourseSeat.fullPath,
            icon: "fal fa-screen-users",
            paths: [InstructorBaseRoutes.CourseSeat.CourseSeat.fullPath]
        }
    ],
    Learner: [
        {
            id: "id-my-courses",
            title: () => i18next.t("Dashboard.MyOnlineCourses"),
            href: LearnerBaseRoutes.Learn.fullPath,
            icon: "fal fa-display",
            paths: [LearnerBaseRoutes.Learn.fullPath, LearnerBaseRoutes.LearnCourse.fullPath, LearnerBaseRoutes.LearnCertificate.fullPath]
        },
        {
            id: "id-my-learning-history",
            title: () => i18next.t("My Learning History"),
            href: LearnerBaseRoutes.LearnHistory.fullPath,
            icon: "fal fa-history",
            paths: [LearnerBaseRoutes.LearnHistory.fullPath]
        }
    ],
    Error: [
        {
            id: "id-error",
            title: () => i18next.t("Error"),
            href: ErrorBaseRoutes.Error,
            icon: "fal fa-error",
            paths: [ErrorBaseRoutes.Error]
        }
    ],
    MyTraining: [  
        {
            id: "id-my-online-courses",
            title: () => i18next.t("Dashboard.MyOnlineCourses"),
            href: MyTrainingBaseRoutes.MyOnlineCourses.fullPath,
            icon: "fal fa-display",
            paths: [MyTrainingBaseRoutes.MyOnlineCourses.fullPath],
            route: MyTrainingBaseRoutes.MyOnlineCourses
        },    
        {
            id: "id-certification-history",
            title: () => i18next.t("CPI.MyAccount.Dashboard.ProfessionalDevelopment.CertificationHistory"),
            href: MyTrainingBaseRoutes.CertificationHistory.fullPath,
            icon: "fal fa-graduation-cap",
            paths: [MyTrainingBaseRoutes.CertificationHistory.fullPath],
            route: MyTrainingBaseRoutes.CertificationHistory,
            tempPathURL: "/Profile/Certification-History"
        },  
        {
            id: "id-membership",
            title: () => i18next.t("Membership"),
            href: MyTrainingBaseRoutes.Membership.fullPath,
            icon: "fal fa-sharp fa-address-card",
            paths: [MyTrainingBaseRoutes.Membership.fullPath],
            route: MyTrainingBaseRoutes.Membership,
            tempPathURL: "/Membership",
            accessCultures: ['en-US', 'en-CA', 'fr-CA']
        },
        {
            id: "id-professional-enrichment",
            title: () => i18next.t("Professional Enrichment"),
            href: MyTrainingBaseRoutes.ProfessionalEnrichment.fullPath,
            icon: "fal fa-graduation-cap",
            paths: [MyTrainingBaseRoutes.ProfessionalEnrichment.fullPath],
            route: MyTrainingBaseRoutes.ProfessionalEnrichment
        },
        {
            id: "id-community",
            title: () => i18next.t("Community"),
            href: MyTrainingBaseRoutes.Community.fullPath,
            icon: "fal fa-comments",
            paths: [MyTrainingBaseRoutes.Community.fullPath],
            route: MyTrainingBaseRoutes.Community,
            tempPathURL: "/cpiinstructorcommunity/"
        }
    ],
    Support: [  
        {
            id: "id-support-profile",
            title: () => "Profile",
            href: SupportBaseRoutes.SupportProfile.fullPath,
            icon: "fal fa-square-user",
            paths: [SupportBaseRoutes.SupportProfile.fullPath],
            route: SupportBaseRoutes.SupportProfile
        },     
        {
            id: "id-support-order-history",
            title: () => i18next.t("Order History"),
            href: SupportBaseRoutes.SupportOrderHistory.fullPath,
            icon: "fal fa-circle-dollar",
            paths: [SupportBaseRoutes.SupportOrderHistory.fullPath],
            route: SupportBaseRoutes.SupportOrderHistory,
            tempPathURL: "/Order-History/"
        },
        {
            id: "id-support-contact-us",
            title: () => i18next.t("Contact Us"),
            href: SupportBaseRoutes.SupportContactUs.fullPath,
            icon: "fal fa-phone",
            paths: [SupportBaseRoutes.SupportContactUs.fullPath],
            route: SupportBaseRoutes.SupportContactUs
        },
        {
            id: "id-logout",
            title: () => i18next.t("CPI.UI.Default.SignOut"),
            href: SupportBaseRoutes.Logout.path,
            icon: "fal fa-arrow-right-from-bracket",
            paths: [SupportBaseRoutes.Logout.path],
            route: SupportBaseRoutes.Logout
        }
    ]
};
