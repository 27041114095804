import * as React from "react";


import { Breadcrumb, Button, ButtonGroup, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";

import { InstructorBaseRoutes } from "../Routing/routes";
import { Learner } from "../Interfaces";
import LearnerEditForm from "./Components/learner-edit-form";
import { Link } from "react-router-dom";
import PageMessages from "../Components/page-messages";
import RemoveLearnerConfirm from "./Components/remove-learner-confirm";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../Components/remote-table";
import { fetchActiveLearners } from "../ApiServices/Learner";
import { formatDate } from '../locale-utils';

const ManageLearnersPage = () => {
    const { t } = useTranslation();
    const { toast } = useToast();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);
    const [selectedLearner, setSelectedLearner] = React.useState<Learner>(null);
    const [forceRefresh, setForceRefresh] = React.useState(false);

    const emailColumn = (emailAddress: string, userId: number) => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="viewLearnerHistoryTooltip">{t("View Learner History")}</Tooltip>}
            >
                <Link
                    className="text-link"
                    title={emailAddress}
                    to={InstructorBaseRoutes.Learner.LearnerHistory.fullPath.replace(":userId", userId.toString())}
                    aria-label={t("Learner History")}
                >
                    {emailAddress}
                </Link>
            </OverlayTrigger>
        );
    }

    const actionsColumn = (row: Learner) => {
        return (
            <ButtonGroup className="w-100">
                <Button
                    variant="outline-primary"
                    style={{ width: "100%" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setSelectedLearner(row);
                        setShowEditModal(true);
                    }}
                >
                    <i className="fas fa-edit"></i> {t("Edit")}
                </Button>
                <DropdownButton
                    variant="outline-primary"
                    title=""
                    as={ButtonGroup}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()}
                >
                    <span>
                        <Dropdown.Item
                            eventKey="1"
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation();
                                setSelectedLearner(row);
                                setShowRemoveModal(true);
                            }}
                        >
                            {t("Remove")}
                        </Dropdown.Item>
                    </span>
                </DropdownButton>
            </ButtonGroup>
        );
    }

    const columnHelper = createColumnHelper<Learner>()

    const columns = React.useMemo<ColumnDef<Learner>[]>(
        () => [

            columnHelper.accessor('EmailAddress', {
                cell: info => emailColumn(info.getValue(), info.row.original.UserId),
                    header: "Email",
                    enableSorting: true,
                    size: 30
            }),
            columnHelper.accessor('LastName', {
                cell: info => info.getValue(),
                header: t("Last Name"),
                enableSorting: true,
            }),
            columnHelper.accessor('FirstName', {
                cell: info => info.getValue(),
                header: t("First Name"),
                enableSorting: true,
            }),
            columnHelper.accessor('DateAdded', {
                cell: info => formatDate(info.getValue()),
                header: t("Date Created"),
                enableSorting: true,
            }),
            columnHelper.accessor('LastLogin', {
                cell: info => formatDate(info.getValue()),
                header: t("Last Login"),
                enableSorting: true,
            }),
            columnHelper.accessor('UserId', {
                cell: info => actionsColumn(info.row.original),
                header: t("Actions"),
                enableSorting: false,
                size: 10
            }),
        ],
        []
    );

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>{t("Manage Learners")}</h2>
                <div>
                    <Button
                        variant="outline-primary"
                        className="float-end"
                        as={Link}
                        to={InstructorBaseRoutes.Learner.LearnerBulkUpload.fullPath}
                    >
                        <i className="fas fa-plus"></i> {t("Upload Many Learners")}
                    </Button>
                </div>
            </div>
            <PageMessages loading={loading} />
            {!loading && (
               <RemoteTable
                    keyField="UserId"
                    fetchFunction={fetchActiveLearners}
                    columns={columns}
                    defaultSorted={{ dataField: "DateAdded", order: "desc" }}
                    forceRefresh={forceRefresh} />   
            )}

            {showEditModal && (
                <LearnerEditForm
                    isVisible={showEditModal}
                    learner={selectedLearner}
                    onSave={(resp) => {
                        setShowEditModal(false);
                        toast(resp);
                        if (!resp.Error) {
                            setForceRefresh(!forceRefresh);
                        }
                    }}
                    onHide={() => setShowEditModal(false)}
                />
            )}

            {showRemoveModal && (
                <RemoveLearnerConfirm
                    isVisible={showRemoveModal}
                    learner={selectedLearner}
                    hasLearnerCourses={true}
                    onHide={() => setShowRemoveModal(false)}
                    handleRemove={(resp) => {
                        setShowRemoveModal(false);
                        toast(resp);
                        if (!resp.Error) {
                            setForceRefresh(!forceRefresh);
                        }
                    }}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, ManageLearnersPage);
