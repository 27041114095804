import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ThreeColumnLayout = (props: {
    left: JSX.Element,
    center: JSX.Element,
    right: JSX.Element,
    isMobile?: boolean
}): JSX.Element => {
    props = { isMobile: false, ...props };

    return (
        <Container className="mw-1440 mt-4" fluid>
            <Row>
                <Col className={`${!props.isMobile ? 'pl-0' : ''}`} xs={12} lg={4}>
                    {props.left}
                </Col>

                <Col xs={12} lg={4}>
                    {props.center}
                </Col>

                <Col className={`${!props.isMobile ? 'pr-0' : ''}`} xs={12} lg={4}>
                    {props.right}
                </Col>
            </Row>
        </Container>
    );
};

export default ThreeColumnLayout;
