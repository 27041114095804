import * as React from "react";
// import Tile from "../../../Components/UI/tile";
// import Hours from "./hours";
// import ArrowLink from "../../../Components/UI/arrow-link";
// import TextInput from "../../../Components/Form/text-input";
// import Select from "../../../Components/Form/select";
// import TextArea from "../../../Components/Form/text-area";
// import Button from "../../../Components/UI/button";

import { Row, Container, Col } from "react-bootstrap";
import Button from "../../Components/UI/button";
import Tile from "../../Components/UI/tile";
import useParsedTranslation from "../../hooks/useParsedTranslation";
import { useTranslation } from "react-i18next";
import { RbacContext } from "../../rbac-context";
import { getToken } from "../../helper-functions";
import { ConfigContext } from "../../configuration-context";

const CertificationAgreement = (props: {

}) => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    let { setAgreementCriteriaMet } = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const handleAcceptedCertificateAgreement = () => {
        fetch(apimBaseUrl + "/C/Enrollment/AcceptCertAgreement/", {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "content-type": "application/json",
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                userId: rbac.userContext.PingId
            })
        })
            .then(resp => {
                if (resp.status == 200) { setAgreementCriteriaMet(); }
            })
            .catch(function (error) {
                alert(JSON.stringify(error));
            });
    };

    return (
        <div className="page-content m-auto">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <h3 className="mt-4 page-title-h3">{ t('CPI.MyAccount.Dashboard.PreDashboard.CertAgreementHeader') }</h3>
                    </Col>
                    <div className="text-container">
                        { tp('CPI.MyAccount.CertificationAgreementContent') }
                    </div>
                </Row>

                { !rbac.userContext.AgreementCriteriaMet &&
                    <div className="acknowledge-button">
                        <Button
                            label={t('CPI.MyAccount.Acknowledge')}
                            isSolid={true}
                            elementId="acknowledgeButton"
                            clickAction={handleAcceptedCertificateAgreement}
                        />
                    </div>
                }
    
            </Container>
        </div>
    )
};

export default CertificationAgreement;