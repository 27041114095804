import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CustomTable from "../../../Components/custom-table";
import ClassList from "./class-list";
import Button from "../../../Components/UI/button";
import EmptyContentBasic from "../../../Components/UI/empty-content-basic";
import ClassListing from "./class-listing";
import {
    fetchInstructorsCertifications,
    fetchPrograms,
    fetchProgramsOrganizations,
    fetchOrganizationBranchIds,
    pageSizes,
    returnInstructorsCertifications,
    returnPrograms
} from "../DashboardPlatformUtils";

import { ConfigContext } from "../../../configuration-context";
import { RbacContext } from "../../../rbac-context";
import useParsedTranslation from "../../../hooks/useParsedTranslation";

import type { IClass } from "../../../Interfaces/Platform/Classes";
import type { Pagination } from "../../../Interfaces/Platform/CustomTable";
import { FiltersTemplate, SelectedFilters } from "../../../Interfaces/Platform/GenericFilter";
import useTrainableSpecialtiesByCert from "../../../hooks/useTrainableSpecialtiesByCert";
import { InstructorBaseRoutes } from "../../../Routing/routes";
import { getToken, isBusinessAdministrator, isCertInstructor, isExternalURL } from "../../../helper-functions";

import useFeatureFlags from "../../../hooks/useFeatureFlags";

interface Props {
    canViewLearningHistory: boolean;
    canViewClassDetails: boolean;
}
const ClassesArchived = (props: Props) => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const navigate = useNavigate();
    const [ITLIntermediatePhysicalSkills] = useFeatureFlags("ITLIntermediatePhysicalSkills", "pending");
    const [trainablePrograms] = useTrainableSpecialtiesByCert(ITLIntermediatePhysicalSkills);
    
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const rbacContext = React.useContext(RbacContext);
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
    const [isFiltersEmpty, setIsFiltersEmpty] = React.useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const isBA = isBusinessAdministrator(rbacContext?.userContext);
    const isCI = isCertInstructor(rbacContext?.userContext);
    const altOrg = rbacContext?.userContext?.SelectedOrganization;
    const altOrgsLength = rbacContext?.userContext?.AltOrganizationsList?.length;
    const orgToBeSent = (isCI && !isBA) && altOrgsLength > 0 ? altOrg : null;
    const defaultFilters = {
        searchTerm: '',
        sortBy: 'date',
        percentageRecorded: 'all',
        program: 'all',
        instructor: 'all'
    };

    const [classes, setClasses] = React.useState<IClass[]>([]);
    const [organizationBranchIds, setOrganizationBranchIds] = React.useState([]);
    const [programs, setPrograms] = React.useState([]);
    const [showClearFilters, setShowClearFilters] = React.useState<boolean>(false);
    const [instructors, setInstructors] = React.useState([]);
    const [filters, setFilters] = React.useState<SelectedFilters>(defaultFilters);
    const [pagination, setPagination] = React.useState<Pagination>({
        current: 1,
        total: 0,
        size: 25,
    });

    const handleNavigation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isExternalURL(InstructorBaseRoutes.Class.Class.fullPath)) {
            event.preventDefault();
            navigate(InstructorBaseRoutes.Class.Class.fullPath);
        }
    };

    const getOrganizationBranch = async () => {
        const data = await fetchOrganizationBranchIds(apimBaseUrl, configContext, altOrg);
        setOrganizationBranchIds(data);
        getProgramsOrganizations(data);
        getOrganizationsPaginatedData(1, true, data);
        getInstructors(data);
        setHasLoaded(true);
    };

    const fetchClasses = async (page: number, size: number): Promise<{ totalResults: number; classes: [] }> => {
        try {
            setIsLoading(true);
            const response = await fetch(`${apimBaseUrl}/Q/Class/Archived/User`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({
                    "searchTerm": filters.searchTerm,
                    "program": filters.program === 'all' ? null : filters.program,
                    "userId": filters.instructor === 'all' ? rbacContext.userContext.PingId : filters.instructor,
                    "percentSubmitted": filters.percentageRecorded === 'all' ? null : parseInt(filters.percentageRecorded),
                    "sortBy": filters.sortBy,
                    "organizationId": orgToBeSent,
                    "pageNumber": page,
                    "resultsPerPage": size
                })
            });
            if (response.status === 404 || response.status === 204) return {
                totalResults: 0,
                classes: []
            };
            return await response.json();
        } catch (e) {
            setIsLoading(false);
            // Handle fetch error
        }
    };

    const fetchClassesOrganizations = async (page: number, size: number, orgIds?: string[]): Promise<{ totalResults: number; classes: [] }> => {
        try {
            setIsLoading(true);
            const response = await fetch(apimBaseUrl + "/Q/Class/Archived/Organizations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({
                    "searchTerm": filters.searchTerm,
                    "program": filters.program === 'all' ? null : filters.program,
                    "percentSubmitted": filters.percentageRecorded === 'all' ? null : parseInt(filters.percentageRecorded),
                    "sortBy": filters.sortBy,
                    "pageNumber": page,
                    "resultsPerPage": size,
                    "organizationIds": orgIds ? orgIds : organizationBranchIds,
                    "organizationId": altOrg
                })
            });
            if (response.status === 404 || response.status === 204) return {
                totalResults: 0,
                classes: []
            }
            return await response.json();
            
        } catch (e) {
            setIsLoading(false);
            // Handle fetch error
        }
    };

    const getInstructors = async (organizations: string[]) => {
        const data = await fetchInstructorsCertifications(apimBaseUrl, configContext, altOrg, organizations, rbacContext.userContext.UserId);
        setInstructors(data);
    };

    const getPrograms = async () => {
        let allTrainablePrograms:string[] = [];
        const data = await fetchPrograms(apimBaseUrl, configContext, [rbacContext.userContext.PingId]);
        if (Array.isArray(data) && (data.length > 0)) {
            data.forEach((program:any) => {
                let trainableProgramsOnThisSpecialty = trainablePrograms(program, rbacContext.userContext.Country, false);
                allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
            });
        }
        setPrograms([...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program })));
    };

    const getProgramsOrganizations = async (orgsIds: string[]) => {
        let allTrainablePrograms:string[] = [];
        const data = await fetchProgramsOrganizations(apimBaseUrl, configContext.SystemConfiguration.ApimKey, orgsIds);
        if (Array.isArray(data) && (data.length > 0)) {
            data.forEach((program:any) => {
                let trainableProgramsOnThisSpecialty = trainablePrograms(program, rbacContext.userContext.Country, false);
                allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
            });
        }
        setPrograms([...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program })));
    };

    const getPaginatedData = async (page: number, initialCall: boolean = false) => {
        const { totalResults, classes } = await fetchClasses(page, pagination.size);
        setShowClearFilters(JSON.stringify(filters) !== JSON.stringify(defaultFilters));
        setPagination({ ...pagination, total: totalResults });
        setIsEmpty(initialCall && totalResults === 0);
        setIsFiltersEmpty(!initialCall && totalResults === 0);
        setClasses(classes);
        setIsLoading(false);
    };

    const getOrganizationsPaginatedData = async (page: number, initialCall: boolean = false, orgIds?: string[]) => {
        const { totalResults, classes } = await fetchClassesOrganizations(page, pagination.size, orgIds);
        setShowClearFilters(JSON.stringify(filters) !== JSON.stringify(defaultFilters));
        setPagination({ ...pagination, total: totalResults });
        setIsEmpty(initialCall && totalResults === 0);
        setIsFiltersEmpty(!initialCall && totalResults === 0);
        setClasses(classes);
        setIsLoading(false);  
    };

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey && (ITLIntermediatePhysicalSkills != "pending")) {
            if (isBA) {
                getOrganizationBranch();
            } else {
                getPrograms();
            }
        }
    }, [configContext?.SystemConfiguration?.ApimKey, altOrg, ITLIntermediatePhysicalSkills]);

    React.useEffect(() => {
        // First data load
        // Is user is not a BA
        if (!isBA && !hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            getPaginatedData(pagination.current, true);
            setHasLoaded(true);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, pagination.current, pagination.size]);

    React.useEffect(() => {
        // On pagination change data load
        // If user is a BA
        if (isBA && organizationBranchIds && hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            // If there's no instructor selected
            if (filters.instructor === 'all') {
                getOrganizationsPaginatedData(pagination.current);
            } else {
                // If there's an instructor selected
                getPaginatedData(pagination.current);
            }
        }
        // If user is not a BA
        if (!isBA && hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            getPaginatedData(pagination.current);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, pagination.current, pagination.size]);

    React.useEffect(() => {
        // If data has already been loaded and value on search is cleared
        if (hasLoaded && filters.searchTerm === '') {
            const handler = setTimeout(() => {
                // If there's no instructor selected
                if (isBA) {
                    if (filters.instructor === 'all') {
                        getOrganizationsPaginatedData(pagination.current);
                    } else {
                        // If there's an instructor selected
                        getPaginatedData(pagination.current);
                    }
                } else {
                    getPaginatedData(pagination.current);
                }
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [filters.searchTerm]);

    React.useEffect(() => {
        // On filters change data load
        // If user is a BA
        if (isBA && organizationBranchIds && organizationBranchIds.length > 0 && hasLoaded && configContext?.SystemConfiguration?.ApimKey) {
            setIsLoading(true);
            const handler = setTimeout(() => {
                // If there's no instructor selected
                if (filters.instructor === 'all') {
                    getOrganizationsPaginatedData(pagination.current);
                } else {
                    // If there's an instructor selected
                    getPaginatedData(pagination.current);
                }
            }, 2000);
            return () => clearTimeout(handler);
        }
        // If user is not a BA
        if (!isBA && configContext?.SystemConfiguration?.ApimKey && hasLoaded) {
            setIsLoading(true);
            const handler = setTimeout(() => {
                getPaginatedData(pagination.current);
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [
        configContext?.SystemConfiguration?.ApimKey,
        filters.instructor,
        filters.percentageRecorded,
        filters.sortBy,
        filters.program,
    ]);

    React.useEffect(() => {
        // If user is not a BA and Alternate Organization changes
        if (!isBA && configContext?.SystemConfiguration?.ApimKey && hasLoaded) {
            setIsLoading(true);
            getPaginatedData(1, true);
        }
    }, [
        configContext?.SystemConfiguration?.ApimKey,
        altOrg
    ]);

    const updatePageSize = (size: number) => setPagination({ ...pagination, current: 1, size });
    const updatePage = (page: number) => setPagination({ ...pagination, current: page });
    const updateFilter = (filter: string, value: string) => setFilters({ ...filters, [filter]: value });
    const clearFilters = () => {
        setFilters({
            searchTerm: '',
            sortBy: 'date',
            percentageRecorded: 'all',
            program: 'all',
            instructor: 'all'
        });
        setShowClearFilters(false);
    };

    const returnClasses = () => classes.map((classItem, index) => (
        <ClassListing
            {...classItem}
            canViewClassDetails={props.canViewClassDetails}
            key={`class-archived-${index}`}
        />
    ));

    const handleSearch = () => {
        if (isBA) {
            if (filters.instructor === 'all') {
                return getOrganizationsPaginatedData(1);
            } else {
                return getPaginatedData(1);
            }
        } else {
            return getPaginatedData(1);
        }
    };

    const filterOptions = !isBA ? {
        sortByOptions: [
            { label: t("CPI.Platform.EventRegistration.UI.NearestDate"), value: 'date' },
            { label: t("Class Name (A to Z)"), value: 'alpha' }
        ],
        programOptions: [
            { label: t("All"), value: 'all' },
            ...returnPrograms(programs)
        ],
    } : {
        sortByOptions: [
            { label: t("CPI.Platform.EventRegistration.UI.NearestDate"), value: 'date' },
            { label: t("Class Name (A to Z)"), value: 'alpha' }
        ],
        programOptions: [
            { label: t("All"), value: 'all' },
            ...returnPrograms(programs)
        ],
        instructorOptions: [
            { label: t("All"), value: 'all' },
            ...returnInstructorsCertifications(instructors)
        ],
    };

    if (isEmpty) {
        // Empty Display when filters will not change results
        return (
            <EmptyContentBasic
                primary
                title={t("You do not have any archived classes")}
                subtitle={t("Classes that have past their credential expiration date will display here")}
            />
        );
    }

    return (
        <>
            <div className="classes-actions-row">
                <span>{t("Total-Classes", { total: pagination.total })}</span>
                <div className="classes-actions">
                    {props.canViewLearningHistory && <div onClick={handleNavigation}><Button elementId="view-prior-learning-history-button" isSolid={false} label={t("View Prior CPI Learning History")} /></div>}
                </div>
            </div>
            <CustomTable
                enableFilter
                classes={false}
                enablePaging
                enableSearch={false}
                enableHeader={false}
                pageSize={pagination.size}
                pageSizes={pageSizes}
                currentPage={pagination.current}
                total={pagination.total}
                loading={isLoading}
                handlePage={updatePage}
                handlePageSize={updatePageSize}
                empty={isFiltersEmpty}
                emptyComponent={
                    <EmptyContentBasic
                        title={t("No Results Found")}
                        subtitle={t("Clear your filters to continue searching")}
                    />
                }
                filters={{
                    template: FiltersTemplate.Classes,
                    update: updateFilter,
                    set: setFilters,
                    showClear: showClearFilters,
                    clear: clearFilters,
                    search: {
                        label: t("CPI.UI.Default.Search"),
                        handle: handleSearch
                    },
                    options: filterOptions
                }}
            >
                <ClassList listItems={returnClasses()} />
            </CustomTable>
        </>
    );
};

export default ClassesArchived;
