import "./many-sco-play-modal.scss";
import "./many-sco-preview-modal.scss";

import * as React from "react";

import { Alert, Button, Modal } from "react-bootstrap";

import { fetchLessonLaunchUrl } from "../../ApiServices/Preview";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const ManyScoPreviewModal = ({
    show,
    courseId,
    lessonId,
    isLastLesson,
    onHide,
    onNext,
    showAlert,
    onAlertClose
}: {
    show: boolean;
    courseId: number;
    lessonId: number;
    isLastLesson: boolean;
    onHide: () => void;
    onNext: () => void;
    showAlert: boolean;
    onAlertClose: () => void;
}) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [launchUrl, setLaunchUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [advancing, setAdvancing] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const resp = await fetchLessonLaunchUrl(courseId, lessonId);
                setLoading(false);
                setLaunchUrl(resp.Data.LaunchUrl);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }         
        };

        getData();
    }, [lessonId]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            className="modal-play many-sco-preview-modal"
            dialogClassName="modal-dialog-responsive"
            contentClassName="mx-auto"
        >
            <Modal.Body className={loading ? "loading" : ""}>
                <div className="text-center">
                    <Alert
                        variant="info"
                        dismissible
                        className="m-0 d-inline-block"
                        show={showAlert}
                        onClose={onAlertClose}
                    >
                        {t("This is a preview course, not to be used for formal training.")}{" "}
                        <a className="text-link pendo__link-contact-us" href="#" onClick={(e) => e.preventDefault()}>
                            {t("Please contact us to learn more.")}
                        </a>
                    </Alert>
                </div>
                <div className="btn-group-play-modal">
                    <Button variant="primary" onClick={onHide}>
                        <i className="fas fa-times"></i>
                    </Button>
                </div>
                {loading && (
                    <div style={{ position: "absolute", top: "50%", left: "50%" }} className="fa-3x">
                        <i className="fas fa-spinner fa-spin"></i>
                    </div>
                )}
                {!loading && (
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" allow="autoplay" src={launchUrl}></iframe>
                    </div>
                )}
                <Button
                    variant="outline-primary"
                    className="btn-continue-play"
                    disabled={advancing}
                    onClick={() => {
                        try {
                            setAdvancing(true);
                            if (isLastLesson) {
                                onHide();
                            } else {
                                onNext();
                            }
                        } finally {
                            setAdvancing(false);
                        }
                    }}
                >
                    {advancing && <i className="fas fa-spinner fa-spin"></i>} {isLastLesson ? t("Finish") : t("Next")}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ManyScoPreviewModal);
