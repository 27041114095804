import * as React from "react";

import { Alert } from "react-bootstrap";
import { ClassInfoModel } from "../../Interfaces";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface SeatCountAlertProps {
    classInfo: ClassInfoModel;
}

const SeatCountAlert = ({ classInfo }: SeatCountAlertProps) => {
    const { t } = useTranslation();

    return (
        <>
            {classInfo.SeatsRemaining < 1 && (
                <Alert variant="danger" className="fw-bold">
                    {t("You do not have any seats remaining for")} {classInfo.CourseName}.{" "}
                    <a className="test-link pendo__link-contact-us" href="#" onClick={(e) => e.preventDefault()}>
                        {t("Please contact us for more seats")}.
                    </a>
                </Alert>
            )}
            {classInfo.SeatsRemaining < 30 && classInfo.SeatsRemaining >= 1 && (
                <Alert variant="warning" className="fw-bold">
                    {t("You only have {{seatsRemaining}} seats remaining for {{courseName}}", {
                        seatsRemaining: classInfo.SeatsRemaining,
                        courseName: classInfo.CourseName
                    })}{" "}
                    <a className="test-link pendo__link-contact-us" href="#" onClick={(e) => e.preventDefault()}>
                        {t("Please contact us for more seats")}.
                    </a>
                </Alert>
            )}
            {classInfo.SeatsRemaining >= 30 && (
                <Alert variant="success" className="fw-bold">
                    {t("You have {{seatsRemaining}} seats remaining for {{courseName}}.", {
                        seatsRemaining: classInfo.SeatsRemaining,
                        courseName: classInfo.CourseName
                    })}
                </Alert>
            )}
        </>
    );
};

export default withAITracking(reactPlugin, SeatCountAlert);
