import i18n from "i18next";
import type { IDocumentClass } from "../Interfaces/Platform/Classes";
import { DEFAULT_MAX_CHARS } from "../FormValidationRules/commonValues";

export function validateDocumentClassWithDates(values: IDocumentClass) {
    let errors: any = {};

    if (!values.selection) {
        errors.selection = i18n.t("Select a program");
    }

    if ((values.classDates[0] && !values.classDates[0].date) || (values.classDates[1] && !values.classDates[1].date) || (values.classDates[2] && !values.classDates[2].date) || (values.classDates[3] && !values.classDates[3].date) || (values.classDates[4] && !values.classDates[4].date)) {
        errors.classDates = i18n.t("Validate-Date");
    }

    if ((values.classDates[0] && values.classDates[0].refresherHours && values.classDates[0].refresherHours.length > DEFAULT_MAX_CHARS)
        || (values.classDates[0] && values.classDates[0].initialHours && values.classDates[0].initialHours.length > DEFAULT_MAX_CHARS)) {
        errors.classDates0 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }
    
    if ((values.classDates[0] && ((!values.classDates[0].refresherHours || values.classDates[0].refresherHours === '0') && (!values.classDates[0].initialHours || values.classDates[0].initialHours === '0')))) {
        errors.classDates0 = i18n.t("Validate-Hours-Greater-Than-Zero");
    }

    if ((values.classDates[1] && values.classDates[1].refresherHours && values.classDates[1].refresherHours.length > DEFAULT_MAX_CHARS)
        || (values.classDates[1] && values.classDates[1].initialHours && values.classDates[1].initialHours.length > DEFAULT_MAX_CHARS)) {
        errors.classDates1 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    if ((values.classDates[1] && ((!values.classDates[1].refresherHours || values.classDates[1].refresherHours === '0') && (!values.classDates[1].initialHours || values.classDates[1].initialHours === '0')))) {
        errors.classDates1 = i18n.t("Validate-Hours-Greater-Than-Zero");
    }

    if ((values.classDates[2] && values.classDates[2].refresherHours && values.classDates[2].refresherHours.length > DEFAULT_MAX_CHARS)
        || (values.classDates[2] && values.classDates[2].initialHours && values.classDates[2].initialHours.length > DEFAULT_MAX_CHARS)) {
        errors.classDates2 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    if ((values.classDates[2] && ((!values.classDates[2].refresherHours || values.classDates[2].refresherHours === '0') && (!values.classDates[2].initialHours || values.classDates[2].initialHours === '0')))) {
        errors.classDates2 = i18n.t("Validate-Hours-Greater-Than-Zero");
    }

    if ((values.classDates[3] && values.classDates[3].refresherHours && values.classDates[3].refresherHours.length > DEFAULT_MAX_CHARS)
        || (values.classDates[3] && values.classDates[3].initialHours && values.classDates[3].initialHours.length > DEFAULT_MAX_CHARS)) {
        errors.classDates3 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    if ((values.classDates[3] && ((!values.classDates[3].refresherHours || values.classDates[3].refresherHours === '0') && (!values.classDates[3].initialHours || values.classDates[3].initialHours === '0')))) {
        errors.classDates3 = i18n.t("Validate-Hours-Greater-Than-Zero");
    }

    if ((values.classDates[4] && values.classDates[4].refresherHours && values.classDates[4].refresherHours.length > DEFAULT_MAX_CHARS)
        || (values.classDates[4] && values.classDates[4].initialHours && values.classDates[4].initialHours.length > DEFAULT_MAX_CHARS)) {
        errors.classDates4 = i18n.t("Exceeded-Max-Limit", { length: DEFAULT_MAX_CHARS });
    }

    if ((values.classDates[4] && ((!values.classDates[4].refresherHours || values.classDates[4].refresherHours === '0') && (!values.classDates[4].initialHours || values.classDates[4].initialHours === '0')))) {
        errors.classDates4 = i18n.t("Validate-Hours-Greater-Than-Zero");
    }

    return errors;
}

export function validateDocumentClass(values: IDocumentClass) {
    let errors: any = {};

    if (!values.selection) {
        errors.selection = i18n.t("Select a program");
    }

    return errors;
}
