import * as React from "react";
import { Container } from "react-bootstrap";
import SixBySix from "../Components/UI/UX-Layout/Content/six-by-six";
import EightByFour from "../Components/UI/UX-Layout/Content/eight-by-four";
import useForm from "../hooks/useForm";
import TextInput from "../Components/Form/text-input";
import Button from "../Components/UI/button";
import validate from "../FormValidationRules/setYourPasswordValidationRules";
import AlertProperties from "../Interfaces/Platform/AlertProperties";
import Alert from "../Components/UI/alert";
import Tile from "../Components/UI/tile";
import { returnPageLevelAlerts } from "./Utils/alerts";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import { validateSetPasswordToken } from "../ApiServices/Login";
import { resetPassword, login } from "../ApiServices/Login";
import { SupportBaseRoutes } from "../Routing/routes";
import NoAccess from "./no-access";

import { useTranslation } from "react-i18next";
import { getUserClaims } from "../helper-functions";

import { SetYourPasswordUserData } from "../Interfaces/Platform/SetYourPassword";
import { get } from "lodash";

const SetYourPassword = () => {
    const { t } = useTranslation();

    const [pageLevelAlerts, addPageLevelAlert, removePageLevelAlerts] = usePageLevelAlerts();
    const [isValidating, setIsValidating] = React.useState(true);
    const [isValidated, setIsValidated] = React.useState(false);
    const [userData, setUserData] = React.useState<SetYourPasswordUserData>({ userId: null, email: null });

    const [errorAlert, setErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const [submitErrorAlert, setSubmitErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const removeErrorAlert = () => setErrorAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const removeSubmitErrorAlert = () => setSubmitErrorAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const handleTokenValidation = async () => {
        const resp = await validateSetPasswordToken();

        if (resp && resp.data && resp.data.userId) {
            setUserData(resp.data);
            setIsValidated(true);                    
            setIsValidating(false);
        } else {
            // redirect to login (or through logout if user is authenticated)
            window.location.href = (getUserClaims()) ? SupportBaseRoutes.Logout.path : '/Login';
        }
    }

    React.useEffect(() => {
        handleTokenValidation();
    }, []);

    const handleSetPassword =  async () => {
        const resp = await resetPassword(userData.userId, formFieldData.newPassword);
        
        // if a user is authenticated, redirect through logout to login, otherwise just straight to login
        if (!resp.Error && resp.Data && resp.Data.Success) {
            window.location.href = (getUserClaims()) ? SupportBaseRoutes.Logout.path : '/Login';
        } else {
            // something went wrong
            removePageLevelAlerts();
            addPageLevelAlert({ alertLevel: { alertLevel: "error" }, description: (resp.Error) ? resp.Error.Message : "Something went wrong", canDismiss: false });
        }
    }

    const displayValidators = () => {
        const validations = {
            minLength: t('At least 8 characters'),
            upperCase: t('Uppercase character'),
            lowerCase: t('Lowercase character'),
            number: t('Number'),
            symbol: t('Symbol'),
            passwordsMatch: t('New password matches password confirmation')
        };
        const formExists = formFieldData && Object.entries(formFieldData).length > 0;
        return Object.entries(validations).map(([key, value], index: number) => {
            const classes = () => {
                if (formExists && formFieldData.newPassword) {
                    if (!checkErrors && formFieldData.newPassword !== formFieldData.confirmPassword) {
                        return 'fa-light change-password-validator-error';
                    } else {
                        if (errors.hasOwnProperty(key)) {
                            return 'fa-light change-password-validator-error';
                        } else {
                            return 'fa-solid change-password-validator-success';
                        }
                    }
                } else {
                    return 'fa-light change-password-validator-error';
                }
            };
            return (
                <div key={`password-validation-${index}`}>
                    <i className={`${classes()} fa-sharp fa-circle-check mr-2`}/>
                    {value}
                </div>
            );
        });
    };

    const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Uses regex patterns from HoneyBee component
        handleChangeByGetEvent({ newPassword: e.target.value });
        if (e.target.value) delete errors.newPassword;
        if (!e.target.value.match(/.{8,}/)) {
            errors.minLength = true;
        } else {
            delete errors.minLength;
        }
        if (!e.target.value.match(/[A-Z]/)) {
            errors.upperCase = true;
        } else {
            delete errors.upperCase;
        }
        if (!e.target.value.match(/[a-z]/)) {
            errors.lowerCase = true;
        } else {
            delete errors.lowerCase;
        }
        if (!e.target.value.match(/[0-9]/)) {
            errors.number = true;
        } else {
            delete errors.number;
        }
        if (!e.target.value.match(/[!@#$%`~^&*()\-_=+[\]{}.,<>\/\\?'";:|]/)) {
            errors.symbol = true;
        } else {
            delete errors.symbol;
        }
        if (e.target.value === "" && formFieldData.confirmPassword === "") {
            errors.passwordsMatch = true;
        } else if (e.target.value !== formFieldData.confirmPassword) {
            errors.passwordsMatch = true;
        } else {
            delete errors.passwordsMatch;
        }
    };

    const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeByGetEvent({ confirmPassword: e.target.value });
        if (e.target.value) delete errors.confirmPassword;
        if (formExists && formFieldData.newPassword !== "") {
            if ((e.target.value !== formFieldData.newPassword)) {
                errors.passwordsMatch = true;
            } else {
                delete errors.passwordsMatch;
            }
        }
    };

    const {
        formFieldData,
        errors,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit
    } = useForm(handleSetPassword, validate);

    const formExists = formFieldData && Object.entries(formFieldData).length > 0;
    const checkErrors = errors && Object.entries(errors).length > 0;
    const showErrorAlert = (alert: { show: boolean } & AlertProperties) => setErrorAlert(alert);
    
    return (
        <>
            { isValidating &&
                <div className="d-flex justify-content-center align-items-center mt-4" style={{ height: 'calc(100vh - 120px)' }}><i className="fs38 color-blue fas fa-spinner fa-spin"></i></div>
            }

            { isValidated && !isValidating &&
                <div className="page-content m-auto">
                    <Container fluid className="page-padding mt-4">
                        {errorAlert.show && (
                            <Alert
                                state={errorAlert.alertLevel}
                                description={errorAlert.description}
                                arrowLink={errorAlert.arrowLink}
                                canDismiss={errorAlert.canDismiss}
                                removeAlertParentHandler={removeErrorAlert}
                            />
                        )}
                        {submitErrorAlert.show && (
                            <Alert
                                state={submitErrorAlert.alertLevel}
                                description={submitErrorAlert.description}
                                arrowLink={submitErrorAlert.arrowLink}
                                canDismiss={submitErrorAlert.canDismiss}
                                removeAlertParentHandler={removeSubmitErrorAlert}
                            />  
                        )}
                        
                        <EightByFour 
                            left={
                                <> { returnPageLevelAlerts(pageLevelAlerts) } </>
                            }

                            right={<></>}
                        />

                        <EightByFour 
                            left={
                                <Tile title={ t("Set Your Password") } customClassName="mt-0 p-4">
                                    <SixBySix
                                        left={
                                            <div className="change-password-form-container">
                                                { userData.email &&
                                                    <TextInput
                                                        label={ t("Email") }
                                                        name="email"
                                                        value={userData.email}
                                                        type="email"
                                                        id="email"
                                                        isReadOnly={true}
                                                        isRequired={true}
                                                    />
                                                }

                                                <TextInput
                                                    label={ t("New Password") }
                                                    isError={errors.hasOwnProperty("newPassword")}
                                                    name="newPassword"
                                                    value={formFieldData.newPassword}
                                                    type="password"
                                                    changeAction={handleNewPassword}
                                                    id="newPassword"
                                                    isRequired
                                                    errorLabel={errors.newPassword}
                                                />
                                                <TextInput
                                                    label={ t("Confirm Password") }
                                                    isError={errors.hasOwnProperty("confirmPassword")}
                                                    name="confirmPassword"
                                                    value={formFieldData.confirmPassword}
                                                    type="password"
                                                    changeAction={handleConfirmPassword}
                                                    id="confirmPassword"
                                                    isRequired
                                                    errorLabel={errors.confirmPassword}
                                                />
                                                <div className="change-password-form-actions">
                                                    <Button
                                                        label={ t("Sign In") }
                                                        isSolid
                                                        clickAction={handleSubmit}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        right={
                                            <div className="change-password-validators-container">
                                                <span className="fs18 mb-1 bold">{ t("New Password Requirements") }</span>
                                                {displayValidators()}
                                            </div>
                                        }
                                    />
                                </Tile>
                            }
                            right={<></>}
                        />
                        
                    </Container>
                </div>
            }

            { !isValidated && !isValidating &&
                <NoAccess />
            }
    </>
    );
};

export default SetYourPassword;
