import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";
import { isExternalURL } from "../../../helper-functions";
import { InstructorBaseRoutes } from "../../../Routing/routes";

export interface Props {
    onSuccess: any;
    onClose: () => void;
}

const ClassDatePast = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(InstructorBaseRoutes.Class.Class.fullPath)) {
            event.preventDefault();
            navigate(InstructorBaseRoutes.Class.Class.fullPath);
        }
    };

    return (
        <>
            <div className="class-create-past-date">
                <div className="content">
                    <p>{t("Blended-General-Text-Alert")}</p>
                    <p>{t("Blended-Seats-Text-Alert")} <a href="javascript:void(0);" onClick={handleClick} className="link-cursor">{t("CPI-Learning-History")}</a>.</p>
                    <p>{t("Blended-Continue-Text")}</p>
                </div>
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton clickAction={() => props.onClose()} label={<><i className="me-1 far fa-times"/> {t("Cancel")}</>} />
                    <Button isSolid label={t("Yes, Continue")} clickAction={props.onSuccess} />
                </div>
            </div>
        </>
    );

};

export default ClassDatePast;
