import * as React from "react";
import { useNavigate } from "react-router-dom";

import { ArrowLinkObject } from "../../Interfaces/Platform/ArrowLink";

import { isExternalURL } from "../../helper-functions";

const ArrowLink = (props: {
    data:ArrowLinkObject
}): JSX.Element => {

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(props.data.href)) {
            event.preventDefault();
            navigate(props.data.href);
        }
    };

    return (
        <>
            <div className="arrow-link fs16 fw600 color-blue-darker">
                <a onClick={handleClick} className="arrow-link-text" target={ (props.data.openInNewTab) ? "_blank" : "" } href={props.data.href}>{props.data.label}</a>
            </div>
        </>
    );

};

export default ArrowLink;
