import { Course, CourseEditionModel } from "../../Interfaces";
import { getToken } from "../../helper-functions";

export const createCourse = async (configContext: any, formData: FormData, setValidationErrors: any): Promise<any|undefined> => {
    try {

        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/C/Curriculum/CreateCourse", {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: formData
        });


        if(response.status === 400) {
            const validationError = await response.json()            
            setValidationErrors(validationError)
            return
        } else if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        return { Message : e.Message };
    }
}

export const updateCourse = async (configContext: any, formData: FormData, setValidationErrors: any) => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/C/Curriculum/UpdateCourse", {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: formData
        });

        if(response.status === 400) {
            const validationError = await response.json()
            setValidationErrors(validationError)
            return
        } else if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        return { Message : e.Message };
    }
}

export const fetchCourse = async (configContext: any, courseId: string): Promise<Course|undefined> => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/Q/Curriculum/CourseById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                "courseId": courseId,
            })
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchCourseByCourseObjectId = async (configContext: any, courseObjectId: string): Promise<Course | undefined> => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/Q/Curriculum/CourseByCourseObjectId", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                "courseObjectId": courseObjectId,
            })
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();

    } catch (e) {
        console.error(e);
    }
}

export const fetchRegions = async (configContext: any) => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/Q/Curriculum/Regions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({})
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const createCourseEdition = async (configContext: any, payload: CourseEditionModel): Promise<any|undefined> => {
    try {
        const response = await fetch(configContext?.SystemConfiguration?.ApimBaseUrl + "/C/Curriculum/CreateEditionCourse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`,

            },
            body: JSON.stringify(payload),
        });

        return response;
        
    } catch (e) {
        console.error(e);
        return { message : e.Message };
    }
}

export const fetchSpecialties = async (configContext: any, culture: string | null) => {
    try {
        const url = `${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Curriculum/Specialties${culture ? '?culture=${culture}' : ''}`; 
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchSpecialtyQualifications = async (configContext: any, specialtyId: string) => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Curriculum/SpecialtyQualifications/${specialtyId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}