import "./invoice-accordions.scss";

import * as React from "react";

import { Accordion, Button, ButtonGroup, Card, Col, Container,
         Dropdown, DropdownButton, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ConditionalOverlay } from "../../Components";
import SeatAdjustmentInfoModal from "./seat-adjustment-info-modal";
import { ClassSeatUsageModel, OrganizationInvoiceModel, SeatAdjustmentModel } from "../../Interfaces";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import Table from "../../Components/custom-table";

import { CanShow } from "../../rbac-context";
import { InvoiceStatus } from "../../Enums/InvoiceStatus";
import { OrgSeatAdjustmentEnum } from "../../Enums";
import { formatDate } from "../../locale-utils";
import { useTranslation } from "react-i18next";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { fetchSeatsUsage } from "../Utils/class";
import { ConfigContext } from "../../configuration-context";
import { RbacContext } from "../../rbac-context";

import { RoleEnum } from "../../Enums";

export interface InvoiceAccordionsProps {
    invoices: OrganizationInvoiceModel[];
    showAdminFeatures: boolean;
    handleExpirationModalShow?: (orgSeatId: string, currentExpiratonDate: string) => void;
    handleRemoveModalShow?: (orgSeat: OrganizationInvoiceModel) => void;
    filter?: InvoiceStatus;
}

const InvoiceAccordions = ({
    invoices,
    showAdminFeatures,
    handleExpirationModalShow,
    handleRemoveModalShow,
    filter
}: InvoiceAccordionsProps) => {
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { t } = useTranslation();

    const [accordionStates, setAccordionStates] = React.useState<{ [id: number]: boolean }>({});
    const [invoicesState, setInvoicesState] = React.useState<OrganizationInvoiceModel[]>(invoices);


    const getInvoiceStatus = (invoice: OrganizationInvoiceModel) => {
        // First check whether or not the invoice is expired
        if (invoice.isExpired) {
            return InvoiceStatus.Expired;
        // If not, default to Active for now, later we'll implement Adjusted / Removed
        } else {
            return InvoiceStatus.Active;
        }
    };

    const checkSeatsUsageData = async (invoice: OrganizationInvoiceModel) => {
        if((invoice.classSeatUsageModels?.length ?? 0) === 0){
            const classSeatUsageModels = await fetchSeatsUsage(apimBaseUrl, configContext, invoice.seatPoolId);
            invoice.classSeatUsageModels = classSeatUsageModels;
            setInvoicesState([...invoicesState]);          
        }                                          
    }

    return (
        <>
            {invoicesState.map((invoice) => {
                const numMissingEnrollments = GetNumMissingEnrollments(invoice);
                const status = getInvoiceStatus(invoice);

                let removeInvoiceTooltip = t("Remove Seats");
                if (!invoice.active) removeInvoiceTooltip = t("Invoice is already removed");
                else if (invoice.isExpired) removeInvoiceTooltip = t("Cannot remove an expired invoice");
                else if (invoice.seatsRemaining == 0) removeInvoiceTooltip = t("There are no seats to remove");

                if (!filter || filter === 0 as InvoiceStatus || status === filter) {
                    return (
                        <Accordion key={invoice.seatPoolId}>
                            <Accordion.Item eventKey={invoice.seatPoolId}>
                                <Accordion.Header
                                    className="invoice-accordion"
                                    onClick={ () => {
                                        checkSeatsUsageData(invoice)
                                        accordionStates[invoice.seatPoolId] = !accordionStates[invoice.seatPoolId];
                                        setAccordionStates({ ...accordionStates });
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <Col className="col-auto m-auto">
                                                {accordionStates[invoice.seatPoolId] ? (
                                                    <i className="fas fa-minus"></i>
                                                ) : (
                                                    <i className="fas fa-plus"></i>
                                                )}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Invoice")}:</b>
                                                </div>
                                                {invoice.invoiceNumber || showAdminFeatures
                                                    ? invoice.invoiceNumber
                                                    : t("Not Available")}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Status")}:</b>
                                                </div>
                                                {InvoiceStatus.toString(status)}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Transaction Date")}:</b>
                                                </div>
                                                {formatDate(invoice.transactionDate)}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Expiration Date")}:</b>
                                                </div>
                                                {!showAdminFeatures && invoice.isExpiring && (
                                                    <span>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-expiring-seats">
                                                                    {t("This invoice expires soon")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="far fa-exclamation-circle text-warning" />
                                                        </OverlayTrigger>
                                                    </span>
                                                )}
                                                {formatDate(invoice.expirationDate)}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Total Seats")}:</b>
                                                </div>
                                                {invoice.seatsPurchased}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>
                                                        {invoice.isExpired ? t("Expired") : t("Remaining")}:
                                                    </b>
                                                </div>
                                                {invoice.isExpired ? invoice.expiredSeats : invoice.seatsRemaining}
                                            </Col>
                                            <Col>
                                                <div>
                                                    <b>{t("Used")}:</b>
                                                </div>
                                                {invoice.seatsUsed}
                                            </Col>
                                            {showAdminFeatures && (

                                            <Col>
                                                <ButtonGroup className="w-100">
                                                    <Button
                                                        variant="outline-primary"
                                                        style={{ width: "100%" }}
                                                        // Disable button if user is not a StaffSeatAdjustor
                                                        disabled={rbac.userContext.RolesByName?.indexOf(RoleEnum.StaffSeatAdjustor) == 0}
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                                                        ) => {
                                                            e.stopPropagation();
                                                            handleExpirationModalShow(
                                                                invoice.seatPoolId,
                                                                formatDate(invoice.expirationDate)
                                                            );
                                                        }}
                                                    >
                                                        <i className="fas fa-edit"></i> {t("Edit")}
                                                    </Button>
                                                    <DropdownButton
                                                        variant="outline-primary"
                                                        title=""
                                                        as={ButtonGroup}
                                                        disabled={!invoice.active}
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                                                        ) => e.stopPropagation()}
                                                    >
                                                        <ConditionalOverlay
                                                            condition={
                                                                invoice.seatsRemaining == 0 ||
                                                                invoice.isExpired
                                                            }
                                                            overlay={
                                                                <Tooltip id="expiredInvoiceTooltip">
                                                                    {removeInvoiceTooltip}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>
                                                                <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={(
                                                                        e: React.MouseEvent<
                                                                            HTMLButtonElement,
                                                                            MouseEvent
                                                                        >
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleRemoveModalShow(invoice);
                                                                    }}
                                                                    disabled={
                                                                        !invoice.active ||
                                                                        invoice.isExpired ||
                                                                        invoice.seatsRemaining == 0
                                                                    }
                                                                >
                                                                    {t("Remove")}
                                                                </Dropdown.Item>
                                                            </span>
                                                        </ConditionalOverlay>
                                                    </DropdownButton>
                                                </ButtonGroup>
                                             </Col>

                                            )}
                                        </Row>
                                    </Container>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body>
                                        {showAdminFeatures && (
                                            <AdjustmentHistory seatAdjustments={invoice.seatAdjustments ?? []} />
                                        )}
                                        <ClassHistory
                                            classSeats={invoice.classSeatUsageModels ?? []}
                                            numMissingEnrollments={numMissingEnrollments}
                                        />
                                    </Card.Body>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    );
                }
            })}
        </>
    );
};

const AdjustmentHistory = ({ seatAdjustments }: { seatAdjustments: SeatAdjustmentModel[] }) => {
    const { t } = useTranslation();
    const [showReasonModal, setShowReasonModal] = React.useState(false);
    const [selectedAdjustment, setSelectedAdjustment] = React.useState<SeatAdjustmentModel>(null);

    const typeToString = (type: OrgSeatAdjustmentEnum) => {
        switch (type) {
            case OrgSeatAdjustmentEnum.Expiration:
                return t("Expiration");
            case OrgSeatAdjustmentEnum.Remove:
                return t("Remove");
            default:
                return t("Partial Remove");
        }
    };

    const columnHelper = createColumnHelper<SeatAdjustmentModel>()
    const columns = React.useMemo<ColumnDef<SeatAdjustmentModel>[]>(
        () => [
            columnHelper.accessor('modifyUserName', {
                cell: info => info.getValue(),
                header: t("User"),
                enableSorting: true,
            }),
            columnHelper.accessor('adjustmentDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Adjustment Date"),
                enableSorting: true,
            }),
            columnHelper.accessor('type', {
                cell: info => info.getValue()?.name ?? "",
                header: t("Adjustment Type"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle"}
            }),
            columnHelper.accessor(row => row.type.value > 1 ? row.newValue - row.oldValue : formatDate(row.newValue), {
                cell: info => info.getValue(),
                header: t("Change"),
                enableSorting: true,
                meta: { bodyClass: "text-truncate align-middle" }
            }),
            columnHelper.accessor('requestorName', {
                cell: info => info.getValue(),
                header: t("Requestor"),
                enableSorting: true,
            }),
            columnHelper.accessor('reason', {
                cell: info => info.getValue(),
                header: t("Reason"),
                enableSorting: true,
            }),
            columnHelper.accessor('orgSeatAdjustmentId', {
                cell: info => actionsColumn(info.row.original),
                header: "",
                enableSorting: true,
                meta: {bodyClass: "float-end"}
            }),
        ],
        []
    );

    const actionsColumn = (row: SeatAdjustmentModel) => {

        return (
            <OverlayTrigger overlay={<Tooltip id="reasonToolTip">{t("View Reason")}</Tooltip>}>
                <Button
                    variant="outline-primary"
                    style={{ width: "100%" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setSelectedAdjustment(row);
                        setShowReasonModal(true);
                    }}
                >
                    <i className="fal fa-file-search"></i>{t("View")}
                </Button>
            </OverlayTrigger>
        );
    }

    return (
        <>
            <div>
                <h4>{t("Adjustment History")}</h4>
                <Table
                    fetchData={false}
                    defaultData={seatAdjustments}
                    keyField="OrgSeatAdjustmentId"
                    columns={columns}
                    enablePaging={false}
                    enableSearch={false}
                />
            </div>

            {
                showReasonModal && (
                    <SeatAdjustmentInfoModal
                        title={t("Seat Adjustment")}
                        data={selectedAdjustment}
                        isVisible={showReasonModal}
                        onHide={() => setShowReasonModal(false)}
                    />
                )
            }
        </>
    );

};

const ClassHistory = ({
    classSeats,
    numMissingEnrollments
}: {
    classSeats: ClassSeatUsageModel[];
    numMissingEnrollments: number;
}) => {
    const { t } = useTranslation();

    let seatsUsedLabel = t("Seats Used");
    let classFooter = "";
    let organizationFooter = "";
    let enrolledLearnersFooter = "";

    if (numMissingEnrollments > 0) {
        seatsUsedLabel += "*";
        classFooter = t("Not Available") + "*";
        organizationFooter = t("Not Available") + "*";
        enrolledLearnersFooter = numMissingEnrollments.toString();
    }

    const columnHelper = createColumnHelper<ClassSeatUsageModel>()
    const columns = React.useMemo<ColumnDef<ClassSeatUsageModel>[]>(
        () => [
            columnHelper.accessor('className', {
                cell: info => info.getValue() ?? "No Class Name",
                header: t("Class"),
                enableSorting: true,
                footer: classFooter,
            }),
            columnHelper.accessor('organizationName', {
                cell: info => info.getValue() ?? "No Org Name",
                header: t("Organization"),
                enableSorting: true,
                footer: organizationFooter,
            }),
            columnHelper.accessor('enrolledLearners', {
                cell: info => info.getValue(),
                header: seatsUsedLabel,
                enableSorting: true,
                footer: enrolledLearnersFooter,
            }),
        ],
        []
    );

    return (
        <div>
            <h4>{t("Class History")}</h4>
            <Table
                fetchData={false}
                defaultData={classSeats}
                keyField="classId"
                columns={columns}
                enablePaging={false}
                enableSearch={false}
            />
        </div>
    );
};

function GetNumMissingEnrollments(invoice: OrganizationInvoiceModel) {
    let expectedNumSeatsUsed = invoice.seatsUsed;
    let actualNumSeatsUsed = 0;
    invoice.classSeatUsageModels?.map((csUsageModel) => {
        actualNumSeatsUsed += csUsageModel.enrolledLearners;
    });
    return expectedNumSeatsUsed - actualNumSeatsUsed;
}

export default withAITracking(reactPlugin, InvoiceAccordions);
