import * as React from "react";
import { useTranslation } from "react-i18next";

const AdminPage = () => {
    const { t } = useTranslation();
    
    return (
       <>teh admin</>
    );
};

export default AdminPage;
