import { ApiResponse, GET, GETWITHERROR, POST, POSTFORM, PUTFORM } from ".";
import { template } from "../helper-functions";
import { Course, CourseObject, CourseSkuModel, SelectModel } from "../Interfaces";

const FETCH_MULTI_SCO_COURSES_URL = "/Courses/GetMultiScoCoursesAsync";
const FETCH_MULTI_SCO_COURSE_URL_TEMPLATE = template`/Courses/GetMultiScoCourseAsync?courseId=${0}`;
const FETCH_MULTI_SCO_COURSE_THUMBNAIL_URL_TEMPLATE = template`/Courses/GetMultiScoCourseThumbnailAsync?courseId=${0}`;
const POST_MULTI_SCO_COURSE_URL = "/Courses/CreateMultiScoCourseAsync";
const PUT_MULTI_SCO_COURSE_URL = "/Courses/UpdateMultiScoCourseAsync";
const POST_MULTI_SCO_COURSEOBJECT_URL = "/Courses/CreateMultiScoCourseObjectAsync";
const PUT_MULTI_SCO_COURSEOBJECT_URL = "/Courses/UpdateMultiScoCourseObjectAsync";

const FETCH_COURSE_SKUS_URL = "/Courses/GetCourseSkusAsync";

const FETCH_COURSE_LANGUAGES_URL = "/Courses/GetCourseLanguagesAsync";

const FETCH_MARKET_TYPES_URL = "/Courses/GetMarketTypesAsync";
const FETCH_INDUSTRIES_URL = "/Courses/GetIndustriesAsync";

const FETCH_COURSE_OBJECTS_URL = template`/Courses/GetCourseObjectsAsync?courseId=${0}`;
const FETCH_COURSE_OBJECT_URL = template`/Courses/GetCourseObjectAsync?courseObjectId=${0}`;

const POST_QUICK_ADD_SEATS = "/Courses/QuickAddCourseSeatsAsync";

export async function fetchMultiScoCourses(): Promise<Course[]> {
    const resp = await GETWITHERROR<Course[]>(FETCH_MULTI_SCO_COURSES_URL);
    return resp.Data;
}

export async function fetchMultiScoCourse(courseId: number): Promise<Course> {
    const resp = await GET(FETCH_MULTI_SCO_COURSE_URL_TEMPLATE(courseId));
    if (resp.status === 404) {
        return null;
    }
    return await resp.json();
}

export async function fetchCourseObjects(courseId: number): Promise<CourseObject[]> {
    const resp = await GET(FETCH_COURSE_OBJECTS_URL(courseId));
    return await resp.json();
}

export async function fetchCourseObject(courseObjectId: number): Promise<CourseObject> {
    const resp = await GETWITHERROR<CourseObject>(FETCH_COURSE_OBJECT_URL(courseObjectId));
    return resp.Data;
}

export async function fetchMultiScoCourseThumbnail(courseId: number): Promise<Blob> {
    const resp = await GET(FETCH_MULTI_SCO_COURSE_THUMBNAIL_URL_TEMPLATE(courseId));
    return await resp.blob();
}

export async function createMultiScoCourse(
    course: Course,
    thumbnail: File
): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    formData.append("course", JSON.stringify(course));
    const resp = await POSTFORM(POST_MULTI_SCO_COURSE_URL, formData);
    return resp.ok ? await resp.json() : { Success: false, Message: resp.statusText };
}

export async function createMultiScoCourseObject(
    courseObject: CourseObject,
    file: File,
    previewFile: File
): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("previewFile", previewFile);
    formData.append("courseObject", JSON.stringify(courseObject));
    const resp = await POSTFORM(POST_MULTI_SCO_COURSEOBJECT_URL, formData);
    return resp.ok ? await resp.json() : { Success: false, Message: resp.statusText };
}

export async function updateMultiScoCourse(
    course: Course,
    thumbnail: File
): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    formData.append("course", JSON.stringify(course));
    const resp = await PUTFORM(PUT_MULTI_SCO_COURSE_URL, formData);
    return resp.ok ? await resp.json() : { Success: false, Message: resp.statusText };
}

export async function updateMultiScoCourseObject(
    courseObject: CourseObject,
    file: File,
    previewFile: File
): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("previewFile", previewFile);
    formData.append("courseObject", JSON.stringify(courseObject));
    const resp = await PUTFORM(PUT_MULTI_SCO_COURSEOBJECT_URL, formData);
    return resp.ok ? await resp.json() : { Success: false, Message: resp.statusText };
}

export async function fetchCourseSkus(): Promise<CourseSkuModel[]> {
    const resp = await GET(FETCH_COURSE_SKUS_URL);
    return await resp.json();
}

export async function fetchCourseLanguages(): Promise<SelectModel[]> {
    const resp = await GET(FETCH_COURSE_LANGUAGES_URL);
    return await resp.json();
}

export async function fetchMarketTypes(): Promise<SelectModel[]> {
    const resp = await GET(FETCH_MARKET_TYPES_URL);
    return await resp.json();
}

export async function fetchIndustries(): Promise<SelectModel[]> {
    const resp = await GET(FETCH_INDUSTRIES_URL);
    return await resp.json();
}

export async function postQuickAddSeats(courseId: number): Promise<ApiResponse> {
    const resp = await POST(POST_QUICK_ADD_SEATS, {
        courseId: courseId
    });
    return await resp.json();
}
