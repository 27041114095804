import * as React from "react";

const Radio = (props: {
    label: string | React.ReactElement,
    value: string,
    name: string,
    id: string,
    checked?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}): JSX.Element => {

    return (
        <>        
            <div className="d-flex mb-2">
                
                { !props.checked ? <input onChange={props.onChange} className="form-radio" id={props.id} value={props.value} name={props.name} type="radio"></input> : 
                    <input onChange={props.onChange} className="form-radio" id={props.id} value={props.value} name={props.name} checked={props.checked} type="radio"></input>
                }
                
                <label className="form-radio-label mb-0" htmlFor={props.id}>
                    <span>{props.label}</span>
                </label>
            </div>
        </>
    );
};

export default Radio;
