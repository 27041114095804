import * as React from "react";

const CPILockup = (props: {

}): JSX.Element => {

    return (
        <>
            <a href="/Dashboard" className="navbar-branding"><span className="sr-only">Crisis Prevention Institute</span></a>
        </>
    );
};

export default CPILockup;
