import * as React from "react";


import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../Components/remote-table";
import { Breadcrumb, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Learner, LearnerHistoryViewModel } from "../Interfaces";
import { Link, useParams } from "react-router-dom";
import { fetchLearner, fetchLearnerHistory } from "../ApiServices/Learner";

import { InstructorBaseRoutes } from "../Routing/routes";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { StatusEnum } from "../Enums";
import SyncMyCoursesComponent from "./Components/sync-my-courses-component";
import { formatDate } from "../locale-utils";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const LearnerHistoryPage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const params = useParams<{ userId: string }>();
    const userId = parseInt(params.userId);
    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [learner, setLearner] = React.useState<Learner>(null);
    const [history, setHistory] = React.useState<LearnerHistoryViewModel[]>([]);

    React.useEffect(() => {
        const getLearner = async () => {
            try {
                const result = await fetchLearner(userId);
                if (result.Error) {
                    setErrored(true);
                    document.title = t("Learning History");
                } else {
                    setLearner(result.Data);
                    document.title = `${result.Data.FirstName} ${result.Data.LastName} - ${t("Learning History")}`;
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
            }
        };

        const getHistory = async () => {
            try {
                const result = await fetchLearnerHistory(userId);
                if (result.Error) {
                    setErrored(true);
                } else {
                    setHistory(result.Data);
                }
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
                setLoading(false);
            }
        };

        getLearner();
        getHistory();
    }, []);

    const renderCertificateLink = (cell: number, row: LearnerHistoryViewModel) => {
        return row.Status === "Completed" ? (
            <OverlayTrigger
                overlay={<Tooltip id={`view-cert-${row.LearnerCourseId}`}>{t("View Certificate")}</Tooltip>}
            >
                <a
                    className="text-link"
                    title={t("View Certificate")}
                    href={`/Learn/ViewCertificate/${cell}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={t("View")}
                >
                    {t("View")}
                </a>
            </OverlayTrigger>
        ) : null;
    };

    const handleOnSynced = async () => {
        const result = await fetchLearnerHistory(userId);
        if (result.Error) {
            setErrored(true);
        } else {
            setHistory(result.Data);
        }
    };

    const classNameColumn = (className: string, classId: number) => {
        return (
            <Link
                className="text-link"
                title={className}
                to={`/Class/Manage/${classId}`}
                aria-label={t("Class")}
            >
                {className}
            </Link>
        );
    }

    const columnHelper = createColumnHelper<LearnerHistoryViewModel>()
    const columns = React.useMemo<ColumnDef<LearnerHistoryViewModel>[]>(
        () => [
            columnHelper.accessor('CourseName', {
                cell: info => info.getValue(),
                header: t("Course Name"),
                enableSorting: true,
                size: 20
            }),
            columnHelper.accessor('ClassName', {
                cell: (info) => classNameColumn(info.getValue(), info.row.original.ClassId),
                header: t("Class Name"),
                enableSorting: true,
                size: 20
            }),
            columnHelper.accessor('EnrollmentDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Enrollment Date"),
                enableSorting: true,
                size: 10
            }),
            columnHelper.accessor('ExpirationDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Expiration Date"),
                enableSorting: true,
                size: 10
            }),
            columnHelper.accessor('CompletionDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Completion Date"),
                enableSorting: true,
                size: 10
            }),
            columnHelper.accessor('Status', {
                cell: info => StatusEnum.translateString(info.getValue()),
                header: t("Status"),
                enableSorting: true,
                size: 10
            }),
            columnHelper.accessor('ContactHours', {
                cell: info => info.getValue(),
                header: t("Contact Hours"),
                enableSorting: true,
                size: 7
            }),
            columnHelper.accessor('Score', {
                cell: info => info.getValue(),
                header: t("Score"),
                enableSorting: true,
                size: 6
            }),
            columnHelper.accessor('LearnerCourseId', {
                cell: (info) => renderCertificateLink(info.getValue(), info.row.original),
                header: t("Certificate"),
                enableSorting: false,
                size: 7,
                enableGlobalFilter: false
            }),
        ],
        []
    );

    if (loading || errored || learner === null) return <PageMessages loading={loading || !learner} errored={errored} />;

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h2 style={{ display: "inline-flex" }}>
                    {learner.FirstName} {learner.LastName} - {t("Learning History")}
                </h2>
                <SyncMyCoursesComponent pageLoad={loading} onSynced={handleOnSynced} />
            </div>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: InstructorBaseRoutes.Learner.Learner.fullPath }}>
                    {t("Manage Learners")}
                </Breadcrumb.Item>
                {learner && (
                    <Breadcrumb.Item active>
                        {learner.FirstName} {learner.LastName}
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
            <RemoteTable
                keyField="LearnerCourseId"
                fetchData={false}
                columns={columns}
                defaultData={history}
                defaultSorted={{ dataField: "EnrollmentDate", order: "desc" }}
                enablePaging={false}
                enableSearch={false}
            />
        </>
    );
};

export default withAITracking(reactPlugin, LearnerHistoryPage);
