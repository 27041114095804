import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const CeBoardApproval = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
              <Container fluid className="page-padding">
                <OneColumnLayout
                  marginTop={false}
                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <h1 className="page-title-h1 title-margin">{ t('CEBoardApproval-Title') }</h1>
                            <p>{ t('CEBoardApproval-Intro') }</p>
                            <Tile title={ t('CEBoardApproval-Submission-Header') } hideTileHeader={false}>
                            <>
                        {/* WRITE HTML BELOW THIS LINE */}
                            <TwoColumnLayout
                    left={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <span className="parsed-text">{ tp('CEBoardApproval-SubmissionProcess') }</span>
                        </>
                    }

                    right={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <span className="parsed-text">{ tp('CEBoardApproval-Contact') }</span>
                        </>
                    }
                />
              
                
                    </>      
                </Tile>
                <Tile title="Submission Packets" hideTileHeader={false}>
                    <>
                        {/* WRITE HTML BELOW THIS LINE */}
                        <ThreeColumnLayout
                    left={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <span className="parsed-text">{ tp('CEBoardApproval-Packet') }</span>
                        </>
                    }

                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') } with { t('APS') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition') , href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/APS3E_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CE-Packet_APS2E-2.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-APS-QST-0140-GEN-0222-US-E.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') } with { t('APS') }: { t('ASD') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/APS3E_ASD_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') } with { t('APS') }: { t('TRA') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/APS3E_TRA_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') } with { t('APS') }: { t('MH') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/APS3E_MH_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI3E_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI2E_CE-Submission-Packet-8.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-NCI-QST-0133-GEN-0222-US-E.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') }: { t('ASD') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI3E_ASD_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI2E_ASD-CE-Submission-Packet-2.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-ASD-QST-0141-GEN-0122-US-E.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') }: { t('TRA') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI3E_TRA_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI2E_Trauma-CE-Submission-Packet-Updated-3_22.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('NCI') }: { t('MH') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/NCI3E_MH_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                        </>
                    }

                    right={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('VI') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/VI3E_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CE-Packet_VI2E.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-VI-QST-0143-GEN-0122-US.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('VI') }: { t('ASD') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/VI3E_ASD_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('VI') }: { t('TRA') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/VI3E_TRA_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('VI') }: { t('MH') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-3rd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/VI3E_TRA_CE_SubmissionPacket.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-3rd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                            <GrayTile percentWidth={100}>
                            <>
                              {/* WRITE HTML BELOW THIS LINE */}
                                <h3 className="sub-headings-h3">{ tp('DCC') }</h3>
                                <ArrowLink data={{ label:  t('BTN-Submission-Packet-2nd-Edition'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/DCC2e-CE-Submission-Packet-5.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-2nd-Edition'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/_CPI-DCC-QST-0146-GEN-0322-US-Foundation.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Behaviors'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-DCC-QST-0144-GEN-0322-US_Behaviors.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Behaviors'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Submission-Packet-Foundation-Behaviors'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/CPI-DCC-QST-0145-GEN-0322-US-Foundations-and-Behaviors.pdf", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Authorization-Letter-Foundation-Behaviors'), href: "URL", openInNewTab: true  }} />
                                <ArrowLink data={{ label: t('BTN-Participant_Roster'), href: "https://cdn.crisisprevention.com/media/training-center/ce-submission-packets/Participant-Roster.xlsx", openInNewTab: true  }} />
                              </>
                            </GrayTile>
                            <p></p>
                          </>
                        }
                      />    
                    </>      
                  </Tile>

                  </>
                }
              />
            </Container>
          </div>
        </>
    );
};

export default CeBoardApproval;
