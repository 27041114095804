import { ApiResponse, DELETE, GETWITHERROR, JsonResponseModel, POSTFORM, POSTFORMWITHERROR, PagingOptions, GETPAGED, PUT, handleError, PagedResult } from ".";
import { BulkUploadResponse, BulkUploadRow, Learner, LearnerHistoryViewModel, UpdateLearnerModel } from "../Interfaces";

import { template } from "../helper-functions";

const FETCH_LEARNER_URL = template`/Learner/GetLearnerAsync?userId=${0}`;
const FETCH_LEARNER_HISTORY_URL = template`/Learner/GetLearnerHistoryAsync?userId=${0}`;
const PUT_LEARNER_DETAILS_URL = template`/Learner/UpdateLearnerAsync?userId=${0}`;
const DELETE_LEARNER_URL_TEMPLATE = template`/Learner/DeactivateLearnerAsync?userId=${0}`;
const POST_BULK_UPLOAD_URL = "/Learner/UploadFileAsync";
const POST_GENERATE_UPLOAD_PREVIEW = "/Learner/GenerateUploadPreview";
const FETCH_ACTIVE_LEARNERS_URL = "/Learner/GetActiveLearnersByOrganizationAsync";


export async function fetchActiveLearners(pagingOptions: PagingOptions): Promise<PagedResult<Learner>> {
    const resp = await GETPAGED<PagedResult<Learner>>(FETCH_ACTIVE_LEARNERS_URL, pagingOptions);
    return resp.Data;
}

export async function fetchLearner(userId: number): Promise<JsonResponseModel<Learner>> {
    return GETWITHERROR(FETCH_LEARNER_URL(userId));
}

export async function fetchLearnerHistory(userId: number): Promise<JsonResponseModel<LearnerHistoryViewModel[]>> {
    return GETWITHERROR(FETCH_LEARNER_HISTORY_URL(userId));
}

export async function updateLearner(learner: UpdateLearnerModel): Promise<JsonResponseModel<ApiResponse>> {
    return PUT(PUT_LEARNER_DETAILS_URL(learner.UserId), learner);
}

export async function removeLearnerAsync(userId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_LEARNER_URL_TEMPLATE(userId));
}

export function bulkUpload(
    organizationId: number,
    file: File
): [Promise<JsonResponseModel<BulkUploadResponse>>, AbortController] {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("organizationId", organizationId.toString());

    const controller = new AbortController();
    const signal = controller.signal;
    const fetchCall = POSTFORM(POST_BULK_UPLOAD_URL, formData, signal).then(async (resp) =>
        handleError<BulkUploadResponse>(resp)
    );
    return [fetchCall, controller];
}

export async function generateBulkUploadPreview(file: File): Promise<JsonResponseModel<BulkUploadRow[]>> {
    const formData = new FormData();
    formData.append("file", file);

    return POSTFORMWITHERROR(POST_GENERATE_UPLOAD_PREVIEW, formData);
}
