import { EMAIL_REGEX, PASSWORD_REGEX } from "./constants";

import Cookie from "universal-cookie";
import { RoleEnum } from "./Enums";
import { UserModel, UserContext } from "./Interfaces";
import * as jwt_decode from 'jwt-decode';
import UserTokenInfo from "./Interfaces/UserTokenInfo";
import { SupportBaseRoutes } from "./Routing/routes";

export function template(strings: TemplateStringsArray, ...keys: (string | number)[]) {
    return function (...values: any[]) {
        let dict = values[values.length - 1] || {};
        let result = [strings[0]];
        keys.forEach(function (key: any, i: number) {
            let value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join("");
    };
}

export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}

export function isNullOrEmpty(value: string): boolean {
    return !value || 0 === value.length;
}

export function convertBytesToFile(bytes: number[], filename: string): File {
    const blob = new Blob([Buffer.from(bytes)]);
    //@ts-ignore
    blob["name"] = filename;
    return blob as File;
}

/**
 * Sourced from SO: https://stackoverflow.com/questions/49986720/how-to-detect-internet-explorer-11-and-below-versions
 */
export function isIE() {
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf("MSIE "); // IE 10 or older
    const trident = ua.indexOf("Trident/"); //IE 11

    return msie > 0 || trident > 0;
}

export function isCertifiedInstructor(user: UserModel) {
    return (user.Roles) ? user.Roles.some((role) => role === RoleEnum.CertifiedInstructor) : false;
}

export function isBusinessAdministrator(user: UserContext) {
    return (user.Roles) ? user.RolesByName?.some((role) => role === RoleEnum.BusinessAdministrator) : false;
}

export function isCertInstructor(user: UserContext) {
    return (user.Roles) ? user.RolesByName?.some((role) => role === RoleEnum.CertifiedInstructor) : false;
}

export function isOnlyLearner(user: UserContext) {
    return (user.RolesByName) ? user.RolesByName.every((role) => role === RoleEnum.Learner) : false;
}

export function isStaffMember(user: UserModel) {
    return (user.Roles) ? user.Roles.some((role) => role === RoleEnum.ParticipantAdmin || role === RoleEnum.Administrator || role === RoleEnum.StaffSeatAdjustor || role === RoleEnum.StaffSeatReadOnly || role === RoleEnum.Developer) : false;
}

export function isValidEmail(email: string) {
    return EMAIL_REGEX.test(email);
}

export function isValidPassword(password: string) {
    return PASSWORD_REGEX.test(password);
}

export function enumToString(key: string) {
    return key?.replace(/([A-Z])/g, " $1").trim();
}

export function isAuthenticated() {
    const cookie = new Cookie();
    return !!cookie.get("CurrentOrgContext");
}

export function getToken(tokenName: string) {
    const cookie = new Cookie();
    let token = cookie.get(tokenName);
    return token;
}

// Setting Partial return to remove linting error due to possible empty object return
export function getUserClaims(): Partial<UserTokenInfo>
{
    var idToken = getToken("idToken");
    const decodedToken = (idToken) ? jwt_decode.jwtDecode(idToken) as UserTokenInfo : {};   

    //console.log(" :: JWT :: ", decodedToken);
    
    return decodedToken;
}

export function isExternalURL(url: string):boolean {
    // If the URL is a logout URL, it is considered external - it hits a web api to trigger the ping logout flow
    if (url === SupportBaseRoutes.Logout.fullPath) {
        return true;
    }

    try {
        const urlObject = new URL(url);
        return Boolean(urlObject.protocol && urlObject.host);
    } catch (error) {
        // If the URL constructor throws an error, the URL is not valid and therefore not external
        return false;
    }
}