import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../configuration-context";

import Tile from "../Components/UI/tile";
import SubHeaderBar from "../Components/UI/Content-Pages/subheader-bar";
import OneColumnLayout from "../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../Components/UI/UX-Layout/Tiles/three-column-layout";

import SixBySix from "../Components/UI/UX-Layout/Content/six-by-six";
import FacilitatingStrategyBlock from "../../ClientApp/Components/UI/Content-Pages/facilitating-strategy-block";

const DevPage = () => {
    const { t } = useTranslation();
    
    return (
        <></>
    );
};

export default DevPage;
