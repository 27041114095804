import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RbacContext } from "../rbac-context";
import { ConfigContext } from "../configuration-context";

import useApi from "../hooks/useApi";
import useDetectWindowSize from "../hooks/useDetectWindowSize";
import useCustomTable from "../hooks/useCustomTable";
import useParsedTranslation from "../hooks/useParsedTranslation";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import useFeatureFlags from "../hooks/useFeatureFlags";
import useTrainableSpecialtiesByCert from "../hooks/useTrainableSpecialtiesByCert";

import { getUserClaims, isBusinessAdministrator, isCertInstructor } from "../helper-functions";

import Tile from "../Components/UI/tile";
import BOESelector from "./Components/UI/boe-selector";
import EmptyContentBasic from "../Components/UI/empty-content-basic";
import Alert from "../Components/UI/alert";
import Button from "../Components/UI/button";
import CustomTable from "../Components/custom-table";
import ModalPopUp from "../Components/UI/modal-pop-up";
import CreateParticipant from "../Components/UI/create-participant";
import ViewParticipantDetails from "../Components/UI/view-participant-detail";
import ParticipantList from "./Components/Participants/participant-list";
import ParticipantListing from "./Components/Participants/participant-listing";
import {
    pageSizes,
    returnInstructorsCertifications,
    returnOrganizations,
    returnPrograms
} from "./Components/DashboardPlatformUtils";
import ImportUsingFile from "../Components/UI/Content-Pages/import-using-file";
import ParticipantsSelected from "./Components/Participants/participants-selected";
import EmptyContent from "../Components/UI/empty-content";

import { returnPageLevelAlerts } from "./Utils/alerts";
import { FiltersTemplate, SelectedFilters } from "../Interfaces/Platform/GenericFilter";
import { Pagination } from "../Interfaces/Platform/CustomTable";
import {
    FetchParticipantsPayload,
    FetchParticipantsResponse,
    IParticipant,
    Participant,
    Organization,
    Instructor,
    FetchInstructorsPayload,
    ExportParticipantsPayload
} from "../Interfaces/Platform/Participants";

interface UseCustomTable {
    filters: SelectedFilters;
    setFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>;
    resetFilters: () => void;
    updateFilter: (filter: string, value: string) => void;
    pagination: Pagination;
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
    updateTotal: (total: number) => void;
    updatePage: (page: number) => void;
    updatePageSize: (size: number) => void;
}
import { CustomResponse } from "./Utils/bulk-upload-response";
import jsonToCsvExport from "json-to-csv-export";
import { exportParticipants } from "./Utils/participants";
import { saveAs } from "file-saver";

const ParticipantsPage = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const [canCreateParticipants] = useFeatureFlags("ITLCreateParticipants", true);
    const [canEditParticipants] = useFeatureFlags("ITLEditParticipants", true);
    const [canBulkUploadParticipants] = useFeatureFlags("ITLBulkUploadParticipants", true);
    const [canViewCertificates] = useFeatureFlags("ITLCertificates", true);
    const [canTransferParticipants] = useFeatureFlags("ITLTransferParticipants", true);
    const [canSendEnrollmentEmail] = useFeatureFlags("ITLSendEnrollmentEmails", true);
    const [canEditClassParticipants] = useFeatureFlags("ITLEditClassParticipants", true);
    const [shouldBOESelectorShow] = useFeatureFlags("ITLAltBOESelector", true);
    const [ITLIntermediatePhysicalSkills] = useFeatureFlags("ITLIntermediatePhysicalSkills", "pending");
    const [trainablePrograms] = useTrainableSpecialtiesByCert(ITLIntermediatePhysicalSkills);
    const rbac = React.useContext(RbacContext);
    const isBA = isBusinessAdministrator(rbac?.userContext);
    const isCI = isCertInstructor(rbac?.userContext);
    const shouldShowBOE = rbac.userContext.AltOrganizationsList?.length > 1 && isCI && shouldBOESelectorShow;
    const [canBulkUpdateParticipants] = useFeatureFlags("ITLBulkUpdateParticipants", false);

    const defaultFilters = {
        searchTerm: "",
        sortBy: "date",
        status: "all",
        program: "all",
        expirationDate: "all",
        lastTrained: "all",
        instructor: "all",
        organization: "all"
    };
    const { isMobile } = useDetectWindowSize();

    const configContext = React.useContext(ConfigContext);
    const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [parentToChildTrigger, setParentToChildTrigger] = React.useState(0);
    const [participants, setParticipants] = React.useState<IParticipant[]>([]);
    const [selectedParticipantsIds, setSelectedParticipantsIds] = React.useState<string[]>([]);
    const [showClearFilters, setShowClearFilters] = React.useState<boolean>(false);
    const [programs, setPrograms] = React.useState([]);
    const [showParticipantDetails, setShowParticipantDetails] = React.useState<boolean>(false);
    const [viewEditParticipant, setViewEditParticipant] = React.useState<IParticipant>(null);
    const [headerCheck, setHeaderCheck] = React.useState<boolean>(false);
    const [showCreateParticipant, setShowCreateParticipant] = React.useState<boolean>(false);
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const [clearFiltersTrigger, setClearFiltersTrigger] = React.useState(false);
    const [triggerGetPaginatedData, setTriggerGetPaginatedData] = React.useState(false);
    const [showImportFromFile, setShowImportFromFile] = React.useState<boolean>(false);
    const [orgs, setOrgs] = React.useState<Organization[]>([]);
    const [orgsBranchIds, setOrgsBranchIds] = React.useState<string[]>([]);
    const altOrg = rbac.userContext.SelectedOrganization;
    const {
        filters,
        setFilters,
        pagination,
        updateFilter,
        resetFilters,
        updateTotal,
        updatePage,
        updatePageSize
    }: UseCustomTable = useCustomTable<SelectedFilters>({
        defaultFilters,
        pagination: { current: 1, total: 0, size: 100 }
    });
    const [bulkResult, setBulkResult] = React.useState<CustomResponse>();
    const [bulkKey, setBulkKey] = React.useState(1);
    const culture = getUserClaims()?.culture ?? "en-US";

    const displayBulkImportSuccess = (data: any) => {
        setBulkResult({
            isError: data.rowsError.length > 0,
            message: t("Bulk-Import-Participant-Errors", {
                totalProcessed: data.totalProcessed,
                totalCreated: data.totalCreated,
                totalUpdated: data.totalUpdated
            }),
            statusCode: 200,
            response: data.rowsError
        });

        setShowImportFromFile(false);
        setTimeout(() => handleSearch(), 2000);
    };

    const returnImportFromFileComponent = (): JSX.Element => (
        <ImportUsingFile
            onSuccess={displayBulkImportSuccess}
            onCancel={() => setShowImportFromFile(false)}
            source="participant"
            desiredLms={null}
        />
    );

    React.useEffect(() => {
        fetchOrgs();
        if (isBA) fetchOrgsBranchIds();
    }, [altOrg]);

    const {
        fetchData: fetchOrgs,
        isLoading: isLoadingOrganizations,
        data: organizationsData
    } = useApi<{}, Organization[]>({
        url: `Q/Organization/Tree/Whole/${altOrg}`,
        method: "GET",
        payload: {}
    });

    const {
        fetchData: fetchOrgsBranchIds,
        isLoading: isLoadingOrganizationsBranchIds,
        data: organizationsBranchIdsData
    } = useApi<{}, string[]>({
        url: `Q/Organization/Branch/Ids/${altOrg}`,
        method: "GET",
        payload: {}
    });

    const {
        fetchData: fetchParticipants,
        isLoading: isLoadingParticipants,
        data: participantsData
    } = useApi<FetchParticipantsPayload, FetchParticipantsResponse>({
        url: "/Q/Participant/Search/List",
        method: "POST",
        payload: {
            organizationIds:
                filters.organization === "all"
                    ? [...new Set(orgs.map((o) => o.organizationId))]
                    : [filters.organization],
            searchTerm: filters.searchTerm,
            status: filters.status === "all" ? null : filters.status,
            program: filters.program === "all" ? null : filters.program,
            userId: filters.instructor === "all" ? null : filters.instructor,
            expirationDate: filters.expirationDate === "all" ? null : filters.expirationDate,
            lastTrained: filters.lastTrained === "all" ? null : filters.lastTrained,
            sortBy: filters.sortBy,
            pageNumber: pagination.current,
            resultsPerPage: pagination.size
        }
    });

    React.useEffect(() => {
        setIsLoading(isLoadingParticipants);
    }, [isLoadingParticipants]);

    const {
        fetchData: fetchInstructors,
        isLoading: isLoadingInstructors,
        data: instructors
    } = useApi<FetchInstructorsPayload, Instructor[]>({
        url: "/Q/Certification/Organizations/Instructors",
        method: "POST",
        payload: {
            organizationId: altOrg,
            organizations: organizationsBranchIdsData,
            userId: rbac.userContext.UserId
        }
    });

    const {
        fetchData: fetchPrograms,
        isLoading: isLoadingPrograms,
        data: programsData
    } = useApi<{ userIds: string[] }, string[]>({
        url: "/Q/Certification/Users/Certifications",
        method: "POST",
        payload: {
            userIds: [rbac.userContext.PingId]
        }
    });

    const {
        fetchData: fetchProgramsOrganizations,
        isLoading: isLoadingProgramsOrganizations,
        data: programsOrganizationsData
    } = useApi<{ organizationIds: string[] }, string[]>({
        url: "/Q/Certification/Organizations/Certifications",
        method: "POST",
        payload: {
            organizationIds: organizationsBranchIdsData
        }
    });

    React.useEffect(() => {
        if (ITLIntermediatePhysicalSkills !== "pending") {
            let allTrainablePrograms: string[] = [];
            if (!isBA && Array.isArray(programsData) && programsData.length > 0) {
                setPrograms(programsData.map((program: string) => ({ label: tp(program), value: program })));
                programsData.forEach((program: any) => {
                    let trainableProgramsOnThisSpecialty = trainablePrograms(program, rbac.userContext.Country, false);
                    allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
                });
                setPrograms(
                    [...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program }))
                );
            }
            if (isBA && Array.isArray(programsOrganizationsData) && programsOrganizationsData.length > 0) {
                setPrograms(
                    programsOrganizationsData.map((program: string) => ({ label: tp(program), value: program }))
                );
                programsOrganizationsData.forEach((program: any) => {
                    let trainableProgramsOnThisSpecialty = trainablePrograms(program, rbac.userContext.Country, false);
                    allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
                });
                setPrograms(
                    [...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program }))
                );
            }
        }
    }, [programsData, programsOrganizationsData, ITLIntermediatePhysicalSkills]);

    React.useEffect(() => {
        if (participantsData && participantsData.participants) {
            const { totalResults, participants } = participantsData;
            updateTotal(totalResults);
            setIsEmpty(totalResults === 0);
            setParticipants(
                participants.map((item) => ({
                    ...item,
                    isChecked: selectedParticipantsIds.includes(item.participantId)
                }))
            );

            const selectedIds = participants.map((participant) => participant.participantId);
            const isHeaderCheck = selectedIds.every((selectedParticipant) =>
                selectedParticipantsIds.includes(selectedParticipant)
            );
            setHeaderCheck(totalResults > 0 ? isHeaderCheck : false);
        }
    }, [participantsData]);

    React.useEffect(() => {
        if (organizationsData) setOrgs(organizationsData);
    }, [organizationsData]);

    React.useEffect(() => {
        if (isBA && organizationsBranchIdsData && organizationsBranchIdsData.length > 0) {
            setOrgsBranchIds(organizationsBranchIdsData);
            fetchProgramsOrganizations();
            fetchInstructors();
        }
    }, [organizationsBranchIdsData]);

    const handleDownloadErrorReport = () => {
        const dataToExport = {
            data: bulkResult?.response,
            filename: "participant-exceptions",
            delimiter: ","
        };

        jsonToCsvExport(dataToExport);
    };

    React.useEffect(() => {
        if (clearFiltersTrigger) {
            resetFilters();
            setShowClearFilters(false);
            setClearFiltersTrigger(false);
            setTriggerGetPaginatedData(true);
        }

        if (triggerGetPaginatedData && orgs && orgs.length > 0) {
            updatePage(1);
            fetchParticipants();
            setShowClearFilters(JSON.stringify(filters) !== JSON.stringify(defaultFilters));
            setTriggerGetPaginatedData(false);
        }
    }, [clearFiltersTrigger, triggerGetPaginatedData, orgs]);

    React.useEffect(() => {
        // First data load
        if (!hasLoaded && orgs && orgs.length > 0) {
            fetchParticipants();
            setHasLoaded(true);
        }
    }, [pagination.current, pagination.size, orgs, hasLoaded]);

    React.useEffect(() => {
        // On pagination change data load
        if (hasLoaded && orgs && orgs.length > 0) {
            fetchParticipants();
        }
    }, [pagination.current, pagination.size, orgs]);

    React.useEffect(() => {
        // If data has already been loaded and value on search is cleared
        if (hasLoaded && filters.searchTerm === "") {
            const handler = setTimeout(() => {
                fetchParticipants();
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [filters.searchTerm]);

    React.useEffect(() => {
        // On filters change data load
        if (hasLoaded && orgs && orgs.length > 0) {
            setIsLoading(true);
            const handler = setTimeout(() => {
                fetchParticipants();
                setShowClearFilters(JSON.stringify(filters) !== JSON.stringify(defaultFilters));
            }, 2000);
            return () => clearTimeout(handler);
        }
    }, [
        filters.searchTerm,
        filters.status,
        filters.instructor,
        filters.program,
        filters.expirationDate,
        filters.lastTrained,
        filters.percentageRecorded,
        filters.sortBy,
        filters.organization
    ]);

    React.useEffect(() => {
        if (!isBA) {
            fetchPrograms();
        }
    }, []);

    React.useEffect(() => {
        if (selectedParticipantsIds.length > 0) {
            const selectedIds: string[] = participants.map((participant: Participant) => participant.participantId);
            const isHeaderCheck = selectedIds.every((selectedParticipant) =>
                selectedParticipantsIds.includes(selectedParticipant)
            );
            setHeaderCheck(isHeaderCheck);
        }
    }, [selectedParticipantsIds]);

    const handleHeaderCheck = (isHeaderCheck: boolean): void => {
        setHeaderCheck(isHeaderCheck);
        setParticipants((prevState) => prevState.map((participant) => ({ ...participant, isChecked: isHeaderCheck })));

        const selected = [...participants];
        const selectedIds = selected.map((participant) => participant.participantId);
        if (isHeaderCheck) {
            setSelectedParticipantsIds([...new Set([...selectedIds, ...selectedParticipantsIds])]);
        } else {
            setSelectedParticipantsIds((prevState) =>
                prevState.filter((participantId) => !selectedIds.includes(participantId))
            );
        }
    };

    const handleCheck = (checked: boolean, id: string) => {
        setParticipants((prevState) => {
            const newState = [...prevState];
            const selected = newState.findIndex((participant) => participant.participantId === id);
            newState[selected].isChecked = checked;
            return newState;
        });
        if (checked) {
            setSelectedParticipantsIds((prevState) => [...prevState, id]);
        } else {
            setSelectedParticipantsIds((prevState) => prevState.filter((selected) => selected !== id));
        }
    };

    const handleViewEditParticipant = (participant: IParticipant) => {
        setShowParticipantDetails(true);
        setViewEditParticipant(participant);
    };

    const returnParticipants = () =>
        participants.map((participant) => {
            return (
                <ParticipantListing
                    {...participant}
                    onViewEditParticipant={() => handleViewEditParticipant(participant)}
                    onCheck={handleCheck}
                    canBulkUpdateParticipants={canBulkUpdateParticipants}
                />
            );
        });

    let columns = [
        { id: "name", enableSorting: false, accessorKey: "Name" },
        {
            id: "status",
            enableSorting: false,
            header: () => (
                <div className="d-flex">
                    <div className="participant-listing-header-status">{t("Status")}</div>
                    <div className="participant-listing-header-item">{t("Organization")}</div>
                    <div className="participant-listing-header-item">{t("Blue-Card-Number")}</div>
                    <div className="participant-listing-header-item-last">{t("Last Trained")}</div>
                </div>
            )
        },
        { id: "actions", enableSorting: false, accessorKey: "Actions", header: () => <></> }
    ];

    if (canBulkUpdateParticipants) {
        columns = [
            {
                id: "checkbox",
                enableSorting: false,
                accessorKey: "Checkbox",
                header: () => (
                    <div className="d-flex">
                        <input
                            onChange={(e) => handleHeaderCheck(e.target.checked)}
                            className="form-checkbox participant-listing-checkbox"
                            id="all-check"
                            checked={headerCheck}
                            name=""
                            type="checkbox"
                        />
                    </div>
                )
            },
            ...columns
        ];
    }

    const clearFilters = (customFilters?: SelectedFilters) => {
        customFilters ? setFilters(customFilters) : resetFilters();
        setShowClearFilters(false);
    };

    const getOffsetDate = (months: number): string => {
        const offsetDate = new Date();
        offsetDate.setMonth(offsetDate.getMonth() + months);
        return offsetDate.toISOString().split("T")[0];
    };

    const handleCloseViewEditParticipant = () => {
        setShowParticipantDetails(false);
        setViewEditParticipant(null);
    };

    const handleCancel = (postWasSuccessfull?: boolean) => {
        if (postWasSuccessfull) {
            updatePage(1);
            setParentToChildTrigger((parentToChildTrigger) => parentToChildTrigger + 1);
            setClearFiltersTrigger(true);
            setTimeout(() => fetchParticipants(), 3000);
        }

        setShowCreateParticipant(false);
    };

    const handleSearch = () => {
        updatePage(1);
        setShowClearFilters(JSON.stringify(filters) !== JSON.stringify(defaultFilters));
        return orgs && orgs.length > 0 && fetchParticipants();
    };

    const filterOptions = !isBA
        ? {
              sortByOptions: [
                  { label: t("Date Added"), value: "date" },
                  { label: t("Date Last Trained"), value: "lasttrained" },
                  { label: t("Email"), value: "email" },
                  { label: t("Expiration Date"), value: "expirationdate" },
                  { label: t("First Name"), value: "firstname" },
                  { label: t("Last Name"), value: "lastname" },
                  { label: t("Organization"), value: "organization" }
              ],
              programOptions: [{ label: t("All"), value: "all" }, ...returnPrograms(programs)],
              statusOptions: [
                  { label: t("All"), value: "all" },
                  { label: t("Active"), value: "active" },
                  { label: t("Expired"), value: "expired" },
                  { label: t("None"), value: "none" }
              ],
              expirationDateOptions: [
                  { label: t("All"), value: "all" },
                  { label: t("Next 6 Months"), value: getOffsetDate(6) },
                  { label: t("Next 12 Months"), value: getOffsetDate(12) },
                  { label: t("Next 18 Months"), value: getOffsetDate(18) }
              ],
              organizationOptions: [{ label: t("All"), value: "all" }, ...returnOrganizations(orgs)]
          }
        : {
              sortByOptions: [
                  { label: t("Date Added"), value: "date" },
                  { label: t("Date Last Trained"), value: "lasttrained" },
                  { label: t("Email"), value: "email" },
                  { label: t("Expiration Date"), value: "expirationdate" },
                  { label: t("First Name"), value: "firstname" },
                  { label: t("Last Name"), value: "lastname" },
                  { label: t("Organization"), value: "organization" }
              ],
              programOptions: [{ label: t("All"), value: "all" }, ...returnPrograms(programs)],
              instructorOptions: [{ label: t("All"), value: "all" }, ...returnInstructorsCertifications(instructors)],
              statusOptions: [
                  { label: t("All"), value: "all" },
                  { label: t("Active"), value: "active" },
                  { label: t("Expired"), value: "expired" },
                  { label: t("None"), value: "none" }
              ],
              expirationDateOptions: [
                  { label: t("All"), value: "all" },
                  { label: t("Next 6 Months"), value: getOffsetDate(6) },
                  { label: t("Next 12 Months"), value: getOffsetDate(12) },
                  { label: t("Next 18 Months"), value: getOffsetDate(18) }
              ],
              organizationOptions: [{ label: t("All"), value: "all" }, ...returnOrganizations(orgs)]
          };

    React.useEffect(() => setBulkKey(bulkKey + 1), [bulkResult]);

    const handleExportParticipants = async () => {
        try {
            const payload: ExportParticipantsPayload = {
                organizationIds:
                    filters.organization === "all"
                        ? [...new Set(orgs.map((o) => o.organizationId))]
                        : [filters.organization],
                organization:
                    filters.organization === "all"
                        ? null
                        : `${orgs.filter((o) => o.organizationId === filters.organization)[0].organizationName} ${
                              orgs.filter((o) => o.organizationId === filters.organization)[0].city
                          }`,
                searchTerm: filters.searchTerm,
                status: filters.status === "all" ? null : filters.status,
                program: filters.program === "all" ? null : filters.program,
                userId: filters.instructor === "all" ? null : filters.instructor,
                expirationDate: filters.expirationDate === "all" ? null : filters.expirationDate,
                lastTrained: filters.lastTrained === "all" ? null : filters.lastTrained,
                sortBy: filters.sortBy,
                culture
            };

            const resp = await exportParticipants(apimBaseUrl, configContext, payload);
            if (resp) {
                saveAs(resp, "Participants.csv");
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleRemoveParticipantFromClass = () => fetchParticipants();

    return (
        <>
            <div className="page-content m-auto">
                <ModalPopUp
                    title={t("Participant Details")}
                    size="md"
                    show={showParticipantDetails}
                    hide={handleCloseViewEditParticipant}
                    actionButton={{
                        label: t("Close"),
                        isSolid: false,
                        clickAction: (e) => {
                            e.preventDefault();
                            handleCloseViewEditParticipant();
                        }
                    }}
                    content={
                        <ViewParticipantDetails
                            participant={viewEditParticipant}
                            canEditParticipants={canEditParticipants}
                            canTransferParticipants={canTransferParticipants}
                            canEditClassParticipants={canEditClassParticipants}
                            canSendEnrollmentEmail={canSendEnrollmentEmail}
                            canViewCertificates={canViewCertificates}
                            handleEdit={() => fetchParticipants()}
                            organizationId={viewEditParticipant?.organizationId}
                            fromParticipants
                            addPageLevelAlert={addPageLevelAlert}
                            onRemoveParticipantFromClass={handleRemoveParticipantFromClass}
                        />
                    }
                />
                <ModalPopUp
                    title={t("Create Participant")}
                    size="md"
                    show={showCreateParticipant}
                    hide={() => setShowCreateParticipant(false)}
                    content={
                        <CreateParticipant
                            requiresEmail={false}
                            orgsArr={[]}
                            addPageLevelAlert={addPageLevelAlert}
                            handleCancelButton={handleCancel}
                        />
                    }
                />
                <ModalPopUp
                    title={t("ClassParticipants.ImportUsingAFile")}
                    size="lg"
                    content={returnImportFromFileComponent()}
                    show={showImportFromFile}
                    hide={() => setShowImportFromFile(false)}
                />
                <Container fluid className="page-padding">
                    <Row>
                        <Col xs={12}>
                            <h1 className="title-margin page-title-h1">{t("ClassParticipants.Participants")}</h1>
                        </Col>
                    </Row>
                    <div className="alert-wrapper">
                        <>{returnPageLevelAlerts(pageLevelAlerts)}</>
                        {bulkResult && (
                            <Alert
                                description={bulkResult.message}
                                state={{ alertLevel: bulkResult.isError ? "warning" : "success" }}
                                key={`bulk${bulkKey}`}
                                canDismiss
                                arrowLinkHandled={
                                    bulkResult.isError
                                        ? {
                                              label: t("Download Issues"),
                                              isButton: true,
                                              onClick: handleDownloadErrorReport,
                                              iconClass: "fas fa-download"
                                          }
                                        : undefined
                                }
                            />
                        )}
                    </div>
                    {shouldShowBOE && <BOESelector />}
                    <Tile customClassName="no-mt" hideTileHeader title="">
                        <>
                            <div className="participants-actions-row">
                                <span>{t("Total-Participants", { total: pagination.total })}</span>
                                <div className="participants-actions">
                                    {canCreateParticipants && (
                                        <Button
                                            elementId="create-participant-button"
                                            isSolid={false}
                                            label={t("Create Participant")}
                                            clickAction={() => setShowCreateParticipant(true)}
                                        />
                                    )}
                                    {!isMobile && (
                                        <>
                                            {canBulkUploadParticipants && canCreateParticipants && (
                                                <Button
                                                    elementId="import-participants-button"
                                                    isSolid={false}
                                                    label={t("Import From File")}
                                                    clickAction={() => setShowImportFromFile(true)}
                                                />
                                            )}
                                            <Button
                                                elementId="export-participants-button"
                                                clickAction={() => handleExportParticipants()}
                                                isSolid={false}
                                                label={t("ClassParticipants.ExportList")}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <CustomTable
                                parentTrigger={parentToChildTrigger}
                                enableFilter
                                filters={{
                                    template: FiltersTemplate.Participants,
                                    update: updateFilter,
                                    set: setFilters,
                                    showClear: showClearFilters,
                                    clear: clearFilters,
                                    search: {
                                        label: t("Participants-Search-Title"),
                                        handle: handleSearch
                                    },
                                    options: filterOptions
                                }}
                                pageSizes={pageSizes}
                                pageSize={pagination.size}
                                handlePageSize={updatePageSize}
                                handlePage={updatePage}
                                enableSearch={false}
                                enableHeader
                                columns={columns}
                                loading={isLoading}
                                empty={isEmpty}
                                emptyComponent={
                                    !showClearFilters ? (
                                        <div className="p-5">
                                            <EmptyContent
                                                button={
                                                    <div className="d-flex">
                                                        <Button
                                                            elementId="first-create-participant-button"
                                                            additionalStyleClasses="mr-3"
                                                            clickAction={() => setShowCreateParticipant(true)}
                                                            isSolid
                                                            label={t("Create Participant")}
                                                        />
                                                        <Button
                                                            elementId="first-import-button"
                                                            isSolid={false}
                                                            label={t("ClassParticipants.ImportUsingAFile")}
                                                            clickAction={() => setShowImportFromFile(true)}
                                                        />
                                                    </div>
                                                }
                                                title={t("No-Participants-Yet")}
                                                subtitle={t("Create-Some-Participants")}
                                            />
                                        </div>
                                    ) : (
                                        <EmptyContentBasic
                                            title={t("No Results Found")}
                                            subtitle={t("Clear your filters to continue searching")}
                                        />
                                    )
                                }
                                currentPage={pagination.current}
                                total={pagination.total}
                                enablePaging
                            >
                                <ParticipantList listItems={returnParticipants()} />
                            </CustomTable>
                        </>
                    </Tile>
                </Container>
                <ParticipantsSelected
                    selected={selectedParticipantsIds}
                    canBulkUpdateParticipants={canBulkUpdateParticipants}
                />
            </div>
        </>
    );
};

export default ParticipantsPage;
