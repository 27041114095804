import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Modal } from "react-bootstrap";

import { RbacContext } from "../../rbac-context";
import { unenrollLearner } from "../../ApiServices/Class";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface UnenrollModalProps {
    show: boolean;
    onHide: () => void;
    handleSaveComplete: (resp: JsonResponseModel<ApiResponse>) => void;
    learnerCourseId: number;
    learnerName: string;
    learnerEmailAddress: string;
    hasReturnableSeat: boolean;
    isEventEnrollment: boolean;
    _className: string;
}

const UnenrollModal = ({
    show,
    onHide,
    handleSaveComplete,
    learnerCourseId,
    learnerName,
    learnerEmailAddress,
    hasReturnableSeat,
    isEventEnrollment,
    _className
}: UnenrollModalProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [saving, setSaving] = React.useState(false);

    const handleSubmit = async () => {
        try {
            setSaving(true);
            const resp = await unenrollLearner(learnerCourseId);

            if (!resp.Error) {
                resp.Data.Message = t("{{name}} ({{emailAddress}}) has been removed from {{className}}.", {
                    name: learnerName,
                    emailAddress: learnerEmailAddress,
                    className: _className
                });
            }

            setSaving(false);
            handleSaveComplete(resp);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }        
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Unenroll Learner")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t("Are you sure you want to remove the following learner from {{className}}?", {
                        className: _className
                    })}
                </p>
                <p style={{ wordWrap: "break-word" }}>
                    <strong>{learnerName}</strong> ({learnerEmailAddress})
                </p>

                {!hasReturnableSeat && !isEventEnrollment && (
                    <p>
                        {t(
                            "The seat associated with this learner is expired or otherwise non-returnable. If you unenroll, no seat will be returned."
                        )}
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide} disabled={saving}>
                    {t("Cancel")}
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {!saving && t("Unenroll Learner")}
                    {saving && (
                        <>
                            <i className="fas fa-spinner fa-spin"></i> {t("Processing")}
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withAITracking(reactPlugin, UnenrollModal);
