import * as React from "react";

const TextArea = (props: {
    label: string,
    name: string,
    id: string,
    defaultRows?: number,
    placeholder?: string,
    isRequired?: boolean,
    isError?: boolean,
    errorLabel?: string,
    additionalClass?: string,
    showRequired?: boolean,
    value?: string,
    changeAction?: React.ChangeEventHandler<HTMLTextAreaElement>
}): JSX.Element => {
    const defaultProps = {
        showRequired: true,
        ...props
    };
    props = {...defaultProps, ...props};
    const alertLabel = props.errorLabel ? props.errorLabel : "Default Alert Text";
    const fieldErrorBorderClass = props.isError ? " field-error" : "";

    return (
        <> 
                <>
                    <label className="bold form-label" htmlFor={props.id}>{props.label}</label>
                    { props.showRequired && (
                        props.isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">(optional)</span>
                    )}
                </>

                <textarea onChange={props.changeAction} value={props.value} rows={props.defaultRows ? props.defaultRows : 12} className={`form-input text-area mt-1 d-block ${fieldErrorBorderClass} ${props.additionalClass}`} id={props.id} name={props.name} placeholder={props.placeholder}></textarea>
                { props.isError ? <span className="field-validation form-field-error small-error" id="firstName-required-label-1">{alertLabel}</span> : null }
        </>
    );
};

export default TextArea;
