import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import Button from "../../Components/UI/button";

const SpecifyingPurposeDirectionsAndSetup = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <h1 className="page-title-h1 mb-3">Specifying Purpose, Directions, and Setup</h1>
                            <p className="mw-900">When you script your directions, it is helpful to follow this format. The specifying purpose, directions, and setup section of your activity writing guide includes a sample script that you can reference while you draft your directions.</p>
                            <ul>
                                <li>Set the purpose for participants.</li>
                                <li>Set up the activity by telling them what they will do, who they will work with, and how much time they will have.</li>
                                <li>Allow staff to participate and script what you'll say to identify a point of completion or success.</li>
                            </ul>
                        </>
                    }
                />

                <Tile title="Writing the Script" hideTileHeader={false}>
                    <>
                        {/* WRITE HTML BELOW THIS LINE */}

                        <FourByEight
                            left={
                                <>
                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                    <div className="mb-4">
                                        <a href="https://cdn.crisisprevention.com/media/training-center/images/outlining-activity-format-and-timing/Activity-Writing-Guide.pdf" target="_new"><img width="250px" src="https://cdn.crisisprevention.com/media/training-center/images/outlining-activity-format-and-timing/format-timing.png" alt="Activity Writing Guide"></img></a>
                                        <ArrowLink data={{ label: "Download the Activity Writing Guide", href: "https://cdn.crisisprevention.com/media/training-center/images/outlining-activity-format-and-timing/Activity-Writing-Guide.pdf", openInNewTab: true }} />
                                    </div>

                                    <a href="https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity_Template.pdf" target="_new"><img width="250px" src="https://cdn.crisisprevention.com/media/training-center/images/activity-template.png" alt="Activity Template"></img></a>
                                    <ArrowLink data={{ label: "Download the Activity Template", href: "https://cdn.crisisprevention.com/media/training-center/images/write-train-activities/Activity_Template.pdf", openInNewTab: true }} />

                                </>
                            }

                            right={
                                <>
                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                    <p className="mt-4 mb-4">The first step is to introduce the purpose. You can do this either by explicitly stating your activity goal and format, or by telling a story of a time when the skills you're practicing helped you de-escalate a crisis.</p>
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h4 className="h4">Write a purpose statement that:</h4>
                                            <ul>
                                                <li>Tells participants what they will practice during the activity</li>
                                                <li>Connects the activity to the program goals</li>
                                                <li>Creates buy-in for the activity</li>
                                            </ul>
                                        </div>
                                        <div className="w-100 mb-4">
                                            <GrayTile percentWidth={80}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <p>Example:</p>
                                                    <p>We are going to practice recognizing and responding to anxiety behaviors by working on a case study in teams.</p>
                                                </>
                                            </GrayTile>
                                        </div>
                                    </div>
                                    <p className="mt-4 mb-4">In your activity writing guide, write out simple, step-by-step directions you will communicate to your group. When writing directions, make them clear and specific so participants understand their task. Anticipate questions that may come up, and address them in your directions. Read your directions several times to make sure they are simple and clear. Within your instructions clearly identify a point of completion or success.</p>
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h4 className="h4">When scripting and practicing your directions:</h4>

                                            <ul>
                                                <li>Use clear and specific language</li>
                                                <li>Anticipate questions</li>
                                                <li>Re-read and practice aloud</li>
                                                <li>Identify a point of completion or success</li>
                                            </ul>
                                        </div>
                                        <div className="w-100">
                                            <GrayTile percentWidth={80}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <p>Example:</p>
                                                    <ol>
                                                        <li>Each of you has a case study. Read it and underline any anxiety behaviors you recognize.</li>
                                                        <li>Under the case study, make a bullet point list of the anxiety behavior you saw, and possible responses you might take to support the individual.</li>
                                                    </ol>
                                                </>
                                            </GrayTile>
                                        </div>
                                    </div>
                                    <p className="mt-4 mb-4">In your script you're also going to want to include a list that identifies what you need to prepare; the supplies needed to complete the activity; the room configuration for the best and safest facilitation of the activity. Take a few minutes to add this to your script.</p>
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h4 className="h4">Write a script that includes:</h4>
                                            <ul>
                                                <li>What you need to prepare</li>
                                                <li>The supplies neded to complete the activity</li>
                                                <li>The room configuration for the best and safest facilitation of the activity</li>
                                            </ul>
                                        </div>
                                        <div className="w-100">
                                            <GrayTile percentWidth={80}>
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <p>Example:</p>
                                                    <ul>
                                                        <li>Paper copies of the case study</li>
                                                        <li>Animal stickers to identify pairs</li>
                                                    </ul>
                                                </>
                                            </GrayTile>
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </>
                </Tile>
                <OneColumnLayout
                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <div className="d-flex justify-content-between flex-wrap">

                                <div>
                                    <Button label="Outline Activity Format & Timing" isSolid={false} elementId="buttonId" href="Outlining-Activity-Format-And-Timing" />

                                </div>

                                <div>
                                    <Button label="Step 5: Guide Participants to Debrief" isSolid={true} elementId="buttonId" href="Guiding-Participants-To-Debrief" />
                                </div>

                            </div>
                        </>
                    }
                />
                </Container>
            </div>
        </>

    );
};

export default SpecifyingPurposeDirectionsAndSetup;
