export interface Skill {
    skillId: string;
    skillName: string;
    active: boolean;
}

export interface SkillSubcategory {
    subcategory: string;
    skills: Skill[];
}

export interface SkillCategory {
    category: string;
    subcategories: SkillSubcategory[];
}

export interface ClassSkillModel {
    categories: SkillCategory[];
}

export interface ClassSkillCheck {
    id: string;
    name: string;
    checked: string;
    category: string;
    subCategory: string;
}

export interface ClassSkillSubmitted {
    skillId: string;
    category: string;
    subCategory: string;
    skillName: string;
}

export const ClassSkillModelMapToClassSkillSubmittedList =(skillModel: ClassSkillModel)=>{
    let classSkillsSubmitted:ClassSkillSubmitted[] = [];
    
    skillModel.categories?.forEach((parentCategory) => {
        parentCategory.subcategories?.forEach((subcategory)=>{
            if(subcategory.skills.length > 1){
                subcategory.skills.forEach((skill)=>{
                    classSkillsSubmitted.push({
                        skillId: skill.skillId,
                        skillName: skill.skillName,
                        subCategory: subcategory.subcategory,
                        category: parentCategory.category
                    });
                })
            }else{
                classSkillsSubmitted.push({
                    skillId: subcategory.skills[0].skillId,
                    skillName: subcategory.skills[0].skillName,
                    subCategory: subcategory.subcategory,
                    category: parentCategory.category
                });
            }
        })
    })

    return classSkillsSubmitted;
}
