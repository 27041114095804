import * as React from "react";

import { Button, Card, Col, Container, OverlayTrigger, Row, Tooltip, Modal } from "react-bootstrap";
import { Industry } from "../../Enums";
import { WithTranslation, withTranslation } from "react-i18next";
import { fetchUserPingGroups } from "../../ApiServices/MyAccount";

import ParticipantEditForm from "./participant-edit-form";
import ParticipantEnrollmentDetails from "./participant-enrollment-details";
import ProgressBar from "../../Components/UI/progress-bar";
import { enumToString } from "../../helper-functions";
import { formatDate } from "../../locale-utils";
import moment from "moment";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withNavigator } from '../../Components/with-navigator';
import Course from "../../Interfaces/Course";
import { updateLearningHistoryProgress, removeParticipantFromClass } from "../Utils/class";
import { fetchCourseByCourseObjectId } from "../Utils/course";
import { fetchOrganization } from "../Utils/organization";
import { fetchLearningHistory, fetchParticipantByUserId } from "../Utils/participants";
import { fetchParticipantOnlineCourseCertificate } from "../Utils/participants";
import { openPdfFromBase64InNewTab } from "../Utils/pdf";

import { UserContext } from "../../Interfaces";

export interface ParticipantDetailsProps extends WithTranslation {
    user: any;
    configContext: any;
    userContext: UserContext;
    apimBaseUrl: string;
}

interface ParticipantDetailsState {
    user: any;
    participant: any;
    organization: any;
    roles: string[];
    learningHistory: any[];
    expandedCourse: number[];
    expandedModule: number[];
    selectedLearningHistoryId: number;
    loading: boolean;
    errored: boolean;
    notFound: boolean;
    learnerOnly: boolean;
    showOrgModal: boolean;
    showRemoveModal: boolean;
    enrollmentToRemove: any;
    showEditModal: boolean;
    courseLanguages: any[];
    showCourseOptionsModal: boolean;
    saving: boolean;
}

class ParticipantDetails extends React.Component<ParticipantDetailsProps, ParticipantDetailsState> {
    constructor(props: ParticipantDetailsProps) {
        super(props);
        this.state = {
            user: null,
            participant: null,
            organization: null,
            roles: [],
            learningHistory: [],
            expandedCourse: [],
            expandedModule: [],
            selectedLearningHistoryId: null,
            loading: true,
            errored: false,
            notFound: false,
            learnerOnly: false,
            showOrgModal: false,
            showRemoveModal: false,
            enrollmentToRemove: null,
            showEditModal: false,
            courseLanguages: [],
            showCourseOptionsModal: false,
            saving: false
        };
    }

    componentDidMount = async () => {
        this.setInitialData();
    };

    componentDidUpdate = async () => {
        if (this.props.user.id !== this.state.user.id) {
            this.setInitialData();
        }
    };

    setInitialData = async () => {
        this.setState({ loading: true });
        this.setState({ user: this.props.user });
        this.setState({ participant: await fetchParticipantByUserId(this.props.apimBaseUrl, this.props.configContext, this.props.user.id) });
        if (this.props.user.organizationId) {
            this.setState({ organization: await fetchOrganization(this.props.apimBaseUrl, this.props.configContext, this.props.user.organizationId) });
        }
        const learningHistory = await fetchLearningHistory(this.props.apimBaseUrl, this.props.configContext, this.props.user.id);
        if (learningHistory?.activeCourses && learningHistory?.completedCourses) {
            this.setState({ learningHistory: learningHistory.activeCourses.concat(learningHistory.completedCourses) });
        }
        else if (learningHistory?.activeCourses) {
            this.setState({ learningHistory: learningHistory.activeCourses });
        }
        else if (learningHistory?.completedCourses) {
            this.setState({ learningHistory: learningHistory.completedCourses });
        }
        else {
            this.setState({ learningHistory: [] });
        }
        this.setState({ roles: (await fetchUserPingGroups(this.props.user.id)).Data ?? [] });
        this.setState({ loading: false });
    }

    render() {
        const {
            user,
            participant,
            organization,
            learningHistory,
            selectedLearningHistoryId,
            loading,
            errored,
            notFound,
            learnerOnly,
            showOrgModal,
            showRemoveModal,
            enrollmentToRemove,
            showEditModal,
            courseLanguages,
            showCourseOptionsModal,
            saving
        } = this.state;

        const { t } = this.props;
        let selectedCourse = null;
        if (learningHistory && learningHistory.length > 0) {
            selectedCourse = learningHistory?.find((course) => course.learningHistoryId === selectedLearningHistoryId);
            if (selectedCourse) {
                selectedCourse.TotalTimeString = selectedCourse.TotalTime != "0"
                    ? moment.duration(selectedCourse.TotalTime, "seconds").format(`d [${t("dy")}] h [${t("hr")}] m [${t("min")}] s [${t("sec")}]`)
                    : t("Not Available");
            }
        }

        return (
            <>
                {!loading && !notFound && user && (
                    <Container fluid>
                        <Row>
                            <div className="jumbotron" style={{ width: "100%", marginTop: "10px" }}>
                                <Row>
                                    <Col>
                                        <h2>
                                            {user.firstName} {user.lastName} 
                                        </h2>
                                    </Col>
                                    <Col
                                        lg={4}
                                        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="edit-learner-tooltip">
                                                    {user.customerId == null || isNaN(user.customerId)
                                                        ? t("edit learner information")
                                                        : t("This participant must be updated in CE.")}
                                                </Tooltip>
                                            }
                                            delay={{ show: 250, hide: 250 }}
                                        >
                                            <div className="d-inline-block">
                                                <Button
                                                    disabled={!(user.customerId == null || isNaN(user.customerId))}
                                                    variant="light"
                                                    style={{ margin: "3px" }}
                                                    onClick={() => this.setState({ showEditModal: true })}
                                                >
                                                    {t("Edit Learner")}
                                                </Button>
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        <strong>{t("Email")}:</strong> {user.email}
                                    </Col>
                                    <Col lg>
                                        <strong>{t("Primary Organization")}:</strong>{" "}
                                        {organization?.organizationName} ({organization?.orgId})
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        <strong>{t("Customer #")}:</strong> {user.customerId}
                                    </Col>
                                    <Col lg>
                                        <strong>{t("Roles")}:</strong>{" "}
                                        {this.state.roles.length > 0 && <span>{this.state.roles.join(", ")}</span>}
                                    </Col>
                                </Row>
                            </div>
                        </Row>

                        <Row>
                            <Col>{learningHistory.map(this.renderCourseRow)}</Col>

                            <Col>{selectedLearningHistoryId && this.renderLearningHistory(selectedCourse)}</Col>
                        </Row>

                        {showEditModal && user && (
                            <ParticipantEditForm
                                isVisible={showEditModal}
                                participant={participant}
                                organization={organization}
                                onSave={this.handleParticipantEdit}
                                onHide={() => this.setState({ showEditModal: false })}
                            />
                        )}

                        {showRemoveModal && enrollmentToRemove && this.renderRemoveModal(enrollmentToRemove)}
                    </Container>
                )}
            </>
        );
    }

    hideRemoveModal = () => {
        this.setState({ showRemoveModal: false, enrollmentToRemove: null });
        return;
    };

    renderRemoveModal = (enrollment: any) => {
        const { t } = this.props;

        return (
            <Modal show={this.state.showRemoveModal} onHide={this.hideRemoveModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>{t("Participant Unenrollment")}</h3>
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    {t("Unenroll this participant from class ")} {enrollment.courseName}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={this.state.saving} onClick={this.hideRemoveModal}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" disabled={this.state.saving} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.postRemoveEnrollment(enrollment.classParticipantId, enrollment.participantId, enrollment.classId, e)}>
                        {this.state.saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Ok")
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    renderCourseRow = (course: any) => {
        const { selectedLearningHistoryId } = this.state;
        const cardStyle = { margin: "3px", width: "100%" };
        const isSelected = course.learningHistoryId === selectedLearningHistoryId;
        const complete = course.completedDate !== null;

        return (
            <Row key={course.learningHistoryId}>
                <Card
                    border={isSelected ? "dark" : null}
                    style={cardStyle}
                    onClick={() => this.setState({ selectedLearningHistoryId: course.learningHistoryId })}
                >
                    <Card.Body>
                        <Card.Title>
                            {course.courseName}
                            {complete &&
                                this.renderLink(course.learningHistoryId)}
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{course.EnrollmentId}</Card.Subtitle>
                        <Card.Text className="mb-0">{course.ClassName}</Card.Text>
                        <ProgressBar
                            startedDate={course.courseStart}
                            widthBar={100}
                            onlineCoursePercentComplete={course.percentComplete ?? 0}
                            lastLogin={null}
                            minified
                        />
                        <Row style={{ flexDirection: "row-reverse", paddingTop: "20px" }}>
                            {!complete && course.classParticipantId && course.participantId && course.classId && this.renderRemoveEnrollment(course)}
                            {this.renderCourseAction(course.learningHistoryId, course.courseObjectId, complete)}
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        );
    };

    renderRemoveEnrollment = (course: any) => {
        const { t } = this.props;
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="removeEnrollmentTooltip">{t("Remove this enrollment")}</Tooltip>}
            >
                <Button
                    className="ml-2"
                    variant="light"
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        this.setState({
                            enrollmentToRemove: { ...course },
                            showRemoveModal: true
                        });
                    }}
                >
                    {t("Remove")}
                </Button>
            </OverlayTrigger>
        );
    };

    renderCourseAction = (learningHistoryId: string, courseObjectId: string, complete: boolean) => {
        const { t } = this.props;
        if (complete) {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="resetParticipantCourseTooltip">{t("Reset participant progress in course")}</Tooltip>}
                >
                    <Button
                        className="ml-2"
                        variant="light"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                            this.postUndoPassCourse(learningHistoryId, courseObjectId, e)
                        }
                    >
                        {t("Undo Pass")}
                    </Button>
                </OverlayTrigger>
            );
        }

        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="passParticipantCourseTooltip">{t("Pass participant through course")}</Tooltip>}
            >
                <Button
                    variant="light"
                    style={{ marginLeft: "3px" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        this.postPassCourse(learningHistoryId, courseObjectId, e)
                    }
                >
                    {t("Pass")}
                </Button>
            </OverlayTrigger>
        );
    };

    renderLearningHistory = (course: any) => {
        if (!course) return;
        return (
            <>
                <ParticipantEnrollmentDetails course={course} configContext={this.props.configContext} apimBaseUrl={this.props.apimBaseUrl} />
                <hr className="mt-4 mb-4" />
                {this.renderCourseOptions(course)}
            </>
        );
    };

    renderCourseOptions = (course: any) => {
        const { t } = this.props;
        const featureEnabled = false;

        return (
            <Container className="p-0">
                <h5 className="mb-3">
                    <strong>{t("Course Options")}</strong>
                </h5>
                {featureEnabled && (
                    <Row>
                        <Col>
                            <strong>{t("Industry")}:</strong> {enumToString(Industry[course.Industry])}
                            <br />
                            <strong>{t("Language")}:</strong> {course.Language}
                        </Col>
                        {course.Active && (
                            <Col lg={4} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <div className="d-inline-block">
                                    <Button variant="light" onClick={() => this.setState({ showCourseOptionsModal: true })}>
                                        {t("Edit Options")}
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                )}
                {featureEnabled && (
                    <Row>
                        <Col>
                            Feature incoming...
                        </Col>
                    </Row>
                )}
            </Container>
        );
    };

    renderLink = (learningHistoryId: string) => {
        const { t } = this.props;
        return (
            <div className="float-right">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="viewCertificateTooltip">{t("View Certificate")}</Tooltip>}
                >
                    <small>
                        <Button
                            variant="btn-link"
                            onClick={() =>
                                fetchParticipantOnlineCourseCertificate(this.props.apimBaseUrl, this.props.userContext.Country, this.props.configContext, [learningHistoryId]).then((response: any) => {
                                    openPdfFromBase64InNewTab(response.certificates[0]);
                                })}
                        >
                            {t("View Certificate")}
                        </Button>
                    </small>
                </OverlayTrigger>
            </div>
        );
    };

    async handleOrganizationSave(resp: any) {
        this.setState({ showOrgModal: false });
        this.setState({ organization: resp });
    }

    handleParticipantEdit = async (updatedParticipant: any) => {
        this.setState({ showEditModal: false });
        this.setState({ user: { ...this.state.user, firstName: updatedParticipant.firstName, lastName: updatedParticipant.lastName, email: updatedParticipant.email } });
        this.setState({ participant: { ...this.state.participant, firstName: updatedParticipant.firstName, lastName: updatedParticipant.lastName, email: updatedParticipant.email } });
        this.setState({ organization: await fetchOrganization(this.props.apimBaseUrl, this.props.configContext, updatedParticipant.organizationId) });
    };

    postUndoPassCourse = async (learningHistoryId: string, courseObjectId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        const requestBody = { LearningHistoryId: learningHistoryId, Score: 0, CompletedDate: null, IsComplete: false, IsPassed: false, PercentComplete: 0 };
        const result = await updateLearningHistoryProgress(this.props.configContext, requestBody);
        if (result) {
            const index = this.state.learningHistory.findIndex((l) => l.learningHistoryId === learningHistoryId);
            const currentLearningHistory = Object.assign({}, this.state.learningHistory[index]);
            currentLearningHistory.completedDate = null;
            currentLearningHistory.examScore = 0;
            currentLearningHistory.percentComplete = 0;

            const newLearningHistory = this.state.learningHistory.slice();
            newLearningHistory[index] = currentLearningHistory;

            this.setState({ learningHistory: newLearningHistory });
        }
    };

    postPassCourse = async (learningHistoryId: string, courseObjectId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        const course = await fetchCourseByCourseObjectId(this.props.configContext, courseObjectId);
        const result = await updateLearningHistoryProgress(this.props.configContext, { LearningHistoryId: learningHistoryId, Score: course.minimumScore, CompletedDate: new Date(), IsComplete: true, IsPassed: true, PercentComplete: 100 });
        if (result) {
            const index = this.state.learningHistory.findIndex((l) => l.learningHistoryId === learningHistoryId);
            const currentLearningHistory = Object.assign({}, this.state.learningHistory[index]);
            currentLearningHistory.completedDate = new Date();
            currentLearningHistory.examScore = course.minimumScore;
            currentLearningHistory.percentComplete = 100;

            const newLearningHistory = this.state.learningHistory.slice();
            newLearningHistory[index] = currentLearningHistory;

            this.setState({ learningHistory: newLearningHistory });
        }
    };

    postRemoveEnrollment = async (classParticipantId: string, participantId: string, classId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        await removeParticipantFromClass(this.props.apimBaseUrl, this.props.configContext, { classParticipantId, participantId, classId });
        const index = this.state.learningHistory.findIndex((l) => l.classParticipantId === classParticipantId);
        if (index > -1) { 
            const lh = this.state.learningHistory;
            lh.splice(index, 1);
            this.setState({ learningHistory: lh, saving: false });
        }
        this.hideRemoveModal();
    }
}

export default withNavigator(withAITracking(reactPlugin, withTranslation() (ParticipantDetails)));
