import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import VideoEmbed from "../../Components/UI/video-embed";


const ManagingYourParticipantsAndClassroom = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1 title-margin">Managing Your Participants and Classroom</h1>
                                <p className="mw-900 mt-3">When preparing for training, content is one piece of the puzzle. But there’s another, equally important, aspect to practice: how to manage the classroom. We’ve highlighted simple ways to manage your participants, time, and classroom environment to run your session smoothly.</p>
                                <Tile title="Managing Participants" hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <EightByFour
                                            left={
                                                <>
                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                    <span className="parsed-text">{tp('ManagingParticipants-EncourageParticipation')}</span>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Encouraging Participation" vimeoId="768157484" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-EncourageParticipation.png" />
                                                            <p className="pt-2"><strong>Encouraging Participation</strong></p>
                                                            <p>Things you can do before and during your training sessions to encourage your participants to participate in the training session.</p>
                                                        </>
                                                    </GrayTile>

                                                </>
                                            }
                                        />
                                        <EightByFour
                                            left={
                                                <>
                                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                                    <span className="parsed-text">{tp('ManagingParticipants-ManageQuestions')}</span>


                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                                    <p></p>
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Managing Too Many Responses" vimeoId="768157530" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-TooManyResponses.png" />
                                                            <p className="pt-2"><strong>Managing Too Many Responses</strong><br />
                                                                Tips on managing participant responses during your training session.</p>
                                                        </>
                                                    </GrayTile>
                                                    <p></p>
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Answering Difficult Questions" vimeoId="768157557" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-DifficultQuestions.png" />
                                                            <p className="pt-2"><strong>Answering Difficult Questions</strong><br />
                                                                How to respond when participants ask questions you are unsure of or do not know how to answer.</p>
                                                        </>
                                                    </GrayTile>

                                                </>
                                            }
                                        />
                                    </>

                                </Tile>
                                <Tile title="Managing the Classroom and Time" hideTileHeader={false}>
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Staying on Time During Training" vimeoId="768157593" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-StayOnTime.png" />
                                                            <p className="pt-2"><strong>Staying on Time During Training</strong><br />
                                                                Suggestions to help you be proactive in your time management skills by looking at things you can do before and during your training sessions to ensure you don’t run out of time.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Running Out of Time" vimeoId="768157641" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-RunningOutTime.png" />
                                                            <p className="pt-2"><strong>Running Out of Time</strong><br />
                                                                Tips to use during a training session to regain control of the time so you can cover the content required.</p>                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Unable to Complete Each Module" vimeoId="768157679" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-UnabletoComplete.png" />
                                                            <p className="pt-2"><strong>Unable to Complete Each Module</strong><br />
                                                                Things you would need to do to make up for a session that didn’t cover the required content.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }
                                        />
                                        <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Time Left Over" vimeoId="768157709" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-TimeLeftOver.png" />
                                                            <p className="pt-2"><strong>Time Left Over</strong><br />
                                                                Use time left at the end of the training productively, while keeping the participants engaged and motivated.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Creating a Safe and Respectful Classroom" vimeoId="768157755" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-SafeRespectfulClassroom.png" />
                                                            <p className="pt-2"><strong>Creating a Safe and Respectful Classroom</strong><br />
                                                                Tips on creating a learning environment that is safe and respectful, which is integral to the participants’ participation and learning. </p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Motivating Participants Throughout Training" vimeoId="768157787" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-MotivatingParticipants.png" />
                                                            <p className="pt-2"><strong>Motivating Participants Throughout Training</strong><br />
                                                                Things you can do before and during your training sessions to increase your participants’ motivation, which is essential for effective learning.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }
                                        />
                                        <ThreeColumnLayout
                                            left={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Balancing New and Experienced Learners" vimeoId="768157825" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-BalancingNewExperienced.png" />
                                                            <p className="pt-2"><strong>Balancing New and Experienced Learners</strong><br />
                                                                How you can adapt content to benefit new and experienced learners, while keeping both groups involved and engaged.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            center={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Managing Difficult Participant Behavior" vimeoId="768157865" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-DifficultBehavior.png" />
                                                            <p className="pt-2"><strong>Managing Difficult Participant Behavior</strong><br />
                                                                Why some participants may exhibit difficult behavior, what this behavior means, and how you can respond in these situations.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }

                                            right={
                                                <>
                                                    {/* WRITE HTML BELOW THIS LINE */}
                                                    <GrayTile percentWidth={100}>
                                                        <>
                                                            {/* WRITE HTML BELOW THIS LINE */}
                                                            <VideoEmbed title="Giving Participants Feedback" vimeoId="768157893" thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/images/Thumb-GivingFeedback.png" />
                                                            <p className="pt-2"><strong>Giving Participants Feedback</strong><br />
                                                                How you can use feedback not only to validate participant responses, but also to further their learning.</p>
                                                        </>
                                                    </GrayTile>
                                                </>
                                            }
                                        />
                                    </>
                                </Tile>


                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default ManagingYourParticipantsAndClassroom;
