import { ApiResponse, DELETE, GETWITHERROR, JsonResponseModel, POSTWITHERROR, PUT } from ".";
import { OrgEmailTemplateModel, OrgSettingModel, ReminderSettingModel } from "../Interfaces";

import { HoneyBeeSettingDefinition } from "../Interfaces/HoneyBeeSettingDefinition";
import { template } from "../helper-functions";

const FETCH_ORG_SETTING_DEFINITIONS_URL = "/OrganizationSettings/GetOrgSettingDefinitions";

const FETCH_GENERAL_SETTINGS_URL = "/OrganizationSettings/GetGeneralSettingsAsync";
const POST_GENERAL_SETTING_URL = "/OrganizationSettings/CreateGeneralSettingAsync";
const PUT_GENERAL_SETTING_URL_TEMPLATE = template`/OrganizationSettings/UpdateGeneralSettingAsync?orgSettingId=${0}`;
const DELETE_GENERAL_SETTING_URL_TEMPLATE = template`/OrganizationSettings/DeleteGeneralSettingAsync?orgSettingId=${0}`;

const FETCH_REMINDER_SETTINGS_URL = "/OrganizationSettings/GetReminderSettingAsync";
const POST_REMINDER_SETTING_URL = "/OrganizationSettings/CreateReminderSettingAsync";
const PUT_REMINDER_SETTING_URL_TEMPLATE = template`/OrganizationSettings/UpdateReminderSettingAsync?reminderSettingId=${0}`;
const DELETE_REMINDER_SETTING_URL_TEMPLATE = template`/OrganizationSettings/DeleteReminderSettingAsync?reminderSettingId=${0}`;

const FETCH_EMAIL_TEMPLATES_URL = "/OrganizationSettings/GetEmailTemplatesAsync";
const POST_EMAIL_TEMPLATE_URL = "/OrganizationSettings/CreateEmailTemplateAsync";
const PUT_REMAIL_TEMPLATE_URL_TEMPLATE = template`/OrganizationSettings/UpdateEmailTemplateAsync?emailTemplateId=${0}`;
const DELETE_EMAIL_TEMPLATE_URL_TEMPLATE = template`/OrganizationSettings/DeleteEmailTemplateAsync?emailTemplateId=${0}`;

export async function fetchOrgSettingDefinitions(): Promise<JsonResponseModel<HoneyBeeSettingDefinition[]>> {
    return GETWITHERROR(FETCH_ORG_SETTING_DEFINITIONS_URL);
}

export async function fetchGeneralSettings(): Promise<JsonResponseModel<OrgSettingModel[]>> {
    return GETWITHERROR(FETCH_GENERAL_SETTINGS_URL);
}

export async function createGeneralSetting(orgSetting: OrgSettingModel): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_GENERAL_SETTING_URL, orgSetting);
}

export async function updateGeneralSetting(orgSetting: OrgSettingModel): Promise<JsonResponseModel<ApiResponse>> {
    return PUT(PUT_GENERAL_SETTING_URL_TEMPLATE(orgSetting.OrgSettingId), orgSetting);
}

export async function deleteGeneralSetting(orgSettingId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_GENERAL_SETTING_URL_TEMPLATE(orgSettingId));
}

export async function fetchReminderSettings(): Promise<ReminderSettingModel[]> {
    const resp = await GETWITHERROR<ReminderSettingModel[]>(FETCH_REMINDER_SETTINGS_URL);
    return resp.Data;
}

export async function createReminderSetting(
    reminderSetting: ReminderSettingModel
): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_REMINDER_SETTING_URL, reminderSetting);
}

export async function updateReminderSetting(
    reminderSetting: ReminderSettingModel
): Promise<JsonResponseModel<ApiResponse>> {
    return PUT(PUT_REMINDER_SETTING_URL_TEMPLATE(reminderSetting.ReminderSettingId), reminderSetting);
}

export async function deleteReminderSetting(reminderSettingId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_REMINDER_SETTING_URL_TEMPLATE(reminderSettingId));
}

export async function fetchEmailTemplates(): Promise<JsonResponseModel<OrgEmailTemplateModel[]>> {
    return GETWITHERROR(FETCH_EMAIL_TEMPLATES_URL);
}

export async function createEmailTemplate(
    emailTemplate: OrgEmailTemplateModel
): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(POST_EMAIL_TEMPLATE_URL, emailTemplate);
}

export async function updateEmailTemplate(
    emailTemplate: OrgEmailTemplateModel
): Promise<JsonResponseModel<ApiResponse>> {
    return PUT(PUT_REMAIL_TEMPLATE_URL_TEMPLATE(emailTemplate.OrgEmailTemplateId), emailTemplate);
}

export async function deleteEmailTemplate(emailTemplateId: number): Promise<JsonResponseModel<ApiResponse>> {
    return DELETE<ApiResponse>(DELETE_EMAIL_TEMPLATE_URL_TEMPLATE(emailTemplateId));
}
