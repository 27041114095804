import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const { t } = useTranslation();
    const mailTo = "mailto:" + t("info@crisisprevention.com");
    
    return (
        <Container fluid>
            <Row>
                <Col className="text-center">
                    <h1 className="mt-5 mb-4 text-center">{t("Whoops, there's been a problem.")}</h1>
                    <div className="text-center mb-3">
                        {t("We're sorry for the inconvenience. Try going back to ")} <a href="/" className="text-link">{t("CPI Learning Home")}</a>. {t("If the problem persists, contact our customer support.")}
                    </div>
                    <div className="text-center mt-5">
                        {t("CPI Customer Support")}<br/>
                        {t("888.426.2184")}<br/>
                        <a href={mailTo} className="text-link">{t("info@crisisprevention.com")}</a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorPage;
