import * as React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSkills from "../../../hooks/useSkills";

import { loadUpdatedClassFromPayload, loadClassDetailsInstructors } from "../../../stores/classDetailsSlice";

import useFeatureFlags from "../../../hooks/useFeatureFlags";

import Button from "../../../Components/UI/button";
import LoadingContent from "../../../Components/UI/loading-content";
import DestructiveButton from "../../../Components/UI/cancel-button";
import Alert from "../../../Components/UI/alert";
import DateHours from "../../../Components/UI/date-hours";
import DateInput from "../../../Components/Form/date-input";
import RadioList from "../../../Components/Form/radio/radio-list";
import SelectWorkbookList from "../../../Components/Form/select-workbook/select-workbook-list";
import TextInput from "../../../Components/Form/text-input";
import Select from "../../../Components/Form/select";
import AdditionalInstructor from "../../../Components/UI/additional-instructor";
import TrainingFormats from "../../Components/Classes/training-formats";
import TrainingFormatsNonEdit from "../../Components/Classes/training-formats-nonedit";
import type { IAdditionalInstructor } from "../../../Interfaces/Platform/AdditionalInstructor";
import type { IPrimaryDateHourDetails, IAdditionalDateHourDetails } from "../../../Interfaces/Platform/DateHours";
import type FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import type { ShowAlert } from "../../../Interfaces/Platform/AlertProperties";
import { isCertInstructor, isBusinessAdministrator } from "../../../helper-functions";
import {
    fetchInstructorsCertifications,
    fetchOrganizationBranchIds,
    fetchPrograms,
    fetchAllPrograms,
    returnInstructorsCertifications,
    expirationOffsets,
    getTimeInSeconds,
    returnPrograms
} from "../../Components/DashboardPlatformUtils";
import {
    fetchAdditionalContent,
    fetchClassSettings,
    fetchProgramEditions,
    fetchTypesOfTraining,
    fetchTrainingFormats,
    fetchOrgLmsSettings,
    returnTypesOfTraining,
    returnTrainingFormats,
    returnAdditionalContent,
    fetchSkills,
    fetchClassCourseObject,
    fetchClassLanguagesAndIndustries,
    fetchOrganizationSeats,
    returnOrganizationsSeats,
    updateClass,
} from "../../Utils/classCreate";

import { ConfigContext } from "../../../configuration-context";
import { RbacContext } from "../../../rbac-context";
import useForm from "../../../hooks/useForm";
import usePageLevelAlerts from "../../../hooks/usePageLevelAlerts";
import useTrainableSpecialtiesByCert from "../../../hooks/useTrainableSpecialtiesByCert";
import { returnPageLevelAlerts } from "../../Utils/alerts";
import validateRules from "../../../FormValidationRules/createClass";
import PhysicalSkills from "../../Components/Classes/physical-skills";
import { ClassSkillModel, ClassSkillCheck } from "../../../Interfaces/ClassSkillModel";
import { publish, subscribe, unsubscribe } from "../../Utils/events";
import type { IClassDetails, CourseData, ICourseObject, OrganizationSeat, OrgLmsSettings } from "../../../Interfaces/Platform/Classes";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { useAppSelector } from "../../../hooks/useReduxDefaultHooks";

import useRouteAccessCheck from "../../../hooks/useRouteAccessCheck";

interface ProgramEdition {
    specialty: string;
    edition: string;
    hasAdditionalContent: boolean;
    hasSupplimentContent: boolean;
    active: boolean;
    defaultContent: string;
}
interface Workbook extends ProgramEdition {
    isSelected: boolean;
}
interface ClassSettings {
    blueCardId: boolean;
    dates: boolean;
    hours: boolean;
    expiration: boolean;
    skills: boolean;
    hasWorkbook: boolean;
}

interface InstructorModel {
    userId: string;
    firstName: string;
    lastName: string;
    certifications: string[];
}

interface Props {
    classDetails: IClassDetails;
    canEditClass: boolean;
    participantsEnrolled: number;
    onSuccess: () => void;
    onCancel: () => void;
}

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const ClassDetails = (props: Props) => {
    const dispatch = useDispatch();
    const classDetails = useAppSelector((state) => state.classDetails);
    const BlockEdit = useAppSelector((state) => state.classDetails.blockEdit);
    const { prepareSkillsDataForEdit, processSkillsData } = useSkills();
    const { isCPIStaff } = useRouteAccessCheck();
    const trainingFormat = props.classDetails?.trainingFormat;
    const classroomFormat = props.classDetails?.classroomFormat;
    const hasParticipantsEnrolled = props.classDetails?.totalParticipants > 0;
    const isClassExpired = ((new Date(props.classDetails?.expirationDate).getTime() <= new Date().getTime()) && (hasParticipantsEnrolled) && (props.classDetails?.totalParticipants == props.classDetails?.participantsSubmitted)) || !props.canEditClass;
    const isMigrated = props.classDetails?.imported === true ? true : false;   
    const previousInstructors = props.classDetails?.classInstructors;
    const previousDates = props.classDetails?.classDates;
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const [ITLIntermediatePhysicalSkills] = useFeatureFlags("ITLIntermediatePhysicalSkills", "pending");
    const [ trainablePrograms ] = useTrainableSpecialtiesByCert(ITLIntermediatePhysicalSkills);
    const navigate = useNavigate();
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const [ ITLClassSkills ] = useFeatureFlags("ITLClassSkills", false);
    const [ ITLAllowFullEditClass ] = useFeatureFlags("ITLAllowFullEditClass", "pending");

    const rbac = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const culture = rbac.userContext.Country;

    const [userValues, setUserValues] = React.useState<InstructorModel>({
        userId: rbac.userContext.UserId,
        firstName: rbac.userContext.FirstName,
        lastName: rbac.userContext.LastName,
        certifications: rbac.userContext?.CertificationData.length > 0 ? rbac.userContext.CertificationData.map((data) => (data.specialty)) : []
    });

    const [editable, setEditable] = React.useState(!BlockEdit && (props.classDetails.specialty !== "PF") && (props.classDetails.specialty !== "DEB"));
    const [typesOfTraining, setTypesOfTraining] = React.useState<string[]>([]);
    const [orgLmsSettings, setOrgLmsSettings] = React.useState<OrgLmsSettings>(null);
    const [initialHasLoaded, setInitialHasLoaded] = React.useState<boolean>(false);
    const [orgSeats, setOrgSeats] = React.useState<OrganizationSeat[]>([]);
    const [courses, setCourses] = React.useState<CourseData[]>([]);
    const [courseObject, setCourseObject] = React.useState<ICourseObject>(null);
    const [selectedEdition, setSelectedEdition] = React.useState<Workbook>(null);
    const [showPrimaryInstructorChange, setShowPrimaryInstructorChange] = React.useState<boolean>(false);
    const [showAdditionalInstructorChange, setShowAdditionalInstructorChange] = React.useState<boolean>(false);
    const [trainingFormats, setTrainingFormats] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [updateAlert, setUpdateAlert] = React.useState<ShowAlert>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [classSettings, setClassSettings] = React.useState<ClassSettings>({
        blueCardId: false,
        dates: false,
        hours: false,
        expiration: false,
        skills: false,
        hasWorkbook: false
    });
    const [apiSkills, setApiSkills] = React.useState<any>([]);
    const [workbooks, setWorkbooks] = React.useState<Workbook[]>([]);
    const [newClassData, setNewClassData] = React.useState<boolean>(false);
    const [additionalContent, setAdditionalContent] = React.useState<string[]>([]);
    const [instructors, setInstructors] = React.useState([]);
    const isCI = isCertInstructor(rbac.userContext);
    const isBA = isBusinessAdministrator(rbac.userContext);
    
    // This adds the additional empty fields that we cannot yet see
    // If there are two additional instructors already on the class, it will render those as show:true with a value attached
    // and THEN it will render two invisible fields with show:false and no value attached
    //
    // If we already have the maximum number of instructors, no need to create the data for the invisible fields.
    //
    // At least for now, we're going to restrict the editing/removing of existing instructors.
    const addFillAdditionalInstructors = () => {
        const additionalInstructorsResponse = props.classDetails?.classInstructors.filter((i) => !i.isPrimary);
        if (additionalInstructorsResponse.length < 4) {
            const array = additionalInstructorsResponse.map((i, index) => {
                return {
                    id: `additionalInstructor-${index}`, locked: true, show: true, value: i.userId
                };
            });

            const additionalLength = additionalInstructorsResponse.length + (4 - additionalInstructorsResponse.length);
            for (let i = additionalInstructorsResponse.length; i < additionalLength; i++) {
                array.push({
                    id: `additionalInstructor-${i}`, locked: false, show: false, value: null
                });
            }
            return array;
        } else {
            return additionalInstructorsResponse.map((i, index) => {
                return {
                    id: `additionalInstructor-${index}`, locked: true, show: true, value: i.userId
                };
            });
        }
    };
    const [additionalInstructors, setAdditionalInstructors] = React.useState<IAdditionalInstructor[]>(addFillAdditionalInstructors());
    const [selectedInstructors, setSelectedInstructors] = React.useState<any>({});
    const [selectedProgram, setSelectedProgram] = React.useState('');
    const [programs, setPrograms] = React.useState([]);
    const [skills, setSkills] = React.useState<ClassSkillModel>();
    const [skillsSelected, setSkillsSelected] = React.useState<any[]>([]);
    const skillsSelectedRef = React.useRef(skillsSelected);
    skillsSelectedRef.current = skillsSelected;

    const today = new Date();
    const setDate = (months: number) => {
        const result: Date = new Date(today);
        result.setMonth(result.getMonth() + months);
        const month = result.getMonth() + 1;
        return result.toISOString();
    };
    const expirationOffset = props.classDetails?.expirationOffset;
    const customExpiration = () => {
        return expirationOffset !== 6 && expirationOffset !== 12 && expirationOffset !== 18 && expirationOffset !== 24;
    };
    const initialExpiration = () => {
        const date = props.classDetails?.expirationDate.split('T')[0];
        return [
            { id: 'expirationDate1', checked: expirationOffset === 6 ? true : false, label: t("6 Months"), value: setDate(6) },
            { id: 'expirationDate2', checked: expirationOffset === 12 ? true : false, label: t("12 Months"), value: setDate(12) },
            { id: 'expirationDate5', checked: customExpiration(), label: t("Until"), value: customExpiration() ? date : '' },
        ];
    };
    const [expirationDate, setExpirationDate] = React.useState(initialExpiration());
    const todayDateFormat = new Date().toISOString().split('T')[0];
    const [primaryDay, setPrimaryDay] = React.useState<IPrimaryDateHourDetails>({
        date: props.classDetails?.classDates[0].date.split('T')[0],
        initialHours: props.classDetails?.classDates[0].initialHours.toString(),
        refresherHours: props.classDetails?.classDates[0].refresherHours.toString(),
        classDateId: props.classDetails?.classDates[0].classDateId
    });
    const addDays = () => {
        return props.classDetails?.classDates.slice(1).map((i, index) => {
            return {
                id: `additional-${index}`,
                show: true,
                date: i.date.split('T')[0],
                initialHours: i.initialHours.toString(),
                refresherHours: i.refresherHours.toString(),
                classDateId: i.classDateId
            };
        });
    };
    const addFillDays = () => {
        const additionalDaysResponse = props.classDetails?.classDates.slice(1);
        if (additionalDaysResponse.length < 4) {
            const array = additionalDaysResponse.map((i, index) => {
                return {
                    id: `additional-${index}`,
                    show: true,
                    date: i.date.split('T')[0],
                    initialHours: i.initialHours.toString(),
                    refresherHours: i.refresherHours.toString(),
                    classDateId: i.classDateId
                };
            });

            const additionalLength = additionalDaysResponse.length + (4 - additionalDaysResponse.length);
            for (let i = additionalDaysResponse.length; i < additionalLength; i++) {
                array.push({
                    id: `additional-${i}`,
                    show: false,
                    date: todayDateFormat,
                    initialHours: '0',
                    refresherHours: '0',
                    classDateId: null
                });
            }
            return array;
        } else {
            return additionalDaysResponse.map((i, index) => {
                return {
                    id: `additional-${index}`,
                    show: true,
                    date: i.date.split('T')[0],
                    initialHours: i.initialHours.toString(),
                    refresherHours: i.refresherHours.toString(),
                    classDateId: i.classDateId
                };
            });
        }
    };
    const [additionalDays, setAdditionalDays] = React.useState<IAdditionalDateHourDetails[]>(isClassExpired ? addDays() : addFillDays());

    const getOrganizationBranch = async () => {
        const data = await fetchOrganizationBranchIds(apimBaseUrl, configContext, rbac.userContext.OrganizationId.toString());
        getInstructors(data);
    };

    const getInstructors = async (orgs: string[]) => {
        const classInstructors = props?.classDetails?.classInstructors.map((i) => i.userId);
        const data: InstructorModel[] = await fetchInstructorsCertifications(apimBaseUrl, configContext, rbac.userContext.OrganizationId.toString(), orgs, rbac.userContext.UserId, classInstructors);
        const isInstructorExist = data.some((instructor) => instructor.userId === userValues.userId);
        if (!isInstructorExist && isBA) {            
            data.unshift(userValues);            
        }        
        setInstructors((prevState) => [...prevState, ...data]);
    };

    const getAdditionalContent = async (instructorsIds: string[]) => {
        const data = await fetchAdditionalContent(apimBaseUrl, configContext, instructorsIds, selectedProgram);
        if (data?.length === 0) {
            setAdditionalContent([props.classDetails.qualification])
        } else {
            setAdditionalContent(data);
        }

        handleChangeByGetEvent({ isAdditionalContentRequired: !!data.length });
    };

    const getTypesOfTraining = async () => {
        const data = await fetchTypesOfTraining(apimBaseUrl, configContext, selectedProgram, culture);
        setTypesOfTraining(data);
    };

    const getOrgLmsSettings = async () => {
        const data = await fetchOrgLmsSettings(apimBaseUrl, configContext, rbac.userContext.OrganizationId.toString());
        setOrgLmsSettings(data);
    };

    const getTrainingFormats = async () => {
        const data = await fetchTrainingFormats(apimBaseUrl, configContext, selectedProgram, culture, formFieldData.additionalContent, formFieldData.specialtyEdition);

        if (props.classDetails.trainingFormat === 'classroom') {
            // If class is already created as classroom-only
            const formats = returnTrainingFormats(data).filter((i) => i.value === "classroom");
            setTrainingFormats(formats);
        } else {
            if (orgLmsSettings && !orgLmsSettings.usesCpiLms && !orgLmsSettings.usesExternalLms && formFieldData.desiredLms === null) {
                const formats = returnTrainingFormats(data).filter((i) => i.value !== "blended");
                setTrainingFormats(formats);
                handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0]?.value });
            } else {
                const formats = returnTrainingFormats(data);
                setTrainingFormats(formats);
                if (!newClassData) {
                    handleChangeByGetEvent({ trainingFormat: formats && formats.length > 0 ? formats.filter((format) => format.value === props.classDetails?.trainingFormat)[0].value : null });
                    setTrainingFormats((prevState) => prevState.map((format) => ({ ...format, checked: format.value === props.classDetails?.trainingFormat })));
                } else {
                    handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0].value });
                }
            }
        }
    };

    const getClassSettings = async () => {
        const data = await fetchClassSettings(apimBaseUrl, configContext, selectedProgram);
        if (Object.entries(data[0]).length > 0) setClassSettings(data[0]);
    };

    const getProgramEditions = async () => {
        const data = await fetchProgramEditions(apimBaseUrl, configContext, selectedProgram, culture);
        if (!newClassData) {
            const specialtyEditions = data.filter((i: ProgramEdition) => i.edition !== 'none').map((edition: ProgramEdition, index: number) => ({
                ...edition,
                isSelected: props.classDetails?.specialtyEdition === edition.edition
            }));
            setWorkbooks(specialtyEditions);

            if (specialtyEditions && specialtyEditions.length > 0) {
                handleChangeByGetEvent({ specialtyEdition: specialtyEditions.filter((i: Workbook) => i.isSelected)[0].edition });
            } else {
                handleChangeByGetEvent({ specialtyEdition: 'none' });
            }
        } else {
            const specialtyEditions = data.filter((i: ProgramEdition) => i.edition !== 'none').map((edition: ProgramEdition, index: number) => ({
                ...edition,
                 isSelected: index === 0
            }));
            setWorkbooks(specialtyEditions);
            if (specialtyEditions && specialtyEditions.length > 0) {
                handleChangeByGetEvent({ specialtyEdition: specialtyEditions.filter((i: Workbook) => i.isSelected)[0].edition });
            } else {
                handleChangeByGetEvent({ specialtyEdition: 'none' });
            }
        }
    };

    const removeUpdateAlert = () => {
        setUpdateAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });
    };

    const showUpdateAlert = (alert: ShowAlert) => {
        setUpdateAlert({ ...alert, show: true });
    };

    const getOrganizationSeats = async (courseId: string) => {
        const payload = {
            courseId,
            organizationId: rbac.userContext.OrganizationId.toString(),
            isBA,
            isCI,
        };
        const data = await fetchOrganizationSeats(configContext, payload);
        setOrgSeats(data);
    };

    const getQualification = () => {
        if (formFieldData.trainingFormat === 'blended') {
            return formFieldData.lmsQualification;
        } else {
            return (formFieldData.additionalContent === 'none' || !formFieldData.additionalContent) ? null : formFieldData.additionalContent;
        }
    };

    const getLanguagesAndIndustries = async () => {
        const data = await fetchClassLanguagesAndIndustries(apimBaseUrl, configContext, selectedProgram, getQualification(), formFieldData.specialtyEdition);
        setCourses(data);
    };

    const getCourseObject = async () => {
        const data: ICourseObject = await fetchClassCourseObject(
            apimBaseUrl,
            configContext,
            selectedProgram,
            formFieldData.specialtyEdition,
            formFieldData.classroomLanguage,
            formFieldData.classroomIndustry,
            getQualification()
        );
        setCourseObject(data);
        if (data && data.courseId) {
            getOrganizationSeats(data.courseId);
        } else {
            setOrgSeats([]);
        }
    };

    const getPrograms = async (ids: string[]) => {
        let allTrainablePrograms:string[] = [];
        const data = (isCPIStaff()) ? await fetchAllPrograms(apimBaseUrl, configContext, culture) : await fetchPrograms(apimBaseUrl, configContext, ids);

        if ((Array.isArray(data) && (data.length > 0)) || isBA) {
            if (data.length > 0) {
                data.forEach((program: any) => {
                    let trainableProgramsOnThisSpecialty = trainablePrograms(program, culture, true);
                    allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
                });
            } else {
                let trainableProgramsOnThisSpecialty = trainablePrograms("", culture, false, isBA);
                allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
            }

        }
        // Remove duplicated specialty codes
        setPrograms([...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program })));
    };

    const getSkills = async () => {
        const payload: any = {
            specialty: formFieldData.specialty,
            edition: formFieldData.specialtyEdition,
            additionalContent: (formFieldData.additionalContent === 'None' || formFieldData.additionalContent === 'none' || !formFieldData.additionalContent) ? null : formFieldData.additionalContent,
        };
        const classSkillsData = (ITLClassSkills) ? await fetchSkills(apimBaseUrl ?? "", configContext, payload) : null;
        setApiSkills(classSkillsData);
        const classDetailsSkillsData = (ITLClassSkills) ? props.classDetails?.classSkills : [];

        const processedSkills = processSkillsData(classSkillsData, classDetailsSkillsData);

        setSkills(processedSkills);
        setSkillsSelected(prepareSkillsDataForEdit(processedSkills, classDetailsSkillsData));
    };

    React.useEffect(() => {
        if (!ITLAllowFullEditClass) {
            setEditable(false);
        }
    }, [ITLAllowFullEditClass]);

    React.useEffect(() => {
        if (apiSkills?.length > 0) {
            const processedSkills = processSkillsData(apiSkills, props.classDetails?.classSkills);
            setSkills(processedSkills);
            setSkillsSelected(prepareSkillsDataForEdit(processedSkills, props.classDetails?.classSkills));
        }
    } , [props.classDetails?.classSkills, apiSkills]);

    React.useEffect(() => {
        handleChangeByGetEvent({
            userId: rbac.userContext.PingId,
            organizationId: rbac.userContext.OrganizationId,
            organizationName: rbac.userContext.OrganizationName,
            classroomFormat: null,
            classroomFormatBlended: null,
            classroomLanguage: null,
            classroomIndustry: null,
            classroomOrganization: null,
            desiredLms: null,
            seatOrganizationId: null,
            emailSend: null,
            emailScheduledDate: null,
            emailMessage: null,
            qualification: null,
            lmsQualification: null,
            lmsQualificationRadio: null
        });
    }, []);

    React.useEffect(() => {
        handleChangeByGetEvent({
            additionalInstructors: additionalInstructors.filter((additionalInstructor) => additionalInstructor.show).length
        });
    }, [additionalInstructors]);

    React.useEffect(() => {
        handleChangeByGetEvent({ additionalContent: (additionalContent?.length === 0 || props.classDetails.qualification === 'NULL') ? null : props.classDetails.qualification });
    }, [additionalContent]);

    React.useEffect(() => {
        if (selectedProgram) {
            getOrgLmsSettings();
            getClassSettings();
            getTypesOfTraining();
            getProgramEditions();
        }
    }, [selectedProgram, newClassData]);

    React.useEffect(() => {
        if (classSettings) handleChangeByGetEvent({ blueCardId: classSettings.blueCardId });
    }, [classSettings]);

    React.useEffect(() => {
        // Setting Training Type from Class Details
        if (typesOfTraining && typesOfTraining.length > 0) {
            handleChangeByGetEvent({ trainingType: props.classDetails?.trainingType });
        }
    }, [typesOfTraining]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            getOrganizationBranch();
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey && (ITLIntermediatePhysicalSkills != "pending")) {
            const instrucs = Object.entries(selectedInstructors);
            if (selectedInstructors.primaryInstructor) {
                const instrucsIds = instrucs.map(([key, value]) => value) as string[];
                if (instrucs && instrucs.length > 0) getPrograms(instrucsIds);
            }
        }
    }, [configContext?.SystemConfiguration?.ApimKey, selectedInstructors, ITLIntermediatePhysicalSkills]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            const instrucs = Object.entries(selectedInstructors);
            const instrucsIds = instrucs.map(([key, value]) => value) as string[];
            if (instrucs.length > 0 && selectedProgram) getAdditionalContent(instrucsIds);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, selectedProgram, selectedInstructors]);

    const detected = instructors.filter((instructor) => instructor.userId === props.classDetails?.classInstructors.filter((i) => i.isPrimary)[0].userId)[0];
    const additionalDetected = props.classDetails?.classInstructors.filter((i) => !i.isPrimary).filter((instructor) => instructors.map((i) => i.userId).includes(instructor.userId)).reduce((prev, current, index) => {
        return {
            ...prev,
            [`additionalInstructor-${index}`]: current.userId
        };
    }, { primaryInstructor: detected?.userId });

    React.useEffect(() => {
        setSelectedInstructors(additionalDetected);
    }, [Object.entries(additionalDetected)?.length, instructors]);

    const handleCancel = () => {
        window.scrollTo(0, 0);
        props.onCancel();
    };

    const handleUpdateClass = async (params: any) => {
        const instructorsParams = params.instructors;
        const datesParams = params.classDates.map(({id, ...rest}: any) => ({
            ...rest,
            date: rest.date.split('T')[0]
        }));;
        const trainingClassroom = trainingFormat === "classroom";
        const internalLms = params.desiredLms === "internal";
        const expirationId = expirationDate.filter((i) => i.checked)[0].id;
        if (expirationOffsets[expirationId] !== null) {
            params.expirationOffset = expirationOffsets[expirationId];
            params.expirationDate = null;
        } else {
            params.expirationOffset = null;
        }
        // Removing unused data on Create Class, data only being used for form validation & data display
        delete params.hasAdditionalContent;
        delete params.additionalInstructors;
        delete params.isAdditionalContentRequired;

        const seatOrganizationIdToUse = (params.classroomOrganization) ? params.classroomOrganization : params.virtualOrganization;

        params = {
            ...params,
            blueCardRequired: params.blueCardId,
            qualification: (params.additionalContent === 'none' || !params.additionalContent) ? null : params.additionalContent,
            language: (trainingClassroom || !internalLms) ? null : params.classroomLanguage,
            industry: (trainingClassroom || !internalLms) ? null : params.classroomIndustry,
            seatOrganizationId: (trainingClassroom || !internalLms) ? null : seatOrganizationIdToUse,
            seatOrganizationName: (trainingClassroom || !internalLms) ? null : orgSeats.filter((i) => {
                return (i.organizationId === params.classroomOrganization) || (i.organizationId === params.virtualOrganization)
            })[0]?.organizationName,
            classId: props.classDetails?.classId,
            courseId: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.courseId ? courseObject.courseId : null,
            courseObjectId: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.courseObjectId ? courseObject.courseObjectId : null,
            importId: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.importId ? courseObject.importId : null,
            fileUrl: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.fileUrl ? courseObject.fileUrl : null,
            courseName: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.courseName ? courseObject.courseName : null,
            courseCulture: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.courseCulture ? courseObject.courseCulture : null,
            contactHours: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.contactHours ? courseObject.contactHours : null,
            templateId: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.templateId ? courseObject.templateId : null,
            estimatedTime: (trainingClassroom || !internalLms) ? null : courseObject ? getTimeInSeconds(courseObject.estimatedTimeHours, courseObject.estimatedTimeMinutes) : null,
            thumbnailUrl: (trainingClassroom || !internalLms) ? null : courseObject && courseObject.thumbnailUrl ? courseObject.thumbnailUrl : null,
            classroomFormat: classroomFormat,
        };
        delete params.classroomOrganization;
        delete params.virtualOrganization;
        delete params.classroomLanguage;
        delete params.classroomIndustry;
        delete params.classroomFormatBlended;
        delete params.blueCardId;
        delete params.additionalContent;
        params.classDates = [
            ...datesParams
        ];
        params.instructors = [
            ...instructorsParams
        ];
        params.trainingFormat = trainingFormat;
        setIsLoading(true);

        try {
            const { data, status } = await updateClass(apimBaseUrl, configContext, params);
            params.instructors = instructorsParams;
            params.classDates = datesParams;
            params.expirationOffset = expirationOffsets[expirationId];
            // If Update Class Response is 202
            if (status === 200 && typeof data.classId === "string") {
                removeUpdateAlert();
                dispatch(loadUpdatedClassFromPayload(data));
                window.scrollTo(0, 0);
                setIsLoading(false);                
                props.onSuccess();
            }
        } catch (e) {
            showUpdateAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        if (programs.filter((program) => program.value === selectedProgram)) {
            handleChangeByGetEvent({
                specialty: selectedProgram
            });
        } else {
            setSelectedProgram('')
        }
    }, [programs, selectedProgram]);

    const handleSkillsSelected = (ev: CustomEvent) => {

        let tempSkillsState = skillsSelectedRef.current;

        const allSelected = ev.detail.filter((chk: ClassSkillCheck) => chk.checked);
        const allUnselected = ev.detail.filter((chk: ClassSkillCheck) => !chk.checked);

        // add to state
        if (allSelected.length > 0) {
            
            allSelected.forEach((skill: ClassSkillCheck) => {
                if (!tempSkillsState.some((s: any) => s.skillId === skill.id)) {
                    // trying to add classSkillId to selected skills
                    tempSkillsState.push({ skillId: skill.id, skillName: skill.name, category: skill.category, subCategory: skill.subCategory, deleted: false });
                } else {
                    tempSkillsState = tempSkillsState.map((s: any) => {
                        if (s.skillId === skill.id) {
                            // trying to add classSkillId to selected skills
                            return { ...s, deleted: false };
                        }
                        return s;
                    });
                }
            });

        }

        if (allUnselected.length > 0) {
            allUnselected.forEach((skill: ClassSkillCheck) => {
                tempSkillsState = tempSkillsState.map((s: any) => {
                    if (s.skillId === skill.id) {
                        // adding classSkillId to unselected skills
                        if (props.classDetails?.classSkills.filter((i) => i.skillId === s.skillId)[0]?.classSkillId) {
                            return { ...s, deleted: true, classSkillId: props.classDetails?.classSkills.filter((i) => i.skillId === s.skillId)[0].classSkillId };
                        } else {
                            return { ...s, deleted: true };
                        }
                    }
                    return s;
                });
            });
        }

        setSkillsSelected(tempSkillsState);

        //publish("mySkillsChanged", {});
    };

    React.useEffect(() => {
        subscribe("skillsSelected", handleSkillsSelected);
        //subscribe("mySkillsChanged", (e: any) => {console.log(" :: A CHANGE :: "); console.log(mySkills); setSkillsSelected(mySkills);});

        return () => {
            unsubscribe("skillsSelected", handleSkillsSelected);
            //unsubscribe("mySkillsChanged", (e: any) => setSkillsSelected(mySkills));
        }
    }, []);

    // skills are set to form hook here
    React.useEffect(() => {
        handleChangeByGetEvent({ classSkills: skillsSelected })
    }, [skillsSelected]);

    React.useEffect(() => {
        const selectedInstructorsArray = Object.entries(selectedInstructors);

        // for each selected instructor, find the matching instructor in the orgInstructors array
        // then find the matching classInstructor in the classInstructors array
        // then create a new object for the instructors array payload

        if (selectedInstructorsArray && selectedInstructorsArray.length > 0 && selectedInstructors?.primaryInstructor) {
            const selectedInstructorsForSubmitPayload = selectedInstructorsArray.map(([instructorPositionKey, selectedInstructorUserId], selectedInstructorIndex) => {
                const orgInstructorWithMatchingUserId = instructors.filter((orgInstructor) => orgInstructor.userId === selectedInstructorUserId)[0];
                let classInstructor = classDetails?.classInstructors.filter((classInstructor) => classInstructor.userId === orgInstructorWithMatchingUserId.userId)[0];
                if (!classInstructor) classInstructor = classDetails?.classInstructors[selectedInstructorIndex];

                return {
                    isPrimary: instructorPositionKey === "primaryInstructor",
                    userId: orgInstructorWithMatchingUserId.userId,
                    firstName: orgInstructorWithMatchingUserId.firstName,
                    lastName: orgInstructorWithMatchingUserId.lastName,
                    classInstructorId: classInstructor?.classInstructorId || null
                };
            });
            handleChangeByGetEvent({ instructors: selectedInstructorsForSubmitPayload });
            dispatch(loadClassDetailsInstructors(selectedInstructorsForSubmitPayload));
        }
    }, [selectedInstructors]);

    React.useEffect(() => {
        const additional = additionalDays.filter((day) => day.show).map((day) => {
            return {
                id: day.id,
                date: day.date.split('T')[0],
                initialHours: day.initialHours,
                refresherHours: day.refresherHours,
                classDateId: day.classDateId
            }
        });
        handleChangeByGetEvent({ classDates: [{ id: 'primaryDay', ...primaryDay }, ...additional]});
    }, [additionalDays, primaryDay]);

    const {
        formFieldData,
        errors,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit,
        handleResetErrors
    } = useForm(handleUpdateClass, validateRules);

    React.useEffect(() => {
        let desiredLms = (props.classDetails?.desiredLms) ? props.classDetails?.desiredLms : null;
        if (!desiredLms) {
            // if both lms options are possible but one is not selected on classdetails already, default to external, otherwise null
            desiredLms = (orgLmsSettings?.usesExternalLms && !orgLmsSettings?.usesCpiLms) ? "external" : null;
        }

        if (props.classDetails?.trainingFormat === 'classroom') { desiredLms == null }

        handleChangeByGetEvent({
            desiredLms
        });
    }, [orgLmsSettings, props.classDetails]);

    React.useEffect(() => {
        if (orgLmsSettings && !orgLmsSettings.usesExternalLms && !orgLmsSettings.usesCpiLms && formFieldData.desiredLms === null && formFieldData.trainingFormat) {
            const formats = trainingFormats.filter((i) => i.value !== "blended");
            setTrainingFormats(formats);
            handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0]?.value });
        }
    }, [formFieldData.desiredLms, formFieldData.trainingFormat, orgLmsSettings]);

    const handleResetInstructorChange = () => {
        removeUpdateAlert();
        setNewClassData(true);
        setSelectedProgram("");
        setClassSettings({
            blueCardId: false,
            dates: false,
            hours: false,
            expiration: false,
            skills: false
        });
        setWorkbooks([]);
        setPrograms([]);
        setSkills({ categories: [] });
        setTypesOfTraining([]);
        setTrainingFormats([]);
        setAdditionalContent([]);
        handleChangeByGetEvent({ skills: [] });
        setExpirationDate([
            { id: 'expirationDate1', checked: false, label: t("6 Months"), value: setDate(6) },
            { id: 'expirationDate2', checked: true, label: t("12 Months"), value: setDate(12) },
            { id: 'expirationDate5', checked: false, label: t("Until"), value: '' },
        ]);
        setSkillsSelected([]);
        if (!isClassExpired) {
            setPrimaryDay({
                date: todayDateFormat,
                initialHours: '0',
                refresherHours: '0',
                classDateId: props.classDetails?.classDates[0].classDateId
                
            });
        } else {
            setPrimaryDay({
                date: props.classDetails?.classDates[0].date.split('T')[0],
                initialHours: props.classDetails?.classDates[0].initialHours.toString(),
                refresherHours: props.classDetails?.classDates[0].refresherHours.toString(),
                classDateId: props.classDetails?.classDates[0].classDateId
            });
        }
        handleChangeByGetEvent({
            userId: rbac.userContext.PingId,
            organizationId: rbac.userContext.OrganizationId,
            organizationName: rbac.userContext.OrganizationName,
            classroomFormat: 'classroom',
            classroomFormatBlended: 'classroom',
            specialty: null,
            specialtyEdition: null,
            //classDates: [],
            hasAdditionalContent: null,
            isAdditionalContentRequired: null,
            additionalContent: null,
            classroomLanguage: null,
            classroomIndustry: null,
            classroomOrganization: null,
            desiredLms: (orgLmsSettings?.usesExternalLms) ? "external" : "internal",
            seatOrganizationId: null,
            emailSend: null,
            emailScheduledDate: null,
            emailMessage: null,
            qualification: null,
            lmsQualification: null,
            lmsQualificationRadio: null,
            skills: [],
            trainingType: null,
            trainingFormat: null
        });
    };

    React.useEffect(() => {
        if (selectedProgram && formFieldData.specialtyEdition) getLanguagesAndIndustries();
    }, [selectedProgram, formFieldData.specialtyEdition, formFieldData.additionalContent, formFieldData.lmsQualification]);

    React.useEffect(() => {
        const { specialtyEdition, classroomLanguage, classroomIndustry, specialty, additionalContent } = formFieldData;
        const valuesToCheckForNotUndefined = [specialtyEdition, classroomLanguage, classroomIndustry, specialty, additionalContent];
        if (valuesToCheckForNotUndefined.every(val => val !== undefined)) getCourseObject();
    }, [
        formFieldData.additionalContent,
        formFieldData.specialty,
        formFieldData.specialtyEdition,
        formFieldData.classroomLanguage,
        formFieldData.classroomIndustry,
        formFieldData.lmsQualification
    ]);

    React.useEffect(() => {
        if (props.classDetails) {
            handleChangeByGetEvent({
                className: props.classDetails?.className,
                classroomLanguage: props.classDetails?.language,
                classroomIndustry: props.classDetails?.industry,
                classroomOrganization: props.classDetails?.seatOrganizationId,
                virtualOrganization: props.classDetails?.seatOrganizationId,
                classroomFormat: props.classDetails?.classroomFormat,
                classroomFormatBlended: props.classDetails?.classroomFormat,
                seatOrganizationId: props.classDetails?.seatOrganizationId,
                trainingFormat: props.classDetails?.trainingFormat
            });
        }
    }, [props.classDetails, newClassData]);

    React.useEffect(() => {
        if (programs && programs.length > 0 && !newClassData) {
            setSelectedProgram(props.classDetails?.specialty);
            handleChangeByGetEvent({
                specialty: props.classDetails?.specialty,
                trainingType: props.classDetails?.trainingType
            })
        }
    }, [programs, newClassData]);

    React.useEffect(() => {
        if (formFieldData.specialty && formFieldData.specialtyEdition) {
            getSkills();
        }
    }, [formFieldData.specialty, formFieldData.specialtyEdition, formFieldData.additionalContent]);

    const handleSelectWorkbook = (edition: string) => {
        handleChangeByGetEvent({ specialtyEdition: edition });
        setWorkbooks((prevState) => {
            return prevState.map((workbook) => ({
                ...workbook,
                isSelected: workbook.edition === edition
            }));
        });
    };

    React.useEffect(() => {
        if (formFieldData.hasAdditionalContent) getAdditionalContent(formFieldData.instructors.map((i: any) => i.userId));
    }, [formFieldData.specialtyEdition, formFieldData.hasAdditionalContent]);

    React.useEffect(() => {
        if (selectedProgram && formFieldData.specialtyEdition) {
            getTrainingFormats();
        }
    }, [selectedProgram, formFieldData.specialtyEdition, formFieldData.additionalContent]);

    React.useEffect(() => {
        if (selectedProgram) {
            // After last call on initial loading is finished, set to true
            if (!initialHasLoaded) setTimeout(() => setInitialHasLoaded(true), 500);
        }
    }, [selectedProgram, formFieldData.additionalContent, formFieldData.specialtyEdition, orgLmsSettings]);

    const handleChangeSpecialty = (e: FormSelectOption) => {
        setSelectedProgram(e.value);
        handleChangeByGetEvent({ specialtyEdition: null });
        handleChangeByGetEvent({ specialty: e.value });
    };

    const handleChangeAdditionalContent = (e: FormSelectOption) => {
        handleChangeByGetEvent({ additionalContent: e.value });
    };

    const handleChangeTrainingType = (e: FormSelectOption) => {
        handleChangeByGetEvent({ trainingType: e.value });
    };

    const handleChangeExpirationDate = (e: any) => {
        setExpirationDate((prevState) => prevState.map((training) => ({ ...training, checked: training.id === e.target.id })));
    };

    const handleChangeTrainingFormat = (e: any) => {
        setTrainingFormats((prevState) => prevState.map((format) => ({ ...format, checked: format.id === e.target.id })));
        handleChangeByGetEvent({ trainingFormat: e.target.value });
        if (e.target.value !== props.classDetails?.trainingFormat) {
            handleChangeByGetEvent({ classroomFormat: 'classroom', classroomFormatBlended: 'classroom' });
        } else {
            handleChangeByGetEvent({ classroomFormat: props.classDetails?.classroomFormat ?? 'classroom', classroomFormatBlended: props.classDetails?.classroomFormat ?? 'classroom' });
        }
    };

    const handleExpirationDateUntil = (e: any, training: any) => {
        const date = new Date(e.target.value).toISOString().split('T')[0];
        setExpirationDate((prevState) => prevState.map((valid: any) => valid.id === training.id ? ({ ...valid, value: date }) : valid));
    };

    const isUntilSelected = expirationDate.find((i) => i.id === 'expirationDate5').checked;

    React.useEffect(() => {
        const expDate = expirationDate.filter((i) => i.checked)[0];
        if (expDate) handleChangeByGetEvent({ expirationDate: expDate.value });
    }, [expirationDate]);

    const showAlert: boolean = Object.entries(errors).length > 0;
    
    React.useEffect(() => {
        if (workbooks && workbooks.length > 0) {
            setSelectedEdition(workbooks.filter((edition) => edition.isSelected)[0]);
        }
    }, [workbooks]);
    
    React.useEffect(() => {
        if (selectedEdition) handleChangeByGetEvent({ hasAdditionalContent: selectedEdition.hasAdditionalContent });
    }, [selectedEdition]);
    const additionalContentOptions = ():FormSelectOption[] => {
        // translate additional content label strings, handling with/without default value cases
        let additionalContentOptionsWithDefault = returnAdditionalContent([selectedEdition.defaultContent, ...additionalContent]).map((option) => {
            option.label = tp(option.label) as any;
            return option;
        }) || [];
        let additionalContentOptionsWithoutDefault = returnAdditionalContent(additionalContent).map((option) => {
            option.label = tp(option.label) as any;
            return option;
        }) || [];

        if (selectedEdition.defaultContent) {            
            return [
                { label: t("CPI.UI.OrganizationType.None"), value: null },
                ...additionalContentOptionsWithDefault
            ];
        }
        return [
            { label: t("CPI.UI.OrganizationType.None"), value: null },
            ...additionalContentOptionsWithoutDefault
        ]
    };

    React.useEffect(() => {
        if (formFieldData.trainingFormat === 'blended') {
            if (formFieldData.additionalContent === 'MH' || formFieldData.additionalContent === 'ASD' || formFieldData.additionalContent === 'TRA') {
                handleChangeByGetEvent({ lmsQualificationRadio: 'yes', lmsQualification: formFieldData.additionalContent });
            } else {
                handleChangeByGetEvent({ lmsQualificationRadio: null, lmsQualification: null });
            }
        } else {
            handleChangeByGetEvent({ lmsQualification: null, lmsQualificationRadio: null });
        }
    }, [formFieldData.additionalContent, formFieldData.trainingFormat]);

    React.useEffect(() => {
        if (formFieldData.trainingFormat === 'blended') {
            if (formFieldData.lmsQualificationRadio === 'yes') {
                handleChangeByGetEvent({ lmsQualification: formFieldData.additionalContent });
            } else {
                handleChangeByGetEvent({ lmsQualification: null });
            }
        } else {
            handleChangeByGetEvent({ lmsQualification: null, lmsQualificationRadio: null });
        }
    }, [formFieldData.lmsQualificationRadio, formFieldData.trainingFormat]);

    const handleChangeClassroomLanguage = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomLanguage: e.value,
        });
    };

    const handleChangeClassroomIndustry = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomIndustry: e.value,
        });
    };

    const handleChangeLmsSelection = (e: any) => {
        handleChangeByGetEvent({
            desiredLms: e.target.value,
        });
    };

    const handleChangeLmsQualificationRadio = (e: any) => {
        handleChangeByGetEvent({ lmsQualificationRadio: e.target.value });
    };

    const handleChangeClassroomFormat = (e: any) => {
        handleChangeByGetEvent({
            classroomFormat: e.target.value,
        });
    };

    const handleChangeClassroomFormatBlended = (e: any) => {
        handleChangeByGetEvent({
            classroomFormatBlended: e.target.value,
        });
    };

    const handleChangeClassroomOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomOrganization: e.value,
        });
    };

    const handleChangeVirtualOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            virtualOrganization: e.value,
        });
    };

    const handleChangePrimaryInstructor = () => {
        setShowPrimaryInstructorChange(false);
        handleChangeByGetEvent({ specialty: null });
        const instrucs = Object.entries(selectedInstructors);
        const instrucsIds = instrucs.map(([key, value]) => value) as string[];
        if (instrucs.length > 0) getPrograms(instrucsIds);
    };

    if (!initialHasLoaded || (ITLAllowFullEditClass == "pending")) {
        return (
            <LoadingContent
                title={t("Loading")}
                icon={
                    <div className="loading-content-participants">
                        <i className="fas fa-duotone fa-spinner icon" />
                    </div>
                }
            />
        );
    }

    return (
        <>
            <div className="alert-wrapper mt-4">{returnPageLevelAlerts(pageLevelAlerts)}</div>
            {updateAlert?.show && (
                <div className="alert-wrapper mt-4">
                    <Alert
                        removeAlertParentHandler={removeUpdateAlert} 
                        state={updateAlert.alertLevel}
                        arrowLink={updateAlert.arrowLink}
                        description={updateAlert.description}
                        canDismiss={updateAlert.canDismiss}
                    />
                </div>
            )}
            <>
                {showAlert && (
                    <div className="class-create-detail-item">
                        <Alert
                            canDismiss={false}
                            description={t("Class-Detail-Error")}
                            state={{ alertLevel: "error" }}
                        />
                    </div>
                )}
                <div className="class-create-detail-item-column">
                    {isClassExpired ? (
                        <div>
                            <label className="bold form-label fs14 filter-select-label">{t("Class Name")}</label>
                            <div>{formFieldData.className}</div>
                        </div>
                    ) : (
                        <TextInput
                            id="className"
                            name="className"
                            label={t("Class Name")}
                            isError={errors.hasOwnProperty("className")}
                            value={formFieldData.className}
                            changeAction={handleChangeByUserEvent}
                            errorLabel={errors.className}
                            isRequired
                        />
                    )}
                </div>
                <div className="class-create-detail-item-column">
                    <AdditionalInstructor
                        id="instructors"
                        name="instructors"
                        isError={errors.hasOwnProperty("instructors")}
                        errorLabel={errors.instructors}
                        setSelected={setSelectedInstructors}
                        additionalInstructors={additionalInstructors}
                        setAdditionalInstructors={setAdditionalInstructors}
                        specialtySelected={formFieldData.specialty}
                        selectedOptions={selectedInstructors}
                        editable={!isClassExpired}
                        fromClassDetails={true}
                        handleChangeBySpecialty={handleResetInstructorChange}
                        removable
                        lockPrimaryInstructor
                        isRequired
                        options={instructors && instructors.length > 0 ? [...returnInstructorsCertifications(instructors)] : []}
                    />
                </div>
                <div className="class-create-detail-item-column">
                    <Select
                        id="specialty"
                        label={t("CPI Program")}
                        isReadOnly={!editable || isClassExpired}
                        name="specialty"
                        isError={errors.hasOwnProperty("specialty")}
                        isRequired
                        value={formFieldData.specialty}
                        changeFilterAction={handleChangeSpecialty}
                        filter
                        errorLabel={errors.specialty}
                        options={[
                            ...returnPrograms(programs)
                        ]}
                    />
                </div>
                {(classSettings?.hasWorkbook && (workbooks?.length > 0)) && (
                    <>
                        <div className="class-create-detail-item">
                            <SelectWorkbookList
                                id="specialtyEdition"
                                name="specialtyEdition"
                                isRequired
                                editable={editable && !isClassExpired}
                                isError={errors.hasOwnProperty("specialtyEdition")}
                                errorLabel={errors.specialtyEdition}
                                label={t("Participant-Workbook-using")}
                                selectWorkbook={handleSelectWorkbook}
                                workbooks={workbooks}
                                archived={isClassExpired}
                            />
                        </div>
                        {additionalContent && additionalContent.length > 0 && selectedEdition && selectedEdition.hasAdditionalContent && (
                            <div className="class-create-detail-item-column">
                                <Select
                                    id="additionalContent"
                                    label={t("Additional Content")}
                                    name="additionalContent"
                                    isError={errors.hasOwnProperty("additionalContent")}
                                    isRequired
                                    isReadOnly={!editable || isClassExpired}
                                    value={formFieldData.additionalContent}
                                    changeFilterAction={handleChangeAdditionalContent}
                                    filter
                                    errorLabel={errors.additionalContent}
                                    defaultValue
                                    options={additionalContentOptions()}
                                />
                            </div>
                        )}
                    </>
                )}
                {typesOfTraining && typesOfTraining.length > 0 && (
                    <>
                        <div className="class-create-detail-item-column">
                            <Select
                                id="trainingType"
                                label={t("Type of Training")}
                                name="trainingType"
                                isError={errors.hasOwnProperty("trainingType")}
                                isReadOnly={isClassExpired}
                                isRequired
                                value={formFieldData.trainingType}
                                changeFilterAction={handleChangeTrainingType}
                                filter
                                errorLabel={errors.trainingType}
                                options={[
                                    ...returnTypesOfTraining(typesOfTraining)
                                ]}
                            />
                        </div>
                        {classSettings && classSettings.dates && (
                            <div className="class-create-detail-item class-create-date-hours">
                                <DateHours
                                    primaryDay={primaryDay}
                                    setPrimaryDay={setPrimaryDay}
                                    removable
                                    settings={{ dates: classSettings.dates, hours: classSettings.hours }}
                                    typeOfTraining={formFieldData.trainingType}
                                    additionalDays={additionalDays}
                                    setAdditionalDays={setAdditionalDays}
                                    errors={errors}
                                    isReadOnly={isClassExpired}
                                    isError={errors.hasOwnProperty("classDates")}
                                    errorLabel={errors.classDates}
                                    isRequired
                                />
                            </div>
                        )}
                    </>
                )}
                {classSettings && classSettings.expiration && (
                    <div className="class-create-detail-item class-create-training-valid">
                        {!isClassExpired ? (
                            <>
                                <div>
                                    <RadioList
                                        id="training-valid"
                                        name="training-valid"
                                        isRequired
                                        onChange={handleChangeExpirationDate}
                                        label={t("How-Long-Training-Valid")}
                                        errorLabel="Select date for training"
                                        radios={
                                            expirationDate.map((training) => {
                                                if (training.label === 'Until') {
                                                    return {
                                                        ...training,
                                                        label: (
                                                            <div className="class-create-row gap">
                                                                <span>Until Date</span>
                                                                {isUntilSelected && (
                                                                    <DateInput
                                                                        onChange={(e) => handleExpirationDateUntil(e, training)}
                                                                        id="expirationDate"
                                                                        name="expirationDate"
                                                                        isRequired={false}
                                                                        showLabel={false}
                                                                        overrideOptionalLabel=""
                                                                        value={training.value}
                                                                        isActive={training.checked}
                                                                        isReadOnly={false}
                                                                        isError={errors.hasOwnProperty("expirationDate")}
                                                                        errorLabel={errors.expirationDate}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    };
                                                }
                                                return training;
                                            })}
                                    />
                                </div>
                                <div className="class-create-center-icon">
                                    <p className="class-create-alert">
                                        <i className="fa-sharp fa-light fa-circle-info class-create-alert-icon"></i>
                                        {t("Expiration-Date-Alert")}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div>
                                <label className="form-label bold fs14">{t("How-Long-Training-Valid")}</label>
                                <p>{new Date(props.classDetails?.expirationDate).toLocaleDateString(locale)}</p>
                            </div>
                        )}
                    </div>
                )}
                {(editable && !isClassExpired) ? (
                    trainingFormats && trainingFormats.length > 0 && (
                        <div className="class-create-detail-item-w70">
                            <TrainingFormats
                                trainingFormats={trainingFormats}
                                courses={courses}
                                orgSeats={returnOrganizationsSeats(orgSeats)}
                                editable={editable}
                                orgLmsSettings={orgLmsSettings}
                                onChangeFormat={handleChangeTrainingFormat}
                                formData={formFieldData}
                                errors={errors}
                                handleChangeVirtualOrganization={handleChangeVirtualOrganization}
                                handleChangeClassroomIndustry={handleChangeClassroomIndustry}
                                handleChangeClassroomLanguage={handleChangeClassroomLanguage}
                                handleChangeClassroomOrganization={handleChangeClassroomOrganization}
                                handleChangeClassroomFormat={handleChangeClassroomFormat}
                                handleChangeClassroomFormatBlended={handleChangeClassroomFormatBlended}
                                handleChangeLmsSelection={handleChangeLmsSelection}
                                handleChangeLmsQualificationRadio={handleChangeLmsQualificationRadio}
                                isMigrated={isMigrated}
                            />
                        </div>
                    )
                ) : (
                    <TrainingFormatsNonEdit
                        classDetails={props.classDetails}
                        orgSeats={returnOrganizationsSeats(orgSeats)}
                        trainingFormats={trainingFormats}
                        isMigrated={isMigrated}
                    />
                )}                

                {
                    ITLClassSkills && classSettings.skills && skills && skills.categories && skills.categories.length > 0 && (
                        <PhysicalSkills data={skills} isReadOnly={isClassExpired} />
                    )
                }

                {
                    !isClassExpired && (
                        <div className="class-create-row mt-5">
                            <DestructiveButton
                                clickAction={handleCancel}
                                additionalStyleClasses="inline-icon-left cancel-button"
                                label={<><i className="fa-light fa-xmark fa-sharp" /> {t("Cancel")}</>}
                            />
                            <Button
                                elementId="save-changes-button"
                                label={
                                    <div className="d-flex align-items-center justify-content-center">
                                        {isLoading && <Spinner size="sm" className="mr-2"/>} {t("Save Changes")}
                                    </div>
                                }
                                clickAction={(e) => handleSubmit(e, true)}
                                isDisabled={isClassExpired}
                                isSolid
                            />
                        </div>
                    )
                }
            </>
        </>
    );
};

export default ClassDetails;
