import * as React from "react";
import Select from "../../../Components/Form/select";
import { RbacContext } from "../../../rbac-context";
import { returnOrganizations } from "../../Components/DashboardPlatformUtils";

const BOESelector = (props: {
    isReadOnly?: boolean
}): JSX.Element => {
    const rbac = React.useContext(RbacContext);

    const handleChange = (e: any) => {
        rbac.setAltOrganization(e.value);
    };
    return (
        <div className="mb-3 d-flex flex-column align-items-start">
            <p className="mb-2 fw600">BASE OF EMPLOYMENT</p>
            <div style={{ minWidth: 350}}>
                <Select
                    filter
                    label=""
                    showLabel={false}
                    changeFilterAction={handleChange}
                    value={rbac.userContext.SelectedOrganization}
                    name="boe"
                    id="boe"
                    options={returnOrganizations(rbac.userContext.AltOrganizationsList)}
                    isReadOnly={props.isReadOnly}
                />
            </div>
        </div>
    );
};

export default BOESelector;
