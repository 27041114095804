import * as React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface ConditionalTooltipProps {
    condition: boolean;
    children: JSX.Element;
    overlay: JSX.Element;
}

const ConditionalOverlay = ({ condition, children, overlay }: ConditionalTooltipProps) => {
    return condition ? <OverlayTrigger overlay={overlay}>{children}</OverlayTrigger> : children;
};

export default ConditionalOverlay;
