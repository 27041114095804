import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Modal } from "react-bootstrap";
import { Learner, LearnerHistoryViewModel } from "../../Interfaces";
import { fetchLearnerHistory, removeLearnerAsync } from "../../ApiServices/Learner";
import { RbacContext } from "../../rbac-context";

import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface RemoveLearnerConfirmProps {
    learner: Learner;
    isVisible: boolean;
    onHide: () => void;
    hasLearnerCourses: boolean;
    handleRemove: (resp: JsonResponseModel<ApiResponse>) => void;
}

const RemoveLearnerConfirm = ({ learner, isVisible, onHide, handleRemove }: RemoveLearnerConfirmProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [learnerCourses, setLearnerCourses] = React.useState<LearnerHistoryViewModel[]>();
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        async function getLearnerCourses() {
            try {
                var learnerCourses = await fetchLearnerHistory(learner.UserId);
                setLearnerCourses(learnerCourses.Data.filter((x) => x.StatusEnum != 3));
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }         
        }

        getLearnerCourses();
    }, []);

    const handleSubmit = async () => {
        try {
            setSaving(true);
            const resp = await removeLearnerAsync(learner.UserId);
            handleRemove(resp);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }       
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Remove Learner")}
                    <h6>{learner.PrimaryOrganizationName}</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ wordWrap: "break-word" }}>
                    <strong>
                        {learner.FirstName} {learner.LastName} ({learner.EmailAddress})
                    </strong>
                </p>

                {learnerCourses?.length > 0 && (
                    <>
                        <p>{t("is currently enrolled in")}:</p>

                        {learnerCourses?.map((learnerCourse) => {
                            return (
                                <p key={learnerCourse.LearnerCourseId} style={{ textIndent: "20px" }}>
                                    <strong>
                                        {learnerCourse.ClassName} ({learnerCourse.CourseName})
                                    </strong>
                                </p>
                            );
                        })}

                        <p>{t("Removing this learner will return these seats to your organization.")}</p>
                    </>
                )}

                <p>* {t("If this learner has completed any courses, they will remain in the class.")}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide} disabled={saving}>
                    {t("Cancel")}
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? (
                        <>
                            <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                        </>
                    ) : (
                        t("Save")
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withAITracking(reactPlugin, RemoveLearnerConfirm);
