import * as React from "react";
import AlertLevelString from "../../Interfaces/Platform/AlertLevelString";

const CITaskBlock = (props: {
    title: string,
    complete?: boolean,
    subtitle?: string,
    clickable?: boolean
    alertLevel?: AlertLevelString,
    alertTitle?: string
}): JSX.Element => {
    const clickableStyleClass = props.clickable ? 'task-block-clickable' : '';
    const subtitle = props.subtitle ? props.subtitle : '';
    const alertTitle = props.alertTitle ? props.alertTitle : '';
    const complete = props.complete ? true : false;

    return (
        <>
            <a className={"link-unstyled task-block task-rounded  task-default " + clickableStyleClass}>

                { complete ? <div className="task-banner-tag-container">
                    <div className="task-banner"></div>
                </div> : null }

                <div className="task-block-content-container">
                    <div className="task-title-text bold">{props.title}</div>
                    <div className="small">
                        <span className="task-subtitle-text">{subtitle}</span>
                    </div>

                    { alertTitle ? <div className={"task-subtitle-text-container small " + props.alertLevel.alertLevel}>
                        <span className="fa-exclamation-circle task-subtitle-alert far" aria-hidden="true"></span>
                        <span className="task-subtitle-text">{alertTitle}</span>
                    </div> : null }

                </div>
                { props.clickable ? <i className="chevron-right fa-sharp fa-light fa-chevron-right"></i> : null }
            </a>
        </>
    );
};

export default CITaskBlock;
