import * as React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ActionMenu from "../../../Components/UI/action-menu-component/action-menu";
import ParticipantStatus from "../Participants/participant-status";
import ActionMenuItem from "../../../Components/UI/action-menu-component/action-menu-item";
import type { IParticipant } from "../../../Interfaces/Platform/Participants";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import useParsedTranslation from "../../../hooks/useParsedTranslation";

interface Props extends IParticipant {
    onCheck: (checked: boolean, id: string) => void;
    onViewEditParticipant: (participantId: string) => void;
    canBulkUpdateParticipants: boolean;
}

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const ParticipantListing = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const { isMobile } = useDetectWindowSize();

    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const displayDate = (date: string) => {
        return new Date(date).toLocaleString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
    };

    const displayCertifications = () => {
        const certifications = props.participantHistory;
        const length = certifications.length;

        return certifications && length > 0 ? certifications.map((certification, index) => {
            return (
                <>
                    <tr className="participant-listing-certification">
                        <div className="participant-listing-status">
                            <p className="mb-2 fw600 fs14 participant-listing-text">{tp(certification.specialty)}</p>
                            <p className={`${index === length - 1 ? "mb-2" : "mb-0"} participant-listing-status-text participant-listing-text fw600 fs14`}>
                                <ParticipantStatus certification={certification} />
                            </p>
                            {index === length - 1 && (
                                <p className="mb-0 participant-listing-details participant-listing-text color-blue small">
                                    <a
                                        href="javascript:;"
                                        className="text-reset text-decoration-none"
                                        onClick={() => handleViewEditParticipant(props.participantId)}
                                    >
                                        {t("See Details")} <i className="fa-light fa-chevron-right"></i>
                                    </a>
                                </p>
                            )}
                        </div>
                        <div className={`small participant-listing-blue-card participant-listing-text ${index < length - 1 && "participant-listing-inner"}`}>
                            {props.organizationName}
                        </div>
                        <div className={`small participant-listing-blue-card participant-listing-text ${index < length - 1 && "participant-listing-inner"}`}>
                            {certification.blueCardId}
                        </div>
                        <div className={`small participant-listing-last-trained participant-listing-text ${index < length - 1 && "participant-listing-inner"}`}>
                            {certification.startDate && displayDate(certification.startDate)}
                        </div>
                    </tr>
                    {length > 1 && index < length - 1 && <hr className="participant-listing-separator my-3" />}
                </>
            );
        }) : (
            <>
                <tr className="participant-listing-certification">
                    <div className="participant-listing-status"/>
                    <div className="small participant-listing-blue-card participant-listing-text">
                        {props.organizationName}
                    </div>
                    <div className="small participant-listing-blue-card participant-listing-text"/>
                    <div className="small participant-listing-last-trained participant-listing-text"/>
                </tr>
            </>
        )
    };

    const displayCertificationsMobile = () => {
        const certifications = props.participantHistory;
        const length = certifications.length;

        return certifications && length > 0 && certifications.map((certification, index) => {
            return (
                <div className={`d-flex flex-column ${length > 1 ? 'mb-3' : ''}`}>
                    <p className="mb-2 fs14 fw600">{tp(certification.specialty)}</p>
                    <p className="mb-2 participant-listing-status-text participant-listing-text fw600 fs14">
                        <ParticipantStatus certification={certification} />
                    </p>
                    {index === length - 1 && (
                        <p className="mb-0 participant-listing-details participant-listing-text color-blue small">
                            <a
                                href="javascript:;"
                                className="text-reset text-decoration-none"
                                onClick={() => handleViewEditParticipant(props.participantId)}
                            >
                                {t("See Details")} <i className="fa-light fa-chevron-right"></i>
                            </a>
                        </p>
                    )}
                </div>
            );
        });
    };

    const participantName = `${props.firstName} ${props.lastName}`;

    const handleViewEditParticipant = (participantId:string) => {
        props.onViewEditParticipant(participantId);
    };

    return !isMobile ? (
        <>
            {
                props.canBulkUpdateParticipants && (
                    <td className={`participant-listing-container participant-listing-checkbox-container ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-r`}>
                        <input
                            onChange={(e) => props.onCheck(e.target.checked, props.participantId)}
                            className="form-checkbox participant-listing-checkbox"
                            id={`${props.externalId}-participant-checkbox`}
                            checked={props.isChecked}
                            name="participant-checkbox"
                            type="checkbox"
                        />
                    </td>
                )
            }
            <td className={`participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                <p className="mb-2 fw600 color-black participant-listing-text">{participantName}</p>
                {props.email && <p className="mb-2 small participant-listing-text">{props.email}</p>}
                {props.externalId && <p className="mb-0 small participant-listing-text">{props.externalId}</p>}
            </td>
            <td className={`participant-listing-container participant-listing-certifications ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                {displayCertifications()}
            </td>
            <td className={`participant-listing-container participant-listing-actions ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                <ActionMenu isOpen={false} menuItemsArr={[
                    <ActionMenuItem data={{ label: t("View/Edit Participant"), clickAction: () => { handleViewEditParticipant(props.participantId)} }} />,
                    //<ActionMenuItem data={{ label: t("Add to Class"), clickAction: () => {} }} />
                ]} />
            </td>
        </>
    ) : (
        <div className="d-flex flex-column">
            <div className="participant-listing-grid">
                {
                    props.canBulkUpdateParticipants && (
                        <div id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                            <div className="participant-listing-grid-checkbox">
                                <input
                                    onChange={(e) => props.onCheck(e.target.checked, props.participantId)}
                                    className="ml-0 form-checkbox participant-listing-checkbox"
                                    id={`${props.externalId}-participant-checkbox`}
                                    checked={props.isChecked}
                                    name="participant-checkbox"
                                    type="checkbox"
                                />
                                <span className="fw600 fs14">{t("Name")}</span>
                            </div>
                        </div>
                    )
                }
                <div id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    <div className="participant-listing-grid-status">
                        <div className="participant-listing-grid-status-container">
                            <p className="mb-2 fw600 color-black participant-listing-text wrap-normal">{participantName}</p>
                            {props.email && <p className="mb-2 small participant-listing-text wrap-normal">{props.email}</p>}
                            {props.externalId && <p className="mb-0 small participant-listing-text wrap-normal">{props.externalId}</p>}
                        </div>
                    <ActionMenu isOpen={false} menuItemsArr={[
                        <ActionMenuItem data={{ label: t("View/Edit Participant"), clickAction: () => { handleViewEditParticipant(props.participantId)} }} />,
                        //<ActionMenuItem data={{ label: t("Add to Class"), clickAction: () => {} }} />
                    ]} />
                    </div>
                </div>
            </div>
            <div className="participant-listing-grid">
                <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                    <span className="fs14 fw600">{t("Status")}</span>
                </td>
                <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    {displayCertificationsMobile()}
                </td>
            </div>
            <div className="participant-listing-grid">
                <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                    <span className="fs14 fw600">{t("Organization")}</span>
                </td>
                <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    <span className="small">{props.organizationName}</span>
                </td>
            </div>
            <div className="participant-listing-grid">
                <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                    <span className="fs14 fw600">{t("Blue-Card-Number")}</span>
                </td>
                <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    <span className="small">{props.participantHistory[0]?.blueCardId}</span>
                </td>
            </div>
            <div className="participant-listing-grid">
                <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                    <span className="fs14 fw600">{t("Last Trained")}</span>
                </td>
                <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    <span className="small">{displayDate(props.participantHistory[0]?.startDate)}</span>
                </td>
            </div>
        </div>
    );

};

export default ParticipantListing;
