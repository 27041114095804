import * as React from "react";

import { Button, Container, Form, Modal } from "react-bootstrap";

import { EMAIL_REGEX_PATTERN } from "../../constants";
import { RbacContext } from "../../rbac-context";
import { updateParticipant } from "../Utils/participants";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "../../configuration-context";
import TypeaheadSearch from "../../Components/typeahead-search";


import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface ParticipantEditFormProps {
    isVisible: boolean;
    participant: any;
    organization: any;
    onSave: (resp: any) => void;
    onHide: () => void;
}

const ParticipantEditForm = ({ isVisible, participant, organization, onSave, onHide }: ParticipantEditFormProps) => {
    const { t } = useTranslation();
    const configContext = useContext(ConfigContext);

    const { userContext } = React.useContext(RbacContext);

    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [participantUpdate, setParticipantUpdate] = React.useState({ ...participant, orgId: organization.orgId, organizationName: organization.organizationName });
    const [currentOrganization, setCurrentOrganization] = React.useState(organization);

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        if (form.checkValidity()) {
            setSaving(true);
            try {
                const updateParticipantStatus = await updateParticipant(apimBaseUrl, configContext, participantUpdate);
                if (updateParticipantStatus) {
                    onSave(participantUpdate);
                }
            } catch (err) {
                onSave({
                    Error: {
                        Code: null,
                        MessageCode: 0,
                        Message: t("An unexpected error occurred, please try again.")
                    }
                });
            }
            setSaving(false);
            setValidated(false);
        }
    };

    const typeAheadRenderer = (option: any) => {
        return (
            <div key={option.OrganizationId}>
                <strong>{option.organizationName}</strong> (#{option.orgId})
            </div>
        )
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>{t("Participant Details")}</h3>
                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Container>
                        <Form.Group controlId="emailAddress">
                            <Form.Label>{t("Email")}</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                maxLength={150}
                                pattern={EMAIL_REGEX_PATTERN}
                                value={participantUpdate.email}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setParticipantUpdate({
                                        ...participantUpdate,
                                        email: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>{t("First Name")}</Form.Label>
                            <Form.Control
                                required
                                maxLength={50}
                                value={participantUpdate.firstName}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setParticipantUpdate({
                                        ...participantUpdate,
                                        firstName: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>{t("Last Name")}</Form.Label>
                            <Form.Control
                                required
                                maxLength={50}
                                value={participantUpdate.lastName}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setParticipantUpdate({
                                        ...participantUpdate,
                                        lastName: e.target.value
                                    })
                                }
                            />
                        </Form.Group>
                        <div style={{ paddingTop: "20px" }}>
                            <h4>{t("Organization")}</h4>
                        </div>
                        <div className="mt-2 mb-2">
                            {currentOrganization && <p style={{ wordBreak: "break-word" }}>{currentOrganization?.organizationName} <br/>#{currentOrganization?.orgId}</p>}
                        </div>
                        <div className="mt-2 mb-2">
                            <TypeaheadSearch
                                id="HiveSearch"
                                searchUrl={apimBaseUrl + "/Q/Organization/GetOrganizations/"}
                                placeholder={t("Search for an organization")}
                                labelKey="organizationName"
                                itemRenderer={typeAheadRenderer}
                                onChange={(item) => {
                                    setCurrentOrganization(item[0]);
                                    setParticipantUpdate({ ...participantUpdate, organizationId: item[0].organizationId, orgId: item[0].orgId, organizationName: item[0].organizationName });
                                }}
                            />
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ParticipantEditForm);
