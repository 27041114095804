import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { OrganizationInvoiceModel, OrganizationInvoiceSummaryModel, SelectModel } from "../Interfaces";

import InvoiceAccordions from "./Components/invoice-accordions";
import PageMessages from "../Components/page-messages";
import BOESelector from "./Components/UI/boe-selector";
import TileTabbed from "../Components/UI/tile-tabbed";
import { RbacContext } from "../rbac-context";
import { useTranslation } from "react-i18next";
import { isBusinessAdministrator, isCertInstructor } from "../helper-functions";

import { appInsights, reactPlugin } from "../application-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ConfigContext } from "../configuration-context";
import {
    fetchOrganizationInvoicedCourses,
    fetchOrganizationCourseInvoiceSummary,
    fetchCourseSeatDataByOrganization
} from "./Utils/organization";
import useFeatureFlags from "../hooks/useFeatureFlags";

interface OrganizationInvoicedCourseModel {
    courseId: string;
    courseName: string;
    seatsPurchased: number;
    seatsRemaining: number;
    seatsUsed: number;
    OrganizationId: string;
}

const CourseSeatsPage = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);

    const configContext = React.useContext(ConfigContext);
    const { userContext } = React.useContext(RbacContext);
    const isCI = isCertInstructor(userContext);
    const isBA = isBusinessAdministrator(userContext);

    const [invoicedCourses, setInvoicedCourses] = React.useState<SelectModel[]>([]);
    const [selectedInvoicedCourse, setSelectedInvoicedCourse] = React.useState<SelectModel>();

    const [loadingInvoices, setLoadingInvoices] = React.useState(true);
    const [organizationInvoiceSummary, setOrganizationInvoiceSummary] =
        React.useState<OrganizationInvoiceSummaryModel>();
    const [organizationInvoices, setOrganizationInvoices] = React.useState<OrganizationInvoiceModel[]>([]);
    const [totalSeatsRemaining, setTotalSeatsRemaining] = React.useState<number>(0);
    const [allReturnedCourses, setAllReturnedCourses] = React.useState<OrganizationInvoicedCourseModel[]>([]);

    const [shouldBOESelectorShow] = useFeatureFlags("ITLAltBOESelector", true);
    const shouldShowBOE = userContext.AltOrganizationsList?.length > 1 && (isCI || isBA) && shouldBOESelectorShow;

    const tallySeatsRemainingForInvoicedCourse = () => {
        let totalSeats = 0;
        allReturnedCourses.forEach((course) => {
            if (course.courseId === selectedInvoicedCourse.Id) {
                totalSeats += course.seatsRemaining;
            }
        });
        setTotalSeatsRemaining(totalSeats);
    };

    React.useEffect(() => {
        async function getInvoicedCourses() {
            try {
                setLoading(true);
                const response = await fetchOrganizationInvoicedCourses(
                    configContext,
                    userContext.SelectedOrganization,
                    isBA
                );
                const invoicedCourses = response?.map(
                    (x) => ({ Id: x.courseId, Display: x.courseName } as SelectModel)
                );
                setInvoicedCourses(invoicedCourses);
                setSelectedInvoicedCourse(invoicedCourses?.length > 0 ? invoicedCourses[0] : null);
                setAllReturnedCourses(response);
            } catch (err) {
                console.error(err);
                appInsights.trackException({ error: err, properties: userContext });
            } finally {
                setLoading(false);
            }
        }

        getInvoicedCourses();
    }, [userContext.SelectedOrganization]);

    React.useEffect(() => {
        async function getInvoices() {
            try {
                setLoadingInvoices(true);

                const invoiceSummary = await fetchOrganizationCourseInvoiceSummary(
                    configContext,
                    userContext.SelectedOrganization,
                    isBA,
                    selectedInvoicedCourse.Id
                );
                setOrganizationInvoiceSummary(invoiceSummary);

                const invoicedCourses = await fetchCourseSeatDataByOrganization(
                    configContext,
                    userContext.SelectedOrganization,
                    selectedInvoicedCourse.Id,
                    isBA
                );
                setOrganizationInvoices(
                    invoicedCourses.sort((a, b) => (a.transactionDate < b.transactionDate ? 1 : -1))
                );
            } catch (err) {
            } finally {
                setLoadingInvoices(false);
            }
        }

        if (selectedInvoicedCourse) {
            getInvoices();
            tallySeatsRemainingForInvoicedCourse();
        }
    }, [selectedInvoicedCourse]);

    if (loading) {
        return <PageMessages loading={loading} />;
    }

    return (
        <div className="page-content m-auto">
            <Container fluid className="page-padding">
                <h1 className="page-title-h1 title-margin">{t("View Seats")}</h1>
                {shouldShowBOE && <BOESelector />}
                {invoicedCourses !== null && invoicedCourses.length > 0 ? (
                    <div className="tabbed">
                        <Row>
                            <Col>
                                <p className="h4">{t("Select course")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <select
                                    className="custom-select w-auto"
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const invoicedCourse = invoicedCourses.find((x) => x.Id === e.target.value);
                                        setSelectedInvoicedCourse(invoicedCourse);
                                    }}
                                >
                                    {invoicedCourses.map((x) => (
                                        <option key={x.Id} value={x.Id}>
                                            {x.Display}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>

                        <div className="jumbotron" style={{ width: "100%", marginTop: "10px" }}>
                            <Row>
                                <Col lg>
                                    <strong>{t("Total Available Seats")}:</strong> {totalSeatsRemaining ?? 0}
                                </Col>
                            </Row>
                        </div>

                        {loadingInvoices && (
                            <div style={{ position: "absolute", top: "50%", left: "50%" }} className="fa-3x">
                                <i className="fas fa-spinner fa-spin"></i>
                            </div>
                        )}
                        {!loadingInvoices && (
                            <InvoiceAccordions invoices={organizationInvoices} showAdminFeatures={false} />
                        )}
                    </div>
                ) : (
                    <p className="h4">{t("No Data Available")}</p>
                )}
            </Container>
        </div>
    );
};

export default withAITracking(reactPlugin, CourseSeatsPage);
