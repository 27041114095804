import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../ApiServices";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadNewClassFromCreatePayload } from "../stores/classDetailsSlice";
import { ClassInfoModel, ClassModel, OrganizationResult } from "../Interfaces";
import { useParams, useNavigate } from "react-router-dom";
import { fetchClass, fetchClassesByCourseId, fetchOrganizationTree, fetchAllClassRoster } from "../ApiServices/Class";
import {
    fetchOrganizationBranchIds,
    fetchProgramsOrganizations,
    fetchInstructorsCertifications
} from "./Components/DashboardPlatformUtils";
import {
    fetchEditionCoursesByCourseId,
    fetchCourseWithCourseObject,
    convertLmsClass,
    cancelConvertLmsClass,
    createLmsParticipants,
    fetchOrganization
} from "./Utils/class";
import { fetchOrganizationsIds } from "../Pages/Utils/participants";
import { fetchClassSettings } from "./Utils/classCreate";
import { isCertInstructor, isBusinessAdministrator } from "../helper-functions";
import TileTabbed from "../Components/UI/tile-tabbed";

import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import { returnPageLevelAlerts } from "./Utils/alerts";

import ClassRoster from "./Components/class-roster";
import Button from "../Components/UI/button";
import DocumentClass from "./Components/Classes/document-class";
import ModalPopUp from "../Components/UI/modal-pop-up";
import PageMessages from "../Components/page-messages";
import { RbacContext } from "../rbac-context";
import { PlatformBaseRoutes } from "../Routing/routes";
import { ConfigContext } from "../configuration-context";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";
import useDetectWindowSize from "../hooks/useDetectWindowSize";
import {
    ClassCourseObject,
    LMSParticipant,
    Organization,
    EditionCourse,
    EditionCourseResponse
} from "../Interfaces/Platform/Classes";
import useLocalStorage from "../hooks/useLocalstorage";

import useTrainableSpecialtiesByCert from "../hooks/useTrainableSpecialtiesByCert";

import { appInsights, reactPlugin } from "../application-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

import { setClassInactive } from "../ApiServices/Class";
import useFeatureFlags from "../hooks/useFeatureFlags";

import { ExpectedRosterCount } from "../Interfaces/Platform/ExpectedRosterCount";
import { InstructorModel } from "./manage-classes";

const ManageClassPage = () => {
    const { t } = useTranslation();
    const params = useParams<{ classId: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { isLaptop } = useDetectWindowSize();
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const rbac = React.useContext(RbacContext);
    const userId = rbac.userContext.UserId;

    const [convertClassError, setConvertClassError] = React.useState<string>(null);
    const [isConvertLoading, setIsConvertLoading] = React.useState<{ modal: boolean; loading: boolean }>({
        modal: false,
        loading: false
    });
    const [viewClassActiveTab, setViewClassActiveTab] = useLocalStorage("activeViewClassTab", {
        classId: null,
        activeTab: 1
    });

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const isCI = isCertInstructor(userContext);
    const isBA = isBusinessAdministrator(userContext);

    const shouldBOE = rbac.userContext?.AltOrganizationsList?.length > 0 && (isCI || isBA);
    const currentOrg = shouldBOE
        ? rbac.userContext?.SelectedOrganization.toString()
        : rbac.userContext?.OrganizationId.toString();

    const [loading, setLoading] = React.useState(true);
    const [errored, setErrored] = React.useState(false);
    const [classInfo, setClassInfo] = React.useState<ClassInfoModel>(null);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [preventConvertSpam, setPreventConvertSpam] = React.useState(false);
    const preventConvertSpamRef = React.useRef(preventConvertSpam);
    React.useEffect(() => { 
        preventConvertSpamRef.current = preventConvertSpam 
    }, [preventConvertSpam]);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [isOnlineOnly, setIsOnlineOnly] = React.useState<boolean>(undefined);
    const isOnlineOnlyRef = React.useRef(isOnlineOnly);
    React.useEffect(() => {
        isOnlineOnlyRef.current = isOnlineOnly;
    }, [isOnlineOnly]);

    const [organization, setOrganization] = React.useState<Organization>(null);
    const [notFound, setNotFound] = React.useState(false);
    const [organizations, setOrganizations] = React.useState<OrganizationResult[]>([]);
    const [orgInstructors, setOrgInstructors] = React.useState<InstructorModel[]>([]);
    const [classes, setClasses] = React.useState<ClassModel[]>([]);
    const [childRefresh, setChildRefresh] = React.useState(false);
    const [showDocumentClass, setShowDocumentClass] = React.useState<boolean>(false);
    const [lmsParticipants, setLmsParticipants] = React.useState<LMSParticipant[]>([]);
    const [editionCourses, setEditionCourses] = React.useState<EditionCourse[]>([]);
    const [classRosterCount, setClassRosterCount] = React.useState<number>(0);

    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();

    const [canSetConvertedClassInactive] = useFeatureFlags("ITLSetConvertedClassInactiveInHB");

    const [trainablePrograms] = useTrainableSpecialtiesByCert(false);

    const getOrganizationBranch = async () => {
        const data = await fetchOrganizationBranchIds(apimBaseUrl, configContext, currentOrg);
        console.log("Orgs: ", data);
        getInstructors(data);
    };

    const getInstructors = async (orgs: string[]) => {
        const data = await fetchInstructorsCertifications(apimBaseUrl, configContext, currentOrg, orgs, userId);
        setOrgInstructors((prevState) => [...prevState, ...data]);
    };

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            getOrganizationBranch();
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        const getData = async () => {
            try {
                const classId = parseInt(params.classId);
                const _class = await fetchClass(classId);
                if (!_class) {
                    setNotFound(true);
                    setLoading(false);
                    return;
                }
                const _organization = await fetchOrganization(apimBaseUrl, configContext, _class.AccountId.toString());
                const _participants = await fetchAllClassRoster([parseInt(params.classId)]);
                setLmsParticipants(() =>
                    _participants.Results.filter((i) => i.PingId !== null && i.PingId !== undefined).map((i) => ({
                        participantUserId: i.PingId,
                        participantOrgId: i.OrgId,
                        participantOrganizationId: i.AccountId,
                        participantOrganizationName: i.AccountName,
                        participantFirstName: i.FirstName,
                        participantLastName: i.LastName,
                        participantEmail: i.EmailAddress,
                        lmsLearnerCourseId: i.LearnerCourseId,
                        participantCourseStartDate: i.CourseStart,
                        participantCourseCompletedDate: i.CompletionDate,
                        participantCourseLastAccessed: i.LastAccessed,
                        participantCoursePercentComplete: i.PercentCompleted,
                        participantCourseTimeSpent: i.TimeSpent,
                        participantCourseExamScore: i.Score,
                        participantOrganizationSeatId: i.OrgSeatGuid,
                        seatPoolId: i.SeatPoolId
                    }))
                );
                setOrganization(_organization);

                document.title = _class.Name;
                setClassInfo(_class);

                const orgs = await fetchOrganizationTree();
                const classesResponse = await fetchClassesByCourseId(_class.CourseObjectId);

                // _class.ClassId should match an item in classes.Results
                // Get the OnlineOnly value from the item in classes.Results that matches _class.ClassId
                // If it doesn't find a matching class it will be set to undefined
                const onlineOnly = classesResponse.Results.find((c) => c.ClassId == _class.ClassId)?.OnlineOnly;

                setOrganizations(orgs);
                setClasses(classesResponse.Results);
                setIsOnlineOnly(onlineOnly ? true : false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setErrored(true);
            }

            setLoading(false);
        };
        if (userContext.UserId) {
            getData();
        }
    }, [userContext]);

    const refreshData = async () => {
        const _class = await fetchClass(classInfo.ClassId);

        setClassInfo(_class);
    };

    const onSave = (resp: JsonResponseModel<ApiResponse>, refresh: boolean = true) => {
        toast(resp);
        if (!resp.Error && refresh) {
            setChildRefresh(!childRefresh);
            refreshData();
        }
    };


    const handleDocumentClass = async () => {

        // If the spam prevention state hasn't been triggered we can proceed with convert functionality.
        // Otherwise if preventConvertSpamRef is true, we'll just ignore the button click.
        if (!preventConvertSpamRef.current) {

            // Now that we've begun class convert functionality, set preventConvertSpam to true to prevent spamming the button
            setPreventConvertSpam(true);

            try {
                // Getting trainable specialties here
                let specialties = [];
                if (isBA) {
                    const orgData = await fetchOrganizationBranchIds(
                        apimBaseUrl,
                        configContext,
                        userContext.OrganizationId.toString()
                    );
                    specialties = await fetchProgramsOrganizations(
                        apimBaseUrl,
                        configContext.SystemConfiguration.ApimKey,
                        orgData
                    );
                } else {
                    if (isCI) {
                        specialties = userContext.CertificationData.map((i: any) => i.specialty);
                    }
                }

                let allTrainablePrograms: string[] = [];

                specialties.forEach((specialty: string) => {
                    allTrainablePrograms.push(specialty);
                    const programs = trainablePrograms(specialty, userContext.Country, false);
                    allTrainablePrograms = [...allTrainablePrograms, ...programs];
                });

                allTrainablePrograms = [...new Set(allTrainablePrograms)];

                const data: EditionCourseResponse = await fetchEditionCoursesByCourseId(
                    apimBaseUrl,
                    configContext,
                    classInfo.CourseGuid,
                    allTrainablePrograms
                );
                const response = data?.editionCourses;
                setEditionCourses(response);

                // Decision to show modal or not
                // Do not show modal if there's ONE of every data AND there is a class start date (No inputs for date needed in this case)
                // If the condition here is met, it means we do not need the modal and will just carry on with converting the class.
                // On success, the user will be redirected to the class details page.
                if (response?.length === 1 && classInfo?.ClassStartDate) {
                    setIsConvertLoading({ modal: false, loading: true });
                    const courseData: ClassCourseObject = await fetchCourseWithCourseObject(
                        apimBaseUrl,
                        configContext,
                        classInfo.CourseGuid,
                        classInfo.CourseObjectGuid
                    );
                    setShowDocumentClass(false);
                    // Make api call to convert class withtout opening modal
                    handleConvertLmsClass({
                        userId: userContext.UserId,
                        organizationId: classInfo.AccountId,
                        organizationName: classInfo.AccountName,
                        seatOrganizationId: classInfo.SeatAccountId,
                        seatOrganizationName: classInfo.SeatAccountName,
                        className: classInfo.Name,
                        specialty: response[0]?.specialty,
                        specialtyEdition: response[0]?.edition,
                        qualification: response[0]?.qualification,
                        trainingType: isOnlineOnlyRef.current ? null : "initial-refresher",
                        trainingFormat: isOnlineOnlyRef.current ? "virtual" : "blended",
                        courseId: classInfo.CourseGuid,
                        courseName: classInfo.CourseName,
                        courseCulture: courseData.courseCulture ?? null,
                        courseContactHours: courseData.contactHours ?? null,
                        courseTemplateId: courseData.templateId ?? null,
                        courseEstimatedTime: courseData.courseEstimatedTime ?? null,
                        courseObjectId: courseData.courseObjectId ?? null,
                        courseObjectModulesUrl: courseData.modulesUri ?? null,
                        courseObjectScormId: courseData.scormId ?? null,
                        courseLanguage: courseData.language ?? null,
                        courseObjectIndustry: courseData.industry ?? null,
                        participants: lmsParticipants.length ?? 0,
                        instructor: {
                            userId: classInfo.InstructorPingId,
                            firstName: classInfo.InstructorFirstName,
                            lastName: classInfo.InstructorLastName,
                            isPrimary: true
                        },
                        classDates: [
                            {
                                date: classInfo.ClassStartDate,
                                initialHours: 0,
                                refresherHours: 0
                            }
                        ]
                    });
                } else {
                    // Show modal so user can select fields and then make api call to convert class
                    setPreventConvertSpam(false);
                    setShowDocumentClass(true);
                }
            } catch (e) {
                console.log('error', e);

                // If something goes wrong, re-enable convert button
                setPreventConvertSpam(false);
            }
            
        }
        
    };

    const handleDocumentClassWithForm = async (form: any) => {
        setIsConvertLoading({ modal: true, loading: true });
        const courseData: ClassCourseObject = await fetchCourseWithCourseObject(
            apimBaseUrl,
            configContext,
            classInfo.CourseGuid,
            classInfo.CourseObjectGuid
        );
        if (courseData != null) {
            handleConvertLmsClass({
                userId: userContext.UserId,
                organizationId: classInfo.AccountId,
                organizationName: classInfo.AccountName,
                seatOrganizationId: classInfo.SeatAccountId,
                seatOrganizationName: classInfo.SeatAccountName,
                className: classInfo.Name,
                specialty: form.specialty,
                specialtyEdition: form.edition,
                qualification: form.qualification,
                trainingType: isOnlineOnlyRef.current ? null : "initial-refresher",
                trainingFormat: isOnlineOnlyRef.current ? "virtual" : "blended",
                courseId: classInfo.CourseGuid,
                courseName: classInfo.CourseName,
                courseCulture: courseData.courseCulture ?? null,
                courseContactHours: courseData.contactHours ?? null,
                courseTemplateId: courseData.templateId ?? null,
                courseEstimatedTime: courseData.courseEstimatedTime ?? null,
                courseObjectId: courseData.courseObjectId ?? null,
                courseObjectModulesUrl: courseData.modulesUri ?? null,
                courseObjectScormId: courseData.scormId ?? null,
                courseLanguage: courseData.language ?? null,
                courseObjectIndustry: courseData.industry ?? null,
                participants: lmsParticipants.length ?? 0,
                instructor: {
                    userId: classInfo.InstructorPingId,
                    firstName: classInfo.InstructorFirstName,
                    lastName: classInfo.InstructorLastName,
                    isPrimary: true
                },
                classDates: classInfo.ClassStartDate
                    ? [
                          {
                              date: classInfo.ClassStartDate,
                              initialHours: 0,
                              refresherHours: 0
                          }
                      ]
                    : form.classDates.map(({ id, ...rest }: any) => rest)
            });
        } else {
            setConvertClassError("There was an error converting the class.");
            setIsConvertLoading({ modal: false, loading: false });
        }
    };

    const handleConvertLmsClass = async (payload: any) => {
        try {
            // Retrieve class settings to get extra requirements
            const classSettings = await fetchClassSettings(apimBaseUrl, configContext, payload.specialty);

            // Add extra requirements to payload
            payload = {
                ...payload,
                blueCardRequired: classSettings[0].blueCardId
            };

            const response = await convertLmsClass(apimBaseUrl, configContext, payload);

            // If convert lms class fails (400 or 500), the service will handle the convert cancellation.
            // If create lms participants fails (400 or 500), we need to cover the cancellation here
            // by hitting another endpoint.
            if (response.status === 200) {
                const organizations = await fetchOrganizationsIds(
                    apimBaseUrl,
                    configContext,
                    userContext.OrganizationId.toString()
                );
                const participantsResponse = await createLmsParticipants(apimBaseUrl, configContext, {
                    participants: lmsParticipants,
                    classId: response.data.classId,
                    orgId: userContext.OrgId.toString(),
                    organizationId: userContext.OrganizationId.toString(),
                    organizationName: userContext.OrganizationName,
                    organizationIds: organizations
                });
                if (participantsResponse === 202) {
                    setIsConvertLoading({ modal: false, loading: false });
                    // Convert Class AND Participants success
                    // Set class inactive in HB if feature flag is enabled
                    canSetConvertedClassInactive && setClassInactive(classInfo.ClassId);

                    dispatch(
                        loadNewClassFromCreatePayload({
                            language: response.data.courseLanguage,
                            ...response.data
                        })
                    );

                    // Navigate to Class Details with data on redux (Like Class Create)
                    setViewClassActiveTab({ classId: response.data.classId, activeTab: 0 });

                    // create expectedRosterCount session data to class-details can
                    // anticipage a certain number of participants
                    const expectedRosterCount: ExpectedRosterCount = {
                        classId: response.data.classId,
                        count: lmsParticipants.length
                    };
                    sessionStorage.setItem("expectedRosterCount", JSON.stringify(expectedRosterCount));

                    navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${response.data.classId}`);

                // Create Lms Participants failed
                // We need to make an additional call to cancel the conversion
                } else if (participantsResponse === 400 || participantsResponse === 500) {
                    cancelConvertLmsClass(apimBaseUrl, configContext, response.data.classId);
                    setIsConvertLoading({ modal: false, loading: false });
                    setShowDocumentClass(false);
                    addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
                
                // Catch-all, basically doing the same thing as 400 or 500, at least for now.
                } else {
                    setIsConvertLoading({ modal: false, loading: false });
                    setShowDocumentClass(false);
                    addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
                }

            // Call to ConvertLmsClass failed.
            // We don't need to call to cancel the conversion but we do need to handle in the UI.
            } else if (response.status === 400 || response.status === 500) {
                setIsConvertLoading({ modal: false, loading: false });
                setShowDocumentClass(false);
                addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
                
            // Catch-all, basically doing the same thing as 400 or 500, at least for now.
            } else {
                // Convert Class failure
                setIsConvertLoading({ modal: false, loading: false });
                setShowDocumentClass(false);
                // Show error
                addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
            }
        } catch (e) {
            console.log("error", e);
            setIsConvertLoading({ modal: false, loading: false });
            setShowDocumentClass(false);
            addPageLevelAlert({ alertLevel: {alertLevel:"error"}, description: t("Response-Default"), canDismiss: true });
        }
    };

    return (
        <>
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <PageMessages
                        loading={loading}
                        errored={errored}
                        notFound={notFound}
                        notFoundMessage={`${t("Error")}: ${t("The requested class was not found")}`}
                    />
                    <div className="alert-wrapper mt-4">{returnPageLevelAlerts(pageLevelAlerts)}</div>
                    {!loading && !errored && !notFound && (
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h1 className="page-title-h1 title-margin">{classInfo.Name}</h1>
                                {classRosterCount <= 100 && (
                                    <Button
                                        label={
                                            <div className="d-flex align-items-center justify-content-center">
                                                {!isConvertLoading.modal && isConvertLoading.loading && (
                                                    <i className="fas fa-spinner fa-spin mr-2" />
                                                )}{" "}
                                                {t("Document Class")}
                                            </div>
                                        }
                                        isSolid
                                        clickAction={handleDocumentClass}
                                    />
                                )}
                            </div>
                            <ModalPopUp
                                title="Additional Class Details"
                                size={isLaptop ? "md" : "lg"}
                                center
                                content={
                                    <DocumentClass
                                        onSubmit={handleDocumentClassWithForm}
                                        editionCourses={editionCourses}
                                        class={classInfo}
                                        classId={classInfo.ClassId.toString()}
                                        onClose={() => setShowDocumentClass(false)}
                                        isLoading={isConvertLoading.loading}
                                        convertClassError={convertClassError}
                                        resetConvertClassError={setConvertClassError}
                                        instructors={orgInstructors}
                                    />
                                }
                                show={showDocumentClass}
                                showFooter={false}
                                hide={() => setShowDocumentClass(false)}
                            />
                            <TileTabbed
                                onSelectTab={setActiveTab}
                                activeTab={activeTab}
                                tabs={[
                                    {
                                        title: t("Class Roster"),
                                        content: (
                                            <ClassRoster
                                                classInfo={classInfo}
                                                classes={classes}
                                                onSave={onSave}
                                                forceRefresh={childRefresh}
                                                setClassRosterCount={setClassRosterCount}
                                            />
                                        )
                                    }
                                ]}
                            />
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default withAITracking(reactPlugin, ManageClassPage);
