import React, { useState } from "react";
import type { KeyboardEvent } from "react";
import { SearchParamsBasic } from "../../../Interfaces/Platform/GenericFilter";
import TextInput from "../../../Components/Form/text-input";
import Select, { components, DropdownIndicatorProps } from "react-select";
import FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import Button from "../../../Components/UI/button";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import useDebounce from "../../../hooks/use-debounce";

const ChevronDropdownIndicator = (props: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...props}>
        <i className="fa-sharp fa-light fa-chevron-down generic-filter-select-icon" />
    </components.DropdownIndicator>
);

const GenericFilterBasic = ({
    sortByOptions,
    filters,
    searchLabel,
    onSearch,
    onCreate,
    updateFilter,
    canCreateParticipants
}: SearchParamsBasic): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('date');
    const { isMobile } = useDetectWindowSize();

    React.useEffect(() => {
        setSearchTerm(filters.searchTerm);
    }, [filters.searchTerm]);

    const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onSearch();
        }
    };

    return (
        <>
            <div className="generic-filter-basic-container">
                <div className="generic-filter-basic-row">
                    {isMobile && (
                        <Button
                            elementId="create-participant-button"
                            isSolid={false}
                            clickAction={onCreate}
                            isDisabled={!canCreateParticipants}
                            label="Create Participant"
                            additionalStyleClasses="btn-participant ms-auto"
                        />
                    )}
                    <div className="generic-filter-basic-search">
                        <TextInput
                            isRequired={false}
                            label={`${searchLabel ?? "Search"}`}
                            name="searchTerm"
                            id="searchTerm"
                            value={searchTerm}
                            overrideOptionalLabel=" "
                            onKeyDown={(e) => handleEnterKey(e)}
                            changeAction={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchTerm(e.target.value);
                                updateFilter('searchTerm', e.target.value);
                            }}
                        />
                    </div>
                    {
                        !isMobile && (
                            <>
                                <div className="search-dropdown">
                                    <label htmlFor="sortBy" className="bold form-label">SORT BY</label>
                                    <Select
                                        name="sortBy"
                                        id="sortBy"
                                        className="mt-1 generic-filter-basic filter-min-width"
                                        isSearchable={false}
                                        classNames={{
                                            menuPortal: () => 'generic-filter-menu',
                                            option: (state) => {
                                                if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                if (state.isFocused) return 'generic-filter-menu-option-focused';
                                            },
                                            indicatorSeparator: () => 'generic-filter-select-separator'
                                        }}
                                        components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                        options={sortByOptions}
                                        defaultValue={sortByOptions[0]}
                                        onChange={(o: FormSelectOption) => {
                                            setSortBy(o.value);
                                            updateFilter('sortBy', o.value);
                                        }}
                                        value=
                                        {
                                            sortBy !== null
                                                ? sortByOptions.filter((o) => o.value === sortBy)[0]
                                                : sortByOptions.filter(o => o.default)[0]
                                        }
                                    />
                                </div>

                                <Button
                                    elementId="create-participant-button-2"
                                    isSolid={false}
                                    isDisabled={!canCreateParticipants}
                                    clickAction={onCreate}
                                    label="Create Participant"
                                    additionalStyleClasses="btn-participant"
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
};

export default GenericFilterBasic;
