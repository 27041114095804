import * as React from "react";

import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { InstructorBaseRoutes, LearnerBaseRoutes } from "../Routing/routes";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { isAuthenticated, isNullOrEmpty, isValidEmail } from "../helper-functions";

import { EMAIL_REGEX_PATTERN } from "../constants";
import { RbacContext } from "../rbac-context";
import ResetPasswordModal from "./Components/reset-password-modal";
import { RoleEnum } from "../Enums";
import { login } from "../ApiServices/Login";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../configuration-context";

const LoginPage = () => {
    const { t } = useTranslation();
    const { userContext, reloadContext } = React.useContext(RbacContext);
    const location = useLocation();
    const [email, setEmail] = React.useState("");
    const [emailValidated, setEmailValidated] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [passwordValidated, setPasswordValidated] = React.useState(false);
    const [error, setError] = React.useState("");
    const [loggingIn, setLoggingIn] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [resetEmail, setResetEmail] = React.useState("");
    const [redirect, setRedirect] = React.useState("");
    const [showSsoMessage, setShowSsoMessage] = React.useState(true);
    const [searchParams] = useSearchParams(); 
    const configContext = React.useContext(ConfigContext);

    const messageCode = React.useMemo(() => {
        return searchParams.get('code');
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setLoggingIn(true);
                setShowSsoMessage(false);
                const resp = await login(email, password);

                if (resp.Error) {
                    if (resp.Error.Code === 400) {
                        setError(
                            t(
                                "An account with the provided email and password could not be found. Please verify you have typed everything correctly."
                            )
                        );
                    } else {
                        setError(resp.Error.Message);
                    }

                    setLoggingIn(false);
                    return;
                }

                await reloadContext();

                if (location.state && location.state.from) {
                    setRedirect(location.state.from);
                } else if (
                    resp.Data.Roles.some(
                        (r) =>
                            r === RoleEnum.BusinessAdministrator ||
                            r === RoleEnum.CertifiedInstructor ||
                            r === RoleEnum.Developer
                    )
                ) {
                    setRedirect(InstructorBaseRoutes.Class.Class.fullPath);
                } else {
                    setRedirect(LearnerBaseRoutes.Learn.fullPath);
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }          
        } else {
            setEmailValidated(true);
            setPasswordValidated(true);
        }
    };

    if (!!redirect) {
        return <Navigate to={redirect} />;
    }

    if (!loggingIn && isAuthenticated()) {
        if (redirect) {
            return <Navigate to={redirect} />;
        }

        if (!userContext.UserId) {
            return <></>;
        }

        if (userContext.Roles.every((r) => r === RoleEnum.Learner)) {
            return <Navigate to={LearnerBaseRoutes.Learn.fullPath} />;
        }

        return <Navigate to={redirect || InstructorBaseRoutes.Class.Class.fullPath} />;
    }

    return (
        <Container fluid>
            <Row>
                <Col md={6} lg={4} xl={4} className="col-xxl-3 mx-auto">
                    <h1 className="mb-4">{t("Login")}</h1>
                    <div className="d-flex justify-content-center mb-3">
                        <Button variant="dark" href={configContext?.SystemConfiguration?.SsoUrl} className="w-100">
                            <i className="fas fa-user-shield"></i> {t("Certified Instructor Login")}
                        </Button>
                    </div>
                    <p className="text-center">{t("or")}</p>
                    <Form noValidate onSubmit={onSubmit}>
                        <Form.Group controlId="email">
                            <Form.Label>{t("Email")}</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                isInvalid={emailValidated && !isValidEmail(email)}
                                pattern={EMAIL_REGEX_PATTERN}
                                maxLength={150}
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
                                onBlur={() => setEmailValidated(true)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("Please enter a valid email address")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>{t("Password")}</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                autoComplete="off"
                                isInvalid={passwordValidated && isNullOrEmpty(password)}
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPassword(e.target.value)}
                                onBlur={() => setPasswordValidated(true)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("Please enter your password")}
                            </Form.Control.Feedback>
                            {error && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {error}
                                </Form.Control.Feedback>
                            )}
                            {messageCode && showSsoMessage && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {/* i18next-extract-disable-next-line */}
                                    {t(`messageCodes::${messageCode}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <div className="d-flex mb-3">
                            <Button variant="link" onClick={() => setShowModal(true)}>
                                {t("Create Password / Forgot Password")}
                            </Button>
                            <Button variant="primary" type="submit" className="ms-auto" disabled={loggingIn} title="Login">
                                {loggingIn ? (
                                    <>
                                        <i className="fas fa-spinner fa-spin"></i> {t("Logging In")}
                                    </>
                                ) : (
                                    t("Login")
                                )}
                            </Button>
                        </div>
                        {!!resetEmail && (
                            <Alert variant="success">
                                <p className="text-break">
                                    {t("A password reset link email has been sent to {{emailAddress}}.", {
                                        emailAddress: resetEmail
                                    })}{" "}
                                    {t("If you do not receive an email, please contact your instructor.")}
                                </p>
                            </Alert>
                        )}
                    </Form>
                </Col>
            </Row>
            {showModal && (
                <ResetPasswordModal
                    show={showModal}
                    onSend={(email) => {
                        setResetEmail(email);
                        setShowModal(false);
                    }}
                    handleCancel={() => setShowModal(false)}
                />
            )}
        </Container>
    );
};

export default withAITracking(reactPlugin, LoginPage);
