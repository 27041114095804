import * as React from "react";

import { Button, Col, Row } from "react-bootstrap";
import {
    deleteGeneralSetting,
    fetchGeneralSettings,
    fetchOrgSettingDefinitions
} from "../../ApiServices/OrganizationSettings";

import GeneralOrgSettingForm from "./general-org-setting-form";
import { HoneyBeeSettingDefinition } from "../../Interfaces/HoneyBeeSettingDefinition";
import { OrgSettingModel } from "../../Interfaces";
import PageMessages from "../../Components/page-messages";
import { RbacContext } from "../../rbac-context";
import { WithToast } from "../../hooks/toast";
import { useTranslation } from "react-i18next";

import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";
import { formatDate } from "../../locale-utils";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import ConfirmationModal from "../../Components/confirmation-modal";

interface GeneralOrgSettingsProps extends WithToast {}

const GeneralOrgSettings = ({ toast }: GeneralOrgSettingsProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [loading, setLoading] = React.useState(true);

    const [orgSettings, setOrgSettings] = React.useState<OrgSettingModel[]>(null);
    const [selectedSetting, setSelectedSetting] = React.useState<OrgSettingModel>(null);
    const [settingDefinitions, setSettingDefinitions] = React.useState<HoneyBeeSettingDefinition[]>([]);

    const [showCreateModal, setShowCreateModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    React.useEffect(() => {
        async function getTemplates() {
            try {
                setLoading(true);
                const settingDefinitions = await fetchOrgSettingDefinitions();
                setSettingDefinitions(settingDefinitions.Data);
                const settings = await fetchGeneralSettings();
                setOrgSettings(settings.Data);
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }        
        }

        getTemplates();
    }, []);

    const refreshSettings = async () => {
        try {
            const resp = await fetchGeneralSettings();
            setOrgSettings(resp.Data);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }
        
    };

    const handleDelete = async () => {
        try {
            const resp = await deleteGeneralSetting(selectedSetting.OrgSettingId);
            setShowDeleteModal(false);
            toast(resp);
            await refreshSettings();
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }      
    };

    const getTypeName = (type: number) =>
        settingDefinitions.find((def) => def.Type === type)?.TypeName;


    const columnHelper = createColumnHelper<OrgSettingModel>()
    const columns = React.useMemo<ColumnDef<OrgSettingModel>[]>(
        () => [
            columnHelper.accessor('Type', {
                cell: info => getTypeName(info.getValue()),
                header: t("Setting Name"),
                enableSorting: true,
            }),
            columnHelper.accessor('Value', {
                cell: info => info.getValue(),
                header: t("Value"),
                enableSorting: true,
            }),
            columnHelper.accessor('Active', {
                cell: info => info.getValue().toString(),
                header: t("Active"),
                enableSorting: true,
            }),
            columnHelper.accessor('CreateDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Created"),
                enableSorting: true,
            }),
            columnHelper.accessor('ModifyDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Last Updated"),
                enableSorting: true,
            }),
            columnHelper.accessor("OrgSettingId", {
                cell: info => typeColumn(info.row.original),
                header: t("Type"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "250px" }, bodyStyle: {whiteSpace: "nowrap"} }
            }),
        ],
        [settingDefinitions]
    );

    const typeColumn = (row: OrgSettingModel) => {
        return (
            <>

                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedSetting(row);
                        setShowEditModal(true);
                    }}
                >
                    <i className="me-1 fas fa-edit" />
                    {t("Edit")}
                </Button>
                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedSetting(row);
                        setShowDeleteModal(true);
                    }}
                >
                    <i className="me-1 fas fa-trash" />
                    {t("Delete")}
                </Button>
            </>
        )
    }

    return (
        <>
            <PageMessages loading={loading} />

            <Row>
                <Col>
                    <h3>{t("General")}</h3>
                </Col>
                <Col sm="auto">
                    <Button onClick={() => setShowCreateModal(true)}>+ {t("Create Setting")}</Button>
                </Col>
            </Row>

            <RemoteTable
                keyField="OrgSettingsId"
                fetchData={false}
                columns={columns}
                defaultData={orgSettings ?? []}
                defaultSorted={{ dataField: "ReminderInterval", order: "asc" }}
                enablePaging={false}
                enableSearch={false}
            />

            {showCreateModal && (
                <GeneralOrgSettingForm
                    isCreateForm
                    isVisible={showCreateModal}
                    onSave={(resp) => {
                        setShowCreateModal(false);
                        toast(resp);
                        refreshSettings();
                    }}
                    onHide={() => setShowCreateModal(false)}
                    existingSettings={orgSettings}
                    settingDefinitions={settingDefinitions}
                />
            )}
            {showEditModal && (
                <GeneralOrgSettingForm
                    isVisible={showEditModal}
                    existingSetting={selectedSetting}
                    onSave={(resp) => {
                        setShowEditModal(false);
                        toast(resp);
                        refreshSettings();
                    }}
                    onHide={() => setShowEditModal(false)}
                    existingSettings={orgSettings}
                    settingDefinitions={settingDefinitions}
                />
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={showDeleteModal}
                    hide={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                    onCancel={() => setShowDeleteModal(false)}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, GeneralOrgSettings);
