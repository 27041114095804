import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const AfterVirtualTraining = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1 title-margin">{ t('AfterVirtualTraining-Title') }</h1>
                                <p className="mw-900 mt-3">{ tp('AfterVirtualTraining-Intro') }</p>
                                <Tile title={ t('AfterVirtualTraining-Header') } hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <span>{ tp('AfterVirtualTraining-SectionIntro') }</span>
                            <TwoColumnLayout
                        left={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <span>{ tp('AfterVirtualTraining-SelfAssessment') }</span>
                                <span>{ tp('AfterVirtualTraining-Feedback') }</span>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <span>{ tp('AfterVirtualTraining-Reinforce') }</span>
                                <span>{ tp('AfterVirtualTraining-Resources') }</span>
                            </>
                        }
                    />
                        </>      
                    </Tile>

                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default AfterVirtualTraining;
