import * as React from "react";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";
import { useNavigate } from "react-router-dom";
import { ClassParticipantHistory } from "../../Interfaces/Platform/Participants";
import { TRAINING_FORMATS, TRAINING_TYPES } from "../../Pages/Utils/participants";
import Badge from "../UI/badge";
import ActionMenu from "../UI/action-menu-component/action-menu";
import ActionMenuItem, { MenuItemObject } from "../UI/action-menu-component/action-menu-item";
import ProgressBar from "../UI/progress-bar";
import { getUserClaims, isExternalURL } from "../../helper-functions";
import { resendOnlineEmail } from "../../Pages/Utils/class";
import { ConfigContext } from "../../configuration-context";
import { PlatformBaseRoutes } from "../../Routing/routes";
import { removeParticipantFromClass } from "../../Pages/Utils/class";
import useLocalStorage from "../../hooks/useLocalstorage";
import useFeatureFlags from "../../hooks/useFeatureFlags";

import { RbacContext } from "../../rbac-context";

import { openPdfFromBase64InNewTab } from "../../Pages/Utils/pdf";
import { fetchParticipantOnlineCourseCertificate } from "../../Pages/Utils/participants";
import { fetchParticipantVerificationLetter } from "../../Pages/Utils/participants";

const ViewParticipantClassDetails = (props: {
    participantHistory: ClassParticipantHistory,
    canViewCertificates: string | boolean,
    classId: string,
    canEditClassParticipants: string | boolean,
    canSendEnrollmentEmail: string | boolean,
    canTransferParticipants: string | boolean,
    fromParticipants?: string | boolean,
    onClose: () => void,
    onRemoveParticipantFromClass: (certification: ClassParticipantHistory) => void,
    onResendEnrollmentEmail: (isError: boolean) => void
}): JSX.Element => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const navigate = useNavigate();
    const [viewClassActiveTab, setViewClassActiveTab] = useLocalStorage('activeViewClassTab', { classId: null, activeTab: 1 });
    const culture = getUserClaims()?.culture ?? "en-US";
    const rbac = React.useContext(RbacContext);
    const [showClassDetails, setShowClassDetails] = React.useState(false);
    const [canPrintVerificationLetters] = useFeatureFlags("ITLVerificationLetters", "pending");
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const isMigrated = props.participantHistory.imported === true ? true : false;

    const displayInstructors = () => {
        const primary = props.participantHistory.instructors.find((i) => i.isPrimary);
        const additional = props.participantHistory.instructors.filter((i) => !i.isPrimary).map((i) => `${i.firstName} ${i.lastName}`);
        const instructors = [`${primary.firstName} ${primary.lastName}`, ...additional];
        return `${instructors.join(", ")}`;
    };

    const handleRemoveParticipantFromClass = async (certification: ClassParticipantHistory) => {
        removeParticipantFromClass(apimBaseUrl, configContext, {
            classParticipantId: certification.classParticipantId,
            participantId: certification.participantId,
            classId: certification.classId
        }).then((response: any) => {
            if (response.statusText === "Accepted") props.onRemoveParticipantFromClass(certification);
        });
    };

    const displayDates = () => {
        return props.participantHistory.dates.map((d) => {
            const [year, month, day] = new Date(d.date).toISOString().split("T")[0].split("-");
            return (
                <p className="mb-0 small participant-listing-text">
                    {`${month}/${day}/${year}`}{d.initialHours > 0 && `, ${d.initialHours} hrs`}{d.refresherHours > 0 && `, ${d.refresherHours} hrs`}
                </p>
            );
        });
    };

    const displayStartDate = () => {
        const [year, month, day] = new Date(props.participantHistory.startDate).toISOString().split("T")[0].split("-");
        return `${month}/${day}/${year}`;
    };

    const handleResendEnrollmentEmail = async () => {
        var response = await resendOnlineEmail(apimBaseUrl, configContext, props.participantHistory.classParticipantId, props.classId);

        response?.status === 202 ? props.onResendEnrollmentEmail(false) : props.onResendEnrollmentEmail(true) ;
    };

    const handleNavigation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, certification: ClassParticipantHistory) => {
        if (!isExternalURL(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${certification.classId}`)) {
            event.preventDefault();
            navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${certification.classId}`);
        }
    };

    const menuItems = (certification: ClassParticipantHistory) => {

        const currentDate = new Date();
        const startDate = new Date(certification.startDate);
        const startDateObject = new Date(certification.startDate.split('T')[0]);
        const expirationDate = new Date(certification.expirationDate);
        const classIsExpired = new Date(certification.expirationDate) < new Date();
        const classIsActive = certification?.expirationDate === null || new Date() <= new Date(certification.expirationDate);
        const classHasStarted = new Date().setHours(0,0,0,0) >= startDateObject.setHours(0,0,0,0);
        const todayDateObject = new Date();
        const daysDifferenceTime = todayDateObject.getTime() - startDateObject.getTime();
        const oneDayTime = 24 * 3600 * 1000;
        const daysBetween = Math.ceil(daysDifferenceTime / oneDayTime);
        const classImportedCondition = (certification?.imported && !certification?.ceSubmitted) || (!certification?.imported);        
        let items: React.ReactElement<MenuItemObject>[] = [];
        items.push(
            <ActionMenuItem data={{ label: t("Nav.ViewClass"), clickAction: (e: any) => {
                if (props.fromParticipants) {
                    handleNavigation(e, certification);
                } else {
                    props.onClose();
                    if (props.classId !== certification.classId) {
                        setViewClassActiveTab({ classId: certification.classId, activeTab: 1 });
                        window.scrollTo(0,0);
                        handleNavigation(e, certification);
                    }
                }
            }}} />
        );
        // Re-send Enrollment Email
        if (certification?.trainingFormat !== 'classroom' && classIsActive && certification?.percentComplete?.toString() !== '100' && classImportedCondition) {
            items.push(<ActionMenuItem data={{ label: t("ReSend-Enrollment-Email"), clickAction: () => handleResendEnrollmentEmail() }} />);
        }
        
        // Remove From Class
        if (classIsActive && !certification?.ceSubmitted && certification.dateCompleted === null) {
            if (certification?.trainingFormat === 'classroom') {
                if (!certification?.ceSubmitted) {
                    items.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(certification) } }} />);
                }
            } else {
                if (!classHasStarted) {
                    // Course has not started
                    items.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(certification) } }} />);
                } else {
                    // Course has started, but not completed within 90 days
                    if (certification.percentComplete?.toString() !== '100' && daysBetween <= 90) {
                        items.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(certification) } }} />);
                    }
                }
            }
        }
        // Transfer to Another Class - NOT IN SCOPE
        /*if (props.canTransferParticipants && classIsActive && !isMigrated) {
            items.push(<ActionMenuItem data={{ label: t("Transfer-To-Another-Class"), clickAction: () => {} }} />);
        }*/
        // Print Certificate
        if (props.canViewCertificates && (props.participantHistory) && (props.participantHistory.ceSubmitted) && (props.participantHistory.status == "pass") && !((props.participantHistory.trainingFormat == 'classroom') && (rbac.userContext.Country == "en-US")) && (props.participantHistory.learningHistoryId)) {

            items.push(<ActionMenuItem data={{ label: t("Print-Online-Course-Certificate"), clickAction: () => {
                fetchParticipantOnlineCourseCertificate(apimBaseUrl, rbac.userContext.Country, configContext, [props.participantHistory.learningHistoryId]).then((response: any) => {
                    openPdfFromBase64InNewTab(response.certificates[0]);
                });
            } }} />);           

        }
        
        // Print Verification Letter
        if (certification?.ceSubmitted && certification?.status === 'pass' && (canPrintVerificationLetters == true)) {
            items.push(<ActionMenuItem data={{ label: t("Print-Verification-Letter"), clickAction: () => {
                fetchParticipantVerificationLetter(apimBaseUrl, rbac.userContext.Country, configContext, props.participantHistory.classParticipantId).then((response: any) => {
                    openPdfFromBase64InNewTab(response.certificate);
                });
            } }} />);
        }
        return items;
    };

    const displayStatus = (certification: ClassParticipantHistory) => {
        const currentDate = new Date();
        const expirationDate = new Date(certification.expirationDate);

        // Enrolled AND Not Submitted | In Progress
        if (!certification.ceSubmitted) {
            return <span className="small view-participant-detail-class-status-label">{t("In Progress")}</span>;
        } else {
            // Submitted AND Failed
            if (certification.status === 'fail') {
                // Failed
                return <span className="small view-participant-detail-class-status-label">{t("Failed")}</span>;
            }
            // Submitted AND Passed | Active
            if (certification.status === 'pass') {
                if (currentDate > expirationDate) {
                    // Expired
                    return <span className="small view-participant-detail-class-status-label">{t("Expired")}</span>;
                } else {
                    return (
                        <>
                            <Badge style={{ style: "class-status" }}>
                                <span className="small view-participant-detail-badge">{t("Active").toUpperCase()}</span>
                            </Badge>
                        </>
                    );
                }

                
            }
        }
    };

    const displayQualification = () => {
        const qualification = props.participantHistory.qualification;
        if (qualification && qualification !== 'none' && qualification !== 'None') {
            return `${props.participantHistory.specialtyEdition ? ', ' : ''}${tp(props.participantHistory.qualification)}`;
        }
        return '';
    };

    const displayTrainingType = () => {
        return props.participantHistory.trainingType && `${(props.participantHistory.qualification || props.participantHistory.specialtyEdition) ? ', ' : ''}${TRAINING_TYPES[props.participantHistory.trainingType]}`;
    };

    // Render credential line on participant learning history
    // Looks something like: Blue Card 123456 | 01/01/2021 - 01/01/2022
    const displayCredentialInfo = () => {
        // Get blue card number to render
        const blueCardString = (props.participantHistory.blueCardId) ? `${t("Blue Card")} ${props.participantHistory.blueCardId}` : '';

        // Set a dynamic display date (covers UK vs. US date numeric formats) for start and expiration dates and then combine
        const startDateString = (props.participantHistory.startDate) ? t('dynamicDate', { val: new Date(props.participantHistory.startDate), formatParams: { val: { month: 'numeric', day: 'numeric', year: 'numeric' } } }) : "";
        const expirationDateString = (props.participantHistory.expirationDate) ? t('dynamicDate', { val: new Date(props.participantHistory.expirationDate), formatParams: { val: { month: 'numeric', day: 'numeric', year: 'numeric' } } }) : "";
        const activeTimeframeString = (props.participantHistory.startDate && props.participantHistory.expirationDate) ? ` | ${startDateString} - ${expirationDateString}` : "";

        // Concat and return strings
        return `${blueCardString}${activeTimeframeString}`;
    };

    return (
        <div className="view-participant-detail-classes-wrapper mt-1">
            <div className="view-participant-detail-class">
                <div className="view-participant-detail-class-column">
                    <h5 className="mb-0 fw600 color-black participant-listing-text">{tp(props.participantHistory.specialty)}</h5>
                    <p className="mb-0 small participant-listing-text-large">
                        {props.participantHistory.specialtyEdition && t(`Edition.${props.participantHistory.specialtyEdition}`)}
                        {displayQualification()}
                        {displayTrainingType()}
                    </p>
                    <p className="mb-0 small participant-listing-text-large">
                        {props.participantHistory.ceSubmitted && displayCredentialInfo()}
                    </p>
                    <div onClick={() => setShowClassDetails((prevState) => !prevState)} className="show-class-details-button mt-1">
                        <span className="small">
                            <i className={`fas ${showClassDetails ? "fa-minus" : "fa-plus"} mr-1`} />
                            {t(`${showClassDetails ? "Hide" : "Show"} Class Details`)}
                        </span>
                    </div>
                </div>
                <div className="view-participant-detail-class-status">
                    {displayStatus(props.participantHistory)}
                    <ActionMenu
                        menuItemsArr={menuItems(props.participantHistory)}
                    />
                </div>
            </div>
            {showClassDetails && (
                <div className="view-class-details-container">
                    <div className="view-class-details-staff-training mb-3">
                        <h6 className="fw600 color-black participant-listing-text">{props.participantHistory.className}</h6>
                        {props.participantHistory.trainingFormat && (
                            <p className="mb-0 small participant-listing-text-large">{TRAINING_FORMATS[props.participantHistory.trainingFormat]}</p>
                        )}
                        {props.participantHistory.dates && props.participantHistory.dates.length > 0 && (
                            <div>
                                {displayDates()}
                            </div>
                        )}
                        {props.participantHistory.instructors && props.participantHistory.instructors.length > 0 && (
                            <p className="mb-0 small participant-listing-text-large">
                                Instructor(s): {displayInstructors()}
                            </p>
                        )}
                    </div>

                    { props.participantHistory.trainingFormat != 'classroom' && 
                        <div className="view-class-details-online-course mb-3">
                            <h6 className="fw600 color-black participant-listing-text">{t("CPI.MyAccount.Dashboard.Enrollment.OnlineCourse")}</h6>
                            <ProgressBar
                                lastLogin={props.participantHistory.dateLastAccessed}
                                startedDate={props.participantHistory.dateStarted}
                                widthBar={100}
                                score={props.participantHistory.score}
                                onlineCoursePercentComplete={props.participantHistory.percentComplete}
                                totalTime={props.participantHistory.timeSpent}
                            />
                        </div>
                    }
                    
                    { false && // hiding until future release
                        <>
                            <div className="view-class-details-disengagement-skills mb-3">
                                <h6 className="fw600 color-black participant-listing-text">{t("DisengagementSkills")}</h6>
                            </div>
                            <div className="view-class-details-holding-skills mb-3">
                                <h6 className="fw600 color-black participant-listing-text">{t("HoldingSkills")}</h6>
                            </div>
                            {props.participantHistory.participantNotes && props.participantHistory.participantNotes.length > 0 && (
                                <div className="view-class-details-notes">
                                    <h6 className="fw600 color-black participant-listing-text">{t("Notes")}</h6>
                                    {props.participantHistory.participantNotes.map((note) => (
                                        <p key={`note-${note.classParticipantNoteId}`} className="mb-0 small participant-listing-text">
                                            {note.note}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </>
                    }
                </div>
            )}
        </div>
    );
};

export default ViewParticipantClassDetails;
