import * as React from "react";

import { ConfirmationModal } from "../../Components";
import { Button, Col, Row } from "react-bootstrap";
import { deleteEmailTemplate, fetchEmailTemplates } from "../../ApiServices/OrganizationSettings";

import EmailTemplateForm from "./email-template-form";
import { OrgEmailTemplateModel } from "../../Interfaces";
import PageMessages from "../../Components/page-messages";
import { RbacContext } from "../../rbac-context";
import { WithToast } from "../../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../../Components/remote-table";
import { formatDate } from "../../locale-utils";

interface EmailTemplatesProps extends WithToast {}

const EmailTemplates = ({ toast }: EmailTemplatesProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [loading, setLoading] = React.useState(true);

    const [emailTemplates, setEmailTemplates] = React.useState<OrgEmailTemplateModel[]>(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState<OrgEmailTemplateModel>(null);

    const [showCreateModal, setShowCreateModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    React.useEffect(() => {
        async function getTemplates() {
            try {
                setLoading(true);
                const templates = await fetchEmailTemplates();
                setEmailTemplates(templates.Data);
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }          
        }

        getTemplates();
    }, []);

    const refreshEmailTemplates = async () => {
        try {
            const resp = await fetchEmailTemplates();
            setEmailTemplates(resp.Data);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }        
    };

    const handleDelete = async () => {
        try {
            const resp = await deleteEmailTemplate(selectedTemplate.OrgEmailTemplateId);
            setShowDeleteModal(false);
            toast(resp);
            await refreshEmailTemplates();
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }     
    };

    const emailTypeDict: { [key: string]: string } = {
        "1": t("Enrollment"),
        "2": t("Purchase"),
        "3": t("Purchase - No Blue Card"),
        "4": t("Purchase - With Blue Card"),
        "5": t("Reset Password")
    };

    const columnHelper = createColumnHelper<OrgEmailTemplateModel>()
    const columns = React.useMemo<ColumnDef<OrgEmailTemplateModel>[]>(
        () => [
            columnHelper.accessor('Type', {
                cell: info => emailTypeDict[info.getValue()],
                header: t("Email Type"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" }, bodyStyle: {whiteSpace: "nowrap"}}
            }),
            columnHelper.accessor('TemplateId', {
                cell: info => info.getValue(),
                header: t("SendGrid Email Template"),
                enableSorting: false,
                meta: { headerStyle: { whiteSpace: "nowrap" }, bodyStyle: {whiteSpace: "nowrap"}, bodyClass: "text-truncate" }
            }),
            columnHelper.accessor('Active', {
                cell: info => info.getValue().toString(),
                header: t("Active"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "150px" }, bodyStyle: { whiteSpace: "nowrap" } }
            }), 
            columnHelper.accessor('CreateDate', {
                cell: info =>  formatDate(info.getValue()),
                header: t("Created"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" }}
            }),
            columnHelper.accessor('ModifyDate', {
                cell: info => formatDate(info.getValue()),
                header: t("Last Updated"),
                enableSorting: true,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "200px" }}
            }), 
            columnHelper.accessor('OrgEmailTemplateId', {
                cell: info => actionsColumn(info.row.original),
                header: t(""),
                enableSorting: false,
                meta: { headerStyle: { whiteSpace: "nowrap", width: "250px" }, bodyStyle: { whiteSpace: "nowrap" } }
            }), 
        ],
        []
    );

    const actionsColumn = (row: OrgEmailTemplateModel) => {
        return (
            <>
                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedTemplate(row);
                        setShowEditModal(true);
                    }}
                >
                    <i className="me-1 fas fa-edit" />
                    {t("Edit")}
                </Button>
                <Button
                    variant="outline-primary"
                    className="me-2 float-end"
                    onClick={() => {
                        setSelectedTemplate(row);
                        setShowDeleteModal(true);
                    }}
                >
                    <i className="me-1 fas fa-trash" />
                    {t("Delete")}
                </Button>
            </>

        )
    }

    return (
        <>
            <PageMessages loading={loading} />
            <div className="mt-4">
                <Row>
                    <Col>
                        <h3>{t("Email Templates")}</h3>
                    </Col>
                    <Col sm="auto">
                        <Button onClick={() => setShowCreateModal(true)}>+ {t("Create Template")}</Button>
                    </Col>
                </Row>

                <RemoteTable
                    fetchData={false}
                    columns={columns}
                    defaultData={emailTemplates ?? []}
                    defaultSorted={{ dataField: "ReminderInterval", order: "asc" }}
                    enablePaging={false}
                    enableSearch={false}
                    />
            </div>

            {showCreateModal && (
                <EmailTemplateForm
                    isCreateForm
                    isVisible={showCreateModal}
                    onSave={(resp) => {
                        setShowCreateModal(false);
                        toast(resp);
                        refreshEmailTemplates();
                    }}
                    onHide={() => setShowCreateModal(false)}
                    existingTemplates={emailTemplates}
                />
            )}
            {showEditModal && (
                <EmailTemplateForm
                    isVisible={showEditModal}
                    existingTemplate={selectedTemplate}
                    onSave={(resp) => {
                        setShowEditModal(false);
                        toast(resp);
                        refreshEmailTemplates();
                    }}
                    onHide={() => setShowEditModal(false)}
                    existingTemplates={emailTemplates}
                />
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={showDeleteModal}
                    hide={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                    onCancel={() => setShowDeleteModal(false)}
                />
            )}
        </>
    );
};

export default withAITracking(reactPlugin, EmailTemplates);
