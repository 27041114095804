import * as React from "react";
import Button from "./button";
import TextInput from "../Form/text-input";
import Select from "../Form/select";
import useForm from "../../../ClientApp/hooks/useForm"
import validateRulesWithEmail from "../../FormValidationRules/createParticipantWithEmail";
import validateRulesWithoutEmail from "../../FormValidationRules/createParticipantWithoutEmail";
import { fetchOrganizations, createParticipant } from "../../Pages/Utils/participants";
import { returnOrganizations } from "../../Pages/Components/DashboardPlatformUtils";
import DestructiveButton from "../../Components/UI/cancel-button";
import { ConfigContext } from "../../../ClientApp/configuration-context";
import OrganizationResult from "../../Interfaces/OrganizationResult";
import FormSelectOption from "../../Interfaces/Platform/FormSelectOption";
import { RbacContext } from "../../../ClientApp/rbac-context";
import { getUserClaims } from "../../helper-functions";
import { useTranslation } from "react-i18next";

const CreateParticipant = (props: {
    requiresEmail: boolean,
    orgsArr: Array<OrganizationResult>
    addPageLevelAlert:any
    handleCancelButton?: Function
}): JSX.Element => {
    const { t } = useTranslation();
    const culture = getUserClaims()?.culture ?? "en-US";
    const ref = React.useRef(null);
    const { userContext, setContext } = React.useContext(RbacContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [containerWidth, setContainerWidth] = React.useState(0);
    const validate = props.requiresEmail ? validateRulesWithEmail : validateRulesWithoutEmail;
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [orgs, setOrgs] = React.useState([]);
    const [orgsOptions, setOrgsOptions] = React.useState([]);
    let selectOptions = [{label: t("Select"), value: null}];

    React.useEffect(()=> {
        if(!orgsOptions.length) fetchOrgs(userContext.SelectedOrganization.toString());
    }, [orgsOptions]);

    const fetchOrgs = async (userOrgId: string) => {
        try {
            const data = await fetchOrganizations(apimBaseUrl, configContext, userOrgId);
            if (data) {
                selectOptions = selectOptions.concat(returnOrganizations(data));
                setOrgs(data);
                setOrgsOptions(selectOptions);
            }
        } catch (e) {
            console.log(JSON.stringify(e));
        }
    };

    const exit = (success: boolean) => {
        props.handleCancelButton(success);
        return;
    };

    const handleResponseStatus = (status?: any) => {
        switch(status) {
            case "Created": 
                    props.addPageLevelAlert({ 
                        alertLevel: {alertLevel: "success"},                        
                        description: t("Response-Participant-Created", { firstName: formFieldData.firstName, lastName: formFieldData.lastName }),
                        canDismiss: true
                    });
                break;
            case "AlreadyExists":
                props.addPageLevelAlert({ 
                    alertLevel: {alertLevel: "error"},
                    description: t("Response-Already-Exists"), 
                    canDismiss: true
                });
                break;
            case "400":
                props.addPageLevelAlert({ 
                    alertLevel: {alertLevel:"error"},
                    description: t("Response-400"),
                    canDismiss: true
                });
                break;
            case "500":
                props.addPageLevelAlert({ 
                    alertLevel: {alertLevel:"error"},
                    description: t("Response-500"),
                    canDismiss: true
                });
                break;
            default: 
                props.addPageLevelAlert({ 
                    alertLevel: {alertLevel:"error"},
                    description: t("Response-Default"),
                    canDismiss: true
                });
                break;
        }
    };

    const handleSaveChangesClick = async (data:any) => {
        setIsLoading(true);
        let selectedOption = orgsOptions.find(item => formFieldData.organizationId === item.value);

        data = {
            ...formFieldData,
            email: formFieldData.email === "" ? null : formFieldData.email,
            organizationIds: [...orgsOptions.filter((i) => i.value !== null).map((i) => i.value)],
            organizationName: selectedOption.label,
            orgId: orgs.filter((i) => i.organizationId === formFieldData.organizationId)[0].orgId,
            locale: culture
        };

        try {
            const response = await createParticipant(apimBaseUrl, configContext, data);
            let responseStatus = response ? "200" : "400";
            handleResponseStatus(response.participantStatus);
            setIsLoading(false);
            exit(true);
        } catch (e) {
            console.error(JSON.stringify(e));
            handleResponseStatus();
            setIsLoading(false);
            exit(false);
        }
    };

    React.useEffect(() => {
        const size = () => setContainerWidth(ref.current?.offsetWidth);
        setContainerWidth(ref.current?.offsetWidth);
        window.addEventListener("resize", size)
        return () => window.removeEventListener("resize", size);
    }, []);

    const {
        formFieldData,
        errors,
        handleChangeByUserEvent,
        handleChangeByGetEvent,
        handleSubmit
    } = useForm(handleSaveChangesClick, validate);

    const handleChangeOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({ organizationId: e.value });
    };

    return (
        <div ref={ref} className="create-participant-container">
            <div className={`create-participant-inputs-wrapper ${containerWidth > 650 ? 'col-6' : 'col-12'} p-0`}>
                <TextInput
                    label={t("First Name")}
                    name="firstName"
                    id="firstName"
                    isError={errors.hasOwnProperty('firstName')}
                    value={formFieldData.firstName}
                    changeAction={handleChangeByUserEvent}
                    errorLabel={errors.firstName || "eek"}
                    isRequired={true}
                ></TextInput>
                <br />
                <TextInput
                    label={t("Last Name")}
                    name="lastName"
                    id="lastName"
                    isError={errors.hasOwnProperty('lastName')}
                    value={formFieldData.lastName}
                    changeAction={handleChangeByUserEvent}
                    errorLabel={errors.lastName || "eek"}
                    isRequired={true}
                ></TextInput>
                <br />
                <TextInput
                    label={t("Email")}
                    name="email"
                    id="email"
                    isError={errors.hasOwnProperty('email')}
                    value={formFieldData.email}
                    changeAction={handleChangeByUserEvent}
                    errorLabel={errors.email || "eek"}
                    isRequired={false}
                ></TextInput>
                <br />
                <Select
                    label={t("Organization")}
                    name="organizationId"
                    id="organizationId"
                    filter
                    options={orgsOptions}
                    value={formFieldData.organizationId}
                    isRequired={true}
                    isError={errors.hasOwnProperty('organizationId')}
                    errorLabel={errors.organizationId}
                    changeFilterAction={handleChangeOrganization}
                />
                <br />
                <TextInput
                    label={t("External ID")}
                    name="externalId"
                    id="externalId"
                    value={formFieldData.externalId}
                    changeAction={handleChangeByUserEvent}
                    isError={errors.hasOwnProperty('externalId')}
                    errorLabel={errors.externalId}
                    isRequired={false}
                ></TextInput>
                <br />
            </div>
            <div className="create-participant-buttons-wrapper">
                <DestructiveButton label={<><i className="me-1 far fa-times" /> {t("Cancel")}</>} clickAction={() => exit(false)} />
                <Button
                    elementId="create-participant-button"
                    label={
                        <div className="d-flex align-items-center justify-content-center">
                            {isLoading && <i className="fas fa-spinner fa-spin mr-2"/>} {t("Create Participant")}
                        </div>
                    }
                    isSolid={false}
                    clickAction={handleSubmit}
                ></Button>
            </div>
        </div>
    );

};

export default CreateParticipant;
