import * as React from "react";

import { Button, Modal } from "react-bootstrap";

import SkuModel from "../Interfaces/SkuModel";
import AssociatedCourse from "../Interfaces/AssociatedCourse";
import { RbacContext } from "../rbac-context";
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import RemoteTable from "../Components/remote-table";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import useCurriculumApi from "../hooks/useCurriculumApi";
import Table from "../Components/table";
import useApi from "../hooks/useApi";
import { ConfigContext } from "../configuration-context";
import { getToken } from "../helper-functions";

const ManageSkusPage = () => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext); 
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [showModal, setShowModal] = React.useState(false);
    const [selectedSku, setSelectedSku] = React.useState<SkuModel>(null);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [totalResultsData, setTotalResultsData] = React.useState(0);

    const columnHelper = createColumnHelper<SkuModel>()
    const columns = React.useMemo<ColumnDef<SkuModel>[]>(
        () => [
            columnHelper.accessor('code', {
                cell: info => info.getValue(),
                header: t("Sku"),
                enableSorting: true,
                size: 25
            }),
            columnHelper.accessor('skuType', {
                cell: info => info.getValue(),
                header: t("SKU Type"),
                enableSorting: true,
                size: 25
            }),
            columnHelper.accessor('courseListJson', {
                cell: (info) => courseListColumn(info.row.original),
                header: t("Courses"),
                enableSorting: false,
                size: 25
            }),
        ],
        []
    );

    const { fetchData, isLoading } = useCurriculumApi(
        "/Q/Curriculum/Skus",
        "POST"
    )

    const downloadSkus = async () => {
        try {
            const response = await fetch(apimBaseUrl + "/Q/Curriculum/ExportSkus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify(
                    {
                        searchTerm: "",
                        sortBy: "",
                        pageNumber: 1,
                        resultsPerPage: totalResultsData,
                        sortDirection: "desc"
                    }
                )
            });
            if (response.status === 404 || response.status === 204) return undefined;
            return await response.blob()
            
        } catch (e) {
            console.error(e);
            // Handle fetch error
        }
    }
    
    const courseListColumn = (row: any) => {
        if(row.courseListJson){
            let courseList = JSON.parse(row.courseListJson);
            if(Array.isArray(courseList)){
                row.courseList = courseList;
            }
            else{
                row.courseList = [courseList];
            }    
            return (
                <Button
                    variant="btn-link"
                    style={{}}
                    disabled={row.courseList?.length < 1}
                    onClick={() => {
                        setSelectedSku({ ...row });
                        setShowModal(true);
                    }}
                >
                    {t("Used by")} {row.courseList?.length} {t("courses")}
                </Button>
            )
        }
    }

    
    const handleTotalResults = (total: number) => {
        setTotalResultsData(total)
    }

    const downloadSkuList = async () => {
        try {
            setDownloading(true);
            const resp = await downloadSkus()

            if (resp) {
                var today = new Date();
                saveAs(resp, `skus_${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.csv`);   
            }
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
            
        }
        setDownloading(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>{t("Manage SKUs")}</h2>
                <div>
                    <Button
                        variant="outline-primary"
                        onClick={() => {
                            downloadSkuList();
                        }}
                    >
                        <i className="fas fa-plus"></i> {t("Export SKU List")}
                    </Button>
                </div>
            </div>
            <Table
                keyField="SkuID"
                fetchFunction={fetchData}
                columns={columns}
                defaultSorted={{ dataField: "ModifyDate", order: "desc" }}
                isLoading={isLoading}
                forceRefresh={forceRefresh}
                handleTotalResults={handleTotalResults}
            />
            {showModal && (
                <SkuCoursesModal
                    show={showModal}
                    sku={selectedSku}
                    handleCancel={() => setShowModal(false)}
                />
            )}
        </>
    );
};

const RenderCourseList = ({ courseList }:
    { courseList: Array<AssociatedCourse> }) => {
    const list = new Array();
    courseList.forEach((course) => list.push(<p><a href={"/Admin/ManageCourses/Edit/" + course.CourseId}>{course.Name}</a></p>)
    );
    return (
        <div>
            { list }
        </div>
    );
};


const SkuCoursesModal = ({
    show,
    sku,
    handleCancel
}: {
    show: boolean;
    sku: SkuModel;
    handleCancel: () => void;
}) => {
    const { t } = useTranslation();
    
    return (
        <Modal show={show} onHide={handleCancel}>
            <Modal.Header>
                <Modal.Title>{sku.Code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RenderCourseList courseList={sku.courseList} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleCancel}>
                    {t("OK")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default withAITracking(reactPlugin, ManageSkusPage);
