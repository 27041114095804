import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const WriteYourOwnTrainingActivities = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    {/* WRITE HTML BELOW THIS LINE */}


                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1">{t('WriteYourOwn-Title')}</h1>
                                    <p>{t('WriteYourOwn-Subheading')}</p>
                                </>
                            </>
                        }
                    />

                    <Tile title={t('WriteYourOwn-Section1-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('WriteYourOwn-Section1-Content')}
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <img className="w-100" src="https://cdn.crisisprevention.com/media/training-center/images/intro.png" alt="Active and passive learning styles" />

                                    </>
                                }
                            />

                        </>
                    </Tile>

                    <Tile title={t('WriteYourOwn-Section2-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('WriteYourOwn-Section2-Content1')}
                                    </>
                                }


                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        {tp('WriteYourOwn-Section2-Content2')}

                                    </>
                                }
                            />
                        </>
                    </Tile>


                </Container>
            </div>




        </>
    );
};

export default WriteYourOwnTrainingActivities;
