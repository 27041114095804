import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Container, Form, Modal } from "react-bootstrap";

import { EnrollmentListItem } from "../../Interfaces";
import { RbacContext } from "../../rbac-context";
import { resendEnrollmentEmail } from "../../ApiServices/Class";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface ResendEmailModalProps {
    show: boolean;
    classId: number;
    enrollment: EnrollmentListItem;
    handleCancel: () => void;
    onSave: (resp: JsonResponseModel<ApiResponse>, refresh: boolean) => void;
}

const ResendEmailModal = ({ show, classId, enrollment, handleCancel, onSave }: ResendEmailModalProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [saving, setSaving] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setSaving(true);
                const resp = await resendEnrollmentEmail(classId, enrollment.UserId);
                setSaving(false);
                if (!resp.Error) {
                    resp.Data.Message = t("Enrollment email has been resent to {{emailAddress}}", {
                        emailAddress: enrollment.EmailAddress
                    });
                }
                onSave(resp, false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }          
        }
    };

    return (
        <Modal show={show} onHide={handleCancel}>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <h5>{t("Resend Enrollment Email")}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <p>{t("Are you sure you want to resend the enrollment email for the following learner?")}</p>
                        <p className="text-break">
                            <strong>
                                {enrollment.FirstName} {enrollment.LastName}
                            </strong>{" "}
                            ({enrollment.EmailAddress})
                        </p>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {!saving && t("Resend")}
                        {saving && (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Resending")}
                            </>
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, ResendEmailModal);
