export enum CultureEnum {
    enUS = "en-US",
    enCA = "en-CA",
    enGB = "en-GB",
    enIE = "en-IE",
    enAU = "en-AU",
    enNZ = "en-NZ",
    frFR = "fr-FR"
}

export namespace CultureEnum {
    export function getProperlyCasedCultureCode(cultureCode:string) {
        const lowerCasedCultureCode = cultureCode?.toLocaleLowerCase();

        switch (lowerCasedCultureCode) {
            case "en-us":
                return CultureEnum.enUS;
            case "en-ca":
                return CultureEnum.enCA;
            case "en-gb":
                return CultureEnum.enGB;
            case "en-ie":
                return CultureEnum.enIE;
            case "en-au":
                return CultureEnum.enAU;
            case "en-nz":
                return CultureEnum.enNZ;
            case "fr-fr":
                return CultureEnum.frFR;
            default:
                return CultureEnum.enUS;
        }
    }
}