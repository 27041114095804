export default function validate(values:any) {
    let errors: any = {};

    // Uses regex patterns from HoneyBee component
    
    if (!values.newPassword) {
        errors.newPassword = "Enter new password";
        errors.minLength = true;
        errors.upperCase = true;
        errors.lowerCase = true;
        errors.number = true;
        errors.symbol = true;
        errors.passwordsMatch = true;

        errors.submitMinLength = true;
        errors.submitUpperCase = true;
        errors.submitLowerCase = true;
        errors.submitNumber = true;
        errors.submitSymbol = true;
        errors.submitPasswordsMatch = true;
    } else {
        // At least 8 characters
        if (!values.newPassword.match(/.{8,}/)) {
            errors.minLength = true;
            errors.submitMinLength = true;
        }
        // Uppercase character
        if (!values.newPassword.match(/[A-Z]/)) {
            errors.upperCase = true;
            errors.submitUpperCase = true;
        }
        // Lowercase character
        if (!values.newPassword.match(/[a-z]/)) {
            errors.lowerCase = true;
            errors.submitLowerCase = true;
        }
        // Number character
        if (!values.newPassword.match(/[0-9]/)) {
            errors.number = true;
            errors.submitNumber = true;
        }
        // Symbol character
        if (!values.newPassword.match(/[!@#$%`~^&*()\-_=+[\]{}.,<>\/\\?'";:|]/)) {
            errors.symbol = true;
            errors.submitSymbol = true;
        }
        // Passwords Match
        if (values.confirmPassword === "" && values.newPassword === "") {
            errors.passwordsMatch = true;
            errors.submitPasswordsMatch = true;
        } else if (values.confirmPassword !== values.newPassword) {
            errors.passwordsMatch = true;
            errors.submitPasswordsMatch = true;
        }
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = "Enter new password again";
    } else {
        if (values.confirmPassword === "" && values.newPassword === "") {
            errors.passwordsMatch = true;
            errors.submitPasswordsMatch = true;
        } else if (values.confirmPassword !== values.newPassword) {
            errors.passwordsMatch = true;
            errors.submitPasswordsMatch = true;
        }
    }

    return errors;
}