import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";
import { RbacContext } from "../../../rbac-context";
import useForm from "../../../hooks/useForm";
import useStatesToCountryMapping from "../../../hooks/useStatesToCountryMapping";
import validate from "../../../FormValidationRules/updateProfileValidationRules";
import validateLearner from "../../../FormValidationRules/updateLearnerProfileValidationRules";
import TextInput from "../../../Components/Form/text-input";
import Select from "../../../Components/Form/select";
import TwoColumnLayout from "../../../Components/UI/UX-Layout/Tiles/two-column-layout";
import Tile from "../../../Components/UI/tile";
import FormDisplay from "../../../Components/Form/form-display";
import { ConfigContext } from "../../../configuration-context";
import ProfileDataModel from "../../../Interfaces/Platform/ApimData/ProfileDataModel";
import ArrowLink from "../../../Components/UI/arrow-link";
import { useTranslation } from "react-i18next";
import { getToken, isOnlyLearner } from "../../../helper-functions";
import { useNavigate } from "react-router-dom";
import { fetchMyProfileInfo } from "../../../ApiServices/Login";
import { getUserClaims } from "../../../helper-functions";

const EditProfile = (props: {
    showOrganizationSection?: boolean,
    showSaveChangesButton?: boolean,
    isUserUpdatingDataFromDashboard?: boolean,
    setUserContactDataIsVerified: Function,
}) => {
    const { t } = useTranslation();

    //
    // SETTING CONFIGCONTEXT
    //
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const rbac = React.useContext(RbacContext);
    const isParticipant = isOnlyLearner(rbac.userContext);
    const navigate = useNavigate();
    const [countryOptions, setCountryOptions] = React.useState([]);
    const [stateOptions, setStateOptions] = React.useState([]);
    const [userProfileHasLoaded, setUserProfileHasLoaded] = React.useState(false);
    const [organizationDataHasLoaded, setOrganizationDataHasLoaded] = React.useState(false);

    const [organizationData, setOrganizationData] = React.useState({
        organizationName: "",
        city: "",
        state: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        phone: "",
        zipCode: "",
        accountEmail: "" 
    });

    //
    // GET ACCOUNT PROFILE DATA ONCE APIM KEY IS SET FROM APPCONFIG
    //
    React.useEffect(() => {

        if (configContext?.SystemConfiguration?.ApimKey) {
            fetchMyProfileInfo(getUserClaims().sub).then(function (data) {
                handleChangeByGetEvent(data);

                setCountryOptions(getCountrySelectOptions(data.country));
                setStateOptions(getStateSelectOptionsByCountry(data.country, data.state));

                setUserProfileHasLoaded(true);
                getOrgInfo(data);
            })
            .catch(function (error) {            
                setUserProfileHasLoaded(true);
            });
        }

    }, [configContext?.SystemConfiguration?.ApimKey]);

    //
    // GET Account Organization data once the profile data is returned (org id will be in profile data)
    //
    const getOrgInfo = (profileData:ProfileDataModel) => {
        if ((configContext?.SystemConfiguration?.ApimKey && profileData.organizationId) && props.showOrganizationSection) {
            fetch(apimBaseUrl + "/Q/Organization/Details/" + profileData.organizationId, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                }
            })
            .then(resp => resp.json())
            .then(function (data) {
                setOrganizationData(data);
                setOrganizationDataHasLoaded(true);
            })
            .catch(function (error) {
                setOrganizationDataHasLoaded(true);
            });
        }
    
    }

    //
    // POST FORM
    //
    const handleSaveChangesClick = function (data:any) {
        let _data = {...data}
        
        if (Array.isArray(_data.state)) {
            const state = _data.state.find(item => item.default);
            state !== null && state !== undefined
                ? _data.state = state.value
                : _data.state = "-";
        }

        for (let key in _data) {
            if (_data[key] == "") {
                _data[key] = null;
            }
        }

        let accountInfoEndpoint = props.isUserUpdatingDataFromDashboard ? "verifyaccountinfo" : "UpdateAccountInfo"
        fetch(apimBaseUrl + `/C/Profile/${accountInfoEndpoint}` , {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(_data)
        })
            .then(resp => resp.json())
            .then(function (_data) {
                props.isUserUpdatingDataFromDashboard
                    ? props.setUserContactDataIsVerified(true)
                    : navigate("/Support/Profile");
            })
            .catch(function (error) {
                console.error(JSON.stringify(error));
        });
    }

    const handleCancel = () =>  {
        navigate("/Support/Profile");
    }

    //
    // Custom Hooks
    //
    const {
        formFieldData,
        errors,
        handleChangeByUserEvent,
        handleChangeByGetEvent,
        handleSubmit
    } = useForm(handleSaveChangesClick, !isParticipant ? validate : validateLearner);
    // handles field validation and routes submission

    const {
        getCountrySelectOptions,
        getStateSelectOptionsByCountry
    } = useStatesToCountryMapping();
    // handles country and state dropdown options

    //
    // useEffect Hooks
    // 
    React.useEffect(() => {
        setStateOptions(getStateSelectOptionsByCountry(formFieldData.country, formFieldData.state));
        handleChangeByGetEvent({ state: getStateSelectOptionsByCountry(formFieldData.country, formFieldData.state) });
    }, [formFieldData.country]);
    // Updates state dropdown options when country is changed

    // COMPONENT UI
    return (
        <>
            <div className="page-content m-auto even-height">

                { props.isUserUpdatingDataFromDashboard &&
                    <>
    
                        <div className="mt-4"><FormDisplay label={ t("Support.EditProfile.WorkEmail") } content={formFieldData.email} /></div>

                        <div className="mt-4"><TextInput
                            label={ t("First Name") }
                            isError={errors.hasOwnProperty('firstName')}
                            name="firstName"
                            id="firstName"
                            maxLength={50}
                            value={formFieldData.firstName}
                            changeAction={handleChangeByUserEvent}
                            isRequired={true}
                            errorLabel={errors.firstName}
                        /></div>

                        <div className="mt-4"><TextInput
                            label={ t("Last Name") }
                            isError={errors.hasOwnProperty('lastName')}
                            name="lastName"
                            id="lastName"
                            maxLength={50}
                            value={formFieldData.lastName}
                            changeAction={handleChangeByUserEvent}
                            isRequired={true}
                            errorLabel={errors.lastName}
                        /></div>

                        {!isParticipant && (
                            <>
                                <div className="mt-4"><TextInput
                                    label={ t("Support.Profile.JobTitle") }
                                    isError={errors.hasOwnProperty('title')}
                                    name="title"
                                    id="title"
                                    maxLength={60}
                                    value={formFieldData.title}
                                    changeAction={handleChangeByUserEvent}
                                    isRequired={true}
                                    errorLabel={errors.title}
                                /></div>

                                <div className="mt-4"><TextInput
                                    label={ t("Support.EditProfile.WorkAddress1") }
                                    isError={errors.hasOwnProperty('addressLine1')}
                                    name="addressLine1"
                                    id="addressLine1"
                                    maxLength={60}
                                    value={formFieldData.addressLine1}
                                    changeAction={handleChangeByUserEvent}
                                    isRequired={true}
                                    errorLabel={errors.addressLine1}
                                /></div>

                                <div className="mt-4"><TextInput
                                    label={ t("Support.EditProfile.WorkAddress2") }
                                    name="addressLine2"
                                    id="addressLine2"
                                    maxLength={60}
                                    value={formFieldData.addressLine2}
                                    changeAction={handleChangeByUserEvent}
                                    isRequired={false}
                                /></div>

                                <div className="mt-4"><Select
                                    label={ t("CPI.UI.Form.CreateAccount.Country") }
                                    isError={errors.hasOwnProperty('country')}
                                    name="country"
                                    id="country"
                                    options={countryOptions}
                                    changeAction={handleChangeByUserEvent}
                                    isRequired={true}
                                    errorLabel={errors.country}
                                /></div>

                                <div className="d-flex align-items-end justify-content-between mt-4 city-state-zip">
                                    <div className="pl-1 pr-1 city-wrapper">
                                        <TextInput isError={errors.hasOwnProperty('city')} maxLength={60} errorLabel={errors.city} label={ t("CPI.UI.Form.CreateAccount.City") } name="city" id="city" value={formFieldData.city} changeAction={handleChangeByUserEvent} isRequired={true} />
                                    </div>

                                    <div className="pl-1 pr-1 state-wrapper">
                                        <Select isError={errors.hasOwnProperty('state')} errorLabel={errors.state} label={ t("CPI.UI.ManageAccount.State") } name="state" id="state" options={stateOptions} changeAction={handleChangeByUserEvent} isRequired={true} />
                                    </div>

                                    <div className="pl-1 pr-1 zip-wrapper">
                                        <TextInput isError={errors.hasOwnProperty('zipCode')} maxLength={10} errorLabel={errors.zipCode} label={ t("CPI.UI.ManageAccount.ZipCode") } name="zipCode" id="zipCode" value={formFieldData.zipCode} changeAction={handleChangeByUserEvent} isRequired={true} />
                                    </div>
                                </div>

                                <div className="mt-4"><TextInput
                                    label={ t("CPI.UI.Phone") }
                                    isError={errors.hasOwnProperty('phone')}
                                    name="phone"
                                    id="phone"
                                    value={formFieldData.phone}
                                    changeAction={handleChangeByUserEvent}
                                    isRequired={true}
                                    errorLabel={errors.phone}
                                /></div>
                            </>
                        )}
                    </>
                }

                { !props.isUserUpdatingDataFromDashboard &&
                    <>
                        <Container fluid>
                            <Row>
                                <Col xs={12}>

                                    <h1 className="mt-4 page-title-h1">{ t("Support.EditProfile.ContactInformation") }</h1>

                                </Col>
                            </Row>
                        </Container>

                        <TwoColumnLayout
                        left={
                            <>
                                <Tile hide={!userProfileHasLoaded} title={ t("Support.EditProfile.ContactInformation") }>
                                    <>
        
                                        <div className="mt-4"><FormDisplay label={ t("Support.EditProfile.WorkEmail") } content={formFieldData.email} /></div>
        
                                        <div className="mt-4"><TextInput
                                            label={ t("First Name") }
                                            isError={errors.hasOwnProperty('firstName')}
                                            name="firstName"
                                            id="firstName"
                                            maxLength={50}
                                            value={formFieldData.firstName}
                                            changeAction={handleChangeByUserEvent}
                                            isRequired={true}
                                            errorLabel={errors.firstName}
                                        /></div>
        
                                        <div className="mt-4"><TextInput
                                            label={ t("Last Name") }
                                            isError={errors.hasOwnProperty('lastName')}
                                            name="lastName"
                                            id="lastName"
                                            maxLength={50}
                                            value={formFieldData.lastName}
                                            changeAction={handleChangeByUserEvent}
                                            isRequired={true}
                                            errorLabel={errors.lastName}
                                        /></div>

                                        {!isParticipant && (
                                            <>
                                                <div className="mt-4"><TextInput
                                                    label={ t("Support.Profile.JobTitle") }
                                                    isError={errors.hasOwnProperty('title')}
                                                    name="title"
                                                    id="title"
                                                    maxLength={60}
                                                    value={formFieldData.title}
                                                    changeAction={handleChangeByUserEvent}
                                                    isRequired={true}
                                                    errorLabel={errors.title}
                                                /></div>
                
                                                <div className="mt-4"><TextInput
                                                    label={ t("Support.EditProfile.WorkAddress1") }
                                                    isError={errors.hasOwnProperty('addressLine1')}
                                                    name="addressLine1"
                                                    id="addressLine1"
                                                    maxLength={60}
                                                    value={formFieldData.addressLine1}
                                                    changeAction={handleChangeByUserEvent}
                                                    isRequired={true}
                                                    errorLabel={errors.addressLine1}
                                                /></div>
        
                                                <div className="mt-4"><TextInput
                                                    label={ t("Support.EditProfile.WorkAddress2") }
                                                    name="addressLine2"
                                                    id="addressLine2"
                                                    maxLength={60}
                                                    value={formFieldData.addressLine2}
                                                    changeAction={handleChangeByUserEvent}
                                                    isRequired={false}
                                                /></div>
                
                                                <div className="mt-4"><Select
                                                    label={ t("CPI.UI.Form.CreateAccount.Country") }
                                                    isError={errors.hasOwnProperty('country')}
                                                    name="country"
                                                    id="country"
                                                    options={countryOptions}
                                                    changeAction={handleChangeByUserEvent}
                                                    isRequired={true}
                                                    errorLabel={errors.country}
                                                /></div>
        
                                                <div className="d-flex align-items-end justify-content-between mt-4 city-state-zip">
                                                    <div className="pl-1 pr-1 city-wrapper">
                                                        <TextInput isError={errors.hasOwnProperty('city')} maxLength={60} errorLabel={errors.city} label={ t("CPI.UI.Form.CreateAccount.City") } name="city" id="city" value={formFieldData.city} changeAction={handleChangeByUserEvent} isRequired={true} />
                                                    </div>
                
                                                    <div className="pl-1 pr-1 state-wrapper">
                                                        <Select isError={errors.hasOwnProperty('state')} errorLabel={errors.state} label={ t("CPI.UI.ManageAccount.State") } name="state" id="state" options={stateOptions} changeAction={handleChangeByUserEvent} isRequired={true} />
                                                    </div>
                
                                                    <div className="pl-1 pr-1 zip-wrapper">
                                                        <TextInput isError={errors.hasOwnProperty('zipCode')} maxLength={10} errorLabel={errors.zipCode} label={ t("CPI.UI.ManageAccount.ZipCode") } name="zipCode" id="zipCode" value={formFieldData.zipCode} changeAction={handleChangeByUserEvent} isRequired={true} />
                                                    </div>
                                                </div>
                
                                                <div className="mt-4"><TextInput
                                                    label={ t("CPI.UI.Phone") }
                                                    isError={errors.hasOwnProperty('phone')}
                                                    name="phone"
                                                    id="phone"
                                                    value={formFieldData.phone}
                                                    changeAction={handleChangeByUserEvent}
                                                    isRequired={true}
                                                    errorLabel={errors.phone}
                                                /></div>
                                            </>
                                        )}
                                    </>
                                </Tile>
                            </>
                        }
        
                        right={
                            <>
                                {!isParticipant && (
                                    <Tile hide={!organizationDataHasLoaded} title={ t("Organization") }>
                                        <>
                                            <div className="mt-4">
                                                <FormDisplay label={ t("Support.Profile.OrganizationBaseEmployment") } content={"This is Org Info"} />
                                                <div>{organizationData.organizationName}</div>    
                                                <div>{organizationData.addressLine1}</div>
                                                <div>{organizationData.addressLine2}</div>
                                                <div>{organizationData.city + ', ' + organizationData.state + ' ' + organizationData.zipCode}</div>
                                                <div>{organizationData.country}</div>
                                                <div className="mt-3">{organizationData.phone}</div>
                                                <ArrowLink data={{label: t("Support.EditProfile.RequestChangeBaseEmployment"), href: "#"}} />
                                            </div>                                                
                                        </>
                                    </Tile>
                                )}
                            </>
                        }
        
                        />

                    </>
                }

                <Container fluid className={`${isParticipant ? 'mt-3' : ''}`}>
                <Row>
                    <Col xs={12}>

                        { ((props.showSaveChangesButton || organizationDataHasLoaded) &&
                            <div className="d-flex justify-content-between mt-3">
                                { !props.isUserUpdatingDataFromDashboard &&
                                    <DestructiveButton label="Cancel" clickAction={() => handleCancel()}/>
                                }
                            
                                <Button elementId="edit-profile-button" clickAction={handleSubmit} label={(props.isUserUpdatingDataFromDashboard) ? t("Support.EditProfile.SaveContactInformation") : t("Save Changes")} isSolid={true} />
                            </div>)
                        }

                    </Col>
                </Row>
                </Container>
                          
            </div>
        </>
    )
};

export default EditProfile;