//import "./topnav.scss";

import * as React from "react";

import { AdminRoles, InstructorRoles, StaffSeatManagementRoles } from "../../Enums/RoleEnum";
import { Badge, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RbacContext, emptyUserContext } from "../../rbac-context";

import { UrlGroups } from "../../Routing/routes";
import { dataLayer } from "../../google-tag-manager";
import { logout } from "../../ApiServices/Login";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

import useNetworkStatus from "../../hooks/useNetworkStatus";
import CultureSelector from "./culture-selector";
import CartIcon from "./cart-icon";
import Button from "../../Components/UI/button";
import Breadcrumbs from "./breadcrumbs";
import CPILockup from "./cpi-lockup";

import { CpiRoute } from "../../Routing/routes";
import { ConfigContext } from "../../configuration-context";

const Header = (
    props: {
        toggleSideNavIsOpen?: any,
        setHeaderHeight?: any,
        route?: CpiRoute,
        ref?: any,
        siteHeaderIsHidden: boolean,
        siteHeaderIsFixed: boolean,
        siteHeaderIsAnimated: boolean
    }
): JSX.Element => {
    const { t } = useTranslation();
    const isOnline = useNetworkStatus();
    const { userContext, resetContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const history = useNavigate();
    const maint = process.env.ENVIRONMENT == "maint";
    const ping = configContext?.SystemConfiguration?.UsePing;
    const crumbsArray = ['Dashboard', 'Parent', 'Child'];

    const ref = useRef(null);

    useEffect(() => {
        props.setHeaderHeight(ref.current.clientHeight);
    }, [ref, isOnline]);

    return (
        <header ref={ref} className={`site-header ${props.siteHeaderIsHidden ? 'is-hidden' : ''} ${props.siteHeaderIsFixed ? 'is-fixed' : ''} ${props.siteHeaderIsAnimated ? 'is-animated' : ''}`}>
            {!isOnline && (
                <div className="offline-banner">
                    <i className="fa-sharp fa-solid fa-triangle-exclamation offline-banner-icon mr-2"/>
                    {t("offline-alert")}
                </div>
            )}
            <div className="main-navigation">
                <nav className="navbar navbar-expand-lg">
                    <div className="navbar-wrapper">
                        
                        <CPILockup />

                        <div className="navbar-buttons d-flex justify-content-end">
                            <button id="main-nav-icon" onClick={props.toggleSideNavIsOpen} className="nav-button mr-auto">
                                <span className="nav-icon icon-menu"><span className="sr-only">Menu</span></span>
                            </button>
                            
                            <CultureSelector />
                            <CartIcon numberOfItemsInCart={5} />
                            
                        </div>
                    </div>
                </nav>
            </div>
            <div className="header-bar">
                
                <Breadcrumbs />

            </div>
        </header>
    );
};

export default Header;
