import { getToken } from "../../helper-functions";
import { bulkUploadValidationParser } from "./bulk-upload-response";
import { bulkUploadParser } from "./import-response";

export const fetchBulkUploadTemplate = async (request: any, configContext: any) => {
    try {
        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/C/Participant/CreateParticipantBulkTemplate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });
        if (response.status === 404 || response.status === 204) return "";
        return await response.text();
    } catch (e) {
        // Handle fetch error
    }
};

export const uploadBulkFile = async (request: any, file: any, configContext: any) => {
    try {
        const formData = new FormData();

        formData.append("organizationId", request.organizationId.toString());
        formData.append("file", file, file.name);
        formData.append("template", request.template);
        formData.append("locale", request.locale);
        formData.append("seatsAvailable", request.seatsAvailable);
        formData.append("desiredLms", request.desiredLms);

        if (request.classId) {
            formData.append("classId", request.classId);
        }

        if (request.courseId) {
            formData.append("courseId", request.courseId);
        }

        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/C/Participant/CreateParticipantBulkValidation`, {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: formData
        });

        return await bulkUploadValidationParser(response);

    } catch (e) {
        console.log(e);
    }
};

export const fetchErrorReport = async (request: any, configContext: any) => {
    try {
        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/C/Participant/CreateParticipantBulkValidationErrors`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });

        return response;
    } catch (e) {
        // Handle fetch error
    }
};

export const bulkCreation = async (request: any, configContext: any) => {
    try {
        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/C/Participant/CreateParticipantBulk`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(request)
        });

        return await bulkUploadParser(response);
    } catch (error) {
        console.error(error);
    }
};

export const fetchSeatsAvailable = async (configContext: any, seatOrganizationId?: string, courseId?: string) => {
    try {
        const response = await fetch(`${configContext.SystemConfiguration.ApimBaseUrl}/Q/Organization/Seats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationId: seatOrganizationId,
                courseId
            })
        });
        if (response.status === 404 || response.status === 204) return "";
        return await response.json();
    } catch (e) {
        // Handle fetch error
    }
};
