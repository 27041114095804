import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";
import VideoEmbed from "../../Components/UI/video-embed";

const VIThirdEdition = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}

            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1 title-margin">{t('VI3E-Title')}</h1>
                                </>
                            </>
                        }
                    />
                    <EightByFour
                        left={
                            <>
                                {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                <p>{t('VI3E-Intro')}</p>
                            </>
                        }

                        right={
                            <>
                                {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                <div className="mt-3">
                                    <Button label={t('BTN-ViewClassroomMaterials')} isSolid={true} elementId="buttonId" href="/Dashboard" />
                                </div>

                            </>
                        }
                    />


                    <Tile title={t('VI3E-IntroSectionTitle')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}


                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('VI3E-IntroSection-Content1')}

                                    </>
                                }
                            />




                            <SixBySix
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('VI3E-IntroSection-Content2')}

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('VI3E-IntroSection-Content3')}


                                    </>
                                }
                            />

                        </>
                    </Tile>

                    <Tile title={tp('VI3E-Mod1Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 1 Video" vimeoId={tp('VI3E-Mod1Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod1.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod1Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod1Section-Content2')}
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />

                        </>
                    </Tile>

                    <Tile title={t('VI3E-Mod2Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 2 Video" vimeoId={t('VI3E-Mod2Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod2.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod2Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod2Section-Content2')}

                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>

                    <Tile title={t('VI3E-Mod3Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 3 Video" vimeoId={t('VI3E-Mod3Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod3.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod3Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod3Section-Content2')}

                                            </>
                                        </GrayTile>

                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title={t('VI3E-Mod4Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 4 Video" vimeoId={t('VI3E-Mod4Section-Title')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod4.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod4Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod4Section-Content2')}

                                            </>
                                        </GrayTile>

                                    </>
                                }
                            />


                        </>
                    </Tile>
                    <Tile title={t('VI3E-Mod5Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 5 Video" vimeoId={t('VI3E-Mod5Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod5.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod5Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod5Section-Content2')}

                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title={t('VI3E-Mod6Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 6 Video" vimeoId={t('VI3E-Mod6Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod6.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod6Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod6Section-Content2')}

                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <Tile title={t('VI3E-Mod7Section-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Module 7 Video" vimeoId={t('VI3E-Mod7Section-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-mod7.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-Mod7Section-Content1')}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-Mod7Section-Content2')}

                                                <ArrowLink data={{ label:  t('VI3E-Mod7Section-Link') , href: "https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/Critical-Incident-Reporting.pdf", openInNewTab: true }} />

                                            </>
                                        </GrayTile>

                                    </>
                                }
                            />


                        </>
                    </Tile>

                    <Tile title={t('VI3E-ConclusionSection-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                        <VideoEmbed title="Introduction Video" vimeoId={t('VI3E-ConclusionSection-VimeoId')} thumbnailUrl="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/vid-3e-conclusion.png" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('VI3E-ConclusionSection-Content1')}
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                {tp('VI3E-ConclusionSection-Content2')}

                                                <ArrowLink data={{ label:  t('VI3E-ConclusionSection-VIChecklistLink') , href: "https://cdn.crisisprevention.com/media/training-center/classroom-resources/Physical-Skills/CHECKLIST_VI_3E_Verbal_Intervention_Checklist.pdf", openInNewTab: true }} />
                                            </>
                                        </GrayTile>
                                    </>
                                }
                            />
                        </>
                    </Tile>

                    <Tile title={t('VI3E-MaterialsSection-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                        <img className="gray-border-thin mt-2" width="150" src="https://cdn.crisisprevention.com/media/training-center/videos/nci-3e/THUMB_NCI_3E_IG.jpg" alt="Nonviolent Crisis Intervention Instructor Guide" />

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                        <ArrowLink data={{ label:  t('VI3E-MaterialsSection-IGLink') , href: "", openInNewTab: false }} />
                                        <ArrowLink data={{ label:  t('VI3E-MaterialsSection-WorkbookLink') , href: "", openInNewTab: false }} />
                                        <ArrowLink data={{ label:  t('VI3E-MaterialsSection-VideoLink') , href: "", openInNewTab: false }} />


                                    </>
                                }
                            />
                        </>
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default VIThirdEdition;
