import * as React from "react";
import useFeatureFlags from "./useFeatureFlags";

type BooleanOrPending = boolean | "pending";

const useTrainableSpecialtiesByCert = (ITLIntermediatePhysicalSkills: BooleanOrPending) => {

    // Local configuration for conditions to add MAPA, PMFS, or PTP to the list of trainable programs.
    // If a program in the array is what's passed in (selected on the form) then add the indicated programs.
    const programsToAddMapaAndPmfs = ["SIFC", "SIFA", "SIAC", "SIAA", "SIEC", "SIEA"];
    const programsToAddPtp = ["APSE", "APS", "NCI-IPS", "CPI", "VI"];

    // This function takes a cert and returns a list of programs that are trainable under it.
    // It also handles a few other configurable conditions.
    const trainablePrograms = (cert: string, culture: string, addMapaPmfsOrPtp?: boolean, isBA?: boolean) => {        
        let programs: string[] = [];

        switch (cert) {
            case "APSE":
                programs = ["DEB", "PF", "VI", "CPI", "APS", "APSE"];
                break;
            case "APS":
                programs = ["DEB", "PF", "VI", "CPI", "APS"];
                break;
            case "NCI-IPS":
                programs = ["DEB", "PF", "VI", "CPI", "NCI-IPS"];
                break;
            case "CPI-DCC":
                programs = ["PF", "DCC", "CPI-DCC"];
                break;
            case "NCI":
                programs = ["DEB", "PF", "VI", "CPI"];
                break;
            case "CPI":
                programs = ["DEB", "PF", "VI", "CPI"];
                break;
            case "VIA":
                programs = ["PF", "VIA"];
                break;
            case "VIC":
                programs = ["PF", "VIA", "VIC"];
                break;
            case "SIFA":
                programs = ["PF", "VIA", "SIFA"];
                break;
            case "SIFC":
                programs = ["PF", "VIC", "VIA", "SIFC", "SIFA"];
                break;
            case "SIAA":
                programs = ["PF", "VIA", "SIFA", "SIAA"];
                break;
            case "SIAC":
                programs = ["PF", "VIA", "VIC", "SIFA", "SIFC", "SIAA", "SIAC"];
                break;
            case "SIEA":
                programs = ["PF", "VIA", "SIFA", "SIAA", "SIEA"];
                break;
            case "SIEC":
                programs = ["PF", "VIA", "VIC", "SIFA", "SIFC", "SIAA", "SIAC", "SIEA", "SIEC"];
                break;
            case "DCC":
                programs = ["PF", "DCC"];
                break;
            case "CC":
                programs = ["PF", "CC"];
                break;
            case "CH":
                programs = ["PF", "CH"];
                break;
            case "VI":
                programs = ["DEB", "PF", "VI"];
                break;
            case "PTP":
                programs = ["PTP"];
                break;
            default:
                programs = [];
        }

        // If the user is a BA and they need to create a class without a certified instructor
        // we at least add PF to the list of programs.
        if (isBA) {
            if (!programs.some((p) => p === "PF")) {
                programs.push("PF");
                programs.push("DEB");
            }            
        }

        // If the calling script passes in true to addMapaPmfsOrPtp then we'll add those programs
        // if the cert is in the list of programs that should have them.
        if (addMapaPmfsOrPtp && programsToAddMapaAndPmfs.indexOf(cert) >= 0) {
            programs.push("MAPA");
            programs.push("PMFS");
        }

        // If the calling script passes in true to addMapaPmfsOrPtp then we'll add it
        // if the cert is in the list of programs that should have it.
        if (addMapaPmfsOrPtp && programsToAddPtp.indexOf(cert) >= 0) {
            programs.push("PTP");
        }

        // If ITLIntermediatePhysicalSkills is enabled and cert is either APS or APSE 
        // then we want to inject NCI-IPS into programs[] after CPI.
        if (ITLIntermediatePhysicalSkills && (cert === "APS" || cert === "APSE")) {
            // Ensuring that CPI is in the list before trying to inject after it.
            // If it's not in the list (which shouldn't occur) then we'll just add NCI-IPS to the end.
            if (programs.indexOf("CPI") !== -1) {
                programs.splice(programs.indexOf("CPI") + 1, 0, "NCI-IPS");
            } else {
                programs.push("NCI-IPS");
            }
        }

        return programs;
    };

    // This function handles a list of certs rather than one at a time and returns a deduplicated list of programs that are trainable
    // given the list that's apssed in.
    const trainableProgramsByListOfCerts = (certs: string[], culture: string, addMapaPmfsOrPtp?: boolean, isBA?: boolean) => {
        let programs: string[] = [];

        certs.forEach((cert) => {
            programs = programs.concat(trainablePrograms(cert, culture, addMapaPmfsOrPtp, isBA));
        });

        // De-duplicate the list of programs
        const uniquePrograms = [...new Set(programs)];

        return uniquePrograms;
    }

    return [trainablePrograms, trainableProgramsByListOfCerts] as const;
};

export default useTrainableSpecialtiesByCert;
