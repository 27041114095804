import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const RefresherTraining = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={true}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <h1 className="page-title-h1">{ t('Refreshers-Title') }</h1>
                                <p className="mw-900 mt-3">{ tp('Refreshers-Intro') }</p>

                                <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <Tile customClassName="mt-0" title={ t('Refreshers-WhatIsRefresher-Title') }  hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <span className="parsed-text">{ tp('Refreshers-WhatIsRefresher') }</span>

                                        
                                        </>      
                                    </Tile>
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <Tile customClassName="mt-0" title={ t('Refreshers-When-Title') }  hideTileHeader={false}>
                                        <>
                                            {/* WRITE HTML BELOW THIS LINE */}
                                            <span className="parsed-text">{ tp('Refreshers-When') }</span>
                                        
                                        </>      
                                    </Tile>
                                    </>
                                }
                            />


                            </>
                            
                        }
                    />
                    <Tile title={ t('Refreshers-NCIRefresher-Title') } hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <TwoColumnLayout
                            left={
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <h3 className="sub-headings-h3">{ t('Refreshers-ClassroomTraining-Title') }</h3>
                                    { tp('Refreshers-ClassroomTraining-Intro') }

                                    <Button label={ t('BTN-DownloadLessonPlanTemplate') } isSolid={false} elementId="DownloadClassroomLessonPlan" href="https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson-Plan.pdf" />
                                    <h4 className="h4 mt-4">{ t('Refreshers-MinimumRecommendedTime') }</h4>
                                    
                                    <div className="row border-bottom bg-light mx-0 py-2">
                                        <div className="col-3 small">{ tp('Refreshers-Participants') }</div>
                                        <div className="col-3 small">{ tp('Refreshers-NonrestrictiveModules') }</div>
                                        <div className="col-3 small">{ tp('Refreshers-RestrictiveModules') }</div>
                                        <div className="col-3 small">{ tp('Refreshers-MinClassTime') }</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">Less than 15</div>
                                        <div className="col-3 small">3.25 hours</div>
                                        <div className="col-3 small">2 hours</div>
                                        <div className="col-3 small">5.25 hours</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">15 to 30</div>
                                        <div className="col-3 small">3.25 hours</div>
                                        <div className="col-3 small">3 hours</div>
                                        <div className="col-3 small">6.25 hours</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">More than 30</div>
                                        <div className="col-3 small">3.25 hours</div>
                                        <div className="col-3 small">4 hours</div>
                                        <div className="col-3 small">7.25 hours</div>
                                    </div>
                                    { tp('Refreshers-MinTime-Notes') }

                                    <h3 className="sub-headings-h3 mt-5">{ t('Refreshers-BlendedTraining-Title') }</h3>
                                    { tp('Refreshers-BlendedTraining-Intro') }
                                    
                                    <Button label={ t('BTN-DownloadLessonPlanTemplate') } isSolid={false} elementId="DownloadBlendedLessonPlan" href="https://cdn.crisisprevention.com/media/training-center/classroom-resources/Lesson-Plan.pdf" />
                                    <h4 className="h4 mt-4">{ t('Refreshers-MinimumRecommendedTime') }</h4>
                                    
                                    <div className="row border-bottom bg-light mx-0 py-2">
                                        <div className="col-3 small">{ tp('Refreshers-Participants') }</div>
                                        <div className="col-3 small">{ tp('Refreshers-OnlineTraining') }</div>
                                        <div className="col-2 small">{ tp('Refreshers-NonrestrictiveModules') }</div>
                                        <div className="col-2 small">{ tp('Refreshers-RestrictiveModules') }</div>
                                        <div className="col-2 small">{ tp('Refreshers-MinClassTime') }</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">Less than 15</div>
                                        <div className="col-3 small">2 to 2.5 hours</div>
                                        <div className="col-2 small">1 hour</div>
                                        <div className="col-2 small">2 hours</div>
                                        <div className="col-2 small">3 hours</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">15 to 30</div>
                                        <div className="col-3 small">2 to 2.5 hours</div>
                                        <div className="col-2 small">1 hour</div>
                                        <div className="col-2 small">3 hours</div>
                                        <div className="col-2 small">4 hours</div>
                                    </div>
                                    <div className="row border-bottom mx-0 py-2">
                                        <div className="col-3 small">More than 30</div>
                                        <div className="col-3 small">2 to 2.5 hours</div>
                                        <div className="col-2 small">1 hour</div>
                                        <div className="col-2 small">4 hours</div>
                                        <div className="col-2 small">5 hours</div>
                                    </div>
                                    { tp('Refreshers-MinTime-Notes') }




                                </>
                            }

                            right={
                                <>
                                    {/* WRITE HTML BELOW THIS LINE */}
                                    <h3 className="sub-headings-h3">{ t('Refreshers-FAQ-Title') }</h3>
                                    <ul className="px-3">
                                        <li>
                                            <p className="h4">{ t('Refreshers-FAQ-ContentAreas-Q') }</p>
                                            <span className="parsed-text">{ tp('Refreshers-FAQ-ContentAreas-A') }</span>

                                            <Button label={ t('BTN-DownloadSelfAssessment') } isSolid={false} elementId="DownloadSelfAssessment" href="https://cdn.crisisprevention.com/media/training-center/classroom-resources/_NCI2e-Learner-Self-Assessment.pdf" />
                                            <p className="pb-2"></p>

                                        </li>
                                        <li>
                                            <p className="h4">{ tp('Refreshers-FAQ-Workbook-Q') }</p>
                                            <span className="parsed-text">{ tp('Refreshers-FAQ-Workbook-A') }</span>

                                        </li>
                                        <li>
                                            <p className="h4">{ tp('Refreshers-FAQ-Impact-Q') }</p>
                                            <span className="parsed-text">{ tp('Refreshers-FAQ-Impact-A') }</span>

                                        </li>
                                        <li>
                                            <p className="h4">{ t('Refreshers-FAQ-Supply-Q') }</p>
                                            <span className="parsed-text">{ tp('Refreshers-FAQ-Supply-A') }</span>

                                        </li>
                                    </ul>
                                </>
                            }
                        />
                        
                        </>      
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default RefresherTraining;
