import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const TrainingEnvironmentSetup = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <h1 className="page-title-h1 title-margin">{t('TrainingEnvironmentSetup-Title')}</h1>

                    <Tile title={t('TrainingEnvironmentSetup-Subheading1')} hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <span className="parsed-text">{tp('TrainingEnvironmentSetup-Section1List')}</span>
                                    </>
                                }
                            />

                        </>
                    </Tile>

                    <Tile title={t('TrainingEnvironmentSetup-Subheading2')} hideTileHeader={false}>
                        <>

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>{t('TrainingEnvironmentSetup-Section2Content')}</p>
                                        <h3>{t('TrainingEnvironmentSetup-Section2AdditionalHeading')}</h3>

                                    </>
                                }
                            />

                            <EightByFour
                            left={
                                <>
                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                    <img alt="Room Configurations" className="mx-auto w-100" src="https://cdn.crisisprevention.com/media/training-center/images/training-environment-setup/RoomConfig-front-half.png" title="Room Configurations"></img>

                                    <p>{t('TrainingEnvironmentSetup-Section2-MainImageDescription')}</p>

                                </>
                            }

                            right={
                                <>
                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                    <img alt="Room Configurations Option 1" className="mx-auto w-100" src="https://cdn.crisisprevention.com/media/training-center/images/training-environment-setup/RoomConfig-square-rooms.png" title="Room Configurations Option 1"></img>
                                    <p>{t('TrainingEnvironmentSetup-Section2Option1')}</p>

                                    <img alt="Room Configurations Option 2" className="mx-auto w-100" src="https://cdn.crisisprevention.com/media/training-center/images/training-environment-setup/RoomConfig-long-rooms.png" title="Room Configurations Option 2"></img>
                                    <p>{t('TrainingEnvironmentSetup-Section2Option2')}</p>

                                    <img alt="Room Configurations OPtion 3" className="mx-auto w-100" src="https://cdn.crisisprevention.com/media/training-center/images/training-environment-setup/RoomConfig-pods.png" title="Room Configurations Option 3"></img>
                                    <p>{t('TrainingEnvironmentSetup-Section2Option3')}</p>

                                </>
                            }
                        />

                        </>
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default TrainingEnvironmentSetup;
