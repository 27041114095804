import * as React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../Components/UI/button";
import DestructiveButton from "../Components/UI/cancel-button";
import Alert from "../Components/UI/alert";
import TileTabbed from "../Components/UI/tile-tabbed";
import Tile from "../Components/UI/tile";
import ClassParticipants from "./Components/Classes/class-participants";
import ClassDetails from "./Components/Classes/class-details";
import ModalPopUp from "../Components/UI/modal-pop-up";
import ClassNotes from "./Components/Classes/notes";
import { fetchClassDetails, fetchClassParticipants, printCertificates, printTrainingRecord, printVerificationLetters } from "./Utils/class";
import type AlertProperties from "../Interfaces/Platform/AlertProperties";
import type { ClassParticipant } from "../Interfaces/Platform/Participants";
import type { Pagination } from "../Interfaces/Platform/CustomTable";
import { returnPageLevelAlerts } from "./Utils/alerts";
import { ConfigContext } from "../configuration-context";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import useFeatureFlags from "../hooks/useFeatureFlags";
import { formatShortDate } from "../locale-utils";
import { useAppSelector } from "../hooks/useReduxDefaultHooks";
import { loadClassFromDetailsPayload, loadEnrolledParticipants, updateTotalClassParticipantsFromQuery } from "../stores/classDetailsSlice";
import { loadClassParticipantsPayload } from "../stores/classParticipantsSlice";
import { CustomResponse } from "./Utils/bulk-upload-response";
import jsonToCsvExport from "json-to-csv-export";
import useLocalStorage from "../hooks/useLocalstorage";
import { getUserClaims } from "../helper-functions";
import { downloadZipOfPdfsFromBase64StringArrays, openPdfFromBase64InNewTab, openPdfsFromBase64InNewTab } from "./Utils/pdf";
import { toContainHTML } from "@testing-library/jest-dom/matchers";

import { ExpectedRosterCount } from "../Interfaces/Platform/ExpectedRosterCount";

const ClassPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [canEditClass] = useFeatureFlags("ITLEditClass", true);
    const [canEditClassParticipants] = useFeatureFlags("ITLEditClassParticipants", true);
    const [canCreateParticipants] = useFeatureFlags("ITLCreateParticipants", true);
    const [canEditParticipants] = useFeatureFlags("ITLEditParticipants", true);
    const [canSendEnrollmentEmail] = useFeatureFlags("ITLSendEnrollmentEmails", true);
    const [canEnrollParticipants] = useFeatureFlags("ITLEnrollExistingParticipants", true);
    const [canTransferParticipants] = useFeatureFlags("ITLTransferParticipants", true);
    const [canBulkUploadClassParticipants] = useFeatureFlags("ITLBulkUploadClassParticipants", true);
    const [canViewCertificates] = useFeatureFlags("ITLCertificates", true);
    const [canPrintVerificationLetters] = useFeatureFlags("ITLVerificationLetters", true);
    const [canPrintTrainingRecord] = useFeatureFlags("ITLPrintTrainingRecord", true);
    const [viewClassActiveTab, setViewClassActiveTab] = useLocalStorage('activeViewClassTab', { classId: null, activeTab: 1 });
    const params = useParams();
    const culture = getUserClaims()?.culture ?? "en-US";

    const getActiveTab = () => {
        if (viewClassActiveTab && (params?.className == viewClassActiveTab.classId)) {
            return viewClassActiveTab.activeTab;
        }

        return 1;
    }

    const classDetails = useAppSelector((state) => state.classDetails);
    const classParticipants = useAppSelector((state) => state.classParticipants.participants);
    const pagination = useAppSelector((state) => state.classParticipants.pagination);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [participantsEnrolled, setParticipantsEnrolled] = React.useState(0);
    const [showAlert, setShowAlert] = React.useState<boolean>(true);
    const [activeTab, setActiveTab] = React.useState<number>(getActiveTab());
    const [showClassDetailsModal, setShowClassDetailsModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isParticipantsLoading, setIsParticipantsLoading] = React.useState<boolean>(false);
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();
    const [showClassNotes, setShowClassNotes] = React.useState(false);
    const [submitted, setSubmitted] = React.useState<string>("");
    const [submittedErrors, setSubmittedErrors] = React.useState<any>([]);
    const [displaySubmitErrorAlert, setDisplaySubmitErrorAlert] = React.useState<boolean>(false);
    const [participantsInClass, setParticipantsInClass] = React.useState(0);
    const [participantsSubmittedInClass, setParticipantsSubmittedInClass] = React.useState(0);
    const [classWebId, setClassWebId] = React.useState<string>(null);
    const [initialHasLoaded, setInitialHasLoaded] = React.useState<boolean>(false);
    const [submitErrorAlert, setSubmitErrorAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [submitSuccessAlert, setSubmitSuccessAlert] = React.useState<{ show: boolean } & AlertProperties>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [participantsToSubmit, setParticipantsToSubmit] = React.useState<string[]>(null);
    const [bulkResult, setBulkResult] = React.useState<CustomResponse>();
    const [bulkKey, setBulkKey] = React.useState(1);
    const [participantsAlreadySubmitted, setParticipantsAlreadySubmitted] = React.useState<{ [page: number]: number }>(null);
    const [selectedParticipantsIds, setSelectedParticipantsIds] = React.useState<string[]>([]);
    
    const removeSubmitErrorAlert = () => setSubmitErrorAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    React.useEffect(() => {
        if (Object.values(submittedErrors).some(value => value === true)) {
            setDisplaySubmitErrorAlert(true);
        } else {
            setDisplaySubmitErrorAlert(false);
        }
    }, [submittedErrors]);

    const removeSubmitSuccessAlert = () => setSubmitSuccessAlert({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });

    const showSubmitErrorAlert = (alert: { show: boolean } & AlertProperties) => setSubmitErrorAlert(alert);
    const showSubmitSuccessAlert = (alert: { show: boolean } & AlertProperties) => setSubmitSuccessAlert(alert);

    const getClassDetails = async (refreshData: boolean = false) => {
        setIsLoading(true);
        try {

            // Check redux store, if we have cached data, use that - otherwise call to internet
            if (refreshData || (classDetails.classId != params?.className)) {
                const data = await fetchClassDetails(apimBaseUrl, configContext, params?.className);
                dispatch(loadClassFromDetailsPayload(data));
                getClassParticipants(data.classId);
            } else {
                getClassParticipants(classDetails.classId);
            }

            if (params?.className == JSON.parse(localStorage.getItem('activeViewClassTab')).classId) {
                setActiveTab(JSON.parse(localStorage.getItem('activeViewClassTab')).activeTab);
            }

        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    };

    const getClassParticipants = async (classId: string, refresh: boolean = false) => {
        try {
            setIsParticipantsLoading(true);

            // check for expectedRosterCount item in session storage, if not - set to undefined
            const expectedRosterCount:ExpectedRosterCount = JSON.parse(sessionStorage.getItem('expectedRosterCount')) || undefined;

            // query class participants if
            // the classId differs from the current classId in the store
            // or if we have a refresh flag set
            // or if we have an expectedRosterCount item in session storage            
            if ((classDetails.classId != params?.className) || refresh || expectedRosterCount) {

                let attempts:number = 0;
                let total:number = 0;
                let participants:ClassParticipant[] = [];

                // Remove session storage item as soon as we've read it into the component
                sessionStorage.removeItem('expectedRosterCount');

                // we're going to query the class participants as usual
                // then we're only going to begin looping the query if we have an expected roster count from a class-conversion
                do {
                    const response = await fetchClassParticipants(apimBaseUrl, configContext, {
                        classId,
                        pageNumber: pagination.current,
                        resultsPerPage: pagination.size
                    });

                    ({ total, participants } = response);

                    if (expectedRosterCount && (total < expectedRosterCount.count)) {
                        if (attempts >= 6) {
                            break;
                        }

                        await new Promise(resolve => setTimeout(resolve, 5000));
                        attempts++;
                    }

                // keep trying while don't yet have an expected roster count, and the expectedRosterCount.classId matches this one
                } while (expectedRosterCount && (expectedRosterCount.count && expectedRosterCount.classId) && (total < expectedRosterCount.count) && (expectedRosterCount.classId == classId));

                
                dispatch(loadClassParticipantsPayload({
                    total,
                    classParticipants: participants,
                    participants: participants.map((i) => ({
                        ...i,
                        isChecked: i.ceSubmitted ? false : selectedParticipantsIds.includes(i.classParticipantId),
                        hasBlueCardIdDuplicated: false
                    }))
                }));
                dispatch(updateTotalClassParticipantsFromQuery({total}));
                setParticipantsAlreadySubmitted({ ...participantsAlreadySubmitted, [pagination.current]: participants.filter((i) => i.ceSubmitted ).length });
                dispatch(loadEnrolledParticipants(total));
                setParticipantsInClass(total);
            }
        } catch (e) {

        } finally {
            setIsParticipantsLoading(false);
            setInitialHasLoaded(true);
        }
    };

    React.useEffect(() => {
        if (initialHasLoaded) getClassParticipants(classDetails.classId, true);
    }, [pagination.current, pagination.size]);

    const handlePrintVerificationLetters = async () => {
        const request = {
            classId: classDetails.classId,
            culture
        };

        const response = await printVerificationLetters(configContext, request);
        
        if (response && response.certificates) {
            await openPdfsFromBase64InNewTab(response.certificates)
        }
    };

    const handlePrintTrainingRecord = async () => {
        const request = {
            classId: classDetails.classId,
            culture
        };

        const response = await printTrainingRecord(configContext, request);
        
        if (response && response.trainingRecord) {
            openPdfFromBase64InNewTab(response.trainingRecord);
        }
    };

    const handlePrintCertificates = async () => {
        const request = {
            classId: classDetails.classId,
            culture
        };

        const response = await printCertificates(configContext, request);

        if (response && response.certificates) {
            await downloadZipOfPdfsFromBase64StringArrays(response.certificates, response.fileName);
        }
    };

    React.useEffect(() => {
        if (classDetails && classDetails.desiredLms === "internal" && classDetails.trainingFormat !== "classroom") {
            if ((classDetails.seatOrganizationId === null || classDetails.courseId === null) && activeTab === 1) {
                setShowClassDetailsModal(true);
            } else {
                setShowClassDetailsModal(false);
            }
        }
    }, [classDetails.trainingFormat, classDetails.seatOrganizationId, classDetails.courseId, activeTab]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (configContext?.SystemConfiguration?.ApimKey) getClassDetails();
    }, [configContext?.SystemConfiguration?.ApimKey, params?.className]);

    React.useEffect(() => {
        // Save the activeTab value to localStorage whenever it changes
        setViewClassActiveTab({ classId: params?.className, activeTab: activeTab });
    }, [activeTab]);

    const handleParticipantsEnrolled = (participantsToBeEnrolled: number) => {
        setParticipantsEnrolled((prevState) => prevState + participantsToBeEnrolled);
        setShowAlert(true);
    };

    const classIsEnded = new Date(classDetails?.endDate) < new Date();
    const classIsExpired = new Date(classDetails?.expirationDate) < new Date();
    const endDate = new Date(classDetails?.endDate).toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric"
    });

    const classDates = classDetails?.classDates.map(classDate => formatShortDate(classDate.date));

    const handleShowParticipantsSubmitted = (submitted: number, total: number) => {
        setSubmitted(submitted > 0 && total > 0 ? t("ParticipantsRecorded", { submitted, total }) : '');
    };

    const handleUpdate = () => {
        setActiveTab(1);
    };

    const handleDownloadErrorReport = () => {
        const dataToExport = {
            data: bulkResult?.response,
            filename: 'participant-exceptions',
            delimiter: ','
        }

        jsonToCsvExport(dataToExport);
    };

    React.useEffect(() => setBulkKey(bulkKey + 1), [bulkResult]);
    React.useEffect(() => setClassWebId(classDetails?.webId), [classDetails.webId]);

    const participantsEnrolledText = (participantsEnrolled: number) => {
        if ((classDetails.trainingFormat === 'classroom') || !classDetails.seatsRequired) {
            return `${participantsEnrolled > 1 ? t("ParticipantsAdded", { participantsEnrolled }) : t("ParticipantAdded")}`;
        } else {
            return `${participantsEnrolled > 1 ? t("ParticipantsAdded", { participantsEnrolled }) : t("ParticipantAdded")} ${t("EnrollmentEmailsWillBeSent")}`;
        }
    };

    return (
        <>
            <ModalPopUp
                title={t("Class Notes")}
                size="md"
                center
                content={<ClassNotes classId={classDetails?.classId} onClose={() => setShowClassNotes(false)} />}
                show={showClassNotes}
                showFooter={false}
                hide={() => setShowClassNotes(false)}
            />
            <ModalPopUp
                title={t("Additional Class Details")}
                center={false}
                content={
                    <div className="d-flex flex-column">
                        <span className="mb-5">{t("Class.NeedAdditionalInfo")}</span>
                        <div className="d-flex flex-row justify-content-between mt-5">
                            <DestructiveButton label={<><i className="me-1 far fa-times" /> {t("Cancel")}</>} clickAction={() => setShowClassDetailsModal(false)} />
                            <Button elementId="view-class-details-button" isSolid label={t("View Class Details")} clickAction={() => {
                                setShowClassDetailsModal(false);
                                setActiveTab(0);
                            }} />
                        </div>
                    </div>
                }
                show={showClassDetailsModal}
                showFooter={false}
                cancelButtonTitle="Cancel"
                actionButton={{ clickAction: () => {}, elementId: 'view-class-details', label: t('View Class Details') }}
                hide={() => setShowClassDetailsModal(false)}
            />
            <div className="page-content m-auto">
                <Container fluid>
                    {(isLoading) ? <div>Loading...</div> : (
                        <>
                            <div className="mt-4 mb-4 class-row">
                                <h1 className="page-title-h1">
                                    {classDetails?.className}
                                </h1>
                                <div className="classes-title-actions">
                                    {(classDetails?.classDates.length > 0) &&
                                    <span>
                                        {
                                            classDates.join(', ')
                                        }
                                    </span>
                                    }
                                <Button
                                    elementId="class-notes-button"
                                    label={
                                        <div className="d-flex">
                                            <i className="fa-light fa-sharp fa-note-sticky mr-2 class-notes"/>
                                            {t("TrainingRecord.ClassNotes")}
                                        </div>
                                    }
                                    isSolid={false}
                                    clickAction={() => setShowClassNotes(true)}
                                />
                                </div>
                            </div>
                            <div className="alert-wrapper mt-4">
                                {participantsEnrolled > 0 && showAlert && (
                                    <Alert
                                        state={{ alertLevel: "success" }}
                                        description={participantsEnrolledText(participantsEnrolled)}
                                        resetState={() => setShowAlert(false)}
                                        canDismiss
                                    />
                                )}
                                {submitted.length > 0 && (
                                    <Alert
                                        state={{ alertLevel: "success" }}
                                        description={submitted}
                                        canDismiss
                                    />
                                )}
                                {submitSuccessAlert.show && (
                                    <Alert
                                        state={submitSuccessAlert.alertLevel}
                                        description={submitSuccessAlert.description}
                                        arrowLink={submitSuccessAlert.arrowLink}
                                        canDismiss={submitSuccessAlert.canDismiss}
                                        removeAlertParentHandler={removeSubmitSuccessAlert}
                                    />
                                )}
                                
                                {displaySubmitErrorAlert && (
                                    <Alert
                                        state={{alertLevel: "error"}}
                                        description={t("Submit-Participant-Error")}
                                        arrowLink={null}
                                        canDismiss={true}
                                        removeAlertParentHandler={removeSubmitErrorAlert}
                                    />
                                )}
                                {
                                    bulkResult && (
                                        <Alert
                                            description={bulkResult.message}
                                            state={{ alertLevel: bulkResult.isError ? "warning" : "success" }}
                                            key={`bulk${bulkKey}`}
                                            canDismiss
                                            arrowLinkHandled={
                                                bulkResult.isError
                                                    ? { 
                                                        label: t("Download Issues"),
                                                        isButton: true,
                                                        onClick: handleDownloadErrorReport,
                                                        iconClass: "fas fa-download"
                                                    } 
                                                    : undefined
                                            }
                                        />
                                    )
                                }
                                {returnPageLevelAlerts(pageLevelAlerts)}
                            </div>
                            
                            { classDetails.participantsSubmitted > 0 && classDetails.specialty !== "PF" && classDetails.specialty !== "DEB" &&
                                <>
                                    <Tile title={t('Training Documentation')} sideLabel={(classWebId) ? classWebId : ""}>
                                        <>
                                            <p className="mt-3">{t('TrainingDocumentationTile.Content', { recordedParticipants: classDetails.participantsSubmitted, totalParticipants: classDetails.totalParticipants })}</p>

                                            <div className="d-flex">
                                                { canPrintVerificationLetters &&
                                                    <div className="mt-1 mr-4 color-blue">
                                                        <span className="bold">
                                                            <a href="#" className="color-blue" onClick={handlePrintVerificationLetters}>
                                                                <i className="fal fa-print"></i> { t("Print Verification Letters") }
                                                            </a>
                                                        </span>
                                                    </div>
                                                }
                                                

                                                { canPrintTrainingRecord &&
                                                    <div className="mt-1 mr-4 color-blue">
                                                        <span className="bold">
                                                            <a href="#" className="color-blue" onClick={handlePrintTrainingRecord}>
                                                                <i className="fal fa-print"></i> { t("Print Training Record") }
                                                            </a>
                                                        </span>
                                                    </div>
                                                }
                                                

                                                { canViewCertificates &&
                                                    <div className="mt-1 mr-4 color-blue">
                                                        <span className="bold">
                                                            <a href="#" className="color-blue" onClick={handlePrintCertificates}>
                                                                <i className="fal fa-file-certificate"></i> { t("View Certificates") }
                                                            </a>
                                                        </span>
                                                    </div> 
                                                }
                                                                                   
                                            </div>
                                        </>
                                    </Tile>
                                </>
                            }
                            

                            <div className="class-container">
                                <TileTabbed
                                    onSelectTab={setActiveTab}
                                    activeTab={activeTab}
                                    tabs={[
                                        {
                                            title: t("Class Details"),
                                            content: (
                                                <ClassDetails
                                                    classDetails={classDetails}
                                                    canEditClass={canEditClass}
                                                    participantsEnrolled={participantsInClass}
                                                    onCancel={() => setActiveTab(1)}
                                                    onSuccess={handleUpdate}                                             />
                                            )
                                        },
                                        {
                                            title: t("ClassParticipants.Participants"),
                                            content: (
                                                <ClassParticipants
                                                    isLoading={isParticipantsLoading}
                                                    canEditClassParticipants={canEditClassParticipants}
                                                    canEditParticipants={canEditParticipants}
                                                    canSendEnrollmentEmail={canSendEnrollmentEmail}
                                                    canCreateParticipants={canCreateParticipants}
                                                    canEnrollParticipants={canEnrollParticipants}
                                                    canTransferParticipants={canTransferParticipants}
                                                    canViewCertificates={canViewCertificates}
                                                    showParticipantsSubmitted={handleShowParticipantsSubmitted}
                                                    alertSubmitError={showSubmitErrorAlert}
                                                    alertSubmitSuccess={showSubmitSuccessAlert}
                                                    showAlertSubmitSuccess={submitSuccessAlert.show}
                                                    showAlertSubmitError={submitErrorAlert.show}
                                                    setParticipantsToSubmit={setParticipantsToSubmit}
                                                    participantsToSubmit={participantsToSubmit}
                                                    setParticipantsEnrolled={handleParticipantsEnrolled}
                                                    setParticipantsInClass={setParticipantsInClass}
                                                    setShowAlerts={setShowAlert}
                                                    setParticipantsSubmittedInClass={setParticipantsSubmittedInClass}
                                                    getClassParticipants={getClassParticipants}
                                                    setClassWebId={setClassWebId}
                                                    classDetails={classDetails}
                                                    setSubmittedErrors={setSubmittedErrors}
                                                    addPageLevelAlert={addPageLevelAlert}
                                                    setBulkResult={setBulkResult}
                                                    canBulkUploadClassParticipants={canBulkUploadClassParticipants}                                              />
                                            )
                                        }
                                    ]}
                                />
                            </div>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default ClassPage;
