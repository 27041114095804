import * as React from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import Badge from "../../../Components/UI/badge";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import type { IClass } from "../../../Interfaces/Platform/Classes";
import ActionMenu from "../../../Components/UI/action-menu-component/action-menu";
import ActionMenuItem from "../../../Components/UI/action-menu-component/action-menu-item";

import { PlatformBaseRoutes } from "../../../Routing/routes";
import { ConfigContext } from "../../../configuration-context";
import { getUserClaims } from "../../../helper-functions";
import { printTrainingRecord, printVerificationLetters } from "../../Utils/class";
import { openPdfFromBase64InNewTab, openPdfsFromBase64InNewTab } from "../../Utils/pdf";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const ClassListing = ({
    classId,
    className,
    programName,
    date,
    instructor,
    specialty,
    classDates,
    classInstructors,
    organizationName,
    participantsRecorded,
    participantsOnlineCourseComplete,
    participantsEnrollment,
    canViewClassDetails
}: IClass): JSX.Element => {
    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const configContext = React.useContext(ConfigContext);
    const culture = getUserClaims()?.culture ?? "en-US";

    const displayDates = (fullDate = true) => {
        return classDates.map((classDate) => {
            return new Date(classDate.date)
                .toLocaleDateString(locale, fullDate ? {
                    month: 'long', day: '2-digit', year: 'numeric'
                } : {
                    month: 'long', year: 'numeric'
                });
        }).join(', ');
    };

    const displayInstructors = () => {
        return classInstructors.map((classInstructor) => {
            return `${classInstructor.firstName} ${classInstructor.lastName}`;
        }).join(', ');
    };

    const handlePrintTrainingRecord = async () => {
        const request = {
            classId,
            culture
        };

        const response = await printTrainingRecord(configContext, request);
        
        if (response && response.trainingRecord) {
            openPdfFromBase64InNewTab(response.trainingRecord);
        }
    };

    const handlePrintVerificationLetters = async () => {
        const request = {
            classId,
            culture
        };

        const response = await printVerificationLetters(configContext, request);
        
        if (response && response.certificates) {
            await openPdfsFromBase64InNewTab(response.certificates);
        }
    };

    const renderMenuItems = () => {
        const menuItems = [
            <ActionMenuItem data={{ disabled: !canViewClassDetails, label: t("View/Edit Class"), clickAction: () => navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${classId}`) }} />,
        ];
        if (participantsRecorded > 0) {
            menuItems.push(...[
                <ActionMenuItem data={{ label: t("Print Verification Letters"), clickAction: () => handlePrintVerificationLetters() }} />,
                <ActionMenuItem data={{ label: t("Print Class Training Record"), clickAction: () => handlePrintTrainingRecord() }} />,
            ]);
        }
        return [
            ...menuItems,
            //<ActionMenuItem data={{ label: t("Delete Class"), clickAction: () => {} }} />,
        ];
    };

    return (
        <>
            <div className="class-listing-container">
                {participantsEnrollment > 0 && participantsRecorded === participantsEnrollment && (
                    <img
                        className="class-listing-check"
                        alt="check-icon"
                        src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/_icons/Complete.svg`}
                    />
                )}
                <div className="class-listing-inner-wrapper">
                    <div className="class-listing-main-column">
                        <div className={`class-listing-title-container ${!canViewClassDetails ? 'no-hover' : ''}`}>
                            <i className="fa-sharp fa-solid fa-calendar class-listing-icon"></i>
                            <h3 className={`sub-headings-h3 class-listing-title ${!canViewClassDetails ? 'no-hover' : ''}`} onClick={() => {
                                if (canViewClassDetails) navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${classId}`)
                                return;
                            }}>
                                {className}
                            </h3>
                        </div>
                        <span className="bold class-listing-program">{tp(specialty)}</span>
                        <span>{t("Class Date")}: {classDates && classDates.length > 0 && displayDates()}</span>
                        <span>{t("Instructor")}: {classInstructors && classInstructors.length > 0 && displayInstructors()}</span>
                        <span>{organizationName}</span>
                    </div>
                    <div className="class-listing-row">
                        <div className="class-listing-secondary-column">
                            {participantsEnrollment != undefined && participantsRecorded  != undefined && (
                                <Badge style={{ style: "senior" }}>
                                    <span className="class-listing-badge">
                                        {`${participantsRecorded}/${participantsEnrollment} recorded`}
                                    </span>
                                </Badge>
                            )}
                            {participantsOnlineCourseComplete && (
                                <span className="bold class-listing-program">                                   
                                    {t("Online-Course-Complete", { participantsOnlineCourseComplete: participantsOnlineCourseComplete, participantsEnrollment: participantsEnrollment })}
                                </span>
                            )}
                        </div>
                        <ActionMenu
                            isOpen={false}
                            menuItemsArr={renderMenuItems()}
                        />
                    </div>
                </div>
            </div>
        </>
    );

};

export default ClassListing;
