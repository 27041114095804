import * as React from "react";

import { addDays, parseMSDate } from "../../date-utils";
import { Col, Container, Row } from "react-bootstrap";
import { COURSE_EXPIRATION_ALERT_PERIOD } from "../../constants";
import { formatLongDate } from "../../locale-utils";
import { LearnerCourseDetails, PreviewDetails } from "../../Interfaces";
import { Link } from "react-router-dom";
import { StatusEnum } from "../../Enums";
import { useTranslation } from "react-i18next";

import { ConfigContext } from "../../configuration-context";

import ProgressBar from "../../Components/progress-bar";

import "./course-card.scss";

import { reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

export interface CourseCardProps {
    url: string;
    thumbnailUrl: string;
    course: LearnerCourseDetails | PreviewDetails;
    descriptionExpanded: boolean;
    toggleDescriptionExpand: () => void;
}

const CourseCard = ({ url, thumbnailUrl, course, descriptionExpanded, toggleDescriptionExpand }: CourseCardProps) => {
    const { t } = useTranslation();
    const learnerCourse = course as LearnerCourseDetails;
    const configContext = React.useContext(ConfigContext);

    let status: JSX.Element = null;
    if (learnerCourse.CompletionDate) {
        status = (
            <h6>
                {t("Completed on")}: {formatLongDate(learnerCourse.CompletionDate)}
            </h6>
        );
    } else if (learnerCourse.ClassDate) {
        status = (
            <h6>
                {t("Complete by")}: {formatLongDate(learnerCourse.ClassDate)}
            </h6>
        );
    }
    return (
        <div className="course-card">
            <Container fluid className="course-card__image-section">
                <Row>
                    <Col sm="auto">
                        <Container>
                            <Row className="d-flex justify-content-center mb-2">
                                <Link to={url}>
                                    <img
                                        className="course-card__image"
                                        src={thumbnailUrl ?? `${configContext?.SystemConfiguration?.CDNImagesUrl}/platform/components/preview-courses/CPI Logo.png`}
                                    />
                                </Link>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Link className="button-brand-primary mt-auto" role="button" to={url}>
                                    {!learnerCourse.LearnerCourseId ? t("Preview Course") : t("View Course")}
                                </Link>
                            </Row>
                        </Container>
                    </Col>

                    <Col>
                        <div className="me-4 course-card__details">
                            <h4>
                                <Link className="course-card__title" to={url}>
                                    {course.Name}
                                </Link>
                            </h4>
                            {course.IsMultiSco && (
                                <h6>
                                    {t("Estimated Time: {{hours}} hours {{minutes}} minutes", {
                                        hours: course.EstimatedTimeHours,
                                        minutes: course.EstimatedTimeMinutes
                                    })}
                                </h6>
                            )}
                            {status}
                            {learnerCourse.ExpirationDate &&
                                parseMSDate(learnerCourse.ExpirationDate) <=
                                addDays(new Date(), COURSE_EXPIRATION_ALERT_PERIOD) && (
                                    <h6 className="text-danger">
                                        <i className="far fa-exclamation-circle"></i> {t("Course expiring on")}:{" "}
                                        {formatLongDate(learnerCourse.ExpirationDate)}
                                    </h6>
                                )}
                            <h6>
                                {learnerCourse.InstructorEmailAddress && !learnerCourse.OnlineOnly && (
                                    <>
                                        <span className="me-4">
                                            {t("Instructor")}:{" "}
                                            {`${learnerCourse.InstructorFirstName} ${learnerCourse.InstructorLastName}`}
                                        </span>
                                        <a
                                            href={`mailto:${learnerCourse.InstructorEmailAddress}`}
                                            className="text-link me-4"
                                        >
                                            <i className="fas fa-envelope me-1"></i>
                                            {t("Send Email")}
                                        </a>
                                    </>
                                )}
                                {learnerCourse.Status === StatusEnum.Completed && (
                                    <a
                                        href={`/Learn/ViewCertificate/${learnerCourse.LearnerCourseId}`}
                                        className="text-link"
                                        title={t("View Certificate")}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={t("View")}
                                    >
                                        <i className="fas fa-certificate me-1"></i>
                                        {t("View Certificate")}
                                    </a>
                                )}
                            </h6>
                            {learnerCourse.Progress && (
                                <ProgressBar
                                    currentLesson={learnerCourse.Progress.CurrentLesson}
                                    totalLessons={learnerCourse.Progress.TotalLessons}
                                />
                            )}
                        </div>
                        <br />
                        <div>
                            {learnerCourse.ClassLocation && (
                                <>
                                    <h5 className="course-card__subheading">{t("Classroom Location")}</h5>
                                    <p>{learnerCourse.ClassLocation}</p>
                                </>
                            )}
                            {learnerCourse.Description && (
                                <>
                                    <h5 className="course-card__subheading">{t("Description")}</h5>
                                    <p>
                                        {course.Description?.length > 240 && !descriptionExpanded
                                            ? course.Description.substr(0, 240) + "..."
                                            : course.Description}{" "}
                                        {course.Description?.length > 240 && (
                                            <span
                                                className="ms-2"
                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                onClick={() => toggleDescriptionExpand()}
                                            >
                                                {!descriptionExpanded ? (
                                                    <i className="fas fa-caret-down"></i>
                                                ) : (
                                                        <i className="fas fa-caret-up"></i>
                                                    )}{" "}
                                                {!descriptionExpanded ? t("Show more") : t("Show less")}
                                            </span>
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr />
        </div>
    );
};

export default withAITracking(reactPlugin, CourseCard);
