import { useState } from "react";
import type { Pagination } from "../Interfaces/Platform/CustomTable";

interface Props<FilterType> {
    defaultFilters?: FilterType;
    pagination?: Pagination;
}
const useCustomTable = <FilterType>(props: Props<FilterType>) => {
    const [filters, setFilters] = useState<FilterType>(props.defaultFilters);
    const [pagination, setPagination] = useState<Pagination>(props.pagination);

    const updateFilter = (filter: string, value: string) => setFilters({ ...filters, [filter]: value });
    const resetFilters = () => setFilters(props.defaultFilters);
    const updateTotal = (total: number) => setPagination({ ...pagination, total });
    const updatePage = (current: number) => setPagination({ ...pagination, current });
    const updatePageSize = (size: number) => setPagination({ ...pagination, current: 1, size });
    return {
        filters,
        setFilters,
        resetFilters,
        updateFilter,
        pagination,
        updateTotal,
        updatePage,
        updatePageSize,
        setPagination,
    } as const;
};

export default useCustomTable;
