import {} from "../../ApiServices/AppSettings";

import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CertificateOptionModel, CourseLanguageModel } from "../../Interfaces";
import { RbacContext } from "../../rbac-context";

import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { getToken } from "../../helper-functions";
import { ConfigContext } from "../../configuration-context";

interface CreateCourseLanguageFormProps {
    isCreateForm?: boolean;
    existingSetting?: CourseLanguageModel;

    certOptions: CertificateOptionModel[];
    isVisible: boolean;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
    onHide: () => void;
}

const CourseLanguageForm = ({
    existingSetting,
    isCreateForm,
    certOptions,
    isVisible,
    onSave,
    onHide
}: CreateCourseLanguageFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [courseLanguage, setCourseLanguage] = React.useState<CourseLanguageModel>(
        isCreateForm
            ? {
                  CourseLanguageId: undefined,
                  Language: "",
                  Code: "",
                  CrmOptionSetValue: 0,
                  CertificateImagePath: null,
                  Active: true,
                  CreateDate: "",
                  CreateUser: 0,
                  ModifyDate: "",
                  ModifyUser: 0
              }
            : existingSetting
    );

    const createCourseLanguage = async (payload: any): Promise<any|undefined> => {
        try {
            const response = await fetch(apimBaseUrl + "/C/Curriculum/CreateLanguage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`,

                },
                body: JSON.stringify(payload),
            });

            if (response.status === 404 || response.status === 204) return undefined;
            return await response.json();
            
        } catch (e) {
            console.error(e);
            return { Message : e.Message };
        }
    }

    const updateCourseLanguage = async (payload: any) => {
        try {
            const response = await fetch(apimBaseUrl + "/C/Curriculum/UpdateLanguage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify(payload),
            });

            if (response.status === 404 || response.status === 204) return undefined;
            return await response.json();

        } catch (e) {
            console.error(e);
            return { Message : e.Message };
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            try {
                setSaving(true);

                const languagePayload = {
                    courseLanguageId: courseLanguage.courseLanguageId,
                    name: courseLanguage.language,
                    code: courseLanguage.code,
                    certificateImagePath: courseLanguage.certificateImagePath,
                    crmOptionSet: courseLanguage.crmOptionSet
                }
                const response = isCreateForm
                    ? await createCourseLanguage(languagePayload)
                    : await updateCourseLanguage(languagePayload);
                setSaving(false);
                onSave(response);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
            
        }
        setValidated(true);
    };

    return (
        <Modal show={isVisible} size="lg" onHide={onHide} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Create Language")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group as={Row} controlId="name">
                        <Form.Label column sm="2">
                            {t("Language Name")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                maxLength={255}
                                value={courseLanguage.language}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseLanguage({
                                        ...courseLanguage,
                                        language: e.target.value
                                    })
                                }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="code">
                        <Form.Label column sm="2">
                            {t("Language Code")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                maxLength={255}
                                value={courseLanguage.code}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseLanguage({ ...courseLanguage, code: e.target.value })
                                }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="optionSetValue">
                        <Form.Label column sm="2">
                            {t("CRM OptionSet Value")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                maxLength={255}
                                value={courseLanguage.crmOptionSet}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseLanguage({
                                        ...courseLanguage,
                                        crmOptionSet: isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value) 
                                    })
                                }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="certImage">
                        <Form.Label column sm="2">
                            {t("Cert Image")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                value={courseLanguage.certificateImagePath}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCourseLanguage({ ...courseLanguage, certificateImagePath: e.target.value })
                                }
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, CourseLanguageForm);
