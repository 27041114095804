import * as React from "react";
import FormCheckbox from "../../../Interfaces/Platform/FormCheckbox";

const Checkbox = (props: {
    checkbox: FormCheckbox,
    handleCheckboxOnChange
}): JSX.Element => {
    
    let children: JSX.Element[] = [];

    if (props.checkbox.childrenNodes.length > 0) {
        children = props.checkbox.childrenNodes.map((checkbox: FormCheckbox) => 
            <Checkbox
                checkbox={{
                    ...checkbox,
                    isDisplayOnly: props.checkbox.isDisplayOnly
                }}
                handleCheckboxOnChange={props.handleCheckboxOnChange}
            />
        );
    }

    const renderChildren = (): JSX.Element => {
        let jsxChildren: JSX.Element;
        if (children.length <= 4) {
            jsxChildren = <div className="mb-1 mt-1" style={{ marginLeft: 24 }}>{children}</div>
        } else {
            const limit = Math.ceil(children.length / 2) + 1;
            
            jsxChildren = (
                <div className="d-flex justify-content-between">
                    <div className="mb-1 mt-1" style={{ marginLeft: 24 }}>{children.slice(0, limit)}</div>
                    <div className="mb-1 mt-1" style={{ marginLeft: 24 }}>{children.slice(limit, children.length)}</div>
                </div>
            )
        }

        return jsxChildren;
    };

    return (
        <div className="d-flex flex-column">        
            <div className={`d-flex align-items-center ${!children ? 'mb-1' : ''}`}>
                {
                    props.checkbox.isDisplayOnly && (
                        <>
                            <i
                                className={`fa-sharp fs24 mr-2 ${props.checkbox.isChecked ? 'fa-check color-blue' : 'fa-xmark text-gray-500'}`}
                            />
                            &nbsp;
                            { props.checkbox.label }
                        </>
                    )
                }
                {
                    !props.checkbox.isDisplayOnly && (
                        <>
                            <input
                                className="form-checkbox"
                                id={props.checkbox.id}
                                value={props.checkbox.value}
                                name={props.checkbox.name}
                                type="checkbox"
                                checked={props.checkbox.isChecked}
                                onChange={() => props.handleCheckboxOnChange(props.checkbox.id, props.checkbox.parentId)}
                            />
                            
                            <label className="form-checkbox-label my-1" htmlFor={props.checkbox.name}>
                                <span className="text-capitalize">{props.checkbox.label}</span>
                            </label>
                        </>
                    )
                }
            </div>
            {
                children && children.length > 0 && renderChildren()
            }
        </div>
    );
};

export default Checkbox;
