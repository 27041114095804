import * as React from "react";

import { Trans, useTranslation } from "react-i18next";
import useParsedTranslation from "../hooks/useParsedTranslation";
import useClassMaterials from "../hooks/useClassMaterials";
import parseHtml from 'html-react-parser';

import OneColumnLayout from "../Components/UI/UX-Layout/Tiles/one-column-layout";
import EightByFour from "../Components/UI/UX-Layout/Content/eight-by-four";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import useCustomerSupportContactInfo from "../hooks/useCustomerSupportContactInfo";

import Accordion from "../Components/UI/accordion";
import VideoListing from "../Components/UI/Content-Pages/video-listing";

const ProgramMaterials = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const [ classMaterials, setClassMaterials ] = React.useState([]);
    const [ searchParameters, setSearchParameters ] = React.useState({ 'Program-Code': '' });

    const { rac } = useRouteAccessCheck();    
    const [ programMaterials ] = useClassMaterials(['APS'], ['APS-ASD'], searchParameters);

    const returnClassMaterials = (data:any[]) => {
        return (
            data.map((material: any) => (
                <>
                    <div className="mb-4">
                        <div>{ material['Content-Type']}</div>
                        <div>{ material['Program']}</div>
                        <div>{ material['Version']}</div>
                        <div>{ material['Language']}</div>
                        <div>{ material['File-Type']}</div>
                    </div>
                </>
            ))
        )
    }
    
    return (        
        <>
            Program Materials :: <br /><br />
            { programMaterials && returnClassMaterials(programMaterials) }
        </>
    );
};

export default ProgramMaterials;
