import * as React from "react";
import { useTranslation } from "react-i18next";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

interface Props {
    certification: any;
}

const ParticipantStatus = (props: Props): JSX.Element => {
    const { certification } = props;
    const { t } = useTranslation();
    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const getDateFromString = (date: string) => {
        if (date != null) {
            const [year, month, day] = date.split('T')[0].split('-');
            return new Date(`${month}-${day}-${year}`).setHours(0,0,0,0);
        } else {
            return undefined;
        }     
    };

    const displayDate = (date: string) => {
        return new Date(date).toLocaleString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
    };

    const currentDate = new Date().setHours(0,0,0,0);

    const startDate = (certification.startDate) ? getDateFromString(certification.startDate) : undefined;

    // due to older participants sometimes having endDate null when only one date is selected, we need to use startDate instead
    const endDate = (certification.endDate == null) ? startDate : getDateFromString(certification.endDate);

    const expirationDate = getDateFromString(certification.expirationDate);

    // Enrolled AND Not Submitted | In Progress
    if (!certification.ceSubmitted) {
        return <><i className="fa-regular fa-sharp fa-arrows-rotate participant-listing-status-icon-progress"/> {t("In Progress")}</>;
    } else {
        // Submitted AND Failed
        if (certification.status === 'fail') {
            // Failed
            return <><i className="fa-light fa-xmark participant-listing-status-icon-fail"/> {t("Failed")}</>;
        }
        // Submitted AND Passed | Active or Expired
        if (certification.status === 'pass') {
            if (currentDate > expirationDate) {
                // Expired
                return (
                    <>
                        <i className="fa-light fa-sharp fa-diamond-exclamation participant-listing-status-icon-expired"/> {t("Expired")}
                        <span className="fw400">(On {displayDate(certification.expirationDate)})</span>
                    </>
                );
            } else {
                return (
                    <>
                        <i className="fa-light fa-check participant-listing-status-icon"/> {t("Active")}
                        <span className="fw400">(Until {displayDate(certification.expirationDate)})</span>
                    </>
                );
            }

            
        }
    }
};

export default ParticipantStatus;
