import * as React from "react";
import { ArrowLinkObjectHandled } from "../../Interfaces/Platform/ArrowLink";

const ArrowLinkHandled = (props: {
    data: ArrowLinkObjectHandled
}): JSX.Element => {
    return (
        <>
            <div className="arrow-link fs16 fw600 color-blue-darker">
                <a
                    className={props.data.isButton ? "btn btn-outline-primary" : "arrow-link-text"}
                    href="javascript:;"
                    onClick={props.data.onClick}
                >
                    {props.data.iconClass && <><i className={props.data.iconClass} />&nbsp;</>}
                    {props.data.label}
                </a>
            </div>
        </>
    );

};

export default ArrowLinkHandled;
