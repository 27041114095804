import { ObjectMap } from "../Interfaces";
import { getToken, getUserClaims } from "../helper-functions";
import i18next from "i18next";

export interface ApiResponse {
    Success: boolean;
    Message?: string;
    MessageCode?: number;
    MessageParams?: any[];
    Id?: number;
}

export interface ErrorModel {
    Code: number;
    MessageCode: number;
    Message: string;
    MessageParams?: any[];
    Errors?: ErrorDetailsModel[];
}

export interface ErrorDetailsModel {
    Domain?: string;
    Reason?: string;
    Message?: string;
    MessageCode?: number;
    MessageParams?: any[];
}

export interface JsonResponseModel<T> {
    Id?: string;
    Data?: T;
    Error?: ErrorModel;
}

export interface PagedResult<T> {
    Results: T[];
    CurrentPage: number;
    PageCount: number;
    PageSize: number;
    RowCount: number;
}

export interface PagedRows<T> {
    rows: T[];
    pageCount: number;
}

export async function handleError<T>(resp: Response): Promise<JsonResponseModel<T>> {
    if (!resp.ok) {
        const respCopy = resp.clone();
        try {
            const json = (await resp.json()) as JsonResponseModel<T>;

            if (json.Error) {
                const params: ObjectMap = {};
                json.Error.MessageParams?.forEach((param, i) => (params[i.toString()] = param));
                /* i18next-extract-disable-next-line */
                json.Error.Message = i18next.t(`messageCodes::${json.Error.MessageCode}`, {
                    interpolation: { prefix: "{", suffix: "}" },
                    ...params
                });

                json.Error.Errors?.forEach((error) => {
                    const errorParams: ObjectMap = {};
                    error.MessageParams?.forEach((param, i) => (errorParams[i.toString()] = param));
                    /* i18next-extract-disable-next-line */
                    error.Message = i18next.t(`messageCodes::${error.MessageCode}`, {
                        interpolation: { prefix: "{", suffix: "}" },
                        ...errorParams
                    });
                });
            }

            return json;
        } catch {
            const text = await respCopy.text();
            if (text.includes("500 - The request timed out")) {
                const timeoutError: JsonResponseModel<T> = {
                    Error: {
                        Code: 500,
                        MessageCode: 0,
                        Message: i18next.t("The request timed out. Please remove learners and try again.")
                    }
                };
                return timeoutError;
            }

            return {
                Error: {
                    Code: 500,
                    MessageCode: 1001,
                    Message: i18next.t("An unexpected error occurred. Please try again.")
                }
            };
        }
    }

    if (resp.status === 204) {
        return null;
    }

    return resp.json();
}

export function GET(url: string, signal?: AbortSignal) {
    return fetch("/api" + url, {
        method: "GET",
        headers: {
            Accept: "application/json"
        },
        credentials: "same-origin",

        signal: signal
    });
}

export function GETFROMAPIM(url:string, subscriptionKey:string) {
    return fetch(url, {
        method: "GET",
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Authorization": `Bearer ${getToken("accessToken")}`
        }
    });
}

export function POSTFROMAPIM(url:string, subscriptionKey:string, payload: any) {
    return fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Authorization": `Bearer ${getToken("accessToken")}`
        },
        body: JSON.stringify(payload)
    });
}

export interface PagingOptions {
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDirection: "asc" | "desc",
    searchTerm: string
}

export function GETPAGED<T>(
    url: string,
    pagingOptions: PagingOptions
) {
    const search = pagingOptions.searchTerm ? `searchTerm=${pagingOptions.searchTerm}` : "";
    const fetchCall = GET(
        `${url}?page=${pagingOptions.currentPage}&pageSize=${pagingOptions.pageSize}&sortBy=${pagingOptions.sortBy}&sortDirection=${pagingOptions.sortDirection}&${search}`
    ).then(async (resp) => {
        if (resp.ok) {
            return resp.json();
        }
        return await handleError<PagedResult<T>>(resp);
    });
    return fetchCall;
}

export function GETFILE(url: string, filetype: ".csv") {
    const fileTypes = {
        ".csv": "text/csv"
    };

    return fetch("/api" + url, {
        method: "GET",
        headers: {
            Accept: fileTypes[filetype] ?? "application/json"
        },
        credentials: "same-origin"
    });
}

export async function GETWITHERROR<T>(url: string) {
    const resp = await fetch("/api" + url, {
        method: "GET",
        headers: {
            Accept: "application/json"
        },
        credentials: "same-origin"
    });

    return handleError<T>(resp);
}

export function POST(url: string, payload: any) {
    return fetch("/api" + url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",
        body: JSON.stringify(payload)
    });
}

export function POSTFORM(url: string, form: any, signal?: AbortSignal) {
    return fetch("/api" + url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",

        body: form,

        signal: signal
    });
}

export async function POSTWITHERROR<T>(url: string, payload: any) {
    const resp = await fetch("/api" + url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",
        body: JSON.stringify(payload)
    });

    return handleError<T>(resp);
}

export async function POSTFORMWITHERROR<T>(url: string, form: any, signal?: AbortSignal) {
    const resp = await fetch("/api" + url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",

        body: form,

        signal: signal
    });

    return handleError<T>(resp);
}

export async function PUT<T>(url: string, payload: any) {
    const resp = await fetch("/api" + url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",
        body: JSON.stringify(payload)
    });

    return handleError<T>(resp);
}

export function PUTFORM(url: string, form: any) {
    return fetch("/api" + url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",
        body: form
    });
}

export async function PUTFORMWITHERROR<T>(url: string, form: any) {
    const resp = await fetch("/api" + url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin",
        body: form
    });

    return handleError<T>(resp);
}

export async function DELETE<T>(url: string): Promise<JsonResponseModel<T>> {
    var resp = await fetch("/api" + url, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "CsrfToken": getCsrfCookie().toString()
        },
        credentials: "same-origin"
    });

    return handleError<T>(resp);
}

function getCsrfCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; CsrfToken=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return "";
}
