import * as React from "react";

import { Trans, useTranslation } from "react-i18next";
import { getUserClaims } from "../helper-functions";
import useParsedTranslation from "../hooks/useParsedTranslation";
import parseHtml from 'html-react-parser';
import { RbacContext } from "../rbac-context";

import OneColumnLayout from "../Components/UI/UX-Layout/Tiles/one-column-layout";
import EightByFour from "../Components/UI/UX-Layout/Content/eight-by-four";
import useRouteAccessCheck from "../hooks/useRouteAccessCheck";
import useCustomerSupportContactInfo from "../hooks/useCustomerSupportContactInfo";

const NoAccess = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const rbac = React.useContext(RbacContext);

    const { rac } = useRouteAccessCheck();    
    const { getSupportContactInfoByCultureCode } = useCustomerSupportContactInfo();

    const [supportContactInfo, setSupportContactInfo] = React.useState(getSupportContactInfoByCultureCode((getUserClaims() ? getUserClaims().culture : 'en-US')));
    
    return (        
        <>
            { (rbac.userContext && !rbac.userContext.UserDataIsComplete) ?

            <OneColumnLayout
                center={
                    <EightByFour
                        left={
                            <>
                                <h1 className="page-title-h1 mb-3">{ t('NoAccessHeader') }</h1>

                                <div>{ tp('MissingUserDataContent', { phone: supportContactInfo.phone, 
                                        email: supportContactInfo.email, 
                                        startTime: supportContactInfo.startTime, 
                                        endTime: supportContactInfo.endTime, 
                                        timezone: supportContactInfo.timezone, 
                                        startDay: supportContactInfo.startDay, 
                                        endDay: supportContactInfo.endDay }) }</div>
                            </>
                        }

                        right={ <></> }
                    />
                }
            />

            :

            <OneColumnLayout
                center={
                    <EightByFour
                        left={
                            <>
                                <h1 className="page-title-h1 mb-3">{ t('NoAccessHeader') }</h1>

                                <div>{ tp('NoAccessContent', { phone: supportContactInfo.phone, 
                                        email: supportContactInfo.email, 
                                        startTime: supportContactInfo.startTime, 
                                        endTime: supportContactInfo.endTime, 
                                        timezone: supportContactInfo.timezone, 
                                        startDay: supportContactInfo.startDay, 
                                        endDay: supportContactInfo.endDay }) }</div>
                            </>
                        }

                        right={ <></> }
                    />
                }
            />

            }

        </>
    );
};

export default NoAccess;
