import i18next from "i18next";
import { isNullOrUndefined } from "./helper-functions";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export const getLang = () => i18next.language || "en-US";
moment.locale(getLang());
//@ts-ignore
momentDurationFormatSetup(moment);

export const formatDate = (date: string | Date | moment.Moment): string => {
    if (isNullOrUndefined(date)) {
        return null;
    }

    moment.locale(getLang());
    var m = moment(date);
    return m.format("DD MMM yyy");
};

export const formatShortDate = (date: string | Date | moment.Moment): string => {
    if (isNullOrUndefined(date)) {
        return null;
    }

    moment.locale(getLang());
    var m = moment(date);
    return m.format("M/DD/yyyy");
};

export const formatLongDate = (date: string | Date | moment.Moment): string => {
    if (isNullOrUndefined(date)) {
        return null;
    }

    moment.locale(getLang());
    var m = moment(date);
    return m.format("LL");
};

export const formatLongDateWithDay = (date: string | Date | moment.Moment): string => {
    if (isNullOrUndefined(date)) {
        return null;
    }

    moment.locale(getLang());
    var m = moment(date);

    return m.format("dddd, MMMM D, YYYY");
};

export const formatNumber = (num: number): string => {
    if (isNullOrUndefined(num)) {
        return null;
    }

    return new Intl.NumberFormat(getLang()).format(num);
};

export const dateFormatPattern = () => {
    switch (getLang()) {
        case "fr":
        case "fr-FR":
            return "dd/MM/yyyy";

        case "fr-CA":
        case "en-CA":
            return "yyyy-MM-dd";

        case "en-GB":
            return "yyyy-MM-dd";

        case "es":
        case "es-SP":
        case "es-MX":
            return "dd-MM-yy";

        case "en":
        case "en-US":
        default:
            return "yyyy-MM-dd";
    }
};
