import * as React from "react";

import { useTranslation } from "react-i18next";

const ProfessionalEnrichment = () => {
    const { t } = useTranslation();
    
    return (
        <>            
            {/* WRITE HTML BELOW THIS LINE */}
            Professional Enrichment
        
        
        
        
        </>
    );
};

export default ProfessionalEnrichment;
