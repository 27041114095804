import { configureStore } from "@reduxjs/toolkit";
import classDetailsReducer from "./stores/classDetailsSlice";
import classParticipantsReducer from "./stores/classParticipantsSlice";

export const store = configureStore({
    reducer: {
        classDetails: classDetailsReducer,
        classParticipants: classParticipantsReducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch