import * as React from "react";
import Button from "../../../Components/UI/button";
import OnlineCourse, { CourseInstructor } from "../../../Interfaces/OnlineCourse";
import ProgressBar from "../../../Components/UI/progress-bar";
import { CourseObject } from "../../../Interfaces/Platform/courseObject";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { StatusEnum, RoleEnum } from "../../../Enums";
import { ConfigContext } from "../../../configuration-context";
import { getToken, getUserClaims } from "../../../helper-functions";
import { UserContext } from "../../../Interfaces/UserContext";
import useRouteAccessCheck from "../../../hooks/useRouteAccessCheck";

import IFrameVideoEmbed from "../../../Components/UI/iframe-video-embed";

import { openPdfFromBase64InNewTab } from "../../Utils/pdf";
import { fetchParticipantOnlineCourseCertificate } from "../../Utils/participants";
import { encodeToBase64 } from "pdf-lib";
import { Organization } from "../../../Interfaces/Platform/Participants";

import { RbacContext } from "../../../rbac-context";
import { render } from "@testing-library/react";

interface StartCourseUrl {
    learningHistoryId: string
    redirectUrl: string

}

const CourseListing = (props: {
    courseObject: CourseObject,
    organization: Organization,
    userInfo: UserContext,
    userCulture: string,
    renderAsHistoric: boolean
}): JSX.Element => {

    const { isCPIStaff } = useRouteAccessCheck();
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const videoEmbedRef = React.useRef(null);

    const [courseUrl, setCourseUrl] = React.useState("")

    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    const rbac = React.useContext(RbacContext);

    const [courseStatus, setCourseStatus] = React.useState(StatusEnum.NotStarted);
    const [hasEstimatedTimeToDisplay, setHasEstimatedTimeToDisplay] = React.useState(false);

    const displayInstructors = (courseInstructors: CourseInstructor[]) => {
        return courseInstructors.map((courseInstructor) => {
            return `${courseInstructor.firstName} ${courseInstructor.lastName}`;
        }).join(', ');
    };

    const doesHaveEstimatedTimeToDisplay = (estimatedTime: number) => {
        return ((estimatedTime != null) && (estimatedTime != undefined) && (estimatedTime != 0) && (estimatedTime > 0));
    }

    const fetchCourseUrl = async (learningHistoryId: string) => {
        try {
            const response = await fetch(`${apimBaseUrl}/C/Class/StartCourseUrl`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({
                    learningHistoryId: learningHistoryId,
                    redirectUrl: ""
                })
            });
            if (response.status === 404 || response.status === 204) return undefined;
            return await response.json();
        } catch (e) {
            console.error(e);
            // Handle fetch error
        }
    }

    const getRolesForSurvey = (roles: string[]) => {
        const Roles: string[] = [];
        roles.map(r => { if (r != RoleEnum.Developer && r != RoleEnum.Administrator && r != RoleEnum.StaffSeatAdjustor && r != RoleEnum.StaffSeatReadOnly && r != RoleEnum.ParticipantAdmin) Roles.push(r) });
        if (isCPIStaff() || roles.includes(RoleEnum.Developer) || roles.includes(RoleEnum.Administrator) || roles.includes(RoleEnum.StaffSeatAdjustor) || roles.includes(RoleEnum.StaffSeatReadOnly) || roles.includes(RoleEnum.ParticipantAdmin)) Roles.push("CPI");
        Roles.sort();
        return Roles;
    }

    const base64Encode = (inputString: string, urlEncoding: boolean) => {
        const valueArray = urlEncoding ? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_" : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        let utf8 = new Uint8Array(inputString.length * 3 + 32);
        const textEncoder = new TextEncoder();
        const resultsOfEncoding = textEncoder.encodeInto(inputString, utf8);
        if (resultsOfEncoding.written < utf8.length) {
            utf8 = utf8.slice(0, resultsOfEncoding.written);
        }
        const len = utf8.length;
        let outputResult = '';
        for (let i = 0; i <= (len - 1) / 3; i++) {
            const c1 = (i * 3) + 1 >= len;
            const c2 = (i * 3) + 2 >= len;
            const first = utf8[i * 3] * 65536;
            const second = c1 ? 0 : utf8[i * 3 + 1] * 256;
            const third = c2 ? 0 : utf8[i * 3 + 2];
            let numeric = first + second + third;
            let smallChunk = '';
            if (c1 && numeric > 65536) numeric /= 4096;
            else if (c2 && numeric > 256) numeric /= 64;
            while (numeric > 0) {
                const rem = numeric % 64;
                smallChunk = valueArray[rem] + smallChunk;
                numeric -= rem;
                numeric /= 64;
            }

            outputResult += smallChunk + ((c1 && !urlEncoding) ? "=" : "" + ((c2 && !urlEncoding) ? "=" : ""));
        }

        return outputResult;
    }

    const buildQueryParametersToLaunchACourseWith = (lastName: string, firstName: string, email: string,
        course: string, roles: string[], accountName: string,
        contactMarketType: string, eventVariableCategory: string, userLanguage: string,
        accountId: string, environment: string, contactId: string) => {
        let jsonString = "{ \"RecipientLastName\": \"" + (lastName || "") +
            "\", \"RecipientFirstName\": \"" + (firstName || "") +
            "\", \"RecipientEmail\": \"" + (email || "") +
            "\", \"Course\": \"" + (course || "") +
            "\", \"Role\": \"" + (roles ? roles.join(", ") : "") +
            "\", \"AccountName\": \"" + (accountName || "") +
            "\", \"DistributionChannel\": \"online course\", \"ContactMarketType\": \"" + (contactMarketType || "") +
            "\", \"EventVariableCategory\": \"" + (eventVariableCategory || "") +
            "\", \"UserLanguage\": \"" + (userLanguage || "") +
            "\", \"AccountID\": \"" + (accountId || "") + 
            "\", \"Env\": \"" + (environment || "");
        jsonString +=(contactId && (contactId.length > 0)) ? "\", \"ContactID\": \"" + (contactId || "") + "\"}" : "\"}";
        return "Q_EED=" + base64Encode(jsonString, true);
    }

    const startCourse = async (learningHistoryId: string) => {
        try {
            const response = await fetch(`${apimBaseUrl}/C/Class/StartCourse`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({
                    learningHistoryId: learningHistoryId
                })
            });
            if (response.status === 404) return undefined;
            await response.json();
            return true;
        } catch (e) {
            console.error(e);
        }
    }

    const handleOpenCourseUrl = async (reviewCompletedCourse?: boolean) => {
        if (!(props.courseObject.courseStart)) {
            startCourse(props.courseObject.learningHistoryId)
        }
        if (!props.courseObject.completedDate || reviewCompletedCourse) {
            //This is the scenario for Xyleme course
            if (props.courseObject.modulesUrl?.length > 0) {
                const query_parameters_for_survey: string = buildQueryParametersToLaunchACourseWith(props.userInfo.LastName, props.userInfo.FirstName, props.userInfo.Email, props.courseObject.courseName, getRolesForSurvey(props.userInfo.RolesByName), rbac?.userContext?.OrganizationName, props.organization?.marketType, props.courseObject?.specialty, props.userCulture, props.organization?.orgId, process?.env?.ENVIRONMENT, props.userInfo?.ContactId);
                const separatorCharacter = props.courseObject.modulesUrl.includes("?") ? "&" : "?";
                window.open(props.courseObject.modulesUrl + separatorCharacter + query_parameters_for_survey, '_blank');

                //window.open(props.courseObject.modulesUrl, '_blank');
            }
            //This is the scenario for ScormCloud course
            else {
                const response = await fetchCourseUrl(props.courseObject.learningHistoryId)
                if (response && videoEmbedRef) {
                    setCourseUrl(response);
                    videoEmbedRef?.current.openModal()
                }
            }
        }
        else {
            //Print Certificate
            fetchParticipantOnlineCourseCertificate(apimBaseUrl, rbac.userContext.Country, configContext, [props.courseObject.learningHistoryId]).then((response: any) => {
                openPdfFromBase64InNewTab(response.certificates[0]);
            });
        }
    }

    React.useEffect(() => {

        setHasEstimatedTimeToDisplay(doesHaveEstimatedTimeToDisplay(props.courseObject.estimatedTime));
        
        // active list
        if (!props.renderAsHistoric) {
            // No start date
            if (!props.courseObject?.courseStart) { 
                setCourseStatus(StatusEnum.NotStarted); 
            // Has start date and completed date
            } else if (props.courseObject?.courseStart && props.courseObject?.completedDate) { 
                setCourseStatus(StatusEnum.Completed); 
            // Has start date but no completed date  
            } else { 
                setCourseStatus(StatusEnum.InProgress);
            }

        // history list    
        } else {
            // If it has a completed date
            if (props.courseObject?.completedDate) {
                setCourseStatus(StatusEnum.History);
            // Otherwise, it does not have a completed date - it's incomplete
            } else {
                setCourseStatus(StatusEnum.Failed);
            }
        }

    }, [props.courseObject]);

    return (
        <>
            <div className="course-listing-container">
                <div className="course-listing-inner-wrapper">

                    <div>
                        {!(courseStatus == StatusEnum.History) && (
                            <div className="course-listing-thumbnail-column">
                                <img
                                    src={(props.courseObject && props.courseObject.thumbnailUrl) ? props.courseObject.thumbnailUrl : configContext?.SystemConfiguration?.CDNImagesUrl + "/_logos/cpi-mark.svg"}
                                />
                            </div>
                        )}
                    </div>

                    <div className="rightside-content">
                        <div className="course-listing-main-column">
                            <div className="course-listing-title-container">
                                <a className="sub-headings-h3 course-listing-title" onClick={() => { 

                                        // Block the listing title from acting on a click of the course is failed
                                        if (courseStatus != StatusEnum.Failed) {
                                            handleOpenCourseUrl();
                                        }                                        
                                    
                                    }}>
                                    {(props.courseObject && props.courseObject.courseName) ? props.courseObject.courseName : 'Online Course'}
                                    <i className="pl-2 fa-sharp fs16 fa-chevron-right"></i>
                                </a>
                            </div>
                            <div className="course-detail">
                                {!(courseStatus == StatusEnum.History) && (
                                    <div className="course-detail-primary-column">
                                        <span>
                                            {(hasEstimatedTimeToDisplay) && t("CourseListing.EstimatedTime") + ": "}
                                            {(hasEstimatedTimeToDisplay) && Math.floor(props.courseObject.estimatedTime / 60 / 60)}&nbsp;
                                            {(hasEstimatedTimeToDisplay) && t("CourseListing.Hours")}&nbsp;
                                            {(hasEstimatedTimeToDisplay) && (((props.courseObject.estimatedTime / 60) % 60) > 0) && (props.courseObject.estimatedTime / 60) % 60}&nbsp;
                                            {(hasEstimatedTimeToDisplay) && (((props.courseObject.estimatedTime / 60) % 60) > 0) && t("CourseListing.Minutes")}
                                        </span>
                                    </div>
                                )}
                                {(courseStatus == StatusEnum.History) && (
                                    <div className="course-detail-primary-column">
                                        <span>Enrolled {t('dynamicDate', { val: new Date(props.courseObject.enrolledDate), formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } })}</span>
                                        <span>Completed {t('dynamicDate', { val: new Date(props.courseObject.completedDate), formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } })}</span>
                                    </div>
                                )}
                                {courseStatus == StatusEnum.Completed || (courseStatus == StatusEnum.History) && (
                                    <div className="course-detail-secondary-column">
                                        <span>{t("MyTraining.ExamScore")} {Math.round(props.courseObject.examScore)}</span>
                                        <span>{t("MyTraining.ContactHours")} {props.courseObject.contactHours}</span>
                                    </div>
                                )}
                            </div>

                            <span className="course-description">{ }</span>
                        </div>

                        <div className="course-listing-button">
                            <div className="course-listing-secondary-column">
                                <>
                                    <div className="w100">
                                        { (courseStatus != StatusEnum.Failed) && // Do not render button if course is failed
                                            <Button
                                                label={StatusEnum.getButtonLabel(courseStatus)}
                                                isSolid={((courseStatus == StatusEnum.Completed) || (courseStatus == StatusEnum.History)) ? false : true}
                                                additionalStyleClasses={"w100"}
                                                clickAction={() => { handleOpenCourseUrl() }}

                                            />
                                        }                            

                                        {courseStatus == StatusEnum.Completed &&
                                            <Button
                                                label={t("Review Course")}
                                                isSolid={true}
                                                additionalStyleClasses={"w100 mt-2"}
                                                clickAction={() => {handleOpenCourseUrl(true)}}
                                            />
                                        }
                                    </div>
                                    {!(courseStatus == StatusEnum.History) && (
                                        <>
                                            <div className="course-listing-progress-bar w100">
                                                <ProgressBar completedDate={(props.courseObject.completedDate) ? new Date(props.courseObject.completedDate) : null} startedDate={props.courseObject.courseStart} minified widthBar={100} onlineCoursePercentComplete={(props.courseObject && props.courseObject.percentComplete) ? props.courseObject.percentComplete : 0} />
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-listing-separator mt-1"></div>
            <IFrameVideoEmbed ref={videoEmbedRef} modulesUrl={courseUrl} modalId="uniqueModalId" />
        </>
    )

};

export default CourseListing;
