import * as React from "react";
import { useNavigate } from "react-router-dom"
import Button from "../../../Components/UI/button";
import i18next, { t } from "i18next";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const InstructorListItem = (props: {
    listItems?: JSX.Element[]
}): JSX.Element => {
    const navigate = useNavigate();

    React.useEffect(() => {

    }, []);

    const printCertificateHandler = (instructorUserId:any) => {
        
    }

    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const displayDate = (date: string) => {
        return t("dynamicDate", { val: new Date(date), formatParams: { month: "numeric", day: "numeric", year: "numeric" } });
    };

    return (
        <>
            {
                props.listItems?.map((cert) => (
                    <tr>
                        <td className="course-name">
                            <div>{cert.courseName}</div>
                        </td>
                        <td className="seat-type">
                            {cert.seatType}
                        </td>
                        <td className="date">
                            {displayDate(cert.startDate)} - {displayDate(cert.endDate)}
                        </td>
                        <td className="cert-status">
                            {cert.status}
                        </td>
                        <td>
                            <Button 
                                label="Print"
                                isSolid={false}
                                elementId="printCertButton"
                                additionalStyleClasses={null}
                                clickAction={() => printCertificateHandler(cert.certId)}
                            />
                        </td>
                    </tr>
                ))
            }
        </>
    )

};

export default InstructorListItem;
