import * as React from "react";

import { CpiRoute } from "../../Routing/routes";

import { isExternalURL } from "../../helper-functions";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useFeatureFlags from "../../hooks/useFeatureFlags";

import { injectCultureToRoute } from "../../Pages/Utils/routing";

import { RbacContext } from "../../rbac-context";
import { ConfigContext } from "../../configuration-context";

const IconLink = (props: {
    key: number,
    url: { id: string; title: () => string; href: string; icon: string; paths: string[], route: CpiRoute },
    action?: Function
}): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = props.url.href === location.pathname;

    const rbac = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);

    const [ ITLTransitionRedirects ] = useFeatureFlags("ITLTransitionRedirects", false);
    const [ finalRoute, setFinalRoute ] = React.useState(props.url.href);

    const isLinkingToProfile = (path:string) => {
        return path == "/Platform/Dashboard/Profile";
    }

    React.useEffect(() => {

        const tempBaseURLConfigKey:string = (props.url && props.url.route) ? props.url.route.tempBaseURLConfigKey : null;
        const tempBaseUrl:string | boolean | { LangTag: string; Name: string; }[] = (tempBaseURLConfigKey) ? configContext.SystemConfiguration[tempBaseURLConfigKey] : null;

        if (ITLTransitionRedirects && tempBaseUrl && props.url.route.tempPathURL && !isLinkingToProfile(props.url.route.tempPathURL)) { 
            setFinalRoute(injectCultureToRoute(rbac.userContext.Country, tempBaseUrl, props.url.route.tempPathURL));
        }

    }, [ITLTransitionRedirects, rbac.userContext.Country]);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL(finalRoute)) {
            event.preventDefault();
            props.action && props.action();
            navigate(finalRoute);
        }
    };

    return (
        <>
            <a onClick={handleClick} className={`main-nav-item ${isActive ? 'current' : ''}`} id={props.url.id} href={finalRoute} target="_self">
                <i className={props.url.icon + (!isSpecialIconUrlId(props.url.id) ? " fs18 fa-sharp fa-light mw24" : "")} /><span className="mulish nav-item-text ml-3">{props.url.title()}</span>
            </a>
        </>
    );
};

const isSpecialIconUrlId = (iconUrlId: string): boolean => {
    return iconUrlId === "id-manage-participants" || iconUrlId === "id-manage-seats";
}

export default IconLink;
