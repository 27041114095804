import * as React from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CourseObject, SelectModel } from "../../Interfaces";

import { RbacContext } from "../../rbac-context";
import Tagsinput from "react-tagsinput";
import { useTranslation } from "react-i18next";
import uuid from "react-native-uuid";
import validate from "react-native-uuid";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../../configuration-context";
import { getToken } from "../../helper-functions";

export interface CourseIndustryFormProps {
    courseObjects: CourseObject[];
    index: number;
    setCourseObjects: any;
    industryOptions: any[];
    isXyleme: boolean;
    courseId: string;
    selectedIndustries: string[];
    setSelectedIndustries: any;
}

const CourseIndustryForm = ({
    courseObjects,
    index,
    setCourseObjects,
    industryOptions,
    isXyleme,
    courseId,
    selectedIndustries,
    setSelectedIndustries
}: CourseIndustryFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const [courseObject, setCourseObject] = React.useState<CourseObject>(courseObjects[index]);    
    const [originalCourseObject, setOriginalCourseObject] = React.useState<CourseObject>(null);
    const [isXylemeCourse, setIsXylemeCourse] = React.useState<boolean>(isXyleme);    
    const [objectTags, setObjectTags] = React.useState<any[]>([]);
    if (!originalCourseObject) {
        setOriginalCourseObject({ ...courseObject });
        setObjectTags(courseObject.tags);
    }

    const [isCreateForm, setIsCreateForm] = React.useState(courseObject.courseObjectId == undefined);

    if (isCreateForm) {
        if (!courseObject.importId) {
            setCourseObject({ ...courseObject, importId: uuid.v4().toString(), previewImportId: uuid.v4().toString(), tags: [] });
            setObjectTags([]);
        }
    }

    const [validated, setValidated] = React.useState(false);
    const [invalidImportId, setInvalidImportId] = React.useState(false);
    const [invalidPreviewImportId, setInvalidPreviewImportId] = React.useState(false);
    const [showSaveError, setShowSaveError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [saveError, setSaveError] = React.useState("");


    React.useEffect(() =>{
        setIsXylemeCourse(isXyleme);
    }, [isXyleme])

    const handleTagChange = (tags: string[]) => {
        if (isCreateForm) {
            var newTags = tags.map(tag => ({ tagName: tag }) )
            setObjectTags([...newTags])
        } else {
            const updatedTags = objectTags.map(t => ({
                ...t,
                deleted: !tags.includes(t.tagName),
            }))

            const newTagsToAdd = tags.filter(tag => 
                !objectTags.some(t => t.tagName === tag && !t.deleted)
            ).map(tag => ({ tagName: tag, deleted: false }))

            const combinedTags = [ ...updatedTags, ...newTagsToAdd, ]

            const filteredTags = Object.values(combinedTags.reduce((acc, cur) => {
                acc[cur.tagName] = acc[cur.tagName] && acc[cur.tagName].courseObjectTagId ? acc[cur.tagName] : cur
                return acc
            }, {}))

            setObjectTags([...filteredTags]);
        }
        if (!isEdited) { setIsEdited(true); }
    }

    const validateNotDuplicate = (industryId: string) => {
        return !(courseObjects.some((co) =>  co.industryId === industryId));
    }

    const saveCourseObject = async (): Promise<any|undefined> => {
        try {
            const createOrUpdateCall = isCreateForm ? "/C/Curriculum/CreateCourseObject" :
                "/C/Curriculum/UpdateCourseObject";

            const response = await fetch(apimBaseUrl + createOrUpdateCall, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify(
                    {
                        ...courseObject,
                        tags: objectTags,
                        courseId: courseId,
                        importId: courseObject.importId?.length === 0 ? uuid.v4().toString() : courseObject.importId,
                        previewImportId: courseObject.previewImportId?.length === 0 ? null : courseObject.previewImportId
                    })
            });
            if (response.status === 404 || response.status === 204) return undefined;
            return await response.json();
            
        } catch (e) {
            console.error(e);
            // Handle fetch error
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setShowSaveError(false);
        setInvalidImportId(false);
        setInvalidPreviewImportId(false);

        if (form.checkValidity() === true) {
            try {

                if(courseObject.importId && !validate.validate(courseObject.importId)){
                    setInvalidImportId(true);                    
                    return false;
                }
        
                if(courseObject.previewImportId && !validate.validate(courseObject.previewImportId)){
                    setInvalidPreviewImportId(true);                    
                    return false;
                }

                setSaving(true);
                setValidated(true);

                if (isCreateForm) {
                    const resp = await saveCourseObject();
                    if (resp) {
                        setCourseObject({ ...courseObject, courseObjectId: resp });
                        setSaving(false);
                        setValidated(false);
                        setIsCreateForm(false);
                        setIsEdited(false);
                        setSaved(true);
                        setOriginalCourseObject(Object.create(courseObject));
                        const newSelectedIndustries = [courseObjects.filter((cO, i) => i !== index).map(co => co.industryId), courseObject.industryId].flat();
                        setSelectedIndustries(newSelectedIndustries);
                        return;
                    } else {
                        setSaving(false);
                        setShowSaveError(true);
                        if (resp.Message) {
                            setSaveError(resp.Message);
                            console.error(resp.Message);
                        }
                        else {
                            const errMsg = t(`messageCodes::${resp.MessageCode}`, {
                                interpolation: { prefix: "{", suffix: "}" },
                                ...resp.MessageParams
                            });
                            setSaveError(errMsg);
                        }
                    }
                } else {
                    const resp = await saveCourseObject();
                    if (resp) {                        
                        setSaving(false);
                        setIsEdited(false);
                        setSaved(true);
                        setValidated(false);
                        setOriginalCourseObject(Object.create(courseObject));
                        const newSelectedIndustries = [courseObjects.filter((cO, i) => i !== index).map(co => co.industryId), courseObject.industryId].flat();
                        setSelectedIndustries(newSelectedIndustries);
                        return;
                    } else {
                        setSaving(false);
                        setShowSaveError(true);
                        if (resp.Message) {
                            setSaveError(resp.Message);
                            console.error(resp.Message);
                        }
                        else {
                            setSaveError(t(`messageCodes::${resp.MessageCode}`, {
                                interpolation: { prefix: "{", suffix: "}" },
                                ...resp.MessageParams
                            }));
                        }
                    }
                }
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
                setShowSaveError(true);
                setSaving(false);
            }
        }

        setValidated(true);
    };

    if (isEdited && saved) {
        setSaved(false);
    }

    if (saving && showSaveError) {
        setShowSaveError(false);
        setSaveError("");
    }

    const showSaved = () => {
        if (saved) {
            return (<div className="text-success"><i className="far fa-save"></i> Saved</div>);
        }
        else if (showSaveError) {
            return (<div className="text-danger"><i className="fas fa-times-circle"></i> {saveError}</div>);
        }
        return;
    }

    return (
        <>
            <Container fluid>
                <Row className="mb-4 border border-light-gray">
                    <Col className="mb-3">
                        <div className="fw-bold row-header bg-light-gray pt-1 pb-1 mb-3">Industry {courseObject.industry}</div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ maxWidth: "768px" }}>
                            <Form.Group as={Row} controlId="code">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Course Code")}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        required
                                        maxLength={50}
                                        value={courseObject.code}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setCourseObject({ ...courseObject, code: e.target.value });
                                            setIsEdited(true);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group className="row-padding" as={Row} controlId="industry">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Industry")}
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        as="select"
                                        required
                                        isInvalid={false}
                                        value={courseObject?.industryId?.toString() || ""}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                setCourseObject({
                                                    ...courseObject,
                                                    industryId: e.currentTarget.value ? e.currentTarget.value : null
                                                });
                                                setIsEdited(true);
                                        }}
                                    >
                                        <option key="0" value="" disabled>
                                            {t("Select")}
                                        </option>
                                        {industryOptions?.map(x => (
                                            selectedIndustries.includes(x.industryId) ? <option key={`${x.industryId}`} value={`${x.industryId}`} disabled>{`${x.name} (Industry Being Used)`}</option> : <option key={`${x.industryId}`} value={`${x.industryId}`}>{`${x.name}`}</option>
                                        ))}
                                    </Form.Control>                                   
                                </Col>
                            </Form.Group>
                            <Form.Group className="row-padding" as={Row} controlId="importId">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Import ID")}
                                </Form.Label>
                                <Col>
                                    <Form.Control  
                                        isInvalid={invalidImportId}
                                        maxLength={50}
                                        value={courseObject.importId}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setCourseObject({ ...courseObject, importId: e.target.value });
                                            setIsEdited(true);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            {isXylemeCourse && (<Form.Group className="row-padding" as={Row} controlId="fileUrl">
                                <Form.Label className="label-title" column sm="2">
                                    {t("File URL")}
                                </Form.Label>
                                <Col>
                                    <Form.Control                                        
                                        value={courseObject.fileUrl}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setCourseObject({ ...courseObject, fileUrl: e.target.value });
                                            setIsEdited(true);
                                        }}
                                    />
                                </Col>
                            </Form.Group>)}
                            <Form.Group className="row-padding" as={Row} controlId="previewImportId">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Preview Import ID")}
                                </Form.Label>
                                <Col>
                                    <Form.Control          
                                        isInvalid={invalidPreviewImportId}
                                        maxLength={50}
                                        value={courseObject.previewImportId}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setCourseObject({ ...courseObject, previewImportId: e.target.value });
                                            setIsEdited(true);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            {isXylemeCourse && (<Form.Group as={Row} controlId="previewFileUrl">
                                <Form.Label className="label-title" column sm="2">
                                    {t("Preview File URL")}
                                </Form.Label>
                                <Col>
                                    <Form.Control                                        
                                        value={courseObject.previewFileUrl}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setCourseObject({ ...courseObject, previewFileUrl: e.target.value });
                                            setIsEdited(true);
                                        }}
                                    />
                                </Col>
                            </Form.Group>)}
                            <Form.Group as={Row}>
                                <Form.Label className="label-title" column sm="2">
                                    {t("Tags")}
                                </Form.Label>
                                <Col>
                                    <Tagsinput value={ objectTags?.filter(t => !t.deleted)?.map(t => t.tagName) } onChange={ handleTagChange } />
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label className="label-title" >{t("Description")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    maxLength={1000}
                                    value={courseObject.description}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setCourseObject({ ...courseObject, description: e.target.value });
                                        setIsEdited(true);
                                    }}
                                />
                            </Form.Group>
                            <Row>
                                <Col sm="6" className="m-auto test-end">
                                    {showSaved()}
                                </Col>
                                <Col xs="6" sm="3">
                                    <Button
                                        variant="light"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            if (isCreateForm) {
                                                setCourseObjects(courseObjects.filter((co, i) => i !== index));
                                            }
                                            else {
                                                setCourseObject({
                                                    ...courseObject,
                                                    courseObjectId: originalCourseObject.courseObjectId,
                                                    courseId: originalCourseObject.courseId,
                                                    code: originalCourseObject.code,
                                                    description: originalCourseObject.description,
                                                    fileUrl: originalCourseObject.fileUrl,
                                                    importId: originalCourseObject.importId,
                                                    previewFileUrl: originalCourseObject.previewFileUrl,
                                                    previewImportId: originalCourseObject.previewImportId,
                                                    industryId: originalCourseObject.industryId,
                                                    industry: originalCourseObject.industry,
                                                    tags: originalCourseObject.tags  });
                                                setIsEdited(false);
                                                setShowSaveError(false);
                                            }
                                        }}
                                        disabled={(saving || !isEdited) && !isCreateForm}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs="6" sm="3">
                                    <Button variant="primary" type="submit" style={{ width: "100%" }} disabled={saving || !isEdited}>
                                        {saving ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                                            </>
                                        ) : (
                                                t("Save Industry")
                                            )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default withAITracking(reactPlugin, CourseIndustryForm);
