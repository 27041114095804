import * as React from "react";
import i18next from "i18next";
import { IDateInputProps } from "../../Interfaces/Platform/DateInput";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const DateInput = ({
    id,
    name,
    value,
    isActive,
    isReadOnly,
    isError,
    onChange,
    label,
    errorLabel,
    isRequired,
    showLabel = true,
    overrideOptionalLabel
}: IDateInputProps) => {
    const inputId = id ?? "dateInput";
    const inputName = name ?? "dateInput";
    const ref = React.useRef(null);
    const alertLabel = errorLabel ?? "Enter date";
    const optionalLabel = overrideOptionalLabel ? overrideOptionalLabel : "(Optional)";

    const getClassNames = () => {
        if (!isReadOnly) {
            if (isActive && isError && !value) {
                return "date-input--empty";
            }
            if (isActive && isError) {
                return "date-input--empty form-input.field-error";
            }
            if (isActive && !isError) {
                return "date-input";
            }
            return "date-input--inactive";
        } else {
            return "date-input";
        }
    };

    const getIconClassNames = () => {
        if (!isReadOnly) {
            if (isActive && isError && !value) {
                return "date-input-icon--empty";    
            }
            if (isActive && isError) {
                return "date-input-icon--empty form-input.field-error";    
            }
            if (isActive && !isError) {
                return "date-input-icon";
            }
            return "date-input-icon--inactive";
        } else {
            return "date-input-icon";
        }
    };

    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");
    const readOnlyDate = new Date(value).toLocaleDateString(locale, { timeZone: 'UTC' });
    const dateInputFormat = value !== '' && value.length > 0 ? new Date(value).toISOString().split('T')[0] : value;

    return (
        <>
            { !isReadOnly ? (
                <div className="date-input-container flex-column">
                    {showLabel && (
                        <div className="d-flex">
                            <label className="bold form-label fs14">{label}</label>
                            {isRequired ? <span className="fas fa-asterisk color-red superscript required-tag" aria-hidden="true"></span> : <span className="cpi-small optional-tag">{optionalLabel}</span>}
                        </div>
                    )}
                    <div className="d-flex">
                        <input
                            id={inputId}
                            name={inputName}
                            className={`mt-1 ${getClassNames()}`}
                            type="date"
                            ref={ref}
                            value={dateInputFormat}
                            onChange={onChange}
                            disabled={!isActive}
                        />
                        <div
                            onClick={() => {
                                //@ts-ignore
                                ref.current.showPicker();
                            }}
                            className={`mt-1 ${getIconClassNames()}`}
                        >
                            <i className="fa-sharp fa-light fa-calendar"></i>
                        </div>
                    </div>
                    { isError ? <span className="field-validation form-field-error small-error" id="dateInput-required-label-1">{alertLabel}</span> : null }
                </div>
                ) : (
                    <div>
                        <label className="bold form-label fs14 date-input-label-margin">{label}</label>
                        <p className="fw400">{readOnlyDate}</p>
                    </div>
                )
            }
        </>
    );
};

export default DateInput;
