import * as React from "react";
import { Container,  } from "react-bootstrap";
import Tile from "../Components/UI/tile";
import FormDisplay from "../Components/Form/form-display";
import TwoColumnLayout from "../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../Components/UI/UX-Layout/Tiles/three-column-layout";
import ArrowLink from "../Components/UI/arrow-link";
import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";
import ProfileDataModel from "../Interfaces/Platform/ApimData/ProfileDataModel";
import ViewProfileModel from "../Interfaces/Platform/ApimData/ProfileDataModel";
import InstructorCertificationHistory from "./instructorCertificationHistory";
import { getToken } from "../helper-functions";
import { useTranslation } from "react-i18next";

import { fetchProgramsOrganizations } from "./Components/DashboardPlatformUtils";
import useDetectWindowSize from "../hooks/useDetectWindowSize";
import { RoleEnum } from "../Enums";

import { PlatformBaseRoutes } from "../Routing/routes";

const TrainingMaterials = (

) => {
    const { isLaptop } = useDetectWindowSize();
  
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { t } = useTranslation();

    const [ isBAOfActiveCIs, setIsBAOfActiveCIs ] = React.useState(false);
    const [ isActiveCI, setIsActiveCI ] = React.useState(false);

    React.useEffect(() => {
        if (rbac.userContext.RolesByName.includes(RoleEnum.BusinessAdministrator)) {
            fetchProgramsOrganizations(apimBaseUrl, configContext.SystemConfiguration.ApimKey, [rbac.userContext.OrganizationNumber]).then((data) => {
                if (rbac.userContext.RolesByName.includes(RoleEnum.BusinessAdministrator) && data && Array.isArray(data) && data.length > 0) {
                    setIsBAOfActiveCIs(true);
                }
            });
        }
    }, [configContext?.SystemConfiguration?.ApimKey, rbac.userContext.PingId]);

    React.useEffect(() => {
        // check if user has CI role, and that at least one certification is active
        if (
                rbac.userContext.RolesByName.includes(RoleEnum.CertifiedInstructor) &&
                rbac.userContext.CertificationData &&
                rbac.userContext.CertificationData.length > 0 &&
                rbac.userContext.CertificationData.some((certification: any) => certification.status.toLowerCase() == "active")
           ) {

            setIsActiveCI(true);

        }

    }, [rbac.userContext.RolesByName]);

    return (
        <>
            <div className="page-content ml-4 training-materials-wrapper">
                <Container fluid>
                        <h1 className="page-title-h1 mt-5">{t("BTN-Training-Materials")}</h1>
                        <ThreeColumnLayout
                            isMobile={!isLaptop}
                            left={
                                <>
                                    <>
                                        { (isBAOfActiveCIs || isActiveCI) &&
                                            <Tile title="">
                                                <div className="tile-content-wrapper">
                                                    <div className="image-block">
                                                        <a href={`${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Instructor-Guides-and-Presentations`}>
                                                            <img 
                                                                alt="Image Alt"
                                                                className="link-cursor"
                                                                src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Classroom-Materials.png`}
                                                            ></img>
                                                        </a>
                                                    </div>
                                                    <ArrowLink data={{ label: t("VI3E-MaterialsSection-Title"), href:`${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Instructor-Guides-and-Presentations`}}/>
                                                    <p className="mt-3">
                                                        {t("VI3E-MaterialsSection-Text")}
                                                    </p>
                                                </div>
                                            </Tile>
                                        }
                                        { (isBAOfActiveCIs || isActiveCI) &&
                                            <Tile title="">
                                                <div className="tile-content-wrapper">
                                                    <div className="image-block">
                                                        <a href={`${configContext?.SystemConfiguration?.PublicSite}plp?id=1073741825&fkey=ProductTypeList:Workbooks&fkey=ProductTypeList:Workbooks+with+Blended+Learning`}>
                                                            <img 
                                                                alt="Image Alt"
                                                                className="link-cursor"
                                                                src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Workbooks-Seats.png`}
                                                            ></img>
                                                        </a>
                                                    </div>
                                                    <ArrowLink data={{ label: t("VI3E-ConclusionSection-WorkbookLink"), href:`${configContext?.SystemConfiguration?.PublicSite}plp?id=1073741825&fkey=ProductTypeList:Workbooks&fkey=ProductTypeList:Workbooks+with+Blended+Learning`}}/>
                                                    <p className="mt-3">
                                                        {t("VI3E-ConclusionSection-WorkbookLink-Text")}
                                                    </p>
                                                </div>
                                            </Tile>
                                        }
                                    </>
                                </>
                            }
                            center={
                                <>
                                    { (isBAOfActiveCIs || isActiveCI) &&
                                        <Tile title="">
                                            <div className="tile-content-wrapper">
                                                <div className="image-block">
                                                    <a href={`${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources/Training-Videos`}>
                                                        <img 
                                                            alt="Image Alt"
                                                            className="link-cursor"
                                                            src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Training-Videos.png`}
                                                        ></img>
                                                    </a>
                                                </div>
                                                <ArrowLink data={{ label: t("BTN-TrainingVideos"), href:`${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources/Training-Videos`}}/>
                                                <p className="mt-3">
                                                    {t("BTN-TrainingVideos-Text")}
                                                </p>
                                            </div>
                                        </Tile>
                                    }
                                    <Tile title="">
                                    <div className="tile-content-wrapper">
                                            <div className="image-block">
                                                <a href="#">
                                                    <img 
                                                        alt="Image Alt"
                                                        className="link-cursor"
                                                        src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Other-Products.png`}
                                                    ></img>
                                                </a>
                                            </div>
                                            <ArrowLink data={{ label: t("Other Products"), href: "#"}}/>
                                            <p className="mt-3">
                                                {t("Other-Products-Text")}
                                            </p>
                                        </div>
                                    </Tile>
                                </>
                            }
                            right={
                                <>
                                    { (isBAOfActiveCIs || isActiveCI) &&
                                        <Tile title="">
                                            <div className="tile-content-wrapper">
                                                <div className="image-block">
                                                    <a href={`${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources`}>
                                                        <img 
                                                            alt="Image Alt"
                                                            className="link-cursor"
                                                            src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Training-Tips.png`}
                                                        ></img>
                                                    </a>
                                                </div>
                                                <ArrowLink data={{ label: t("Training Tips & Best Practices"), href: `${configContext?.SystemConfiguration?.KenticoSite}/Platform/Dashboard/Training-Center/Classroom-Videos-and-Resources`}}/>
                                                <p className="mt-3">
                                                    {t("Training-Tips-Best-Practices-Text")}
                                                </p>
                                            </div>
                                        </Tile>
                                    }
                                    { (isBAOfActiveCIs || isActiveCI) &&
                                        <Tile title="">
                                            <div className="tile-content-wrapper">
                                                <div className="image-block">
                                                    <a href={PlatformBaseRoutes.Preview.fullPath}>
                                                        <img 
                                                            alt="Image Alt"
                                                            className="link-cursor"
                                                            src={`${configContext?.SystemConfiguration?.CDNImagesUrl}/training-materials/IMG-Preview-Courses.png`}
                                                        ></img>
                                                    </a>
                                                </div>
                                                <ArrowLink data={{ label: t("Preview Courses"), href: PlatformBaseRoutes.Preview.fullPath }}/>
                                                <p className="mt-3">
                                                    {t("Preview Courses-Text")}
                                                </p>
                                            </div>
                                        </Tile>
                                    }                                
                                </>
                           }
                        />
                </Container>
            </div>
        </>
    );
};

export default TrainingMaterials;
