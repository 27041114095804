import * as React from "react";
import { CourseObject } from "../../../Interfaces/Platform/courseObject";
import CourseListing from "./course-listing";
import { RbacContext } from "../../../rbac-context";
import { OnlineCourseListType } from "../../../Interfaces/Platform/OnlineCourseList";
import { Organization } from "../../../Interfaces/Platform/Participants";

const CourseList = (props: {
    courses?: CourseObject[],
    organization: Organization,
    listType: OnlineCourseListType,
    userCulture: string
}): JSX.Element => {

    const { userContext } = React.useContext(RbacContext);
    const sortActiveCoursesFirst = (a: CourseObject, b: CourseObject) => {
        if (a.completedDate && !b.completedDate) { return -1 }
        else if (!a.completedDate && b.completedDate) { return 1 }
        else { return 0 }
    }

    return (
        <>
            {
                props.courses?.sort(sortActiveCoursesFirst).map((course) => (
                    <CourseListing renderAsHistoric={(props.listType.listType == "history")} courseObject={course} organization={props.organization} userInfo={userContext} userCulture={props.userCulture} />
                ))
            }
        </>
    )

};

export default CourseList;
