import * as React from "react";
import Accordion from "../../../../Components/UI/accordion";
import EditProfile from "../../Profile/edit-profile";

const PreDashboard = (props: {
    standaloneEnrollmentData: Array<any>,
    returnEnrollmentComponents: Function
}) => {
    const [userContactDataIsVerified, setUserContactDataIsVerified] = React.useState(false);

    React.useEffect(() => {
        let _userContactDataIsVerified = props.standaloneEnrollmentData.find((item:any) => item.verifiedContactInfo) ? true : false;
        setUserContactDataIsVerified(_userContactDataIsVerified);
    }, []);

    return (
        <>
            <Accordion 
                title="Verify Contact Information"
                isOpen={!userContactDataIsVerified}
                isComplete={userContactDataIsVerified}
                children={<EditProfile 
                    showOrganizationSection={false} 
                    showSaveChangesButton={true} 
                    isUserUpdatingDataFromDashboard={true} 
                    setUserContactDataIsVerified={setUserContactDataIsVerified}
                />}
            />
            
            <Accordion 
                title="Complete Certification Training"
                isOpen={true}
                isComplete={false}
                children={props.returnEnrollmentComponents(props.standaloneEnrollmentData)}
            />
        </>
    )
};

export default PreDashboard;