import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { setLanguage } from '../ApiServices/Language';
import { setPendoCulture } from '../pendo';
import Cookies from 'universal-cookie';

const useLanguages = () => {

    const { t } = useTranslation();
    const cookies = new Cookies();

    const getLanguageLabel = (languageCode:string) => {
        switch (languageCode) {
            case "en-US":
                return "EN";
            case "en-CA":
                return "CA";     
            case "en-GB":
                return "GB";
            case "en-IE":
                return "IE";
            case "en-AU":
                return "AU";
            case "en-NZ":
                return "NZ";
            case "en-FI":
                return "FI";
            case "fr-FR":
                return "FR";
        }
    }

    const updateLanguage = (languageCode:string) => {
        document.documentElement.lang = languageCode;
        cookies.set("i18n.langtag", languageCode, { path: "/" });
        i18next.changeLanguage(languageCode);
        setPendoCulture(languageCode);
    };

    return { getLanguageLabel, updateLanguage }
}

export default useLanguages;