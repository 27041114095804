import * as React from "react";

import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PageMessagesProps {
    loading: boolean;
    errored?: boolean;
    notFound?: boolean;
    notFoundMessage?: string;
}

const PageMessages = ({ loading, errored, notFound, notFoundMessage }: PageMessagesProps) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {loading && (
                <div style={{ position: "fixed", top: "50%", left: "50%" }} className="fa-3x">
                    <i className="fas fa-spinner fa-spin"></i>
                </div>
            )}
            {errored && <Alert variant="danger">{t("An unknown error has occurred, please try again.")}</Alert>}
            {notFound && <Alert variant="danger">{notFoundMessage}</Alert>}
        </>
    );
};

export default PageMessages;
