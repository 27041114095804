import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import VideoEmbed from "../../Components/UI/video-embed";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const SelectingATrainingTopic = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}
            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                        marginTop={false}
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}

                                <h1 className="page-title-h1 title-margin">{t('SelectTopic-Title')}</h1>
                                <p className="mw-900">{t('SelectTopic-Subheading')}</p>
                            </>
                        }
                    />
                    <Tile title={t('SelectTopic-Section1-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <ThreeColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('SelectTopic-Section1-Content1')}
                                    </>
                                }

                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('SelectTopic-Section1-Content2')}
                                        
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        {tp('SelectTopic-Section1-Content3')}
                                        
                                    </>
                                }
                            />

                        </>
                    </Tile>
                    <Tile title={t('SelectTopic-Section2-Title')} hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <FourByEight
                                left={
                                    <>
                                        {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}
                                        {tp('SelectTopic-Section2-Content1')}

                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}

                                        {tp('SelectTopic-Section2-Content2')}

                                    </>
                                }
                            />
                        </>
                    </Tile>
                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="mb-3">

                                    </div>
                                    <div className="mb-3">
                                        <Button label="Step 2 | Identify Participant Needs & Goals" isSolid={true} elementId="buttonId" href="identifying-participant-needs-and-goals" />
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Container>
            </div>
        </>
    );
};

export default SelectingATrainingTopic;
