import InvoiceReturnModel from "../../Interfaces/InvoiceReturnModel";
import OrganizationInvoiceModel from "../../Interfaces/OrganizationInvoiceModel";
import OrganizationInvoiceSummaryModel from "../../Interfaces/OrganizationInvoiceSummaryModel";
import OrganizationInvoicedCourse from "../../Interfaces/OrganizationInvoicedCourse";
import OrganizationSearchModel from "../../Interfaces/OrganizationSearchModel";
import SeatExpirationUpdateModel from "../../Interfaces/SeatExpirationUpdateModel";
import { fetchCourse } from "./course";
import { getToken } from "../../helper-functions";

export const fetchOrganizationTreeIds = async (apimBaseUrl: string, configContext: any, organizationId: string) => {
    try {
        const response = await fetch(`${apimBaseUrl}/Q/Organization/Tree/Ids/${organizationId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        });
        if (response.status === 404 || response.status === 204) return [];
        return await response.json();
    } catch (e) {
        // Handle fetch error
        console.log(e);
    }
};

export const fetchOrganization = async (apimBaseUrl: string, configContext: any, organizationId: string): Promise<OrganizationSearchModel|undefined> => {
    try {
        const response = await fetch(apimBaseUrl + "/Q/Organization/GetOrganizationById/" + organizationId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();            
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchSeatsByOrganization = async (apimBaseUrl: string, configContext: any, organizationId: string): Promise<OrganizationInvoicedCourse[]|undefined> => {
    try {
        const response = await fetch(apimBaseUrl + "/Q/Organization/SeatsByOrganization/" + organizationId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            }
        });
        if (response.status === 404 || response.status === 204) return undefined;
        const data = await response.json();
        for (let i = 0; i < data.length; i++) {
            if (!data[i].courseName) {
                const thisCourseInfo = await fetchCourse(configContext, data[i].courseId);
                data[i].courseName = thisCourseInfo.name;
            }
        }
        return data;
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchCourseSeatDataByOrganization = async (configContext: any, organizationId: string, courseId: string, includeOrganizationTree: boolean = false)
: Promise<OrganizationInvoiceModel[]|undefined> => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Organization/CourseSeatsData/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationId,
                courseId,
                includeOrganizationTree,
            })
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const UpdateOrganizationSeat = async (configContext: any, payload: SeatExpirationUpdateModel)
: Promise<OrganizationInvoiceModel|undefined> => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/C/Organization/UpdateOrganizationSeat`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return await response.json();
    } catch (e) {
        // Handle error message
    }
};

export const RemoveOrganizationSeat = async (configContext: any, payload: InvoiceReturnModel) 
: Promise<OrganizationInvoiceModel|undefined> => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/C/Organization/RemoveOrganizationSeat`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify(payload)
        });
        return await response.json();
    } catch (e) {
        // Handle error message
    }
};

export const fetchOrganizationInvoicedCourses = async (configContext: any, organizationId: string, includeOrganizationTree: boolean)
: Promise<any> => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Organization/InvoicedCourses/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationId,
                includeOrganizationTree,
            })
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}

export const fetchOrganizationCourseInvoiceSummary = async (configContext: any, organizationId: string, includeOrganizationTree: boolean, courseId: string)
: Promise<OrganizationInvoiceSummaryModel|undefined> => {
    try {
        const response = await fetch(`${configContext?.SystemConfiguration?.ApimBaseUrl}/Q/Organization/InvoiceSummary/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
            body: JSON.stringify({
                organizationId,
                includeOrganizationTree,
                courseId,
            })
        });
        if (response.status === 404 || response.status === 204) return undefined;
        return await response.json();
        
    } catch (e) {
        console.error(e);
        // Handle fetch error
    }
}