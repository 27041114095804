import i18n from "i18next";

export enum CertStatusEnum {
    Active = "active",
    Inactive = "inactive"
}

export namespace CertStatusEnum {
    export function toTranslatedString(certStatus: string) {
        switch (certStatus) {
            case "active":
                return i18n.t("Active");
            case "inactive":
                return i18n.t("Inactive");
            default:
                return i18n.t("Inactive");
        }
    }
}
