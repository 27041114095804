import { ApiResponse, GET, GETWITHERROR, JsonResponseModel, POSTWITHERROR } from ".";
import { LearnerCourseDetails, LearnerHistoryViewModel } from "../Interfaces";

import CertificateModel from "../Interfaces/CertificateModel";
import { loadAsync } from "jszip";
import { template } from "../helper-functions";

export const FETCH_LEARNER_COURSES_URL = "/Learn/GetLearnerCoursesAsync";
const FETCH_LEARNER_COURSE_BY_CLASS_URL_TEMPLATE = template`/Learn/GetLearnerCourseByClassAsync?classId=${0}`;
const FETCH_LESSON_LAUNCH_URL_TEMPLATE = template`/Learn/GetLessonLaunchUrlAsync?learnerLessonId=${0}`;
const FETCH_MANY_SCO_RESULTS_URL_TEMPLATE = template`/Learn/GetManyScoResultsAsync?learnerLessonId=${0}`;
export const FETCH_LEARNER_COURSE_THUMBNAILS_URL = "/Learn/GetLearnerCourseThumbnailsAsync";
export const FETCH_LEARNER_HISTORY_URL = "/Learn/LearnerHistoryAsync";
const SYNC_COURSE_PROGRESS_URL = "/Learn/SyncCourseProgressAsync";
const FETCH_CERTIFICATE_DATA_URL_TEMPLATE = template`/Learn/GetCertificateDataAsync?learnerCourseId=${0}`;

export async function fetchLearnerCourses(): Promise<LearnerCourseDetails[]> {
    const resp = await GETWITHERROR<LearnerCourseDetails[]>(FETCH_LEARNER_COURSES_URL);
    return resp.Data;
}

export async function fetchLearnerCourseThumbnails(): Promise<{ [courseId: string]: Blob }> {
    const resp = await GET(FETCH_LEARNER_COURSE_THUMBNAILS_URL);
    const thumbnailsZip = await resp.blob();
    const courseThumbnails: { [id: string]: Blob } = {};
    const jsZip = await loadAsync(thumbnailsZip);

    const filenames = Object.keys(jsZip.files);
    for (let i = 0; i < filenames.length; i++) {
        const filename = filenames[i];
        const blob = await jsZip.files[filename].async("blob");
        const filenameParts = filename.split("-");
        courseThumbnails[filenameParts[0]] = blob;
    }
    return courseThumbnails;
}

export async function fetchLearnerCourse(classId: number): Promise<JsonResponseModel<LearnerCourseDetails>> {
    return GETWITHERROR(FETCH_LEARNER_COURSE_BY_CLASS_URL_TEMPLATE(classId));
}

export async function fetchLessonLaunchUrl(learnerLessonId: number): Promise<JsonResponseModel<{ LaunchUrl: string }>> {
    return GETWITHERROR(FETCH_LESSON_LAUNCH_URL_TEMPLATE(learnerLessonId));
}

export async function fetchManyScoResults(learnerLessonId: number): Promise<JsonResponseModel<{ Completed: boolean }>> {
    return GETWITHERROR(FETCH_MANY_SCO_RESULTS_URL_TEMPLATE(learnerLessonId));
}

export async function fetchLearnerHistory(): Promise<LearnerHistoryViewModel[]> {
    const resp = await GETWITHERROR<LearnerHistoryViewModel[]>(FETCH_LEARNER_HISTORY_URL);
    return resp.Data;
}

export async function syncCourseProgress(): Promise<JsonResponseModel<ApiResponse>> {
    return POSTWITHERROR(SYNC_COURSE_PROGRESS_URL, {});
}

export async function fetchCertificateData(learnerCourseId: number): Promise<JsonResponseModel<CertificateModel>> {
    return GETWITHERROR(FETCH_CERTIFICATE_DATA_URL_TEMPLATE(learnerCourseId));
}
