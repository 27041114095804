import * as React from "react";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";

export interface Props {
    specialty: string;
    onSuccess: () => void;
    onClose: () => void;
}

const ClassInstructorRemove = (props: Props): JSX.Element => {
    const { tp } = useParsedTranslation();

    return (
        <>
            <div className="class-notes-content">
                <p className="mb-5">The instructor you're trying to remove is certified to train {tp(props.specialty)}. By removing the instructor, information selected for this class will be reset.</p>
                <p className="fw600">Do you want to continue?</p>
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton clickAction={() => props.onClose()} label={<><i className="me-1 far fa-times"/> Cancel</>} />
                    <Button isSolid label={
                        <>
                            <span>Yes, Remove Instructor</span>
                            <i className="fa-sharp fa-light fa-chevron-right"/>
                        </>
                    } clickAction={props.onSuccess} />
                </div>
            </div>
        </>
    );

};

export default ClassInstructorRemove;
