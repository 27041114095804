import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

import ArrowLink from "../../Components/UI/arrow-link";
import Button from "../../Components/UI/button";

import ActivityPatternBlock from "../../Components/UI/Content-Pages/activity-pattern-block";
import FacilitatingStrategyBlock from "../../Components/UI/Content-Pages/facilitating-strategy-block";
import ImportUsingFile from "../../Components/UI/Content-Pages/import-using-file";
import PdfDownload from "../../Components/UI/Content-Pages/pdf-download";
import ResourcePost from "../../Components/UI/Content-Pages/resource-post";
import VideoListing from "../../Components/UI/Content-Pages/video-listing";
import WebinarListing from "../../Components/UI/Content-Pages/webinar-listing";
import WriteYourOwnStep from "../../Components/UI/Content-Pages/write-your-own-step";

const OutliningActivityFormatAndTiming = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE HTML BELOW THIS LINE */}

            <div className="page-content m-auto">
                <Container fluid className="page-padding">
                    <OneColumnLayout
                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <h1 className="page-title-h1 mb-2">{t('OutliningActivity-Title')}</h1>
                            <p className="mw-900">{t('OutliningActivity-Subeading')}</p>

                        </>
                    }
                />

                <Tile title={t('OutliningActivity-Section1-Title')} hideTileHeader={false}>
                    <>
                        {/* WRITE HTML BELOW THIS LINE */}
                        <FourByEight
                            left={
                                <>
                                    {/* WRITE LEFT COLUMN CONTENT BELOW THIS LINE */}

                                    <a href="https://cdn.crisisprevention.com/media/training-center/images/outlining-activity-format-and-timing/Activity-Writing-Guide.pdf" target="_new"><img width="250px" src="https://cdn.crisisprevention.com/media/training-center/images/outlining-activity-format-and-timing/format-timing.png" alt="Activity Writing Guide"></img></a>
                                    {tp('OutliningActivity-Section1-Content1')}


                                </>
                            }

                            right={
                                <>
                                    {/* WRITE RIGHT COLUMN CONTENT BELOW THIS LINE */}
                                    {tp('OutliningActivity-Section1-Content2')}
                                </>
                            }
                        />
                    </>
                </Tile>
                <Tile title={t('OutliningActivity-Section2-Title')} hideTileHeader={false}>
                    <>
                        {/* WRITE HTML BELOW THIS LINE */}
                        {tp('OutliningActivity-Section2-Content1')}

                    </>
                </Tile>

                <OneColumnLayout
                    center={
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="mb-3">
                                    <Button label="Identify Participant Needs & Goals" isSolid={false} elementId="buttonId" href="Identifying-Participant-Needs-And-Goals" />
                                </div>
                                <div className="mb-3">
                                    <Button label="Step 4 | Specifying Purpose, Directions, & Setup" isSolid={true} elementId="buttonId" href="Specifying-Purpose-Directions-and-Setup" />
                                </div>
                            </div>
                        </>
                    }
                />
                </Container>
            </div>
        </>
    );
};

export default OutliningActivityFormatAndTiming;
