import * as React from "react";

import BaseOfEmployment from "../Pages/Support/base-of-employment";
import { SupportBaseRoutes } from "./routes";
import Page from "../Pages/page";
import { CpiRoute } from "./routes";
import ProtectedRoute from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { RoleEnum, SupportRoles } from "../Enums";
import NoAccess from "../Pages/no-access";

import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import OrderHistoryPage from "../Pages/Support/order-history";
import ProfilePage from "../Pages/Components/profile";
import EditProfile from "../Pages/Components/Profile/edit-profile";
import MultiFactorAuthentication from "../Pages/Components/Profile/multi-factor-authentication";
import ChangePassword from "../Pages/Components/Profile/change-password";
import CertificationAgreement from "../Pages/CertAgreement/certification-agreement";
import SetYourPassword from "../Pages/set-your-password";
import useFeatureFlags from "../hooks/useFeatureFlags";

import { ConfigContext } from "../configuration-context";

import Redirect from "./redirect";

const StaffSupportRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (

    <ProtectedRoute roles={SupportRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData:any,
    rac:any,
    canViewProfile: boolean,
    canManageProfile: boolean
) => {
    const tempArray:CpiRoute[] = [];

    const configContext = React.useContext(ConfigContext);

    for (const route in routesData) {
        tempArray.push(SupportBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route:any, i:number) => (
            // map over CpiRoute[]
            (rac(route)) ?
            <>
                // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                <Route key={i} path={route.path}
                    element={ (!route.isOutsideAuth) ?
                        <StaffSupportRoute>

                                <Redirect baseUrl={configContext.SystemConfiguration[route.tempBaseURLConfigKey]} path={route.tempPathURL}>
                          
                                    <div>
                                        {(route.pageId == "supportProfile" && canViewProfile) &&
                                            <ProfilePage />
                                        }

                                        {(route.pageId == "supportProfile" && !canViewProfile) &&
                                            <NoAccess />
                                        }

                                        {(route.pageId == "supportProfileChangePassword" && canManageProfile) &&
                                            <ChangePassword />
                                        }

                                        {(route.pageId == "supportProfileChangePassword" && !canManageProfile) &&
                                            <NoAccess />
                                        }

                                        {(route.pageId == "supportMultiFactorAuthentication" && canManageProfile) &&
                                            <MultiFactorAuthentication />
                                        }

                                        {(route.pageId == "supportMultiFactorAuthentication" && !canManageProfile) &&
                                            <NoAccess />
                                        }

                                        { route.pageId == "supportCertificationAgreement" &&
                                            <CertificationAgreement />
                                        }
                                        
                                        {(route.pageId == "supportEditProfile" && canManageProfile) &&
                                            <EditProfile 
                                                showSaveChangesButton={true}
                                                isUserUpdatingDataFromDashboard={false}
                                                showOrganizationSection={true}
                                            />
                                        }

                                        {(route.pageId == "supportEditProfile" && !canManageProfile) &&
                                            <NoAccess />
                                        }

                                        { route.pageId == "baseOfEmployment" &&
                                            <BaseOfEmployment />
                                        }

                                        { route.pageId == "supportOrderHistory" &&
                                            <OrderHistoryPage/>
                                        }

                                        { route.pageId == "logout" &&
                                            <div>Logging Out</div>
                                        }
                                    </div>

                                </Redirect>
                        
                        </StaffSupportRoute>

                        :

                     
                            <div>
                                {route.pageId == "setYourPassword" &&
                                    <SetYourPassword />
                                }

                                {route.pageId == "somethingWentWrong" &&
                                    <NoAccess />
                                }
                            </div>
                    
                    }
                />
                <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                    }
                />
            </>
            :
                // we do not meet the criteria for culture and should render no-access content
                <Route key={i} path={route.path}
                    element={
                        <ProtectedRoute>
                          
                                <>
                                    { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */ }
                                    { <NoAccess /> }
                                </>
                 
                        </ProtectedRoute>
                    }
                />               
            ))}
        </>
    );
};

const SupportRouting = () => {
    const { rac } = useRouteAccessCheck();
    const [canViewProfile] = useFeatureFlags("ITLViewProfile", true);
    const [canManageProfile] = useFeatureFlags("ITLManageProfile", true);

    return (
        <Routes>           
            { createRoutes(SupportBaseRoutes, rac, canViewProfile, canManageProfile) }
        </Routes>
    );
};

export default withAITracking(reactPlugin, SupportRouting);
