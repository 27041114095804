import * as React from "react";

import DashboardPage from "../Pages/dashboard";
import TrainingCenter from "../Pages/TrainingCenter/training-center";
import EvaluationsAndPostTrainingResponsibilities from "../Pages/TrainingCenter/eval-and-post-training-responsibilities";
import CustomizationNewTermsTransitions from "../Pages/TrainingCenter/customization-new-terms-transitions";
import QualityStandards from "../Pages/TrainingCenter/quality-standards";
import DccQualityStandards from "../Pages/TrainingCenter/dcc-quality-standards";
import BaseOfEmployment from "../Pages/Support/base-of-employment";
import CeBoardApproval from "../Pages/TrainingCenter/ce-board-approval";
import ChoosingYourCertifiedInstructors from "../Pages/TrainingCenter/choosing-your-certified-instructors";
import CommunicatingInternally from "../Pages/TrainingCenter/communicating-internally";
import AfterVirtualTraining from "../Pages/TrainingCenter/after-virtual-training";
import DownloadResources from "../Pages/TrainingCenter/download-resources";
import CpiLogoBrandMaterials from "../Pages/TrainingCenter/cpi-logo-brand-materials";
import PreparingForTraining from "../Pages/TrainingCenter/preparing-for-training";
import TrainingEnvironmentSetup from "../Pages/TrainingCenter/training-environment-setup";
import VirtualTraining from "../Pages/TrainingCenter/virtual-training";
import RefresherTraining from "../Pages/TrainingCenter/refresher-training";
import IntroductionsAndExpectations from "../Pages/TrainingCenter/introductions-and-expectations";
import FacilitatingTrainingActivities from "../Pages/TrainingCenter/facilitating-training-activities";
import ManagingYourParticipantsAndClassroom from "../Pages/TrainingCenter/managing-your-participants-and-classroom";
import TeamTeachingStrategies from "../Pages/TrainingCenter/team-teaching-strategies";
import RecordedWebinars from "../Pages/TrainingCenter/recorded-webinars";
import ImplemeningCpiAtYourOrganization from "../Pages/TrainingCenter/implementing-cpi-at-your-organization";
import ProperUseOfTerms from "../Pages/TrainingCenter/ProperUseOfTerms";
import WriteYourOwnTrainingActivities from "../Pages/TrainingCenter/write-your-own-training-activities";
import TeachingSafetyInterventions from "../Pages/TrainingCenter/teaching-safety-interventions";
import IntroducingThirdEdition from "../Pages/TrainingCenter/introducing-3rd-edition";
import RisksOfPhysicalRestraints from "../Pages/TrainingCenter/risks-of-physical-restraints";
import NCIThirdEdition from "../Pages/TrainingCenter/nci-third-edition";
import NCISecondEdition from "../Pages/TrainingCenter/nonviolent-crisis-intervention-second-edition";
import VIThirdEdition from "../Pages/TrainingCenter/verbal-intervention-third-edition";
import VISecondEdition from "../Pages/TrainingCenter/verbal-intervention-second-edition";
import TrainingVideos from "../Pages/TrainingCenter/training-videos";
import { TrainingCenterBaseRoutes } from "./routes";
import Page from "../Pages/page";
import { CpiRoute } from "./routes";
import ProtectedRoute from "./protected-route";
import { Routes, Route } from "react-router-dom";
import { RoleEnum, TrainingCenterRoles } from "../Enums";
import { useTranslation } from "react-i18next";
import NoAccess from "../Pages/no-access";

import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

import { reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import SelectingATrainingTopic from "../Pages/TrainingCenter/selecting-a-training-topic";
import IdentifyingParticipantNeedsAndGoals from "../Pages/TrainingCenter/identifying-participant-needs-and-goals";
import OutliningActivityFormatAndTiming from "../Pages/TrainingCenter/outlining-activity-format-and-timing";
import SpecifyingPurposeDirectionsAndSetup from "../Pages/TrainingCenter/specifying-purpose-directions-and-setup";
import GuidingParticipantsToDebrief from "../Pages/TrainingCenter/guiding-participants-to-debrief";
import EvaluatingEffectiveness from "../Pages/TrainingCenter/evaluating-effectiveness";

const TrainingCenterRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => (

    <ProtectedRoute roles={TrainingCenterRoles} {...rest}>
        {children}
    </ProtectedRoute>
);

const createRoutes = (
    routesData:any,
    rac:any
) => {
    let tempArray:CpiRoute[] = [];

    for (let route in routesData) {
        tempArray.push(TrainingCenterBaseRoutes[route]);
    }

    return (
        <>
            {tempArray.map((route:any, i:number) => (
            // map over CpiRoute[]
            (rac(route)) ?
            <>
                // we meet the criteria for culture OR there are no access-culture requirements and we should have access to this page
                <Route key={i} path={route.path}
                    element={
                        <TrainingCenterRoute>
                          
                                <div>
                                    { route.pageId == "trainingCenter" &&
                                        <TrainingCenter />
                                    }

                                    { route.pageId == "evalAndPostTrainingResponsibilities" &&
                                        <EvaluationsAndPostTrainingResponsibilities />
                                    }

                                    { route.pageId == "customizationNewTermsAndTransitions" &&
                                        <CustomizationNewTermsTransitions />
                                    }

                                    { route.pageId == "qualityStandards" &&
                                        <QualityStandards />
                                    }

                                    { route.pageId == "dccQualityStandards" &&
                                        <DccQualityStandards />
                                    }

                                    { route.pageId == "baseOfEmployment" &&
                                        <BaseOfEmployment />
                                    }

                                    { route.pageId == "instructionsForCeBoardApproval" &&
                                        <CeBoardApproval />
                                    }

                                    { route.pageId == "choosingYourCertifiedInstructors" &&
                                        <ChoosingYourCertifiedInstructors />
                                    }

                                    { route.pageId == "communicatingInternally" &&
                                        <CommunicatingInternally />
                                    }

                                    { route.pageId == "afterVirtualTraining" &&
                                        <AfterVirtualTraining />
                                    }

                                    { route.pageId == "downloadResources" &&
                                        <DownloadResources />
                                    }

                                    { route.pageId == "cpiLogoBrandMaterials" &&
                                        <CpiLogoBrandMaterials />
                                    }

                                    { route.pageId == "preparingForTraining" &&
                                        <PreparingForTraining />
                                    }

                                    { route.pageId == "trainingEnvironmentSetup" &&
                                        <TrainingEnvironmentSetup />
                                    }

                                    { route.pageId == "virtualTraining" &&
                                        <VirtualTraining />
                                    }

                                    { route.pageId == "refresherTraining" &&
                                        <RefresherTraining />
                                    }

                                    { route.pageId == "introductionsAndExpectations" &&
                                        <IntroductionsAndExpectations />
                                    }

                                    { route.pageId == "facilitatingTrainingActivities" &&
                                        <FacilitatingTrainingActivities />
                                    }

                                    { route.pageId == "managingYourParticipantsAndClassroom" &&
                                        <ManagingYourParticipantsAndClassroom />
                                    }

                                    { route.pageId == "teamTeachingStrategies" &&
                                        <TeamTeachingStrategies />
                                    }

                                    { route.pageId == "recordedWebinars" &&
                                        <RecordedWebinars />
                                    }

                                    { route.pageId == "implementingCpiAtYourOrganization" &&
                                        <ImplemeningCpiAtYourOrganization />
                                    }

                                    { route.pageId == "properUseOfTerms" &&
                                        <ProperUseOfTerms />
                                    }

                                    { route.pageId == "writeYourOwnTrainingActivities" &&
                                        <WriteYourOwnTrainingActivities />
                                    }

                                    { route.pageId == "teachingSafetyInterventions" &&
                                        <TeachingSafetyInterventions />
                                    }

                                    { route.pageId == "introducingThirdEdition" &&
                                        <IntroducingThirdEdition />
                                    }
                                    
                                    { route.pageId == "selectingATrainingTopic" &&
                                        <SelectingATrainingTopic />
                                    }

                                    { route.pageId == "identifyingParticipantNeedsAndGoals" &&
                                        <IdentifyingParticipantNeedsAndGoals />
                                    }

                                    { route.pageId == "outliningActivityFormatAndTiming" &&
                                        <OutliningActivityFormatAndTiming />
                                    }

                                    { route.pageId == "specifyingPurposeDirectionsAndSetup" &&
                                        <SpecifyingPurposeDirectionsAndSetup />
                                    }

                                    { route.pageId == "guidingParticipantsToDebrief" &&
                                        <GuidingParticipantsToDebrief />
                                    }

                                    { route.pageId == "evaluatingEffectiveness" &&
                                        <EvaluatingEffectiveness />
                                    }

                                    { route.pageId == "risksOfPhysicalRestaints" &&
                                        <RisksOfPhysicalRestraints />
                                    }

                                    { route.pageId == "nciThirdEdition" &&
                                        <NCIThirdEdition />
                                    }

                                    { route.pageId == "verbalInterventionThirdEdition" &&
                                        <VIThirdEdition />
                                    }

                                    { route.pageId == "verbalInterventionSecondEdition" &&
                                        <VISecondEdition />
                                    }

                                    { route.pageId == "nonviolentCrisisInterventionSecondEdition" &&
                                        <NCISecondEdition />
                                    }

                                    { route.pageId == "trainingVideos" &&
                                        <TrainingVideos />
                                    }
                                </div>
                        
                        </TrainingCenterRoute>
                    }
                />
                <Route path="*"
                        element={
                        <ProtectedRoute>
                            {<NoAccess />}
                        </ProtectedRoute>                                
                    }
                />
            </>
            :
                // we do not meet the criteria for culture and should render no-access content
                <Route key={i} path={route.path}
                    element={
                        <ProtectedRoute>
                         
                                <>
                                    { /* render no-access page - pass in all access criteria and let no-access page component manage what to communicate to the user */ }
                                    { <NoAccess /> }
                                </>
                       
                        </ProtectedRoute>
                    }
                />               
            ))}
        </>
    );
};

const TrainingCenterRouting = () => {
    const { rac } = useRouteAccessCheck();

    return (
        <Routes>           
            { createRoutes(TrainingCenterBaseRoutes, rac) }
        </Routes>
    );
};

export default withAITracking(reactPlugin, TrainingCenterRouting);
