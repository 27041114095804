import * as React from 'react'; 
import { useTranslation } from 'react-i18next';

const useIncludesBySpecialtyCode = (specialtyCode:string) => {
    const { t } = useTranslation();

    switch (specialtyCode) {
        case "APS":
            return [t("VI")];
        case "CPI-DCC":
            return [t("DCC")];
        case "NCI":
            return [t("VI")];
        case "CPI":
            return [t("VI")];
        case "SIFA":
            return [t("VI")];
        case "SIFC":
            return [t("VI")];
        case "SIAA":
            return [t("SIF"), t("VI")];
        case "SIAC":
            return [t("SIF"), t("VI")];
        case "SIEA":
            return [t("SIA"), t("SIF"), t("VI")];
        case "SIEC":
            return [t("SIA"), t("SIF"), t("VI")];
        case "DCC":
            return [t("DCS")];
        default:
            return [];
    }
}

export default useIncludesBySpecialtyCode;