import * as React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../hooks/useParsedTranslation";

import OneColumnLayout from "../../Components/UI/UX-Layout/Tiles/one-column-layout";
import TwoColumnLayout from "../../Components/UI/UX-Layout/Tiles/two-column-layout";
import ThreeColumnLayout from "../../Components/UI/UX-Layout/Tiles/three-column-layout";

import Tile from "../../Components/UI/tile";
import SubHeaderBar from "../../Components/UI/Content-Pages/subheader-bar";
import SubHeaderUnderlined from "../../Components/UI/Content-Pages/subheader-underlined";
import GrayTile from "../../Components/UI/Content-Pages/gray-tile";

import FourByEight from "../../Components/UI/UX-Layout/Content/four-by-eight";
import EightByFour from "../../Components/UI/UX-Layout/Content/eight-by-four";
import SixBySix from "../../Components/UI/UX-Layout/Content/six-by-six";

const ProperUseOfTerms = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            {/* WRITE CODE BELOW THIS LINE */}

            <div className="page-content m-auto">
                <Container fluid className="page-padding">

                    <OneColumnLayout
                        center={
                            <>
                                {/* WRITE HTML BELOW THIS LINE */}
                                <>
                                    <h1 className="page-title-h1">Proper Use of Terms</h1>
                                </>
                            </>
                        }
                    />

                    <Tile title="Guidelines" hideTileHeader={false}>
                        <>
                            {/* WRITE HTML BELOW THIS LINE */}

                            <OneColumnLayout
                                center={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <p>CPI has several registered, trade and service marks (protected terms) that should appear the same way each time they are used in print or on the web. Please follow these guidelines when using CPI terminology with protected terms.</p>
                                        <ul>
                                            <li><span className="bold">Do not abbreviate protected terms</span> unless the abbreviation/initialism is itself a protected term. Use the full term and corresponding mark each time the term is used.</li>
                                            <li><span className="bold">Italicize all words in the protected term</span> to distinguish it from adjoining words in the sentence. If a protected term that is normally italicized appears in an italicized paragraph or sentence, then it should <em>not</em> be italicized.
                                                <ul>
                                                    <li>Use the corresponding registered symbol (®), trade mark symbol (TM), and service mark symbol (SM) with EVERY appearance of a protected term.</li>
                                                    <li>Make the ®, TM and SM superscript, at least two point sizes smaller than surrounding text, and not italicized.</li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </>
                                }
                            />

                            <TwoColumnLayout
                                left={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}

                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h4 className="bold h4 mb-0">CPI's Protected Terms (US)</h4>
                                            </>
                                        </GrayTile>
                                        <ul className="mt-3">
                                            <li><em >Beyond the Blue Card</em><sup>™</sup></li>
                                            <li>Blue Card<sup>®</sup></li>
                                            <li><em >Care, Welfare, Safety, and Security</em><sup >℠</sup></li>
                                            <li><em >Children’s Control Position</em><sup >℠</sup></li>
                                            <li><em >COPING Model</em><sup >℠</sup></li>
                                            <li><em >Crisis Development Model</em><sup >℠</sup></li>
                                            <li>Crisis Prevention Institute<sup >®</sup></li>
                                            <li> CPI<sup>®</sup></li>
                                            <li><em >Decision-Making Matrix</em><sup >℠</sup></li>
                                            <li>ICan<sup>℠</sup></li>
                                            <li><em >Nonviolent Crisis Intervention</em><sup >®</sup>, 3rd Edition Training</li>
                                            <li>NCI<sup>™</sup> 3rd Edition Training</li>
                                            <li><em >Opt-Out Sequence</em><sup >℠</sup></li>
                                            <li><em >Respect, Service, and Safety at Work</em><sup>®</sup></li>
                                            <li><em >RESPONSE Continuum</em><sup >℠</sup></li>
                                            <li><em >Supported Prone Position</em><sup >℠</sup></li>
                                            <li><em >Supportive Stance</em><sup >℠</sup></li>
                                            <li><em >Team Control Position</em><sup >℠</sup></li>
                                            <li><em >Transport Position</em><sup >℠</sup></li>
                                            <li><em >Verbal Escalation Continuum</em><sup >℠</sup></li>
                                            <li><em >Verbal Intervention</em><sup >™</sup>, 3rd Edition Training</li>
                                            <li><em >Warchol Best-Abilities Care Model</em><sup >℠</sup></li>
                                        </ul>
                                    </>
                                }

                                right={
                                    <>
                                        {/* WRITE HTML BELOW THIS LINE */}
                                        <GrayTile percentWidth={100}>
                                            <>
                                                {/* WRITE HTML BELOW THIS LINE */}
                                                <h4 className="h4 bold mb-0">Here are a few examples of how CPI protected terms appear in materials:</h4>
                                            </>
                                        </GrayTile>
                                        <p className="mt-3"><strong>Nonviolent Crisis Intervention<sup>®</sup></strong><strong >, 3rd Edition Training, NCI<sup>™</sup>&nbsp;3rd&nbsp;Edition Training</strong></p>

                                        <p><em>Nonviolent Crisis Intervention</em>®, 3rd Edition Training focuses on verbal de-escalation and early intervention, giving staff an effective framework for decision making and problem solving. By teaching safe disengagements and restrictive interventions that can be implemented with the least use of force, NCI™ 3rd Edition Training provides the safest environment for all parties involved.</p>

                                        <p><em><strong>Verbal Intervention</strong></em><strong><sup>™</sup>, 3rd Edition Training</strong></p>

                                        <p>Ideal for organizations with a hands-off policy, and staff who don’t experience the kind of higher risk situations that require restrictive interventions, <em>Verbal Intervention</em><sup>™</sup>, 3rd Edition Training instills the confidence and skills to verbally de-escalate disruptive behaviors and prevent further escalation.</p>

                                        <p><em><strong>Crisis Development Model</strong></em><strong><sup>℠</sup></strong></p>

                                        <p><em>Dementia Capable Care</em>, 2nd Edition Training combines the Allen Cognitive Levels, person-centered care, and the <em>Crisis Development Model</em><sup>℠</sup> to empower care partners to prevent and de-escalate distress behaviors.</p>
                                    </>
                                }
                            />

                        </>
                    </Tile>


                </Container>

            </div>
        </>
    );
};

export default ProperUseOfTerms;
