import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createEmailTemplate, updateEmailTemplate } from "../../ApiServices/OrganizationSettings";

import { OrgEmailTemplateModel } from "../../Interfaces";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface CreateEmailTemplateFormProps {
    isCreateForm?: boolean;
    existingTemplate?: OrgEmailTemplateModel;
    isVisible: boolean;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
    onHide: () => void;
    existingTemplates: OrgEmailTemplateModel[];
}

const EmailTemplateForm = ({
    existingTemplate,
    isCreateForm,
    isVisible,
    onSave,
    onHide,
    existingTemplates
}: CreateEmailTemplateFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [emailTemplate, setEmailTemplate] = React.useState<OrgEmailTemplateModel>(
        isCreateForm
            ? {
                  OrgEmailTemplateId: -1,
                  Type: 1,
                  TemplateId: "",
                  Active: true,
                  CreateDate: ""
              }
            : existingTemplate
    );
    const [validEmailTemplate, setValidEmailTemplate] = React.useState(true);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        try {
            const validEmailTemplate = !existingTemplates.some(
                (template) =>
                    template.Type === emailTemplate.Type && template.OrgEmailTemplateId !== emailTemplate.OrgEmailTemplateId
            );
            setValidEmailTemplate(validEmailTemplate);
            if (form.checkValidity() && validEmailTemplate) {
                setSaving(true);
                const resp = isCreateForm
                    ? await createEmailTemplate(emailTemplate)
                    : await updateEmailTemplate(emailTemplate);
                setSaving(false);
                onSave(resp);
            }

            setValidated(true);
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }       
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Create Reminder")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group as={Row} controlId="email-template-name">
                        <Form.Label column sm="2">
                            {t("Email Type")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                custom
                                required
                                isInvalid={!validEmailTemplate}
                                value={emailTemplate.Type}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setEmailTemplate({
                                        ...emailTemplate,
                                        Type: parseInt(e.target.value)
                                    })
                                }
                            >
                                <option value="1">{t("Enrollment")}</option>
                                <option value="2">{t("Purchase")}</option>
                                <option value="5">{t("Reset Password")}</option>
                            </Form.Control>
                            {!validEmailTemplate && validated && (
                                <Form.Control.Feedback type="invalid">
                                    {t("A template of this type already exists.")}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="email-template-templateId">
                        <Form.Label column sm="2">
                            {t("SendGrid Template")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                maxLength={255}
                                value={emailTemplate.TemplateId}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setEmailTemplate({ ...emailTemplate, TemplateId: e.target.value })
                                }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="active">
                        <Form.Label column sm="2">
                            {t("Active")}
                        </Form.Label>
                        <Col>
                            <Form.Check
                                custom
                                type="switch"
                                id="active"
                                label=" "
                                className="col-form-label"
                                checked={emailTemplate.Active}
                                onChange={() => setEmailTemplate({ ...emailTemplate, Active: !emailTemplate.Active })}
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, EmailTemplateForm);
