import * as React from "react";

import MyCertificationStatus from "./my-certification-status";
import MyCertificationSummary from "./my-certification-summary";
import CITaskBlock from "../../../../Components/UI/ci-task-block";
import Enrollment from "./enrollment";

import AlertLevelString from "../../../../Interfaces/Platform/AlertLevelString";
import { CertStatusEnum } from "../../../../Enums/CertStatusEnum";

import { Trans, useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../configuration-context";
import { RbacContext } from "../../../../rbac-context";
import useMonthLabelByMonthCode from "../../../../hooks/useMonthLabelByMonthCode";
import { getToken } from "../../../../helper-functions";

const Certification = (props: {
    certificationModel:any,
    addPageLevelAlert:any,
    todaysDate:any
}) => {

    const { t } = useTranslation();
    const { month } = useMonthLabelByMonthCode();
    
    const [enrollmentsData, setEnrollmentsData] = React.useState(null);

    const createEnrollments = (
        enrollmentsData:any
    ) => {
        return (
            <>
                {enrollmentsData.map((enrollment:any, i:number) => (
                    <Enrollment todaysDate={props.todaysDate} enrollmentData={enrollment} addPageLevelAlert={props.addPageLevelAlert} />
                ))}
            </>
        );
    };

    //
    // SETTING CONFIGCONTEXT
    //
    const configContext = React.useContext(ConfigContext);
    const rbac = React.useContext(RbacContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;

    const [renewalAlertLevel, setRenewalAlertLevel] = React.useState<AlertLevelString>({ alertLevel: "none" });
    const [renewalAlertLabel, setRenewalAlertLabel] = React.useState<string>("");
    const [renewalSubtitle, setRenewalSubtitle] = React.useState<string>("");
    const [renewalIsComplete, setRenewalIsComplete] = React.useState<boolean>(false);

    const [documentClassesAlertLevel, setDocumentClassesAlertLevel] = React.useState<AlertLevelString>({ alertLevel: "none" });
    const [documentClassesAlertLabel, setDocumentClassesAlertLabel] = React.useState<string>("");
    const [documentClassesIsComplete, setDocumentClassesIsComplete] = React.useState<boolean>(false);

    React.useEffect(() => {

        if (configContext?.SystemConfiguration?.ApimKey && rbac.userContext.PingId && props.certificationModel.specialty) {

            fetch(apimBaseUrl + "/Q/Enrollment/Certification", {
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "content-type": "application/json",
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: JSON.stringify({userId: rbac.userContext.PingId, specialty: props.certificationModel.specialty})
                
            })
                .then(resp => resp.json())
                .then(function(data) {

                    if (typeof data == "string") {
                        data = null;
                    }

                    setEnrollmentsData(data);
                })
                .catch(function(error) {
                    console.log(JSON.stringify(error));
                });   
            
        }

    }, [props.certificationModel]);

    // Updates renewalAlertLevel whenever certificationModel changes
    React.useEffect(() => {
        const now = props.todaysDate;
        const renewalDate = new Date(props.certificationModel.renewBy);
        const renewalDatePlus90Days = new Date(props.certificationModel.renewBy);
        renewalDatePlus90Days.setDate(renewalDatePlus90Days.getDate() + 90);

        const nowInDays = Math.ceil(now.getTime() / 1000 / 60 / 60 / 24);
        const renewalDateInDays = Math.ceil(renewalDate.getTime() / 1000 / 60 / 60 / 24);

        // if we are ahead of (or on) the renewal date AND attendedRenewal is false
        if ((nowInDays <= renewalDateInDays) && (!props.certificationModel.attendedRenewal)) {
            // we are ahead of renewal date
            // now check for how soon
        
            if ((nowInDays + 31) > renewalDateInDays) {
                // we are within 30 days of renewal deadline
                setRenewalAlertLevel({ alertLevel: "error" });
                setRenewalAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining"));

                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining") }, alertLevel: {alertLevel:"error"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Alerts.CertificationWillExpireAccess"
                values={{ specialtyName: props.certificationModel.specialty, string0: t('dynamicDate', { val: renewalDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }} />, canDismiss: false });
            } else if ((nowInDays + 61) > renewalDateInDays) {
                // we are within 60 days of renewal deadline
                setRenewalAlertLevel({ alertLevel: "warning" });
                setRenewalAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.RegisterForRenewalTraining"));
                
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.MyCertification.SeeAvailableTraining") }, alertLevel: {alertLevel:"warning"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Alerts.CertificationWillExpire"
                values={{ specialtyName: props.certificationModel.specialty, string0: t('dynamicDate', { val: renewalDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }} />, canDismiss: true });
            } else if ((nowInDays + 91) > renewalDateInDays) {
                // we are within 90 days of renewal deadline
                setRenewalAlertLevel({ alertLevel: "none" });
                setRenewalAlertLabel(t("Time-To-Register-For-Your-Training"));
                
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.MyCertification.SeeAvailableTraining") }, alertLevel: {alertLevel:"warning"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Alerts.CertificationWillExpire"
                values={{ specialtyName: props.certificationModel.specialty, string0: t('dynamicDate', { val: renewalDate, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }} />, canDismiss: true });
            } else {
                // we are well ahead of the renewal date
                setRenewalIsComplete(true);
                setRenewalSubtitle(t("CPI.MyAccount.Dashboard.MyCertification.PrintYourCertificate"));
            }
        } else if ((nowInDays > renewalDateInDays) && (!props.certificationModel.attendedRenewal)) {
            // we are past due on renewal AND attendedRenewal is false

            if (nowInDays < (renewalDateInDays + 91)) {
                // we are within 90 day period beyond renewal date
                setRenewalAlertLevel({ alertLevel: "error" });
                setRenewalAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining"));
                
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining") }, alertLevel: {alertLevel:"error"}, description: <Trans
                defaults="CPI.MyAccount.Dashboard.Alerts.CertificationWillExpireAccess"
                values={{ specialtyName: props.certificationModel.specialty, string0: t('dynamicDate', { val: renewalDatePlus90Days, formatParams: { val: { month: 'short', day: 'numeric', year: 'numeric' } } }) }} />, canDismiss: false });
            } else if ((props.certificationModel.status) && (props.certificationModel.status.toLowerCase() != CertStatusEnum.Active)) {
                // SET INACTIVE
                props.addPageLevelAlert({ arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.MyCertification.RegisterForTraining") }, alertLevel: {alertLevel:"error"}, description: t("CPI.MyAccount.Dashboard.OrderMaterials.CurrentlyMarkedInactive"), canDismiss: false });              
            }
        } else {
            // will only hit this if attendedRenewal flag is true
            setRenewalIsComplete(true);
            setRenewalSubtitle(t("CPI.MyAccount.Dashboard.MyCertification.PrintYourCertificate"));
        }

    }, [props.certificationModel]);

    // Updates documentClassesAlertLevel whenever certificationModel changes
    React.useEffect(() => {
        const now = props.todaysDate;
        const sixMonthsFromNow = new Date();
        sixMonthsFromNow.setMonth(now.getMonth() + 6);
        const certificationEndDate = new Date(props.certificationModel.endDate);
        const certificationStartDate = new Date(props.certificationModel.startDate);

        const renewalDate = (props.certificationModel.renewBy) ? new Date(props.certificationModel.renewBy) : new Date(props.certificationModel.endDate);
        const renewalDatePlus90Days = renewalDate;
        renewalDatePlus90Days.setDate(renewalDatePlus90Days.getDate() + 90);

        const nowInDays = Math.ceil(now.getTime() / 1000 / 60 / 60 / 24);
        const sixMonthsFromNowInDays = Math.ceil(sixMonthsFromNow.getTime() / 1000 / 60 / 60 / 24);
        const certificationEndDateInDays = Math.ceil(certificationEndDate.getTime() / 1000 / 60 / 60 / 24);
        const certificationStartDateInDays = Math.ceil(certificationStartDate.getTime() / 1000 / 60 / 60 / 24);
        // if we are ahead of (or on) the end date
        if ((nowInDays <= certificationEndDateInDays) && (props.certificationModel.totalCycleClasses < props.certificationModel.classesNeeded)) {
            // we are ahead of end date
            // now check for how soon

            if (((nowInDays + 91) > certificationEndDateInDays)) {
                // we are within 90 days of end date
                setDocumentClassesAlertLevel({ alertLevel: "warning" });
                setDocumentClassesAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.RememberToDocumentTraining"));
                props.addPageLevelAlert({ 
                    arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.TrainingCenter.DocumentTraining") }, 
                    alertLevel: { alertLevel:"warning" },
                    description: <Trans defaults="CPI.MyAccount.Dashboard.Alerts.DocumentRecommended"/>,
                    canDismiss: true 
                });
            } else if ((certificationStartDateInDays + 182) < nowInDays && (props.certificationModel.totalCycleClasses < props.certificationModel.classesNeeded)) {
                setDocumentClassesAlertLabel(t("CPI.MyAccount.Dashboard.TrainingCenter.DocumentTraining"));
                setDocumentClassesAlertLevel({ alertLevel: "warning" });
                props.addPageLevelAlert({
                    arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.TrainingCenter.DocumentTraining") }, 
                    alertLevel: { alertLevel: "warning" },
                    description: <Trans
                        defaults="CPI.MyAccount.Dashboard.Alerts.MustDocument"
                        values={{
                            specialtyName: props.certificationModel.specialty,
                            date: t('dynamicDate', {
                                val: renewalDate, 
                                formatParams: {
                                    val: { 
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric'
                                    }
                                }
                            })
                        }} />,
                    canDismiss: false
                });
            }

        } else if (props.certificationModel.totalCycleClasses < props.certificationModel.classesNeeded) {

            // we are past end date but within the 90 day grace period
            if (nowInDays < (certificationEndDateInDays + 91)) {
                setDocumentClassesAlertLevel({ alertLevel: "error" });
                setDocumentClassesAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.DocumentYourTraining"));
                props.addPageLevelAlert({ 
                    arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.TrainingCenter.DocumentTraining") }, 
                    alertLevel: { alertLevel:"error" },
                    description: <Trans
                        defaults="CPI.MyAccount.Dashboard.Alerts.CertificationWillExpireAccess"
                        values={{
                            specialtyName: props.certificationModel.specialty,
                            string0: t('dynamicDate', {
                                val: renewalDatePlus90Days, 
                                formatParams: {
                                    val: { 
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric'
                                    }
                                }
                            })
                        }} />,
                    canDismiss: false
                });
            } else {
                // INACTIVE
                setDocumentClassesAlertLevel({ alertLevel: "error" });
                setDocumentClassesAlertLabel(t("CPI.MyAccount.Dashboard.MyCertification.DocumentYourTraining"));
                props.addPageLevelAlert({
                    arrowLink: { href: "#", label: t("CPI.MyAccount.Dashboard.TrainingCenter.DocumentTraining") },
                    alertLevel: { alertLevel:"error" },
                    description: 
                    <Trans
                        defaults="CPI.MyAccount.Dashboard.Alerts.MembershipFeeInactive"
                    />,
                    canDismiss: false
                });
            }
        } else {
            setDocumentClassesIsComplete(true);
        }
    }, [props.certificationModel]);

    return (
        <>
            <MyCertificationSummary certTitle={props.certificationModel.specialty} additives={props.certificationModel.qualifications} />
            <MyCertificationStatus status={props.certificationModel.status} startDate={new Date(props.certificationModel.startDate)} endDate={new Date(props.certificationModel.endDate)} />
            <CITaskBlock title={t("CPI.MyAccount.Dashboard.MyCertification.CompleteInstructorTraining")} subtitle={renewalSubtitle} alertLevel={renewalAlertLevel} alertTitle={renewalAlertLabel} complete={renewalIsComplete} />

            { enrollmentsData &&
                createEnrollments(enrollmentsData)
            }

            <CITaskBlock complete={documentClassesIsComplete} alertLevel={documentClassesAlertLevel} alertTitle={documentClassesAlertLabel} title={t("Document-4-Classes")} subtitle={t("Recommended-2-Per-Year")} />
        </>
    )
};

export default Certification;