import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import { useContext } from "react";
import { ConfigContext } from "../configuration-context";
import { getToken } from "../helper-functions";
import * as React from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";

export interface TypeaheadSearchProps {
    id: string;
    placeholder: string;
    labelKey: any;
    searchUrl: string;
    itemRenderer: (option: any, props: any, index: number) => React.ReactElement;
    onChange: (item: any) => void;
    minLength?: number;
    method?: string;
    searchBody?: string;
}

interface TypeaheadSearchState {
    allowNew: boolean;
    isLoading: boolean;
    multiple: boolean;
    options: any[];
}

const TypeaheadSearch = (props: TypeaheadSearchProps) => {    

    const configContext = useContext(ConfigContext);
    const [state, setState] = React.useState<TypeaheadSearchState>(
    {
        allowNew: false,
        isLoading: false,
        multiple: false,
        options: []
    });

    const makeAndHandleRequest = (query: string) => {
        const { searchUrl } = props;
        
        if (props.method == "POST") {
            const defaultOptions = {
                method: props.method,
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                    "Authorization": `Bearer ${getToken("accessToken")}`
                },
                body: props.searchBody.replace("{query}", query), 
            };

            return fetch(searchUrl, defaultOptions).then((resp) => resp.json());
        }

        const defaultOptions = {
            method: props.method ?? "GET",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": configContext?.SystemConfiguration?.ApimKey,
                "Authorization": `Bearer ${getToken("accessToken")}`
            },
        };
        return fetch(searchUrl + query, defaultOptions).then((resp) => resp.json());
    };

    const _handleSearch = (query: string) => {
        setState({ ...state, isLoading: true });
        makeAndHandleRequest(query).then((options) => {
            setState({
                ...state,
                isLoading: false,
                options: options
            });
        });
    };

    const blur = () => {
        // @ts-ignore
       _typeahead.getInstance().blur();
    };
    
    const clear = () => {
        // @ts-ignore
        _typeahead.getInstance().clear();
    };
    
    const focus = () => {
        // @ts-ignore
        _typeahead.getInstance().focus();
    };

    const { id, placeholder, labelKey, itemRenderer, onChange, minLength } = props;

    return (
        <AsyncTypeahead
            id={id}
            {...state}
            minLength={minLength ?? 3}
            filterBy={() => true} // server side filtering
            onSearch={_handleSearch}
            placeholder={placeholder}
            labelKey={labelKey}
            renderMenuItemChildren={itemRenderer}
            onChange={(items) => {
                if (items.length === 1) {
                    // @ts-ignore
                    onChange(items);
                }
            }}
            autoFocus={true}
            clearButton={true}
            delay={500}
            highlightOnlyResult={true}
        />
    );
}

export default TypeaheadSearch;
