import { Col, Row } from "react-bootstrap";
import TextInput from "../../../Components/Form/text-input";
import Button from "../../../Components/UI/button";
import React, { useEffect, useState } from "react";
import type { KeyboardEvent } from "react";
import { FiltersTemplate, SearchParams } from "../../../Interfaces/Platform/GenericFilter";
import Select, { components, DropdownIndicatorProps } from "react-select";
import FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";


const CalendarDropdownIndicator = (props: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...props}>
        <i className="fa-sharp fa-solid fa-calendar generic-filter-select-icon" />
    </components.DropdownIndicator>
);

const ChevronDropdownIndicator = (props: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...props}>
        <i className="fa-sharp fa-light fa-chevron-down generic-filter-select-icon" />
    </components.DropdownIndicator>
);

const GenericFilter = ({
    parentTrigger,
    filtersTemplate,
    sortByOptions,
    statusOptions,
    percentageRecordedOptions,
    programOptions,
    searchLabel,
    instructorOptions,
    expirationDateOptions,
    lastTrainedOptions,
    organizationOptions,
    showClearFilter,
    setSearchParams,
    onSearch,
    updateFilter,
    clearFilters
}: SearchParams): JSX.Element => {
    const { isMobile } = useDetectWindowSize();
    const [showFilters, setShowFilters] = useState(false);
    const [templateFilters, setTemplateFilters] = useState<FiltersTemplate>(filtersTemplate ?? FiltersTemplate.Classes);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('date');
    const [percentageRecorded, setPercentageRecorded] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [program, setProgram] = useState<string>('');
    const [instructor, setInstructor] = useState<string>('');
    const [expirationDate, setExpirationDate] = useState<string>('');
    const [lastTrained, setLastTrained] = useState<string>('');
    const [organization, setOrganization] = useState<string>('');
    useEffect(() => {
        if(templateFilters){
            setTemplateFilters(filtersTemplate);
        }
      }, []);

    useEffect(() => {
    if(parentTrigger){
        clearFilterHandler();
    }
    }, [parentTrigger]);

    const onSelectExpirationDate = (selectedDate: string) => {
       setExpirationDate(selectedDate);
       updateFilter('expirationDate', selectedDate);
    };

    const onSelectLastTrainedDate = (selectedDate: string) => {
        setLastTrained(selectedDate);
        updateFilter('lastTrained', selectedDate);
    };

    const onSearchHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSearch();
    };

    const clearFilterHandler = () => {
        setSearchTerm('');
        setSortBy('date');
        setPercentageRecorded('all');
        setStatus('all');
        setProgram('all');
        setInstructor('all');
        setExpirationDate('all');
        setLastTrained('all');
        setOrganization('all');
        clearFilters();
    };

    const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onSearch();
        }
    };

    return (
        <>
            <div className="m-auto generic-filter-container">
                <div className="generic-filter-row">
                    <div className="generic-filter-search">
                        <div>
                            <TextInput
                                isRequired={false}
                                label={`${searchLabel ?? "Search"}`}
                                name="searchTerm"
                                id="searchTerm"
                                value={searchTerm}
                                overrideOptionalLabel=" "
                                onKeyDown={(e) => handleEnterKey(e)}
                                changeAction={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchTerm(e.target.value);
                                    updateFilter('searchTerm', e.target.value);
                                }}
                            />
                        </div>
                        <Button elementId="go-button" isSolid={false} label="Go" additionalStyleClasses="btn-outline-primary align-self-end" clickAction={onSearchHandler}/>
                    </div>
                    <div className="generic-filter-sortBy">
                        <label htmlFor="sortBy" className="semi-bold fs14 form-label">Sort By</label>
                        <Select
                            name="sortBy"
                            id="sortBy"
                            className="mt-1"
                            isSearchable={false}
                            menuPortalTarget={document.body}
                            classNames={{
                                menuPortal: () => 'generic-filter-menu',
                                option: (state) => {
                                    if (state.isSelected) return 'generic-filter-menu-option-selected';
                                    if (state.isFocused) return 'generic-filter-menu-option-focused';
                                },
                                indicatorSeparator: () => 'generic-filter-select-separator'
                            }}
                            components={{ DropdownIndicator: ChevronDropdownIndicator }}
                            options={sortByOptions}
                            defaultValue={sortByOptions[0]}
                            onChange={(o: FormSelectOption) => {
                                setSortBy(o.value);
                                updateFilter('sortBy', o.value);
                            }}
                            value={
                                sortBy !== null 
                                ? sortByOptions.filter((o) => o.value === sortBy)[0] 
                                : sortByOptions.filter(o => o.default)[0]
                            }
                        />
                    </div>
                </div>
                <div>
                    <div className="generic-filter-actions-row mt-3">
                        <div onClick={() => setShowFilters(!showFilters)} className="">
                            <span className="fs14 link-filter-button">
                                <i className="fa-solid fa-bars-filter px-1" />
                                {showFilters ? "Hide" : "Show"} Filters
                            </span>
                        </div>
                        <div className="">
                            {showClearFilter && (
                                <div onClick={() => clearFilterHandler()} className="">
                                    <span className="fs14 link-filter-button">
                                        <i className="fa-solid fa-xmark px-1"></i> Clear Filters
                                    </span>
                                </div>
                            )} 
                        </div>
                    </div>
                    {
                    showFilters && (
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="generic-filter-options-row mt-2 d-flex flex-wrap flex-xs-wrap flex-sm-wrap flex-md-wrap">
                                { templateFilters == FiltersTemplate.Classes ? (
                                    percentageRecordedOptions && percentageRecordedOptions.length > 0 && (
                                        <div className="mt-2 mr-2 generic-filter-width-recorded">
                                            <label htmlFor="percentageRecorded" className="bold form-label fs14">% RECORDED</label>
                                            <Select
                                                name="percentageRecorded"
                                                id="percentageRecorded"
                                                menuPortalTarget={document.body}
                                                classNames={{
                                                    menuPortal: () => 'generic-filter-menu',
                                                    option: (state) => {
                                                        if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                        if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                    },
                                                    indicatorSeparator: () => 'generic-filter-select-separator'
                                                }}
                                                components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                                options={percentageRecordedOptions}
                                                defaultValue={percentageRecordedOptions[0]}
                                                onChange={(o: FormSelectOption) => {
                                                    setPercentageRecorded(o.value);
                                                    updateFilter('percentageRecorded', o.value);
                                                }}  
                                                value={
                                                    percentageRecorded != null 
                                                    ? percentageRecordedOptions.filter(o => o.value == percentageRecorded )[0] 
                                                    : percentageRecordedOptions.filter(o => o.default)[0] 
                                                }                               
                                            />
                                        </div>
                                    )
                                ) : (
                                    <div className="mt-2 mr-2 generic-filter-width-status">
                                        <label htmlFor="status" className="bold form-label fs14">STATUS</label>
                                        <Select
                                            name="status"
                                            id="status"
                                            isSearchable={false}
                                            classNames={{
                                                indicatorSeparator: () => 'generic-filter-select-separator',
                                                option: (state) => {
                                                    if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                    if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                }
                                            }}
                                            components={{ DropdownIndicator: ChevronDropdownIndicator}}
                                            options={statusOptions}
                                            defaultValue={statusOptions[0]}
                                            onChange={(o: FormSelectOption) => {
                                                setStatus(o.value);
                                                updateFilter('status', o.value);
                                            }}  
                                            value={
                                                status != null 
                                                ? statusOptions.filter(o => o.value == status )[0] 
                                                : statusOptions.filter(o => o.default)[0] 
                                            }                               
                                        />
                                    </div>
                                )} 
                                {programOptions && programOptions.length > 2 && (                               
                                <div className="mt-2 mr-2 generic-filter-width-program">
                                    <label htmlFor="program" className="bold form-label fs14">PROGRAM</label>
                                    <Select
                                        name="program"
                                        id="program"
                                        menuPortalTarget={document.body}
                                        classNames={{
                                            menuPortal: () => 'generic-filter-menu',
                                            option: (state) => {
                                                if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                if (state.isFocused) return 'generic-filter-menu-option-focused';
                                            },
                                            indicatorSeparator: () => 'generic-filter-select-separator'
                                        }}
                                        components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                        options={programOptions}
                                        defaultValue={programOptions[0]}
                                        onChange={(o: FormSelectOption) => {
                                            setProgram(o.value);
                                            updateFilter('program', o.value);
                                        }}  
                                        value={
                                            program != null 
                                            ? programOptions.filter(o => o.value == program )[0] 
                                            : programOptions.filter(o => o.default)[0] 
                                        }                               
                                    />
                                </div>)
                                }
                                {instructorOptions && instructorOptions.length > 2 && (
                                    <div className="mt-2 mr-2 generic-filter-width-instructor">
                                        <label htmlFor="instructor" className="bold form-label fs14">INSTRUCTOR</label>
                                        <Select
                                            name="instructor"
                                            id="instructor"
                                            menuPortalTarget={document.body}
                                            classNames={{
                                                menuPortal: () => 'generic-filter-menu',
                                                option: (state) => {
                                                    if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                    if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                },
                                                indicatorSeparator: () => 'generic-filter-select-separator'
                                            }}
                                            components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                            options={instructorOptions}
                                            defaultValue={instructorOptions[0]}
                                            onChange={(o: FormSelectOption) => {
                                                setInstructor(o.value);
                                                updateFilter('instructor', o.value);
                                            }}  
                                            value={
                                                instructor != null 
                                                ? instructorOptions.filter(o => o.value == instructor )[0] 
                                                : instructorOptions.filter(o => o.default)[0] 
                                            }                               
                                        />
                                    </div>
                                )}
                                { templateFilters == FiltersTemplate.Participants && (
                                    
                                        <div className="mt-2 mr-2 generic-filter-width-date">
                                            <label htmlFor="expirationDate" className="bold form-label fs14">Expiration Date</label>
                                            <Select
                                                name="expirationDate"
                                                id="expirationDate"
                                                isSearchable={false}
                                                classNames={{
                                                    indicatorSeparator: () => 'generic-filter-select-separator',
                                                    option: (state) => {
                                                        if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                        if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                    }
                                                }}
                                                components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                                options={expirationDateOptions}
                                                defaultValue={expirationDateOptions[0]}
                                                onChange={(o: FormSelectOption) => {
                                                    onSelectExpirationDate(o.value);
                                                }}
                                                value={
                                                    lastTrained !== null 
                                                    ? expirationDateOptions.filter((o) => o.value === expirationDate)[0] 
                                                    : expirationDateOptions.filter(o => o.default)[0]
                                                }
                                            />
                                        </div>
                                    )}

                                { lastTrainedOptions && lastTrainedOptions.length > 1 && templateFilters == FiltersTemplate.Participants && (
                                        <div className="mt-2 generic-filter-width-date">
                                            <label htmlFor="expirationDate" className="bold form-label fs14">Last Trained</label>
                                            <Select
                                                name="lastTrained"
                                                id="lastTrained"
                                                isSearchable={false}
                                                classNames={{
                                                    indicatorSeparator: () => 'generic-filter-select-separator',
                                                    option: (state) => {
                                                        if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                        if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                    }
                                                }}
                                                components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                                options={lastTrainedOptions}
                                                defaultValue={lastTrainedOptions[0]}
                                                onChange={(o: FormSelectOption) => {
                                                    onSelectLastTrainedDate(o.value);
                                                }}
                                                value={
                                                    lastTrained !== null 
                                                    ? lastTrainedOptions.filter((o) => o.value === lastTrained)[0]
                                                    : lastTrainedOptions.filter(o => o.default)[0]
                                                }
                                            />
                                        </div>
                                )}
                                {organizationOptions && organizationOptions.length > 2 && (
                                    <div className="mt-2 mr-2 generic-filter-width-organization">
                                        <label htmlFor="organization" className="bold form-label fs14">ORGANIZATION</label>
                                        <Select
                                            name="organization"
                                            id="organization"
                                            menuPortalTarget={document.body}
                                            classNames={{
                                                menuPortal: () => 'generic-filter-menu',
                                                option: (state) => {
                                                    if (state.isSelected) return 'generic-filter-menu-option-selected';
                                                    if (state.isFocused) return 'generic-filter-menu-option-focused';
                                                },
                                                indicatorSeparator: () => 'generic-filter-select-separator'
                                            }}
                                            components={{ DropdownIndicator: ChevronDropdownIndicator }}
                                            options={organizationOptions}
                                            defaultValue={organizationOptions[0]}
                                            onChange={(o: FormSelectOption) => {
                                                setOrganization(o.value);
                                                updateFilter('organization', o.value);
                                            }}  
                                            value={
                                                organization != null
                                                  ? organizationOptions.filter(o => o.value == organization )[0]
                                                  : organizationOptions.filter(o => o.default)[0]
                                              }
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>  
                    )}
                </div>
            </div>
        </>
    );
};

export default GenericFilter;