import * as React from "react";
import Select from "../../../Components/Form/select";
import TextArea from "../../../Components/Form/text-area";
import DateInput from "../../../Components/Form/date-input";
import Radio from "../../../Components/Form/radio/radio";
import type { CourseData, OrgLmsSettings } from "../../../Interfaces/Platform/Classes";
import { useTranslation } from "react-i18next";
import ProperUseOfTerms from "../../TrainingCenter/ProperUseOfTerms";

interface Props {
    trainingFormats: any[];
    editable?: boolean;
    courses: CourseData[];
    orgSeats: { label: string, value: string }[];
    orgLmsSettings: OrgLmsSettings;
    onChangeFormat: (e: any) => void;
    handleChangeClassroomLanguage: any;
    handleChangeClassroomIndustry: any;
    handleChangeClassroomOrganization: any;
    handleChangeVirtualOrganization: any;
    handleChangeClassroomFormat: any;
    handleChangeClassroomFormatBlended: any;
    handleChangeLmsSelection: any;
    handleChangeLmsQualificationRadio: any;
    formData: any;
    errors: any;
    isMigrated: boolean;
}
const TrainingFormats = (props: Props) => {
    props = { editable: false, ...props };
    const [currentLanguage, setCurrentLanguage] = React.useState(props.formData?.classroomLanguage || null);
    const [languages, setLanguages] = React.useState<any[]>([]);
    const [industries, setIndustries] = React.useState<any[]>([]);
    const { t } = useTranslation();

    // Getting languages and displaying unique only
    let uniqueLanguages = [...new Set(props.courses.map((i) => i.course.language))];

    // Getting industries of language(s) and displaying unique only
    let uniqueIndustries = [...new Set(props.courses.filter((i) => i.course.language === currentLanguage).map((i) => i.course.courseObjects.map((x) => x.industryAbbreviation)).flat())];
    let industriesObjects = props.courses.filter((i) => i.course.language === currentLanguage).map((i) => i.course.courseObjects).flat();

    const getLanguages = () => {
        uniqueLanguages = [...new Set(props.courses.map((i) => i.course.language))];
        const languageOptions = uniqueLanguages && uniqueLanguages.length > 0 ? [{ label: t("Select"), value: null }, ...uniqueLanguages.map((i) => {
            return { label: i, value: i };
        })] : [{ label: t("Select"), value: null }];
        setLanguages(languageOptions);
    };

    React.useEffect(() => {
        if (!props.editable) {
            setCurrentLanguage(null);
            props.handleChangeClassroomLanguage({ value: null });
            props.handleChangeClassroomIndustry({ value: null });
            props.handleChangeClassroomOrganization({ value: null });
            if (uniqueLanguages && uniqueLanguages.length === 1) {
                setCurrentLanguage(uniqueLanguages[0]);
                props.handleChangeClassroomLanguage({ value: uniqueLanguages[0] });
                if (uniqueIndustries && uniqueIndustries.length === 1) {
                    props.handleChangeClassroomIndustry({ value: uniqueIndustries[0] });
                }
            } else {
                getLanguages();
            }
        } else {
            getLanguages();
            getIndustries();
            if (uniqueLanguages && uniqueLanguages.length === 1) {
                setCurrentLanguage(uniqueLanguages[0]);
                props.handleChangeClassroomLanguage({ value: uniqueLanguages[0] });
                if (uniqueIndustries && uniqueIndustries.length === 1) {
                    props.handleChangeClassroomIndustry({ value: uniqueIndustries[0] });
                }
            } else {
                getLanguages();
                const industry = props.formData.classroomIndustry;
            }
        }
    }, [props.courses, props.trainingFormats, props.editable]);

    const getIndustries = () => {
        uniqueIndustries = [...new Set(props.courses.filter((i) => i.course.language === currentLanguage).map((i) => i.course.courseObjects.map((x) => x.industryAbbreviation)).flat())];
        industriesObjects = props.courses.filter((i) => i.course.language === currentLanguage).map((i) => i.course.courseObjects).flat();

        const industryOptions = uniqueIndustries ? [{ label: t("Select"), value: null }, ...uniqueIndustries.map((i) => {
            return { label: industriesObjects.find((x) => x.industryAbbreviation === i).industry, value: i };
        })] : [{ label: t("Select"), value: null }];
        setIndustries(industryOptions);
    };

    React.useEffect(() => {
        if (currentLanguage && currentLanguage !== "") getIndustries();
    }, [currentLanguage, props.courses]);

    React.useEffect(() => {
        if (industries && industries.length === 2) {
            props.handleChangeClassroomIndustry({ value: uniqueIndustries[0] });
        }
    }, [industries]);

    React.useEffect(() => {
        if (props.formData.classroomLanguage === null || props.formData.classroomIndustry === null) {
            props.handleChangeClassroomOrganization({ value: null });
        }
    }, [props.formData.classroomLanguage, props.formData.classroomIndustry]);

    const handleLanguage = (e: { value: string }) => {
        setCurrentLanguage(e.value);
        props.handleChangeClassroomLanguage(e);
        props.handleChangeClassroomIndustry({ label: t("Select"), value: null });
        props.handleChangeClassroomOrganization({ value: null });
    };

    React.useEffect(() => {
        if (props.orgSeats.length === 1) {
            props.handleChangeClassroomOrganization({ value: props.orgSeats[0]?.value });
            props.handleChangeVirtualOrganization({ value: props.orgSeats[0]?.value });
        }
    }, [props.orgSeats.length]);

    const isAdditionalContentForOnlineCourse = props.formData.additionalContent === 'MH' || props.formData.additionalContent === 'ASD' || props.formData.additionalContent === 'TRA';

    return (
        <div className="my-4">
            <p className="text-uppercase semi-bold fs14">{t("Training Format")}</p>
            {props.trainingFormats.map((format) => (
                <React.Fragment key={format.id}>
                    {props.trainingFormats.length > 1 ? (
                        <Radio
                            id={format.id}
                            name="training-format"
                            onChange={props.onChangeFormat}
                            label={format.label}
                            value={format.value}
                            checked={format.checked && format.value === props.formData.trainingFormat}
                        />
                    ) : (
                        <p>{format.label}</p>
                    )}
                    {format.value === "classroom" && format.checked && (
                        <div className={`training-formats-classroom ${props.trainingFormats.length > 1 ? 'ml-4' : ''} py-3 px-4`}>
                            <p className="text-uppercase fw600 fs14">{t("Classroom-Conducted")}</p>
                            <Radio
                                id="in-person"
                                name="classroom-portion"
                                onChange={props.handleChangeClassroomFormat}
                                label={t("In-Person")}
                                value="classroom"
                                checked={props.formData.classroomFormat === 'classroom' || props.formData.classroomFormat === null}
                            />
                            <Radio
                                id="virtual"
                                name="classroom-portion"
                                onChange={props.handleChangeClassroomFormat}
                                label={t("Virtual Meeting")}
                                value="virtual"
                                checked={props.formData.classroomFormat === 'virtual'}
                            />
                            {/*<p className="text-uppercase fw600 fs14 pt-3">Enrollment Emails</p>
                            <Radio
                                id="dont-send"
                                name="enrollment-emails"
                                onChange={() => {}}
                                label="Do not send enrollment emails"
                                value="dont-send"
                                checked
                            />
                            <Radio
                                id="immediate"
                                name="enrollment-emails"
                                onChange={() => {}}
                                label="Send immediately after enrollment"
                                value="immediate"
                                checked={false}
                            />
                            <Radio
                                id="send-after"
                                name="enrollment-emails"
                                onChange={() => {}}
                                label={
                                    <div className="class-create-row gap">
                                        <span>Send</span>
                                        <label>
                                            <Select
                                                id="organization-seats"
                                                name="organization-seats"
                                                label=""
                                                showLabel={false}
                                                value="10"
                                                isRequired
                                                options={[{ label: "Select", value: null }, { label: "10", value: "10" }]}
                                                filter
                                            />
                                        </label>
                                        <span>days before first day of class</span>
                                    </div>
                                }
                                value="immediate"
                                checked={false}
                            />*/}
                        </div>
                    )}
                    {format.value === "blended" && format.checked && (
                        <div className={`training-formats-classroom ${props.trainingFormats.length > 1 ? 'ml-4' : ''} py-3 px-4`}>
                            {(props.orgLmsSettings.usesCpiLms && props.orgLmsSettings.usesExternalLms && !props.isMigrated) &&
                                <>
                                    <p className="text-uppercase fw600 fs14">{t("ClassExport.DesiredLms")}</p>
                                    <Radio
                                        id="my-company-lms"
                                        name="lms-selection"
                                        onChange={props.handleChangeLmsSelection}
                                        label={t("DesiredLms.external")}
                                        value="external"
                                        checked={props.formData.desiredLms === 'external'}
                                    />

                                    <Radio
                                        id="cpi-lms"
                                        name="lms-selection"
                                        onChange={props.handleChangeLmsSelection}
                                        label={t("DesiredLms.internal")}
                                        value="internal"
                                        checked={props.formData.desiredLms === 'internal'}
                                    />
                                </>
                            }

                            <p className="text-uppercase mt-3 fw600 fs14">{t("Classroom-Portion-Conducted")}</p>
                            <Radio
                                id="in-person-blended"
                                name="classroom-portion-blended"
                                onChange={props.handleChangeClassroomFormatBlended}
                                label={t("In-Person")}
                                value="classroom"
                                checked={props.formData.classroomFormatBlended === 'classroom' || props.formData.classroomFormatBlended === null}
                            />
                            <Radio
                                id="virtual-blended"
                                name="classroom-portion-blended"
                                onChange={props.handleChangeClassroomFormatBlended}
                                label={t("Virtual Meeting")}
                                value="virtual"
                                checked={props.formData.classroomFormatBlended === 'virtual'}
                            />
                            {isAdditionalContentForOnlineCourse && (
                                <>
                                    <p className="text-uppercase mt-3 fw600 fs14">{t("Include-Additional-Content")}</p>
                                    <Radio
                                        id="lmsQualification-yes"
                                        name="lmsQualification"
                                        onChange={props.handleChangeLmsQualificationRadio}
                                        label={t("Use-Integrated-Content")}
                                        value="yes"
                                        checked={props.formData.lmsQualificationRadio === 'yes'}
                                    />
                                    <Radio
                                        id="lmsQualification-no"
                                        name="lmsQualification"
                                        onChange={props.handleChangeLmsQualificationRadio}
                                        label={t("Use-Standard-Program-Content")}
                                        value="no"
                                        checked={props.formData.lmsQualificationRadio === 'no'}
                                    />
                                </>
                            )}
                            {(!props.isMigrated && props.formData.desiredLms === 'internal') && (
                                <>
                                    {/* Checking for greater than 2 on languages due to Select option being added to languages */}
                                    {languages && languages.length > 2 && (
                                        <div className="mt-3">
                                            <Select
                                                id="language"
                                                name="language"
                                                label={t("Online-Course-Language")}
                                                isRequired
                                                options={languages}
                                                filter
                                                changeFilterAction={handleLanguage}
                                                errorLabel={props.errors.classroomLanguage}
                                                isError={props.errors.hasOwnProperty('classroomLanguage')}
                                                value={props.formData.classroomLanguage}
                                            />
                                        </div>
                                    )}
                                    {/* Checking for greater than 2 on industries due to Select Option being added to industries */}
                                    {props.formData.classroomLanguage !== null && industries && industries.length > 2 && (
                                        <div className="mt-3">
                                            <Select
                                                id="industry"
                                                name="industry"
                                                label={t("Examples-Industry")}
                                                isRequired
                                                options={industries}
                                                filter
                                                changeFilterAction={props.handleChangeClassroomIndustry}
                                                errorLabel={props.errors.classroomIndustry}
                                                isError={props.errors.hasOwnProperty('classroomIndustry')}
                                                value={props.formData.classroomIndustry}
                                            />
                                        </div>
                                    )}
                                    <div className="mt-3">
                                        <Select
                                            id="organization-seats"
                                            name="organization-seats"
                                            label={t("Organization-Seats")}
                                            isRequired
                                            options={props.orgSeats}
                                            filter
                                            changeFilterAction={props.handleChangeClassroomOrganization}
                                            errorLabel={props.errors.classroomOrganization}
                                            isError={props.errors.hasOwnProperty('classroomOrganization')}
                                            value={props.formData.classroomOrganization}
                                        />
                                    </div>
                                    {/*<p className="text-uppercase fw600 fs14 pt-3">Enrollment Emails</p>
                                    <Radio
                                        id="dont-send"
                                        name="enrollment-emails"
                                        onChange={() => {}}
                                        label="Send immediately after enrollment"
                                        value="dont-send"
                                        checked
                                    />
                                    <Radio
                                        id="send-after"
                                        name="enrollment-emails"
                                        onChange={() => {}}
                                        label={
                                            <div className="class-create-row gap">
                                                <span>Send</span>
                                                <label>
                                                    <Select
                                                        id="organization-seats"
                                                        name="organization-seats"
                                                        label=""
                                                        showLabel={false}
                                                        value="10"
                                                        isRequired
                                                        options={[{ label: "Select", value: null }, { label: "10", value: "10" }]}
                                                        filter
                                                    />
                                                </label>
                                                <span>days before first day of class</span>
                                            </div>
                                        }
                                        value="immediate"
                                        checked={false}
                                    />*/}
                                </>
                            )}                            
                        </div>
                    )}
                    {format.value === "virtual" && format.checked && (
                        <div className={`training-formats-classroom ${props.trainingFormats.length > 1 ? 'ml-4' : ''} py-3 px-4`}>                            
                            {(!props.isMigrated && props.formData.desiredLms === 'internal') && (
                                <>
                                    {/* Checking for greater than 2 on languages due to Select option being added to languages */}
                                    {languages && languages.length > 2 && (
                                        <div className="mt-3">
                                            <Select
                                                id="language"
                                                name="language"
                                                label={t("Online-Course-Language")}
                                                isRequired
                                                options={languages}
                                                filter
                                                changeFilterAction={handleLanguage}
                                                errorLabel={props.errors.classroomLanguage}
                                                isError={props.errors.hasOwnProperty('classroomLanguage')}
                                                value={props.formData.classroomLanguage}
                                            />
                                        </div>
                                    )}
                                    {/* Checking for greater than 2 on industries due to Select Option being added to industries */}
                                    {props.formData.classroomLanguage !== null && industries && industries.length > 2 && (
                                        <div className="mt-3">
                                            <Select
                                                id="industry"
                                                name="industry"
                                                label={t("Examples-Industry")}
                                                isRequired
                                                options={industries}
                                                filter
                                                changeFilterAction={props.handleChangeClassroomIndustry}
                                                errorLabel={props.errors.classroomIndustry}
                                                isError={props.errors.hasOwnProperty('classroomIndustry')}
                                                value={props.formData.classroomIndustry}
                                            />
                                        </div>
                                    )}
                                    <div className="mt-3">
                                        <Select
                                            id="virtual-organization-seats"
                                            name="virtual-organization-seats"
                                            label={t("Organization-Seats")}
                                            isRequired                                
                                            options={props.orgSeats}
                                            filter
                                            changeFilterAction={props.handleChangeVirtualOrganization}
                                            errorLabel={props.errors.virtualOrganization}
                                            isError={props.errors.hasOwnProperty('virtualOrganization')}
                                            value={props.formData.virtualOrganization}
                                        />
                                    </div>
                                    {/*<p className="text-uppercase fw600 fs14 pt-3">Enrollment Emails</p>
                                    <Radio
                                        id="dont-send"
                                        name="enrollment-emails"
                                        onChange={() => {}}
                                        label="Send immediately after enrollment"
                                        value="dont-send"
                                        checked
                                    />
                                    <Radio
                                        id="send-after"
                                        name="enrollment-emails"
                                        onChange={() => {}}
                                        label={
                                            <div className="class-create-row gap">
                                                <span>Send</span>
                                                <label>
                                                    <Select
                                                        id="organization-seats"
                                                        name="organization-seats"
                                                        label=""
                                                        showLabel={false}
                                                        value="10"
                                                        isRequired
                                                        options={[{ label: "Select", value: null }, { label: "10", value: "10" }]}
                                                        filter
                                                    />
                                                </label>
                                                <span>days before first day of class</span>
                                            </div>
                                        }
                                        value="immediate"
                                        checked={false}
                                    />*/}
                                </>
                            )}   
                            {/*<div className="mt-4">
                                <TextArea
                                    id="message"
                                    name="message"
                                    label="Message to Participants"
                                    isRequired={false}
                                    value=""
                                />
                            </div>*/}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default TrainingFormats;
