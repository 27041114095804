import * as React from "react";

export interface MenuItemObject {
    label: string,
    elementId?: string,
    href?:string,
    disabled?: boolean;
    isSolid?: boolean,
    additionalStyleClasses?: string
    clickAction?:React.MouseEventHandler<HTMLButtonElement>
}

const ActionMenuItem = (props: {
    data:MenuItemObject
}): JSX.Element => {
    const additionalStyleClasses = props.data.additionalStyleClasses ? props.data.additionalStyleClasses : '';

    const renderActionMenuItem = () => {
        if (props.data.disabled) {
            return (
                <button
                    className={additionalStyleClasses}
                    disabled
                    id={props.data.elementId ? props.data.elementId : ''}
                >
                    {props.data.label}
                </button>
            )
        } else {
            if (props.data.href) {
                return <a href={props.data.href}><button className={additionalStyleClasses} id={props.data.elementId ? props.data.elementId : ''}>{props.data.label}</button></a>;
            } else {
                if (props.data.clickAction) {
                    return (
                        <button
                            className={additionalStyleClasses}
                            id={props.data.elementId ? props.data.elementId : ''}
                            onClick={(e) => { props.data.clickAction(e); e.currentTarget.blur(); }}
                        >
                            {props.data.label}
                        </button>
                    );
                }
            }
        }
    };
    
    return (
        <>
            {renderActionMenuItem()}
        </>
    );
};

export default ActionMenuItem;
