import * as React from "react";
import { useTranslation } from "react-i18next";

import TextInput from "../Form/text-input";
import DestructiveButton from "../UI/cancel-button";
import DateInput from "../../Components/Form/date-input";
import { IDateHoursProps } from "../../Interfaces/Platform/DateHours";
import useDetectWindowSize from "../../hooks/useDetectWindowSize";

const DateHours = (props: IDateHoursProps): JSX.Element => {
    const { isMobile } = useDetectWindowSize();

    const defaultProps = {
        removable: false,
        errorLabel: "Default Alert Text",
        isRequired: false
    };
    props = {...defaultProps, ...props};

    const [isRemoved, setIsRemoved] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(() => {
        setIsDisabled(props.additionalDays.filter((additionalDay) => additionalDay.show).length > 3);
    }, [props.additionalDays]);
    
    const handleRemoved = (): void => {
        setIsRemoved(true);
    };

    const dates = [...props.additionalDays.filter((i) => i.show).map((i) => i.date?.split('T')[0]), props.primaryDay.date?.split('T')[0]];
    const lastDate = new Date(dates.sort((a, b) => new Date(b) - new Date(a))[0]);
    const currentDateExists = (date: string) => dates.find((i) => i === new Date(date).toISOString().split('T')[0]);

    const handleAddAdditional = () => {
        props.setAdditionalDays((prevState: any[]) => {
            // Finds first additional day with property show = false
            const additionalDay = prevState.find((additionalDay) => !additionalDay.show);
            return prevState.map((day) => {
                return additionalDay.id === day.id ? { ...day, date: new Date(lastDate.setDate(lastDate.getDate() + 1)).toISOString(), show: true } : day;
            });
        });
    };

    React.useEffect(() => {
        props.setPrimaryDay((prevState: any) => ({
            ...prevState,
            initialHours: props.typeOfTraining === 'initial' || props.typeOfTraining === 'initial-refresher' ? prevState.initialHours : 0,
            refresherHours: props.typeOfTraining === 'refresher' || props.typeOfTraining === 'initial-refresher' ? prevState.refresherHours : 0
        }));
        props.setAdditionalDays((prevState: any) => {
            return prevState.map((additionalDay: any) => ({
                ...additionalDay,
                initialHours: props.typeOfTraining === 'initial' || props.typeOfTraining === 'initial-refresher' ? additionalDay.initialHours : 0,
                refresherHours: props.typeOfTraining === 'refresher' || props.typeOfTraining === 'initial-refresher' ? additionalDay.refresherHours : 0
            }));
        });
    }, [props.typeOfTraining]);

    const onChange = (e: any, isPrimary = false) => {
        if (dates.find((i) => i === e.target.value)) {
            return;
        }
        if (!isPrimary) {
            props.setAdditionalDays((prevState: any) => {
                return prevState.map((additionalDay: any) => {
                    return e.target.id === additionalDay.id ? {
                        ...additionalDay,
                        [e.target.name]: e.target.type === "number" ? e.target.value : currentDateExists(e.target.value) ? new Date(lastDate).toISOString() : new Date(e.target.value).toISOString()
                    } : additionalDay;
                });
            });
        } else {
            props.setPrimaryDay((prevState: any) => ({
                ...prevState,
                [e.target.name]: e.target.type === "number" ? e.target.value : currentDateExists(e.target.value) ? new Date(lastDate).toISOString() : new Date(e.target.value).toISOString()
            }));
        }
    };

    const handleRemoveAdditional = (additionalDay: any) => {
        // Removes selected Additional Day
        props.setAdditionalDays((prevState: any) => {
            return prevState.filter((day: any) => day.id !== additionalDay.id);
        });
        // Gets last id on array and creates a new object with new id
        props.setAdditionalDays((prevState: any) => {
            const lastAdditionalDay = parseInt(prevState.at(-1).id.split("-")[1]);
            return [...prevState, { id: `additionalDay-${lastAdditionalDay + 1}`, show: false, initialHours: '', date: new Date().toISOString(), refresherHours: '', hours: '', isCombined: false }];
        });
    };

    const getDay = (dayNo: number) => {
        if (dayNo === 2) return "2ND Day";
        if (dayNo === 3) return "3RD Day";
        if (dayNo === 4) return "4TH Day";
        if (dayNo === 5) return "5TH Day";
    };

    if (props.removable) {
        return (
            <div className="date-hour">
                <div className="date-hour-primary mb-3">
                    <div className={`date-hour-primary ${props.isReadOnly ? 'read-only' : ''}`}>
                        {props.settings.dates && (
                            <div>
                                <DateInput
                                    isActive
                                    value={props.primaryDay.date}
                                    id="primaryDay-date"
                                    name="date"
                                    label={t("Date of Class")}
                                    isReadOnly={props.isReadOnly}
                                    onChange={(e) => onChange(e, true)}
                                    isRequired={props.isRequired}
                                    isError={props.errors.hasOwnProperty("classDateSelect0")}
                                    errorLabel={props.errors.classDateSelect0}
                                />
                                {(isMobile && props.typeOfTraining === 'initial-refresher') && (
                                    <div className={`center-icon date-hour-message pt-0`}>
                                        <p className="alert-datehours">
                                            <i className="fa-sharp fa-light fa-circle-info datehours-icon"></i>
                                            {t("Hours-Staff-Attending")}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        {(!isMobile && props.settings.hours && (props.typeOfTraining === 'initial' || props.typeOfTraining === 'consultancy')) && (
                            <div className="date-hour-input">
                                <TextInput
                                    value={props.primaryDay?.initialHours}
                                    changeAction={(e) => onChange(e, true)}
                                    type="number"
                                    min={0}
                                    label={t("INITIAL HOURS")}
                                    isRequired
                                    isReadOnly={props.isReadOnly}
                                    displayAsText={props.isReadOnly}
                                    fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                    errorTextClassNames="text-nowrap"
                                    isError={props.errors.hasOwnProperty("classDates0")}
                                    errorLabel={props.errors.classDates0}
                                    id="primaryDay-initialHours"
                                    name="initialHours"
                                />
                            </div>
                        )}
                        {(!isMobile && props.settings.hours && props.typeOfTraining === 'refresher') && (
                            <div className="date-hour-input-lg">
                                <TextInput
                                    value={props.primaryDay?.refresherHours}
                                    label={t("REFRESHER HOURS")}
                                    changeAction={(e) => onChange(e, true)}
                                    type="number"
                                    isReadOnly={props.isReadOnly}
                                    displayAsText={props.isReadOnly}
                                    min={0}
                                    isRequired
                                    fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                    errorTextClassNames="text-nowrap"
                                    isError={props.errors.hasOwnProperty("classDates0")}
                                    errorLabel={props.errors.classDates0}
                                    id="primaryDay-refresherHours"
                                    name="refresherHours"
                                />
                            </div>
                        )}
                        {(!isMobile && props.settings.hours && props.typeOfTraining === 'initial-refresher') && (
                            <>
                                <div className="date-hour-input">
                                    <TextInput
                                        value={props.primaryDay?.initialHours}
                                        changeAction={(e) => onChange(e, true)}
                                        type="number"
                                        min={0}
                                        label={t("INITIAL HOURS")}
                                        isRequired
                                        isReadOnly={props.isReadOnly}
                                        displayAsText={props.isReadOnly}
                                        fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                        errorTextClassNames="text-nowrap"
                                        isError={props.errors.hasOwnProperty("classDates0")}
                                        errorLabel={props.errors.classDates0}
                                        id="primaryDay-initialHours"
                                        name="initialHours"
                                    />
                                </div>
                                <div className="date-hour-input-lg">
                                    <TextInput
                                        value={props.primaryDay?.refresherHours}
                                        label={t("REFRESHER HOURS")}
                                        changeAction={(e) => onChange(e, true)}
                                        type="number"
                                        min={0}
                                        isRequired
                                        isReadOnly={props.isReadOnly}
                                        displayAsText={props.isReadOnly}
                                        fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                        isError={props.errors.hasOwnProperty("classDates0")}
                                        errorLabel=""
                                        showErrorLabel={false}
                                        id="primaryDay-refresherHours"
                                        name="refresherHours"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {(!props.isReadOnly && !isMobile && props.typeOfTraining === 'initial-refresher') && (
                        <div className="center-icon date-hour-message">
                            <p className="alert-datehours">
                                <i className="fa-sharp fa-light fa-circle-info datehours-icon"></i>
                                {t("Hours-Group-Attending")}
                            </p>
                        </div>
                    )}
                </div>
                {isMobile && (
                    <div className="d-flex mb-3 date-hour-gap">
                        {(props.settings.hours && (props.typeOfTraining === 'initial' || props.typeOfTraining === 'consultancy')) && (
                            <div className="date-hour-input">
                                <TextInput
                                    value={props.primaryDay?.initialHours}
                                    changeAction={(e) => onChange(e, true)}
                                    type="number"
                                    min={0}
                                    label={t("INITIAL HOURS")}
                                    isRequired
                                    fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                    errorTextClassNames="text-nowrap"
                                    isError={props.errors.hasOwnProperty("classDates0")}
                                    errorLabel={props.errors.classDates0}
                                    id="primaryDay-initialHours"
                                    name="initialHours"
                                />
                            </div>
                        )}
                        {(props.settings.hours && props.typeOfTraining === 'refresher') && (
                            <div className="date-hour-input-lg">
                                <TextInput
                                    value={props.primaryDay?.refresherHours}
                                    label={t("REFRESHER HOURS")}
                                    changeAction={(e) => onChange(e, true)}
                                    type="number"
                                    min={0}
                                    isRequired
                                    fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                    errorTextClassNames="text-nowrap"
                                    isError={props.errors.hasOwnProperty("classDates0")}
                                    errorLabel={props.errors.classDates0}
                                    id="primaryDay-refresherHours"
                                    name="refresherHours"
                                />
                            </div>
                        )}
                        {(props.settings.hours && props.typeOfTraining === 'initial-refresher') && (
                            <>
                                <div className="date-hour-input">
                                    <TextInput
                                        value={props.primaryDay?.initialHours}
                                        changeAction={(e) => onChange(e, true)}
                                        type="number"
                                        min={0}
                                        label={t("INITIAL HOURS")}
                                        isRequired
                                        fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                        errorTextClassNames="text-nowrap"
                                        isError={props.errors.hasOwnProperty("classDates0")}
                                        errorLabel={props.errors.classDates0}
                                        id="primaryDay-initialHours"
                                        name="initialHours"
                                    />
                                </div>
                                <div className="date-hour-input-lg">
                                    <TextInput
                                        value={props.primaryDay?.refresherHours}
                                        label={t("REFRESHER HOURS")}
                                        changeAction={(e) => onChange(e, true)}
                                        type="number"
                                        min={0}
                                        isRequired
                                        fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty("classDates0") ? 'mb-2' : ''}`}
                                        isError={props.errors.hasOwnProperty("classDates0")}
                                        errorLabel=""
                                        showErrorLabel={false}
                                        id="primaryDay-refresherHours"
                                        name="refresherHours"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
                {props.additionalDays && props.additionalDays.length > 0 && props.additionalDays.filter((additionalDay) => additionalDay.show && additionalDay.id !== 'primaryDay').map((additionalDay, index) => {
                    return (
                        <>
                        <div key={additionalDay.id} className="mb-3 date-hour-additional">
                            <div className={`date-hour-additional-row ${props.isReadOnly ? 'read-only' : ''}`}>
                                {props.settings.dates && (
                                    <div>
                                        <DateInput
                                            isActive
                                            value={additionalDay.date}
                                            id={additionalDay.id}
                                            name="date"
                                            label={getDay(index + 2)}
                                            isReadOnly={props.isReadOnly}
                                            onChange={onChange}
                                            isRequired={props.isRequired}
                                            isError={props.errors.hasOwnProperty(`classDateSelect${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                            errorLabel={props.errors[`classDateSelect${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                        />
                                    </div>
                                )}
                                {(!isMobile && props.settings.hours && (props.typeOfTraining === 'initial' || props.typeOfTraining === 'consultancy')) && (
                                    <div className="date-hour-input">
                                        <TextInput
                                            value={additionalDay.initialHours}
                                            changeAction={onChange}
                                            type="number"
                                            min={0}
                                            label={t("INITIAL HOURS")}
                                            isRequired
                                            isReadOnly={props.isReadOnly}
                                            displayAsText={props.isReadOnly}
                                            fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                            errorTextClassNames="text-nowrap"
                                            isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                            errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                            id={additionalDay.id}
                                            name="initialHours"
                                        />
                                    </div>
                                )}
                                {(!isMobile && props.settings.hours && props.typeOfTraining === 'refresher') && (
                                    <div className="date-hour-input-lg">
                                        <TextInput
                                            value={additionalDay.refresherHours}
                                            label={t("REFRESHER HOURS")}
                                            changeAction={onChange}
                                            type="number"
                                            min={0}
                                            isRequired
                                            isReadOnly={props.isReadOnly}
                                            displayAsText={props.isReadOnly}
                                            fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                            errorTextClassNames="text-nowrap"
                                            isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                            errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                            id={additionalDay.id}
                                            name={"refresherHours"}
                                        />
                                    </div>
                                )}
                                {(!isMobile && props.settings.hours && props.typeOfTraining === 'initial-refresher') && (
                                    <>
                                        <div className="date-hour-input">
                                            <TextInput
                                                value={additionalDay.initialHours}
                                                changeAction={onChange}
                                                type="number"
                                                min={0}
                                                label={t("INITIAL HOURS")}
                                                isRequired
                                                isReadOnly={props.isReadOnly}
                                                displayAsText={props.isReadOnly}
                                                fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                                errorTextClassNames="text-nowrap"
                                                isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                                errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                                id={additionalDay.id}
                                                name="initialHours"
                                            />
                                        </div>
                                        <div className="date-hour-input-lg">
                                            <TextInput
                                                value={additionalDay.refresherHours}
                                                label={t("REFRESHER HOURS")}
                                                changeAction={onChange}
                                                type="number"
                                                min={0}
                                                isRequired
                                                isReadOnly={props.isReadOnly}
                                                displayAsText={props.isReadOnly}
                                                fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                                isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                                errorLabel=""
                                                showErrorLabel={false}
                                                id={additionalDay.id}
                                                name="refresherHours"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {!props.isReadOnly && !isMobile && (
                                <div className="center-icon">
                                    <i className="fa-light fa-sharp fa-xmark date-hour-remove-icon" onClick={() => handleRemoveAdditional(additionalDay)} />
                                </div>
                            )}
                        </div>
                        {isMobile && (
                            <div className="d-flex mb-3 date-hour-gap">
                                {(props.settings.hours && (props.typeOfTraining === 'initial' || props.typeOfTraining === 'consultancy')) && (
                                    <div className="date-hour-input">
                                        <TextInput
                                            value={additionalDay.initialHours}
                                            changeAction={onChange}
                                            type="number"
                                            min={0}
                                            label={t("INITIAL HOURS")}
                                            isRequired
                                            fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                            errorTextClassNames="text-nowrap"
                                            isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                            errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                            id={additionalDay.id}
                                            name="initialHours"
                                        />
                                    </div>
                                )}
                                {(props.settings.hours && props.typeOfTraining === 'refresher') && (
                                    <div className="date-hour-input-lg">
                                        <TextInput
                                            value={additionalDay.refresherHours}
                                            label={t("REFRESHER HOURS")}
                                            changeAction={onChange}
                                            type="number"
                                            min={0}
                                            isRequired
                                            fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                            errorTextClassNames="text-nowrap"
                                            isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                            errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                            id={additionalDay.id}
                                            name={"refresherHours"}
                                        />
                                    </div>
                                )}
                                {(props.settings.hours && props.typeOfTraining === 'initial-refresher') && (
                                    <>
                                        <div className="date-hour-input">
                                            <TextInput
                                                value={additionalDay.initialHours}
                                                changeAction={onChange}
                                                type="number"
                                                min={0}
                                                label={t("INITIAL HOURS")}
                                                isRequired
                                                fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                                errorTextClassNames="text-nowrap"
                                                isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                                errorLabel={props.errors[`classDates${parseInt(additionalDay.id.split("-")[1])+1}`]}
                                                id={additionalDay.id}
                                                name="initialHours"
                                            />
                                        </div>
                                        <div className="date-hour-input-lg">
                                            <TextInput
                                                value={additionalDay.refresherHours}
                                                label={t("REFRESHER HOURS")}
                                                changeAction={onChange}
                                                type="number"
                                                min={0}
                                                isRequired
                                                fieldClassNames={`date-hour-text-input ${props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`) ? 'mb-2' : ''}`}
                                                isError={props.errors.hasOwnProperty(`classDates${parseInt(additionalDay.id.split("-")[1])+1}`)}
                                                errorLabel=""
                                                showErrorLabel={false}
                                                id={additionalDay.id}
                                                name="refresherHours"
                                            />
                                        </div>
                                        <div className="center-icon">
                                            <i className="fa-light fa-sharp fa-xmark date-hour-remove-icon" onClick={() => handleRemoveAdditional(additionalDay)} />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        </>
                    );
                })}
                {(!props.isReadOnly && !isDisabled) && (
                    <div className={`${isDisabled ? 'is-disabled' : ''}`} aria-disabled={isDisabled}>
                        <DestructiveButton
                            label={<><i className="fa-light fa-sharp fa-plus" /> Another Day</>}
                            isDisabled={isDisabled}
                            clickAction={() => handleAddAdditional()}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            {/*Not removable TODO*/}
        </>
    );
};


export default DateHours;
