import * as React from "react";
import { t } from "i18next";
import { Button } from "react-bootstrap";
import DestructiveButton from "../UI/cancel-button";
import ActionButton from "../../Interfaces/Platform/ActionButton";
import useDetectWindowSize from "../../hooks/useDetectWindowSize";

const PopUpModal = (props: {
    actionButton?: ActionButton,
    cancelButtonTitle?: string,
    showFooter?: boolean,
    title?: string,
    modalId?: string,
    content: JSX.Element,
    show: boolean,
    size?: "md" | "lg",
    center?: boolean,
    hide: () => void,
    autoHide?: () => void,
    scrollable?: boolean,
    className?: string
}): JSX.Element => {
    const defaultProps = {
        center: false,
        showFooter: true,
        scrollable: false
    };
    props = {...defaultProps, ...props};

    const { isMobile } = useDetectWindowSize();

    const size = isMobile ? "lg" : props.size;
    const actionButtonStyle = props.actionButton && props.actionButton.isSolid ? "solid-button" : "clear-button";
    return (
        <>
            {props.show &&
                <div className={"popup-dialog " + (props.className ?? "")} onClick={props.autoHide ?? props.hide}>
                    <div id={(props.modalId) ? props.modalId : ""} onClick={(e) => e.stopPropagation()} className={`popup-content content-${size} ${props.center ? "content-center" : ""}`}>
                        <div className="popup-header">
                            <label className="popup-title">{ props.title &&
                                props.title
                            }</label>
                            <Button variant="" className="popup-close-button" onClick={props.hide}>
                                <i className="fa-light fa-sharp fa-times" />
                            </Button>
                        </div>
                        { props.title &&
                            <div className="popup-title-separator mt-1"></div>
                        }
                        
                        <div className={`popup-body ${props.scrollable ? 'scrollable' : ''}`}>
                            {props.content}
                        </div>
                        {props.showFooter && (
                            <div className="popup-footer">
                                {props.cancelButtonTitle && (
                                    <DestructiveButton
                                        label={<><i className="me-1 far fa-times" /> {t(props.cancelButtonTitle)}</>}
                                        clickAction={props.hide}
                                    />
                                )}

                                {props.actionButton && (
                                    <button
                                        onClick={props.actionButton.clickAction}
                                        className={'popup-action-button ' + actionButtonStyle}
                                        id={props.actionButton.elementId ? props.actionButton.elementId : ''}
                                    >
                                        {props.actionButton.label}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>}
        </>
    );
};

export default PopUpModal;