import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Button from "../Components/UI/button";
import DestructiveButton from "../Components/UI/cancel-button";
import Alert from "../Components/UI/alert";
import DateHours from "../Components/UI/date-hours";
import DateInput from "../Components/Form/date-input";
import RadioList from "../Components/Form/radio/radio-list";
import SelectWorkbookList from "../Components/Form/select-workbook/select-workbook-list";
import TextInput from "../Components/Form/text-input";
import Select from "../Components/Form/select";
import AdditionalInstructor from "../Components/UI/additional-instructor";
import ClassDatePast from "./Components/Classes/class-date-past";
import TrainingFormats from "./Components/Classes/training-formats";
import type { IAdditionalInstructor } from "../Interfaces/Platform/AdditionalInstructor";
import type { IDateHour, IAdditionalDateHour } from "../Interfaces/Platform/DateHours";
import type { ShowAlert } from "../Interfaces/Platform/AlertProperties";
import type FormSelectOption from "../Interfaces/Platform/FormSelectOption";
import {
    fetchInstructorsCertifications,
    fetchOrganizationBranchIds,
    fetchPrograms,
    fetchAllPrograms,
    returnInstructorsCertifications,
    expirationOffsets,
    returnPrograms
} from "./Components/DashboardPlatformUtils";
import {
    fetchAdditionalContent,
    fetchClassSettings,
    fetchProgramEditions,
    fetchTypesOfTraining,
    fetchTrainingFormats,
    fetchClassLanguagesAndIndustries,
    fetchClassCourseObject,
    fetchOrgLmsSettings,
    returnTypesOfTraining,
    returnTrainingFormats,
    returnAdditionalContent,
    returnOrganizationsSeats,
    fetchSkills,
    createClass,
    fetchOrganizationSeats,
    TRAINING_FORMATS
} from "./Utils/classCreate";
import Tile from "../Components/UI/tile";
import PopUpModal from "../Components/UI/modal-pop-up";

import { ConfigContext } from "../configuration-context";
import { RbacContext } from "../rbac-context";
import useForm from "../hooks/useForm";
import useSkills from "../hooks/useSkills";
import usePageLevelAlerts from "../hooks/usePageLevelAlerts";
import useTrainableSpecialtiesByCert from "../hooks/useTrainableSpecialtiesByCert";
import { returnPageLevelAlerts } from "./Utils/alerts";
import validateRules from "../FormValidationRules/createClass";
import PhysicalSkills from "./Components/Classes/physical-skills";
import { ClassSkillModel, ClassSkillCheck } from "../Interfaces/ClassSkillModel";
import type { CourseData, ICourseObject, OrganizationSeat, OrgLmsSettings } from "../Interfaces/Platform/Classes";
import { subscribe, unsubscribe } from "./Utils/events";
import { isCertInstructor, isBusinessAdministrator } from "../helper-functions";
import useParsedTranslation from "../hooks/useParsedTranslation";

import useFeatureFlags from "../hooks/useFeatureFlags";

import { useDispatch, useSelector } from "react-redux";
import { loadNewClassFromCreatePayload } from "../stores/classDetailsSlice";
import { clearClassParticipants } from "../stores/classParticipantsSlice";
import { RootState } from "../store";
import { PlatformBaseRoutes } from "../Routing/routes";
import BOESelector from "./Components/UI/boe-selector";

import useRouteAccessCheck from "../hooks/useRouteAccessCheck";

interface ProgramEdition {
    specialty: string;
    edition: string;
    hasAdditionalContent: boolean;
    hasSupplimentContent: boolean;
    active: boolean;
    defaultContent: string;
}
interface Workbook extends ProgramEdition {
    isSelected: boolean;
}
interface ClassSettings {
    blueCardId: boolean;
    dates: boolean;
    hours: boolean;
    expiration: boolean;
    skills: boolean;
    hasWorkbook: boolean;
}

interface InstructorModel {
    userId: string;
    firstName: string;
    lastName: string;
    certifications: string[];
}

const ClassCreatePage = () => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const [ITLIntermediatePhysicalSkills] = useFeatureFlags("ITLIntermediatePhysicalSkills", "pending");
    const { isCPIStaff } = useRouteAccessCheck();
    const [trainablePrograms] = useTrainableSpecialtiesByCert(ITLIntermediatePhysicalSkills);
    const { processSkillsData, prepareSkillsDataForCreate } = useSkills();
    const [ITLClassSkills] = useFeatureFlags("ITLClassSkills");
    const navigate = useNavigate();
    const [pageLevelAlerts, addPageLevelAlert] = usePageLevelAlerts();

    const rbac = React.useContext(RbacContext);

    const culture = rbac.userContext.Country ?? "en-US";
    const userId = rbac.userContext.UserId;

    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const isCI = isCertInstructor(rbac.userContext);
    const isBA = isBusinessAdministrator(rbac.userContext);
    const shouldBOE = rbac.userContext?.AltOrganizationsList?.length > 0 && (isCI || isBA);
    const currentOrg = shouldBOE
        ? rbac.userContext?.SelectedOrganization.toString()
        : rbac.userContext?.OrganizationId.toString();
    const currentOrgName = shouldBOE
        ? rbac.userContext?.AltOrganizationsList.filter((i) => i.organizationId === currentOrg)[0]?.organizationName
        : rbac.userContext?.OrganizationName;

    const dispatch = useDispatch();
    const classDetails = useSelector((state: RootState) => state.classDetails);

    const [typesOfTraining, setTypesOfTraining] = React.useState<string[]>([]);
    const [orgLmsSettings, setOrgLmsSettings] = React.useState<OrgLmsSettings>(null);
    const [orgSeats, setOrgSeats] = React.useState<OrganizationSeat[]>([]);
    const [trainingFormats, setTrainingFormats] = React.useState([]);
    const [courses, setCourses] = React.useState<CourseData[]>([]);
    const [showClassDatePast, setShowClassDatePast] = React.useState<boolean>(false);
    const [courseObject, setCourseObject] = React.useState<ICourseObject>(null);
    const [selectedEdition, setSelectedEdition] = React.useState<Workbook>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [createAlert, setCreateAlert] = React.useState<ShowAlert>({
        alertLevel: null,
        arrowLink: null,
        description: null,
        canDismiss: true,
        show: false
    });
    const [classSettings, setClassSettings] = React.useState<ClassSettings>({
        blueCardId: false,
        dates: false,
        hours: false,
        expiration: false,
        skills: false,
        hasWorkbook: false
    });
    const [workbooks, setWorkbooks] = React.useState<Workbook[]>([]);
    const [additionalContent, setAdditionalContent] = React.useState<string[]>([]);
    const [instructors, setInstructors] = React.useState<InstructorModel[]>([]);
    const [additionalInstructors, setAdditionalInstructors] = React.useState<IAdditionalInstructor[]>([
        { id: "additionalInstructor-0", show: false, value: null },
        { id: "additionalInstructor-1", show: false, value: null },
        { id: "additionalInstructor-2", show: false, value: null },
        { id: "additionalInstructor-3", show: false, value: null }
    ]);
    const [selectedInstructors, setSelectedInstructors] = React.useState<any>({});
    const [selectedProgram, setSelectedProgram] = React.useState("");
    const [programs, setPrograms] = React.useState([]);
    const [skills, setSkills] = React.useState<ClassSkillModel>();
    const [skillsSelected, setSkillsSelected] = React.useState<any[]>([]);
    const [userValues, setUserValues] = React.useState<InstructorModel>({
        userId: rbac.userContext.UserId,        
        firstName: rbac.userContext.FirstName,
        lastName: rbac.userContext.LastName,
        certifications: rbac.userContext?.CertificationData.length > 0 ? rbac.userContext.CertificationData.map((data) => (data.specialty)) : []
    });
    const skillsSelectedRef = React.useRef(skillsSelected);
    skillsSelectedRef.current = skillsSelected;

    const today = new Date();
    const setDate = (months: number) => {
        const result: Date = new Date(today);
        result.setMonth(result.getMonth() + months);
        const month = result.getMonth() + 1;
        return result.toISOString();
    };
    const [expirationDate, setExpirationDate] = React.useState([
        { id: "expirationDate1", checked: false, label: t("6 Months"), value: setDate(6) },
        { id: "expirationDate2", checked: true, label: t("12 Months"), value: setDate(12) },
        { id: "expirationDate5", checked: false, label: t("Until"), value: "" }
    ]);
    const todayDateFormat = today.toISOString().split("T")[0];
    const [primaryDay, setPrimaryDay] = React.useState<IDateHour>({
        date: today.toISOString(),
        initialHours: "0",
        refresherHours: "0"
    });
    const [additionalDays, setAdditionalDays] = React.useState<IAdditionalDateHour[]>([
        { id: "additionalDay-0", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-1", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-2", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-3", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" }
    ]);

    let mySkills: any[] = [];

    const getOrganizationBranch = async () => {
        const data = await fetchOrganizationBranchIds(apimBaseUrl, configContext, currentOrg);
        getInstructors(data);
    };

    const getInstructors = async (orgs: string[]) => {
        const data: InstrtuctorModel[] = await fetchInstructorsCertifications(apimBaseUrl, configContext, currentOrg, orgs, userId);
        const isInstructorExist = data.some((instructor) => instructor.userId === userValues.userId);        
        if (!isInstructorExist && isBA) {
            data.unshift(userValues);
        }                
        setInstructors((prevState) => [...prevState, ...data]);
    };

    const getAdditionalContent = async (instructorsIds: string[]) => {
        const data = await fetchAdditionalContent(apimBaseUrl, configContext, instructorsIds, selectedProgram);
        if (data?.length === 0) {
            setAdditionalContent([null]);
        } else {
            setAdditionalContent(data);
        }

        handleChangeByGetEvent({ isAdditionalContentRequired: !!data.length });
    };

    const getTypesOfTraining = async () => {
        const data = await fetchTypesOfTraining(apimBaseUrl, configContext, selectedProgram, culture);
        setTypesOfTraining(data);
    };

    const getOrgLmsSettings = async () => {
        const data = await fetchOrgLmsSettings(apimBaseUrl, configContext, currentOrg);
        setOrgLmsSettings(data);
    };

    const getTrainingFormats = async () => {
        const data = await fetchTrainingFormats(
            apimBaseUrl,
            configContext,
            selectedProgram,
            culture,
            formFieldData.additionalContent,
            formFieldData.specialtyEdition
        );

        if (
            orgLmsSettings &&
            !orgLmsSettings.usesCpiLms &&
            !orgLmsSettings.usesExternalLms &&
            formFieldData.desiredLms === null
        ) {
            const formats = returnTrainingFormats(data).filter((i) => i.value !== "blended");
            setTrainingFormats(formats);
            handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0]?.value });
        } else {
            const formats = returnTrainingFormats(data);
            setTrainingFormats(formats);
            handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0].value });
        }
    };

    const getOrganizationSeats = async (courseId: string) => {
        const payload = {
            courseId,
            organizationId: currentOrg,
            isBA,
            isCI
        };
        const data = await fetchOrganizationSeats(configContext, payload);
        setOrgSeats(data);
    };

    const getQualification = () => {
        if (formFieldData.trainingFormat === "blended") {
            return formFieldData.lmsQualification;
        } else {
            return formFieldData.additionalContent === "none" || !formFieldData.additionalContent
                ? null
                : formFieldData.additionalContent;
        }
    };

    const getLanguagesAndIndustries = async () => {
        const data = await fetchClassLanguagesAndIndustries(
            apimBaseUrl,
            configContext,
            selectedProgram,
            getQualification(),
            formFieldData.specialtyEdition
        );
        setCourses(data);
    };

    const getCourseObject = async () => {
        const data: ICourseObject = await fetchClassCourseObject(
            apimBaseUrl,
            configContext,
            selectedProgram,
            formFieldData.specialtyEdition,
            formFieldData.classroomLanguage,
            formFieldData.classroomIndustry,
            getQualification()
        );

        if (data && data.courseId) {
            setCourseObject(data);
            getOrganizationSeats(data.courseId);
        } else {
            setOrgSeats([]);
        }
    };

    const removeCreateAlert = () => {
        setCreateAlert({
            alertLevel: null,
            arrowLink: null,
            description: null,
            canDismiss: true,
            show: false
        });
    };

    const showCreateAlert = (alert: ShowAlert) => {
        setCreateAlert({ ...alert, show: true });
    };

    const getClassSettings = async () => {
        const data = await fetchClassSettings(apimBaseUrl, configContext, selectedProgram);
        setClassSettings(data[0]);
    };

    const getProgramEditions = async () => {
        const data = await fetchProgramEditions(apimBaseUrl, configContext, selectedProgram, culture);
        const specialtyEditions = data
            .filter((i: ProgramEdition) => i.edition !== "none")
            .map((edition: ProgramEdition, index: number) => ({
                ...edition,
                isSelected: index === 0
            }));
        setWorkbooks(specialtyEditions);
        if (specialtyEditions && specialtyEditions.length > 0) {
            handleChangeByGetEvent({
                specialtyEdition: specialtyEditions.filter((i: Workbook) => i.isSelected)[0].edition
            });
        } else {
            handleChangeByGetEvent({ specialtyEdition: "none" });
        }
    };

    const getPrograms = async (ids: string[]) => {
        let allTrainablePrograms: string[] = [];
        const data = isCPIStaff()
            ? await fetchAllPrograms(apimBaseUrl, configContext, culture)
            : await fetchPrograms(apimBaseUrl, configContext, ids);
        if ((Array.isArray(data) && data.length > 0) || isBA) {    
            if (data.length > 0) {
                data.forEach((program: any) => {
                    let trainableProgramsOnThisSpecialty = trainablePrograms(program, culture, false, isBA);
                    allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
                });
            } else {
                let trainableProgramsOnThisSpecialty = trainablePrograms("", culture, false, isBA);
                allTrainablePrograms = allTrainablePrograms.concat(trainableProgramsOnThisSpecialty);
            }
            
        }
        // Remove duplicated specialty codes
        setPrograms([...new Set(allTrainablePrograms)].map((program: any) => ({ label: tp(program), value: program })));
    };

    const getSkills = async () => {
        const payload: any = {
            specialty: formFieldData.specialty,
            edition: formFieldData.specialtyEdition,
            additionalContent:
                formFieldData.additionalContent === "none" || !formFieldData.additionalContent
                    ? null
                    : formFieldData.additionalContent
        };
        const data = ITLClassSkills ? await fetchSkills(apimBaseUrl ?? "", configContext, payload) : null;
        let processedSkills = processSkillsData(data);

        if (processedSkills) {
            if (processedSkills.categories != null) {
                processedSkills = {
                    categories: processedSkills.categories.map((category) => {
                        return {
                            ...category,
                            allSelected: true
                        };
                    })
                };
            }
        }

        setSkills(processedSkills);

        setSkillsSelected(prepareSkillsDataForCreate(processedSkills));
    };

    React.useEffect(() => {
        handleChangeByGetEvent({
            userId: rbac.userContext.PingId,
            organizationId: currentOrg,
            organizationName: currentOrgName,
            classroomFormat: null,
            classroomFormatBlended: null,
            classroomLanguage: null,
            classroomIndustry: null,
            classroomOrganization: null,
            desiredLms: null,
            emailSend: null,
            emailScheduledDate: null,
            emailMessage: null,
            qualification: null,
            lmsQualification: null,
            lmsQualificationRadio: null
        });
    }, []);

    React.useEffect(() => {
        handleChangeByGetEvent({
            additionalInstructors: additionalInstructors.filter((additionalInstructor) => additionalInstructor.show)
                .length
        });
    }, [additionalInstructors]);

    const handleResetInstructorChange = () => {
        removeCreateAlert();
        setClassSettings({
            blueCardId: false,
            dates: false,
            hours: false,
            expiration: false,
            skills: false
        });
        setWorkbooks([]);
        setSkills({ categories: [] });
        setTypesOfTraining([]);
        setTrainingFormats([]);
        setAdditionalContent([]);
        setExpirationDate([
            { id: "expirationDate1", checked: false, label: t("6 Months"), value: setDate(6) },
            { id: "expirationDate2", checked: true, label: t("12 Months"), value: setDate(12) },
            { id: "expirationDate5", checked: false, label: t("Until"), value: "" }
        ]);
        setSkillsSelected([]);
        setPrimaryDay({
            date: today.toISOString(),
            initialHours: "0",
            refresherHours: "0"
        });

        handleChangeByGetEvent({
            userId: rbac.userContext.PingId,
            organizationId: currentOrg,
            organizationName: currentOrgName,
            classroomFormat: "classroom",
            classroomFormatBlended: "classroom",
            //classDates: [],
            hasAdditionalContent: null,
            isAdditionalContentRequired: null,
            additionalContent: null,
            classroomLanguage: null,
            classroomIndustry: null,
            classroomOrganization: null,
            desiredLms: orgLmsSettings?.usesExternalLms ? "external" : "internal",
            emailSend: null,
            emailScheduledDate: null,
            emailMessage: null,
            qualification: null,
            lmsQualification: null,
            lmsQualificationRadio: null,
            classSkills: [],
            specialtyEdition: null,
            trainingType: null,
            trainingFormat: null
        });
    };

    React.useEffect(() => {
        if (classSettings) handleChangeByGetEvent({ blueCardId: classSettings.blueCardId });
    }, [classSettings]);

    React.useEffect(() => {
        if (typesOfTraining && typesOfTraining.length > 0) {
            handleChangeByGetEvent({ trainingType: typesOfTraining[0] });
        }
    }, [typesOfTraining]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            getOrganizationBranch();
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            const instrucs = Object.entries(selectedInstructors);
            const instrucsIds = instrucs.map(([key, value]) => value) as string[];
            if (instrucs.length > 0 && ITLIntermediatePhysicalSkills != "pending") getPrograms(instrucsIds);
        }
    }, [
        configContext?.SystemConfiguration?.ApimKey,
        selectedInstructors,
        rbac.userContext.Country,
        ITLIntermediatePhysicalSkills
    ]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
            const instrucs = Object.entries(selectedInstructors);
            const instrucsIds = instrucs.map(([key, value]) => value) as string[];
            if (instrucs.length > 0 && selectedProgram) getAdditionalContent(instrucsIds);
        }
    }, [configContext?.SystemConfiguration?.ApimKey, selectedProgram, selectedInstructors]);

    const detected = instructors.filter((instructor) => instructor.userId === rbac.userContext.PingId)[0];
    React.useEffect(() => {
        if (detected) {
            setSelectedInstructors((prevState: any) => ({ ...prevState, primaryInstructor: detected.userId }));
        }
    }, [instructors]);

    const getTimeInSeconds = (hours: number | null, minutes: number | null): number | null => {
        let estimatedTimeHoursInSeconds: number;
        let estimatedTimeMinutesInSeconds: number;
        if (hours === null && minutes === null) {
            return null;
        } else {
            estimatedTimeHoursInSeconds = hours * 60 * 60;
            estimatedTimeMinutesInSeconds = minutes * 60;
            return estimatedTimeHoursInSeconds + estimatedTimeMinutesInSeconds;
        }
    };

    const areAnyDatesPast = (classDates: any) => {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        const datesAreInPastArr = classDates.map((i: any) => {
            const [year, month, day] = i.date.split("T")[0].split("-");
            const newClassDateStr = `${month}-${day}-${year}`;
            const classDate = new Date(newClassDateStr).setHours(0, 0, 0, 0);
            return classDate < currentDate;
        });
        return datesAreInPastArr.some((i: boolean) => i);
    };

    const handleCreateClass = async (params: any) => {
        const classDates = params.classDates.map(({ id, ...rest }: any) => ({
            ...rest,
            date: rest.date.split("T")[0]
        }));
        params = { ...params, classDates };
        const expirationId = expirationDate.filter((i) => i.checked)[0].id;
        if (expirationOffsets[expirationId] !== null) {
            params.expirationOffset = expirationOffsets[expirationId];
            params.expirationDate = null;
        } else {
            params.expirationOffset = null;
        }

        const trainingClassroom = params.trainingFormat === "classroom";
        const internalLms = params.desiredLms === "internal";
        const isPreventionFirstOrDEB = params.specialty === "PF" || params.specialty === "DEB";

        params = {
            ...params,
            qualification:
                params.additionalContent === "none" || !params.additionalContent ? null : params.additionalContent,
            courseId:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.courseId
                    ? courseObject.courseId
                    : null,
            courseObjectId:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.courseObjectId
                    ? courseObject.courseObjectId
                    : null,
            scormId:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.importId
                    ? courseObject.importId
                    : null,
            modulesUrl:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.fileUrl
                    ? courseObject.fileUrl
                    : null,
            courseName:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.courseName
                    ? courseObject.courseName
                    : null,
            courseCulture:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.courseCulture
                    ? courseObject.courseCulture
                    : null,
            contactHours:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.contactHours
                    ? courseObject.contactHours
                    : null,
            templateId:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.templateId
                    ? courseObject.templateId
                    : null,
            estimatedTime:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject
                    ? getTimeInSeconds(courseObject.estimatedTimeHours, courseObject.estimatedTimeMinutes)
                    : null,
            thumbnailUrl:
                (trainingClassroom && !isPreventionFirstOrDEB) || !internalLms
                    ? null
                    : courseObject && courseObject.thumbnailUrl
                    ? courseObject.thumbnailUrl
                    : null

        };
        // Removing unused data on Create Class, data only being used for form validation & data display
        delete params.hasAdditionalContent;
        delete params.additionalContent;
        delete params.additionalInstructors;
        delete params.isAdditionalContentRequired;

        const seatOrganizationIdToUse =
            params.trainingFormat === "blended" ? params.classroomOrganization : params.virtualOrganization;

        const classroomFormat = () => {
            if (params.trainingFormat === "classroom") {
                return params.classroomFormat;
            }
            if (params.trainingFormat === "blended") {
                return params.classroomFormatBlended;
            }
            return null;
        };

        params = {
            ...params,
            blueCardRequired: params.blueCardId,
            classroomFormat: classroomFormat(),
            industry: trainingClassroom || !internalLms ? null : params.classroomIndustry,
            courseLanguage: trainingClassroom || !internalLms ? null : params.classroomLanguage,
            seatOrganizationId: trainingClassroom || !internalLms ? null : seatOrganizationIdToUse,
            seatOrganizationName: trainingClassroom || !internalLms
                ? null
                : orgSeats.filter((i) => {
                      return (
                          i.organizationId === params.classroomOrganization ||
                          i.organizationId === params.virtualOrganization
                      );
                  })[0].organizationName
        };
        delete params.blueCardId;
        delete params.classroomFormatBlended;
        delete params.classroomIndustry;
        delete params.classroomOrganization;
        delete params.virtualOrganization;
        delete params.classroomLanguage;
        setIsLoading(true);

        try {
            const response = await createClass(apimBaseUrl, configContext, params);
            // If Class Create response is 200
            if (typeof response.data.classId === "string" && response.status === 200) {
                removeCreateAlert();

                let utcDate = new Date(response.data.endDate);
                const newData = {
                    ...response.data,
                    endDate: new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
                };
                dispatch(loadNewClassFromCreatePayload(newData));
                dispatch(clearClassParticipants());

                setIsLoading(false);
                window.scrollTo(0, 0);

                navigate(`${PlatformBaseRoutes.DashboardClasses.fullPath}/${response.data.classId}`);
            }
        } catch (e) {
            window.scrollTo(0, 0);
            showCreateAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        if (programs.filter((program) => program.value === selectedProgram).length > 0) {
            handleChangeByGetEvent({
                specialty: selectedProgram
            });
        } else {
            setSelectedProgram(null);
            handleChangeByGetEvent({ specialty: null });
        }
    }, [programs, selectedInstructors, selectedProgram]);

    const handleSkillsSelected = (ev: CustomEvent) => {
        let tempSkillsState = skillsSelectedRef.current;

        const allSelected = ev.detail.filter((chk: ClassSkillCheck) => chk.checked);
        const allUnselected = ev.detail.filter((chk: ClassSkillCheck) => !chk.checked);

        // add to state
        if (allSelected.length > 0) {
            allSelected.forEach((skill: ClassSkillCheck) => {
                if (!tempSkillsState.some((s: any) => s.skillId === skill.id)) {
                    tempSkillsState.push({
                        skillId: skill.id,
                        skillName: skill.name,
                        category: skill.category,
                        subCategory: skill.subCategory,
                        deleted: false
                    });
                } else {
                    tempSkillsState = tempSkillsState.map((s: any) => {
                        if (s.skillId === skill.id) {
                            return { ...s, deleted: false };
                        }
                        return s;
                    });
                }
            });
        }

        if (allUnselected.length > 0) {
            allUnselected.forEach((skill: ClassSkillCheck) => {
                tempSkillsState = tempSkillsState.map((s: any) => {
                    if (s.skillId === skill.id) {
                        return { ...s, deleted: true };
                    }
                    return s;
                });
            });
        }

        tempSkillsState = tempSkillsState.filter((s: any) => !s.deleted);

        setSkillsSelected(tempSkillsState);

        //publish("mySkillsChanged", {});
    };

    React.useEffect(() => {
        subscribe("skillsSelected", handleSkillsSelected);
        //subscribe("mySkillsChanged", (e: any) => setSkillsSelected(mySkills));

        return () => {
            unsubscribe("skillsSelected", handleSkillsSelected);
            //unsubscribe("mySkillsChanged", (e: any) => setSkillsSelected(mySkills));
        };
    }, []);

    React.useEffect(() => {
        handleChangeByGetEvent({ classSkills: skillsSelected });
    }, [skillsSelected]);

    React.useEffect(() => {
        const objSelected = Object.entries(selectedInstructors);
        if (objSelected.length > 0) {
            const selectedIns = objSelected.map(([key, value]) => {
                const ins = instructors.filter((i) => i.userId === value)[0];
                return {
                    isPrimary: key === "primaryInstructor",
                    userId: ins.userId,
                    firstName: ins.firstName,
                    lastName: ins.lastName
                };
            });
            handleChangeByGetEvent({ instructors: selectedIns });
        }
    }, [selectedInstructors]);

    React.useEffect(() => {
        const additional = additionalDays
            .filter((day) => day.show)
            .map((day) => ({
                id: day.id,
                date: new Date(day.date).toISOString(),
                initialHours: day.initialHours,
                refresherHours: day.refresherHours
            }));
        handleChangeByGetEvent({ classDates: [{ id: "primaryDay", ...primaryDay }, ...additional] });
    }, [additionalDays, primaryDay]);

    const {
        formFieldData,
        errors,
        handleCheckErrorsExist,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit,
        handleResetErrors
    } = useForm(handleCreateClass, validateRules);

    React.useEffect(() => {
        let desiredLms = null;

        if (
            formFieldData.specialty === "PF" ||
            formFieldData.specialty === "DEB" ||
            (orgLmsSettings?.usesCpiLms && !orgLmsSettings?.usesExternalLms)
        )
            desiredLms = "internal";
        else if (
            (orgLmsSettings?.usesCpiLms && orgLmsSettings?.usesExternalLms) ||
            (!orgLmsSettings?.usesCpiLms && orgLmsSettings?.usesExternalLms)
        )
            desiredLms = "external";
        else if (
            formFieldData.trainingFormat === "classroom" ||
            (!orgLmsSettings?.usesCpiLms && !orgLmsSettings?.usesExternalLms)
        )
            desiredLms = null;

        handleChangeByGetEvent({
            desiredLms
        });
    }, [orgLmsSettings]);

    React.useEffect(() => {
        if (
            orgLmsSettings &&
            !orgLmsSettings.usesExternalLms &&
            !orgLmsSettings.usesCpiLms &&
            formFieldData.desiredLms === null &&
            formFieldData.trainingFormat
        ) {
            const formats = trainingFormats.filter((i) => i.value !== "blended");
            setTrainingFormats(formats);
            handleChangeByGetEvent({ trainingFormat: formats.filter((format) => format.checked)[0]?.value });
        }
    }, [formFieldData.desiredLms, formFieldData.trainingFormat, orgLmsSettings]);

    React.useEffect(() => {
        setOrgSeats([]);
    }, [formFieldData.specialtyEdition, formFieldData.specialty]);

    React.useEffect(() => {
        // if there's only one trainingFormat and it's not virtual, set it as classroom, otherwise null
        if (formFieldData.trainingFormat === "virtual") {
            handleChangeByGetEvent({ classroomFormat: null, classroomFormatBlended: null });
        } else {
            handleChangeByGetEvent({ classroomFormat: "classroom", classroomFormatBlended: "classroom" });
        }
    }, [formFieldData.trainingFormat, trainingFormats]);

    React.useEffect(() => {
        if (selectedProgram) {
            getOrgLmsSettings();
            getClassSettings();
            getTypesOfTraining();
            getProgramEditions();
        } else {
            handleResetInstructorChange();
        }
    }, [selectedProgram]);

    React.useEffect(() => {
        if (selectedProgram && formFieldData.specialtyEdition) {
            getTrainingFormats();
        }
    }, [selectedProgram, formFieldData.specialtyEdition, formFieldData.additionalContent]);

    React.useEffect(() => {
        if (formFieldData.specialty && formFieldData.specialtyEdition) {
            getSkills();
            getLanguagesAndIndustries();
        }
    }, [
        formFieldData.specialty,
        formFieldData.specialtyEdition,
        formFieldData.additionalContent,
        formFieldData.lmsQualification
    ]);

    React.useEffect(() => {
        if (
            (formFieldData.classroomLanguage &&
                formFieldData.classroomIndustry &&
                formFieldData.specialtyEdition &&
                formFieldData.specialty) ||
            formFieldData.specialty == "PF" ||
            formFieldData.specialty == "DEB"
        ) {
            getCourseObject();
        }
    }, [
        formFieldData.classroomLanguage,
        formFieldData.classroomIndustry,
        formFieldData.specialty,
        formFieldData.specialtyEdition
    ]);

    const handleSelectWorkbook = (edition: string) => {
        handleChangeByGetEvent({ specialtyEdition: edition });
        setWorkbooks((prevState) => {
            return prevState.map((workbook) => ({
                ...workbook,
                isSelected: workbook.edition === edition
            }));
        });
    };

    React.useEffect(() => {
        if (formFieldData.hasAdditionalContent && selectedProgram)
            getAdditionalContent(formFieldData.instructors.map((i: any) => i.userId));
    }, [formFieldData.specialtyEdition, formFieldData.hasAdditionalContent, selectedProgram]);

    const handleChangeSpecialty = (e: FormSelectOption) => {
        setSelectedProgram(e.value);
        handleChangeByGetEvent({ specialtyEdition: null });
        handleChangeByGetEvent({ specialty: e.value });
    };

    const handleChangeAdditionalContent = (e: FormSelectOption) => {
        handleChangeByGetEvent({ additionalContent: e.value });
    };

    const handleChangeTrainingType = (e: FormSelectOption) => {
        handleChangeByGetEvent({ trainingType: e.value });
    };

    const handleChangeExpirationDate = (e: any) => {
        setExpirationDate((prevState) =>
            prevState.map((training) => ({ ...training, checked: training.id === e.target.id }))
        );
    };

    const handleChangeTrainingFormat = (e: any) => {
        setTrainingFormats((prevState) =>
            prevState.map((format) => ({ ...format, checked: format.id === e.target.id }))
        );
        handleChangeByGetEvent({ trainingFormat: e.target.value });
    };

    const handleExpirationDateUntil = (e: any, training: any) => {
        const date = new Date(e.target.value).toISOString();
        setExpirationDate((prevState) =>
            prevState.map((valid: any) => (valid.id === training.id ? { ...valid, value: date } : valid))
        );
    };

    const isUntilSelected = expirationDate.find((i) => i.id === "expirationDate5").checked;

    React.useEffect(() => {
        const expDate = expirationDate.filter((i) => i.checked)[0];
        if (expDate) handleChangeByGetEvent({ expirationDate: expDate.value });
    }, [expirationDate]);

    const showAlert: boolean = Object.entries(errors).length > 0;

    React.useEffect(() => {
        if (workbooks && workbooks.length > 0) {
            setSelectedEdition(workbooks.filter((edition) => edition.isSelected)[0]);
        }
    }, [workbooks]);

    React.useEffect(() => {
        if (selectedEdition) handleChangeByGetEvent({ hasAdditionalContent: selectedEdition.hasAdditionalContent });
    }, [selectedEdition]);
    const additionalContentOptions = () => {
        // translate additional content label strings, handling with/without default value cases
        let additionalContentOptionsWithDefault =
            returnAdditionalContent([selectedEdition.defaultContent, ...additionalContent]).map((option) => {
                option.label = tp(option.label) as any;
                return option;
            }) || [];
        let additionalContentOptionsWithoutDefault =
            returnAdditionalContent(additionalContent).map((option) => {
                option.label = tp(option.label) as any;
                return option;
            }) || [];

        if (selectedEdition.defaultContent) {
            return [{ label: t("CPI.UI.OrganizationType.None"), value: null }, ...additionalContentOptionsWithDefault];
        }
        return [{ label: t("CPI.UI.OrganizationType.None"), value: null }, ...additionalContentOptionsWithoutDefault];
    };

    React.useEffect(() => {
        if (formFieldData.trainingFormat === "blended") {
            if (
                formFieldData.additionalContent === "MH" ||
                formFieldData.additionalContent === "ASD" ||
                formFieldData.additionalContent === "TRA"
            ) {
                handleChangeByGetEvent({
                    lmsQualificationRadio: "yes",
                    lmsQualification: formFieldData.additionalContent
                });
            } else {
                handleChangeByGetEvent({ lmsQualificationRadio: null, lmsQualification: null });
            }
        } else {
            handleChangeByGetEvent({ lmsQualification: null, lmsQualificationRadio: null });
        }
    }, [formFieldData.additionalContent, formFieldData.trainingFormat]);

    React.useEffect(() => {
        if (formFieldData.trainingFormat === "blended") {
            if (formFieldData.lmsQualificationRadio === "yes") {
                handleChangeByGetEvent({ lmsQualification: formFieldData.additionalContent });
            } else {
                handleChangeByGetEvent({ lmsQualification: null });
            }
        } else {
            handleChangeByGetEvent({ lmsQualification: null, lmsQualificationRadio: null });
        }
    }, [formFieldData.lmsQualificationRadio, formFieldData.trainingFormat]);

    const handleChangeClassroomLanguage = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomLanguage: e.value
        });
    };

    const handleChangeClassroomIndustry = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomIndustry: e.value
        });
    };

    const handleChangeClassroomFormat = (e: any) => {
        handleChangeByGetEvent({
            classroomFormat: e.target.value
        });
    };

    const handleChangeClassroomFormatBlended = (e: any) => {
        handleChangeByGetEvent({
            classroomFormatBlended: e.target.value
        });
    };

    const handleChangeLmsSelection = (e: any) => {
        handleChangeByGetEvent({
            desiredLms: e.target.value
        });
    };

    const handleChangeLmsQualificationRadio = (e: any) => {
        handleChangeByGetEvent({ lmsQualificationRadio: e.target.value });
    };

    const handleChangeClassroomOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            classroomOrganization: e.value
        });
    };

    const handleChangeVirtualOrganization = (e: FormSelectOption) => {
        handleChangeByGetEvent({
            virtualOrganization: e.value
        });
    };

    const handleCreate = (event: any) => {
        // If there are any dates in the past and the class is blended on the CPI LMS - show the modal
        if (
            formFieldData.desiredLms === "internal" &&
            formFieldData.trainingFormat === "blended" &&
            !handleCheckErrorsExist() &&
            areAnyDatesPast(formFieldData.classDates)
        ) {
            setShowClassDatePast(true);
            // Otherwise just submit the class update
        } else {
            handleSubmit(event, true);
        }
    };

    const handleDatePastSuccess = (event: any) => {
        setShowClassDatePast(false);
        handleSubmit(event, true);
    };

    return (
        <>
            <div className="page-content m-auto">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <h1 className="mt-4 page-title-h1">{t("New Class")}</h1>
                        </Col>
                    </Row>
                    <div className="alert-wrapper mt-4">{returnPageLevelAlerts(pageLevelAlerts)}</div>
                    {createAlert?.show && (
                        <div className="alert-wrapper mt-4">
                            <Alert
                                removeAlertParentHandler={removeCreateAlert}
                                state={createAlert.alertLevel}
                                arrowLink={createAlert.arrowLink}
                                description={createAlert.description}
                                canDismiss={createAlert.canDismiss}
                            />
                        </div>
                    )}
                    <PopUpModal
                        title="Class Date"
                        size="md"
                        content={
                            <ClassDatePast
                                onSuccess={handleDatePastSuccess}
                                onClose={() => setShowClassDatePast(false)}
                            />
                        }
                        show={showClassDatePast}
                        showFooter={false}
                        hide={() => setShowClassDatePast(false)}
                    />
                    <Tile title={t("Class Details")}>
                        <>
                            {showAlert && (
                                <div className="class-create-detail-item mb-4">
                                    <Alert
                                        canDismiss={false}
                                        description={t("Class-Create-Error")}
                                        state={{ alertLevel: "error" }}
                                    />
                                </div>
                            )}
                            {shouldBOE && <BOESelector isReadOnly />}
                            <div className="class-create-detail-item-column">
                                <TextInput
                                    id="className"
                                    name="className"
                                    label={t("Class Name")}
                                    isError={errors.hasOwnProperty("className")}
                                    value={formFieldData.className}
                                    changeAction={handleChangeByUserEvent}
                                    errorLabel={errors.className}
                                    isRequired
                                />
                            </div>
                            <div className="class-create-detail-item-column">
                                <AdditionalInstructor
                                    id="instructors"
                                    name="instructors"
                                    isError={errors.hasOwnProperty("instructors")}
                                    errorLabel={errors.instructors}
                                    setSelected={setSelectedInstructors}
                                    additionalInstructors={additionalInstructors}
                                    setAdditionalInstructors={setAdditionalInstructors}
                                    preselectedOption={rbac.userContext.PingId}
                                    preselectable={detected !== undefined}
                                    selectedOptions={selectedInstructors}
                                    removable
                                    isRequired
                                    options={
                                        instructors && instructors.length > 0
                                            ? [...returnInstructorsCertifications(instructors)]
                                            : []
                                    }
                                />
                            </div>
                            <div className="class-create-detail-item-column">
                                <Select
                                    id="specialty"
                                    label={t("CPI Program")}
                                    name="specialty"
                                    isError={errors.hasOwnProperty("specialty")}
                                    isRequired
                                    value={formFieldData.specialty}
                                    changeFilterAction={handleChangeSpecialty}
                                    filter
                                    errorLabel={errors.specialty}
                                    options={[...returnPrograms(programs)]}
                                />
                            </div>
                            {(classSettings.hasWorkbook || workbooks.length > 1) && (
                                <>
                                    <div className="class-create-detail-item">
                                        <SelectWorkbookList
                                            id="specialtyEdition"
                                            name="specialtyEdition"
                                            isRequired
                                            isError={errors.hasOwnProperty("specialtyEdition")}
                                            label={t("Participant-Workbook-using")}
                                            selectWorkbook={handleSelectWorkbook}
                                            workbooks={workbooks}
                                            errorLabel={errors.specialtyEdition}
                                        />
                                    </div>
                                    {additionalContent &&
                                        additionalContent.length > 0 &&
                                        selectedEdition &&
                                        selectedEdition.hasAdditionalContent && (
                                            <div className="class-create-detail-item-column">
                                                <Select
                                                    id="additionalContent"
                                                    label={t("Additional Content")}
                                                    name="additionalContent"
                                                    isError={errors.hasOwnProperty("additionalContent")}
                                                    isRequired
                                                    value={formFieldData.additionalContent}
                                                    changeFilterAction={handleChangeAdditionalContent}
                                                    filter
                                                    errorLabel={errors.additionalContent}
                                                    defaultValue
                                                    options={additionalContentOptions()}
                                                />
                                            </div>
                                        )}
                                </>
                            )}
                            {typesOfTraining && typesOfTraining.length > 0 && (
                                <>
                                    <div className="class-create-detail-item-column">
                                        <Select
                                            id="trainingType"
                                            label={t("Type of Training")}
                                            name="trainingType"
                                            isError={errors.hasOwnProperty("trainingType")}
                                            isRequired
                                            value={formFieldData.trainingType}
                                            changeFilterAction={handleChangeTrainingType}
                                            filter
                                            errorLabel={errors.trainingType}
                                            options={[...returnTypesOfTraining(typesOfTraining)]}
                                        />
                                    </div>
                                    {classSettings && classSettings.dates && (
                                        <div className="class-create-detail-item class-create-date-hours">
                                            <DateHours
                                                primaryDay={primaryDay}
                                                setPrimaryDay={setPrimaryDay}
                                                removable
                                                settings={{ dates: classSettings.dates, hours: classSettings.hours }}
                                                typeOfTraining={formFieldData.trainingType}
                                                additionalDays={additionalDays}
                                                setAdditionalDays={setAdditionalDays}
                                                errors={errors}
                                                isError={errors.hasOwnProperty("classDates")}
                                                errorLabel={errors.classDates}
                                                isRequired
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {classSettings && classSettings.expiration && (
                                <div className="class-create-detail-item class-create-training-valid">
                                    <div>
                                        <RadioList
                                            id="training-valid"
                                            name="training-valid"
                                            isRequired
                                            onChange={handleChangeExpirationDate}
                                            label={t("How-Long-Training-Valid")}
                                            errorLabel={t("Select date for training")}
                                            radios={expirationDate.map((training) => {
                                                if (training.label === "Until") {
                                                    return {
                                                        ...training,
                                                        label: (
                                                            <div className="class-create-row gap">
                                                                <span>Until Date</span>
                                                                {isUntilSelected && (
                                                                    <DateInput
                                                                        onChange={(e) =>
                                                                            handleExpirationDateUntil(e, training)
                                                                        }
                                                                        id="expirationDate"
                                                                        name="expirationDate"
                                                                        isRequired={false}
                                                                        showLabel={false}
                                                                        overrideOptionalLabel=""
                                                                        value={training.value}
                                                                        isActive={training.checked}
                                                                        isReadOnly={false}
                                                                        isError={errors.hasOwnProperty(
                                                                            "expirationDate"
                                                                        )}
                                                                        errorLabel={errors.expirationDate}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    };
                                                }
                                                return training;
                                            })}
                                        />
                                    </div>
                                    <div className="class-create-center-icon">
                                        <p className="class-create-alert">
                                            <i className="fa-sharp fa-light fa-circle-info class-create-alert-icon"></i>
                                            {t("Expiration-Date-Alert")}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {trainingFormats && trainingFormats.length > 0 && (
                                <div className="class-create-detail-item-w70">
                                    <TrainingFormats
                                        trainingFormats={trainingFormats}
                                        orgSeats={returnOrganizationsSeats(orgSeats)}
                                        orgLmsSettings={orgLmsSettings}
                                        onChangeFormat={handleChangeTrainingFormat}
                                        formData={formFieldData}
                                        editable={true}
                                        isMigrated={false}
                                        courses={courses}
                                        errors={errors}
                                        handleChangeVirtualOrganization={handleChangeVirtualOrganization}
                                        handleChangeClassroomFormat={handleChangeClassroomFormat}
                                        handleChangeClassroomFormatBlended={handleChangeClassroomFormatBlended}
                                        handleChangeLmsSelection={handleChangeLmsSelection}
                                        handleChangeClassroomIndustry={handleChangeClassroomIndustry}
                                        handleChangeClassroomLanguage={handleChangeClassroomLanguage}
                                        handleChangeClassroomOrganization={handleChangeClassroomOrganization}
                                        handleChangeLmsQualificationRadio={handleChangeLmsQualificationRadio}
                                    />
                                </div>
                            )}

                            {ITLClassSkills &&
                                classSettings.skills &&
                                skills &&
                                skills.categories &&
                                skills.categories.length > 0 && <PhysicalSkills data={skills} />}

                            <div className="class-create-row mt-5">
                                <DestructiveButton
                                    clickAction={() => navigate(PlatformBaseRoutes.DashboardClasses.fullPath)}
                                    additionalStyleClasses="inline-icon-left cancel-button"
                                    label={
                                        <>
                                            <i className="fa-light fa-xmark fa-sharp" /> {t("Cancel")}
                                        </>
                                    }
                                />

                                <Button
                                    elementId="create-class-button"
                                    label={
                                        <div className="d-flex align-items-center justify-content-center">
                                            {isLoading && <i className="fas fa-spinner fa-spin mr-2" />}{" "}
                                            {t("Create Class")}
                                        </div>
                                    }
                                    clickAction={handleCreate}
                                    isSolid
                                />
                            </div>
                        </>
                    </Tile>
                </Container>
            </div>
        </>
    );
};

export default ClassCreatePage;
