import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import parseHtml from 'html-react-parser';

const useParsedTranslation = () => {

    const { t } = useTranslation();

    const tp = (content:string, props?:any) => {
        return (props) ? parseHtml(t(content, props)) : parseHtml(t(content));
    }

    return { tp }
}

export default useParsedTranslation;