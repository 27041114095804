import * as React from "react";
import { MenuItemObject } from "./action-menu-item";
import useDetectClick from "../../../hooks/useDetectClick";

const ActionMenu = (props: {
    elementId?: string,
    isOpen?: boolean,
    menuItemsArr?: React.ReactElement<{data: MenuItemObject}>[]
}): JSX.Element => {
    const ref = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const { clickOutside } = useDetectClick(ref);

    const toggle = () => setIsOpen(!isOpen);

    const findScrollingParent = (element: HTMLElement | null): HTMLElement | null => {
        if (!element) return null;
    
        let parent = element.parentElement as HTMLElement;
        let lastElement: HTMLElement | null = null;

        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            if (overflowY === 'auto' || overflowY === 'scroll') {
                return parent;
            }
            if (parent.className.toLowerCase() === 'custom-table') {
                lastElement = parent;
            }
            parent = parent.parentElement as HTMLElement;
        }
    
        return null; 
    };

    React.useEffect(() => {
        if (isOpen && ref.current) {
            const menuElement = ref.current.querySelector('.action-button-list') as HTMLDivElement;
            const menuButtonElement = ref.current as HTMLDivElement;
    
            const parentElement = findScrollingParent(ref.current);
            if (menuElement && parentElement) {
                const menuRect = menuElement.getBoundingClientRect();
                const parentRect = parentElement.getBoundingClientRect();
    
                if (menuRect.bottom > parentRect.bottom) {
                    const buttonRect = menuButtonElement.getBoundingClientRect();
                    const offset = menuRect.bottom - parentRect.bottom;
                    parentElement.scrollTop += offset;
                }
            }
        }
    }, [isOpen]);

    React.useEffect(() => {
        // If menu is open and click outside element, close current menu
        isOpen && setIsOpen(!clickOutside);
    }, [clickOutside]);

    return (
        <div className="action-button-container">
            <div className="action-button clear-button" id={(props.elementId) ? props.elementId : ''} ref={ref} onClick={toggle}>
                <i className="fa-sharp fa-regular fa-ellipsis" />
                <div className={isOpen ? "show action-button-list" : "hide d-none"}>
                    {props.menuItemsArr.map((menuItem, index) => <div key={`menu-item-${index}`} className={`${!menuItem.props.data?.disabled ? 'action-button-pointer' : 'action-button-default'}`}>{menuItem}</div>)}
                </div>
            </div>
        </div>
    );

};

export default ActionMenu;
