import * as React from "react";

import { Button, Container, Form, Modal } from "react-bootstrap";

import DatePicker from "../../Components/date-picker";
import { OrganizationInvoiceModel, SeatExpirationUpdateModel, StaffSelectModel } from "../../Interfaces";
import { UpdateOrganizationSeat } from "../Utils/organization";

import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from "../../configuration-context";

export interface SeatEditFormPageProps {
    orgSeatId: string;
    staffMembers: StaffSelectModel[];
    editSeatModalCurrentExpirationDate: string;

    isVisible: boolean;
    onHide: () => void;
    handleSaveComplete: (seatUpdatedResponse: OrganizationInvoiceModel) => void;
}

const SeatEditFormPage = (props: SeatEditFormPageProps) => {
    const { t } = useTranslation();
    const configContext = React.useContext(ConfigContext);
    const { userContext } = React.useContext(RbacContext);

    const formRef = React.useRef<HTMLFormElement>();
    const [validated, setValidated] = React.useState(false);

    const [saving, setSaving] = React.useState(false);
    const [seatExpirationUpdate, setSeatExpirationUpdate] = React.useState({
        newExpirationDate: null,
        requestor: null,
        reason: null
    });

    const handleCancel = () => {
        props.onHide();
        setValidated(false);
        setSeatExpirationUpdate({
            newExpirationDate: null,
            requestor: null,
            reason: null
        });
    };

    const handleSubmit = async () => {
        const isValid = formRef.current.checkValidity();
        setValidated(true);
        if (isValid) {
            try {
                setSaving(true);
                const staffMember = props.staffMembers.find((s) => s.id === seatExpirationUpdate.requestor);  
                const seatExpirationUpdated = {
                    seatPoolId: props.orgSeatId,
                    expirationDate: seatExpirationUpdate.newExpirationDate,
                    userId: userContext.sub,
                    userFirstName: userContext.firstName,
                    userLastName: userContext.lastName,
                    requestorId: seatExpirationUpdate.requestor,
                    requestorFirstName: staffMember?.firstName,
                    requestorLastName: staffMember?.lastName,
                    reason: seatExpirationUpdate.reason
                };
                const seatUpdatedResponse = await UpdateOrganizationSeat(configContext, seatExpirationUpdated);
                props.handleSaveComplete(seatUpdatedResponse);
                setSeatExpirationUpdate({
                    newExpirationDate: null,
                    requestor: null,
                    reason: null
                });
                setSaving(false);                
                setValidated(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }           
        }
    };

    const buildRequestorList = () => {
        const { requestor } = seatExpirationUpdate;
        const values = [];
        if (requestor === null) {
            values.push(
                <option selected disabled key="empty" value="">
                    {t("Choose requestor")}
                </option>
            );
        }
        props.staffMembers?.forEach((staff) =>
            values.push(
                <option key={staff.id} value={staff.id}>
                    {staff.firstName} {staff.lastName}
                </option>
            )
        );
        return values;
    };

    const renderForm = () => {
        return (
            // @ts-ignore
            <Form noValidate validated={validated} ref={formRef}>
                <Container>
                    <Form.Group controlId="currentExpirationDate">
                        <Form.Label>{t("Current Expiration Date")}</Form.Label>
                        <Form.Label>{props.editSeatModalCurrentExpirationDate}</Form.Label>
                    </Form.Group>
                    <DatePicker
                        id="newExpirationDate"
                        required
                        value={seatExpirationUpdate.newExpirationDate}
                        onChange={(d) => setSeatExpirationUpdate({ ...seatExpirationUpdate, newExpirationDate: d })}
                        label={t("New Expiration Date")}
                    />
                    <Form.Group controlId="requestor">
                        <Form.Label>{t("Requestor")}</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            required
                            value={seatExpirationUpdate.requestor || ""}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                setSeatExpirationUpdate({
                                    ...seatExpirationUpdate,
                                    requestor: e.target.value
                                })
                            }
                        >
                            {buildRequestorList()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="reason">
                        <Form.Label>{t("Reason")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            required
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                setSeatExpirationUpdate({
                                    ...seatExpirationUpdate,
                                    reason: e.target.value
                                })
                            }
                        />
                    </Form.Group>
                </Container>
            </Form>
        );
    };

    return (
        <Modal show={props.isVisible} onHide={handleCancel} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>{t("Edit Expiration Date")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleCancel} disabled={saving}>
                    {t("Cancel")}
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? (
                        <>
                            <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                        </>
                    ) : (
                        t("Save")
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withAITracking(reactPlugin, SeatEditFormPage);
