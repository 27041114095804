import * as React from "react";

import { ApiResponse, JsonResponseModel } from "../../ApiServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createGeneralSetting, updateGeneralSetting } from "../../ApiServices/OrganizationSettings";
import { getDefaultValue, getInputProps } from "../../form-helpers";

import { HoneyBeeSettingDefinition } from "../../Interfaces/HoneyBeeSettingDefinition";
import { OrgSettingModel } from "../../Interfaces";
import { RbacContext } from "../../rbac-context";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface GenerOrgSettingFormProps {
    isCreateForm?: boolean;
    existingSetting?: OrgSettingModel;
    isVisible: boolean;
    onSave: (resp: JsonResponseModel<ApiResponse>) => void;
    onHide: () => void;
    existingSettings: OrgSettingModel[];
    settingDefinitions: HoneyBeeSettingDefinition[];
}

const GeneralOrgSettingForm = ({
    existingSetting,
    isCreateForm,
    isVisible,
    onSave,
    onHide,
    existingSettings,
    settingDefinitions
}: GenerOrgSettingFormProps) => {
    const { t } = useTranslation();

    const { userContext } = React.useContext(RbacContext);

    const [validated, setValidated] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [setting, setSetting] = React.useState<OrgSettingModel>(
        isCreateForm
            ? {
                  OrgSettingId: -1,
                  Type: 1,
                  Value: "",
                  Active: true,
                  CreateDate: ""
              }
            : existingSetting
    );
    const [validSetting, setValidSetting] = React.useState(true);
    const [selectedSettingDef, setSelectedSettingDef] = React.useState<HoneyBeeSettingDefinition>(
        existingSetting ? settingDefinitions.find((def) => def.Type === existingSetting.Type) : settingDefinitions[0]
    );

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        try {
            const validSetting = !existingSettings.some(
                (setting) => setting.Type === setting.Type && setting.OrgSettingId !== setting.OrgSettingId
            );
            setValidSetting(validSetting);
            setValidated(true);
            if (form.checkValidity() && validSetting) {
                setSaving(true);
                const resp = isCreateForm ? await createGeneralSetting(setting) : await updateGeneralSetting(setting);
                onSave(resp);
            }
        } catch (err) {
            appInsights.trackException({ error: err, properties: userContext });
            console.error(err);
        }        
    };

    return (
        <Modal show={isVisible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Create Setting")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group as={Row} controlId="email-setting-name">
                        <Form.Label column sm="2">
                            {t("Setting Type")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                custom
                                required
                                isInvalid={!validSetting}
                                value={setting.Type}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    const newValue = parseInt(e.target.value);
                                    const newDefinition = settingDefinitions.find((def) => def.Type === newValue);
                                    setSelectedSettingDef(newDefinition);
                                    setSetting({
                                        ...setting,
                                        Type: newValue,
                                        Value:
                                            newDefinition.DataType !== selectedSettingDef.DataType
                                                ? getDefaultValue(newDefinition).toLocaleString()
                                                : setting.Value
                                    });
                                }}
                            >
                                {settingDefinitions.map((def) => (
                                    <option key={def.Type} value={def.Type}>
                                        {def.TypeName}
                                    </option>
                                ))}
                            </Form.Control>
                            {!validSetting && validated && (
                                <Form.Control.Feedback type="invalid">
                                    {t("A setting of this type already exists.")}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="email-setting-settingId">
                        <Form.Label column sm="2">
                            {t("Value")}
                        </Form.Label>
                        <Col>
                            <Form.Control
                                maxLength={255}
                                value={setting.Value}
                                {...getInputProps(setting, selectedSettingDef, setSetting)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="active">
                        <Form.Label column sm="2">
                            {t("Active")}
                        </Form.Label>
                        <Col>
                            <Form.Check
                                custom
                                type="switch"
                                id="active"
                                label=" "
                                className="col-form-label"
                                checked={setting.Active}
                                onChange={() => setSetting({ ...setting, Active: !setting.Active })}
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" disabled={saving} onClick={onHide}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" disabled={saving}>
                        {saving ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> {t("Saving")}
                            </>
                        ) : (
                            t("Save")
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default withAITracking(reactPlugin, GeneralOrgSettingForm);
