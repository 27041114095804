import * as React from "react";
import Select from "../../../Components/Form/select";
import TextArea from "../../../Components/Form/text-area";
import DateInput from "../../../Components/Form/date-input";
import Radio from "../../../Components/Form/radio/radio";
import type { IClassDetails } from "../../../Interfaces/Platform/Classes";
import { useTranslation } from "react-i18next";

const TrainingFormatsNonEdit = (props: {
    classDetails: IClassDetails,
    orgSeats: { label: string, value: string }[],
    trainingFormats: any[],
    isMigrated: boolean
}) => {
    const [currentLanguage, setCurrentLanguage] = React.useState(null);
    const [languages, setLanguages] = React.useState<any[]>([]);
    const [industries, setIndustries] = React.useState<any[]>([]);
    const { t } = useTranslation();

    const currentFormat = props.trainingFormats.filter((format) => format.value === props.classDetails?.trainingFormat)[0];

    const displayOrganizationSeat = () => {
        return props.orgSeats?.filter((i) => i.value === props.classDetails?.seatOrganizationId)[0]?.label;
    };

    const displayFormat: any = {
        blended: (
            <div>
                <p className="text-uppercase semi-bold fs14">{t("Training Format")}</p>
                <p>{currentFormat?.label}</p>
                <div className="training-formats-classroom py-3 px-4">
                    <p className="text-uppercase fw600 fs14">{t("Classroom-Portion-Conducted")}</p>
                    <p>{props.classDetails?.classroomFormat == "virtual" ? t("Virtual Meeting") : t("In-Person")}</p>
                    {!props.isMigrated && (
                        <>
                            {props.classDetails?.courseLanguage && (
                                <>
                                    <label className="bold form-label fs14 filter-select-label">{t("Online-Course-Language")}</label>
                                    <p>{props.classDetails?.courseLanguage}</p>
                                </>
                            )}
                            {props.classDetails?.industry && (
                                <>
                                    <label className="bold form-label fs14 filter-select-label">{t("Examples-Industry")}</label>
                                    <p>{props.classDetails?.industry}</p>
                                </>
                            )}
                            {displayOrganizationSeat() && (
                                <>
                                    <label className="bold form-label fs14 filter-select-label">{t("Organization-Seats")}</label>
                                    <p>{displayOrganizationSeat()}</p>
                                </>
                            )}
                        </>
                    )}                    
                </div>
            </div>
        ),
        classroom: (
            <div>
                <p className="text-uppercase semi-bold fs14">{t("Training Format")}</p>
                <p>{currentFormat?.label}</p>
                <div className="training-formats-classroom py-3 px-4">
                    <p className="text-uppercase fw600 fs14">{t("Classroom-Conducted")}</p>
                    <p>{props.classDetails?.classroomFormat == "virtual" ? t("Virtual Meeting") : t("In-Person")}</p>
                </div>
            </div>
        ),
        virtual: (
            <div>
                <p className="text-uppercase semi-bold fs14">{t("Training Format")}</p>
                <p>{currentFormat?.label}</p>
                <div className="training-formats-classroom py-3 px-4">
                    <>
                        <label className="bold form-label fs14 filter-select-label">{t("Organization-Seats")}</label>
                        <p>{displayOrganizationSeat()}</p>
                    </>
                </div>
            </div>
        )
    };

    return (
        <div className="my-4">
            {displayFormat[props.classDetails?.trainingFormat]}
        </div>
    );
};

export default TrainingFormatsNonEdit;
