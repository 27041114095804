import * as React from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import Button from "../../../Components/UI/button";
import { getUserClaims } from "../../../helper-functions";
import DestructiveButton from "../../../Components/UI/cancel-button";
import TextInput from "../../../Components/Form/text-input";
import EnrollParticipantStatus from "../Enroll-Participants-Modal/enroll-participant-status";
import Select from "../../../Components/Form/select";
import useForm from "../../../hooks/useForm";
import { updateParticipant, enrollParticipant, createClassParticipant } from "../../Utils/participants";
import { fetchClassSeats } from "../../Utils/class";
import validateEditRules from "../../../FormValidationRules/editEnrollParticipant";
import validateEditRulesWithoutEmail from "../../../FormValidationRules/editEnrollParticipantWithoutEmail";
import validateEditRulesEmailClassroom from "../../../FormValidationRules/editEnrollParticipantEmailClassroom";
import { IEnrollParticipant } from "../../../Interfaces/Platform/Participants";
import FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import { ConfigContext } from "../../../configuration-context";
import { IClassDetails } from "../../../Interfaces/Platform/Classes";
import type { ShowAlert } from "../../../Interfaces/Platform/AlertProperties";
import { enrollClassParticipant } from "../../../stores/classParticipantsSlice";
import { enrollParticipantToClass } from "../../../stores/classDetailsSlice";
import { useTranslation } from "react-i18next";

interface Props extends IEnrollParticipant {
    handleAddToClass(): void;
    isEnrolling: boolean;
    hasEnrolled: boolean;
    organizations: any[];
    seats: number;
    seatsRequired: boolean;
    onEditParticipant: (participantId: string) => void;
    setParticipantsEnrolled: (participants: number) => void;
    getParticipants: () => void;
    addPageLevelAlert: any;
    showSeatsAlert: (alert: ShowAlert) => void;
    class: IClassDetails;
    canEditParticipants: boolean;
    canEnrollParticipants: boolean;
}

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const EnrollParticipantListingNonMobile = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const dispatch = useDispatch();
    const culture = getUserClaims()?.culture ?? "en-US";
    const [edit, setEdit] = React.useState<boolean>(false);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const handleEdit = async () => {
        setIsUpdating(true);
        const isGoingToEnroll = (((props.class.trainingFormat != 'classroom') && ((props.seats > 0) || !props.seatsRequired)) || (props.class.trainingFormat == 'classroom')) && (!props.inClassAlready) && (props.canEnrollParticipants);
        const participantData = {
            participantId: props.participantId,
            externalId: formFieldData.externalId,
            firstName: formFieldData.firstName,
            lastName: formFieldData.lastName,
            email: formFieldData.email,
            organizationId: formFieldData.organizationId,
            organizationName: formFieldData.organizationName,
            userId: props.userId,
            organizationIds: [...props.organizations.map((i) => i.organizationId)],
            orgId: props.organizations.filter((i) => i.organizationId === formFieldData.organizationId)[0].orgId,
            enrolled: isGoingToEnroll,
            locale: culture,
            trainingFormat: props.class.trainingFormat,
        };

        if (isGoingToEnroll) {
            let seats = 0;
            if (props.seatsRequired) {
                seats = await fetchClassSeats(apimBaseUrl, configContext, { organizationId: props.class.seatOrganizationId, courseId: props.class.courseId });
            }
            if (seats > 0 || !props.seatsRequired) {
                try {
                    const update = await updateParticipant(apimBaseUrl, configContext, participantData);
    
                    if (update.participantStatus === 'Updated'){
                        const enrollParticipant = await createClassParticipant(apimBaseUrl, configContext, {
                            classId: props.class.classId,
                            participantId: update.participant.participantId,
                            userId: update.participant.userId,
                            firstName: update.participant.firstName,
                            lastName: update.participant.lastName,
                            email: update.participant.email,
                            externalId: update.participant.externalId,
                            participantCreated: update.participant.participantCreated,
                            userCreated: update.participant.userCreated
                        });
                        if (enrollParticipant.status === 202) {
                            setTimeout(() => {
                                props.setParticipantsEnrolled(1);
                                setIsUpdating(false);
                                onEdit(false, props.participantId);
                                props.getParticipants();
                            }, 3000);
                            dispatch(enrollParticipantToClass());
                            dispatch(enrollClassParticipant(enrollParticipant.data));
                        }
                    } else if (update.participantStatus === 'AlreadyExists') {
                        setIsUpdating(false);
                        props.addPageLevelAlert({
                            alertLevel: { alertLevel: "error" },
                            description: t("Email-Already-Exists"),
                            canDismiss: true,
                        });
                    } else {
                        setIsUpdating(false);
                        props.addPageLevelAlert({
                            alertLevel: { alertLevel: "error" },
                            description: t("Saving-Participant-Error"),
                            canDismiss: true,
                        });
                    }
                } catch (e) {
                    setIsUpdating(false);
                    props.addPageLevelAlert({
                        alertLevel: { alertLevel: "error" },
                        description: t("Saving-Participant-Error"),
                        canDismiss: true,
                    });
                }
            } else {
                setIsUpdating(false);
                props.showSeatsAlert({
                    alertLevel: { alertLevel: "error" },
                    description: tp("Seats-Remaining-Error", { numberOfSeats: '0', specialty: t(props.class.specialty) }),
                    canDismiss: true,
                    arrowLink: { label: "Buy Seats", href: `${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning` }
                });
            }
        } else {
            try {
                const update = await updateParticipant(apimBaseUrl, configContext, participantData);

                if (update.participantStatus === 'Updated'){
                    setTimeout(() => {
                        setIsUpdating(false);
                        onEdit(false, props.participantId);
                        props.getParticipants();
                    }, 3000);
                } else if (update.participantStatus === 'AlreadyExists') {
                    setIsUpdating(false);
                    props.addPageLevelAlert({
                        alertLevel: { alertLevel: "error" },
                        description: t("Email-Already-Exists"),
                        canDismiss: true,
                    });
                } else {
                    setIsUpdating(false);
                    props.addPageLevelAlert({
                        alertLevel: { alertLevel: "error" },
                        description: t("Saving-Participant-Error"),
                        canDismiss: true,
                    });
                }
            } catch (e) {
                setIsUpdating(false);
                props.addPageLevelAlert({
                    alertLevel: { alertLevel: "error" },
                    description: t("Saving-Participant-Error"),
                    canDismiss: true,
                });
            }
        }
    };

    const handleChangeOrganization = (e: FormSelectOption) => handleChangeByGetEvent({ organizationId: e.value });

    const isEmailRequired = ((props.seatsRequired && (props.class.trainingFormat !== "classroom")) || (props.email !== null && props.email !== undefined && props.email.length > 0));
    const emailValidation = () => {
        if (props.class.trainingFormat === 'classroom') {
            // If an email exists, validate with email required, otherwise don't require it.
            // This is because we don't allow users to remove an email from participants once one exists.
            if (props.email !== null && props.email !== undefined && props.email.length > 0) {
                return validateEditRulesEmailClassroom;
            } else {
                return validateEditRulesWithoutEmail;
            }
        } else {
            // If seats are required, ensure that the participant has a valid email.
            // Otherwise, don't require an email. (though it runs the regex if one is present)
            if (props.seatsRequired) {
                return validateEditRules;
            } else {
                return validateEditRulesWithoutEmail;
            }
        }
    };
    const {
        formFieldData,
        errors,
        handleChangeByGetEvent,
        handleChangeByUserEvent,
        handleSubmit,
        handleValidate
    } = useForm(handleEdit, emailValidation());

    const onEdit = (state: boolean, participantId: string) => {
        setEdit(state);
        props.onEditParticipant(participantId);
    };

    React.useEffect(() => {
        handleChangeByGetEvent({
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
            organizationId: props.organizationId,
            organizationName: props.organizationName,
            externalId: props.externalId,
        });
    }, []);

    const handleShowEdit = (show: boolean) => {
        if (props.canEditParticipants) {
            if (!show) {
                handleChangeByGetEvent({
                    firstName: props.firstName,
                    lastName: props.lastName,
                    email: props.email,
                    organizationId: props.organizationId,
                    organizationName: props.organizationName,
                    externalId: props.externalId,
                });
            }
            onEdit(show, props.participantId);
        }
        return;
    };

    const handleAddToClass = () => {
        if (!props.seatsRequired || ((props.class.trainingFormat !== 'classroom' && props.email) || props.class.trainingFormat === 'classroom')) {
            props.handleAddToClass();
        } else {
            onEdit(true, props.participantId);
            handleValidate();
        }
    };

    return (
        <>
            <td className="participant-listing-container participant-listing-info">
                {!edit && (
                    (props.inClassAlready || props.hasEnrolled) ? (
                        <div className="enroll-create-participant-in-class">
                            <i className="fa-light fa-sharp fa-check enroll-create-participant-in-class-icon" /> {t("In Class")}
                        </div>
                    ) : (
                        (props.seatsRequired && props.seats === 0) ? (
                            <Button
                                additionalStyleClasses="enroll-participant-button"
                                    href={`${configContext.SystemConfiguration.PublicSite}/plp?id=1073741825&fkey=ProductTypeList%3AWorkbooks+with+Blended+Learning&fkey=ProductTypeList%3AOnline+Learning`}
                                isSolid={false}
                                label={t("Buy Seats")}
                            />
                        ) : (
                            <Button
                                additionalStyleClasses="enroll-participant-button"
                                clickAction={handleAddToClass}
                                isSolid={false}
                                isDisabled={props.isEnrolling || !props.canEnrollParticipants}
                                label={
                                    <div className="d-flex align-items-center justify-content-center">
                                        {props.isEnrolling ? <><i className="fas fa-spinner fa-spin mr-2"/> {t("Enrolling")}</> : <>{t("Add to Class")}</>}
                                    </div>
                                }
                            />
                        )
                    )
                )}
            </td>
            <td className="participant-listing-container participant-listing-info">
                <p className="fw600 participant-listing-text">{props.firstName} {props.lastName}</p>
            </td>
            <td className="participant-listing-container participant-listing-info">
                <p className="participant-listing-text small text-gray-600">{props.email}</p>
            </td>
            <td className="participant-listing-container participant-listing-info">
                <p className="participant-listing-text small text-gray-600">{props.organizationName}</p>
            </td>
            <td className="participant-listing-container participant-listing-info">
                <p className="participant-listing-text small text-gray-600">{props.externalId}</p>
            </td>
            <td className="participant-listing-container participant-listing-info">
                {props?.history && <EnrollParticipantStatus certification={props.history} />}
            </td>
            <td>
                {props.canEditParticipants && <a href="#" onClick={() => handleShowEdit(!edit)} className="fw600"><i className="fa-light fa-pen mr-2 text-dkprimary"/></a>}
            </td>
            {edit && (
                <div className="enroll-participant-listing-edit">
                    <div className="enroll-participant-listing-edit-container">
                        <div className="enroll-participant-listing-edit-form">
                            <div className="d-flex align-items-end">
                                <TextInput
                                    isRequired
                                    label={t("First Name")}
                                    name="firstName"
                                    id="firstName"
                                    maxLength={50}
                                    value={formFieldData.firstName}
                                    changeAction={handleChangeByUserEvent}
                                    isError={errors.hasOwnProperty("firstName")}
                                    errorLabel={errors.firstName}
                                    errorPosition="position-absolute"
                                    isReadOnly={props.contactId !== null}
                                />
                            </div>
                            <div className="d-flex align-items-end">
                                <TextInput
                                    isRequired
                                    label={t("Last Name")}
                                    name="lastName"
                                    id="lastName"
                                    maxLength={50}
                                    value={formFieldData.lastName}
                                    changeAction={handleChangeByUserEvent}
                                    isError={errors.hasOwnProperty("lastName")}
                                    errorLabel={errors.lastName}
                                    errorPosition="position-absolute"
                                    isReadOnly={props.contactId !== null}
                                />
                            </div>
                            <div className="d-flex align-items-end">
                                <TextInput
                                    isRequired={isEmailRequired}
                                    label={t("Email")}
                                    name="email"
                                    id="email"
                                    maxLength={150}
                                    value={formFieldData.email}
                                    changeAction={handleChangeByUserEvent}
                                    isError={errors.hasOwnProperty("email")}
                                    errorLabel={errors.email}
                                    errorPosition="position-absolute"
                                    isReadOnly={props.contactId !== null}
                                />
                            </div>
                            <div className="d-flex align-items-end">
                                <Select
                                    label={t("Organization")}
                                    isRequired
                                    name="organizationId"
                                    additionalClass="class-participant-listing-select"
                                    id="organizationId"
                                    filter
                                    changeFilterAction={handleChangeOrganization}
                                    isError={errors.hasOwnProperty("organizationId")}
                                    errorLabel={errors.organizationId}
                                    errorPosition="position-absolute"
                                    value={formFieldData.organizationId}
                                    options={props.organizations.map((i) => ({ label: i.organizationName, value: i.organizationId }))}
                                    isReadOnly={props.contactId !== null}
                                />
                            </div>
                            <TextInput
                                isRequired={false}
                                label={t("External ID")}
                                name="externalId"
                                id="externalId"
                                value={formFieldData.externalId}
                                changeAction={handleChangeByUserEvent}
                                isError={errors.hasOwnProperty("externalId")}
                                errorLabel={errors.externalId}
                            />
                        </div>
                    </div>
                    <div className="enroll-participant-listing-edit-actions">
                        <DestructiveButton
                            label={<><i className="fa-light fa-sharp fa-xmark"/> {t("Cancel")}</>}
                            clickAction={() => handleShowEdit(false)}
                        />
                        <Button
                            isSolid
                            label={
                                <div className="d-flex align-items-center justify-content-center">
                                    {isUpdating && <i className="fas fa-spinner fa-spin mr-2"/>} {(((props.class.trainingFormat != 'classroom') && ((props.seats > 0) || !props.seatsRequired)) || (props.class.trainingFormat == 'classroom')) && (!props.inClassAlready) && (props.canEnrollParticipants) ? 'Save & Add to Class' : 'Save'}
                                </div>
                            }
                            clickAction={handleSubmit}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default EnrollParticipantListingNonMobile;
