import React, { useEffect } from "react";
import { getToken } from "../../helper-functions";
import * as jwt_decode from 'jwt-decode';

// This component is used to handle user token refreshes
// It listens for user interaction in the form of clicks
// The component maintains a state of our time until token expiration
// If the user interacts, we check if we're approaching that expiration
// If so, we set a state to indicate we're refreshing the token so if the user is clicking rapidly, we don't spam the server
// We then hit our refresh endpoint to get a new token (saved to cookies)
// After 5 seconds, we set the state back to false so clicks can trigger another refresh once needed
// The expiration check should now have a refreshed value

// Additionally, we also check our token expiration once per minute
// This will happen regardless of user interaction
// If we're approaching token expiration, we may indicate to the user that their session is about to expire (may not be MVP)
// If the user remains idle and we reach token expiration, we navigate the user to our logout path
// This will ultimately force them back to the login page

const UserTokenHandler: React.FC = () => {
    const [ tokenIsRefreshing, setTokenIsRefreshing ] = React.useState(false);
    const tokenIsRefreshingRef = React.useRef(tokenIsRefreshing);

    // Just using the browser URL to determine if we're in a lower env
    // There's certainly a better way to do this, but we're outside React contexts here so it's a little bit tougher to manage
    // Maybe this component should be moved to <Page> so we can use the app config context
    const isLowerEnvironment = (window.location.href.includes("localhost") || window.location.href.includes("dev-account") || window.location.href.includes("test-account") || window.location.href.includes("stage-account"));
    const isDevOrTest = (window.location.href.includes("dev-account") || window.location.href.includes("test-account"));

    // This ref is used to keep track of the tokenIsRefreshing state
    // It ensures code that reads it will have the latest value
    useEffect(() => {
        tokenIsRefreshingRef.current = tokenIsRefreshing;
    }, [tokenIsRefreshing]);

    // TOKEN USER INTERACTION CHECK
    // This effect is used to check the token expiration on user interaction
    // It handles calling our honeybee refresh endpoint to get new tokens as we approach expiration
    useEffect(() => {
        const handleClick = async () => {
            if (isLowerEnvironment) console.log("Click detected, check if token needs refresh...");
            let token = getToken("accessToken");

            // If the accessToken is about to expire, refresh it
            // This should keep the session alive for anyone using the site
            // First just making sure the token exists before we act on it
            if (token) {
                const decodedToken = jwt_decode.jwtDecode<any>(token);
                const currentTime = Math.floor(Date.now() / 1000);
                const expirationTime = decodedToken.exp;
                const timeUntilExpiration = expirationTime - currentTime;
        
                // Console log minutes until token expiration
                if (isLowerEnvironment) console.log("Minutes until token expiration:", timeUntilExpiration / 60);

                // If we're on dev or test, refresh the token sooner at the 45 minute mark
                // Otherwise, refresh at 30 minutes left
                // Note that the value counts down to this point
                let refreshThreshold = (isDevOrTest) ? 45 : 30;

                // If we're on dev or test, force logout the user sooner at the 31 minute mark
                // Otherwise, at 1 minute left
                // Note that the value counts down to this point
                const logoutThreshold = (isDevOrTest) ? 31 : 1;
        
                // If the token is within 30 minutes of expiration, refresh it
                // This effectively means, if the user walks away, their session will remain active for at least 30 minutes
                //
                // Additionally, if the user walked away and didn't get forced out already
                // we catch that here and force logout - it's a last resort.
                if ((timeUntilExpiration / 60) < refreshThreshold) {
                    // If timeUntilExpiration is less than 0, force logout
                    if ((timeUntilExpiration / 60) <= logoutThreshold) {
                        window.location.href = "/Logout";
                    }

                    if (!tokenIsRefreshingRef.current) {
                        if (isLowerEnvironment) console.log("Refreshing token...");
                        setTokenIsRefreshing(true);

                        try {
        
                            // Call to get new tokens
                            // This will result in a new cookie set with the following new tokens:
                            // - accessToken
                            // - refreshToken
                            // - idToken
                            // The endpoint returns nothing, which is why I arbitrarily set 5 seconds to wait before setting tokenIsRefreshing back to false
                            // Preventing spam will ensure we maintain the same token in our cookie that ping expects
                            fetch("/Refresh", {
                                method: "GET"
                            });
                            
                        } catch (error) {
                            console.error("Error refreshing token:", error);
                            setTokenIsRefreshing(false);
                        }

                        // Set tokenIsRefreshing back to false after 5 seconds
                        setTimeout(() => {
                            setTokenIsRefreshing(false);
                        }, 5000);
                    }
                    
                }
            }

        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    // TOKEN AUTOCHECK
    // This effect is used to check for token expiration once per minute
    // Once the token is within a minute of expiration, we force logout
    //
    // We now also check for when a user returns to the tab.
    //
    // There's also a check for initial component mount, so if the user closes
    // the tab and reopens, it'll check right away and force logout immediately if needed.
    useEffect(() => {
        const checkTokenExpiration = async () => {
            let token = await getToken("accessToken");

            if (token) {
                const decodedToken = jwt_decode.jwtDecode<any>(token);
                const currentTime = Math.floor(Date.now() / 1000);
                const expirationTime = decodedToken.exp;
                const timeUntilExpiration = expirationTime - currentTime;

                if (isLowerEnvironment) console.log("[AutoCheck] Minutes until token expiration:", timeUntilExpiration / 60);

                // If we're on dev or test, force logout the user sooner at the 31 minute mark
                // Otherwise, at 1 minute left
                // Note that the value counts down to this point
                const logoutThreshold = (isDevOrTest) ? 31 : 1;

                // If the token is within 1 minute of expiration, force logout
                // We do this at a minute to ensure no period of time exists where tokens are expired
                // since the next check won't be for a full minute
                if ((timeUntilExpiration / 60) <= logoutThreshold) {
                    window.location.href = "/Logout";
                }
            }
        };

        // If the users computer goes to sleep or
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                checkTokenExpiration();
            }
        }

        // Check token expiration once per minute
        const intervalId = setInterval(checkTokenExpiration, 60000);

        // Check token expiration on visibility change (back to visible)
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Run an initial token check on component mount
        checkTokenExpiration();

        // Remove listeners/intervals on unmount
        return () => {
            clearInterval(intervalId);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return null; // This component does not render anything
};

export default UserTokenHandler;