import * as React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isExternalURL } from "../../helper-functions";

const FeaturedLink = (props: {
    action?: Function,
}): JSX.Element => {
    const location = useLocation();
    const isActive = location.pathname === "/Dashboard";

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isExternalURL('/Dashboard')) {
            event.preventDefault();
            props.action && props.action();
            navigate('/Dashboard');
        }
    };

    return (
        <>
            <div className={`main-nav-header ${isActive ? 'current' : ''}`}>
                <a onClick={handleClick} className="nav-item-header" id="mainnavDashboard" href="/Dashboard">
                    <span className="nav-item-text icon-dashboard">{ t("Dashboard") }</span>
                </a>
            </div>
        </>
    );
};

export default FeaturedLink;
