import * as React from "react";
import { useTranslation } from "react-i18next";

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

interface Props {
    certification: any;
}

const EnrollParticipantStatus = (props: Props): JSX.Element => {
    const { certification } = props;
    const { t } = useTranslation();
    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const getDateFromString = (date: string) => {
        const [year, month, day] = date.split('T')[0].split('-');
        return new Date(`${month}-${day}-${year}`).setHours(0,0,0,0);
    };

    const displayDate = (date: string) => {
        return new Date(date).toLocaleString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
    };

    const currentDate = new Date().setHours(0,0,0,0);
    const startDate = getDateFromString(certification.startDate);
    const endDate = getDateFromString(certification.endDate);
    const expirationDate = getDateFromString(certification.expirationDate);

    // Enrolled AND Not Submitted | In Progress
    if (!certification.ceSubmitted) {
        return (
            <>
                <p className="fw600 participant-listing-text text-gray-600 text-capitalize enroll-participant-listing-status">
                    <i className="fa-regular fa-sharp fa-arrows-rotate participant-listing-status-icon-progress mr-2"/> {t("In Progress")}
                </p>
            </>
        );
    } else {
        // Submitted AND Failed
        if (certification.status === 'fail') {
            if (currentDate > expirationDate) {
                // Expired
                return (
                    <>
                        <p className="fw600 participant-listing-text text-gray-600 text-capitalize enroll-participant-listing-status">
                            <i className="fa-light fa-sharp fa-diamond-exclamation participant-listing-status-icon-expired mr-2"/> {t("Expired")}
                        </p>
                        <span className="small participant-listing-text text-gray-600">On {displayDate(certification.expirationDate)}</span>
                    </>
                );
            } else {
                // Failed
                return (
                    <>
                        <p className="fw600 participant-listing-text text-gray-600 text-capitalize enroll-participant-listing-status">
                            <i className="fa-light fa-xmark participant-listing-status-icon-fail mr-2"/> {t("Failed")}
                        </p>
                    </>
                );
            }
        }
        // Submitted AND Passed | Active
        if (certification.status === 'pass') {
            return (
                <>
                    <p className="fw600 participant-listing-text text-gray-600 text-capitalize enroll-participant-listing-status">
                        <i className="fa-light fa-check participant-listing-status-icon mr-2"/> {t("Active")}
                    </p>
                    <span className="small participant-listing-text text-gray-600">Until {displayDate(certification.expirationDate)}</span>
                </>
            );
        }
    }
};

export default EnrollParticipantStatus;
