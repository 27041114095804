import * as React from "react";

import { Alert, Col, Container, Row } from "react-bootstrap";
import { InstructorBaseRoutes, LearnerBaseRoutes } from "../Routing/routes";
import { Link, Redirect, useLocation } from "react-router-dom";

import { RbacContext } from "../rbac-context";
import ResetPasswordForm from "./Components/reset-password-form";
import ResetPasswordModal from "./Components/reset-password-modal";
import { RoleEnum } from "../Enums";
import { fetchPasswordResetInfo } from "../ApiServices/Login";
import { isAuthenticated } from "../helper-functions";
import { parse } from "query-string";
import { useToast } from "../hooks/toast";
import { useTranslation } from "react-i18next";

import { appInsights, reactPlugin } from '../application-insights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { toast } = useToast();

    const token = React.useMemo(() => {
        const query = parse(location.search);
        return query.token as string;
    }, []);

    const { userContext } = React.useContext(RbacContext);
    const [loading, setLoading] = React.useState(true);
    const [invalidToken, setInvalidToken] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [resetEmail, setResetEmail] = React.useState("");
    const [redirect, setRedirect] = React.useState("");
    const [resetting, setResetting] = React.useState(false);

    React.useEffect(() => {
        const getInfo = async () => {
            try {
                const resp = await fetchPasswordResetInfo(token);
                if (resp.Error) {
                    setInvalidToken(true);
                } else {
                    setEmail(resp.Data.Email);
                }
                setLoading(false);
            } catch (err) {
                appInsights.trackException({ error: err, properties: userContext });
                console.error(err);
            }
            
        };

        getInfo();
    }, []);

    if (redirect) {
        return <Redirect to={redirect} push={true} />;
    }

    if (!resetting && isAuthenticated()) {
        if (redirect) {
            return <Redirect to={redirect} />;
        }

        if (
            userContext.Roles.some(
                (r) =>
                    r === RoleEnum.BusinessAdministrator ||
                    r === RoleEnum.CertifiedInstructor ||
                    r === RoleEnum.Developer
            )
        ) {
            return <Redirect to={InstructorBaseRoutes.Class.Class.fullPath} />;
        }

        return <Redirect to={redirect || LearnerBaseRoutes.Learn.fullPath} />;
    }

    return (
        <Container fluid>
            <Row>
                <Col md={6} lg={4} xl={3} className="mx-auto">
                    <h1 className="mb-4">{t("Reset Password")}</h1>
                    {loading && (
                        <div className="mt-4 fa-3x">
                            <i className="fas fa-spinner fa-spin position-absolute " style={{ left: "50%" }}></i>
                        </div>
                    )}
                    {invalidToken && (
                        <Alert variant="info">
                            {t("Password reset link has expired.")}{" "}
                            <Link
                                to=""
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowModal(true);
                                }}
                            >
                                {t("Please click here to resend a password reset link.")}
                            </Link>
                        </Alert>
                    )}
                    {!!resetEmail && (
                        <Alert variant="success">
                            <p className="text-break">
                                {t("A password reset link email has been sent to {{emailAddress}}.", {
                                    emailAddress: resetEmail
                                })}{" "}
                                {t("If you do not receive an email, please contact your instructor.")}
                            </p>
                        </Alert>
                    )}
                    {!loading && !invalidToken && (
                        <ResetPasswordForm
                            email={email}
                            token={token}
                            buttonText={t("Reset Password")}
                            activeButtonText={t("Resetting")}
                            onResetting={() => setResetting(true)}
                            onReset={(resp) => {
                                if (resp.Error) {
                                    toast(resp);
                                } else {
                                    setRedirect(LearnerBaseRoutes.Learn.fullPath);
                                }
                            }}
                        />
                    )}
                </Col>
            </Row>
            {showModal && (
                <ResetPasswordModal
                    show={showModal}
                    onSend={(email) => {
                        setResetEmail(email);
                        setShowModal(false);
                    }}
                    handleCancel={() => setShowModal(false)}
                />
            )}
        </Container>
    );
};

export default withAITracking(reactPlugin, ResetPasswordPage);
