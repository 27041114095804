import * as React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ActionMenu from "../../../Components/UI/action-menu-component/action-menu";
import ActionMenuItem from "../../../Components/UI/action-menu-component/action-menu-item";
import ProgressBar from "../../../Components/UI/progress-bar";
import TextInput from "../../../Components/Form/text-input";
import Select from "../../../Components/Form/select";
import ClassParticipantStatus from "./class-participant-status";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";

import { fetchParticipantOnlineCourseCertificate } from "../../Utils/participants";
import { openPdfFromBase64InNewTab } from "../../Utils/pdf";

import useForm from "../../../hooks/useForm";
import type FormSelectOption from "../../../Interfaces/Platform/FormSelectOption";
import type { IClassDetails } from "../../../Interfaces/Platform/Classes";
import type { IClassParticipant } from "../../../Interfaces/Platform/Participants";
import {
    validateEditRules,
    validateEditCredentialNumberRules,
    validateSubmitBlueCardIdRules,
    validateSubmitCredentialNumberRules,
    validateSubmitStatusRules,
    validateSubmitTypeRules,
} from "../../../FormValidationRules/classParticipants";
import {
    updateClassParticipantBlueCard,
    updateClassParticipantStatus,
    updateClassParticipantType,
    removeParticipantFromClass,
    resendOnlineEmail,
    updateClassParticipantSubmitted
} from "../../Utils/class";
import { ConfigContext } from "../../../configuration-context";
import useDetectWindowSize from "../../../hooks/useDetectWindowSize";
import { RbacContext } from "../../../rbac-context";

interface Props extends IClassParticipant {
    onCheck: (checked: boolean, id: string) => void;
    indexOfList: number;
    classDetails: IClassDetails;
    canEditClassParticipants: boolean;
    canEditParticipants: boolean;
    canSendEnrollmentEmail: boolean;
    canTransferParticipants: boolean;
    canViewCertificates: boolean;
    displayCheckbox: boolean;
    addPageLevelAlert: any;
    setSubmittedErrors: any;
    handleEditDataParticipant: any;
    isBlueCardIdDuplicated: boolean;
    learningHistoryId: string;
    dateCompleted: string;
    isBlueCardRequiredForSubmit: boolean;
    seatsRequired: boolean;
    participantCanSubmit: boolean;
    canSubmitParticipant: boolean;
    updateBlueCard: (classParticipantId: string, blueCardId: string) => void;
    onViewEditParticipant: (classParticipantId: string) => void;
    onEditParticipant: (classParticipantId: string) => void;
    onRemovePartipantFromClass: (classParticipant: any) => void;
    onSubmitRecord: (classParticipantId: string) => void;
    onResendOnlineEmail: (isError: boolean) => void;
}

const getLocale = (locale: string): Locale => {
    try {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/${locale}/index.js`);
    } catch {
        return require(/* webpackExclude: /_lib/ */ `date-fns/locale/en-GB/index.js`);
    }
};

const ClassParticipantListing = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const configContext = React.useContext(ConfigContext);
    const apimBaseUrl = configContext?.SystemConfiguration?.ApimBaseUrl;
    const { isMobile } = useDetectWindowSize();
    const [showToastBlueCard, setShowToastBlueCard] = React.useState<{ isLoading: boolean, show: boolean }>({ isLoading: false, show: false });
    const [showErrorBlueCard, setShowErrorBlueCard] = React.useState<string | React.ReactElement>("");
    const [showToastStatus, setShowToastStatus] = React.useState<{ isLoading: boolean, show: boolean }>({ isLoading: false, show: false });
    const [showErrorStatus, setShowErrorStatus] = React.useState<string | React.ReactElement>("");
    const [showToastType, setShowToastType] = React.useState<{ isLoading: boolean, show: boolean }>({ isLoading: false, show: false });
    const [showErrorType, setShowErrorType] = React.useState<string | React.ReactElement>("");
    const [edit, setEdit] = React.useState(false);

    const rbac = React.useContext(RbacContext);

    const SUCCESS = 1;
    const INVALID = 2;
    const ERROR = 3;

    React.useEffect(() => {
        if (props.isBlueCardRequiredForSubmit && (props.status === "pass" && (!props.blueCardIsValid || !props.blueCardId))) {
            setShowErrorBlueCard(props.classDetails?.specialty === 'DCC' ? t("Validate-Credential-Number-Chars") : t("Validate-Blue-Card"));
        } else { 
            setShowErrorBlueCard("");
        }
    }, [props.isBlueCardRequiredForSubmit]);

    const classIsExpired = new Date(props.classDetails?.expirationDate) < new Date();
    const isMigrated = props.classDetails?.imported === true ? true : false;

    //@ts-ignore
    const locale: string = getLocale(i18next.language) ?? getLocale("en-US");

    const participantName = `${props.firstName} ${props.lastName}`;

    const handleViewEditParticipant = (classParticipantId:string) => {
        props.onViewEditParticipant(classParticipantId);
    };

    const blueCardIdTimer = () => setTimeout(() => setShowToastBlueCard({ isLoading: false, show: false }), 3000);
    const statusTimer = () => setTimeout(() => setShowToastStatus({ isLoading: false, show: false }), 3000);
    const typeTimer = () => setTimeout(() => setShowToastType({ isLoading: false, show: false }), 3000);
    React.useEffect(() => {
        if (showToastBlueCard.show && !showToastBlueCard.isLoading) blueCardIdTimer();
        return () => clearTimeout(blueCardIdTimer());
    }, [showToastBlueCard]);

    React.useEffect(() => {
        if (showToastStatus.show && !showToastStatus.isLoading) statusTimer();
        return () => clearTimeout(statusTimer());
    }, [showToastStatus]);

    React.useEffect(() => {
        if (showToastType.show && !showToastType.isLoading) typeTimer();
        return () => clearTimeout(typeTimer());
    }, [showToastType]);

    React.useEffect(() => {
        handleChangeByGetEventSubmitStatus({
            status: props.status,
        });
    }, [props.status]);

    React.useEffect(() => {
        handleChangeByGetEventSubmitType({
            type: props.classType,
        });
    }, [props.classType]);

    React.useEffect(() => {
        handleChangeByGetEventSubmitBlueCardId({
            blueCardId: props.blueCardId,
            status: props.status
        });
    }, [props.blueCardId]);

    const handleEditParticipant = async (data: any) => {
        const request = {
            classId: props.classId,
            classParticipantId: props.classParticipantId,
            blueCardId: data.blueCardId,
            status: data.status !== props.status ? data.status : null,
            classType: data.type !== props.classType ? data.type : null
        };

        const response = await updateClassParticipantSubmitted(configContext, request);

        if (response.status === 423 || response.status === 500) {
            setShowToastBlueCard({
                isLoading: false,
                show: false
            });
            setShowErrorBlueCard(
                <>
                    {t("There-Was-A-Problem-Saving")}
                    <a className="class-participant-listing-error-action" onClick={() => handleSubmitParticipantBlueCardId(e)}>
                        {t("Try-Again")}
                    </a>
                </>
            );

            return;
        }

        props.updateBlueCard(props.classParticipantId, data.blueCardId);
        props.handleEditDataParticipant(props.classParticipantId, "blueCardId", data.blueCardId);

        if (response.message.blueCardIdUpdated) {
            props.handleEditDataParticipant(props.classParticipantId, "blueCardIsValid", true);
            setShowErrorBlueCard("");

            setShowToastBlueCard({
                show: true,
                isLoading: false
            });
        } else {
            props.handleEditDataParticipant(props.classParticipantId, "blueCardIsValid", false);

            if (data.status === "pass") {
                setShowErrorBlueCard(props.classDetails.specialty === "DCC" ? t("Validate-Credential-Number-Chars") : t("Enter-Valid-Blue-Card"));
            }
        }
        
        if (response.message.statusUpdated) {
            setShowToastStatus({ show: true, isLoading: false });
            props.handleEditDataParticipant(props.classParticipantId, "status", data.status);
        }

        if (response.message.trainingTypeUpdated) {
            setShowToastType({ show: true, isLoading: false });
            props.handleEditDataParticipant(props.classParticipantId, "classType", data.type);
        }

        setTimeout(() => {
            setEdit(false);
            props.onEditParticipant(props.classParticipantId);
        }, 700);
    };

    const handleSubmitParticipantBlueCardId = async (e: { blueCardId: string }) => {
        try {  
            if (e.blueCardId !== props.blueCardId) {
                setShowToastBlueCard({
                    show: true,
                    isLoading: true
                });
                const { status, message } = await updateClassParticipantBlueCard(apimBaseUrl, configContext, {
                    classParticipantId: props.classParticipantId,
                    participantId: props.participantId,
                    classId: props.classId,
                    blueCardId: !e.blueCardId || e.blueCardId.length <= 0 ? null : e.blueCardId,
                });

                // Success updating Blue Card Id
                if (message.value === SUCCESS) {
                    props.updateBlueCard(props.classParticipantId, e.blueCardId);
                    props.handleEditDataParticipant(props.classParticipantId, "blueCardId", e.blueCardId);
                    props.handleEditDataParticipant(props.classParticipantId, "blueCardIsValid", true);
                    setShowErrorBlueCard("");

                    setShowToastBlueCard({
                        show: true,
                        isLoading: false
                    });
                } else if (message.value === INVALID) {
                    props.updateBlueCard(props.classParticipantId, e.blueCardId);
                    props.handleEditDataParticipant(props.classParticipantId, "blueCardId", e.blueCardId);
                    props.handleEditDataParticipant(props.classParticipantId, "blueCardIsValid", false);

                    setShowToastBlueCard({
                        isLoading: false,
                        show: false
                    });

                    if (e.blueCardId) {
                        setShowErrorBlueCard(props.classDetails.specialty === "DCC" ? t("Validate-Credential-Number-Chars") : t("Enter-Valid-Blue-Card"));
                    }
                } else if (message.value === ERROR) {
                    // Handle any error
                    setShowToastBlueCard({
                        isLoading: false,
                        show: false
                    });
                    setShowErrorBlueCard(
                        <>
                            {t("There-Was-A-Problem-Saving")}
                            <a className="class-participant-listing-error-action" onClick={() => handleSubmitParticipantBlueCardId(e)}>
                                {t("Try-Again")}
                            </a>
                        </>
                    );
                }
            }
        } catch (e) {
            setShowToastBlueCard({
                isLoading: false,
                show: false
            });
            props.addPageLevelAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
        }
    };

    const handleSubmitParticipantStatus = async (status: string) => {
        setShowErrorStatus("");
        setShowToastStatus({ isLoading: true, show: true });
        try {
            if (status === props.status) {
                return;
            }

            const response = await updateClassParticipantStatus(apimBaseUrl, configContext, {
                classParticipantId: props.classParticipantId,
                participantId: props.participantId,
                classId: props.classId,
                status: status
            });
            if (response === 202) {
                setShowToastStatus({ show: true, isLoading: false });
                props.handleEditDataParticipant(props.classParticipantId, "status", status);
            } else {
                // Handle 500 error (timeout)
                setShowToastStatus({
                    isLoading: false,
                    show: false
                });
                setShowErrorStatus(
                    <>
                        {t("There-Was-A-Problem-Saving")}
                        <a className="class-participant-listing-error-action" onClick={() => handleSubmitParticipantStatus(status)}>
                            {t("Try-Again")}
                        </a>
                    </>
                );
            }
        } catch (e) {
            setShowToastStatus({ isLoading: false, show: false });
            props.addPageLevelAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
        }
    };

    const handleSubmitParticipantType = async (type: string) => {
        setShowErrorType("");
        setShowToastType({ isLoading: true, show: true });
        try {
            if (type === props.classType) {
                return;
            }

            const response = await updateClassParticipantType(apimBaseUrl, configContext, {
                classParticipantId: props.classParticipantId,
                participantId: props.participantId,
                classId: props.classId,
                classType: type
            });
            if (response === 202) {
                setShowToastType({ show: true, isLoading: false });
                props.handleEditDataParticipant(props.classParticipantId, "classType", type);
            } else {
                // Handle 500 error (timeout)
                setShowToastType({
                    isLoading: false,
                    show: false
                });
                setShowErrorType(
                    <>
                        {t("There-Was-A-Problem-Saving")}
                        <a className="class-participant-listing-error-action" onClick={() => handleSubmitParticipantType(type)}>
                            {t("Try-Again")}
                        </a>
                    </>
                );
            }
        } catch (e) {
            setShowToastType({ isLoading: false, show: false });
            props.addPageLevelAlert({
                alertLevel: { alertLevel: "error" },
                description: e.message,
                canDismiss: true
            });
        }
    };

    const {
        formFieldData: formFieldDataEdit,
        errors: errorsEdit,
        handleChangeByGetEvent: handleChangeByGetEventEdit,
        handleChangeByUserEvent: handleChangeByUserEventEdit,
        handleSubmit: handleSubmitEdit,
        handleResetErrors: handleResetErrorsEdit,
    } = useForm(handleEditParticipant, props.classDetails.specialty === 'DCC' ? validateEditCredentialNumberRules : validateEditRules);

    const {
        formFieldData: formFieldDataSubmitBlueCardId,
        errors: errorsSubmitBlueCardId,
        handleChangeByGetEvent: handleChangeByGetEventSubmitBlueCardId,
        handleChangeByUserEvent: handleChangeByUserEventSubmitBlueCardId,
        handleSubmit: handleSubmitBlueCardId,
        handleResetErrors: handleResetErrorsSubmitBlueCardId,
    } = useForm(handleSubmitParticipantBlueCardId, props.classDetails.specialty === 'DCC' ? validateSubmitCredentialNumberRules : validateSubmitBlueCardIdRules);

    const {
        formFieldData: formFieldDataSubmitStatus,
        errors: errorsSubmitStatus,
        handleChangeByGetEvent: handleChangeByGetEventSubmitStatus,
        handleChangeByUserEvent: handleChangeByUserEventSubmitStatus,
        handleSubmit: handleSubmitStatus,
        handleResetErrors: handleResetErrorsSubmitStatus,
    } = useForm(handleSubmitParticipantStatus, validateSubmitStatusRules);

    const {
        formFieldData: formFieldDataSubmitType,
        errors: errorsSubmitType,
        handleChangeByGetEvent: handleChangeByGetEventSubmitType,
        handleChangeByUserEvent: handleChangeByUserEventSubmitType,
        handleSubmit: handleSubmitType,
        handleResetErrors: handleResetErrorsSubmitType,
    } = useForm(handleSubmitParticipantType, validateSubmitTypeRules);

    const onEdit = (state: boolean, classParticipantId: string) => {
        if (props.canEditClassParticipants && props.canEditParticipants) {
            setEdit(state);
            props.onEditParticipant(classParticipantId);
            if (state) {
                handleChangeByGetEventEdit({
                    blueCardId: props.blueCardId,
                    status: props.status,
                    type: props.classType,
                    blueCardRequired: props.classDetails.blueCardRequired
                });

                if (props.blueCardId && !props.blueCardIsValid) {
                    setShowErrorBlueCard(props.classDetails?.specialty === 'DCC' ? t("Validate-Credential-Number-Chars") : t("Validate-Blue-Card"));
                } else { 
                    setShowErrorBlueCard("");
                }
            } else {
                handleChangeByGetEventEdit({
                    blueCardId: "",
                    status: "",
                    type: ""
                });
            }
        }
        return;
    };

    const handleChangeStatus = (e: FormSelectOption) => handleChangeByGetEventEdit({ status: e.value });
    const handleChangeSubmitStatus = (e: FormSelectOption) => {
        handleChangeByGetEventSubmitStatus({ status: e.value });
        handleSubmitParticipantStatus(e.value);
        handleChangeByGetEventSubmitBlueCardId({ status: e.value });
    };
    const handleChangeType = (e: FormSelectOption) => handleChangeByGetEventEdit({ type: e.value });
    const handleChangeSubmitType = (e: FormSelectOption) => {
        handleChangeByGetEventSubmitType({ type: e.value });
        handleSubmitParticipantType(e.value);
    };

    const handleChangeBlueCard = (e: any) => {
        handleChangeByGetEventSubmitBlueCardId({ blueCardId: e.target.value });
    };

    const handleRemoveParticipantFromClass = async (participant:any) => {
        removeParticipantFromClass(apimBaseUrl, configContext, {
            classParticipantId: props.classParticipantId,
            participantId: participant.participantId,
            classId: participant.classId
        }).then((response: any) => {
            if(response.statusText === "Accepted")
                props.onRemovePartipantFromClass(participant);
        });
    };

    const handleResendEnrollmentEmail = async () => {
        var response = await resendOnlineEmail(apimBaseUrl, configContext, props.classParticipantId, props.classId);

        response?.status === 202 ? props.onResendOnlineEmail(false) : props.onResendOnlineEmail(true) ;
    };

    const endDateObject = new Date(props.classDetails?.endDate);
    const startDateObject = new Date(props.classDetails?.startDate.split('T')[0]);
    const participantDateStartedObject = props.dateStarted ? new Date(props.dateStarted) : null;
    const classHasEnded = endDateObject.setHours(0,0,0,0) <= new Date().setHours(0,0,0,0);
    const classHasStarted = new Date().setHours(0,0,0,0) >= startDateObject.setHours(0,0,0,0);
    const todayDateObject = new Date();
    const daysDifferenceTime = participantDateStartedObject ? (todayDateObject.getTime() - participantDateStartedObject.getTime()) : null;
    const oneDayTime = 24 * 3600 * 1000;
    const daysBetween = daysDifferenceTime ? (Math.ceil(daysDifferenceTime / oneDayTime)) : null;

    const renderActionMenu = () => {
        let actionMenu = [
            <ActionMenuItem data={{ label: t("View/Edit Participant"), clickAction: () => { handleViewEditParticipant(props.classParticipantId)} }} />,
        ];

        // Does not apply for online-only classes | Remove after expiration date
        if (props.classDetails?.trainingFormat !== 'virtual' && (!classIsExpired || props.canSubmitParticipant) && classHasStarted) {
            // If partiicpant hasn't been submitted
            if (!props.ceSubmitted) {
                if (props.canSubmitParticipant && props.canEditClassParticipants) {
                    actionMenu.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Submit-Training-Record"), clickAction: () => props.onSubmitRecord(props.classParticipantId) }} />);
                }
            } else if (!isMigrated) {
                // If participant has been submitted and class it not imported
                actionMenu.push(<ActionMenuItem data={{ disabled: (!props.canEditClassParticipants || edit), label: t("Update-Training-Record"), clickAction: () => { onEdit(true, props.classParticipantId)} }} />);
            }
        }

        // Only Resend for Blended OR Virtual AND Feature Flag AND course NOT complete
        if ((props.classDetails?.trainingFormat === 'blended' || props.classDetails?.trainingFormat === 'virtual') && props.canSendEnrollmentEmail && (props.percentComplete?.toString() !== '100') && !isMigrated) {
            actionMenu.push(<ActionMenuItem data={{ label: t("ReSend-Enrollment-Email"), clickAction: () => handleResendEnrollmentEmail() }} />);
        }

        // Not in scope
        /*if (props.canTransferParticipants) {
            actionMenu.push(<ActionMenuItem data={{ label: t("Transfer-To-Another-Class"), clickAction: () => {} }} />);
        }*/

        // Display for blended and online-only when participant's online course status is complete
        if ((props.percentComplete && props.percentComplete.toString() === '100') && (!((props.classDetails?.trainingFormat === 'classroom') && (rbac.userContext.Country == "en-US"))) && props.canViewCertificates && !isMigrated) {
            actionMenu.push(<ActionMenuItem data={{ label: t("View-Online-Course-Certificate"), clickAction: () => {
                // Fetch and open certificate in new tab if learningHistoryId is present
                if (props.learningHistoryId) {
                    fetchParticipantOnlineCourseCertificate(apimBaseUrl, rbac.userContext.Country, configContext, [props.learningHistoryId]).then((response: any) => {
                        openPdfFromBase64InNewTab(response.certificates[0]);
                    });
                }                 

            } }} />);
        }
        // Remove when class is expired AND remove after a participant has been submitted
        if (!classIsExpired && props.dateCompleted === null) {
            if (props.classDetails?.trainingFormat === 'classroom') {
                if (!props.ceSubmitted) {
                    actionMenu.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(props) } }} />);
                }
            } else {
                if (!classHasStarted && !props.ceSubmitted) {
                    // Course has not started
                    actionMenu.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(props) } }} />);
                } else {
                    // Course has started, but not completed within 90 days
                    if (props.percentComplete?.toString() !== '100' && (!daysBetween || (daysBetween <= 90)) && !props.ceSubmitted) {
                        actionMenu.push(<ActionMenuItem data={{ disabled: !props.canEditClassParticipants, label: t("Remove-From-Class"), clickAction: () => { handleRemoveParticipantFromClass(props) } }} />);
                    }
                }
            }
        }
        return actionMenu;
    };

    const handleBlueCardEnter = (e) => {
        if (e.key === "Enter") {
            handleSubmitBlueCardId(e);
        }
    };

    const handleBlueCardEdit = (e) => {
        if (e.key === "Enter") {
            handleSubmitEdit(e);
        }
    }

    React.useEffect(() => {
        if (!props.blueCardIsValid && props.blueCardId && props.blueCardId.length > 0) {
            setShowErrorBlueCard(props.classDetails?.specialty === 'DCC' ? t("Validate-Credential-Number-Chars") : t("Validate-Blue-Card"));
        } else {
            setShowErrorBlueCard("");
        }
    }, [props.blueCardIsValid])

    React.useEffect(() => {
        if (showErrorBlueCard) {
            props.setSubmittedErrors(prevState => ({ ...prevState, [props.classParticipantId]: true }));
        } else {
            props.setSubmittedErrors(prevState => ({ ...prevState, [props.classParticipantId]: false }));
        }
    }, [showErrorBlueCard]);

    return !isMobile ? (
        <>
            {(props.displayCheckbox && props.classDetails?.trainingFormat !== "virtual") && (
                <td className={`class-participant-listing-checkbox-container participant-listing-container participant-listing-checkbox-container ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-r`}>
                    {!props.ceSubmitted && (
                        <input
                            onChange={(e) => props.onCheck(e.target.checked, props.classParticipantId)}
                            className="form-checkbox participant-listing-checkbox"
                            id={`${props.externalId}-participant-checkbox`}
                            checked={props.isChecked}
                            name="participant-checkbox"
                            type="checkbox"
                        />
                    )}
                </td>
            )}
            <td className={`participant-listing-container participant-listing-info class-participant ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                <p className={`${(props.displayCheckbox && props.classDetails?.trainingFormat !== "virtual") ? "" : "ml-3"} mb-2 fw600 color-black participant-listing-text`}>{participantName}</p>
                {props.email && <p className={`${(props.displayCheckbox && props.classDetails?.trainingFormat !== "virtual") ? "" : "ml-3"} mb-2 small participant-listing-text`}>{props.email}</p>}
                {props.externalId && <p className={`${(props.displayCheckbox && props.classDetails?.trainingFormat !== "virtual") ? "" : "ml-3"} mb-0 small participant-listing-text`}>{props.externalId}</p>}
            </td>
            { ((props.classDetails.trainingFormat !== "classroom") && (!isMigrated) && (props.classDetails.desiredLms == 'internal')) && (
                    <td className={`participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                        <ProgressBar
                            startedDate={props.dateStarted}
                            widthBar={100}
                            onlineCoursePercentComplete={props.percentComplete ? props.percentComplete : 0}
                            completedDate={props.dateCompleted ? new Date(props.dateCompleted) : null}
                            minified
                        />
                </td>
                )
            }
            {props.classDetails?.trainingFormat !== "virtual" && props.classDetails?.blueCardRequired && (
                <td className={`participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                    {!props.ceSubmitted ? (
                        <div>
                            <TextInput
                                onBlur={handleSubmitBlueCardId}
                                showLabel={false}
                                label="blueCardId"
                                name="blueCardId"
                                changeAction={handleChangeBlueCard}
                                removeMargin
                                id="blueCardId"
                                isReadOnly={!props.canEditClassParticipants || !props.canEditParticipants}
                                displayAsText={props.canEditClassParticipants && props.canEditParticipants}
                                value={formFieldDataSubmitBlueCardId.blueCardId ?? ''}
                                isError={(errorsSubmitBlueCardId.hasOwnProperty("blueCardId") || showErrorBlueCard) && !showToastBlueCard.isLoading}
                                errorLabel={errorsSubmitBlueCardId.blueCardId ?? showErrorBlueCard}
                                onKeyDown={handleBlueCardEnter}
                            />
                            {showToastBlueCard.show && (
                                <div className="class-participant-listing-toast">
                                    {showToastBlueCard.isLoading ? (
                                        <>
                                            <i className="fas fa-spinner fa-spin mr-2"/>
                                            {t("Saving")}
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa-sharp fa-light fa-check mr-2"/>
                                            {t("Saved")}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className="small participant-listing-text">{props.blueCardId}</p>
                    )}
                </td>
            )}
            {props.classDetails?.trainingFormat !== "virtual" && (
                <td className={`participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                    {!props.ceSubmitted ? (
                        <div className="participant-listing-select">
                            <Select
                                label=""
                                showLabel={false}
                                isRequired
                                isDisabled={!props.canEditClassParticipants || !props.canEditParticipants}
                                name="status"
                                additionalClass="class-participant-listing-select"
                                id="status"
                                filter
                                changeFilterAction={handleChangeSubmitStatus}
                                isError={errorsSubmitStatus.hasOwnProperty("status") || showErrorStatus}
                                errorLabel={errorsSubmitStatus.status ?? showErrorStatus}
                                iconBeforeTextClass={`${(formFieldDataSubmitStatus.status === "pass" || formFieldDataSubmitStatus.status === null) ? 'class-participant-listing-select-pass': 'class-participant-listing-select-fail'}`}
                                iconBeforeOptionClass={(data: FormSelectOption) => `${(data.value === "pass" || data.value === null) ? 'class-participant-listing-select-pass': 'class-participant-listing-select-fail'}`}
                                value={formFieldDataSubmitStatus.status || "pass"}
                                options={[
                                    { label: "Pass", value: "pass" },
                                    { label: "Fail", value: "fail" }
                                ]}
                            />
                            {showToastStatus.show && (
                                <div className="class-participant-listing-toast">
                                    {showToastStatus.isLoading ? (
                                        <>
                                            <i className="fas fa-spinner fa-spin mr-2"/>
                                            {t("Saving")}
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa-sharp fa-light fa-check mr-2"/>
                                            {t("Saved")}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <ClassParticipantStatus locale={locale} date={props.dateSubmitted} status={props.status} />
                        </div>
                    )}
                </td>
            )}
            {(props.classDetails?.trainingFormat !== "virtual" && props.classDetails?.trainingType === "initial-refresher") && (
                <td className={`participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} no-br`}>
                    {!props.ceSubmitted ? (
                        <div className="participant-listing-select">
                            <Select
                                label=""
                                showLabel={false}
                                isRequired
                                isDisabled={!props.canEditClassParticipants || !props.canEditParticipants}
                                name="type"
                                id="type"
                                filter
                                additionalClass="class-participant-listing-select"
                                value={formFieldDataSubmitType.type || "initial"}
                                isError={errorsSubmitType.hasOwnProperty("type") || showErrorType}
                                errorLabel={errorsSubmitType.type ?? showErrorType}
                                changeFilterAction={handleChangeSubmitType}
                                options={[
                                    { label: t("CPI.Documentation.ClassCategory.CH_I"), value: "initial" },
                                    { label: t("CPI.Documentation.ClassCategory.CH_R"), value: "refresher" }
                                ]}
                            />
                            {showToastType.show && (
                                <div className="class-participant-listing-toast">
                                    {showToastType.isLoading ? (
                                        <>
                                            <i className="fas fa-spinner fa-spin mr-2"/>
                                            {t("Saving")}
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa-sharp fa-light fa-check mr-2"/>
                                            {t("Saved")}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className="small participant-listing-text text-capitalize">{props.classType}</p>
                    )}
                </td>
            )}
            <td className={`participant-listing-container participant-listing-actions ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                <div className="d-flex flex-row justify-content-end class-participant-listing-actions">
                    {props.classDetails?.trainingFormat !== "virtual" && (
                        <>
                            {(props.ceSubmitted && !props.isEdit && !classIsExpired && props.canEditClassParticipants && props.canEditParticipants) && (
                                <div className="class-participant-listing-icon-container" onClick={() => onEdit(true, props.classParticipantId)}>
                                    <i className="fa-light fa-sharp fa-pen class-participant-listing-icon" />
                                    <span className="small">{t("Edit")}</span>
                                </div>
                            )}
                        </>
                    )}
                    <ActionMenu elementId={(props.indexOfList) ? `participant-actionmenu-${props.indexOfList}` : ''} isOpen={false} menuItemsArr={renderActionMenu()} />
                </div>
            </td>
            {edit && (
                <div className="class-participant-listing-edit">
                    <div className="class-participant-listing-edit-container">
                        <h6 className="bold class-participant-listing-edit-title">{t("Edit-Participant-Record")}</h6>
                        <div className="class-participant-listing-edit-form">
                            {props.classDetails?.blueCardRequired && (
                                <TextInput
                                    isRequired
                                    label={t("Blue-Card-Number")}
                                    name="blueCardId"
                                    id="blueCardId"
                                    value={formFieldDataEdit.blueCardId}
                                    changeAction={handleChangeByUserEventEdit}
                                    isError={errorsEdit.hasOwnProperty("blueCardId") || showErrorBlueCard}
                                    errorLabel={errorsEdit.blueCardId ?? showErrorBlueCard}
                                    onKeyDown={handleBlueCardEdit}
                                />
                            )}
                            <Select
                                label={t("Status")}
                                isRequired
                                name="status"
                                additionalClass="class-participant-listing-select"
                                id="status"
                                filter
                                changeFilterAction={handleChangeStatus}
                                isError={errorsEdit.hasOwnProperty("status")}
                                errorLabel={errorsEdit.status}
                                iconBeforeTextClass={`${formFieldDataEdit.status === "fail" ? 'class-participant-listing-select-fail': 'class-participant-listing-select-pass'}`}
                                iconBeforeOptionClass={(data: FormSelectOption) => `${(data.value === "pass" || data.value === null) ? 'class-participant-listing-select-pass': 'class-participant-listing-select-fail'}`}
                                value={formFieldDataEdit.status || "pass"}
                                options={[
                                    { label: t("Pass"), value: "pass" },
                                    { label: t("Fail"), value: "fail" }
                                ]}
                            />
                            {(props.classDetails?.trainingFormat !== "virtual" && props.classDetails?.trainingType === "initial-refresher") && (
                                <Select
                                    label={t("Type")}
                                    isRequired
                                    name="type"
                                    id="type"
                                    filter
                                    additionalClass="class-participant-listing-select"
                                    value={formFieldDataEdit.type || "initial"}
                                    isError={errorsEdit.hasOwnProperty("type")}
                                    errorLabel={errorsEdit.type}
                                    changeFilterAction={handleChangeType}
                                    options={[
                                        { label: t("CPI.Documentation.ClassCategory.CH_I"), value: "initial" },
                                        { label: t("CPI.Documentation.ClassCategory.CH_R"), value: "refresher" }
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                    <div className="class-participant-listing-edit-actions">
                        <DestructiveButton
                            label={<><i className="fa-light fa-sharp fa-xmark"/> {t("Cancel")}</>}
                            clickAction={() => onEdit(false, props.classParticipantId)}
                        />
                        <Button elementId="save-button" isSolid label={t("Save")} clickAction={handleSubmitEdit} />
                    </div>
                </div>
            )}
        </>
    ) : (
        <div className="d-flex flex-column">
            <div className="participant-listing-grid">
                <div id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                    <div className="participant-listing-grid-checkbox">
                        {(props.classDetails?.trainingFormat !== "virtual" && !props.ceSubmitted) && (
                            <input
                                onChange={(e) => props.onCheck(e.target.checked, props.classParticipantId)}
                                className="ml-0 form-checkbox participant-listing-checkbox"
                                id={`${props.externalId}-participant-checkbox`}
                                checked={props.isChecked}
                                name="participant-checkbox"
                                type="checkbox"
                            />
                        )}
                        <span className="fw600 fs14">{t("Name")}</span>
                    </div>
                </div>
                <div id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                    <div className="participant-listing-grid-status">
                        <div>
                            <p className="mb-2 fw600 color-black participant-listing-text wrap-normal">{participantName}</p>
                            <p className="mb-2 small participant-listing-text wrap-normal">{props.email}</p>
                            <p className="mb-0 small participant-listing-text wrap-normal">{props.externalId}</p>
                        </div>
                    <ActionMenu isOpen={false} menuItemsArr={renderActionMenu()} />
                    </div>
                </div>
            </div>
            { ((props.classDetails.trainingFormat !== "classroom") && (!isMigrated) && (props.classDetails.desiredLms == 'internal')) && (
                <div className="participant-listing-grid">
                    <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                        <span className="fs14 fw600">{t("Online Course")}</span>
                    </td>
                    <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                        <ProgressBar
                            startedDate={props.dateStarted}
                            widthBar={100}
                            onlineCoursePercentComplete={props.percentComplete}
                            completedDate={props.dateCompleted ? new Date(props.dateCompleted) : null}
                            minified
                        />
                    </td>
                </div>
            )}
            {props.classDetails?.trainingFormat !== "virtual" && props.classDetails?.blueCardRequired && (
                <div className="participant-listing-grid">
                    <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                        <span className="fs14 fw600">{t("Blue-Card-Number")}</span>
                    </td>
                    <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                        {!props.ceSubmitted ? (
                            <div>
                                <TextInput
                                    onBlur={handleSubmitBlueCardId}
                                    showLabel={false}
                                    label={t("blueCardId")}
                                    isReadOnly={!props.canEditClassParticipants || !props.canEditParticipants}
                                    displayAsText={props.canEditClassParticipants && props.canEditParticipants}
                                    name="blueCardId"
                                    changeAction={handleChangeBlueCard}
                                    id="blueCardId"
                                    value={formFieldDataSubmitBlueCardId.blueCardId ?? ''}
                                    isError={errorsSubmitBlueCardId.hasOwnProperty("blueCardId") || showErrorBlueCard}
                                    errorLabel={errorsSubmitBlueCardId.blueCardId ?? showErrorBlueCard}
                                    onKeyDown={handleBlueCardEnter}
                                />
                                {showToastBlueCard.show && (
                                    <div className="class-participant-listing-toast">
                                        {showToastBlueCard.isLoading ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin mr-2"/>
                                                {t("Saving")}
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa-sharp fa-light fa-check mr-2"/>
                                                {t("Saved")}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p className="small participant-listing-text">{props.blueCardId}</p>
                        )}
                    </td>
                </div>
            )}
            {props.classDetails?.trainingFormat !== "virtual" && (
                <div className="participant-listing-grid">
                    <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked br-r">
                        <span className="fs14 fw600">Status</span>
                    </td>
                    <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'} br-l`}>
                        {!props.ceSubmitted ? (
                            <div className="mt-1">
                                <Select
                                    label=""
                                    showLabel={false}
                                    isRequired
                                    isDisabled={!props.canEditClassParticipants || !props.canEditParticipants}
                                    name="status"
                                    additionalClass="class-participant-listing-select"
                                    id="status"
                                    filter
                                    changeFilterAction={handleChangeSubmitStatus}
                                    isError={errorsSubmitStatus.hasOwnProperty("status") || showErrorStatus}
                                    errorLabel={errorsSubmitStatus.status ?? showErrorStatus}
                                    iconBeforeTextClass={`${formFieldDataSubmitStatus.status === "fail" ? 'class-participant-listing-select-fail': 'class-participant-listing-select-pass'}`}
                                    value={formFieldDataSubmitStatus.status || "pass"}
                                    options={[
                                        { label: t("Pass"), value: "pass" },
                                        { label: t("Fail"), value: "fail" }
                                    ]}
                                />
                                {showToastStatus.show && (
                                    <div className="class-participant-listing-toast">
                                        {showToastStatus.isLoading ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin mr-2"/>
                                                {t("Saving")}
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa-sharp fa-light fa-check mr-2"/>
                                                {t("Saved")}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <ClassParticipantStatus locale={locale} date={props.dateSubmitted} status={props.status} />
                            </div>
                        )}
                    </td>
                </div>
            )}
            {/*<div className="participant-listing-grid">
                <td id="mobile-custom-table-cell-padding" className="header-column participant-listing-container participant-listing-checkbox-container participant-listing-checked">
                </td>
                <td id="mobile-custom-table-cell-padding" className={`data-column participant-listing-container participant-listing-info ${props.isChecked ? 'participant-listing-checked' : 'participant-listing-unchecked'}`}>
                    <div className="d-flex flex-row justify-content-center class-participant-listing-actions">
                        {(props.ceSubmitted && !props.isEdit) && (
                            <div className="class-participant-listing-icon-container" onClick={() => onEdit(true, props.classParticipantId)}>
                                <i className="fa-light fa-sharp fa-pen class-participant-listing-icon" />
                                <span className="small">Edit</span>
                            </div>
                        )}
                        <div className="class-participant-listing-icon-container">
                            <i className="fa-light fa-sharp fa-people-pants class-participant-listing-icon-box">
                                <div className="class-participant-listing-icon-badge">
                                    <i className="fa-light fa-sharp fa-check fw400 badge-content-icon" />
                                </div>
                            </i>
                            <span className="small">Physicals</span>
                        </div>
                        <div className="class-participant-listing-icon-container">
                            <i className="fa-light fa-sharp fa-note-sticky class-participant-listing-icon-box">
                                <div className="class-participant-listing-icon-badge">
                                    <span className="fw600 badge-content-text">1</span>
                                </div>
                            </i>
                            <span className="small">Notes</span>
                        </div>
                    </div>
                </td>
            </div>*/}
        </div>
    );

};

export default ClassParticipantListing;
