import * as React from "react";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import { ConfigContext } from "../../../configuration-context";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";
import { validateDocumentClass, validateDocumentClassWithDates } from "../../../FormValidationRules/documentClass";
import useForm from "../../../hooks/useForm";
import ClassInfoModel from "../../../Interfaces/ClassInfoModel";
import RadioList from "../../../Components/Form/radio/radio-list";
import DateHours from "../../../Components/UI/date-hours";
import { IDateHour, IAdditionalDateHour } from "../../../Interfaces/Platform/DateHours";
import usePageLevelAlerts from "../../../hooks/usePageLevelAlerts";
import { returnPageLevelAlerts } from "../../Utils/alerts";
import { Alert } from "react-bootstrap";
import InstructorSelect from "../../../Components/instructor-select";
import { InstructorModel } from "../../manage-classes";

import useFeatureFlags from "../../../hooks/useFeatureFlags";
import useTrainableSpecialtiesByCert from "../../../hooks/useTrainableSpecialtiesByCert";

import { RbacContext } from "../../../rbac-context";

interface EditionCourse {
    specialty: string;
    edition: string;
    qualification: string;
}

export interface Props {
    class: ClassInfoModel;
    classId: string;
    editionCourses: EditionCourse[];
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (form: any) => void;
    convertClassError: string;
    resetConvertClassError: () => void;
    instructors: InstructorModel[];
}

const DocumentClass = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();
    const rbac = React.useContext(RbacContext);
    const [ITLIntermediatePhysicalSkills] = useFeatureFlags("ITLIntermediatePhysicalSkills", "pending");
    const [pageLevelAlerts, addPageLevelAlert, removePageLevelAlerts] = usePageLevelAlerts();
    const [userCanConvertClass, setUserCanConvertClass] = React.useState<boolean>(false);
    const [convertError, setConvertError] = React.useState<string>(null);
    const [displayConvertError, setDisplayConvertError] = React.useState<boolean>(false);
    const [isPreventionFirst, setIsPreventionFirst] = React.useState<boolean>(false);

    // Some silly code I needed to write to ensure functions using these values have updated values
    const [preventConvertSpam, setPreventConvertSpam] = React.useState(false);
    const preventConvertSpamRef = React.useRef(preventConvertSpam);
    React.useEffect(() => { 
        preventConvertSpamRef.current = preventConvertSpam 
    }, [preventConvertSpam]);

    const today = new Date();
    const todayDateFormat = today.toISOString().split("T")[0];
    const [primaryDay, setPrimaryDay] = React.useState<IDateHour>({
        date: today.toISOString(),
        initialHours: "0",
        refresherHours: "0"
    });
    const [additionalDays, setAdditionalDays] = React.useState<IAdditionalDateHour[]>([
        { id: "additionalDay-0", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-1", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-2", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" },
        { id: "additionalDay-3", show: false, date: todayDateFormat, initialHours: "0", refresherHours: "0" }
    ]);
    const [ trainablePrograms, trainableProgramsByListOfCerts ] = useTrainableSpecialtiesByCert(ITLIntermediatePhysicalSkills);

    const configContext = React.useContext(ConfigContext);

    const handleConvertClass = async (form: any) => {
        if (userCanConvertClass && !preventConvertSpamRef.current) {
            
            // Now that we've begun class convert functionality, set preventConvertSpam to true to prevent spamming the button.
            // We'll have only gotten to this point if the form field inputs validated so we don't need to worry about handling that explicitly.
            setPreventConvertSpam(true);

            try {
                props.onSubmit(form);
                setPreventConvertSpam(false);
            } catch (e) {
                console.log('error', e);

                // If something goes wrong, re-enable convert button
                setPreventConvertSpam(false);
            }
            
        }
    };

    const { formFieldData, errors, handleChangeByGetEvent, handleChangeByUserEvent, handleSubmit } = useForm(
        handleConvertClass,
        !props.class.ClassStartDate ? validateDocumentClassWithDates : validateDocumentClass
    );

    const checkInstructorCerts = (instructorId: string) => {
        if (!(props.editionCourses?.length > 0)) {
            setUserCanConvertClass(false);
            return false;
        }

        if (!instructorId) {
            setUserCanConvertClass(false);
            return false;
        }

        if (!formFieldData.selection) {
            setUserCanConvertClass(false);
            return false;
        }

        const editionCourse = props.editionCourses[formFieldData.selection];

        const selectedInstructor = props.instructors.find((instructor) => instructor.userId === instructorId);

        // Build a total list of trainable programs based on the instructor's certifications.
        const allTrainablePrograms = trainableProgramsByListOfCerts(selectedInstructor.certifications, rbac.userContext.Country, false, false);

        // Check if the instructor is certified to train the selected program.
        // Includes trainable programs under a parent program. (APS can teach NCI and VI etc.)
        if (allTrainablePrograms.includes(editionCourse.specialty)) {
            setUserCanConvertClass(true);
            return true;
        } else {
            setUserCanConvertClass(false);
            addPageLevelAlert({
                alertLevel: "error",
                description: t(
                    "It looks like the instructor you selected is not certified to train this program. Choose a different certified instructor."
                ),
                canDismiss: true
            });
            return false;
        }
    };

    React.useEffect(() => {
        if (props.class.Name.includes("Prevention First")) {
            setIsPreventionFirst(true);
            setUserCanConvertClass(true);
        } else {
            setIsPreventionFirst(false);
            setUserCanConvertClass(false);
        }
    }, [props.class]);

    React.useEffect(() => {
        setDisplayConvertError(props.convertClassError !== null);
        setConvertError(props.convertClassError);
    }, [props.convertClassError]);

    React.useEffect(() => {
        if (configContext?.SystemConfiguration?.ApimKey) {
        }
    }, [configContext?.SystemConfiguration?.ApimKey]);

    const renderRadioButtons = () => {
        return props?.editionCourses?.map((i, index: number) => {
            const renderSpecialty = tp(i.specialty);
            const renderEdition = i.edition ? `, ${t(`Edition.${i.edition}`)}` : "";
            const renderQualification = i.qualification
                ? `, ${tp(
                      i.qualification === "None" || i.qualification === "none"
                          ? "CPI.UI.OrganizationType.None"
                          : i.qualification
                  )}`
                : "";
            const label = (
                <span>
                    {renderSpecialty}
                    {renderEdition}
                    {renderQualification}
                </span>
            );
            return {
                label: label,
                id: index,
                name: "",
                value: index,
                checked: props?.editionCourses?.length === 1 ? true : false
            };
        });
    };

    React.useEffect(() => {
        if (props?.editionCourses?.length === 1) {
            handleChangeByGetEvent({
                selection: "0",
                specialty: props.editionCourses[0].specialty,
                edition: props.editionCourses[0].edition,
                qualification:
                    props.editionCourses[0].qualification === "" ? "None" : props.editionCourses[0].qualification
            });
        }
    }, [props?.editionCourses?.length]);

    React.useEffect(() => {
        if (errors.selection === "Select a program") {
            addPageLevelAlert({
                alertLevel: "error",
                description: t(
                    "You are not authorized to convert this class. Please reach out to the Certified Instructor who trained the class to proceed."
                ),
                canDismiss: true
            });
        }
    }, [errors]);

    const handleChangeRadioButton = (e: any) => {
        const editionCourse = props.editionCourses[e.target.value];
        handleChangeByGetEvent({
            selection: e.target.value,
            specialty: editionCourse.specialty,
            edition: editionCourse.edition,
            qualification: editionCourse.qualification === "" ? "None" : editionCourse.qualification
        });
    };

    React.useEffect(() => {
        const additional = additionalDays
            .filter((day) => day.show)
            .map((day) => ({
                id: day.id,
                date: new Date(day.date).toISOString(),
                initialHours: day.initialHours,
                refresherHours: day.refresherHours
            }));
        handleChangeByGetEvent({ classDates: [{ id: "primaryDay", ...primaryDay }, ...additional] });
    }, [additionalDays, primaryDay]);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {pageLevelAlerts?.length > 0 && (
                    <div onClick={() => removePageLevelAlerts()} className="alert alert-danger mt-4">
                        {returnPageLevelAlerts(pageLevelAlerts)}
                    </div>
                )}
                {displayConvertError && convertError && <Alert variant="danger">{t(convertError)}</Alert>}
                <p>
                    In order to complete the documentation for this class, we need a bit more information. Could you
                    select the program you were training?
                </p>
                {props.editionCourses?.length > 0 && (
                    <RadioList
                        label=""
                        name="selection"
                        id="selection"
                        onChange={handleChangeRadioButton}
                        isError={errors.hasOwnProperty("selection")}
                        errorLabel={errors.selection}
                        showLabel={false}
                        radios={renderRadioButtons()}
                    />
                )}
                {!props.class.ClassStartDate && (
                    <div className="mt-3">
                        <DateHours
                            primaryDay={primaryDay}
                            setPrimaryDay={setPrimaryDay}
                            removable
                            settings={{ dates: true, hours: true }}
                            typeOfTraining={"initial-refresher"}
                            additionalDays={additionalDays}
                            setAdditionalDays={setAdditionalDays}
                            errors={errors}
                            isError={errors.hasOwnProperty("classDates")}
                            errorLabel={errors.classDates}
                            isRequired
                        />
                    </div>
                )}
                {!isPreventionFirst && (
                    <InstructorSelect instructors={props.instructors} onSelectInstructor={checkInstructorCerts} />
                )}
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton
                        clickAction={() => {
                            setDisplayConvertError(false);
                            props.resetConvertClassError(null);
                            setConvertError(null);
                            props.onClose();
                        }}
                        label={
                            <>
                                <i className="me-1 far fa-times" /> Cancel
                            </>
                        }
                    />
                    <Button
                        isSolid
                        label={
                            <div className="d-flex align-items-center justify-content-center">
                                {props.isLoading && <i className="fas fa-spinner fa-spin mr-2" />} {t("Update Class")}
                            </div>
                        }
                        isDisabled={props.isLoading}
                        clickAction={handleSubmit}
                    />
                </div>
            </div>
        </>
    );
};

export default DocumentClass;
