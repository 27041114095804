import * as React from "react";
import { PropsWithChildren } from "react";

import { AppActions, Rules, UserContext } from "./Interfaces/UserContext";
import { dataLayer, triggerDataLayer } from "./google-tag-manager";
import { pendoIdentify, resetPendo } from "./pendo";

import { Alert } from "react-bootstrap";
import { RoleEnum, CultureEnum } from "./Enums";
import { fetchMyProfileInfo, fetchMyCertInfo, fetchMyEnrollmentInfo, logout } from "./ApiServices/Login";
import { fetchIsBAOfActiveCIs } from "./Pages/Utils/profile";
import { getUserClaims } from "./helper-functions";
import { useTranslation } from "react-i18next";
import useLocalStorage from "./hooks/useLocalstorage";

import LoadingDisplay from "./Components/UI/loading";
import { ConfigContext } from "./configuration-context";

import { compileUserDataFromApim, userDataIsComplete } from "./Pages/Utils/profile";

// Add application rules for Role Based Access Control here
const AppRules: Rules = {};
AppRules[RoleEnum.Developer] = { static: ["SeatsPageView", "SeatsAdjustExpiration"] };
AppRules[RoleEnum.StaffSeatAdjustor] = { static: ["SeatsPageView", "SeatsAdjustExpiration"] };
AppRules[RoleEnum.StaffSupport] = { static: ["SeatsPageView"] };

export const emptyUserContext: UserContext = {
    UserId: null,
    Email: null,
    FirstName: null,
    LastName: null,
    ContactId: null,
    CustomerId: null,
    KenticoId: null,
    Roles: [],
    RolesByName: [],
    Population: null,
    Organizations: [],
    OrganizationId: null,
    IsBAOfActiveCIs: false,
    OrgId: null,
    OrganizationName: null,
    OrganizationNumber: null,
    OrganizationIndustry: null,
    AltOrganizationsList: [],
    SelectedOrganization: null,
    CertificationData: null,
    Country: null,
    PingId: null
};

export const RbacContext = React.createContext<{
    userContext: UserContext;
    setContext: (context: UserContext, refreshPage?: boolean) => void;
    resetContext: () => void;
    reloadContext: () => Promise<any>;
    setAgreementCriteriaMet: () => void;
    setCountry: (cultureCode:string) => void;
    setAltOrganization: (orgId: string) => void;
    refreshPage: boolean;
}>({
    userContext: { ...emptyUserContext },
    setContext: () => {},
    resetContext: () => {},
    reloadContext: async () => {},
    setAgreementCriteriaMet: () => {},
    setCountry: (cultureCode:string) => {},
    setAltOrganization: (orgId: string) => {},
    refreshPage: false
});
RbacContext.displayName = "RbacContext";

export const HoneyBeeUserContextApp: React.FunctionComponent<PropsWithChildren> = (props) => {
    const { t } = useTranslation();

    const [isLoading, setLoading] = React.useState(false);
    const [isError, setError] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState<UserContext>({ ...emptyUserContext });
    const [refresh, setRefresh] = React.useState(false);
    const configContext = React.useContext(ConfigContext);
    const [selectedOrganization, setSelectedOrganization] = useLocalStorage("SelectedOrganization", null);

    React.useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                const resp = getUserClaims(); // await fetchMyUserInfo();

                // check if we have a new userId and if so, clear the session storage
                if (window.sessionStorage.getItem("userId") && (window.sessionStorage.getItem("userId") != resp.sub)) {
                    window.sessionStorage.removeItem("userId");
                    window.sessionStorage.removeItem("country");
                }

                // if session items are not set, set them
                if ((!window.sessionStorage.getItem("country")) || (window.sessionStorage.getItem("country") == "undefined")) { window.sessionStorage.setItem("country", resp.culture); }
                if ((!window.sessionStorage.getItem("userId")) || (window.sessionStorage.getItem("userId") == "undefined")) { window.sessionStorage.setItem("userId", resp.sub); }
                
                //const oldResp = await fetchMyUserInfo();
                //console.log(" :: OLD RESP :: ");
                //console.log(oldResp);
                
                if (resp && resp.sub) {
                    let currentUser:any = resp;

                    /*
                        _userHelper.PingId,
                        _userHelper.Email,
                        _userHelper.FirstName,
                        _userHelper.LastName,
                        _userHelper.ContactId,
                        _userHelper.CustomerId,
                        _userHelper.KenticoId,
                        _userHelper.Roles,
                        _userHelper.OrganizationId,
                        _userHelper.OrganizationNumber,
                        _userHelper.OrganizationName,
                        _userHelper.Country,
                        _userHelper.Organizations,
                        _userHelper.OrganizationIndustry
                    */

                    const userDataFromApim = await compileUserDataFromApim();                   

                    const profile = (userDataFromApim.profile) ? userDataFromApim.profile : {};
                    const cert = (userDataFromApim.cert) ? userDataFromApim.cert : {};
                    const enrollments = (userDataFromApim.enrollments) ? userDataFromApim.enrollments : [];
                    const organization = (userDataFromApim.organization) ? userDataFromApim.organization : {};
                    const altOrganizations = (userDataFromApim.altOrganizations) ? userDataFromApim.altOrganizations : [];    

                    const isBAOfActiveCIs = await fetchIsBAOfActiveCIs(resp.rolesByName ?? [], profile.organizationId);

                    currentUser.UserDataIsComplete = userDataIsComplete(userDataFromApim);
                    currentUser.OrganizationId = (profile && profile.organizationId) ? profile.organizationId : "";
                    currentUser.OrgId = (organization && organization.orgId) ? organization.orgId : "";
                    currentUser.UserId = resp.sub;
                    currentUser.PingId = resp.sub;
                    currentUser.Email = resp.email;
                    currentUser.FirstName = (profile && profile.firstName) ? profile.firstName : resp.firstName;
                    currentUser.LastName = (profile && profile.lastName) ? profile.lastName : resp.lastName;
                    currentUser.ContactId = "";
                    currentUser.CustomerId = (profile && profile.customerId) ? profile.customerId : "";
                    currentUser.KenticoId = "";
                    currentUser.Roles = resp.roles;
                    currentUser.IsBAOfActiveCIs = isBAOfActiveCIs;
                    // will not need this next line after updating how roles work
                    currentUser.roles = (resp.roles && userDataIsComplete(userDataFromApim)) ? resp.roles : [];
                    currentUser.RolesByName = (resp.rolesByName && userDataIsComplete(userDataFromApim)) ? resp.rolesByName : [];
                    currentUser.Population = resp.populationId;
                    currentUser.OrganizationNumber = (profile && profile.organizationId) ? profile.organizationId : "";
                    currentUser.OrganizationName = (organization && organization.organizationName) ? organization.organizationName : "";
                    currentUser.Country = (CultureEnum.getProperlyCasedCultureCode(window.sessionStorage.getItem("country"))) ? CultureEnum.getProperlyCasedCultureCode(window.sessionStorage.getItem("country")) : CultureEnum.getProperlyCasedCultureCode(resp.culture);
                    currentUser.Organizations = [];
                    currentUser.AltOrganizationsList = altOrganizations;
                    currentUser.SelectedOrganization = !selectedOrganization ? profile && profile.organizationId && profile.organizationId : selectedOrganization;
                    if (!selectedOrganization) {
                        setSelectedOrganization(profile && profile.organizationId ? profile.organizationId : selectedOrganization);
                        currentUser.SelectedOrganization = (profile && profile.organizationId) ? profile.organizationId : selectedOrganization;
                    } else {
                        // If the current org in localStorage doesn't exist in list
                        if (!(!!altOrganizations.find((i: any) => i.organizationId === selectedOrganization))) {
                            setSelectedOrganization((profile && profile.organizationId) ? profile.organizationId : "");
                            currentUser.SelectedOrganization = (profile && profile.organizationId) ? profile.organizationId : "";
                        }
                    }
                    // If there's only main org or no orgs at all, set user organization
                    if (altOrganizations?.length <= 1) {
                        setSelectedOrganization((profile && profile.organizationId) ? profile.organizationId : "");
                        currentUser.SelectedOrganization = (profile && profile.organizationId) ? profile.organizationId : "";
                    }
                    currentUser.OrganizationIndustry = (organization && organization.marketType) ? organization.marketType : "N/A";

                    currentUser.CertificationData = (cert) ? cert : [];

                    currentUser.AgreementCriteriaMet = agreementCriteriaMet(enrollments);

                    dataLayer(currentUser);
                    setCurrentUser(currentUser);
                    pendoIdentify(currentUser, configContext);

                } else {
                    if (!window.location.pathname.includes("Login") && !window.location.pathname.toLowerCase().includes("set-your-password")) {
                        logout();
                        window.location.pathname = "/Login";
                    }
                    triggerDataLayer();
                }
            } catch (error) {
                if (!window.location.pathname.includes("Login")) {
                    logout();
                    window.location.pathname = "/Login";
                }
                console.error(error);
                setError(true);
            }
            setLoading(false);
        }

        getData();
    }, []);

    if (isError) {
        return (
            <Alert variant="danger">
                {t("Error")}: {t("An unknown error occurred, please try again")}.
            </Alert>
        );
    }

    if (isLoading) {
        return (
            <LoadingDisplay />
        );
    }

    return (
        <RbacContext.Provider
            value={{
                userContext: currentUser,
                setContext: (context: UserContext, refreshPage: boolean = false) => {
                    setCurrentUser(context);
                    if (refreshPage) {
                        setRefresh(!refresh);
                    }
                },
                setAgreementCriteriaMet: () => {
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        AgreementCriteriaMet: true
                    }));    
                },
                setCountry: (cultureCode:string) => {
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        Country: cultureCode
                    }));   
                },
                setAltOrganization: (orgId :string) => {
                    setSelectedOrganization(orgId);
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        SelectedOrganization: orgId
                    }));   
                },
                resetContext: () => {
                    setCurrentUser({ ...emptyUserContext });
                    resetPendo();
                },
                reloadContext: async () => {
                    try {
                        var resp = getUserClaims(); // await fetchMyUserInfo();

                        // if session items are not set, set them
                        if (((!window.sessionStorage.getItem("country")) || (window.sessionStorage.getItem("country") == "undefined"))) { window.sessionStorage.setItem("country", resp.culture); }
                        if (((!window.sessionStorage.getItem("userId")) || (window.sessionStorage.getItem("userId") == "undefined"))) { window.sessionStorage.setItem("userId", resp.sub); }

                        let currentUser:any = resp;

                        if (resp && resp.sub) {
                            const profile = await fetchMyProfileInfo(currentUser.sub);
                            // very temporary fallback - need to determine how to handle an account that has no organizationId, i don't believe this should ever happen
                            // already have a task made to revert/update this once we've determined approach but this is likely breaking dev
                            // I'm not sure the platform org is coming through on the ping jwt yet so will need to make this call for the time being
                            currentUser.OrganizationId = (profile && profile.organizationId) ? profile.organizationId : "ca5124dd-cd71-ee11-9ae7-000d3a1d519c" ;

                            currentUser.UserId = resp.sub;
                            currentUser.PingId = resp.sub;
                            currentUser.Email = resp.email;
                            currentUser.FirstName = resp.firstName;
                            currentUser.LastName = resp.lastName;
                            currentUser.ContactId = "";
                            currentUser.CustomerId = "";
                            currentUser.KenticoId = "";
                            currentUser.Roles = resp.roles;
                            // will not need this next line after updating how roles work
                            currentUser.roles = (resp.roles) ? resp.roles : [];
                            currentUser.RolesByName = (resp.rolesByName) ? resp.rolesByName : [];
                            currentUser.Population = resp.populationId;
                            currentUser.OrganizationNumber = "";
                            currentUser.OrganizationName = "";
                            currentUser.Country = (CultureEnum.getProperlyCasedCultureCode(window.sessionStorage.getItem("country"))) ? CultureEnum.getProperlyCasedCultureCode(window.sessionStorage.getItem("country")) : CultureEnum.getProperlyCasedCultureCode(resp.culture);
                            currentUser.Organizations = [];
                            currentUser.OrganizationIndustry = "";

                            const cert = await fetchMyCertInfo(currentUser.sub);
                            currentUser.CertificationData = (cert) ? cert : {};

                            const enrollments = await fetchMyEnrollmentInfo(resp.sub);
                            currentUser.AgreementCriteriaMet = agreementCriteriaMet(enrollments);
                        }

                        if (currentUser) {
                            dataLayer(currentUser);
                            setCurrentUser(currentUser);
                            pendoIdentify(currentUser, configContext);
                        } else {
                            if (!window.location.pathname.includes("Login")) {
                                logout();
                                window.location.pathname = "/Login";
                            }
                            triggerDataLayer();
                        }
                    } catch (error) {
                        if (!window.location.pathname.includes("Login")) {
                            logout();
                            window.location.pathname = "/Login";
                        }
                        console.error(error);
                        setError(true);
                    }
                },
                refreshPage: refresh
            }}
        >
            {props.children}
        </RbacContext.Provider>
    );
};

export const createHoneyBeeUserApp = (WrappedComponent: React.ElementType, props?: any) => (
    <HoneyBeeUserContextApp>
        <WrappedComponent {...props} />
    </HoneyBeeUserContextApp>
);

const check = (action: AppActions, data: UserContext): boolean => {
    for (let index = 0; index < data.Roles.length; ++index) {
        const role = data.Roles[index];
        const permissions = AppRules[role];
        if (permissions) {
            const staticPermissions = permissions.static;
            if (staticPermissions && staticPermissions.includes(action)) {
                return true;
            }
        }
    }

    for (let index = 0; index < data.Roles.length; ++index) {
        const role = data.Roles[index];
        const permissions = AppRules[role];
        if (permissions) {
            const dynamicPermissions = permissions.dynamic;
            if (dynamicPermissions) {
                const permissionCondition = dynamicPermissions[action];
                if (permissionCondition && permissionCondition(data)) {
                    return true;
                }
            }
        }
    }

    return false;
};

const agreementCriteriaMet = (enrollments:any[]) => {

    if (enrollments && Array.isArray(enrollments)) {
        let enrollmentWithAgreementAccepted = enrollments.find((item:any) => 
            (item.certAgreementRequired && item.certAgreementAccepted)
        );

        let noAgreementIsRequired = enrollments.every((item:any) => 
            (!item.certAgreementRequired)
        );

        return (enrollmentWithAgreementAccepted || noAgreementIsRequired) ? true : false;
    } else {
        // no agreement needed due to zero enrollments
        return true;
    }
    
}

type CanShowProps = {
    action: AppActions;
};
export const CanShow: React.FunctionComponent<PropsWithChildren<CanShowProps>> = (props) => {
    return (
        <RbacContext.Consumer>
            {(context) => (check(props.action, context.userContext) ? props.children : null)}
        </RbacContext.Consumer>
    );
};
