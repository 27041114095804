import i18n from "i18next";

export const bulkUploadValidationParser = async (response: Response) => {
    response.statusText
    const customResponse: CustomResponse = {
        message: response.statusText,
        isError: !response.ok,
        statusCode: response.status
    };

    if (response.ok) {
        customResponse.response = await response.json();
    } else {
        switch (response.status) {
            case 409: // rows with errors                
                customResponse.message = i18n.t("Bulk-Upload-Error-409");
                customResponse.response = await response.json();
                break;

            case 413: // CSV file too large
                customResponse.message = i18n.t("Bulk-Upload-Error-413");
                break;

            case 415: // Not a CSV File
                customResponse.message = i18n.t("Bulk-Upload-Error-415");
                break;

            case 417: // Not enough seats
                customResponse.message = i18n.t("Bulk-Upload-Error-417");
                break;

            case 418: // More than 200 rows
                customResponse.message = i18n.t("Bulk-Upload-Error-418");
                break;

            case 422: // CSV Headers missing
                const { ...data } = await response.json();
                customResponse.message = i18n.t(data.detail);
                break;

            case 500: // Server error
                customResponse.message = i18n.t("Response-500");
                break;
        }
    }

    return customResponse;
}

export interface CustomResponse {
    message: string,
    isError: boolean,
    statusCode: number,
    response?: any
};
